<template>
  <div class="modal fade tw-categories-modal" :id="categories" tabindex="-1" role="dialog" aria-labelledby="shippingLabel" aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="categoriesLabel">{{ categories }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul class="categories-holder" v-if="categories === $t('catalog.category')">
            <li
              v-for="(item) in filterItems"
              v-bind:key="item.Category">
              <div class="form-group checkbox-form filter">
                <input
                  type="checkbox"
                  :id="item.Category"
                  v-model="categoryName"
                  :value="item.Category"
                >
                <label :for="item.Category" class="checkbox-holder">
                </label>
                <label :for="item.Category">
                  {{item.Category}} (<span>{{item.Count}}</span>)
                </label>
              </div>
            </li>
          </ul>
          <ul class="categories-holder" v-else>
            <li
              v-for="(item) in filterItems"
              v-bind:key="item.Manufacturer">
              <div class="form-group checkbox-form filter">
                <input
                  type="checkbox"
                  :id="item.Manufacturer"
                  v-model="manufacturerName"
                  :value="item.Manufacturer"
                >
                <label :for="item.Manufacturer" class="checkbox-holder">
                </label>
                <label :for="item.Manufacturer">
                  {{item.Manufacturer}} (<span>{{item.Count}}</span>)
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <template v-if="categories === $t('catalog.category')">
            <button type="button" class="btn" data-dismiss="modal" @click="updateCategory()">{{$t('buttons.ok')}}</button>
          </template>
          <template v-else>
            <button type="button" class="btn" data-dismiss="modal" @click="updateManufacturer()">{{$t('buttons.ok')}}</button>
          </template>
          <button type="button" class="btn" data-dismiss="modal">{{$t('buttons.close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'

export default {
  name: 'ReqMoreCategory',
  components: {
  },
  props: {
    categories: {
      type: String,
      required: true
    },
    searchResults: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      categoryName: [],
      manufacturerName: []
    }
  },
  methods: {
    created () {
      if (this.searchCategoryList.length > 0 && categories === this.$t('catalog.category')) {
        this.categoryName = this.searchCategoryList
      } else if (this.searchCategory != '' && categories === this.$t('catalog.category')) {
        this.categoryName.push(this.searchCategory)
      }
      if (this.searchManufacturerList.length > 0 && categories !== this.$t('catalog.category')) {
        this.manufacturerName = this.searchManufacturerList
      } else if (this.searchManufacturer != '' && categories !== this.$t('catalog.category')) {
        this.manufacturerName.push(this.searchManufacturer)
      }
    },
    updateCategory () {
      this.$emit('searchCategory', this.categoryName)
      $(`#${this.$t('catalog.category')}`).modal('hide')
    },
    updateManufacturer () {
      this.$emit('searchManufacturer', this.manufacturerName)
      $(`#${this.$t('catalog.brand')}`).modal('hide')
    }
  },
  computed: {
    filterItems () {
      if (this.searchResults) {
        if (this.categories === this.$t('catalog.category')) {
          const categories = Array.from(this.searchResults.MatchingCategories).sort((a, b) => (a.Category ? a.Category : '').localeCompare(b.Category ? b.Category : ''))
          return categories
        } else {
          const manufacturers = Array.from(this.searchResults.MatchingManufacturers).sort((a, b) => (a.Manufacturer ? a.Manufacturer : '').localeCompare(b.Manufacturer ? b.Manufacturer : ''))
          return manufacturers
        }
      }
      return []
    },
    searchCategory () {
      return this.$store.getters.searchCategory
    },
    searchManufacturer () {
      return this.$store.getters.searchManufacturer
    },
    searchCategoryList () {
      return this.$store.getters.searchCategoryList
    },
    searchManufacturerList () {
      return this.$store.getters.searchManufacturerList
    }
  },
  watch: {
    searchCategory: function (value) {
      if (!value) return
      this.categoryName = []
      this.categoryName.push(value)
    },
    searchManufacturer: function (value) {
      if (!value) return
      this.manufacturerName = []
      this.manufacturerName.push(value)
    },
    searchCategoryList: function (value) {
      if (!value) return
      this.categoryName = value
    },
    searchManufacturerList: function (value) {
      if (!value) return
      this.manufacturerName = value
    }
  }
}
</script>

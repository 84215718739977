const safetyPlanGeneralInfo = {
  labels: {
    0: 'Assigned Job Location',
    1: 'Project Address',
    2: 'City',
    3: 'Country',
    4: 'State',
    5: 'Postal Code',
    6: 'Max Size of Crew on the Project',
    7: 'Scope of Work',
    8: 'No Project Duration',
    9: 'Project Duration',
    10: 'Status',
    11: 'Inactive',
    12: 'Inactive Date',
    13: 'Reason',
    14: 'Decommission Reminder'
  },
  validations: {
    startDate: 'Start Date',
    endDate: 'End Date',
    inactiveDate: 'Inactive Date'
  }
}

export default safetyPlanGeneralInfo

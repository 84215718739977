const transfer = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'Id',
        type: 'check'
      },
      {
        label: '',
        key: 'totalQty',
        type: 'warning'
      },
      {
        label: 'Kit',
        key: 'KitNumber',
        nested: true,
        nestedKey: 'FromKey',
        sortable: true
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'Qty',
        key: 'Quantity',
        isEditable: true,
        type: Number,
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'Serial Number',
        key: 'SerialNumber',
        type: String,
        sortable: true
      },
      {
        label: 'From',
        key: 'EntityId',
        nested: true,
        isEditable: true,
        nestedKey: 'FromKey',
        sortable: true
      },
      {
        label: 'Cost Center From',
        key: 'FromCenterId',
        isEditable: true,
        sortable: true
      },
      {
        label: 'Status',
        key: 'ToStatusCodeId',
        isEditable: true,
        sortable: true
      },
      {
        label: 'Meter',
        key: 'Meter',
        isEditable: true,
        sortable: true
      },
      {
        label: 'PI',
        key: 'PersonalIssue',
        nested: true,
        nestedKey: 'FromKey',
        type: Boolean,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Owner',
        key: 'OwnerId',
        nested: true,
        isEditable: true,
        nestedKey: 'FromKey',
        sortable: true
      },
      {
        label: 'Cost Center To',
        key: 'ToCenterId',
        isEditable: true,
        sortable: true
      },
      {
        label: 'Cost Code',
        key: 'ToCodeId',
        isEditable: true,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'Id',
        type: 'check'
      },
      {
        label: '',
        key: 'totalQty',
        type: 'warning'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Kit',
            key: 'KitNumber',
            nested: true,
            nestedKey: 'FromKey',
            sortable: true
          },
          {
            label: 'Item',
            key: 'ItemNumber',
            sortable: true
          },
          {
            label: 'Qty',
            key: 'Quantity',
            isEditable: true,
            type: Number,
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            type: String,
            sortable: true
          },
          {
            label: 'Serial Number',
            key: 'SerialNumber',
            type: String,
            sortable: true
          },
          {
            label: 'From',
            key: 'EntityId',
            nested: true,
            isEditable: true,
            nestedKey: 'FromKey',
            sortable: true
          },
          {
            label: 'Cost Center From',
            key: 'FromCenterId',
            isEditable: true,
            sortable: true
          },
          {
            label: 'Status',
            key: 'ToStatusCodeId',
            isEditable: true,
            sortable: true
          },
          {
            label: 'Meter',
            key: 'Meter',
            isEditable: true,
            sortable: true
          },
          {
            label: 'PI',
            key: 'PersonalIssue',
            nested: true,
            nestedKey: 'FromKey',
            type: Boolean,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Owner',
            key: 'OwnerId',
            nested: true,
            isEditable: true,
            nestedKey: 'FromKey',
            sortable: true
          },
          {
            label: 'Cost Center To',
            key: 'ToCenterId',
            isEditable: true,
            sortable: true
          },
          {
            label: 'Cost Code',
            key: 'ToCodeId',
            isEditable: true,
            sortable: true
          }
        ]
      }
    ]
  },
  lineActions: [
    {
      title: 'Remove Line',
      actionName: 'onRemoveLine',
      permissions: ['TransfersExecute'],
      isMultiple: true,
      isDisabled: function (isEditing) {
        return isEditing
      }
    }
  ]
}

export default transfer

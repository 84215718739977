const countTicketTabCountSummaryTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Category',
        key: 'Category',
        sortable: true
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'T',
        key: 'ItemType',
        sortable: true
      },
      {
        label: 'Item Description',
        key: 'ItemDescription',
        sortable: true
      },
      {
        label: 'Manuf',
        key: 'Manufacturer',
        sortable: true
      },
      {
        label: 'Model',
        key: 'Model',
        sortable: true
      },
      {
        label: 'Count',
        key: 'Count',
        sortable: true
      },
      {
        label: 'Bin No.',
        key: 'Bin',
        sortable: true
      },
      {
        label: 'Assignment',
        key: 'Assignment',
        sortable: true
      },
      {
        label: 'Note',
        key: 'Note',
        sortable: true
      },
      {
        label: 'Counted On',
        key: 'CountedOn',
        type: Date,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Counted By',
        key: 'CountedBy',
        sortable: true
      },
      {
        label: 'Owner',
        key: 'Owner',
        sortable: true
      },
      {
        label: 'Kit',
        key: 'KitNumber',
        sortable: true
      },
      {
        label: 'Status',
        key: 'Status',
        sortable: true
      },
      {
        label: 'PI',
        nested: true,
        nestedKey: 'Key',
        key: 'PersonalIssue',
        sortable: true
      },
      {
        label: '',
        key: 'countedItemActionField',
        sortable: false
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'Category',
            sortable: true
          },
          {
            label: 'Item',
            key: 'ItemNumber',
            sortable: true
          },
          {
            label: 'T',
            key: 'ItemType',
            sortable: true
          },
          {
            label: 'Item Description',
            key: 'Item Description',
            sortable: true
          },
          {
            label: 'Manuf',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Model',
            key: 'Model',
            sortable: true
          },
          {
            label: 'Count',
            key: 'Count',
            sortable: true
          },
          {
            label: 'Bin No.',
            key: 'Bin',
            sortable: true
          },
          {
            label: 'Assignment',
            key: 'Assignment',
            sortable: true
          },
          {
            label: 'Note',
            key: 'Note',
            sortable: true
          },
          {
            label: 'Counted On',
            key: 'CountedOn',
            type: Date,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Counted By',
            key: 'CountedBy',
            sortable: true
          },
          {
            label: 'Owner',
            key: 'Owner',
            sortable: true
          },
          {
            label: 'Kit',
            key: 'KitNumber',
            sortable: true
          },
          {
            label: 'Status',
            key: 'Status',
            sortable: true
          },
          {
            label: 'PI',
            nested: true,
            nestedKey: 'Key',
            key: 'PersonalIssue',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: 'countedItemActionField',
        sortable: false
      }
    ]
  }
}

export default countTicketTabCountSummaryTable

import axios from 'axios'
import { deepCopy } from '@/helpers/utils.js'
export default {
  state: () => ({
    reservationCart: {
      StartDate: null,
      EndDate: null,
      Items: []
    },
    isReservationCartLoading: false
  }),
  mutations: {
    storeReservationCart (state, cart) {
      state.reservationCart = cart
    },
    addReservationItem (state, item) {
      const id = item.ModelId
      const $reservationCart = deepCopy(state.reservationCart)
      const index = $reservationCart.Items.findIndex(ele => ele.ModelId === id)
      if (index === -1) {
        $reservationCart.Items.push(item)
      } else {
        $reservationCart.Items[index].Quantity = +$reservationCart.Items[index].Quantity + +item.Quantity
      }
      state.reservationCart = deepCopy($reservationCart)
    },
    removeReservationItem (state, item) {
      state.reservationCart.Items = state.reservationCart.Items.filter(i => i.ModelId !== item.ModelId)
    },
    updateReservationItem (state, item) {
      const foundIndex = state.reservationCart.Items.findIndex(i => i.ModelId == item.ModelId)
    },
    setReservationCartLoading (state, flag) {
      state.isReservationCartLoading = flag
    },
    setReservationStartDate (state, date) {
      state.reservationCart.StartDate = date
    },
    setReservationEndDate (state, date) {
      state.reservationCart.EndDate = date
    }
  },
  actions: {
    addItemToReservationCart (context, item) {
      context.commit('addReservationItem', item)
    },
    removeItemFromReservationCart (context, item) {
      context.commit('removeReservationItem', item)
    },
    async loadReservationCart (context, isApiLoadRequired) {
      const headerConfig = {
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }
      const params = JSON.stringify({})
      const { data } = await axios.post('/api/core/ReservationCartService/GetCart', params, headerConfig)
      context.commit('storeReservationCart', data.Body)
      context.commit('setReservationCartLoading', false)
    },
    async saveReservationCart (context, cart) {
      const headerConfig = {
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }
      const params = {
        cart: JSON.stringify(cart)
      }
      await axios.post('/api/core/ReservationCartService/SaveCart', params, headerConfig)
    },
    updateItemInReservationCart (context, item) {
      context.commit('updateReservationItem', item)
    },
    checkoutWithReservationCart (context, cart) {
      axios.post('/api/core/reservations/', cart)
        .then(() => {
          context.dispatch('loadReservationCart', true)
          context.commit('setLoading', false)
        })
    },
    setReservationCart (context, cart) {
      context.commit('storeReservationCart', cart)
    }
  },
  getters: {
    reservationCart (state) {
      return state.reservationCart
    },
    isReservationCartLoading (state) {
      return state.isReservationCartLoading
    },
    reservationStartDate (state) {
      return state.reservationCart.StartDate
    },
    reservationEndDate (state) {
      return state.reservationCart.EndDate
    }
  }
}

const workflowApprovals = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'WorkflowApprovalTaskId',
        type: 'check'
      },
      {
        label: 'Workflow Number',
        key: 'WorkflowNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Trigger',
        key: 'TriggerName',
        type: String,
        sortable: true
      },
      {
        label: 'Triggered By',
        key: 'TriggeredBy',
        type: String,
        sortable: true
      },
      {
        label: 'Trigger Date',
        key: 'TriggeredOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Status',
        key: 'ApprovalStatusType',
        type: Number,
        sortable: true
      },
      {
        label: 'Disposition',
        key: 'Disposition',
        type: String,
        sortable: true
      },
      {
        label: 'Decision Date',
        key: 'DecisionDate',
        type: Date,
        sortable: true
      },
      {
        label: 'Assigned Approver',
        key: 'AssignedApprover',
        type: String,
        sortable: true
      },
      {
        label: 'Approved By',
        key: 'ApprovalApprovedBy',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'WorkflowApprovalTaskId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Workflow Number',
            key: 'WorkflowNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Trigger',
            key: 'TriggerName',
            type: String,
            sortable: true
          },
          {
            label: 'Triggered By',
            key: 'TriggeredBy',
            type: String,
            sortable: true
          },
          {
            label: 'Trigger Date',
            key: 'TriggeredOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Status',
            key: 'ApprovalStatusType',
            sortable: true
          },
          {
            label: 'Disposition',
            key: 'Disposition',
            type: String,
            sortable: true
          },
          {
            label: 'Decision Date',
            key: 'DecisionDate',
            type: Date,
            sortable: true
          },
          {
            label: 'Assigned Approver',
            key: 'AssignedApprover',
            type: String,
            sortable: true
          },
          {
            label: 'Approved By',
            key: 'ApprovalApprovedBy',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default workflowApprovals

import axios from 'axios'
import { uuid } from 'vue-uuid'
import { deepCopy, uniq } from '../helpers/utils'
import store from '../store/index'

export default {
  state: () => ({
    oldKitContents: [],
    kitContents: [],
    kitHeader: null
  }),
  mutations: {
    addKitContents (state, { data, isInit }) {
      if (isInit) {
        state.oldKitContents = deepCopy(data)
      }
      const array = [...state.kitContents, ...data]
      state.kitContents = array
    },
    removeKitContent (state, itemId) {
      state.kitContents = deepCopy(state.kitContents.filter(kit => kit.itemId !== itemId))
    },
    resetKitContents (state) {
      state.kitContents = []
    },
    setKitHeader (state, header) {
      state.kitHeader = { ...header }
    },
    resetKitHeader (state) {
      state.kitHeader = null
    },
    resetOldKitContents (state) {
      state.oldKitContents = []
    }
  },
  actions: {
    async getKitHeader (context, stockPointId) {
      const paramsNextNumber = JSON.stringify({
        numberType: JSON.stringify(29), // Kits
        requestedNumberSource: JSON.stringify(1) // System
      })
      const { data } = await axios.post('/api/core/NextNumberService/GetNextNumber', paramsNextNumber)
      const obj = {}
      obj.number = data.Body.Number
      obj.notes = null
      obj.barCode = null
      obj.description = null
      obj.descriptionId = null
      obj.categoryId = null
      obj.assignedToId = stockPointId
      obj.masterId = uuid.v4()
      obj.itemType = 'K'
      obj.itemTypeId = 4
      obj.createdById = localStorage.getItem('UserId')
      obj.createdBy = `${store.getters.lastName}, ${store.getters.firstName}`
      obj.modifiedById = localStorage.getItem('UserId')
      obj.modifiedBy = `${store.getters.lastName}, ${store.getters.firstName}`
      obj.orgId = localStorage.getItem('OrgId')
      context.commit('setKitHeader', { ...obj })
    }
  },
  getters: {
    kitContents (state) {
      return state.kitContents
    },
    oldKitContents (state) {
      return state.oldKitContents
    },
    kitHeader (state) {
      return state.kitHeader
    }
  }
}

const folderReports = {
  tableHeader: {
    desktop: [
      {
        label: 'Nombre de la carpeta'
      },
      {
        label: 'Creado por'
      },
      {
        label: 'Creado el'
      },
      {
        label: 'Modificado por'
      },
      {
        label: 'Modificado el'
      },
      {
        label: '',
        key: '',
        sortable: false
      }
    ],
    mobile: [
      {
        label: 'Nombre de la carpeta'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Creado por'
          },
          {
            label: 'Creado el'
          },
          {
            label: 'Modificado por'
          },
          {
            label: 'Modificado el'
          }
        ]
      },
      {
        label: '',
        key: '',
        sortable: false
      }
    ]
  }
}

export default folderReports

const retiredTools = {
  title: 'Herramientas retiradas',
  actionHeader: {
    0: {
      title: 'Reinstalar artículo',
      icon: 'fas fa-undo'
    },
    1: {
      title: 'Ver Detalle',
      icon: 'fas fa-edit'
    },
    2: {
      title: 'Ver historial de transacciones',
      icon: 'fas fa-history'
    },
    3: {
      title: 'Cambiar la fecha de retiro',
      icon: 'fas fa-calendar'
    },
    4: {
      title: 'Borrar',
      icon: 'fas fa-trash'
    }
  },
  browseRetiredTools: {
    title: 'Explorar herramientas retiradas',
    labels: {
      0: 'Artículo No.',
      1: 'Fecha de retiro',
      2: 'a',
      3: 'Categoría',
      4: 'Supervisor',
      5: 'Retirado en',
      6: 'Sólo retirado',
      7: 'Todo',
      8: 'Razón',
      9: 'Estado'
    }
  },
  noSelectRetiredTools: 'No ha seleccionado ninguna herramienta retirada.',
  tableHeader: {
    desktop: [
      {
        label: 'Categoría',
        key: 'Category',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Retirado',
        type: Date,
        key: 'RetiredDate',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Articulo',
        key: 'ItemNumber',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Tipo',
        key: 'ItemType',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Código de barras',
        key: 'BarCode',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Descripción',
        key: 'Description',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Cantidad',
        key: 'Quantity',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Número de serie',
        key: 'SerialNumber',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Razón',
        key: 'Reason',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Estado',
        key: 'StatusCodeDesc',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Retirado por',
        key: 'RetiredBy',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Retirado en',
        key: 'AssignedTo',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Personal responsable',
        key: 'ResponsiblePartyDesc',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Uso personal',
        key: 'PersonalIssue',
        type: Boolean,
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Dueño',
        key: 'Owner',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Gerente',
        key: 'Manager',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Reintegrado a',
        key: 'ReinstatedToDesc',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Reintegrado por',
        key: 'ReinstatedByDesc',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Reintegrado',
        type: Date,
        key: 'ReinstatedDate',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Último escaneado',
        key: 'LastScannedOn',
        sortable: true,
        type: Date
      },
      {
        label: 'Última área de escaneo BLE',
        key: 'LastScanArea',
        sortable: true
      },
      {
        label: 'Última ubicación de escaneo BLE',
        key: 'LastScanLocation',
        type: 'LastScanLocation',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Categoría',
        key: 'Category',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Retirado',
            type: Date,
            key: 'RetiredDate',
            sortable: true
          },
          {
            label: 'Articulo',
            key: 'ItemNumber',
            sortable: true
          },
          {
            label: 'Tipo',
            key: 'ItemType',
            sortable: true
          },
          {
            label: 'Código de barras',
            key: 'BarCode',
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Cantidad',
            key: 'Quantity',
            sortable: true
          },
          {
            label: 'Número de serie',
            key: 'SerialNumber',
            sortable: true
          },
          {
            label: 'Retirado por',
            key: 'RetiredBy',
            sortable: true
          },
          {
            label: 'Retirado en',
            key: 'AssignedTo',
            sortable: true
          },
          {
            label: 'Personal responsable',
            key: 'ResponsiblePartyDesc',
            sortable: true
          },
          {
            label: 'Uso personal',
            key: 'PersonalIssue',
            type: Boolean,
            sortable: true
          },
          {
            label: 'Estado',
            key: 'StatusCodeNumber',
            sortable: true
          },
          {
            label: 'Dueño',
            key: 'Owner',
            sortable: true
          },
          {
            label: 'Gerente',
            key: 'Manager',
            sortable: true
          },
          {
            label: 'Reintegrado a',
            key: 'ReinstatedToDesc',
            sortable: true
          },
          {
            label: 'Reintegrado por',
            key: 'ReinstatedByDesc',
            sortable: true
          },
          {
            label: 'Reintegrado',
            type: Date,
            key: 'ReinstatedDate',
            sortable: true
          },
          {
            label: 'Último escaneado',
            key: 'LastScannedOn',
            sortable: true,
            type: Date
          },
          {
            label: 'Última área de escaneo BLE',
            key: 'LastScanArea',
            sortable: true
          },
          {
            label: 'Última ubicación de escaneo BLE',
            key: 'LastScanLocation',
            sortable: true
          }
        ]
      }
    ]
  },
  reinstateHeader: {
    desktop: [
      {
        label: 'Número',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Cant.',
        key: 'Quantity',
        sortable: true
      },
      {
        label: 'Retirado en',
        key: 'AssignedTo',
        sortable: true
      },
      {
        label: 'Restablecer a',
        key: 'ReinstateToId',
        isEditable: true,
        type: 'Guid',
        listType: 11,
        sortable: true
      },
      {
        label: 'Estado',
        isEditable: true,
        type: 'Guid',
        listType: 15,
        key: 'ItemStatusId',
        sortable: true
      },
      {
        label: 'Cuestión personal',
        key: 'PersonalIssue',
        type: Boolean,
        sortable: true
      },
      {
        label: 'Tipo',
        key: 'ItemType',
        sortable: true
      },
      {
        label: 'Notas',
        key: 'Notes',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Número',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Descripción',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Cant.',
            key: 'Quantity',
            sortable: true
          },
          {
            label: 'Retirado en',
            key: 'AssignedTo',
            sortable: true
          },
          {
            label: 'Restablecer a',
            key: 'ReinstateToId',
            isEditable: true,
            type: 'Guid',
            listType: 11,
            sortable: true
          },
          {
            label: 'Estado',
            isEditable: true,
            type: 'Guid',
            listType: 15,
            key: 'ItemStatusId',
            sortable: true
          },
          {
            label: 'Uso personal',
            key: 'PersonalIssue',
            type: Boolean,
            sortable: true
          },
          {
            label: 'Tipo',
            key: 'ItemType',
            sortable: true
          },
          {
            label: 'Notas',
            key: 'Notes',
            sortable: true
          }
        ]
      }
    ]
  },
  delete: {
    title: 'Continuar?',
    message: 'Eliminar esta herramienta la eliminará de las herramientas retiradas. No lo encontrará en su navegador de herramientas ni en la papelera de reciclaje. ¿Estás seguro de que deseas eliminar esta herramienta?'
  },
  detail: {
    title: 'Detalle de herramienta retirada',
    note: 'Nota',
    toolInformation: {
      title: 'Información de la herramienta',
      labels: {
        toolNumber: 'Número de herramienta',
        barCode: 'Código de barras',
        desc: 'Descripción',
        serialNum: 'Número de serie',
        qty: 'Cant.',
        department: 'Departamento',
        class: 'Clase',
        status: 'Estado',
        meter: 'Medidor'
      }
    },
    originalPurchase: {
      title: 'Compra original',
      labels: {
        poNum: 'PO No.',
        cost: 'Costo',
        date: 'Fecha',
        warranty: 'Garantía'
      }
    },
    modalInformation: {
      title: 'Información del modelo',
      labels: {
        modelNum: 'Número de modelo',
        manufacturer: 'Fabricante',
        model: 'Modelo',
        category: 'Categoría'
      }
    },
    retirementInformation: {
      title: 'Información de retiro',
      labels: {
        retiredBy: 'Retirado por',
        dateRetired: 'Fecha de retiro',
        time: 'Hora',
        reason: 'Razón',
        totalValueRetired: 'Valor total retirado',
        retiredAt: 'Retirado en',
        responsibleParty: 'Fiesta responsable'
      }
    },
    billingInformation: {
      title: 'Datos de facturación',
      labels: {
        costCenter: 'Centro de costos',
        costCode: 'Código de costo',
        amount: 'Monto'
      }
    }
  },
  history: {
    title: 'Historial de transacciones',
    tableHeader: {
      desktop: [
        {
          label: 'Tran. Fecha',
          key: 'TransactionDate',
          type: Date,
          sortable: true
        },
        {
          label: 'Tran. No.',
          key: 'TransferNumber',
          sortable: true
        },
        {
          label: 'Tipo',
          key: 'TransactionType',
          sortable: true
        },
        {
          label: 'Descripción',
          key: 'MasterDescription',
          sortable: true
        },
        {
          label: 'Cant.',
          key: 'Quantity',
          sortable: true
        },
        {
          label: 'Asignado a',
          key: 'AssignedTo',
          sortable: true
        },
        {
          label: 'Fecha de asignación',
          key: 'AssignmentDate',
          type: Date,
          sortable: true
        },
        {
          label: 'Estado',
          key: 'Status',
          sortable: true
        },
        {
          label: 'UP',
          type: Boolean,
          isEditable: false,
          key: 'PersonalIssue',
          sortable: true
        },
        {
          label: 'Dueño',
          key: 'Owner',
          sortable: true
        },
        {
          label: 'Equipo',
          key: 'KitNumber',
          sortable: true
        }
      ],
      mobile: [
        {
          label: 'Tran. Fecha',
          key: 'TransactionDate',
          type: Date,
          sortable: true
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Tran. No.',
              key: 'TransferNumber',
              sortable: true
            },
            {
              label: 'Tipo',
              key: 'TransactionType',
              sortable: true
            },
            {
              label: 'Descripción',
              key: 'MasterDescription',
              sortable: true
            },
            {
              label: 'Cant.',
              key: 'Quantity',
              sortable: true
            },
            {
              label: 'Asignado a',
              key: 'AssignedTo',
              sortable: true
            },
            {
              label: 'Fecha de asignación',
              key: 'AssignmentDate',
              type: Date,
              sortable: true
            },
            {
              label: 'Estado',
              key: 'Status',
              sortable: true
            },
            {
              label: 'UP',
              type: Boolean,
              isEditable: false,
              key: 'PersonalIssue',
              sortable: true
            },
            {
              label: 'Dueño',
              key: 'Owner',
              sortable: true
            },
            {
              label: 'Equipo',
              key: 'KitNumber',
              sortable: true
            }
          ]
        }
      ]
    }
  },
  changeRetirementDate: {
    title: 'Cambiar la fecha de Retiro',
    labels: {
      category: 'Categoría',
      desc: 'Descripción',
      itemNum: 'Número de artículo',
      retiredDate: 'Fecha de Retiro'
    }
  }
}

export default retiredTools

const workflow = {
  workflowBuilder: {
    title: 'Flujo de trabajo del navegador',
    tabs: {
      0: {
        title: 'Nuevo flujo de trabajo'
      },
      1: {
        title: 'Editar flujo de trabajo'
      },
      2: {
        title: 'Flujo de trabajo de copia'
      },
      3: {
        title: 'Eliminar flujo de trabajo'
      },
      4: {
        title: 'Imprimir',
        icon: 'fas fa-print'
      },
      5: {
        title: 'Exportar',
        icon: 'fas fa-file-export'
      }
    },
    messages: {
      workflowStarted: 'Los flujos de trabajo se iniciaron correctamente para el activador - ',
      workflowCheckErrorMsg: 'No hay flujo de trabajo para el activador de creación de herramienta única. Por favor crea al menos uno',
      toolTypeItemCheckErrorMsg: 'No existe ningún registro de tipo de herramienta único para iniciar el flujo de trabajo del tipo de activador de creación de herramienta única'
    }
  },
  workflowBrowser: {
    title: 'Flujos de trabajo activos',
    tabs: {
      0: {
        title: 'Vista'
      },
      1: {
        title: 'Editar',
        key: 'Editar tarea'
      },
      2: {
        title: 'Imprimir'
      },
      3: {
        title: 'Exportar'
      }
    },
    labels: {
      open: 'Abierta',
      closed: 'Cerrado',
      all: 'Todo'
    }
  },
  workflowApprovals: {
    labels: {
      open: 'Abierta',
      closed: 'Cerrado',
      all: 'Todo',
      switcherOff: 'Mis aprobaciones',
      switcherOn: 'Todas las aprobaciones de usuarios',
      placeholder: 'Qué estás buscando?',
      approvalRejectDialogTitle: 'Aprobar/Rechazar?',
      approve: 'Aprobar',
      reject: 'Rechazar',
      disposition: 'Disposición',
      dispositionErrorMsg: 'Por favor ingrese la disposición'
    },
    browserLineActions: [
      {
        title: 'Aprobar',
        actionName: 'onApprove',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Rechazar',
        actionName: 'onReject',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  }
}

export default workflow

const postData = {
  tableHeader: {
    desktop: [
      {
        label: 'Publicar',
        key: 'posting',
        sortable: true
      },
      {
        label: 'Número',
        key: 'costCenterNumber',
        sortable: true
      },
      {
        label: 'Centro de costos',
        key: 'costCenter',
        sortable: true
      },
      {
        label: 'Transacción en espera',
        key: 'transactionHold',
        sortable: true
      },
      {
        label: 'En espera por',
        key: 'createdBy',
        sortable: true
      },
      {
        label: 'En espera en',
        key: 'createdOn',
        sortable: true
      },
      {
        label: 'Fecha de corte',
        key: 'cutOffDate',
        sortable: true
      },
      {
        label: 'Fecha de cálculo',
        key: 'createdOn',
        sortable: true
      },
      {
        label: 'Estado (Herramientas)',
        id: 'ratesheetTools',
        sortable: true
      },
      {
        label: 'Estado (Materiales)',
        id: 'ratesheetMaterials',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Publicar',
        key: 'posting',
        sortable: true
      },
      {
        label: 'Número',
        key: 'costCenterNumber',
        sortable: true
      },
      {
        data: [
          {
            label: 'Centro de costos',
            key: 'costCenter',
            sortable: true
          },
          {
            label: 'Transacción en espera',
            key: 'transactionHold',
            sortable: true
          },
          {
            label: 'En espera por',
            key: 'createdBy',
            sortable: true
          },
          {
            label: 'En espera en',
            key: 'createdOn',
            sortable: true
          },
          {
            label: 'Fecha de corte',
            key: 'cutOffDate',
            sortable: true
          },
          {
            label: 'Fecha de cálculo',
            key: 'createdOn',
            sortable: true
          },
          {
            label: 'Estado (herramientas)',
            id: 'ratesheetTools',
            sortable: true
          },
          {
            label: 'Estado (Materiales)',
            id: 'ratesheetMaterials',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default postData

const billing = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'En espera',
        key: 'Hold',
        type: Boolean,
        sortable: 'true'
      },
      {
        label: 'Centro de costos',
        key: 'CostCenter',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Código de costo',
        key: 'CostCode',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Fecha de inicio de la transacción',
        key: 'StartTime',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Fecha de final de la transacción',
        key: 'EndTime',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Categoría de artículo',
        key: 'Category',
        type: String,
        sortable: 'true'
      },
      {
        label: 'T',
        key: 'ItemType',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Artículo No.',
        key: 'ItemNumber',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Modelo de artículo',
        key: 'Model',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Descripción del Artículo',
        key: 'Description',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Tipo de cargo',
        key: 'BillingEntryType',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Método de cálculo',
        key: 'BillingCalcMethod',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Cantidad de unidad',
        key: 'ChargeEach',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Cant.',
        key: 'Quantity',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Total extendido',
        key: 'ExtendedTotal',
        type: Number,
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'En Espera',
        key: 'Hold',
        type: Boolean,
        sortable: 'true'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Centro de costos',
            key: 'CostCenter',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Código de costo',
            key: 'CostCode',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Fecha de inicio de la transacción',
            key: 'StartTime',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Fecha de final de la transacción',
            key: 'EndTime',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Categoría de artículo',
            key: 'Category',
            type: String,
            sortable: 'true'
          },
          {
            label: 'T',
            key: 'ItemType',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Artículo No.',
            key: 'ItemNumber',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Modelo de artículo',
            key: 'Model',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Descripción del Artículo',
            key: 'Description',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Tipo de cargo',
            key: 'BillingEntryType',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Método de cálculo',
            key: 'BillingCalcMethod',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Cantidad de unidad',
            key: 'ChargeEach',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Cant.',
            key: 'Quantity',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Total extendido',
            key: 'ExtendedTotal',
            type: Number,
            sortable: 'true'
          }
        ]
      }
    ]
  }
}

export default billing

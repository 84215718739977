const workPerformed = {
  tableHeader: {
    desktop: [
      {
        label: 'Tipo',
        key: 'taskType',
        isSortable: true,
        type: String
      },
      {
        label: 'Trabajo',
        key: 'sequenceNumber',
        isSortable: true,
        type: String
      },
      {
        label: 'Descripción de la trabajo',
        key: 'description',
        isSortable: true,
        type: String
      },
      {
        label: 'Est. Horas',
        key: 'estimatedHours',
        isSortable: true,
        type: Number
      },
      {
        label: 'Hrs. Actuales.',
        key: 'actualHours',
        isEditable: true,
        isSortable: true,
        type: Number
      },
      {
        label: 'Realizado por',
        key: 'modifiedBy',
        isSortable: true,
        type: String
      },
      {
        label: 'Fecha de finalización',
        key: 'modifiedOn',
        isSortable: true,
        type: Date
      },
      {
        label: 'Notas',
        key: 'notes',
        isSortable: true,
        type: String
      },
      {
        label: 'Centro de costos',
        key: 'costCenter',
        isSortable: true,
        type: String
      },
      {
        label: 'Código de costos',
        key: 'costCode',
        isSortable: true,
        type: String
      },
      {
        label: 'Clase de servicio',
        key: 'serviceClass',
        isSortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: 'Tipo',
        key: 'taskType',
        isSortable: true,
        type: String
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Tarea',
            key: 'sequenceNumber',
            isSortable: true,
            type: String
          },
          {
            label: 'Descripción de la trabajo',
            key: 'description',
            isSortable: true,
            type: String
          },
          {
            label: 'Est. Horas',
            key: 'estimatedHours',
            isSortable: true,
            type: Number
          },
          {
            label: 'Hrs. Actuales.',
            key: 'actualHours',
            isEditable: true,
            isSortable: true,
            type: Number
          },
          {
            label: 'Realizado por',
            key: 'modifiedBy',
            isSortable: true,
            type: String
          },
          {
            label: 'Fecha de finalización',
            key: 'modifiedOn',
            isSortable: true,
            type: Date
          },
          {
            label: 'Notas',
            key: 'notes',
            isSortable: true,
            type: String
          },
          {
            label: 'Centro de costos',
            key: 'costCenter',
            isSortable: true,
            type: String
          },
          {
            label: 'Código de costo',
            key: 'costCode',
            isSortable: true,
            type: String
          },
          {
            label: 'Clase de servicio',
            key: 'serviceClass',
            isSortable: true,
            type: String
          }
        ]
      }
    ]
  }
}

export default workPerformed

import { render, staticRenderFns } from "./ViewMode.vue?vue&type=template&id=70cb2837&scoped=true"
import script from "./ViewMode.vue?vue&type=script&lang=js"
export * from "./ViewMode.vue?vue&type=script&lang=js"
import style0 from "./ViewMode.vue?vue&type=style&index=0&id=70cb2837&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70cb2837",
  null
  
)

export default component.exports
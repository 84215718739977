const postData = {
  tableHeader: {
    desktop: [
      {
        label: 'Post',
        key: 'posting',
        sortable: true
      },
      {
        label: 'Number',
        key: 'costCenterNumber',
        sortable: true
      },
      {
        label: 'Cost Center',
        key: 'costCenter',
        sortable: true
      },
      {
        label: 'Transaction On Hold',
        key: 'transactionsOnHold',
        sortable: true
      },
      {
        label: 'Locked By',
        key: 'createdBy',
        sortable: true
      },
      {
        label: 'Locked On',
        key: 'createdOn',
        sortable: true
      },
      {
        label: 'Cut-Off Date',
        key: 'cutOffDate',
        sortable: true
      },
      {
        label: 'Calc Date',
        key: 'createdOn',
        sortable: true
      },
      {
        label: 'Status (Tools)',
        id: 'ratesheetTools',
        sortable: true
      },
      {
        label: 'Status (Materials)',
        id: 'ratesheetMaterials',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Post',
        key: 'posting',
        sortable: true
      },
      {
        label: 'Number',
        key: 'costCenterNumber',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Cost Center',
            key: 'costCenter',
            sortable: true
          },
          {
            label: 'Transaction On Hold',
            key: 'transactionsOnHold',
            sortable: true
          },
          {
            label: 'Locked By',
            key: 'createdBy',
            sortable: true
          },
          {
            label: 'Locked On',
            key: 'createdOn',
            sortable: true
          },
          {
            label: 'Cut-Off Date',
            key: 'cutOffDate',
            sortable: true
          },
          {
            label: 'Calc Date',
            key: 'createdOn',
            sortable: true
          },
          {
            label: 'Status (Tools)',
            id: 'ratesheetTools',
            sortable: true
          },
          {
            label: 'Status (Materials)',
            id: 'ratesheetMaterials',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default postData

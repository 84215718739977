const metric = {
  tableHeader: {
    desktop: [
      {
        label: 'Indicator',
        key: 'MetricName',
        sortable: false
      },
      {
        label: 'Period',
        key: 'Period',
        sortable: false
      },
      {
        label: 'Current',
        key: 'Current',
        sortable: false
      },
      {
        label: 'Previous',
        key: 'Previous',
        sortable: false
      },
      {
        label: 'Change',
        key: 'Change',
        sortable: false
      }
    ],
    mobile: ['Indicator', 'Info']
  }
}

export default metric

export default {
  state: () => ({
    settings: {}
  }),
  mutations: {
    setOrgSettings (state, settings) {
      state.settings = settings
    }
  },
  getters: {
    orgSettings (state) {
      return state.settings
    }
  }
}

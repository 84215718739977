const fuelTransactionTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Card Number',
        key: 'CardNumber',
        type: Number,
        sortable: true
      },
      {
        label: 'Transaction Created',
        key: 'TransactionCreated',
        type: Date,
        sortable: true
      },
      {
        label: 'Item Number',
        key: 'ItemNumber',
        type: Number,
        sortable: true
      },
      {
        label: 'Product',
        key: 'Product',
        type: String,
        sortable: true
      },
      {
        label: 'Quantity',
        key: 'Qty',
        type: Number,
        sortable: true
      },
      {
        label: 'Unit of Measure',
        key: 'UOM',
        type: String,
        sortable: true
      },
      {
        label: 'Total Fuel Cost',
        key: 'TotalFuelCost',
        type: String,
        sortable: true
      },
      {
        label: 'City',
        key: 'City',
        type: String,
        sortable: true
      },
      {
        label: 'State',
        key: 'State',
        type: String,
        sortable: true
      },
      {
        label: 'Driver First Name',
        key: 'DriverFirstName',
        type: String,
        sortable: true
      },
      {
        label: 'Driver Last Name',
        key: 'DriverLastName',
        type: String,
        sortable: true
      },
      {
        label: 'Status',
        key: 'Status',
        type: String,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Card Number',
            key: 'CardNumber',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Transaction Created',
            key: 'TransactionCreated',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Item Number',
            key: 'ItemNumber',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Product',
            key: 'Product',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Quantity',
            key: 'Qty',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Unit of Measure',
            key: 'UOM',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Total Fuel Cost',
            key: 'TotalFuelCost',
            type: String,
            sortable: 'true'
          },
          {
            label: 'City',
            key: 'City',
            type: String,
            sortable: 'true'
          },
          {
            label: 'State',
            key: 'State',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Driver First Name',
            key: 'DriverFirstName',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Driver Last Name',
            key: 'DriverLastName',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Status',
            key: 'Status',
            type: String,
            sortable: 'true'
          }
        ]
      }
    ]
  }
}

export default fuelTransactionTable

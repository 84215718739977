const schedulingBrowser = {
  title: 'Navegador de programación',
  tableHeader: {
    desktop: [
      {
        label: 'Estado',
        key: 'state',
        sortable: true
      },
      {
        label: 'Solicitud de programación',
        key: 'schedulingRequest',
        sortable: true
      },
      {
        label: 'Enviado el',
        key: 'submittedOn',
        sortable: true,
        type: Date
      },
      {
        label: 'Fecha de inicio',
        key: 'startDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Fecha final',
        key: 'endDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Categoría',
        key: 'category',
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'model',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true
      },
      {
        label: 'Fabricante',
        key: 'manufacturer',
        sortable: true
      },
      {
        label: 'Cantidad solicitada',
        key: 'quantity',
        sortable: true
      },
      {
        label: 'Cantidad aprobada por PickTicket',
        key: 'pickTicketApprovedQty',
        sortable: true
      },
      {
        label: 'Cantidad aprobada de orden de compra',
        key: 'purchaseOrderApprovedQty',
        sortable: true
      },
      {
        label: 'Ninguna acción aprobada Cantidad',
        key: 'noActionApprovedQty',
        sortable: true
      },
      {
        label: 'Cantidad rechazada',
        key: 'rejectedQty',
        sortable: true
      },
      {
        label: 'Cantidad indecisa',
        key: 'undecidedQty',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Estado',
        key: 'state'
      },
      {
        label: 'Información',
        data: [
          {
            label: 'Solicitud de programación',
            key: 'schedulingRequest',
            sortable: true
          },
          {
            label: 'Enviado el',
            key: 'submittedOn',
            sortable: true,
            type: Date
          },
          {
            label: 'Fecha de inicio',
            key: 'startDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Fecha final',
            key: 'endDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Categoría',
            key: 'category',
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'model',
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'manufacturer',
            sortable: true
          },
          {
            label: 'Cantidad Cantidad',
            key: 'quantity',
            sortable: true
          },
          {
            label: 'Cantidad aprobada por PickTicket',
            key: 'pickTicketApprovedQty',
            sortable: true
          },
          {
            label: 'Cantidad aprobada de orden de compra',
            key: 'purchaseOrderApprovedQty',
            sortable: true
          },
          {
            label: 'Ninguna acción aprobada Cantidad',
            key: 'noActionApprovedQty',
            sortable: true
          },
          {
            label: 'Cantidad rechazada',
            key: 'rejectedQty',
            sortable: true
          },
          {
            label: 'Cantidad indecisa',
            key: 'undecidedQty',
            sortable: true
          }
        ]
      }
    ]
  },
  formLabels: {
    labels: {
      0: 'Solicitud de programación',
      1: 'Modelo',
      2: 'Ir a la cita'
    }
  },
  subActionHeader: {
    1: {
      title: 'Rechazar',
      key: 'Reject',
      isShow: true
    },
    2: {
      title: 'Aprobar',
      key: 'Approve',
      isShow: true
    },
    3: {
      title: 'Atrás',
      icon: 'fas fa-undo',
      isShow: true
    }
  },
  scheduleRequest: 'Solicitud de programación',
  details: {
    labels: {
      0: 'Número de programación',
      1: 'Estado',
      2: 'Envia a',
      3: 'Ubicación',
      4: 'Fecha necesaria',
      5: 'Fecha de regreso'
    }
  },
  approveRejectTableHeader: {
    desktop: [
      {
        label: '',
        key: 'scheduleLineId',
        type: 'check'
      },
      {
        label: 'Solicitada',
        key: 'undecidedQty',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'description',
        type: String,
        sortable: true
      },
      {
        label: 'Fabricante',
        key: 'manufacturer',
        type: String,
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'model',
        type: String,
        sortable: true
      },
      {
        label: 'Genérica',
        key: 'IsGeneric',
        isEditable: true,
        type: Boolean,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'scheduleLineId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Solicitada',
            key: 'undecidedQty',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'description',
            type: String,
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'manufacturer',
            type: String,
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'model',
            type: String,
            sortable: true
          },
          {
            label: 'Genérica',
            key: 'IsGeneric',
            isEditable: true,
            type: Boolean,
            sortable: true
          }
        ]
      }
    ]
  },
  lineActions: [
    {
      title: 'Ver Detalle',
      actionName: 'viewDetail'
    }
  ],
  lineItemActions: [
    {
      title: 'Aprobar',
      actionName: 'approve'
    },
    {
      title: 'Rechazar',
      actionName: 'reject'
    }
  ],
  scheduleApprove: 'Aprobación de programación',
  schedulingApproveOptions: {
    0: 'Aprobar y crear ticket de selección',
    1: 'Aprobar y crear orden de compra',
    2: 'Aprobar sin otra acción'
  },
  rejectMessage: '¿Está seguro de que desea rechazar esta solicitud?',
  rejectTitle: '¿Continuar?',
  rejectSuccess: 'Los elementos de programación seleccionados se rechazaron correctamente.',
  rejectError: 'No se puede rechazar más de la cantidad solicitada.',
  approveSuccess: 'Los elementos de programación seleccionados se aprobaron correctamente.',
  actionMessage: 'Seleccione el elemento antes de realizar la acción Aprobar/Rechazar.'
}

export default schedulingBrowser

const routes = [
  {
    name: 'Paneles',
    route_name: 'Dashboard',
    icon: 'fal fa-chart-line',
    path: '',
    matchPath: ['/', '/tickets/dashboard', '/purchasing/dashboard', '/service/dashboard', '/reports/dashboards'],
    exact: true,
    completed: true,
    released: true,
    wareHouseUser: true,
    backOfficeUser: true,
    jobSiteUser: true
  },
  {
    name: 'Inventario',
    icon: 'fal fa-tools',
    matchPath: [],
    completed: true,
    released: true,
    jobSiteUser: true,
    backOfficeUser: true,
    children: [
      {
        name: 'Catalogo de producto',
        matchPath: ['/catalog/'],
        exact: true,
        guard: ['ToolModelView', 'MaterialModelView'],
        entitlement: 'T_And_E',
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true,
        path: 'catalog'
      },
      {
        name: 'Buscador de Articulos',
        matchPath: ['/items/', '/items?type'],
        guard: ['ToolDetailView', 'MaterialDetailView'],
        entitlement: 'T_And_E',
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true,
        path: 'items'
      },
      {
        name: 'Navegador de Niveles Bajos',
        matchPath: ['low-level'],
        guard: ['LowLevelBrowserView'],
        entitlement: 'M_And_C',
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true,
        path: 'low-level'
      },
      {
        name: 'Almacen de Trabajo',
        matchPath: ['job-stock'],
        guard: ['JobStockView'],
        entitlement: 'ToolsJobStock',
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true,
        path: 'job-stock'
      },
      {
        name: 'Administrador de Herramientas retiradas',
        matchPath: ['retired-tools'],
        guard: ['ManageRetiredToolsView'],
        entitlement: 'T_And_E',
        completed: true,
        released: true,
        jobSiteUser: false,
        wareHouseUser: true,
        backOfficeUser: false,
        path: 'retired-tools'
      }
    ]
  },
  {
    name: 'Movimiento de Inventario',
    icon: 'fal fa-arrow-right-arrow-left',
    matchPath: [],
    completed: true,
    released: false,
    wareHouseUser: true,
    children: [
      {
        name: 'Boleto de Transferencia',
        path: '/tickets/transfers',
        guard: ['TransfersView'],
        matchPath: ['/tickets/transfers'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Boleto de Solicitud',
        path: '/tickets/picks',
        matchPath: ['/tickets/picks'],
        guard: ['PickTicketView'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Navegador de solicitudes',
        path: 'requisition-browser',
        matchPath: ['requisition-browser'],
        guard: ['RequisitionBrowserView'],
        entitlement: 'P_And_R',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Boleto de Conteo',
        path: '/tickets/count-inventory',
        guard: ['CountInventoryView'],
        matchPath: ['/tickets/count-inventory'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Navegador de plantillas',
        path: '/tickets/templates',
        matchPath: ['/tickets/templates'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Solicitudes',
        path: '/requisitions',
        exact: true,
        matchPath: ['/requisitions/'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        jobSiteUser: true
      },
      {
        name: 'Historial de Solicitudes',
        params: 'items',
        path: '/requisitions/requests/items',
        matchPath: ['requisitions/requests'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        jobSiteUser: true
      },
      {
        name: 'Devoluciones',
        path: '/returns',
        exact: true,
        matchPath: ['/returns/'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        coming: false,
        jobSiteUser: true
      },
      {
        name: 'Historial de devoluciones',
        path: '/returns/history',
        matchPath: ['returns/history'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        coming: false,
        jobSiteUser: true
      }
    ]
  },
  {
    name: 'Servicios y Calibracion',
    icon: 'fal fa-list-check',
    matchPath: [],
    completed: true,
    released: false,
    wareHouseUser: true,
    children: [
      {
        name: 'Solicitudes de Servicios',
        path: '/service/requests',
        matchPath: ['/service/requests', '/service/request'],
        guard: ['ServiceBrowserView'],
        entitlement: 'S_And_C',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Ordenes de Trabajo',
        path: '/service/work-orders',
        matchPath: ['/service/work-orders'],
        guard: ['ServiceBrowserView'],
        entitlement: 'S_And_C',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Navegador Horario de Servicios',
        path: '/service/schedule',
        matchPath: ['/service/schedule', '/service/service-schedule'],
        guard: ['ServiceScheduleView'],
        entitlement: 'S_And_C',
        completed: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Gestión de equipos',
    id: 'EquipmentManagement',
    icon: 'equipment-management.png',
    customIcon: true,
    matchPath: [],
    completed: true,
    released: false,
    wareHouseUser: true,
    children: [
      {
        name: 'Explorador de equipos',
        path: '/equipments',
        matchPath: ['/equipments', '/equipments?type'],
        guard: [],
        entitlement: '',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Scheduling',
        matchPath: [],
        completed: true,
        released: true,
        children: [
          {
            name: 'Solicitud de programación',
            route_name: 'Equipment Scheduling Request',
            path: '/equipment/scheduling/schedulingrequest',
            matchPath: ['equipment/scheduling/schedulingrequest'],
            entitlement: '',
            completed: true,
            released: true
          },
          {
            name: 'Navegador de programación',
            route_name: 'Equipment Scheduling Browser',
            path: '/equipment/scheduling/schedulingbrowser',
            matchPath: ['equipment/scheduling/schedulingbrowser'],
            entitlement: '',
            completed: true,
            released: true
          },
          {
            name: 'Calendario de programación',
            route_name: 'Equipment Scheduling Calendar',
            path: '/equipment/scheduling/schedulingcalendar',
            matchPath: ['equipment/scheduling/schedulingcalendar'],
            entitlement: '',
            completed: true,
            released: true
          }
        ]
      },
      {
        name: 'Registro de uso del equipo',
        route_name: 'EquipmentUsageLog',
        path: '/equipment/usagelog',
        matchPath: ['/equipment/usagelog'],
        guard: [],
        entitlement: '',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Solicitud de servicio de equipo',
        path: '/equipment/service-requests',
        matchPath: ['/equipment/service-requests'],
        guard: [],
        entitlement: '',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Explorador de órdenes de trabajo de equipos',
        path: '/equipment/work-orders',
        matchPath: ['/equipment/work-orders'],
        guard: [],
        entitlement: '',
        completed: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Compras',
    icon: 'fa-sharp fal fa-cart-shopping',
    matchPath: [],
    completed: true,
    released: false,
    wareHouseUser: true,
    children: [
      {
        name: 'Navegador de órdenes de compra',
        route_name: 'PurchaseOrders',
        path: '/purchasing/purchase-orders',
        matchPath: ['purchasing/purchase-orders'],
        guard: ['PurchaseOrdersView'],
        entitlement: 'P_And_R',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Navegador de recepción',
        path: '/purchasing/receiving',
        matchPath: ['purchasing/receiving'],
        guard: ['ReceivingTicketsView'],
        entitlement: 'P_And_R',
        completed: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Costo & Facturacion',
    icon: 'fal fa-calculator',
    matchPath: [],
    completed: true,
    released: true,
    jobSiteUser: true,
    backOfficeUser: true,
    children: [
      {
        name: 'Navegador de facturación',
        path: '/billing',
        guard: ['BillingBrowserView'],
        entitlement: 'JC_And_B',
        exact: true,
        matchPath: ['/billing/'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Navegador de facturas',
        path: '/billing/invoice-browser',
        entitlement: 'JC_And_B',
        matchPath: ['invoice-browser'],
        guard: ['BillingBrowserView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Navegador Transaccion de Combustible',
        path: '/fleet/fuel-transaction-browser',
        entitlement: 'JC_And_B',
        matchPath: ['fuel-transaction-browser'],
        guard: ['FuelCardsView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Navegador de hojas de tarifas',
        path: '/billing/ratesheets',
        guard: ['RateSheetsView'],
        entitlement: 'JC_And_B',
        matchPath: ['rateSheets'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Calendario de la Compañia',
        path: '/administration/company-calendar',
        guard: ['CompanyCalendarView'],
        matchPath: ['company-calendar'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Registro de uso de equipos',
        path: '/billing/equipment',
        guard: ['HourlyUsageLogView'],
        entitlement: 'JC_And_B',
        matchPath: ['/billing/equipment'],
        completed: true,
        released: true,
        backOfficeUser: true,
        jobSiteUser: true
      },
      {
        name: 'Exportar listas de plantillas',
        path: '/billing/export-templates',
        guard: ['ExportJobCost&BillingExecute'],
        entitlement: 'JC_And_B',
        matchPath: ['export-templates'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Exportacion de datos',
        guard: ['ExportJobCost&BillingExecute'],
        entitlement: 'JC_And_B',
        path: '/billing/export-data',
        matchPath: ['export-data'],
        completed: true,
        released: true,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Programación',
    icon: 'fal fa-calendar-alt',
    matchPath: ['schedulingPlatformNav'],
    guard: ['SchedulingView'],
    entitlement: 'Scheduling',
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    children: [
      {
        name: 'Area de Programaciones',
        path: '/reservations',
        exact: true,
        matchPath: ['/reservations/'],
        entitlement: 'Reservations',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      },
      {
        name: 'Historial de Programaciones',
        params: 'items',
        path: '/reservations/history/items',
        matchPath: ['/reservations/history/items'],
        entitlement: 'Reservations',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      },
      {
        name: 'Navegador de Programacion',
        path: '/scheduling/scheduling-browser',
        matchPath: ['/scheduling/scheduling-browser'],
        guard: ['SchedulingView'],
        entitlement: 'Scheduling',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true
      },
      {
        name: 'Solicitud de Programacion',
        path: '/scheduling/scheduling-request',
        matchPath: ['/scheduling/scheduling-request'],
        guard: ['SchedulingView'],
        entitlement: 'Scheduling',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true
      },
      {
        name: 'Scheduling Calendar',
        path: '/scheduling/scheduling-calendar',
        matchPath: ['/scheduling/scheduling-calendar'],
        guard: ['SchedulingView'],
        entitlement: 'Scheduling',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Administración de tareas',
    icon: 'fal fa-list-alt',
    matchPath: ['workmanagement'],
    completed: true,
    released: true,
    jobSiteUser: true,
    backOfficeUser: true,
    wareHouseUser: true,
    children: [
      {
        name: 'Tarea Navegador',
        path: '/workflow/Tasks',
        matchPath: ['workflow/task-browser'],
        guard: ['TasksView'],
        entitlement: 'ToolsTasks',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      },
      {
        name: 'Navegador de flujo de trabajo',
        path: '/workflow/workflow-browser',
        matchPath: ['workflow/workflow-browser'],
        guard: ['WorkflowsView'],
        entitlement: 'ToolsWorkflows',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      },
      {
        name: 'Flujos de trabajo activos',
        path: '/workflow/active-workflow',
        matchPath: ['workflow/active-workflow'],
        guard: ['WorkflowsView'],
        entitlement: 'ToolsWorkflows',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Aprobaciones',
        path: '/workflow/workflow-approvals',
        matchPath: ['workflow/workflow-approvals'],
        guard: ['AllApprovalsView'],
        entitlement: 'ToolsApprovals',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Reportes y Graficos',
    icon: 'fal fa-file-chart-pie',
    matchPath: [],
    completed: true,
    released: true,
    wareHouseUser: true,
    backOfficeUser: true,
    children: [
      {
        name: 'Reportes',
        route_name: 'Canned Reports',
        path: '/reports',
        exact: true,
        matchPath: ['/reports/'],
        entitlement: 'Reporting',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Reportes personalizados',
        path: '/reports/custom',
        matchPath: ['reports/custom'],
        guard: ['CustomReportsView'],
        entitlement: 'CustomReports',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Reportes programados',
        path: '/reports/scheduled',
        matchPath: ['reports/scheduled'],
        guard: ['ScheduledReportsView'],
        entitlement: 'CustomReports',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Administrador de Paneles',
        path: '/reports/dashboards',
        matchPath: ['/reports/dashboards'],
        entitlement: 'CustomDashboards',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Perspectivas',
        path: '/insights',
        matchPath: ['insights'],
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Rastreo',
    icon: 'fal fa-map-location-dot',
    matchPath: [],
    completed: true,
    released: true,
    wareHouseUser: true,
    backOfficeUser: true,
    jobSiteUser: true,
    children: [
      {
        name: 'Mapa',
        path: 'location-map',
        matchPath: ['location-map'],
        guard: ['LocationMapView'],
        entitlement: 'ToolsLocationsMap',
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Sensores de Puertos',
        path: '/administration/ble-gateways',
        matchPath: ['administration/ble-gateways'],
        guard: ['WirelessGatewaysView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Etiquetas',
        path: '/administration/ble-tags',
        matchPath: ['administration/ble-tags'],
        guard: ['WirelessTagsView'],
        completed: true,
        released: true,
        backOfficeUser: true
      }
      // {
      //   name: 'Telemetria',
      //   path: '#',
      //   matchPath: ['/future'],
      //   completed: true,
      //   released: true,
      //   backOfficeUser: true
      // }
    ]
  },
  {
    name: 'Administracion',
    icon: 'fal fa-user-gear',
    matchPath: [],
    completed: true,
    released: true,
    backOfficeUser: true,
    children: [
      {
        name: 'Ajustes de la empresa',
        path: '/administration/company-settings',
        guard: ['CompanyDefaultsView'],
        matchPath: ['administration/company-settings'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Administración de Roles',
        path: '/administration/configure-user-roles',
        matchPath: ['administration/configure-user-roles'],
        guard: ['RolesView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Central de documentos',
        path: '/administration/document-central',
        matchPath: ['administration/document-central'],
        guard: ['DocumentCentralView'],
        entitlement: 'ToolsDocumentCentral',
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Integracion',
        matchPath: [],
        completed: true,
        released: true,
        backOfficeUser: true,
        children: [
          {
            name: 'Catálogo de integración',
            route_name: 'Integration Catalog',
            path: '/administration/integration/catalog',
            matchPath: ['administration/integration/catalog'],
            entitlement: 'Integration',
            completed: true,
            released: true
          },
          {
            name: 'Mis Ajustes',
            route_name: 'Integration Settings',
            path: '/administration/integration/settings',
            matchPath: ['administration/integration/settings'],
            entitlement: 'Integration',
            completed: true,
            released: true
          }
        ]
      },
      {
        name: 'Cargador de datos',
        path: '/administration/data-loader',
        guard: ['DataLoaderView'],
        entitlement: 'DataLoader',
        matchPath: ['administration/data-loader'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Campos Personalizados',
        path: '/administration/custom-fields',
        guard: ['CustomFieldsView'],
        entitlement: 'CustomFields',
        matchPath: ['administration/custom-fields'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Listados de la Compañia',
        matchPath: [],
        completed: true,
        released: true,
        backOfficeUser: true,
        children: [
          {
            name: 'Certificaciones',
            guard: ['CertificationsView'],
            path: '/administration/lists/certifications',
            matchPath: ['certifications'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Contratistas',
            guard: ['ContractorsView'],
            path: '/administration/lists/contractors',
            matchPath: ['administration/lists/contractors'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Clientes',
            guard: ['CustomersView'],
            path: '/administration/lists/customers',
            matchPath: ['administration/lists/customers'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Departamentos',
            guard: ['DepartmentsView'],
            path: '/administration/lists/departments',
            matchPath: ['administration/lists/departments'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Descripciones',
            guard: ['DescriptionsView'],
            path: '/administration/lists/descriptions',
            matchPath: ['administration/lists/descriptions'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Empleados',
            guard: ['EmployeesView'],
            path: '/administration/lists/employees',
            matchPath: ['administration/lists/employees'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Tipos de empleados',
            guard: ['EmployeeTypesView'],
            path: '/administration/lists/employee-types',
            matchPath: ['administration/lists/employee-types'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Ubicaciones',
            guard: ['LocationsView'],
            path: '/administration/lists/locations',
            matchPath: ['administration/lists/locations'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Fabricantes',
            guard: ['ManufacturersView'],
            path: '/administration/lists/manufacturers',
            matchPath: ['administration/lists/manufacturers'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Trabajos',
            matchPath: [],
            completed: true,
            released: true,
            backOfficeUser: true,
            children: [
              {
                name: 'Categorías de Tarea',
                guard: ['TasksView'],
                path: '/administration/lists/tasks-categories',
                matchPath: ['administration/lists/tasks-categories'],
                entitlement: 'ToolsTasks',
                completed: true,
                released: true
              },
              {
                name: 'Prioridades de Tarea',
                guard: ['TasksView'],
                path: '/administration/lists/tasks-priorities',
                matchPath: ['administration/lists/tasks-priorities'],
                entitlement: 'ToolsTasks',
                completed: true,
                released: true
              },
              {
                name: 'Estados de Tarea',
                guard: ['TasksView'],
                path: '/administration/lists/tasks-statuses',
                matchPath: ['administration/lists/tasks-statuses'],
                entitlement: 'ToolsTasks',
                completed: true,
                released: true
              }
            ]
          }
        ]
      },
      {
        name: 'Ajustes',
        matchPath: [],
        completed: true,
        released: true,
        backOfficeUser: true,
        children: [
          {
            name: 'Herramientas & Materiales',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Categorías',
                guard: ['CategoriesView'],
                path: '/administration/lists/categories',
                matchPath: ['administration/lists/categories'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Clases',
                guard: ['ClassesView'],
                path: '/administration/lists/classes',
                matchPath: ['administration/lists/classes'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Métodos de envío',
                guard: ['ShippingMethodsView'],
                path: '/administration/lists/shipping-methods',
                matchPath: ['administration/lists/shipping-methods'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Códigos de estado',
                guard: ['StatusCodesView'],
                path: '/administration/lists/status-codes',
                matchPath: ['administration/lists/status-codes'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Unidades de medida',
                guard: ['UnitsofMeasureView'],
                path: '/administration/lists/units-of-measure',
                matchPath: ['administration/lists/units-of-measure'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              }
            ]
          },
          {
            name: 'Envio & Recepcion',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Vendedores',
                guard: ['VendorsView'],
                path: '/administration/lists/vendors',
                matchPath: ['administration/lists/vendors'],
                entitlement: 'P_And_R',
                completed: true,
                released: true
              }
            ]
          },
          {
            name: 'Costo & Facturacion',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Centros de costos',
                guard: ['CostCentersView'],
                path: '/administration/lists/cost-centers',
                matchPath: ['administration/lists/cost-centers'],
                entitlement: 'JC_And_B',
                completed: true,
                released: true
              },
              {
                name: 'Códigos de costo',
                guard: ['CostCodesView'],
                path: '/administration/lists/cost-codes',
                matchPath: ['administration/lists/cost-codes'],
                entitlement: 'JC_And_B',
                completed: true,
                released: true
              }
            ]
          },
          {
            name: 'Servicios',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Clases de servicio',
                path: '/administration/lists/service-classes',
                matchPath: ['administration/lists/service-classes'],
                entitlement: 'S_And_C',
                completed: true,
                released: true
              }
            ]
          },
          {
            name: 'Lista de Utilidades',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Servicio de Combinacion de Datos',
                guard: ['DataMergeUtilityExecute'],
                path: '/administration/lists/data-merge-utility',
                matchPath: ['administration/lists/data-merge-utility'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              }
            ]
          },
          {
            name: 'Equipos',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Tarjetas de combustible',
                guard: ['FuelCardsView'],
                path: '/administration/lists/fuel-cards',
                matchPath: ['administration/lists/Fuel-cards'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Empleados de combustible',
                guard: ['FuelCardsView'],
                path: '/administration/lists/fuel-employees',
                matchPath: ['administration/lists/fuel-employees'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Importar medidor',
                path: '/billing/import-meter',
                matchPath: ['import-meter'],
                completed: true,
                released: true,
                backOfficeUser: true
              }
            ]
          }
        ]
      },
      {
        name: 'Numeración',
        path: '/administration/numbering',
        guard: ['NumberBlocksView'],
        matchPath: ['administration/numbering'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Visibilidad de la lista',
        guard: ['ListVisibilityView'],
        path: '/administration/list-visibility',
        matchPath: ['administration/list-visibility'],
        completed: true,
        released: false,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Papelera de reciclaje',
    icon: 'fal fa-recycle',
    path: 'recycle-bin',
    matchPath: ['recycle-bin'],
    guard: ['RecyclingBinView'],
    completed: true,
    released: true,
    jobSiteUser: false,
    wareHouseUser: true,
    backOfficeUser: true
  },
  {
    name: 'Qué hay de nuevo',
    icon: 'fa-light fa-sparkles',
    path: 'whatsnew',
    matchPath: ['whatsnew'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  },
  {
    name: 'Comentario',
    icon: 'chatbox-elipsis',
    matchPath: ['feedback'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  },
  {
    name: 'Documentación',
    icon: 'fa-light fa-file-circle-question',
    matchPath: ['Documentation'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  },
  {
    name: 'Solicitar Apoyo',
    icon: 'fa-light fa-circle-question',
    matchPath: ['getSupport'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  }
]

export default routes

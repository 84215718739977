export default {
  state: () => ({
    products: []
  }),
  mutations: {
    setProducts (state, results) {
      state.products = results
    }
  },
  getters: {
    getProductById: state => (id) => {
      const selectedProduct = state.products.filter(product => product.ModelId === id)
      return selectedProduct[0]
    }
  }
}

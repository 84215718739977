const materialCategoryItems = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: 'Category',
        key: 'category',
        sortable: true,
        type: String
      },
      {
        label: 'Number',
        key: 'itemNumber',
        sortable: true,
        type: String
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true,
        type: String
      },
      {
        label: 'Model',
        key: 'model',
        sortable: true,
        type: String
      },
      {
        label: 'Qty',
        key: 'quantity',
        sortable: true,
        type: Number
      },
      {
        label: 'Allocated',
        key: 'pickedQuantity',
        sortable: true,
        type: Number,
        flag: 'isShowPickedItemState'
      },
      {
        label: 'Available',
        key: 'available',
        sortable: true,
        type: Number,
        flag: 'isShowPickedItemState'
      },
      {
        label: 'Uom',
        key: 'uom',
        sortable: true,
        type: String
      },
      {
        label: 'Stock Location',
        key: 'assignedTo',
        sortable: true,
        type: String
      },
      {
        label: 'Bin',
        key: 'binId',
        sortable: true,
        type: String
      },
      {
        label: 'Bar Code',
        key: 'barcode',
        sortable: true,
        type: String
      },
      {
        label: 'Notes',
        key: 'notes',
        sortable: true,
        type: String
      },
      {
        label: 'Last Purchase Cost',
        key: 'lastPurchaseCost',
        sortable: true,
        type: Number
      }
    ],
    mobile: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Number',
            key: 'itemNumber',
            sortable: true,
            type: String
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true,
            type: String
          },
          {
            label: 'Model',
            key: 'model',
            sortable: true,
            type: String
          },
          {
            label: 'Qty',
            key: 'quantity',
            sortable: true,
            type: Number
          },
          {
            label: 'Allocated',
            key: 'pickedQuantity',
            sortable: true,
            type: Number,
            flag: 'isShowPickedItemState'
          },
          {
            label: 'Available',
            key: 'available',
            sortable: true,
            type: Number,
            flag: 'isShowPickedItemState'
          },
          {
            label: 'Uom',
            key: 'uom',
            sortable: true,
            type: String
          },
          {
            label: 'Stock Location',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'Bin',
            key: 'binId',
            sortable: true,
            type: String
          },
          {
            label: 'Bar Code',
            key: 'barcode',
            sortable: true,
            type: String
          },
          {
            label: 'Notes',
            key: 'notes',
            sortable: true,
            type: String
          },
          {
            label: 'Last Purchase Cost',
            key: 'lastPurchaseCost',
            sortable: true,
            type: Number
          }
        ]
      }
    ]
  }
}

export default materialCategoryItems

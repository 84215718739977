const rateSheetViewForMaterials = {
  tableHeader: {
    desktop: [
      {
        label: 'Categoría',
        key: 'Category',
        type: String,
        isEditable: false,
        isTool: false,
        sortable: true
      },
      {
        label: 'Descripción del Artículo',
        key: 'Description',
        type: String,
        isEditable: true,
        isTool: false,
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'Model',
        type: String,
        isEditable: false,
        sortable: true
      },
      {
        label: 'ID de modelo',
        key: 'MasterNumber',
        type: Number,
        isEditable: false,
        sortable: true
      },
      {
        label: 'No cargar',
        key: 'DoNotBill',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [0, 1, 2, 3, 4, null],
        isTool: false,
        sortable: true
      },
      {
        label: 'Valor base',
        key: 'BaseCost',
        type: Number,
        isEditable: true,
        isTool: false,
        sortable: true
      },
      {
        label: 'Método de cálculo',
        key: 'BillingCalculationMethodsId',
        type: Number,
        isEditable: true,
        isTool: false,
        sortable: true
      },
      {
        label: 'Carga inicial %',
        key: 'InitialChargePercent',
        baseKey: 'InitialCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Cargo inicial',
        key: 'InitialCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: '% De crédito de devolución',
        key: 'ReturnCreditPercent',
        baseKey: 'ReturnCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Crédito de devolución',
        key: 'ReturnCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Código de costo',
        key: 'CostCodeId',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: false,
        sortable: true
      },
      {
        label: 'Cuenta GL Charge',
        key: 'ChargeAccountNo',
        type: 'text',
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: false,
        sortable: true
      },
      {
        label: 'Cuenta GL Credit',
        key: 'CreditAccountNo',
        type: 'text',
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: false,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            key: 'Category',
            type: String,
            isEditable: false,
            isTool: false,
            sortable: true
          },
          {
            label: 'Descripción del Artículo',
            key: 'Description',
            type: String,
            isEditable: true,
            isTool: false,
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'Model',
            type: String,
            isEditable: false,
            sortable: true
          },
          {
            label: 'ID de modelo',
            key: 'MasterNumber',
            type: Number,
            isEditable: false,
            sortable: true
          },
          {
            label: 'No cargar',
            key: 'DoNotBill',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [0, 1, 2, 3, 4, null],
            isTool: false,
            sortable: true
          },
          {
            label: 'Valor base',
            key: 'BaseCost',
            type: Number,
            isEditable: true,
            isTool: false,
            sortable: true
          },
          {
            label: 'Método de cálculo',
            key: 'BillingCalculationMethodsId',
            type: Number,
            isEditable: true,
            isTool: false,
            sortable: true
          },
          {
            label: 'Carga inicial %',
            key: 'InitialChargePercent',
            baseKey: 'InitialCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Carga inicial',
            key: 'InitialCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: '% De crédito de devolución',
            key: 'ReturnCreditPercent',
            baseKey: 'ReturnCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Crédito de devolución',
            key: 'ReturnCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Código de costo',
            key: 'CostCodeId',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: false,
            sortable: true
          },
          {
            label: 'Cuenta GL Charge',
            key: 'ChargeAccountNo',
            type: 'text',
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: false,
            sortable: true
          },
          {
            label: 'Cuenta GL Credit',
            key: 'CreditAccountNo',
            type: 'text',
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: false,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default rateSheetViewForMaterials

const statusCodes = {
  tableHeader: {
    desktop: [
      {
        label: 'check',
        key: 'statusCodeId',
        isModify: true
      },
      {
        label: 'Code',
        key: 'statusCodeNumber',
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true
      },
      {
        label: 'No Xfer',
        key: 'noTransfer',
        sortable: false,
        isModify: false
      },
      {
        label: 'Service',
        key: 'needsService',
        sortable: false,
        isModify: false
      },
      {
        label: 'Destroyed',
        key: 'destroyed',
        sortable: false,
        isModify: false
      },
      {
        label: 'L&F',
        key: 'sendToLostAndFound',
        sortable: false,
        isModify: false
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'statusCodeId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Code',
            key: 'statusCodeNumber',
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'No Xfer',
            key: 'noTransfer',
            sortable: false,
            isModify: false
          },
          {
            label: 'Service',
            key: 'needsService',
            sortable: false,
            isModify: false
          },
          {
            label: 'Destroyed',
            key: 'destroyed',
            sortable: false,
            isModify: false
          },
          {
            label: 'L&F',
            key: 'sendToLostAndFound',
            sortable: false,
            isModify: false
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default statusCodes

const categories = {
  ERP: 'ERP / Accounting Systems',
  CONSTRUCTION: 'Construction Management',
  MANUFACTURERS: 'Manufacturers',
  FLEET: 'Fleet',
  BLE: 'Wireless',
  IDENTITY: 'Identity'
}

const integration = {
  title: 'Integration Catalog',
  categories: categories,
  integrations: [
    {
      name: 'Vista',
      desc: categories.ERP,
      longDesc: 'Now, ToolWatch Enterprise integrates with Viewpoint Vista, one of the construction industry’s premier software for managing operations and finance.  That means ToolWatch customers who also utilize Viewpoint for its financial benefits can now improve efficiency in operations and finance by integrating Viewpoint Vista with ToolWatch Enterprise. Each application has its benefits, and now you can realize these benefits while saving time and maintaining consistent data.',
      category: categories.ERP,
      logo: 'vista.jpg',
      id: 'A3EE0C5B-AA25-4763-AE27-50737EE51CEC',
      destinations: ['PR Employess', 'JC Jobs', 'JC Phases', 'EM Equipment', 'EM Equipment Locations'],
      relatedLinks: [{
        mapping: '/Vista/DataMapping',
        setup: '/Vista/Setup'
      }]
    },
    {
      name: 'Spectrum',
      desc: categories.ERP,
      longDesc: '',
      category: categories.ERP,
      logo: 'spectrum.png',
      id: 'BFBE365A-7846-4E29-8AAB-CB5D2D853585',
      destinations: ['PR Employess', 'JC Jobs', 'JC Phase Codes', 'EC Equipment'],
      relatedLinks: [{
        mapping: '/Spectrum/DataMapping',
        setup: '/Spectrum/Setup'
      }]
    },
    {
      name: 'Sage 300 CRE',
      desc: categories.ERP,
      longDesc: 'Now, ToolWatch Enterprise integrates with Sage 300 CRE (formerly Timberline), the most widely-used construction management software in the industry. That means ToolWatch customers who also utilize Sage 300 CRE for its financial benefits can now improve efficiency in operations and finance by integrating Sage 300 CRE with ToolWatch Enterprise. Each application has its benefits, and now you can realize these benefits while saving time and maintaining consistent data.',
      category: categories.ERP,
      logo: 'sage-300.jpg',
      id: 'DBB358BC-0CB8-403E-8555-9BB605083A09',
      destinations: ['PR Employess', 'JC Jobs', 'JC Extras', 'JC Cost Code', 'EQM Master Equipment'],
      relatedLinks: [{
        mapping: '/Sage300CRE/DataMapping',
        setup: '/Sage300CRE/Setup'
      }]
    },
    {
      name: 'Sage 100 Contractor',
      desc: categories.ERP,
      longDesc: '',
      category: categories.ERP,
      logo: 'sage-100.png',
      id: '074ADCA3-AE25-46D4-9763-471CBC0A9507',
      destinations: ['Employess', 'Jobs', 'Phaes'],
      relatedLinks: [{
        mapping: '/Sage100c/DataMapping',
        setup: '/Sage100c/Setup'
      }]
    },
    {
      name: 'eCMS',
      desc: categories.ERP,
      longDesc: 'Now, ToolWatch Enterprise integrates with eCMS from Computer Guidance Corporation. CGC offers an all‐inclusive suite of financial and project management app for contractors.  ToolWatch customers, who additionally use Computer Guidance Corporation eCMS application, can take advantage of a completely integrated ERP, DB2 SQL relational database, browser‐based and mobile friendly dashboards, alerts, and a Cloud deployment option. Our experience in building, implementing and supporting ERP integrations allows companies to immediately have access to business‐critical data.',
      category: categories.ERP,
      logo: 'ecms.png',
      id: 'AB0930F3-C729-4D63-BE56-2AA651B9198B',
      destinations: ['PR Employess', 'JC Jobs', 'JC Phase Codes', 'Equipment'],
      relatedLinks: [{
        mapping: '/eCMS/DataMapping',
        setup: '/eCMS/Setup'
      }]
    },
    {
      name: 'Intacct',
      desc: categories.ERP,
      longDesc: '',
      category: categories.ERP,
      logo: 'intacct.jpg',
      id: 'EAC589CB-93AF-4898-8A7D-D42605489835',
      destinations: ['Employess', 'Projects', 'Project Tasks', 'Standard Tasks'],
      relatedLinks: [{
        mapping: '/Intacct/DataMapping',
        setup: '/Intacct/Setup'
      }]
    },
    {
      name: 'CMiC',
      desc: categories.ERP,
      longDesc: 'Now, ToolWatch Enterprise integrates with CMiC, purposely built for construction companies and capital projects. CMiC increases productivity, reduces risk and accelerates growth by managing your companies financials, projects, resources, and assets — all from a single database.  ToolWatch customers who also employ CMiC can take advantage of its drawing management abilities, synchronizing between your architects and engineers, and across all project site teams. You can also manage change orders by accessing any project detail and comprehend the overall effect to budget letting you make modifications instantaneously. Our experience in building, implementing and supporting ERP integrations allows companies to immediately have access to business‐critical data.',
      category: categories.ERP,
      logo: 'cmic.png',
      id: '394A47F0-640D-40A6-BA2A-7A4FDDE0BDE9',
      destinations: ['PY Employess', 'JC Jobs', 'JC Phase Codes', 'EM Equipment'],
      relatedLinks: [{
        mapping: '/CMIC/DataMapping',
        setup: '/CMIC/Setup'
      }]
    },
    {
      name: 'Procore',
      desc: categories.CONSTRUCTION,
      longDesc: 'Now, ToolWatch integrates with Procore, the industry-leading provider of construction management software. The productivity-enhancing integration improves internal operations to drive productivity throughout construction companies. The collaboration allows organizations to automate and merge enterprise data collected through ToolWatch and Procore.  ToolWatch customers who also employ Procore can take advantage of synchronizing their daily log entries within the Procore platform, including tool and equipment utilization. Additionally, new project information and employee profiles created in Procore will appear in ToolWatch Enterprise. Streamlining this data in real-time allows companies to gain greater insights into projects, manage logistics and make strategic decisions with more accuracy. And they’ll have full access to the ToolWatch Job Site Portal – a dedicated web-based platform for each project in your company.',
      category: categories.CONSTRUCTION,
      logo: 'procore.jpg',
      id: '250C2CA2-5626-4E55-A8E6-2CC3E6BC744B',
      destinations: ['People', 'Projects', 'Equipment Log'],
      relatedLinks: [{
        mapping: '/Procore/DataMapping',
        setup: '/Procore/Setup'
      }]
    },
    {
      name: 'DEWALT (Product Catalog)',
      desc: categories.MANUFACTURERS,
      longDesc: '',
      category: categories.MANUFACTURERS,
      logo: 'dewalt.png',
      id: '',
      destinations: ['Gateway', 'Tag', 'Embedded Tag', 'Assignment'],
      relatedLinks: [{
        mapping: '/DEWALT/DataMapping',
        setup: '/DEWALT/Setup'
      }]
    },
    {
      name: 'GPS Insight',
      desc: categories.FLEET,
      longDesc: '',
      category: categories.FLEET,
      logo: 'gps-insight.jpg',
      id: 'C9734889-EE72-4CFA-B9AE-40866C4829DE',
      destinations: ['Vehicles', 'Odometer Readings', 'Vehicle Trip'],
      relatedLinks: [{
        mapping: '/GpsInsight/DataMapping',
        setup: '/GpsInsight/Setup'
      }]
    },
    {
      name: 'DEWALT (Site Manager)',
      desc: categories.BLE,
      longDesc: '',
      category: categories.BLE,
      logo: 'dewalt-site-manager.jpg',
      id: '783569A7-41FA-484E-85E7-A3CAD6C9B604',
      destinations: ['Gateway', 'Tag', 'Embedded Tag', 'Assignment'],
      relatedLinks: [{
        mapping: '/DEWALT/DataMapping',
        setup: '/DEWALT/Setup'
      }]
    },
    {
      name: 'Active AD',
      desc: categories.IDENTITY,
      longDesc: '',
      category: categories.IDENTITY,
      logo: 'active-directory.png',
      id: '',
      destinations: [],
      relatedLinks: [{
        mapping: '',
        setup: '/SingleSignOn/SamlSetup'
      }]
    },
    {
      name: 'OneLogin',
      desc: categories.IDENTITY,
      longDesc: '',
      category: categories.IDENTITY,
      logo: 'one-login.png',
      id: '',
      destinations: [],
      relatedLinks: [{
        mapping: '',
        setup: '/SingleSignOn/SamlSetup'
      }]
    },
    {
      name: 'Auth0',
      desc: categories.IDENTITY,
      longDesc: '',
      category: categories.IDENTITY,
      logo: 'auth0.png',
      id: '',
      destinations: [],
      relatedLinks: [{
        mapping: '',
        setup: '/SingleSignOn/SamlSetup'
      }]
    },
    {
      name: 'SAML 2.0',
      desc: categories.IDENTITY,
      longDesc: '',
      category: categories.IDENTITY,
      logo: 'saml2.png',
      id: '',
      destinations: [],
      relatedLinks: [{
        mapping: '',
        setup: '/SingleSignOn/SamlSetup'
      }]
    }
  ]
}

export default integration

const stockList = {
  tableHeader: {
    desktop: [
      {
        label: 'Stock Locations',
        key: 'location',
        sortable: true
      },
      {
        label: 'In Stock',
        key: 'inStock',
        sortable: true
      },
      {
        label: 'Low',
        key: 'lowLevel',
        sortable: true,
        editable: true
      },
      {
        label: 'Target',
        key: 'targetLevel',
        sortable: true,
        editable: true
      },
      {
        label: 'High',
        key: 'highLevel',
        sortable: true,
        editable: true
      },
      {
        label: 'Bin',
        key: 'bin',
        sortable: true,
        editable: true,
        isText: true
      }
    ],
    mobile: [
      {
        label: 'Stock Locations',
        key: 'location',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'In Stock',
            key: 'inStock',
            sortable: true
          },
          {
            label: 'Low',
            key: 'lowLevel',
            sortable: true,
            editable: true
          },
          {
            label: 'Target',
            key: 'targetLevel',
            sortable: true,
            editable: true
          },
          {
            label: 'High',
            key: 'highLevel',
            sortable: true,
            editable: true
          },
          {
            label: 'Bin',
            key: 'bin',
            sortable: true,
            editable: true,
            isText: true
          }
        ]
      }
    ]
  }
}

export default stockList

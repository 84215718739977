const routes = [
  {
    name: 'Paneles',
    path: '/ehs',
    icon: 'fa-light fa-chart-line-up',
    matchPath: ['/ehs/dashboards'],
    completed: true,
    released: true
  },
  {
    name: 'Modulos EHS',
    icon: 'fa-light fa-square-plus',
    matchPath: [],
    completed: true,
    released: true,
    children: [
      {
        name: 'Modulo web de Inspeccion',
        path: '/ehs/modules/inspection-web-module',
        matchPath: ['ehs/modules/inspection-web-module'],
        completed: true,
        released: true
      },
      {
        name: 'Modulo Web de Incidentes',
        path: '/ehs/modules/incidents-web-module',
        matchPath: ['ehs/modules/incidents-web-module'],
        completed: true,
        released: true
      },
      {
        name: 'Modulo web de Observaciones',
        path: '/ehs/modules/observations-web-module',
        matchPath: ['ehs/modules/observations-web-module'],
        completed: true,
        released: true
      },
      {
        name: 'Modulo web de JSA',
        path: '/ehs/modules/jsa-web-module',
        matchPath: ['ehs/modules/jsa-web-module'],
        completed: true,
        released: true
      },
      {
        name: 'Modulo web de Seguridad',
        path: '/ehs/modules/safety-scan-web-module',
        matchPath: ['ehs/modules/safety-scan-web-module'],
        completed: true,
        released: true
      },
      {
        name: 'Modulo web de Formas',
        path: '/ehs/modules/forms-web-module',
        matchPath: ['ehs/modules/forms-web-module'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Inspecciones',
    icon: 'fa-light fa-clipboard-list-check',
    matchPath: [],
    guard: ['InspectionsView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Inspeccion de Mantenimiento',
        path: '/ehs/inspections/inspection-maintenance',
        matchPath: ['ehs/inspections/inspection-maintenance'],
        guard: ['InspectionMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Mantenimiento de Area de Trabajo',
        path: '/ehs/inspections/job-bank-maintenance',
        matchPath: ['ehs/inspections/job-bank-maintenance'],
        guard: ['JobBankMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Mantenimineto de Lista de Verification Estandart',
        path: '/ehs/inspections/standard-checklist-maintenance',
        matchPath: ['ehs/inspections/standard-checklist-maintenance'],
        guard: ['StandardChecklistMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Mantenimiento de Lista de verificacion Personalizada',
        path: '/ehs/inspections/custom-checklist-maintenance',
        matchPath: ['ehs/inspections/custom-checklist-maintenance'],
        guard: ['CustomChecklistMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Seguimiento de Acciones Correctivas',
        path: '/ehs/inspections/corrective-action-tracking',
        matchPath: ['ehs/inspections/corrective-action-tracking'],
        guard: ['CorrectiveActionTrackingView'],
        completed: true,
        released: true
      },
      {
        name: 'Sessiones Remotas',
        path: '/ehs/inspections/remote-sessions',
        matchPath: ['ehs/inspections/remote-sessions'],
        guard: ['RemoteSessionsView'],
        // entitlement: 'Entitlement_Safety_Tier_VSR_Sessions',
        completed: true,
        released: true
      },
      {
        name: 'Ajustes de Inspecciones',
        path: '/ehs/inspections/inspection-settings',
        matchPath: ['ehs/inspections/inspection-settings'],
        guard: ['InspectionSettingsView'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Entrenamiento',
    icon: 'fa-light fa-person-chalkboard',
    matchPath: [],
    guard: ['TrainingView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Eventos de Entrenamiento',
        path: '/ehs/training/training-events',
        matchPath: ['ehs/training/training-events'],
        guard: ['TrainingEventsView'],
        completed: true,
        released: true
      },
      {
        name: 'Seguimiento de Entrenamientos',
        path: '/ehs/training/training-tracking',
        matchPath: ['ehs/training/training-tracking'],
        guard: ['TrainingTrackingView'],
        completed: true,
        released: true
      },
      {
        name: 'Charlas de Caja de Herramientas',
        path: '/ehs/training/tool-box-talks',
        matchPath: ['ehs/training/tool-box-talks'],
        guard: ['ToolBoxTalksView'],
        completed: true,
        released: true
      },
      {
        name: 'Historial de Entrenamiento',
        path: '/ehs/training/training-history',
        matchPath: ['ehs/training/training-history'],
        guard: ['TrainingHistoryView'],
        completed: true,
        released: true
      },
      {
        name: 'Entrenamiento Requeridos',
        path: '/ehs/training/required-trainings',
        matchPath: ['ehs/training/required-trainings'],
        guard: ['RequiredTrainingsView'],
        completed: true,
        released: true
      },
      {
        name: 'Evaluaciones de Entrenamiento',
        path: '/ehs/training/training-exam',
        matchPath: ['ehs/training/training-exam'],
        guard: ['TrainingExamView'],
        // entitlement: 'Entitlement_Safety_Tier_Training_Exams',
        completed: true,
        released: true
      },
      {
        name: 'Confirmacion de entrenamiento',
        path: '/ehs/training/training-confirmation',
        matchPath: ['ehs/training/training-confirmation'],
        guard: ['TrainingConfirmationView'],
        completed: true,
        released: true
      },
      {
        name: 'Ajustes de Entrenamiento',
        path: '/ehs/training/training-settings',
        matchPath: ['ehs/training/training-settings'],
        guard: ['TrainingSettingsView'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Incidentes',
    icon: 'fa-light fa-triangle-exclamation',
    matchPath: [],
    guard: ['IncidentsView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Mantenimiento de Incidentes',
        path: '/ehs/incidents/incident-maintenance',
        matchPath: ['ehs/incidents/incident-maintenance'],
        guard: ['IncidentMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'OSHA 301',
        path: '/ehs/incidents/osha-301',
        matchPath: ['ehs/incidents/osha-301'],
        guard: ['OSHA301View'],
        completed: true,
        released: true
      },
      {
        name: 'Registro OSHA 300 ',
        path: '/ehs/incidents/osha-300-log',
        matchPath: ['ehs/incidents/osha-300-log'],
        guard: ['OSHA300LogView'],
        completed: true,
        released: true
      },
      {
        name: 'Comportamiento de Mantenimiento',
        path: '/ehs/incidents/behavior-maintenance',
        matchPath: ['ehs/incidents/behavior-maintenance'],
        guard: ['BehaviorMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Condicion del Mantenimiento',
        path: '/ehs/incidents/conditions-maintenance',
        matchPath: ['ehs/incidents/conditions-maintenance'],
        guard: ['ConditionsMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Entidad de Mantenimiento',
        path: '/ehs/incidents/entity-maintenance',
        matchPath: ['ehs/incidents/entity-maintenance'],
        guard: ['EntityMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Ajustes de Incidentes',
        path: '/ehs/incidents/incident-settings',
        matchPath: ['ehs/incidents/incident-settings'],
        guard: ['IncidentSettingsView'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Observaciones',
    icon: 'fa-light fa-user-magnifying-glass',
    matchPath: [],
    guard: ['ObservationsView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Mantenimiento de Observaciones',
        path: '/ehs/observations/observation-maintenance',
        matchPath: ['ehs/observations/observation-maintenance'],
        guard: ['ObservationMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Categoria de Observaciones',
        path: '/ehs/observations/category-maintenance',
        matchPath: ['ehs/observations/category-maintenance'],
        guard: ['CategoryMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Factor Contribuyente al Mantenimiento',
        path: '/ehs/observations/contributing-factor-maintenance',
        matchPath: ['ehs/observations/contributing-factor-maintenance'],
        guard: ['ContributingFactorMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Ajustes de Observaciones',
        path: '/ehs/observations/observation-settings',
        matchPath: ['ehs/observations/observation-settings'],
        guard: ['ObservationSettingsView'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Analisis de Seguridad de Trabajo (JSA)',
    icon: 'fa-light fa-list-tree',
    matchPath: [],
    guard: ['JobSafetyAnalysisView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Mantenimiento JSA',
        path: '/ehs/job-safety-analysis/jsa-maintenance',
        matchPath: ['ehs/job-safety-analysis/jsa-maintenance'],
        guard: ['JSAMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Banco Principal de JSA',
        path: '/ehs/job-safety-analysis/jsa-bank-maintenance',
        matchPath: ['ehs/job-safety-analysis/jsa-bank-maintenance'],
        guard: ['JSABankMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Mantenimiento de Riesgos',
        path: '/ehs/job-safety-analysis/hazards-maintenance',
        matchPath: ['ehs/job-safety-analysis/hazards-maintenance'],
        guard: ['HazardsMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Mantenimiento de Controles',
        path: '/ehs/job-safety-analysis/controls-maintenance',
        matchPath: ['ehs/job-safety-analysis/controls-maintenance'],
        guard: ['ControlsMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Mantenimiento de Clasificaciones',
        path: '/ehs/job-safety-analysis/classification-maintenance',
        matchPath: ['ehs/job-safety-analysis/classification-maintenance'],
        guard: ['ClassificationMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Mantenimineto de PPE',
        path: '/ehs/job-safety-analysis/ppe-maintenance',
        matchPath: ['ehs/job-safety-analysis/ppe-maintenance'],
        guard: ['PPEMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Mantenimiento de Entrenamiento Requerido',
        path: '/ehs/job-safety-analysis/required-training-maintenance',
        matchPath: ['ehs/job-safety-analysis/required-training-maintenance'],
        guard: ['RequiredTrainingMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Mantenimiento Quimico',
        path: '/ehs/job-safety-analysis/chemical-maintenance',
        matchPath: ['ehs/job-safety-analysis/chemical-maintenance'],
        guard: ['ChemicalMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Ajustes JSA',
        path: '/ehs/job-safety-analysis/jsa-settings',
        matchPath: ['ehs/job-safety-analysis/jsa-settings'],
        guard: ['JSASettingsView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Escaneo de Seguridad',
    icon: 'fa-light fa-qrcode',
    matchPath: [],
    guard: ['SafetyScanView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Activos',
        path: '/ehs/safety-scan/assets',
        matchPath: ['/ehs/safety-scan/assets'],
        guard: ['AssetsView'],
        exact: true,
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Tipos de Activos',
        path: '/ehs/safety-scan/assets-types',
        matchPath: ['ehs/safety-scan/assets-types'],
        guard: ['AssetTypesView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Lista de Verificacion',
        path: '/ehs/safety-scan/checklists',
        matchPath: ['ehs/safety-scan/checklists'],
        guard: ['ChecklistsView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Generar Codigos QR',
        path: '/ehs/safety-scan/generate-qr-codess',
        matchPath: ['ehs/safety-scan/generate-qr-codes'],
        guard: ['GenerateQRCodesView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Descripcion General',
        path: '/ehs/safety-scan/overview',
        matchPath: ['ehs/safety-scan/overview'],
        guard: ['OverviewView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Seguimimiento de Acciones Correctivas',
        route_name: 'Corrective Action Tracking-Safety Scan',
        path: '/ehs/safety-scan/corrective-action-tracking',
        matchPath: ['ehs/safety-scan/corrective-action-tracking'],
        guard: ['ScanCorrectiveActionTrackingView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Rutas',
        path: '/ehs/safety-scan/routes',
        matchPath: ['ehs/safety-scan/routes'],
        guard: ['RoutesView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Localizaciones',
        route_name: 'Locations-Safety Scan',
        path: '/ehs/safety-scan/locations',
        matchPath: ['ehs/safety-scan/locations'],
        guard: ['EHSLocationsView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Edificio, Nivel & Area',
        path: '/ehs/safety-scan/building-floor-area',
        matchPath: ['ehs/safety-scan/building-floor-area'],
        guard: ['Building,Floor&AreaView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Zona',
        path: '/ehs/safety-scan/zone',
        matchPath: ['ehs/safety-scan/zone'],
        guard: ['ZoneView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Division',
        path: '/ehs/safety-scan/division',
        matchPath: ['ehs/safety-scan/division'],
        guard: ['DivisionView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Ajustes Scaneo de Seguridad',
        path: '/ehs/safety-scan/safety-scan-settings',
        matchPath: ['ehs/safety-scan/safety-scan-settings'],
        guard: ['SafetyScanSettingsView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Formas',
    icon: 'fa-light fa-memo-circle-check',
    matchPath: [],
    guard: ['FormsView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Mantenimineto de Formas',
        path: '/ehs/forms/forms-maintenance',
        matchPath: ['ehs/forms/forms-maintenance'],
        guard: ['FormsMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
        completed: true,
        released: true
      },
      {
        name: 'Formas Completadas',
        path: '/ehs/forms/completed-forms',
        matchPath: ['ehs/forms/completed-forms'],
        guard: ['CompletedFormsView'],
        // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
        completed: true,
        released: true
      },
      {
        name: 'Acción correctiva',
        path: '/ehs/forms/corrective-action',
        matchPath: ['ehs/forms/corrective-action'],
        guard: ['CorrectiveActionTrackingView'],
        // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
        completed: true,
        released: true
      },
      {
        name: 'Ajustes de Formas',
        path: '/ehs/forms/forms-settings',
        matchPath: ['ehs/forms/forms-settings'],
        guard: ['FormsSettingsView'],
        // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Reportes y Graficos',
    icon: 'fa-light fa-file-chart-pie',
    matchPath: [],
    guard: ['EHSReports&GraphsView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Reportes de Desempeño',
        route_name: 'Inspection Performance Reports',
        path: '/ehs/reports-graphs/performance-reports/inspection-performance-reports',
        matchPath: ['ehs/reports-graphs/performance-reports/'],
        guard: ['PerformanceReportsView'],
        completed: true,
        released: true
      },
      {
        name: 'Reportes Programados',
        route_name: 'Inspection Scheduled Reports',
        path: '/ehs/reports-graphs/scheduled-reports/inspection-scheduled-reports',
        matchPath: ['ehs/reports-graphs/scheduled-reports/'],
        guard: ['EHSScheduledReportsView'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Administracion',
    icon: 'fa-light fa-user-gear',
    matchPath: [],
    completed: true,
    released: true,
    children: [
      {
        name: 'Configuracion de Cuenta',
        path: '/ehs/administration/account-settings',
        matchPath: ['ehs/administration/account-settings'],
        guard: ['AccountSettingsView'],
        completed: true,
        released: true
      },
      {
        name: 'Informacion de Facturacion',
        path: '/ehs/administration/billing-information',
        matchPath: ['ehs/administration/billing-information'],
        guard: ['BillingInformationView'],
        completed: true,
        released: true
      },
      {
        name: 'Configuracion de Roles de Usuarios',
        route_name: 'Configure User Roles-EHS',
        path: 'ehs/administration/configure-user-roles',
        matchPath: ['ehs/administration/configure-user-roles'],
        guard: ['RolesView'],
        completed: true,
        released: true
      },
      {
        name: 'Central de Documentos',
        route_name: 'Document Central-EHS',
        path: 'ehs/administration/document-central',
        matchPath: ['ehs/administration/document-central'],
        guard: ['DocumentCentralView'],
        completed: true,
        released: true
      },
      {
        name: 'Planes de Seguridad del Area',
        route_name: 'Site Safety Plans-EHS',
        path: 'ehs/administration/safety-plan',
        matchPath: ['ehs/administration/safety-plan'],
        guard: ['SiteSafetyPlansView'],
        completed: true,
        released: true
      },
      {
        name: 'Usuarias',
        route_name: 'Users-EHS',
        path: '/ehs/administration/users',
        matchPath: ['ehs/administration/users'],
        guard: ['SiteSafetyPlansView'],
        completed: true,
        released: true
      },
      {
        name: 'Integracion',
        matchPath: [],
        completed: true,
        released: true,
        children: [
          {
            name: 'Integracion Procore',
            path: '/ehs/integration/procore-integration',
            matchPath: ['ehs/integration/procore-integration'],
            guard: ['ProcoreIntegrationView'],
            // entitlement: 'Entitlement_Safety_Tier_Integrations',
            completed: true,
            released: true
          },
          {
            name: 'Integracion Plangrid',
            path: '/ehs/integration/plangrid-integration',
            matchPath: ['ehs/integration/plangrid-integration'],
            guard: ['PlangridIntegrationView'],
            // entitlement: 'Entitlement_Safety_Tier_Integrations',
            completed: true,
            released: true
          }
        ]
      },
      {
        name: 'Listas de empresas',
        matchPath: [],
        guard: ['ListMaintenanceView'],
        completed: true,
        released: true,
        children: [
          {
            name: 'Certificaciones',
            route_name: 'Certifications-EHS',
            guard: ['CertificationsView'],
            path: '/ehs/company-lists/certifications',
            matchPath: ['ehs/company-lists/certifications'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Contratistas',
            route_name: 'Contractors-EHS',
            guard: ['ContractorsView'],
            path: '/ehs/company-lists/contractors',
            matchPath: ['ehs/company-lists/contractors'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Clientes',
            route_name: 'Customers-EHS',
            guard: ['CustomersView'],
            path: '/ehs/company-lists/customers',
            matchPath: ['ehs/company-lists/customers'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Departamentos',
            route_name: 'Departments-EHS',
            guard: ['DepartmentsView'],
            path: '/ehs/company-lists/departments',
            matchPath: ['ehs/company-lists/departments'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Descripciones',
            route_name: 'Descriptions-EHS',
            guard: ['DescriptionsView'],
            path: '/ehs/company-lists/descriptions',
            matchPath: ['ehs/company-lists/descriptions'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Empleados',
            route_name: 'Employees-EHS',
            guard: ['EmployeesView'],
            path: '/ehs/company-lists/employees',
            matchPath: ['ehs/company-lists/employees'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Tipos de Empleados',
            route_name: 'Employee Types-EHS',
            guard: ['EmployeeTypesView'],
            path: '/ehs/company-lists/employee-types',
            matchPath: ['ehs/company-lists/employee-types'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Ubicaciónes',
            route_name: 'Locations-EHS',
            guard: ['LocationsView'],
            path: '/ehs/company-lists/locations',
            matchPath: ['ehs/company-lists/locations'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Fabricantes',
            route_name: 'Manufacturers-EHS',
            guard: ['ManufacturersView'],
            path: '/ehs/company-lists/manufacturers',
            matchPath: ['ehs/company-lists/manufacturers'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          }
        ]
      },
      {
        name: 'Ajustes EHS',
        icon: 'fa-light fa-circle-question',
        matchPath: [],
        guard: ['EHSSettingsView'],
        completed: true,
        released: true,
        children: [
          {
            name: 'Mantenimineto Licencias EHS',
            route_name: 'Inspection License Maintenance',
            path: '/ehs/ehs-settings/ehs-license-maintenance/inspection-license-maintenance',
            matchPath: ['ehs/ehs-settings/ehs-license-maintenance'],
            guard: ['EHSLicenseMaintenanceView'],
            completed: true,
            released: true
          },
          {
            name: 'Mantenimiento de La Compañia',
            path: '/ehs/ehs-settings/company-maintenance/certifications',
            matchPath: ['ehs/ehs-settings/company-maintenance'],
            guard: ['CompanyMaintenanceView'],
            completed: true,
            released: true
          },
          {
            name: 'Mantenimiento de Localizaciones',
            route_name: 'Observation Location Maintenance',
            path: '/ehs/ehs-settings/location-maintenance/observation-location-maintenance',
            matchPath: ['ehs/ehs-settings/location-maintenance'],
            guard: ['LocationMaintenanceView'],
            completed: true,
            released: true
          },
          {
            name: 'Mantenimiento Personal Responsable',
            route_name: 'Inspection RP Maintenance',
            path: '/ehs/ehs-settings/responsible-party-maintenance/inspection-pr-maintenance',
            matchPath: ['ehs/ehs-settings/responsible-party-maintenance'],
            guard: ['ResponsiblePartyMaintenanceView'],
            completed: true,
            released: true
          },
          {
            name: 'Exportacion de Datos/API',
            route_name: 'Inspection Data Export / API',
            path: '/ehs/ehs-settings/data-export-api/inspection-data-export-api',
            matchPath: ['ehs/ehs-settings/data-export-api'],
            guard: ['DataExport/APIView'],
            completed: true,
            released: true
          }
        ]
      }
    ]
  },
  {
    name: 'Documentación',
    icon: 'fa-light fa-file-circle-question',
    matchPath: ['Documentation'],
    completed: true,
    released: true
  },
  {
    name: 'Solicitar Apoyo',
    icon: 'fa-light fa-circle-question',
    matchPath: ['getSupport'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  }
]

export default routes

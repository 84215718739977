<template>
    <div class="dropdown">
      <a data-toggle="dropdown" class="dropdown-toggle" href="#">
        <span class="block m-t-xs font-bold profile-name">
          <i class="fa fa-cog fa-lg"></i> <b class="caret"></b>
        </span>
      </a>
      <ul class="dropdown-menu animated dropup m-t-xs">
        <li>
          <a class="dropdown-item" @click="GridColumnsDisplay()">
            {{$t('twGridColumnsDisplay.columnsSetting.dropDownList.option1')}}
          </a>
        </li>
        <li>
          <a class="dropdown-item" @click="resetGridColumns()">
            {{$t('twGridColumnsDisplay.columnsSetting.dropDownList.option2')}}
          </a>
          </li>
        <li>
          <a class="dropdown-item" @click="resetToDefault()">
            {{$t('twGridColumnsDisplay.columnsSetting.dropDownList.option3')}}
          </a>
        </li>
        <li v-if="additionalSettings" :class="additionalSettings.type">
          <input
            :type="additionalSettings.type"
            v-model="additionalSettingsWatch"
            :id="additionalSettings.type"
            >
          <label v-bind:for="additionalSettings.type">
            <span>{{additionalSettings.label}}</span>
          </label>
        </li>
      </ul>
    </div>
    </template>

<script>
import { useMediaQueryMatch } from '../../composables/useMediaQueryMatch'
export default {
  name: 'TWGridColumnsSetting',
  setup () {
    // map the isMatch result to a new variable called isMobile
    const { isMatch: isMobile } = useMediaQueryMatch()
    // return isMobile so it's available in the template and the other code
    return { isMobile }
  },
  props: {
    additionalSettings: {
      type: Object,
      default: null
    }
  },
  methods: {
    GridColumnsDisplay () {
      this.$emit('GridColumnsDisplay', this.isMobile)
    },
    resetGridColumns () {
      this.$emit('resetGridColumns')
    },
    resetToDefault () {
      this.$emit('resetToDefault')
    }
  },
  watch: {
    additionalSettingsWatch (value) {
      this.$emit('additionalSettingsCallback', value)
    }
  },
  data () {
    return {
      additionalSettingsWatch: this.additionalSettings ? this.additionalSettings.defaultValue : false
    }
  }
}
</script>
  <style scoped>
    div.dropdown {
      position: absolute;
      right: 20px;
      z-index: 998;
    }
    ul.dropdown-menu {
      top: 0px!important;
      min-width: 140px!important;
      padding: 5px 0!important;
    }
    .dropdown a span {
      color: #647178!important;
      font-weight: 700;
    }
    .dropdown input[type="checkbox"] {
      margin: 0 5px 0 25px;
      vertical-align: middle;
    }
    .dropdown .checkbox {
      margin-top: 8px;
    }
    .dropdown .checkbox label {
      display: inline-block;
      white-space: nowrap;
    }
    .dropdown .checkbox span {
      vertical-align: middle;
    }
  </style>

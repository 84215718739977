const safetyPlanContractorDetail = {
  title: 'Contractor Detail',
  deleteConfirmation: 'Are you sure you want to delete the Contractor(s)?',
  btnAddContractor: 'Add Contractor',
  btnAddAdditionalContact: 'Add Additional Contact',
  btnCancel: 'Cancel',
  btnSave: 'Save',
  errorMessage: 'Addtional Contact has been already used.',
  labels: {
    1: 'Contractor',
    2: 'Task / Responsiblities',
    3: 'Main Contact',
    4: 'Add. Contact',
    5: 'Email',
    6: 'Phone'
  },
  contractortableHeader: {
    desktop: [
      {
        label: '',
        key: 'SafetyplanContractorId',
        type: 'check'
      },
      {
        label: 'Name',
        key: 'ContractorName',
        sortable: true
      },
      {
        label: 'Tasks / Responsibilities',
        key: 'TaskResponsibility',
        sortable: true
      },
      {
        label: 'Main Contact',
        key: 'MaincontactEntityName',
        sortable: true
      },
      {
        label: 'Add. Contact',
        key: 'AdditionalcontactEntityCount',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'SafetyplanContractorId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Name',
            key: 'ContractorName',
            sortable: true
          },
          {
            label: 'Tasks / Responsibilities',
            key: 'TaskResponsibility',
            sortable: true
          },
          {
            label: 'Main Contact',
            key: 'MaincontactEntityName',
            sortable: true
          },
          {
            label: 'Add. Contact',
            key: 'AdditionalcontactEntityCount',
            sortable: true
          }
        ]
      }
    ]
  },
  dropdownActions: [
    {
      title: 'Edit',
      actionName: 'editContractors',
      permissions: []
    },
    {
      title: 'Delete',
      actionName: 'confirmDeltetContractors',
      permissions: []
    }
  ]
}
export default safetyPlanContractorDetail

const tasksStatuses = {
  tableHeader: {
    desktop: [
      {
        label: 'control',
        key: 'statusId',
        isModify: true
      },
      {
        label: 'Número',
        key: 'number',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'control',
        key: 'statusId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Número',
            key: 'number',
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default tasksStatuses

import axios from 'axios'
import { setProcoreShipTo } from '@/helpers/procore.js'
import { uuid } from 'vue-uuid'

export default {
  state: () => ({
    schedulingCart: {
      Items: []
    },
    equipmentSchedulingCart: {
      Items: []
    },
    isSchedulingCartLoading: false,
    isEquipmentSchedulingCartLoading: false
  }),
  mutations: {
    storeSchedulingCart (state, schedulingCart) {
      state.schedulingCart = schedulingCart
      localStorage.setItem('SchedulingCartObject', JSON.stringify(schedulingCart))
    },
    storeEquipmentSchedulingCart (state, equipmentSchedulingCart) {
      state.equipmentSchedulingCart = equipmentSchedulingCart
      localStorage.setItem('EquipmentSchedulingCartObject', JSON.stringify(equipmentSchedulingCart))
    },
    addSchedulingItem (state, item) {
      const id = item.ModelId !== undefined ? item.ModelId : item.Id
      const $schedulingCart = deepCopy(state.schedulingCart)
      const index = $schedulingCart.Items.findIndex(ele => ele.ModelId === id)
      if (index === -1) {
        $schedulingCart.Items.push(item)
      } else {
        $schedulingCart.Items[index].Quantity = +$schedulingCart.Items[index].Quantity + +item.Quantity
      }
      state.schedulingCart = deepCopy($schedulingCart)
    },
    addEquipmentSchedulingItem (state, item) {
      const id = item.ModelId !== undefined ? item.ModelId : item.Id
      const $equipmentSchedulingCart = deepCopy(state.equipmentSchedulingCart)
      const index = $equipmentSchedulingCart.Items.findIndex(ele => ele.ModelId === id)
      if (index === -1) {
        $equipmentSchedulingCart.Items.push(item)
      } else {
        $equipmentSchedulingCart.Items[index].Quantity = +$equipmentSchedulingCart.Items[index].Quantity + +item.Quantity
      }
      state.equipmentSchedulingCart = deepCopy($equipmentSchedulingCart)
    },
    removeSchedulingItem (state, item) {
      state.schedulingCart.Items = state.schedulingCart.Items.filter(i => i.ModelId !== item.ModelId)
    },
    removeEquipmentSchedulingItem (state, item) {
      state.equipmentSchedulingCart.Items = state.equipmentSchedulingCart.Items.filter(i => i.ModelId !== item.ModelId)
    },
    updateScheduleingItem (state, item) {
      const foundIndex = state.schedulingCart.Items.findIndex(i => i.ModelId == item.ModelId)
      state.schedulingCart.Items[foundIndex] = item
    },
    updateEquipmentScheduleingItem (state, item) {
      const foundIndex = state.equipmentSchedulingCart.Items.findIndex(i => i.ModelId == item.ModelId)
      state.equipmentSchedulingCart.Items[foundIndex] = item
    },
    setSchedulingCartLoading (state, flag) {
      state.isSchedulingCartLoading = flag
    },
    setEquipmentSchedulingCartLoading (state, flag) {
      state.isEquipmentSchedulingCartLoading = flag
    }
  },
  actions: {
    addItemToSchedulingCart (context, item) {
      context.commit('addSchedulingItem', item)
    },
    addItemToEquipmentSchedulingCart (context, item) {
      context.commit('addEquipmentSchedulingItem', item)
    },
    removeSchedulingItemFromCart (context, item) {
      context.commit('removeSchedulingItem', item)
    },
    removeEquipmentSchedulingItemFromCart (context, item) {
      context.commit('removeEquipmentSchedulingItem', item)
    },
    async loadSchedulingCart (context, isApiLoadRequired) {
      const stringObj = localStorage.getItem('SchedulingCartObject')
      const cartObj = JSON.parse(stringObj)
      if (cartObj === undefined || cartObj === null) {
        const headerConfig = {
          headers: {
            'Content-type': 'application/json; charset=utf-8'
          }
        }
        const data = { Body: {} }
        data.Body = context.getters.cart
        if (isApiLoadRequired || data.Body.OriginationType === undefined) {
          const params = JSON.stringify({})
          // this is wrong scheduling can not use the requisitions cart
          data.Body = await axios.post('/api/core/RequisitionCartService/GetCart', params, headerConfig)
        }
        data.Body.Items = []
        data.Body.OriginationId = uuid.v4()
        data.Body.OriginationType = 'Scheduling'
        setProcoreShipTo(data.Body)
        context.commit('storeSchedulingCart', data.Body)
        context.commit('setSchedulingCartLoading', false)
        let locations = context.getters.getLocations
        if (!locations.length) {
          await context.dispatch('loadStockLocations')
          locations = context.getters.getLocations
        }
        const cartLocation = locations.find(x => x.Id === data.Body.StockpointId)
        if (cartLocation === undefined) {
          context.commit('setAssignedStockpointChanged', true)
        }
      } else {
        const obj = JSON.parse(localStorage.getItem('SchedulingCartObject'))
        context.commit('storeSchedulingCart', obj)
      }
    },
    async loadEquipmentSchedulingCart (context, isApiLoadRequired) {
      const stringObj = localStorage.getItem('EquipmentSchedulingCartObject')
      const cartObj = JSON.parse(stringObj)
      if (cartObj === undefined || cartObj === null) {
        const headerConfig = {
          headers: {
            'Content-type': 'application/json; charset=utf-8'
          }
        }
        const data = { Body: {} }
        data.Body = context.getters.cart
        if (isApiLoadRequired || data.Body.OriginationType === undefined) {
          const params = JSON.stringify({})
          data.Body = await axios.post('/api/core/RequisitionCartService/GetCart', params, headerConfig)
        }
        data.Body.Items = []
        data.Body.OriginationId = uuid.v4()
        data.Body.OriginationType = 'Scheduling'
        setProcoreShipTo(data.Body)
        context.commit('storeEquipmentSchedulingCart', data.Body)
        context.commit('setEquipmentSchedulingCartLoading', false)
        let locations = context.getters.getLocations
        if (!locations.length) {
          await context.dispatch('loadStockLocations')
          locations = context.getters.getLocations
        }
        const cartLocation = locations.find(x => x.Id === data.Body.StockpointId)
        if (cartLocation === undefined) {
          context.commit('setAssignedStockpointChanged', true)
        }
      } else {
        const obj = JSON.parse(localStorage.getItem('EquipmentSchedulingCartObject'))
        context.commit('storeEquipmentSchedulingCart', obj)
      }
    },
    updateSchedulingItemInCart (context, item) {
      context.commit('updateScheduleingItem', item)
    },
    updateEquipmentSchedulingItemInCart (context, item) {
      context.commit('updateEquipmentScheduleingItem', item)
    },
    setSchedulingCart (context, cart) {
      context.commit('storeSchedulingCart', cart)
    },
    setEquipmentSchedulingCart (context, cart) {
      context.commit('storeEquipmentSchedulingCart', cart)
    }
  },
  getters: {
    schedulingCartItems (state) {
      return state.schedulingCartItems
    },
    equipmentSchedulingCartItems (state) {
      return state.equipmentSchedulingCartItems
    },
    schedulingCart (state) {
      return state.schedulingCart
    },
    equipmentSchedulingCart (state) {
      return state.equipmentSchedulingCart
    },
    isSchedulingCartLoading (state) {
      return state.isSchedulingCartLoading
    },
    isEquipmentSchedulingCartLoading (state) {
      return state.isEquipmentSchedulingCartLoading
    }
  }
}

const safetyPlanInspectionDetail = {
  title: 'Detalle de inspección',
  deleteConfirmation: '¿Está seguro de que desea eliminar el requisito de inspección?',
  labels: {
    0: 'Nombre de inspección',
    1: 'Lista de verificación de inspección',
    6: 'Usuarios asignados',
    7: 'Instrucciones de informes',
    10: 'Empresa de Inspección'
  },
  inspectiontableHeader: {
    desktop: [
      {
        label: '',
        key: 'SafetyplanInspectionId',
        type: 'check'
      },
      {
        label: 'Nombre',
        key: 'InspectionName',
        sortable: true
      },
      {
        label: 'Lista De Verificación',
        key: 'ChecklistName',
        sortable: true
      },
      {
        label: 'Frecuencia',
        key: 'PatternFrequency',
        sortable: true
      },
      {
        label: 'Fecha De Inicio',
        key: 'StartDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Fecha Final',
        key: 'EndDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Usuarios Asignados',
        key: 'UserAssignedName',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'SafetyplanInspectionId',
        type: 'check'
      },
      {
        label: 'Nombre',
        key: 'InspectionName',
        sortable: true
      },
      {
        label: 'Información',
        data: [
          {
            label: 'Lista De Verificación',
            key: 'ChecklistName',
            sortable: true
          },
          {
            label: 'Frecuencia',
            key: 'PatternFrequency',
            sortable: true
          },
          {
            label: 'Fecha De Inicio',
            key: 'StartDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Fecha Final',
            key: 'EndDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Usuarios Asignados',
            key: 'UserAssignedName',
            sortable: true
          }
        ]
      }
    ]
  },
  dropdownActions: [
    {
      title: 'Editar',
      actionName: 'onEdit',
      permissions: []
    },
    {
      title: 'Borrar',
      actionName: 'onDelete',
      permissions: []
    },
    {
      title: 'Clon',
      actionName: 'onClone',
      permissions: []
    }
  ]
}

export default safetyPlanInspectionDetail

const help = {
  onlineDoc: {
    mainLink: 'https://secure.toolwatch.com/',
    title: 'Documentación en línea',
    contactSupport: 'Contacto de Soporte',
    userGuide: 'Guías de usuario',
    guidesLink: [
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Set-up User Guide.pdf',
        name: 'Configuración de ToolWatch'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Essentials User Guide.pdf',
        name: 'Guía del usuario de ToolWatch Esenciales'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Service and Calibration User Guide.pdf',
        name: 'Guía del usuario de servicio y calibración de ToolWatch'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Purchasing and Receiving User Guide.pdf',
        name: 'Guía del usuario de compra y recepción de ToolWatch'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Job Cost and Billing User Guide.pdf',
        name: 'Guía del usuario de facturación y costos de trabajo de ToolWatch'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Enterprise Resources User Guide.pdf',
        name: 'Guía del usuario de ToolWatch Enterprise Resources'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Mobile User Guide.pdf',
        name: 'Guía del usuario de ToolWatch Movil'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Cloud User Guide.pdf',
        name: 'Guía del usuario de ToolWatch en la nube'
      }
    ],
    contactForm: {
      name: 'Nombre de contacto*',
      company: 'Empresa*',
      email: 'Correo electrónico*',
      phoneNumber: 'Número de teléfono',
      subject: 'Tema',
      issue: {
        title: '¿Cual es la naturaleza de su problema?',
        lists: [
          {
            key: 'Pregunta',
            value: 'S4 - Trivial'
          },
          {
            key: 'Error menor o problema de rendimiento que causa un impacto comercial menor',
            value: 'S2 - Major'
          },
          {
            key: 'Error importante o problema de rendimiento que causa un impacto comercial significativo',
            value: 'S1 - Urgent'
          },
          {
            key: 'Error crítico o problema de rendimiento que causa tiempo de inactividad',
            value: 'S0 - Business-Stopping'
          }
        ]
      },
      description: 'Descripción',
      hint: 'Se requieren campos marcados con un asterisco.',
      submit: 'Enviar'
    }
  }
}

export default help

const fuelTransactionImportTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Número de tarjeta'
      },
      {
        label: 'Número de artículo'
      },
      {
        label: 'Transacción creada'
      },
      {
        label: 'ID de transacción de compra'
      },
      {
        label: 'Producto'
      },
      {
        label: 'Cantidad'
      },
      {
        label: 'Costo cada uno'
      },
      {
        label: 'Costo total de combustible'
      },
      {
        label: 'DIRECCIÓN'
      },
      {
        label: 'Ciudad'
      },
      {
        label: 'Estado'
      },
      {
        label: 'Cremallera'
      },
      {
        label: 'Apellido del conductor'
      },
      {
        label: 'Nombre de la conductora'
      },
      {
        label: 'Número de empleado'
      },
      {
        label: ''
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Número de tarjeta'
          },
          {
            label: 'Número de artículo'
          },
          {
            label: 'Transacción creada'
          },
          {
            label: 'ID de transacción de compra'
          },
          {
            label: 'Producto'
          },
          {
            label: 'Cantidad'
          },
          {
            label: 'Costo cada uno'
          },
          {
            label: 'Costo total de combustible'
          },
          {
            label: 'DIRECCIÓN'
          },
          {
            label: 'Ciudad'
          },
          {
            label: 'Estado'
          },
          {
            label: 'Cremallera'
          },
          {
            label: 'Apellido del conductor'
          },
          {
            label: 'Nombre de la conductora'
          },
          {
            label: 'Número de empleado'
          },
          {
            label: ''
          }
        ]
      }
    ]
  }
}
export default fuelTransactionImportTable

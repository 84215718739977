const platformRoles = {
  tableHeader: {
    desktop: [
      {
        label: 'Role Name',
        key: 'Name',
        sortable: true
      },
      {
        label: 'Role Description',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Users Assigned to Role',
        key: 'UserCount',
        sortable: true
      },
      {
        label: 'Permissions Enabled',
        key: 'PermissionCount',
        sortable: true
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: '',
        key: '',
        isModify: false
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Name',
            key: 'Name',
            sortable: true
          }
        ]
      }
    ]
  },
  rolePermissionsTblHeader: {
    desktop: [
      {
        label: 'Permission',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Access / View',
        key: 'CanView',
        permission: 'CanView',
        sortable: true
      },
      {
        label: 'Add / Edit',
        key: 'CanEdit',
        permission: 'CanEdit',
        sortable: true
      },
      {
        label: 'Delete',
        key: 'CanDelete',
        permission: 'CanDelete',
        sortable: true
      },
      {
        label: 'Perform',
        key: 'CanExecute',
        permission: 'CanExecute',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: '',
        isModify: false
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Permission',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Access / View',
            key: 'View',
            permission: 'CanView',
            sortable: true
          },
          {
            label: 'Add / Edit',
            key: 'Edit',
            permission: 'CanEdit',
            sortable: true
          },
          {
            label: 'Delete',
            key: 'Delete',
            permission: 'CanDelete',
            sortable: true
          },
          {
            label: 'Execute',
            key: 'Execute',
            permission: 'CanExecute',
            sortable: true
          }
        ]
      }
    ]
  },
  labels: {
    0: 'Role',
    1: 'Role Name:',
    2: 'Clone Existing Role:',
    3: '{userCount} users assigned',
    4: '{enabledCount} of {maxCount} permissions',
    5: 'Role Description'
  },
  cloneRoles: {
    select: 'Select a role to start from...',
    viewOnly: 'View Only'
  }
}

export default platformRoles

<template>
  <div>
    <span
      class="material-icons md-48 view-mode"
      :class="isGrid && 'selected'"
      @click="viewSelect(true)">
      apps
    </span>
    <span
      class="material-icons md-48 view-mode"
      :class="!isGrid && 'selected'"
      @click="viewSelect(false)">
      format_list_bulleted
    </span>
  </div>
</template>
<script>
export default {
  name: 'ViewMode',
  methods: {
    viewSelect (flag) {
      this.$store.commit('setGrid', flag)
      this.$emit('resetSelect')
    }
  },
  computed: {
    isGrid () {
      return this.$store.getters.isGrid
    }
  }
}
</script>
<style scoped>
  :root {
    --secondary-color: #D09433;
  }
  .material-icons.view-mode {
    margin-top: 10px;
    padding-left: 5px;
    font-size: 30px;
    height: 30px;
    color: var(--secondary-color);
    opacity: 0.7;
  }
  .material-icons.view-mode:hover {
    font-size: 33px;
    cursor: pointer;
    opacity: 1;
  }
  .material-icons.view-mode.selected {
    font-size: 32px;
    opacity: 1;
  }
</style>

<template>
  <div>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{title}}
          </h5>
          <IntegrationLink
          :twId=selectedItem.manufacturerId
          />
           <button class="close" type="button" @click="onClose">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form id="manufacturerData" ref="manufacturerData">
            <div
              v-if="selectedAction === 'delete'"
              class="alert alert-danger"
              role="alert">
              {{$t('message.warning.delete')}}
            </div>
            <div class="form-group tw-select-field row">
              <div class="col-lg-3">
                <label for="number">{{$t('admin.manufacturers.editDlg.labels.0')}}</label>
              </div>
              <div class="col-lg-9">
                <form-group
                  :validator="$v.number"
                  :attribute="$t('admin.manufacturers.editDlg.labels.0')"
                >
                  <NumberChooser
                    v-if="selectedAction !== ''"
                    style="height:33px"
                    :numberType=18
                    :number="number"
                    :isDisabled="selectedAction === 'delete'"
                    :isError="$v.number.$error"
                    @input="$v.number.$touch()"
                    @onNumberChanged="number = $event"
                    @onIsValidChanged="isNumberValid = $event; $v.number.$touch()"
                    @saveUsedNumber="saveNumber = $event;"/>
                </form-group>
              </div>
            </div>
            <div class="form-group tw-select-field row">
              <div class="col-lg-3">
                <label for="name">{{$t('admin.manufacturers.editDlg.labels.1')}}</label>
              </div>
              <div class="col-lg-9">
                <form-group
                  :validator="$v.name"
                  :attribute="$t('admin.manufacturers.editDlg.labels.1')"
                >
                  <input
                    class="form-control"
                    type="text"
                    v-model="name"
                    id="name"
                    name="name"
                    :class="{ error: $v.name.$error }"
                    @input="$v.name.$touch()"
                    :disabled="selectedAction === 'delete'"
                    maxlength="50"/>
                </form-group>
              </div>
            </div>
            <div class="form-group tw-select-field row">
              <div class="col-lg-3">
                <label for="phone">{{$t('admin.manufacturers.editDlg.labels.2')}}</label>
              </div>
              <div class="col-lg-9">
                <form-group
                  :validator="$v.phone"
                  :attribute="$t('admin.manufacturers.editDlg.labels.2')"
                >
                  <input
                    class="form-control"
                    type="text"
                    v-model="phone"
                    name="phone"
                    :class="{ error: $v.phone.$error }"
                    @input="$v.phone.$touch()"
                    :disabled="selectedAction === 'delete'"/>
                </form-group>
              </div>
            </div>
            <div class="form-group tw-select-field row">
              <div class="col-lg-3">
                <label for="fax">{{$t('admin.manufacturers.editDlg.labels.3')}}</label>
              </div>
              <div class="col-lg-9">
                <form-group
                  :validator="$v.fax"
                  :attribute="$t('admin.manufacturers.editDlg.labels.3')"
                >
                  <input
                    class="form-control"
                    type="text"
                    v-model="fax"
                    name="fax"
                    :class="{ error: $v.fax.$error }"
                    @input="$v.fax.$touch()"
                    :disabled="selectedAction === 'delete'"/>
                </form-group>
              </div>
            </div>
            <div class="form-group tw-select-field row">
              <div class="col-lg-3">
                <label for="upc_prefix">{{$t('admin.manufacturers.editDlg.labels.4')}}</label>
              </div>
              <div class="col-lg-9">
                <input
                  class="form-control"
                  type="text"
                  v-model="upcPrefix"
                  name="upc_prefix"
                  :disabled="selectedAction === 'delete'"/>
              </div>
            </div>
            <div class="form-group tw-select-field row">
              <div class="col-lg-3">
                <label for="web_address">{{$t('admin.manufacturers.editDlg.labels.5')}}</label>
              </div>
              <div class="col-lg-9">
                <form-group
                  :validator="$v.url"
                  :attribute="$t('admin.manufacturers.editDlg.labels.5')"
                >
                  <input
                    class="form-control"
                    type="text"
                    v-model="url"
                    name="web_address"
                    maxlength="500"
                    :class="{ error: $v.url.$error }"
                    @input="$v.url.$touch()"
                    :disabled="selectedAction === 'delete'"/>
                </form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group tagged-form">
                  <span class="form-title">{{$t('admin.taggedForm.title.1')}}</span>
                  <div class="row form-group tw-select-field mt-3">
                    <div class="col-lg-3 m-auto">
                      <label for="created">{{$t('admin.taggedForm.title.0')}}</label>
                    </div>
                    <div class="col-lg-9">
                      <input
                        type="text"
                        class="form-control"
                        id="created"
                        name="created"
                        disabled
                        v-model="createdOn"
                      >
                    </div>
                  </div>
                  <div class="row form-group tw-select-field mt-3">
                    <div class="col-lg-3 m-auto">
                      <label for="by">{{$t('admin.taggedForm.labels.1')}}</label>
                    </div>
                    <div class="col-lg-9">
                      <input
                        type="text"
                        class="form-control"
                        id="by"
                        name="by"
                        disabled
                        v-model="createdBy"
                      >
                    </div>
                  </div>
                  <div class="row form-group tw-select-field mt-3">
                    <div class="col-lg-3 m-auto">
                      <label for="modifiedOn">{{$t('admin.taggedForm.labels.2')}}</label>
                    </div>
                    <div class="col-lg-9">
                      <input
                        type="text"
                        class="form-control"
                        id="modifiedOn"
                        name="modifiedOn"
                        disabled
                        v-model="modifiedOn"
                      >
                    </div>
                  </div>
                  <div class="row form-group tw-select-field mt-3">
                    <div class="col-lg-3 m-auto">
                      <label for="modifiedBy">{{$t('admin.taggedForm.labels.1')}}</label>
                    </div>
                    <div class="col-lg-9">
                      <input
                        type="text"
                        class="form-control"
                        id="modifiedBy"
                        name="modifiedBy"
                        disabled
                        v-model="modifiedBy"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <tw-button
            :key="'attachment'"
            :permissions="['ManufacturersEdit']"
            :buttonText="$t('buttons.attachFiles')"
          >
            <button
              v-if="selectedAction !== ''"
              class="btn item-popout-grey-button"
              @click="openAttachmentDialog"
            >
              <i class="material-icons">
                attach_file
              </i> {{$t('buttons.attachFiles')}}
            </button>
          </tw-button>
          <button
            class="btn close-btn"
            type="button"
            @click="onCancelClick()"
            :disabled="isSubmitting"
          >
            {{$t('buttons.cancel')}}
          </button>
          <template v-if="selectedAction === 'delete'">
            <tw-button
              :key="selectedAction"
              :permissions="['ManufacturersDelete']"
              :buttonText="$t('buttons.delete')"
            >
              <template v-if="isSubmitting">
                <button
                  type="button"
                  class="btn" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {{$t('buttons.delete')}}...
                </button>
              </template>
              <template v-else>
                <button
                  type="button"
                  @click="submit"
                  class="btn">
                  {{$t('buttons.delete')}}
                </button>
              </template>
            </tw-button>
          </template>
          <template v-else>
            <tw-button
              :key="selectedAction"
              :permissions="['ManufacturersEdit']"
              :buttonText="$t('buttons.save.default')"
            >
              <template v-if="isSubmitting">
                <button
                  type="button"
                  class="btn" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {{$t('buttons.save.default')}}...
                </button>
              </template>
              <template v-else>
                <button
                  type="button"
                  @click="submit"
                  class="btn"
                  :disabled="!hasChanges">
                  {{$t('buttons.save.default')}}
                </button>
              </template>
            </tw-button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { phone, fax, url, errorHandler, successHandler, getFormattedDate } from '../helpers/utils'
import NumberChooser from '../components/shared/NumberChooser.vue'
import { uuid } from 'vue-uuid'
import TwButton from '../components/shared/TWButton.vue'
import { required } from 'vuelidate/lib/validators'
import IntegrationLink from './integration/IntegrationLink.vue'

export default {
  name: 'ManufacturersForm',
  components: {
    NumberChooser,
    TwButton,
    IntegrationLink
  },
  props: {
    selectedItem: {
      type: Object,
      required: false,
      default: null
    },
    selectedAction: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      isSubmitting: false,
      name: this.selectedItem.name,
      number: this.selectedItem.manufacturerNumber,
      phone: this.selectedItem.phone,
      fax: this.selectedItem.fax,
      upcPrefix: this.selectedItem.upcPrefix,
      url: this.selectedItem.url,
      createdOn: getFormattedDate(this.selectedItem.createdOn),
      createdBy: this.selectedItem.createdBy,
      modifiedOn: getFormattedDate(this.selectedItem.modifiedOn),
      modifiedBy: this.selectedItem.modifiedBy,
      isNumberValid: false,
      saveNumber: null
    }
  },
  validations: {
    number: {
      required,
      numberValid () {
        return this.isNumberValid
      }
    },
    name: {
      required
    },
    phone: {
      phone
    },
    fax: {
      fax
    },
    url: {
      url
    }
  },
  methods: {
    async submit () {
      if (this.$v.$invalid) {
        this.$v.number.$touch()
        this.$v.name.$touch()
        this.$v.phone.$touch()
        this.$v.fax.$touch()
        this.$v.url.$touch()
        return
      }
      this.isSubmitting = true

      const newID = uuid.v4()
      const params = JSON.stringify({
        dto: JSON.stringify({
          ManufacturerId: this.selectedAction == 'edit' ? this.selectedItem.manufacturerId : newID,
          ManufacturerNumber: this.number,
          OrganizationId: localStorage.getItem('OrgId'),
          Name: this.name,
          UpcPrefix: this.upcPrefix,
          Phone: this.phone,
          Fax: this.fax,
          Url: this.url,
          CreatedById: this.selectedAction == 'edit' ? this.selectedItem.createdById : localStorage.getItem('UserId'),
          CreatedBy: this.selectedAction == 'edit' ? this.selectedItem.createdBy : this.$store.getters.fullName,
          CreatedOn: this.selectedAction == 'edit' ? this.selectedItem.createdOn : new Date(),
          ModifiedBy: this.$store.getters.fullName,
          ModifiedOn: new Date(),
          ModifiedById: localStorage.getItem('UserId'),
          Deleted: false,
          Inactive: false,
          DataSourceId: this.selectedItem.DataSourceId
        })
      })
      const paramsDelete = JSON.stringify({
        orgId: '\'' + localStorage.getItem('OrgId') + '\'',
        deletedById: '\'' + localStorage.getItem('UserId') + '\'',
        deletedRecordId: '\'' + this.selectedItem.manufacturerId + '\'',
        deletedRecordNumber: '\'' + this.number + '\'',
        description: '\'' + this.name + '\'',
        recordType: '13',
        markDeleted: 'true'
      })
      const paramsInsert = {
        stockpointId: `"${this.globalStockpointId}"`,
        orgId: `"${localStorage.getItem('OrgId')}"`,
        listItemId: `"${newID}"`
      }
      if (this.selectedAction === 'delete') {
        try {
          await axios.post('/api/core/RecycleBinService/Recycle', paramsDelete)
          this.isSubmitting = false
          successHandler(this.$toast, this.$t('message.success.delete'))
          this.$emit('saveSuccess')
        } catch (error) {
          this.isSubmitting = false
          if (error.response) {
            errorHandler(this.$toast, error.response.statusText, error)
          }
          this.$emit('saveFailure')
        }
      } else if (this.selectedAction === 'edit') {
        try {
          await axios.post('/api/core/ManufacturerService/SaveManufacturer', params)
          if (this.saveNumber) {
            await this.saveNumber()
          }
          this.isSubmitting = false
          successHandler(this.$toast, this.$t('message.success.edit'))
          this.$emit('saveSuccess')
        } catch (error) {
          this.isSubmitting = false
          if (error.response) {
            errorHandler(this.$toast, error.response.statusText, error)
          }
          this.$emit('saveFailure')
        }
      } else {
        try {
          await axios.post('/api/core/ManufacturerService/SaveManufacturer', params)
          await axios.post('/api/core/ListVisibilityService/insertListVis', paramsInsert)
          if (this.saveNumber) {
            await this.saveNumber()
          }
          this.isSubmitting = false
          successHandler(this.$toast, this.$t('message.success.create'))
          this.$emit('saveSuccess', newID)
        } catch (error) {
          this.isSubmitting = false
          if (error.response) {
            errorHandler(this.$toast, error.response.statusText, error)
          }
          this.$emit('saveFailure')
        }
      }
    },
    onCancelClick () {
      this.clearForm()
      this.hideModal()
    },
    clearForm () {
      this.number = this.selectedItem.manufacturerNumber
      this.name = this.selectedItem.name
      this.phone = this.selectedItem.phone
      this.fax = this.selectedItem.fax
      this.upcPrefix = this.selectedItem.upcPrefix
      this.url = this.selectedItem.url
      this.createdOn = getFormattedDate(this.selectedItem.createdOn)
      this.createdBy = this.selectedItem.createdBy
      this.modifiedOn = getFormattedDate(this.selectedItem.modifiedOn)
      this.modifiedBy = this.selectedItem.modifiedBy
    },
    onClose () {
      if (this.hasChanges) {
        this.$emit('openConfirmSaveClose')
      } else {
        this.hideModal()
      }
    },
    hideModal () {
      this.$emit('hideModal')
    },
    openAttachmentDialog () {
      this.$emit('openAttachments')
    }
  },
  computed: {
    title () {
      if (this.selectedAction === 'delete') {
        return this.$t('admin.manufacturers.editDlg.title.2')
      } else if (this.selectedAction === 'edit') {
        return this.$t('admin.manufacturers.editDlg.title.1')
      } else {
        return this.$t('admin.manufacturers.editDlg.title.0')
      }
    },
    hasChanges () {
      if (this.number !== this.selectedItem.manufacturerNumber ||
        this.name !== this.selectedItem.name ||
        this.phone !== this.selectedItem.phone ||
        this.upcPrefix !== this.selectedItem.upcPrefix ||
        this.url !== this.selectedItem.url ||
        this.fax !== this.selectedItem.fax) {
        return true
      }
      return false
    },
    globalStockpointId () {
      return this.$store.getters.getStockpointId
    }
  }
}
</script>
<style scoped>
.tw-select-field input {
  height: 33px!important;
}
.tw-select-field textarea {
  padding-top: 10px;
  height: 50px;
}
.form-group.record-form {
  border: 1px solid #e1e1e1;
  padding: 15px;
  position: relative;
}
.record-form:before {
  content: 'Record Information';
  top: -10px;
  position: absolute;
  left: 10px;
  background: white;
  font-weight: 600;
  color: #495760;
  padding: 0 5px;
}
</style>

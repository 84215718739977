const workRequiredTools = {
  tableHeader: {
    desktop: [
      {
        label: 'Usado',
        type: Boolean,
        key: 'isUsed',
        id: 'workOrderRequiredPartId',
        isEditable: true
      },
      {
        label: 'Est. Cant.',
        key: 'estimatedQty',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Cant. Actuales',
        key: 'actualQty',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'No.',
        key: 'masterNumber',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'masterDescription',
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'model',
        sortable: true
      },
      {
        label: 'Centro de costos',
        key: 'costCenter',
        sortable: true
      },
      {
        label: 'Código de costos',
        key: 'costCode',
        sortable: true
      },
      {
        label: 'Clase de servicio',
        key: 'serviceClass',
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Usado',
            type: Boolean,
            key: 'isUsed',
            id: 'workOrderRequiredPartId',
            isEditable: true
          },
          {
            label: 'Est. Cant.',
            key: 'estimatedQty',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Cant. Actuales',
            key: 'actualQty',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'No.',
            key: 'masterNumber',
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'masterDescription',
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'model',
            sortable: true
          },
          {
            label: 'Centro de costos',
            key: 'costCenter',
            sortable: true
          },
          {
            label: 'Código de costos',
            key: 'costCode',
            sortable: true
          },
          {
            label: 'Clase de servicio',
            key: 'serviceClass',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default workRequiredTools

const customDashboards = {
  tableHeader: {
    desktop: [
      {
        label: 'Nombre del Panel'
      },
      {
        label: 'Descripción'
      },
      {
        label: 'Carpeta'
      },
      {
        label: 'Privado'
      },
      {
        label: 'Creado por'
      },
      {
        label: 'Creado el'
      }
    ],
    mobile: [
      {
        label: 'Nombre del Panel'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Descripción'
          },
          {
            label: 'Carpeta'
          },
          {
            label: 'Privado'
          },
          {
            label: 'Creado por'
          },
          {
            label: 'Creado el'
          }
        ]
      }
    ]
  },
  defaultDashboard: [
    {
      id: '931d8c15-518e-440c-b25b-f6f6d61135e1',
      name: 'Tablero del sistema (POR DEFECTO)',
      dashboardType: 'System Dashboard (DEFAULT)'
    },
    {
      id: 'fb47a7d5-190f-4ea0-8925-d12bb4590c21',
      name: 'Servicio y calibración (POR DEFECTO)',
      dashboardType: 'Service & Calibration (DEFAULT)'
    },
    {
      id: '86ac0211-91a7-4d20-92d2-8919f74cf036',
      name: 'Seleccionar boletos y transferencias (DEFAULT)',
      dashboardType: 'Pick Tickets & Transfers (DEFAULT)'
    },
    {
      id: 'aa18e8b4-6c58-446d-b385-25c6304918a4',
      name: 'Compras (POR DEFECTO)',
      dashboardType: 'Purchasing (DEFAULT)'
    }
  ],
  deleteWarningMsg: '¿Está seguro de que desea eliminar el seleccionado?',
  customDashboardAssignMsg: 'El panel está asignado y no se puede eliminar.'
}

export default customDashboards

export default {
  state: () => ({
    requiringBrowseItem: null
  }),
  mutations: {
    setRequiringBrowseItem (state, item) {
      state.requiringBrowseItem = item
    }
  },
  getters: {
    requiringBrowseItem (state) {
      return state.requiringBrowseItem
    }
  }
}

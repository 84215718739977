<template>
  <Fragment>
    <div
      class="modal fade tw-shipping-modal"
      id="ship-address"
      tabindex="-1"
      role="dialog"
      aria-labelledby="shippingLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <template>
              <h5 class="modal-title" id="shippingLabel">
                {{ $t("requisition.editModal.title") }}, {{ fullName }}
              </h5>
              <span @click="logout()">
                {{ $t("requisition.editModal.logout") }}
              </span>
            </template>
          </div>
          <div class="modal-body">
            <div class="form-field tw-select-field">
              <label for="for">{{
                $t("requisition.editModal.labels.0")
              }}</label>
              <v-select
                v-model="selectedApp"
                label="Key"
                key="Value"
                :options="appLists"
                :clearable="false"
              ></v-select>
            </div>
            <div class="form-field tw-select-field">
              <label for="for">
                {{ $t("requisition.editModal.labels.1") }}
              </label>
              <ReqOrgList
                @onOrgSelected="selectedOrg = $event"
                @resetData="resetData"
              />
            </div>
            <div
              class="form-field tw-select-field"
              v-if="selectedApp.Value == 'jsp' || isRequisition"
            >
              <label for="for">{{
                $t("requisition.editModal.labels.6")
              }}</label>
              <ListSearchComboBox
                v-if="selectedOrg && selectedOrg.Id"
                @onIdSelected="shipToEntityId = $event"
                :selectedId="shipToEntityId"
                label="job site"
                :listTypeId="41"
                :stockpointId="selectedOrg.Id"
                @hasValue="isValidJobSite($event)"
              >
              </ListSearchComboBox>
            </div>
            <div
              class="form-field tw-select-field"
              v-if="selectedApp.Value == 'jsp' || isRequisition"
            >
              <label for="center">
                {{ $t("requisition.editModal.labels.3") }}
              </label>
              <ListSearchComboBox
                v-if="selectedOrg && selectedOrg.Id"
                @onIdSelected="costCenterId = $event"
                @hasValue="isValidCostCenter($event)"
                :selectedId="costCenterId"
                :listTypeId="3"
                label="center"
                :stockpointId="selectedOrg.Id"
                :addBlankOption="true"
              >
              </ListSearchComboBox>
            </div>
            <div
              class="form-field tw-select-field row"
              v-if="selectedApp.Value == 'jsp' || isRequisition"
            >
              <label for="code">
                {{ $t("requisition.editModal.labels.4") }}
              </label>
              <ListSearchComboBox
                v-if="selectedOrg && selectedOrg.Id"
                @onIdSelected="defaultCostCodeId = $event"
                :selectedId="defaultCostCodeId"
                :listTypeId="4"
                :costCenterId="costCenterId"
                label="code"
                :stockpointId="selectedOrg.Id"
                :addBlankOption="true"
                :disabled="costCenterId === null || costCenterId === undefined"
                @hasValue="isValidCostCode($event)"
              >
              </ListSearchComboBox>
            </div>
            <div
              v-if="hasAddress && (selectedApp.Value == 'jsp' || isRequisition)"
              class="form-field addr-field"
            >
              <label>
                {{ $t("requisition.editModal.labels.5") }}
              </label>
              <p class="addr">
                <ReqAddress
                  :address="cartAddress"
                  :address2="cartAddress2"
                  :city="cartCity"
                  :state="cartState"
                  :zip="cartZip"
                  :country="cartCountry"
                  :phone="cartPhone"
                />
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="!hideCloseButton"
              type="button"
              class="btn close-btn"
              @click="closeShipTo()"
              :disabled="loading"
            >
              {{ $t("buttons.close") }}
            </button>
            <button
              type="button"
              class="btn"
              @click="switchingApp()"
              :disabled="isDoneDisabled"
            >
              {{ $t("buttons.done") }}
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <confirm-dialog
      ref="warningDlg"
      class="tw-shipping-modal"
      :title="$t('requisition.assignmentChanged.title')"
      :message="warningMessage"
      :showCancel="false"
      :okBtnText="$t('buttons.ok')"
      @confirmResult="confirmSettings"
    />
  </Fragment>
</template>

<script>
import ListSearchComboBox from '../shared/ListSearchComboBox.vue'
import ReqAddress from '../requisitions/ReqAddress.vue'
import ReqOrgList from '../requisitions/ReqOrgList.vue'
import $ from 'jquery'
import axios from 'axios'
import vSelect from 'vue-select'
import {
  errorHandler,
  successHandler,
  clearLocalStorage,
  getLoginPath
} from '../../helpers/utils'
import ConfirmDialog from '../shared/ConfirmDialog.vue'
import { Fragment } from 'vue-fragment'
export default {
  name: 'ReqEditShipTo',
  components: {
    ListSearchComboBox,
    ReqAddress,
    ReqOrgList,
    vSelect,
    ConfirmDialog,
    Fragment
  },
  data () {
    return {
      loading: false,
      shipToEntityId: '',
      costCenterId: '',
      defaultCostCodeId: '',
      cartAddress: '',
      cartAddress2: '',
      cartCity: '',
      cartState: '',
      cartZip: '',
      cartCountry: '',
      cartPhone: '',
      shipToCostCode: null,
      selectedApp: this.$t('appLists.0'),
      orgLists: [],
      selectedOrg: null,
      hideCloseButton: false,
      isInvalidJob: false
    }
  },
  async created () {
    this.resetApp()
    if (!this.locations.length) {
      await this.$store.dispatch('loadStockLocations')
    }
    this.orgLists = [].concat(this.locations)
    this.selectedOrg = this.orgLists.find(
      (list) => list.Id === this.cart.StockpointId
    )
  },
  methods: {
    async switchingApp () {
      if (this.loading) return
      // Always update pinned location
      await this.savePinnedLocation()
      if (this.hasChanges) {
        await this.saveShipTo()
      }
      if (
        (this.jobSitePortalUser && this.selectedApp.Value == 'jsp') ||
        (this.wareHouse && this.selectedApp.Value == 'wh') ||
        (this.backOffice && this.selectedApp.Value == 'bo')
      ) {
        this.closeModal()
        this.$store.commit('setFLogin', false)
      } else {
        this.switchSite()
      }
      await this.$router.go(this.$route.fullPath)
    },
    async saveShipTo () {
      if (
        this.jobSitePortalUser &&
        this.selectedApp.Value == 'jsp' &&
        (!this.shipToEntityId || this.shipToEntityId === '00000000-0000-0000-0000-000000000000')
      ) {
        return
      }
      // saving cart
      this.loading = true
      try {
        this.cart.ShipToEntityId = this.shipToEntityId
          ? this.shipToEntityId
          : '00000000-0000-0000-0000-000000000000'
        this.cart.CostCenterId = this.costCenterId
        this.cart.DefaultCostCodeId = this.defaultCostCodeId
        this.cart.StockpointId = this.selectedOrg.Id
        this.cart.Address = this.cartAddress
        this.cart.Address2 = this.cartAddress2
        this.cart.City = this.cartCity
        this.cart.State = this.cartState
        this.cart.Zip = this.cartZip
        this.cart.Country = this.cartCountry
        this.cart.Phone = this.cartPhone
        await this.$store.dispatch('saveCart', this.cart)
        const data = JSON.parse(localStorage.getItem('SchedulingCartObject'))
        if (data) {
          data.ShipToEntityId = this.shipToEntityId
            ? this.shipToEntityId
            : '00000000-0000-0000-0000-000000000000'
          data.DefaultCostCodeId = this.defaultCostCodeId
          data.StockpointId = this.selectedOrg.Id
          data.Address = this.cartAddress
          data.Address2 = this.cartAddress2
          data.City = this.cartCity
          data.State = this.cartState
          data.Zip = this.cartZip
          data.Country = this.cartCountry
          data.Phone = this.cartPhone
          localStorage.setItem('SchedulingCartObject', JSON.stringify(data))
          if (this.defaultCostCodeId !== undefined && this.defaultCostCodeId !== null) {
            localStorage.setItem('DefaultCostCodeId', this.defaultCostCodeId)
          }
        }
        this.loading = false
        successHandler(this.$toast, this.$t('message.success.shipSaving'))
        if (!this.loading) {
          this.$emit('onSaved')
          this.closeModal()
          this.$store.commit('setFLogin', false)
        }
      } catch (error) {
        this.loading = false
        if (error.response) {
          errorHandler(this.$toast, this.$t('message.error.shipSaving'))
        }
      }
    },
    async savePinnedLocation () {
      if (!this.selectedOrg) return
      try {
        var pinnedLocationInfo = {
          pinnedLocationId: "'" + this.selectedOrg.Id + "'"
        }
        await axios.post(
          '/api/core/SessionsService/SetSessionPinnedLocationId',
          pinnedLocationInfo
        )
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, this.$t('message.error.shipSaving'))
        }
      }
    },
    switchSite () {
      if (this.selectedApp.Value === 'wh') {
        localStorage.setItem('jobSiteUser', 1)
      } else if (this.selectedApp.Value === 'jsp') {
        localStorage.setItem('jobSiteUser', 0)
      } else {
        localStorage.setItem('jobSiteUser', 2)
      }
    },
    refresh () {
      const {
        ShipToEntityId,
        CostCenterId,
        DefaultCostCodeId,
        StockpointId,
        Address,
        Address2,
        City,
        Zip,
        State,
        Country,
        Phone
      } = this.cart
      this.shipToEntityId = ShipToEntityId
      this.costCenterId = CostCenterId
      this.defaultCostCodeId = DefaultCostCodeId
      this.selectedOrg = this.orgLists.find((list) => list.Id === StockpointId)
      this.cartAddress = Address
      this.cartAddress2 = Address2
      this.cartCity = City
      this.cartState = State
      this.cartZip = Zip
      this.cartCountry = Country
      this.cartPhone = Phone
    },
    resetData () {
      this.shipToEntityId = null
      this.costCenterId = null
      this.defaultCostCodeId = null
    },
    resetApp () {
      if (this.wareHouse) {
        this.selectedApp = this.$t('appLists.1')
      } else if (this.backOffice) {
        this.selectedApp = this.$t('appLists.2')
      } else {
        this.selectedApp = this.$t('appLists.0')
      }
    },
    async closeShipTo () {
      this.refresh()
      this.resetApp()
      // Always update pinned location
      await this.savePinnedLocation()
      this.closeModal()
      this.$store.commit('setFLogin', false)
    },
    closeModal () {
      $('#ship-address').modal('hide')
    },
    async logout () {
      this.closeModal()
      try {
        const { status } = await axios.get('/api/logout')
        if (status === 200) {
          clearLocalStorage()
          window.location.href = getLoginPath()
        }
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    isValidCostCenter (newValue) {
      if (!newValue) {
        this.costCenterId = null
      }
    },
    isValidJobSite (newValue) {
      if (!newValue) {
        this.shipToEntityId = null
      }
    },
    isValidCostCode (newValue) {
      if (!newValue) {
        this.defaultCostCodeId = null
      }
    },
    confirmSettings () {
      this.hideCloseButton = true
      if (this.isInvalidJob) {
        this.resetData()
      }
      $('#ship-address').modal('show')
    },
    async updateOrgTreeFromAssignment () {
      this.selectedOrg = this.orgLists.find(
        (list) => list.Id == this.assignedToId
      )
      // User's assignedTo location has been updated since last cart save
      if (this.selectedOrg && this.selectedOrg.Id !== undefined && this.selectedOrg.Id !== this.cart.StockpointId) {
        const jobs = await this.$store.dispatch('loadDataByListType', {
          query: '',
          listTypeId: 41,
          stockpointId: this.selectedOrg.Id
        })
        const foundJob = jobs.find((x) => x.Value === this.cart.ShipToEntityId)
        if (foundJob === undefined) {
          this.isInvalidJob = true
        } else {
          this.isInvalidJob = false
        }
        this.$nextTick(() => {
          this.$refs.warningDlg.open()
        })
      }
    }
  },
  watch: {
    async shipToEntityId (newValue) {
      if (!newValue) {
        this.costCenterId = null
        this.shipToCostCode = null
      } else if (this.hasChanges) {
        this.$store.commit('setShipLoading', true)
        try {
          const { data } = await axios.get(
            '/api/cloud/requisitions/getShipToEntity/' + this.shipToEntityId
          )
          this.$store.commit('setShipLoading', false)
          const {
            CostCenterId,
            CostCodeId,
            Address,
            Address2,
            City,
            Zip,
            State,
            Country,
            Phone
          } = data
          this.costCenterId = CostCenterId
          this.shipToCostCode = CostCodeId
          this.cartAddress = Address
          this.cartAddress2 = Address2
          this.cartCity = City
          this.cartState = State
          this.cartZip = Zip
          this.cartCountry = Country
          this.cartPhone = Phone
        } catch (error) {
          this.$store.commit('setShipLoading', false)
          if (error.response) {
            errorHandler(this.$toast, error.response.statusText, error)
          }
        }
      }
    },
    async costCenterId () {
      if (this.hasChanges) {
        this.defaultCostCodeId = null
        if (this.shipToCostCode) {
          this.defaultCostCodeId = this.shipToCostCode
          this.shipToCostCode = null
        }
      }
    },
    cart: {
      handler: function () {
        if (this.cart.Items) {
          this.refresh()
        }
      },
      immediate: true
    },
    assignedStockpointChanged: {
      handler: async function (newVal) {
        if (newVal === true) {
          await this.updateOrgTreeFromAssignment()
        }
      },
      immediate: true
    }
  },
  computed: {
    fullName () {
      return this.$store.getters.fullName
    },
    cart () {
      return this.$store.getters.cart
    },
    hasAddress () {
      return (
        this.cartAddress ||
        this.cartAddress2 ||
        this.cartCity ||
        this.cartState ||
        this.cartZip ||
        this.cartCountry ||
        this.cartPhone
      )
    },
    hasChanges () {
      if (
        this.shipToEntityId === '' &&
        this.defaultCostCodeId === '' &&
        this.costCenterId === ''
      ) {
        return false
      }
      return (
        this.shipToEntityId != this.cart.ShipToEntityId ||
        this.costCenterId != this.cart.CostCenterId ||
        this.defaultCostCodeId != this.cart.DefaultCostCodeId ||
        (this.selectedOrg &&
          this.selectedOrg.Description != this.cart.StockPointName)
      )
    },
    isFLogin () {
      return this.$store.getters.isFLogin
    },
    userPermission () {
      return this.$store.getters.userPermission
    },
    locations () {
      return this.$store.getters.getLocations
    },
    appLists () {
      // Backoffice only
      if (this.$store.getters.isEHSOnly) {
        return [this.$t('appLists.2')]
      }

      const apps = [
        this.$t('appLists.0'),
        this.$t('appLists.2'),
        this.$t('appLists.1')
      ]
      return apps
    },
    isDoneDisabled () {
      if (this.selectedApp.Value == 'bo' || this.selectedApp.Value == 'wh') {
        return false
      }
      return (
        this.loading ||
        !this.shipToEntityId ||
        this.shipToEntityId.length === 0 ||
        this.shipToEntityId === '00000000-0000-0000-0000-000000000000'
      )
    },
    jobSitePortalUser () {
      return this.$store.getters.jobSiteUser === 0
    },
    wareHouse () {
      return this.$store.getters.jobSiteUser === 1
    },
    backOffice () {
      return this.$store.getters.jobSiteUser === 2
    },
    isRequisition () {
      return this.$route.path.includes('requisition')
    },
    assignedToId () {
      return this.$store.getters.assignedToId
    },
    assignedStockpointChanged () {
      return (
        this.orgLists.length > 0 &&
        this.$store.getters.assignedStockpointChanged
      )
    },
    warningMessage () {
      return (
        this.$t('requisition.assignmentChanged.message') +
        ' ' +
        this.messageAddendum
      )
    },
    messageAddendum () {
      if (this.isInvalidJob) {
        return this.$t('requisition.assignmentChanged.invalidJob')
      } else {
        return this.$t('requisition.assignmentChanged.confirmSettings')
      }
    }
  }
}
</script>
<style scoped>
.modal-header > span {
  color: #007bff;
}
.modal-header > span:hover {
  cursor: pointer;
}
input[type="radio"] {
  appearance: auto !important;
  -webkit-appearance: auto !important;
  width: 15px !important;
  height: 15px !important;
}
</style>

const toolTransferItems = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: 'Categoría',
        key: 'category',
        sortable: true,
        type: String
      },
      {
        label: 'Número',
        key: 'itemNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Código de barras',
        key: 'barcode',
        sortable: true,
        type: String
      },
      {
        label: 'Número de serie',
        key: 'serialNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'equipo',
        key: 'kitNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'T',
        key: 'itemType',
        sortable: true,
        type: String
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'fabricante',
        key: 'manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Modelo',
        key: 'model',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Cant.',
        key: 'quantity',
        sortable: true,
        isMaterial: true,
        type: Number
      },
      {
        label: 'Estado',
        key: 'statusCodeNumber',
        sortable: true,
        type: String
      },
      {
        label: 'UP',
        key: 'personalIssue',
        sortable: true,
        type: Boolean
      },
      {
        label: 'Asignación',
        key: 'assignedTo',
        sortable: true,
        type: String
      },
      {
        label: 'Edad',
        key: 'ageDays',
        sortable: true,
        type: Number
      },
      {
        label: 'Dueña',
        key: 'owner',
        sortable: true,
        type: String
      },
      {
        label: 'Gerente',
        key: 'manager',
        sortable: true,
        type: String
      },
      {
        label: 'Departamento',
        key: 'department',
        sortable: true,
        type: String
      },
      {
        label: 'Supervisor',
        key: 'supervisor',
        sortable: true,
        type: String
      },
      {
        label: 'Clase de artículo',
        key: 'className',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Número',
            key: 'itemNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Código de barras',
            key: 'barcode',
            sortable: true,
            type: String
          },
          {
            label: 'Número de serie',
            key: 'serialNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Equipo',
            key: 'kitNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'T',
            key: 'itemType',
            sortable: true,
            type: String
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'fabricante',
            key: 'manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Modelo',
            key: 'model',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Cant.',
            key: 'quantity',
            sortable: true,
            isMaterial: true,
            type: Number
          },
          {
            label: 'Estado',
            key: 'statusCodeNumber',
            sortable: true,
            type: String
          },
          {
            label: 'UP',
            key: 'personalIssue',
            sortable: true,
            type: Boolean
          },
          {
            label: 'Asignación',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'Edad',
            key: 'ageDays',
            sortable: true,
            type: Number
          },
          {
            label: 'Dueña',
            key: 'owner',
            sortable: true,
            type: String
          },
          {
            label: 'Gerente',
            key: 'manager',
            sortable: true,
            type: String
          },
          {
            label: 'Departamento',
            key: 'department',
            sortable: true,
            type: String
          },
          {
            label: 'Supervisor',
            key: 'supervisor',
            sortable: true,
            type: String
          },
          {
            label: 'Clase de artículo',
            key: 'className',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  }
}

export default toolTransferItems

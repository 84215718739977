import CONST from '../../helpers/const'

const recurrenceSchedule = {
  recurrence: {
    title: 'Calendario de recurrencia',
    eventRecurrence: 'Calendario de recurrencia',
    messages: {
      saved: 'Recurrencia creada con éxito',
      delete: 'Recurrencia eliminada con éxito'
    },
    pattern: {
      title: 'patrón de recurrencia',
      daily: 'A diario',
      weekly: 'Semanalmente',
      monthly: 'Mensual',
      yearly: 'Anual',
      weekday: 'Todos los días de la semana',
      every: 'Cada',
      days: 'días)',
      recurEvery: 'Repita todos los',
      weeksOn: 'semana(s) en',
      day: 'Día',
      ofEvery: 'de cada',
      months: 'meses)',
      the: 'La',
      of: 'de'
    },
    daysOfWeek: [
      {
        name: 'Domingo',
        value: CONST.SUNDAY
      },
      {
        name: 'Lunes',
        value: CONST.MONDAY
      },
      {
        name: 'Martes',
        value: CONST.TUESDAY
      },
      {
        name: 'Miércoles',
        value: CONST.WEDNESDAY
      },
      {
        name: 'Jueves',
        value: CONST.THURSDAY
      },
      {
        name: 'Viernes',
        value: CONST.FRIDAY
      },
      {
        name: 'Sábado',
        value: CONST.SATURDAY
      },
      {
        name: 'día',
        value: CONST.ALLDAYS
      },
      {
        name: 'día laborable',
        value: CONST.WEEKDAY
      },
      {
        name: 'día de fin de semana',
        value: CONST.WEEKENDDAY
      }
    ],
    weekNumber: [
      {
        name: 'primera',
        value: 1
      },
      {
        name: 'segunda',
        value: 2
      },
      {
        name: 'tercera',
        value: 3
      },
      {
        name: 'Cuarta',
        value: 4
      },
      {
        name: 'última',
        value: 5
      }
    ],
    monthOfYear: [
      {
        name: 'Enero',
        value: 1
      },
      {
        name: 'Febrero',
        value: 2
      },
      {
        name: 'Marzo',
        value: 3
      },
      {
        name: 'Abril',
        value: 4
      },
      {
        name: 'Puede',
        value: 5
      },
      {
        name: 'Junio',
        value: 6
      },
      {
        name: 'Julio',
        value: 7
      },
      {
        name: 'Agosto',
        value: 8
      },
      {
        name: 'Septiembre',
        value: 9
      },
      {
        name: 'Octubre',
        value: 10
      },
      {
        name: 'Noviembre',
        value: 11
      },
      {
        name: 'Diciembre',
        value: 12
      }
    ],
    warning: {
      title: 'Recurrencia de citas',
      message: 'La propiedad "PatrónDíasDeLaSemana" no se puede establecer en "Ninguno" cuando la propiedad "PatternFrequency" se establece en "Weekly"'
    }
  }
}

export default recurrenceSchedule

<template>
  <input :type="type" :maxlength="maxlength" @input="$emit('input', $event.target.value)"/>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text',
      validator: function (value) {
        return value === 'text'
      }
    },
    maxlength: {
      type: Number,
      required: true
    }
  }
}
</script>

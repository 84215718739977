const userRoles = {
  tableHeader: {
    desktop: [
      {
        label: 'Nombre',
        key: 'Name',
        sortable: true
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: '',
        key: '',
        isModify: false
      },
      {
        label: 'Información',
        data: [
          {
            label: 'Nombre',
            key: 'Name',
            sortable: true
          }
        ]
      }
    ]
  },
  rolePermissionsTblHeader: {
    desktop: [
      {
        label: 'Permiso',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Ver',
        key: 'View',
        permission: 'CanView',
        sortable: true
      },
      {
        label: 'Agregar',
        key: 'Add',
        permission: 'CanAdd',
        sortable: true
      },
      {
        label: 'Editar',
        key: 'Edit',
        permission: 'CanEdit',
        sortable: true
      },
      {
        label: 'Borrar',
        key: 'Delete',
        permission: 'CanDelete',
        sortable: true
      },
      {
        label: 'Ejecutar',
        key: 'Execute',
        permission: 'CanExecute',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: '',
        isModify: false
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Permiso',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Ver',
            key: 'View',
            permission: 'CanView',
            sortable: true
          },
          {
            label: 'Agregar',
            key: 'Add',
            permission: 'CanAdd',
            sortable: true
          },
          {
            label: 'Editar',
            key: 'Edit',
            permission: 'CanEdit',
            sortable: true
          },
          {
            label: 'Borrar',
            key: 'Delete',
            permission: 'CanDelete',
            sortable: true
          },
          {
            label: 'Ejecutar',
            key: 'Execute',
            permission: 'CanExecute',
            sortable: true
          }
        ]
      }
    ]
  },
  usersAssignedToRolePage: {
    title: 'Usuarios asignados al rol',
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'TWEntityId',
          type: 'check'
        },
        {
          label: 'No.',
          key: 'EntityNumber',
          sortable: true
        },
        {
          label: 'Nombre de pila',
          key: 'FirstName',
          sortable: true
        },
        {
          label: 'Apellido',
          key: 'LastName',
          sortable: true
        },
        {
          label: 'Tipo',
          key: 'Type',
          sortable: true
        },
        {
          label: 'Funciones adicionales',
          key: 'AdditionalRolesCount',
          type: Number,
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'TWEntityId',
          type: 'check'
        },
        {
          label: 'No.',
          key: 'EntityNumber',
          sortable: true
        },
        {
          label: 'Nombre de pila',
          key: 'FirstName',
          sortable: true
        },
        {
          label: 'Apellido',
          key: 'LastName',
          sortable: true
        },
        {
          label: 'Tipo',
          key: 'Type',
          sortable: true
        },
        {
          label: 'Funciones adicionales',
          key: 'AdditionalRolesCount',
          type: Number,
          sortable: true
        }
      ]
    },
    roleNameLabel: 'Nombre del rol:',
    usersAssignedToRoleCountLabel: 'Usuarios asignados al rol:',
    addUserToRoleButton: 'Agregar usuario al rol',
    backToRoles: 'Volver a Roles',
    dropdownActions: [
      {
        title: 'Reasignar',
        actionName: 'reassignItem',
        permissions: []
      },
      {
        title: 'Eliminar',
        actionName: 'removeItem',
        permissions: []
      }
    ],
    removalOfUsersModal: {
      title: 'Eliminar usuario(s)',
      confirmMessage: '¿Está seguro de que desea eliminar los siguientes ' +
        'usuarios del rol {roleName}?',
      messageBody: 'Los siguientes usuarios se convertirán de nuevo en empleados- ' +
        '<br> <i>quitarles el acceso al producto ToolWatch</i>'
    },
    reassignUsersModal: {
      title: 'Reasignar usuarios',
      currentRoleLabel: 'Rol actual asignado: ',
      newRoleLabel: 'Nuevo rol para asignar usuario(s) ',
      tableHeader: {
        desktop: [
          {
            label: '',
            key: 'RoleName',
            type: 'check'
          },
          {
            label: 'Nombre de rol',
            key: 'RoleName',
            sortable: true
          },
          {
            label: 'Descripción del rol',
            key: 'RoleDescription',
            sortable: true
          }
        ],
        mobile: [
          {

            label: '',
            key: 'RoleName',
            type: 'check'
          },
          {
            label: 'Nombre de rol',
            key: 'RoleName',
            sortable: true
          },
          {
            label: 'Descripción del rol',
            key: 'RoleDescription',
            sortable: true
          }
        ]
      }
    },
    addUsersToRoleModal: {
      title: 'Agregar usuario(s) al rol',
      roleNameLabel: 'Nombre de rol: ',
      usersAssignedToRoleCountLabel: 'Usuarios asignados al rol:',
      dropdownActions: [
        {
          title: 'Editar',
          actionName: 'editItem',
          permissions: []
        }
      ],
      tableHeader: {
        desktop: [
          {
            label: 'esTwUsuario',
            key: 'Username',
            type: 'user'
          },
          {
            label: '',
            key: 'Id',
            type: 'check'
          },
          {
            label: 'No.',
            key: 'Number',
            sortable: true
          },
          {
            label: 'Nombre de pila',
            key: 'FirstName',
            sortable: true
          },
          {
            label: 'Apellido',
            key: 'LastName',
            sortable: true
          },
          {
            label: 'Título',
            key: 'Title',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'esTwUsuario',
            key: 'username',
            type: 'icon'
          },
          {
            label: '',
            key: 'description',
            type: 'check'
          },
          {
            label: 'No.',
            key: 'EntityNumber',
            sortable: true
          },
          {
            label: 'Nombre de pila',
            key: 'FirstName',
            sortable: true
          },
          {
            label: 'Apellido',
            key: 'LastName',
            sortable: true
          },
          {
            label: 'Título',
            key: 'Title',
            sortable: true
          }
        ]
      }
    }
  }
}

export default userRoles

const PickedItemsList = {
  tableHeaderQuantityTool: {
    desktop: [
      {
        label: 'Number',
        key: 'PickTicketNumber',
        type: Number,
        sortable: true
      },
      {
        label: 'Created',
        key: 'DatePicked',
        sortable: true
      },
      {
        label: 'State',
        key: 'State',
        sortable: true
      },
      {
        label: 'Picked',
        key: 'Quantity',
        type: String,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Number',
            key: 'PickTicketNumber',
            type: Number,
            sortable: true
          },
          {
            label: 'Created',
            key: 'DatePicked',
            sortable: true
          },
          {
            label: 'State',
            key: 'State',
            sortable: true
          },
          {
            label: 'Picked',
            key: 'Quantity',
            type: String,
            sortable: true
          }
        ]
      }
    ]
  },
  tableHeaderUniqueTool: {
    desktop: [
      {
        label: 'Number',
        key: 'PickTicketNumber',
        type: Number,
        sortable: true
      },
      {
        label: 'Created',
        key: 'DatePicked',
        sortable: true
      },
      {
        label: 'State',
        key: 'State',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Number',
            key: 'PickTicketNumber',
            type: Number,
            sortable: true
          },
          {
            label: 'Created',
            key: 'DatePicked',
            sortable: true
          },
          {
            label: 'State',
            key: 'State',
            sortable: true
          }
        ]
      }
    ]
  },
  pickedItemStatsQuantityMsg: 'This Pick will take the available inventory for this item into negatives.Are you sure you wish to proceed?',
  pickedItemStatsUniqueyMsg: 'The Item you are picking has already been picked on another pick ticket. Do you want to reallocate the item to this pick ticket?',
  pickedItemStateTitle: 'Warning',
  pickedItemsTableHeader: 'Pick Ticket(s) with Item:',
  pickedItemStatsWarningMsg: 'This will remove the item from the previous pick ticket.',
  itemStatusQuantityMsg: 'The quantity you are picking will take your inventory into the negatives. Are you sure you wish to continue?'
}
export default PickedItemsList

const twGridColumnsDisplay = {
  columnsSetting: {
    dropDownList: {
      option1: 'Seleccionar columnas para mostrar',
      option2: 'Restablecer anchos de columna',
      option3: 'Restablecen a los predeterminados'
    },
    panelLabel: {
      modalTitle: 'Seleccionar columnas para mostrar',
      l_label: 'Columnas disponibles',
      r_label: 'Columnas visibles'
    }
  },
  buttons: {
    change: 'Guardar cambios',
    btncancel: 'Cancelar'
  },
  switch: {
    text: 'Ajuste de texto de columna',
    on: 'Encender',
    off: 'Apagar'
  }
}
export default twGridColumnsDisplay

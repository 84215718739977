const retHistory = {
  tableHeader: {
    desktop: [
      {
        label: 'Estado'
      },
      {
        label: 'Fecha'
      },
      {
        label: 'Boleto'
      },
      {
        label: 'Proyecto'
      },
      {
        label: 'Centro de costos'
      },
      {
        label: 'Artículos'
      }
    ],
    mobile: [
      {
        label: 'Estado'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Fecha'
          },
          {
            label: 'Boleto'
          },
          {
            label: 'Proyecto'
          },
          {
            label: 'Centro de costos'
          },
          {
            label: 'Artículos'
          }
        ]
      }
    ]
  }
}

export default retHistory

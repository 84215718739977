import cart from './cart.js'
import returnTicket from './returnTicket.js'
import user from './user.js'
import search from './search.js'
import ship from './ship.js'
import loader from './loader.js'
import product from './product.js'
import procore from './procore.js'
import view from './view.js'
import reports from './reports'
import catalog from './catalog'
import billing from './billing'
import transferTicket from './transferTicket'
import serviceCalibration from './serviceCalibration.js'
import serviceRequests from './serviceRequests.js'
import pickTicket from './pickTicket'
import templates from './templates.js'
import workOrder from './workOrder.js'
import serviceSchedule from './serviceSchedule.js'
import countInventory from './countInventory.js'
import receivingTickets from './receivingTickets.js'
import itemBrowser from './itemBrowser.js'
import taskBrowser from '../tasks/taskBrowser.js'
import purchaseOrder from './purchaseOrder.js'
import retiredTools from './retiredTools.js'
import kitDetail from './kitDetail.js'
import descriptions from './descriptions.js'
import recurrence from './recurrence.js'
import recurrenceSchedule from './recurrenceSchedule.js'
import orgSettings from './orgSettings.js'
import reservationCart from './reservationCart.js'
import brandThemes from './brandTheme.js'
import requestCache from './requestCache.js'
import customDashboards from './customDashboards.js'
import fuelCards from './fuelCards.js'
import navigation from './navigation.js'
import workflowBuilder from './workflowBuilder.js'
import safetyPlan from './safetyPlan.js'
import schedulingCart from './schedulingCart.js'
import Vue from 'vue'
import Vuex from 'vuex'
import requisitionRequest from './requisitionRequest.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    cart,
    returnTicket,
    search,
    loader,
    product,
    ship,
    procore,
    reports,
    view,
    catalog,
    billing,
    transferTicket,
    serviceCalibration,
    serviceRequests,
    pickTicket,
    templates,
    workOrder,
    purchaseOrder,
    serviceSchedule,
    countInventory,
    receivingTickets,
    itemBrowser,
    taskBrowser,
    retiredTools,
    kitDetail,
    descriptions,
    recurrence,
    recurrenceSchedule,
    orgSettings,
    reservationCart,
    brandThemes,
    requestCache,
    customDashboards,
    fuelCards,
    navigation,
    workflowBuilder,
    safetyPlan,
    schedulingCart,
    requisitionRequest
  }
})

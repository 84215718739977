export default {
  state: () => ({
    companyId: 0,
    projectId: 0,
    settings: {},
    isVerified: false
  }),
  mutations: {
    setCompanyId: (state, id) => {
      state.companyId = id
    },
    setProjectId: (state, id) => {
      state.projectId = id
    },
    setSettings: (state, data) => {
      state.settings = data
    },
    setVerified: (state, flag) => {
      state.isVerified = flag
    }
  },
  actions: {
    setProcoreCompanyProject ({ commit }, { companyId, projectId }) {
      commit('setCompanyId', companyId)
      commit('setProjectId', projectId)
    },
    setSettings ({ commit, state }, data) {
      if (state.isVerified) {
        return
      }
      commit('setSettings', data)
    }
  },
  getters: {
    settings: (state) => {
      return state.settings
    },
    companyId: (state) => {
      return state.companyId
    },
    projectId: (state) => {
      return state.projectId
    },
    isProcoreIntegrationVerified: (state) => {
      return state.isVerified
    }
  }
}

import axios from 'axios'
import { getFormatedColorRBGColor, setCssColors } from '../helpers/utils'

export default {
  state: () => ({
    brand: {
      title: null,
      homepageUrl: null,
      logoName: null,
      theme: {
        primaryColor: null,
        primaryColorRGB: null,
        secondaryColor: null,
        iboxPrimaryColor: null,
        warningHoverDisabledColor: null,
        badgeSecondaryColor: null,
        foreColor: null,
        badgeSecondaryPlatformColor: null
      },
      alignTopLogo: null
    }
  }),
  mutations: {
    setBrandTheme (state, brand) {
      state.brand = brand
    },
    setBrandLogo (state, brandLogo) {
      state.brand.logoName = brandLogo
    },
    setAlignTopLogo (state, alignTopLogo) {
      state.brand.alignTopLogo = alignTopLogo
    }
  },
  getters: {
    brandLogo (state) {
      return state.brand.logoName
    },
    secondaryColor (state) {
      return state.brand.theme.secondaryColor
    },
    warningHoverDisabledColor (state) {
      return state.brand.theme.warningHoverDisabledColor
    },
    primaryColor (state) {
      return state.brand.theme.primaryColor
    },
    primaryColorRGB (state) {
      return state.brand.theme.primaryColorRGB
    },
    alignTopLogo (state) {
      return state.brand.alignTopLogo
    }
  },
  actions: {
    async loadThemes (context) {
      const brandTheme = {
        title: null,
        homepageUrl: null,
        logoName: 'logo.svg',
        theme: {
          primaryColor: '#d29735', // Toolwatch defaut color d29735
          primaryColorRGB: '207,147,51', // Toolwatch defaut RGB color 207,147,51
          secondaryColor: '#D09433', // Toolwatch defaut color D09433
          iboxPrimaryColor: '#BA7C0A', // Toolwatch defaut color BA7C0A
          warningHoverDisabledColor: '#ebb241', // Toolwatch defaut color ebb241
          badgeSecondaryColor: '#FBE7B5',
          foreColor: '#ffffff', // Toolwatch defaut color ffffff,
          badgeSecondaryPlatformColor: '#FBE7B5'
        }
      }
      if (process.env.VUE_APP_BRAND === 'TOOLOPS') {
        const params = JSON.stringify({
          licenseSource: JSON.stringify(0) // Desktop
        })
        const { data } = await axios.post('/api/core/ThemeService/Get', params)
        if (data.Body) {
          brandTheme.title = data.Body.DesktopTitleBarProductDescription
          brandTheme.homepageUrl = data.Body.DesktopHomepageUrl

          const gradientStart = data.Body.ColorPalette.GradientStart ? data.Body.ColorPalette.GradientStart : '0, 205, 0'
          if (gradientStart) {
            brandTheme.theme.primaryColorRGB = gradientStart
            brandTheme.theme.primaryColor = getFormatedColorRBGColor(gradientStart)
            brandTheme.theme.iboxPrimaryColor = getFormatedColorRBGColor(gradientStart)
          }
          const gradientEnd = data.Body.ColorPalette.GradientEnd ? data.Body.ColorPalette.GradientEnd : '0, 235, 0'
          if (gradientEnd) {
            brandTheme.theme.warningHoverDisabledColor = getFormatedColorRBGColor(gradientEnd)
          }
          const gradientBase = data.Body.ColorPalette.Base ? data.Body.ColorPalette.Base : '0, 220, 0'
          if (gradientBase) {
            brandTheme.theme.secondaryColor = getFormatedColorRBGColor(gradientBase)
            brandTheme.theme.badgeSecondaryColor = getFormatedColorRBGColor(gradientBase)
            brandTheme.theme.badgeSecondaryPlatformColor = getFormatedColorRBGColor(gradientBase)
          }
          brandTheme.theme.foreColor = data.Body.ColorPalette.ForeColor ? data.Body.ColorPalette.ForeColor : '#fff'
          const logoName = data.Body.BrandLogoName ? data.Body.BrandLogoName : 'sageto.png'
          if (logoName) {
            brandTheme.logoName = logoName
          }
        }
      } else {
        brandTheme.logoName = 'align_logo_ops.png'
        brandTheme.theme.primaryColor = '#4c84fc'
        brandTheme.theme.primaryColorRGB = '76,132,252'
        brandTheme.theme.secondaryColor = '#4c84fc'
        brandTheme.theme.badgeSecondaryColor = '#f4f7f8'
        brandTheme.theme.iboxPrimaryColor = '#4c84fc'
        brandTheme.theme.warningHoverDisabledColor = '#4c84fc'
        brandTheme.theme.badgeSecondaryPlatformColor = '#D7E3F7'
        brandTheme.alignTopLogo = 'align_logo_ops.png'
      }
      context.commit('setBrandTheme', brandTheme)
      setCssColors('--primary-color', brandTheme.theme.primaryColor)
      setCssColors('--secondary-color', brandTheme.theme.secondaryColor)
      setCssColors('--badge-secondary-color', brandTheme.theme.badgeSecondaryColor)
      setCssColors('--ibox-primary-color', brandTheme.theme.iboxPrimaryColor)
      setCssColors('--warning-hover-disabled-color', brandTheme.theme.warningHoverDisabledColor)
      setCssColors('--badge-secondary-platform-color', brandTheme.theme.badgeSecondaryPlatformColor)
    }
  }
}

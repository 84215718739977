<template>
<div>
    <div class="modal-helpandsupport-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header popup-bgcolor" style="border-bottom: none">
          <h5 class="modal-title" style="font-size: 22px">{{$t("buttons.HelpandSupport")}}</h5>
          <button class="close" type="button" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row" style="padding-bottom: 10px;">
            <div class="col-sm-2 padding-0 text-center">
              <img  src="../../assets/icons/call_icon.svg" class = "icon-size"/>
            </div>
            <div class="col-sm-10 padding-0">
              <h4 class="margin-0 margin-top-3">{{$t("buttons.giveUsCall")}}</h4>
              <div>{{$t("buttons.giveUsCallTxt")}}</div>
            </div>
            </div>
            <div v-if="this.$store.getters.isShowIntercom" class="row box-design" @click="openChatWindow($event)">
              <div class="col-sm-2 padding-0 text-center margin-top-10">
              <img  src="../../assets/icons/chat_icon.svg" class = "icon-size"/>
                </div>
            <div class="col-sm-10 padding-0 margin-top-10">
              <h4 class="margin-0 margin-top-3">{{$t("buttons.chat")}}</h4>
              <div>{{$t("buttons.chatText")}}</div>
            </div>
            </div>
            <div class="row box-design" @click="openDocumentation($event)">
            <div class="col-sm-2 padding-0 text-center margin-top-10">
              <img  src="../../assets/icons/faq_icon.svg" class = "icon-size"/>
            </div>
            <div class="col-sm-10 padding-0 margin-top-10">
              <h4 class="margin-0 margin-top-3">{{$t("buttons.documentation")}}</h4>
              <div>{{$t("buttons.documentationText")}}</div>
            </div>
          </div>
          <div class="row box-design" @click="openSupportTicket($event)">
            <div class="col-sm-2 padding-0 text-center">
              <img  src="../../assets/icons/ticket_icon.svg" class = "icon-size" style="margin-top: 10px;"/>
            </div>
            <div class="col-sm-10 padding-0 margin-top-10">
              <h4 class="margin-0 margin-top-3">{{$t("buttons.supportTicket")}}</h4>
              <div>{{$t("buttons.supportTicketText")}}</div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
<script>
import $ from 'jquery'
import { EventBus } from '../../helpers/eventBus'
import { trackAnalyticEvents } from '../../helpers/utils.js'
export default {
  name: 'HelpAndSupportModel',
  methods: {
    close () {
      $('#helpAndSupportModel').modal('hide')
    },
    openDocumentation () {
      trackAnalyticEvents('Help And Support', 'Documentation')
      window.open('https://help.aligntech.co', '_blank')
    },
    openSupportTicket () {
      trackAnalyticEvents('Help And Support', 'Support Ticket')
      window.open('https://aligntech.co/customers/support-customer-services', '_blank')
    },
    openChatWindow () {
      $('#helpAndSupportModel').modal('hide')
      trackAnalyticEvents('Help And Support', 'Chat')
      EventBus.$emit('openIntercom')
    }
  }
}
</script>
<style>
.tw-shipping-modal .modal-helpandsupport-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  width: 34%;
  height: 50%;
  /* max-width: 1350px; */
  margin: 0;
  display: inline-block;
}
</style>
<style scoped>
.popup-bgcolor {
 background-color: white !important;
}
.padding-0 {
  padding: 0px;
}
.text-center {
  text-align: center;
}
.margin-0 {
  margin: 0px;
}
.margin-top-3 {
 margin-top: 3px;
 font-weight: bold;
}
.icon-size {
  width: 50px;
  height: 50px;
}
.box-design {
  border: 2px solid #ECECE6;
  border-radius: 5px;
  margin-bottom:10px;
  cursor: pointer;
  width: auto;
  height: 90px;
}
.margin-top-10 {
  margin-top: 12px;
}
</style>

export default {
  state: () => ({
    eventRecurrenceSchedule: {
      RangeStartDate: null,
      RangeEndDate: null,
      RangeLimit: null,
      RangeMaxOccurrences: null,
      OccurrenceStartTime: null,
      OccurrenceDurationMinutes: null,
      PatternOccurrenceOfDayInMonth: null,
      PatternInterval: null,
      PatternFrequency: null,
      PatternDaysOfWeek: null,
      PatternDayOfMonth: null,
      PatternMonthOfYear: null,
      PatternType: null
    },
    isRemovingRecurrenceSchedule: false
  }),
  mutations: {
    setRecurrenceSchedule (state, recurrenceSchedule) {
      state.eventRecurrenceSchedule = recurrenceSchedule
    },
    resetRecurrenceSchedule (state, isReset) {
      // isReset = true: reset. isReset = false: remove
      state.eventRecurrenceSchedule = {
        RecurrenceScheduleId: isReset ? null : state.eventRecurrenceSchedule.RecurrenceScheduleId,
        RangeStartDate: null,
        RangeEndDate: null,
        RangeLimit: null,
        RangeMaxOccurrences: null,
        OccurrenceStartTime: null,
        OccurrenceDurationMinutes: null,
        PatternOccurrenceOfDayInMonth: null,
        PatternInterval: null,
        PatternFrequency: null,
        PatternDaysOfWeek: null,
        PatternDayOfMonth: null,
        PatternMonthOfYear: null,
        PatternType: null
      }
      state.isRemovingRecurrenceSchedule = false
    },
    setIsRemovingFlag (state, flag) {
      state.isRemovingRecurrenceSchedule = flag
    }
  },
  getters: {
    getRecurrenceSchedule (state) {
      return state.eventRecurrenceSchedule
    },
    isRemovingRecurrenceSchedule (state) {
      return state.isRemovingRecurrenceSchedule
    }
  }
}

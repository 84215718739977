const workOrderCharges = {
  tableHeader: {
    desktop: [
      {
        label: 'Task',
        type: Number,
        key: 'workOrderTaskNumber',
        sortable: true
      },
      {
        label: 'Type',
        key: 'chargeType',
        sortable: true
      },
      {
        label: 'Item No.',
        key: 'masterNumber',
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true
      },
      {
        label: 'Qty',
        key: 'qty',
        sortable: true
      },
      {
        label: 'Cost',
        key: 'cost',
        type: 'NumberWithTwoDigits',
        sortable: true
      },
      {
        label: 'Unit',
        key: 'uomId',
        type: 'Guid',
        listType: 16,
        sortable: true
      },
      {
        label: 'Parts',
        key: 'parts',
        type: 'NumberWithTwoDigits',
        sortable: true
      },
      {
        label: 'Labor',
        key: 'labor',
        type: 'NumberWithTwoDigits',
        sortable: true
      },
      {
        label: 'Other',
        key: 'other',
        type: 'NumberWithTwoDigits',
        sortable: true
      },
      {
        label: 'Cost Center',
        key: 'costCenterId',
        type: 'Guid',
        listType: 3,
        sortable: true
      },
      {
        label: 'Cost Code',
        key: 'costCodeId',
        type: 'Guid',
        listType: 4,
        sortable: true
      },
      {
        label: 'Service Class',
        key: 'serviceClassId',
        type: 'Guid',
        listType: 33,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Task',
            type: Number,
            key: 'workOrderTaskNumber',
            sortable: true
          },
          {
            label: 'Type',
            key: 'chargeType',
            sortable: true
          },
          {
            label: 'Item No.',
            key: 'masterNumber',
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'Qty',
            key: 'qty',
            sortable: true
          },
          {
            label: 'Cost',
            key: 'cost',
            type: 'NumberWithTwoDigits',
            sortable: true
          },
          {
            label: 'Unit',
            key: 'uomId',
            type: 'Guid',
            listType: 16,
            sortable: true
          },
          {
            label: 'Parts',
            key: 'parts',
            type: 'NumberWithTwoDigits',
            sortable: true
          },
          {
            label: 'Labor',
            key: 'labor',
            type: 'NumberWithTwoDigits',
            sortable: true
          },
          {
            label: 'Other',
            key: 'other',
            type: 'NumberWithTwoDigits',
            sortable: true
          },
          {
            label: 'Cost Center',
            key: 'costCenterId',
            type: 'Guid',
            listType: 3,
            sortable: true
          },
          {
            label: 'Cost Code',
            key: 'costCodeId',
            type: 'Guid',
            listType: 4,
            sortable: true
          },
          {
            label: 'Service Class',
            key: 'serviceClassId',
            type: 'Guid',
            listType: 33,
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default workOrderCharges

import CONST from '../../helpers/const'

const recurrenceSchedule = {
  recurrence: {
    title: 'Recurrence Schedule',
    eventRecurrence: 'Recurrence Schedule',
    messages: {
      saved: 'Recurrence created successfully.',
      delete: 'Recurrence deleted successfully'
    },
    pattern: {
      title: 'Recurrence pattern',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      yearly: 'Yearly',
      weekday: 'Every weekday',
      every: 'Every',
      days: 'day(s)',
      recurEvery: 'Recur every',
      weeksOn: 'week(s) on',
      day: 'Day',
      ofEvery: 'of every',
      months: 'month(s)',
      the: 'The',
      of: 'of'
    },
    daysOfWeek: [
      {
        name: 'Sunday',
        value: CONST.SUNDAY
      },
      {
        name: 'Monday',
        value: CONST.MONDAY
      },
      {
        name: 'Tuesday',
        value: CONST.TUESDAY
      },
      {
        name: 'Wednesday',
        value: CONST.WEDNESDAY
      },
      {
        name: 'Thursday',
        value: CONST.THURSDAY
      },
      {
        name: 'Friday',
        value: CONST.FRIDAY
      },
      {
        name: 'Saturday',
        value: CONST.SATURDAY
      },
      {
        name: 'day',
        value: CONST.ALLDAYS
      },
      {
        name: 'weekday',
        value: CONST.WEEKDAY
      },
      {
        name: 'weekend day',
        value: CONST.WEEKENDDAY
      }
    ],
    weekNumber: [
      {
        name: 'first',
        value: 1
      },
      {
        name: 'second',
        value: 2
      },
      {
        name: 'third',
        value: 3
      },
      {
        name: 'fourth',
        value: 4
      },
      {
        name: 'last',
        value: 5
      }
    ],
    monthOfYear: [
      {
        name: 'January',
        value: 1
      },
      {
        name: 'February',
        value: 2
      },
      {
        name: 'March',
        value: 3
      },
      {
        name: 'April',
        value: 4
      },
      {
        name: 'May',
        value: 5
      },
      {
        name: 'June',
        value: 6
      },
      {
        name: 'July',
        value: 7
      },
      {
        name: 'August',
        value: 8
      },
      {
        name: 'September',
        value: 9
      },
      {
        name: 'October',
        value: 10
      },
      {
        name: 'November',
        value: 11
      },
      {
        name: 'December',
        value: 12
      }
    ],
    warning: {
      title: 'Appointment Recurrence',
      message: 'The "PatternDaysOfWeek" property cannot be set to "None" when the "PatternFrequency" property is set to "Weekly"'
    }
  }
}

export default recurrenceSchedule

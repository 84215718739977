const report = {
  customReports: {
    title: 'Reportes personalizados',
    noMsg: 'Sin datos de informe',
    noFolderMsg: 'Sin carpeta',
    tabs: {
      0: {
        title: 'Nuevo reporte'
      },
      1: {
        title: 'Editar reporte'
      },
      2: {
        title: 'Eliminar informe',
        key: 'DeleteCustomReport'
      },
      3: {
        title: 'Ejecutar reporte'
      },
      4: {
        title: 'Exportar Reporte'
      },
      5: {
        title: 'Nueva carpeta'
      },
      6: {
        title: 'Enviar comentarios'
      }
    },
    actions: {
      0: 'Reportes personalizados',
      1: 'Reciente',
      2: 'Creado por mí',
      3: 'Reportes privados',
      4: 'Reportes públicos',
      5: 'Todos los reportes',
      6: 'Carpeta',
      7: 'Todas las carpetas',
      8: 'Creado por mí',
      9: 'Compartido conmigo'
    },
    shareFolder: {
      title: 'Compartir carpeta',
      labels: {
        0: 'Tipo',
        1: 'Destinatarios',
        2: 'Roles'
      },
      roles: {
        0: 'Usuarios',
        1: 'Todos los usuarios',
        2: 'Roles',
        3: 'Todos los supervisores'
      },
      tag: 'Share'
    },
    newReport: {
      title: 'Agregar reporte',
      labels: {
        0: 'Tipo',
        1: 'Subtipo'
      }
    },
    newFolder: {
      title: 'Agregar carpeta',
      labels: 'Nombre de la carpeta'
    },
    exportReport: {
      title: 'Reporte de exportacion'
    },
    editReport: {
      actions: {
        1: {
          title: 'Guardar'
        },
        2: {
          title: 'Ejecutar'
        }
      },
      addOptions: {
        0: 'Agregar Columna...',
        1: 'Agregar Filtro...',
        2: 'Agregar Grupo...',
        3: 'Agregar Clasificacion...',
        4: 'Agregar Lógica...'
      },
      operators: {
        0: 'igual',
        1: 'no es igual a',
        2: 'menos que',
        3: 'mayor que',
        4: 'menor o igual',
        5: 'mayor o igual',
        6: 'contiene',
        7: 'no contiene',
        8: 'inicia',
        9: 'termina',
        10: 'está en blanco'
      },
      customReportLogic: {
        0: 'Y',
        1: 'O',
        3: '(',
        4: ')',
        5: 'Añadir nueva',
        6: 'Lógica de filtro',
        7: 'Validar'
      },
      emptyMessage: 'La fórmula no puede estar vacía',
      filterOptions: {
        0: {
          label: 'Todo el Tiempo',
          value: 'AllTime:'
        },
        1: {
          label: 'Personalizado',
          value: 'Custom:{from}to{to}'
        },
        3: {
          'Año FISCAL': [
            {
              label: 'AF Actual',
              value: 'FiscalYear:0'
            },
            {
              label: 'AF Anterior',
              value: 'FiscalYear:-1'
            },
            {
              label: '2 AF Anteriores',
              value: 'FiscalYear:-2to-1'
            },
            {
              label: 'Hace 2 AF',
              value: 'FiscalYear:-2'
            },
            {
              label: 'AF Siguiente',
              value: 'FiscalYear:1'
            },
            {
              label: 'AF Presente y Anterior',
              value: 'FiscalYear:-1to0'
            },
            {
              label: '2 AF Presente y Anterior',
              value: 'FiscalYear:-2to0'
            },
            {
              label: 'AF Presente y Siguiente',
              value: 'FiscaYear:0to1'
            }
          ],
          'Trimestre Fiscal': [
            {
              label: 'T Actual',
              value: 'FiscalQuarter:0'
            },
            {
              label: 'T Actual y Siguiente',
              value: 'FiscalQuarter:0to1'
            },
            {
              label: 'T Actual y Anterior',
              value: 'FiscalQuarter:-1to0'
            },
            {
              label: 'T Siguiente',
              value: 'FiscalQuarter:1'
            },
            {
              label: 'T Anterior',
              value: 'FiscalQuarter:-1'
            },
            {
              label: 'Actual y Siguientes 3 T',
              value: 'FiscalQuarter:0to3'
            }
          ],
          CALENDARIO: [
            {
              label: 'Calendario Actual',
              value: 'CalendarYear:0'
            },
            {
              label: 'Calendario Anterior',
              value: 'CalendarYear:-1'
            },
            {
              label: '2 Calendarios Anteriores',
              value: 'CalendarYear:-2to1'
            },
            {
              label: '2 Calendarios Atras',
              value: 'CalendarYear:-2'
            },
            {
              label: 'Calendario Siguiente',
              value: 'CalendarYear:1'
            },
            {
              label: 'Calendario Actual y Anterior',
              value: 'CalendarYear:-1to0'
            },
            {
              label: '2 Calendarios Actual y Anterior',
              value: 'CalendarYear:-2to0'
            },
            {
              label: 'Calendario Actual y Siguiente',
              value: 'CalendarYear:0to1'
            }
          ],
          'CALENDARIO TRIMESTRAL': [
            {
              label: 'T Actual',
              value: 'CalendarQuarter:0'
            },
            {
              label: 'T Actual y Siguiente',
              value: 'CalendarQuarter:0to1'
            },
            {
              label: 'T Siguiente y Actual',
              value: 'CalendarQuarter:-1to0'
            },
            {
              label: 'T Siguiente',
              value: 'CalendarQuarter:1'
            },
            {
              label: 'T Anterior',
              value: 'CalendarQuarter:-1'
            },
            {
              label: '3 T Actuales y Siguientes',
              value: 'CalendarQuarter:0to3'
            }
          ],
          'CALENDARIO MENSUAL': [
            {
              label: 'Mes Pasado',
              value: 'CalendarMonth:-1'
            },
            {
              label: 'Mes Presente',
              value: 'CalendarMonth:0'
            },
            {
              label: 'Mes Siguiente',
              value: 'CalendarMonth:1'
            },
            {
              label: 'Mes Actual y Anterior',
              value: 'CalendarMonth:-1to0'
            },
            {
              label: 'Mes Presente y Siguiente',
              value: 'CalendarMonth:0to1'
            }
          ],
          'CANLENDARIO SEMANAL': [
            {
              label: 'Semana Pasada',
              value: 'CalendarWeek:-1'
            },
            {
              label: 'Esta SemanaThis Week',
              value: 'CalendarWeek:0'
            },
            {
              label: 'Semana Siguiente',
              value: 'CalendarWeek:1'
            }
          ],
          DAY: [
            {
              label: 'Ayer',
              value: 'Day:-1'
            },
            {
              label: 'Hoy',
              value: 'Day:0'
            },
            {
              label: 'Mañana',
              value: 'Day:1'
            },
            {
              label: 'Ultimos 7 Dias',
              value: 'Day:-6to0'
            },
            {
              label: 'Ultimos 30 Dias',
              value: 'Day:-29to0'
            },
            {
              label: 'Ultimos 60 Dias',
              value: 'Day:-59to0'
            },
            {
              label: 'Ultimos 90 Dias',
              value: 'Day:-89to0'
            },
            {
              label: 'Ultimos 120 Dias',
              value: 'Day:-119to0'
            },
            {
              label: 'Siguiente 7 Dias',
              value: 'Day:0to6'
            },
            {
              label: 'Siguiente 30 Dias',
              value: 'Day:0to29'
            },
            {
              label: 'Siguiente 60 Dias',
              value: 'Day:0to59'
            },
            {
              label: 'Siguiente 90 Dias',
              value: 'Day:0to89'
            },
            {
              label: 'Siguiente 120 Dias',
              value: 'Day:0to119'
            }
          ]
        }
      },
      groupSetting: {
        0: 'Filas de detalles',
        1: 'Subtotales',
        2: 'Gran total'
      },
      filterLabels: {
        0: 'Ascendente',
        1: 'Descendiendo',
        2: 'Exacta',
        3: 'Alcance',
        4: 'Operadora',
        5: 'Valor',
        6: 'Fecha de inicio',
        7: 'Fecha final',
        8: 'es',
        9: 'Verdadera',
        10: 'Falsa',
        12: 'Tipo de valor',
        13: 'Ingrese valor',
        14: 'ID de usuario actual',
        15: 'Nombre',
        16: 'Descripción',
        17: 'Carpeta',
        18: 'Informe privado'
      },
      warning: 'Ejecute el informe para ver los resultados, los resultados de vista previa son limitados',
      dataLimitInfo: 'Mostrando los primeros 20000 registros. Exporte el informe completo para ver todos los registros.'
    },
    runReport: {
      actions: {
        0: {
          title: 'Editar'
        },
        1: {
          title: 'Exportar'
        }
      }
    },
    warning: {
      title: 'Advertencia',
      text: 'Seleccione un informe.'
    },
    confirmDelete: {
      title: 'Confirmar eliminación',
      text: '¿Está seguro de que desea eliminar el informe personalizado?'
    },
    longRunningReport: {
      title: 'Cargando...',
      message: 'Este informe está tardando más de lo esperado. Puede salir de esta página' +
     ' y aparecerá una notificación en el centro de mensajes cuando el informe esté listo.'
    },
    disabledEditText: 'No se puede editar el informe que no está guardado. Sólo puede editar los informes que están guardados',
    disabledExportText: 'No se puede exportar el informe que no está guardado. Sólo puede exportar informes que estén guardados'
  },
  scheduledReports: {
    title: 'Reportes programados',
    noMsg: 'No hay reportes programados',
    placeholder: 'Search Scheduled Reports',
    scheduleDlg: {
      title: {
        0: 'Crear informe programado',
        1: 'Editar informe programado',
        2: 'Eliminar informe programado'
      },
      labels: {
        0: 'Informe',
        1: 'Frecuencia',
        2: 'Diario',
        3: 'Semanal',
        4: 'Mensual',
        5: 'Hora',
        6: 'Días de la semana',
        7: 'Día de la semana',
        8: 'Número de registros',
        9: 'Enviar reporte solo si el recuento de registros es mayor que',
        format: 'Formato',
        html: 'HTML',
        pdf: 'PDF',
        csv: 'CSV'
      },
      days: {
        0: 'domingo',
        1: 'lunes',
        2: 'martes',
        3: 'miércoles',
        4: 'jueves',
        5: 'viernes',
        6: 'sábado'
      }
    }
  },
  cannedReports: {
    title: 'Selector de informes',
    actionHeader: {
      0: {
        title: 'Imprimir datos',
        icon: 'fas fa-print'
      },
      1: {
        title: 'Exportar datos',
        icon: 'fas fi-file-export'
      }
    },
    reports: 'Informes',
    options: {
      title: 'Opciones',
      labels: {
        0: 'Al imprimir etiquetas, utilice',
        1: 'Número de artículo',
        2: 'Código de barras',
        3: 'Layout',
        4: 'Agrupar por',
        5: 'Programada',
        6: 'Herramientas',
        7: 'Consumibles',
        8: 'Equipo'
      },
      countStatus: {
        0: 'Toda',
        1: 'Cerrada',
        2: 'Abierta'
      },
      assetTypes: [
        {
          key: 'A',
          value: 'Todas'
        },
        {
          key: 'TQ',
          value: 'Herramientas'
        },
        {
          key: 'M',
          value: 'Consumibles'
        }
      ],
      optionTexts: {
        10: 'Todas las empleadas y ubicaciones',
        17: 'Todos los vendedor',
        7: 'Todas las empleadas',
        9: 'Todas las ubicaciones',
        15: 'Todos los códigos de estado',
        24: 'Todos los servicios solicitados'
      },
      ageLists: [
        {
          key: '45',
          value: '45 o más días'
        },
        {
          key: '90',
          value: '90 o más días'
        },
        {
          key: '180',
          value: '180 o más días'
        },
        {
          key: '-1',
          value: 'Cualquier días'
        }
      ],
      expirationDays: [
        {
          key: 0,
          value: 'Caduca en los próximos 30 días'
        },
        {
          key: 1,
          value: 'Caduca en los próximos 90 días'
        },
        {
          key: 2,
          value: 'Actualmente en garantía'
        },
        {
          key: 3,
          value: 'Actualmente fuera de garantía'
        },
        {
          key: 4,
          value: 'Todas las herramientas con información de garantía'
        }
      ],
      chargeTypes: [
        {
          key: 0,
          value: 'Costo de trabajo'
        },
        {
          key: 1,
          value: 'Facturación'
        }
      ],
      collaborationStatus: {
        0: 'Toda',
        1: 'Leer',
        2: 'No leído'
      },
      ticketTypes: [
        {
          key: '0',
          value: '--Toda--'
        },
        {
          key: 'PickTicket',
          value: 'Recoger boleto'
        },
        {
          key: 'Transfer',
          value: 'Transferir'
        },
        {
          key: 'Requisition',
          value: 'Requisar'
        },
        {
          key: 'RequisitionRequest',
          value: 'Solicitud de Requisición'
        }
      ]
    },
    preview: {
      title: 'Presentar',
      default: 'No hay muestra disponible'
    }
  },
  customDashboard: {
    title: 'Tableros personalizados',
    tabs: {
      0: {
        title: 'Nuevo tablero'
      },
      1: {
        title: 'Editar tablero'
      },
      2: {
        title: 'Nueva carpeta'
      },
      3: {
        title: 'Seleccionar tableros de módulo'
      },
      4: {
        title: 'Copiar panel'
      }
    },
    actions: {
      0: 'Tablero personalizado',
      1: 'Reciente',
      2: 'Creado por mí',
      3: 'Tablero privado',
      4: 'Tablero público',
      5: 'Todo el panel',
      6: 'Carpeta',
      7: 'Todas las carpetas',
      8: 'Creado por mí',
      9: 'Comparte conmigo',
      10: 'Ahorrar',
      11: 'Actualizar'
    },
    warningEdit: 'Seleccione un tablero para editar.',
    warningEditDashboard: 'Predeterminado no editable.',
    customDashboardNewFolder: {
      title: 'Agregar carpeta',
      labels: 'Nombre de la carpeta'
    },
    assignDashboards: {
      title: 'Asignar paneles',
      labels: {
        0: 'Tablero del sistema',
        1: 'Panel de servicio',
        2: 'Recogida de billetes y traslados',
        3: 'Panel de compras'
      }
    },
    newDashboard: {
      title: 'Nuevo tablero'
    },
    editDashboard: {
      title: 'Editar panel'
    },
    addItem: {
      0: {
        title: 'Tablero trasero y personalizado',
        icon: 'fas fa-undo'
      }
    },
    saveDashboard: {
      title: 'guardar tablero',
      updateTitle: 'Actualizar panel',
      labels: {
        0: 'Nombre',
        1: 'Descripción',
        2: 'Carpeta',
        3: 'tablero privado',
        4: 'Seleccione la carpeta'
      }
    }
  }
}
export default report

const workOrder = {
  tableHeader: {
    desktop: [
      {
        label: ''
      },
      {
        label: 'No.'
      },
      {
        label: 'Estado'
      },
      {
        label: 'Creado'
      },
      {
        label: 'Estado'
      },
      {
        label: 'Nota de solicitud de servicio'
      },
      {
        label: 'Urgencia'
      },
      {
        label: 'Artículo No.'
      },
      {
        label: 'Código de barras'
      },
      {
        label: 'Fabricante'
      },
      {
        label: 'Modelo'
      },
      {
        label: 'Descripción del Artículo'
      },
      {
        label: 'Servicio solicitado'
      },
      {
        label: 'Programado para'
      },
      {
        label: 'Cargos totales'
      },
      {
        label: 'Asignación actual'
      }
    ],
    mobile: [
      {
        label: ''
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.'
          },
          {
            label: 'Estado'
          },
          {
            label: 'Creado'
          },
          {
            label: 'Estado'
          },
          {
            label: 'Nota de solicitud de servicio'
          },
          {
            label: 'Urgencia'
          },
          {
            label: 'Artículo No.'
          },
          {
            label: 'Código de barras'
          },
          {
            label: 'Fabricante'
          },
          {
            label: 'Modelo'
          },
          {
            label: 'Descripción del Artículo'
          },
          {
            label: 'Servicio solicitado'
          },
          {
            label: 'Programado para'
          },
          {
            label: 'Cargos totales'
          },
          {
            label: 'Asignación actual'
          }
        ]
      }
    ]
  },
  lineActions: [
    {
      title: 'Vista'
    },
    {
      title: 'Motivo del programa'
    },
    {
      title: 'Eliminar'
    }
  ]
}

export default workOrder

const dashboard = {
  title: {
    system: 'Panel del sistema',
    tools: 'Panel de herramientas',
    requisition: 'Tablero de solicitudes',
    service: 'Panel de servicio y calibración',
    pickTickets: 'Tablero de selección de tickets y transferencias',
    purchasing: 'Panel de compras',
    jobSite: 'Panel de Lugar de Trabajo'
  },
  panelLabel: {
    favorite: {
      title: 'Favoritos',
      modalTitle: 'Administrador de favoritos',
      l_label: 'Disponible',
      r_label: 'Seleccionado'
    },
    message: 'Centro de mensajes',
    kpi: 'Indicadores clave de rendimiento',
    system: {
      0: '% valor total de la herramienta asignadas',
      1: '% de líneas de solicitudes completadas',
      2: 'Valor de las herramientas perdidas'
    },
    jobSiteSystemDashboard: {
      0: '% Total del Valor de las Herramientas ',
      1: 'Herramientas y equipos por categoría - 10 principales',
      2: 'Herramientas y Equipos por Asignación - 10 principales'
    },
    requisitions: {
      0: '% de líneas de solicitudes completadas',
      1: '% de líneas de solicitudes completadas',
      2: 'Valor de las herramientas perdidas'
    },
    service: {
      0: 'Promedio Edad de los OT vencidas',
      1: 'Promedio Horas para completar la solicitud de servicio por mes',
      2: 'Órdenes de trabajo vencidas por categoría'
    },
    pickTickets: {
      0: 'Estado de la solicitud',
      1: 'Número de transferencias por Asignación (10 principales)',
      2: 'Número de transferencias por categoría (10 principales)'
    },
    purchasing: {
      0: 'Promedio Antigüedad de las órdenes de compra vencidas',
      1: 'Valor total de las órdenes de compra por proveedor',
      2: 'Órdenes de compra únicas por proveedor'
    },
    tools: {
      0: '% De pérdida de herramienta durante 90 días',
      1: 'Herramientas y equipos por categoría (10 principales)',
      2: 'Herramientas y equipo por asignación (10 principales)'
    },
    materials: {
      0: '% del valor total del material asignado',
      1: 'Materiales en existencia por asignación: costo promedio',
      2: 'Materiales en existencia por categoría: costo promedio'
    }
  },
  messageSummary: {
    workItemName: 'DashboardWI',
    DecomissionLocations: {
      text: 'Hay lugares para ser dados de baja'
    },
    itemsPastReturnDate: {
      text: 'Hay herramientas fuera de su fecha de devolución',
      methodName: 'ShowToolBrowser_ToolsPastReturnDate'
    },
    lowLevel: {
      text: 'Hay activos con niveles bajos',
      methodName: 'ShowLowLevelBrowser'
    },
    openWorkOrders: {
      text: 'Hay herramientas que necesitan ser reparadas',
      methodName: 'ShowWorkOrderBrowser_OpenWorkOrders'
    }
  }
}

export default dashboard

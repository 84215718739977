<template>
  <div class="wrapper">
    <LocaleSwitcher/>
    <router-view></router-view>
  </div>
</template>

<script>
import LocaleSwitcher from '../shared/LocaleSwitcher'
export default {
  name: 'PublicLayout',
  components: {
    LocaleSwitcher
  }
}
</script>

const tickets = {
  transfer: {
    title: 'Transfer Browser',
    filterTitle: 'Browse Transfers',
    transferDetail: 'Transfer',
    subtitle: 'Transfer Add Items',
    keepPageOpen: 'Keep Page Open',
    printTransfer: 'Print transfer when processed',
    actionHeader: {
      0: {
        title: 'Create Transfer',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Export Data',
        icon: 'fas fa-file-export'
      },
      2: {
        title: 'Print',
        icon: 'fas fa-print'
      }
    },
    subActionHeader: {
      0: {
        title: 'Save as Draft',
        isEditing: false,
        isDraft: true,
        isShow: true,
        icon: 'fas fa-save'
      },
      1: {
        title: 'Process Transfer',
        isEditing: false,
        isDraft: true,
        isShow: true,
        icon: 'fas fa-play'
      },
      2: {
        title: 'Edit Transfer',
        isEditing: true,
        isDraft: false,
        isShow: true,
        isOptional: true,
        icon: 'fas fa-edit'
      },
      3: {
        title: 'Add Tool Record',
        isShow: true,
        isEditing: false,
        isDraft: true,
        icon: 'fas fa-plus'
      },
      4: {
        title: 'Add Material Record',
        isShow: true,
        isEditing: false,
        isDraft: true,
        icon: 'fas fa-plus'
      },
      5: {
        title: 'Back',
        isShow: true,
        isEditing: true,
        isDraft: true,
        icon: 'fas fa-undo'
      },
      6: {
        title: 'Clear Transfer Draft',
        isEditing: false,
        isShow: false,
        isDraft: true,
        icon: 'fa fa-trash'
      },
      7: {
        title: 'Collaborate',
        key: 'collaborate',
        icon: 'fa-regular fa-comment-dots',
        isShow: true,
        isDraft: true
      }
    },
    browserLineActions: [
      {
        title: 'Create Transfer',
        actionName: 'createTransfer',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Export Data',
        actionName: 'exportData',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Print',
        actionName: 'printData',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Open',
        actionName: 'viewTransfer',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Delete',
        actionName: 'deleteTransfer',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    sidePanel: {
      labels: {
        0: 'All Tools',
        1: 'All Materials',
        2: 'All Categories',
        3: 'All Equipment'
      }
    },
    cart: {
      desc: 'Items in the transfer ticket',
      labels: {
        0: 'Transfer',
        1: 'Pick Ticket',
        2: 'Express Check-In',
        3: 'Express Check-Out',
        4: 'Transferred By',
        5: 'Assigned On',
        6: 'Delivered By',
        7: 'Return Date',
        8: 'Document No.',
        9: 'Scan or enter item',
        10: 'Transfer From',
        11: 'Cost Center From',
        12: 'Status',
        13: 'Owner',
        14: 'Cost Center To',
        15: 'Cost Code',
        16: 'Meter',
        17: 'Transfer To',
        18: 'Cost Center',
        19: 'Responsible Party',
        20: 'Returning to a Stock Location',
        21: 'Print transfer',
        22: 'Explode Kits',
        23: 'Status',
        24: 'Source'
      }
    },
    process: {
      kitWarning: 'This Item is in a kit. Would you like to remove the item from the kit or transfer the entire kit?',
      noTransferWarning: '{desc} - {number} has a status of Do Not Transfer. Do you wish to override?',
      removeWarning: {
        0: 'You can not remove this line as it is part of a kit',
        1: 'You must have transfer items added to process',
        certification: {
          title: 'Certification(s) Issues',
          message: 'One or more certification issues are present :\n{message} \n Do you wish to continue?'
        }
      },
      labels: {
        0: 'Maintain Association',
        1: 'Break from kit',
        2: 'Transfer Kit',
        3: 'Do Not Transfer'
      },
      expressZeroInWarning: 'The following items will not be transferred.  Do you wish to continue?',
      transferHasZeroQuantityLinesError: 'Cannot submit transfer lines with 0 quantity, please adjust your transfer.'
    },
    scan: {
      item: 'Multiple items were found with that number. Please choose an item, then click OK.',
      model: 'Multiple Models were found with that number. Please choose a model, then click OK.'
    },
    filter: {
      0: 'Transfer No.',
      1: 'Transfers From',
      2: 'Transfers To',
      3: 'Assignment Dates From'
    },
    report: {
      title: 'Transfer Ticket Report'
    },
    status: {
      new: 'New',
      draft: 'Draft',
      processed: 'Processed'
    },
    negativeWarning: 'This transaction will create a negative stock value',
    editTransferNote: 'Changes made to Cost Centers may result in duplicate billing streams, if charges for this transfer have already been posted. Review posted billing before making changes. Changes to cost codes will only be applied if the transfer has not yet been posted.',
    lastBleDontMatchAssignedTo: 'The Last Wireless scan location does not match the assignment for the item.',
    bleNotActive: 'This item has a wireless tag & has not been scanned in the last 30 days.',
    bleActive: 'This item has a wireless tag & has been scanned in the last 30 days.',
    draftSave: 'Draft saved successfully',
    scheduleTransfer: {
      title: 'Warning',
      info: `The model for item(s) below is needed for scheduling requests. 
        This transfer will drop inventory below the needed qty.`,
      confirm: 'Do you wish to proceed?'
    }
  },
  pick: {
    title: 'Pick Ticket Browser',
    filterTitle: 'Browse Pick Tickets',
    subtitle: 'Pick Ticket',
    actionHeader: {
      0: {
        title: 'Create Ticket',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Pick This Ticket',
        icon: 'fas fa-edit'
      }
    },
    subActionHeader: {
      printTooltip: 'Pick Ticket must be saved first to Print',
      0: {
        title: 'Save',
        icon: 'fas fa-save',
        isShow: true
      },
      1: {
        title: 'Print',
        key: 'Print',
        icon: 'fas fa-print',
        isShow: true
      },
      2: {
        title: 'Add Template',
        icon: 'fas fa-plus',
        isShow: true
      },
      3: {
        title: 'Add Tool Record',
        icon: 'fas fa-plus',
        isShow: false
      },
      4: {
        title: 'Add Record',
        icon: 'fas fa-plus',
        isShow: true
      },
      5: {
        title: 'Attachments',
        icon: 'fas fa-paperclip',
        isShow: true
      },
      6: {
        title: 'Back',
        icon: 'fas fa-undo',
        isShow: true
      },
      7: {
        title: 'Status to Draft',
        action: 'statusToDraft',
        icon: 'fa fa-arrow-left',
        isShow: true
      },
      8: {
        title: 'Clear Pick Draft',
        icon: 'fa fa-trash',
        isShow: false
      },
      9: {
        title: 'Collaborate',
        key: 'collaborate',
        icon: 'fa-regular fa-comment-dots',
        isShow: true
      }
    },
    browserLineActions: [
      {
        title: 'View',
        actionName: 'viewTicketAction',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Delete',
        actionName: 'deleteTicket',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Print',
        actionName: 'printData',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Status to Draft',
        actionName: 'statusToDraft',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Reopen Pick Ticket',
        actionName: 'statusToOpen',
        requiresSelection: true,
        allowsMultiSelect: false
      }
    ],
    filter: {
      0: 'Open',
      1: 'Closed',
      2: 'All',
      3: 'Pick Ticket No.',
      4: 'Picked For',
      5: 'Assigned To',
      6: 'Status',
      7: 'Date Created',
      8: 'Picked From',
      9: 'Requested By',
      10: 'Current Assignment'
    },
    ticketHolder: {
      labels: {
        0: 'Pick From',
        1: 'Pick To',
        2: 'Requisition No.',
        3: 'Requested By',
        4: 'Due Date',
        5: 'Assigned To',
        6: 'Date Closed',
        7: 'Pick Ticket Summary',
        8: 'Transfer picked items?',
        9: 'Pick List',
        10: 'Picked Items',
        11: 'Question',
        12: 'Close this Ticket?',
        13: 'Ready to pick?',
        14: 'Return Date'
      }
    },
    ticketItem: {
      title: 'Items in the Pick Ticket',
      labels: {
        0: 'IsGeneric'
      }
    },
    pickedItemDetail: {
      title: 'Picked Item Detail',
      category: {
        0: {
          name: 'Item',
          labels: {
            0: 'Item Number',
            1: 'Qty',
            2: 'Description',
            3: 'Manf',
            4: 'Model'
          }
        },
        1: {
          name: 'Picked',
          labels: {
            0: 'Picked By',
            1: 'Date',
            2: 'Time',
            3: 'Pick From',
            4: 'Pick For',
            5: 'Cost Center',
            6: 'Cost Code'
          }
        },
        2: {
          name: 'Transfer',
          labels: {
            0: 'Transfer No.',
            1: 'Transfered By',
            2: 'Date',
            3: 'Time',
            4: 'From',
            5: 'To'
          }
        }
      },
      confirmDialog: {
        title: {
          0: 'Substitute Item',
          1: 'Reinstate?'
        },
        text: {
          0: 'Item does not match selected line. Do you want to substitute with this item?',
          1: 'This {desc} has been retired. Would you like to reinstate it?',
          2: 'You are about to reinstate tools. \nThis means that the tool will be removed from the Retired Tools list and placed back into normal service.',
          3: 'Reinstate To:',
          4: 'Status:'
        }
      }
    },
    warning: {
      0: 'You can not pick a ticket in {status} state',
      1: 'Confirm Ticket Delete',
      2: 'Are you sure you want to delete this ticket?',
      3: 'You cannot delete tickets that have been picked.',
      4: 'Pick This Ticket?',
      5: 'Ticket is \'Ready to Pick\'. Would you like to pick this ticket?',
      6: 'You should not pick this ticket. It is currently being picked by another user. \n Pick this ticket anyway?',
      noLineforTransfer: 'There are no items to be transferred',
      invalidTitle: 'Invalid Quantity',
      invalidMessage: 'Item {itemNumber} is a Unique Tool. Only one was picked'
    }
  },
  templates: {
    title: 'Template Browser',
    fly_up: {
      title: 'Template',
      desc: 'Models in the template',
      labels: {
        0: 'Generic'
      }
    },
    actionHeader: {
      0: {
        title: 'Create Template',
        icon: 'fas fa-plus'
      }
    },
    subActionHeader: {
      0: {
        title: 'Add Tool Record',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Add Material Record',
        icon: 'fas fa-plus'
      },
      2: {
        title: 'Attachments',
        icon: 'fas fa-paperclip'
      },
      3: {
        title: 'Print',
        icon: 'fas fa-print'
      }
    },
    dlg: {
      title: 'New Template Type'
    },
    holder: {
      title: {
        edit: 'Template Detail',
        create: 'New Template'
      },
      labels: {
        0: 'Template No.',
        1: 'Template Type',
        2: 'Template Category',
        3: 'Template Description',
        4: 'Note',
        5: 'Template Summary',
        6: 'Select Model'
      },
      dlgLabels: {
        0: 'Are you sure you want to remove the following item from this template?',
        1: 'Number: ',
        2: 'Description: ',
        3: 'Error...',
        4: 'You must have at least one item added to your template before saving',
        6: 'Unable to add...',
        7: 'You must select a Name and category prior to adding any items to the template.',
        8: 'You cannot delete the last item within a template. If you would like to delete the entire template, please do so from the template browser.',
        9: 'Are you sure you want to delete the template:'
      }
    }
  },
  countInventory: {
    title: 'Count Ticket Browser',
    actionHeader: {
      0: {
        title: 'Create Count Ticket',
        tooltip: 'Create Count Ticket',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Inventory Adjustments Browser',
        tooltip: 'Inventory Adjustments Browser',
        icon: 'fas fa-list'
      },
      2: {
        title: 'Print',
        tooltip: 'Print',
        icon: 'fas fa-print'
      }
    },
    subActionHeader: {
      0: {
        title: 'Save',
        icon: 'fas fa-save'
      },
      1: {
        title: 'Add Tool Record',
        icon: 'fas fa-plus'
      },
      2: {
        title: 'Add Material Record',
        icon: 'fas fa-plus'
      },
      3: {
        title: 'Export',
        icon: 'fas fa-file-export'
      },
      4: {
        title: 'Back',
        icon: 'fas fa-undo'
      }
    },
    browserLineActions: [
      {
        title: 'View',
        actionName: 'editCountTicket',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Delete',
        actionName: 'deleteCountTicket',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    inventoryAdjLineActions: [
      {
        title: 'Edit',
        actionName: 'editInventoryItem',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'View Ticket',
        actionName: 'viewTicket',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Add To Transfer',
        actionName: 'addToTransfer',
        requiresSelection: true,
        allowsMultiSelect: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        isMaterial: true,
        permissions: ['TransfersExecute']
      },
      {
        title: 'Retire Tool',
        actionName: 'retireTool',
        isMultiple: false,
        isTool: true,
        isQuantity: false,
        isKit: false,
        isShown: function (items) {
          if (!items.length) return false
          return 'lastScanLocation' in items[0]
        },
        permissions: ['ToolDetailView', 'MaterialDetailView']
      }
    ],
    inventoryAdjustmentsActionHeader: {
      0: {
        title: 'Post Adjustments',
        tooltip: 'Post Adjustments',
        icon: 'fas fa-sliders-h'
      },
      1: {
        title: 'Print',
        tooltip: 'Print',
        icon: 'fas fa-print'
      }
    },
    labels: {
      0: 'Browse Count Tickets',
      1: 'Count Location:',
      2: 'Ticket Number:',
      3: 'Summary:',
      4: 'Created Date:',
      5: 'to',
      6: 'Open Type:',
      7: 'All',
      8: 'Open',
      9: 'Closed',
      10: 'Count Ticket',
      11: 'Status:',
      12: 'Created:',
      13: 'Qty',
      14: 'Confirm delete',
      15: 'Count Summary',
      16: 'Counted Items',
      17: 'Ticket Summary',
      18: 'Inventory Adjustments Browser',
      19: 'This item was counted at a location other than it\'s current assignment!',
      20: 'Counted',
      21: 'Uncounted',
      22: 'All',
      23: 'Adjustment Detail',
      24: 'Line Detail',
      25: 'Count:',
      26: 'Total Count:',
      27: 'Note:',
      28: 'Post Adjustments',
      29: 'Process?',
      30: 'Filter by Location',
      31: 'Create Transfer',
      32: 'Process Transfer Draft'
    },
    toolRecord: {
      title: 'Tool Record',
      actionHeader: {
        0: {
          title: 'Retire Tool',
          tooltip: 'Retire Tool',
          icon: 'fas fa-tools'
        },
        1: {
          title: 'Attachments',
          tooltip: 'Attachments',
          icon: 'fas fa-paperclip'
        }
      },
      labels: {
        0: 'Model ID:',
        1: 'Manufacturer & Model Information',
        2: 'Manufacturer:',
        3: 'Category:',
        4: 'Model:',
        5: 'Your Description:',
        6: 'Manf. Description:',
        7: 'Tracking Method',
        8: 'Bin:',
        9: 'View Model Information',
        10: 'Tool Number',
        11: 'Bar Code',
        12: 'Personal Issue Item',
        13: 'Notes',
        14: 'Work Order Count',
        15: 'Total Charges',
        16: 'Purchase information for stock location:',
        17: 'Cost Information',
        18: 'Total Qty. Purchased',
        19: 'Average Purchase Cost',
        20: 'Last Purchase Cost',
        21: 'Replacement Cost',
        22: 'Preferred Vendor',
        23: 'Purchase Information',
        24: 'Purchase Date',
        25: 'Purchase Cost',
        26: 'Purchase Order No.',
        27: 'Warranty Expiration',
        28: 'Model Summary'
      },
      tabs: [
        'Tool Information',
        'Repair Parts',
        'Components',
        'Accessories',
        'Service',
        'Purchase & Cost Info',
        'Lease/Rental'
      ],
      toolInfoTabLabels: {
        0: 'Current Assignment Information',
        1: 'Assigned To',
        2: 'Owner',
        3: 'Manager',
        4: 'Qty',
        5: 'Status',
        6: 'Return Date',
        7: 'Kit ID',
        8: 'Responsible Party',
        9: 'Unique Tool Information',
        10: 'Serial Number',
        11: 'Department',
        12: 'Class',
        13: 'Vehicle Information',
        14: 'License Tag',
        15: 'State',
        16: 'Expiration',
        17: 'Fuel Card',
        18: 'Meter / Odometer',
        19: 'Meter',
        20: 'Notes',
        21: 'Personal Issue Item'
      },
      serviceTabs: [
        'Service Plan',
        'Open Work Orders',
        'Service History'
      ],
      serviceRecurrenceTypes: [
        '',
        'By Date',
        'By Meter',
        'By Turns',
        'First Occurrence',
        ''
      ],
      serviceRecurrenceSequenceString: [
        '',
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
        'seventh',
        'eighth',
        'ninth',
        'tenth',
        'eleventh',
        'twelfth'
      ],
      serviceRecurrenceMonthString: [
        '',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'December',
        'November',
        'December'
      ],
      repairePartsTblHeader: {
        desktop: [
          {
            label: 'Description',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Manufacturer',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Required',
            key: 'Required',
            sortable: true
          },
          {
            label: 'Price Each',
            key: 'Price Each',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Info',
            data: [
              {
                label: 'Description',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Manufacturer',
                key: 'Manufacturer',
                sortable: true
              },
              {
                label: 'Required',
                key: 'Required',
                sortable: true
              },
              {
                label: 'Price Each',
                key: 'Price Each',
                sortable: true
              }
            ]
          }
        ]
      },
      servicePlanTblHeader: {
        desktop: [
          {
            label: 'Service Events',
            key: 'description',
            sortable: true
          },
          {
            label: 'Recurring',
            key: 'recurring',
            sortable: true
          },
          {
            label: 'Recurrence Method',
            key: 'recurrenceMethod',
            sortable: true
          },
          {
            label: 'Trigger On',
            key: 'triggerOn',
            sortable: true
          },
          {
            label: 'Note',
            key: 'note',
            sortable: true
          },
          {
            label: '',
            key: 'action',
            sortable: false
          }
        ],
        mobile: [
          {
            label: 'Info',
            data: [
              {
                label: 'Service Events',
                key: 'description',
                sortable: true
              },
              {
                label: 'Recurring',
                key: 'recurring',
                sortable: true
              },
              {
                label: 'Recurrence Method',
                key: 'recurrenceMethod',
                sortable: true
              },
              {
                label: 'Trigger On',
                key: 'triggerOn',
                sortable: true
              },
              {
                label: 'Note',
                key: 'note',
                sortable: true
              }
            ]
          },
          {
            label: '',
            key: 'action',
            sortable: false
          }
        ]
      },
      serviceOpenWorkOrdersTblHeader: {
        desktop: [
          {
            label: 'No',
            key: 'No',
            sortable: true
          },
          {
            label: 'Priority',
            key: 'Priority',
            sortable: true
          },
          {
            label: 'Status',
            key: 'Status',
            sortable: true
          },
          {
            label: 'State',
            key: 'State',
            sortable: true
          },
          {
            label: 'Service Request Note',
            key: 'Service Request Note',
            sortable: true
          },
          {
            label: 'Created',
            key: 'Created',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Info',
            data: [
              {
                label: 'No',
                key: 'No',
                sortable: true
              },
              {
                label: 'Priority',
                key: 'Priority',
                sortable: true
              },
              {
                label: 'Status',
                key: 'Status',
                sortable: true
              },
              {
                label: 'State',
                key: 'State',
                sortable: true
              },
              {
                label: 'Service Request Note',
                key: 'Service Request Note',
                sortable: true
              },
              {
                label: 'Created',
                key: 'Created',
                sortable: true
              }
            ]
          }
        ]
      },
      serviceServiceHistoryTblHeader: {
        desktop: [
          {
            label: 'Work Order',
            key: 'Work Order',
            sortable: true
          },
          {
            label: 'Completion Date',
            key: 'Completion Date',
            sortable: true
          },
          {
            label: 'Requested Service',
            key: 'Requested Service',
            sortable: true
          },
          {
            label: 'Service Request Note',
            key: 'Service Request Note',
            sortable: true
          },
          {
            label: 'Charges',
            key: 'Charges',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Info',
            data: [
              {
                label: 'Work Order',
                key: 'Work Order',
                sortable: true
              },
              {
                label: 'Completion Date',
                key: 'Completion Date',
                sortable: true
              },
              {
                label: 'Requested Service',
                key: 'Requested Service',
                sortable: true
              },
              {
                label: 'Service Request Note',
                key: 'Service Request Note',
                sortable: true
              },
              {
                label: 'Charges',
                key: 'Charges',
                sortable: true
              }
            ]
          }
        ]
      },
      leaseRentalTblHeader: {
        desktop: [
          {
            label: 'Vendor',
            key: 'Vendor',
            sortable: true
          },
          {
            label: 'Vendor Unit ID',
            key: 'UnitId',
            sortable: true
          },
          {
            label: 'Contract',
            key: 'Contract',
            sortable: true
          },
          {
            label: 'Type',
            key: 'TypeDescription',
            sortable: true
          },
          {
            label: 'Requisition',
            key: 'Requisition',
            sortable: true
          },
          {
            label: 'Start',
            key: 'StartDate',
            sortable: true
          },
          {
            label: 'End',
            key: 'EndDate',
            sortable: true
          },
          {
            label: 'Reminder',
            key: 'Reminder',
            sortable: true
          },
          {
            label: 'Lease Term',
            key: 'Term',
            sortable: true
          },
          {
            label: 'Lease Amount',
            key: 'LeaseAmount',
            sortable: true
          },
          {
            label: 'Residual Value',
            key: 'Residual',
            sortable: true
          },
          {
            label: 'Rate',
            key: 'Rate',
            sortable: true
          },
          {
            label: 'Rate Unit',
            key: 'RateUnitDescription',
            sortable: true
          },
          {
            label: 'Comments',
            key: 'Comments',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Info',
            data: [
              {
                label: 'Vendor',
                key: 'Vendor',
                sortable: true
              },
              {
                label: 'Vendor Unit ID',
                key: 'UnitId',
                sortable: true
              },
              {
                label: 'Contract',
                key: 'Contract',
                sortable: true
              },
              {
                label: 'Type',
                key: 'TypeDescription',
                sortable: true
              },
              {
                label: 'Requisition',
                key: 'Requisition',
                sortable: true
              },
              {
                label: 'Start',
                key: 'StartDate',
                sortable: true
              },
              {
                label: 'End',
                key: 'EndDate',
                sortable: true
              },
              {
                label: 'Reminder',
                key: 'Reminder',
                sortable: true
              },
              {
                label: 'Lease Term',
                key: 'Term',
                sortable: true
              },
              {
                label: 'Lease Amount',
                key: 'LeaseAmount',
                sortable: true
              },
              {
                label: 'Residual Value',
                key: 'Residual',
                sortable: true
              },
              {
                label: 'Rate',
                key: 'Rate',
                sortable: true
              },
              {
                label: 'Rate Unit',
                key: 'RateUnitDescription',
                sortable: true
              },
              {
                label: 'Comments',
                key: 'Comments',
                sortable: true
              }
            ]
          }
        ]
      },
      contractDetailsDlg: {
        title: 'Contract Details',
        labels: {
          0: 'Vendor',
          1: 'Vendor Unit ID',
          2: 'Contract',
          3: 'Type',
          4: 'Requisition',
          5: 'Start Date',
          6: 'End Date',
          7: 'Reminder Date',
          8: 'Lease Details',
          9: 'Lease Term',
          10: 'Lease Amount',
          11: 'Residual Value',
          12: 'Rental Details',
          13: 'Rental Rate',
          14: 'Rate Unit',
          15: 'Comments',
          16: 'Contract details saved successfully!'
        },
        contractTypes: [
          {
            text: 'Lease',
            value: '1'
          },
          {
            text: 'Lease To Own',
            value: '2'
          }
        ],
        rateUnitTypes: [
          {
            text: 'Hourly',
            value: '1'
          },
          {
            text: 'Daily',
            value: '2'
          },
          {
            text: 'Weekly',
            value: '3'
          },
          {
            text: 'Monthly',
            value: '4'
          }
        ]
      }
    }
  },
  addItem: {
    0: {
      title: 'Back & Add Item',
      icon: 'fas fa-undo'
    }
  },
  addModel: {
    0: {
      title: 'Back to Pick Ticket',
      icon: 'fas fa-undo'
    }
  }
}

export default tickets

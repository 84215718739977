const workPerformed = {
  tableHeader: {
    desktop: [
      {
        label: 'Type',
        key: 'taskType',
        sortable: true,
        type: String
      },
      {
        label: 'Task',
        key: 'sequenceNumber',
        sortable: true,
        type: String
      },
      {
        label: 'Task Description',
        key: 'description',
        sortable: true,
        type: String
      },
      {
        label: 'Est. Hrs',
        key: 'estimatedHours',
        sortable: true,
        type: Number
      },
      {
        label: 'Act. Hrs',
        key: 'actualHours',
        isEditable: true,
        sortable: true,
        type: Number
      },
      {
        label: 'Performed By',
        key: 'performedBy',
        sortable: true,
        type: String
      },
      {
        label: 'Date Completed',
        key: 'modifiedOn',
        sortable: true,
        type: Date
      },
      {
        label: 'Notes',
        key: 'notes',
        sortable: true,
        type: String
      },
      {
        label: 'Cost Center',
        key: 'costCenter',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: 'Type',
        key: 'taskType',
        sortable: true,
        type: String
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Task',
            key: 'sequenceNumber',
            sortable: true,
            type: String
          },
          {
            label: 'Task Description',
            key: 'description',
            sortable: true,
            type: String
          },
          {
            label: 'Est. Hrs',
            key: 'estimatedHours',
            sortable: true,
            type: Number
          },
          {
            label: 'Act. Hrs',
            key: 'actualHours',
            isEditable: true,
            sortable: true,
            type: Number
          },
          {
            label: 'Performed By',
            key: 'modifiedBy',
            sortable: true,
            type: String
          },
          {
            label: 'Date Completed',
            key: 'completedOn',
            sortable: true,
            type: Date
          },
          {
            label: 'Notes',
            key: 'notes',
            sortable: true,
            type: String
          },
          {
            label: 'Cost Center',
            key: 'costCenter',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  }
}

export default workPerformed

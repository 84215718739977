const templateHolder = {
  tableHeader: {
    desktop: [
      {
        label: 'Tipo',
        key: 'ItemType', // from Model
        sortable: true
      },
      {
        label: 'ID de modelo',
        key: 'ModelId', // from Model
        sortable: true
      },
      {
        label: 'Descripción del Artículo',
        key: 'Description', // from Model
        sortable: true
      },
      {
        label: 'Fabricante',
        key: 'Manufacturer', // from Model
        sortable: true
      },
      {
        label: 'Número de modelo',
        key: 'ModelNumber', // from Model
        sortable: true
      },
      {
        label: 'Cantidad',
        key: 'Quantity',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Genérico',
        key: 'IsGeneric',
        type: Boolean,
        isEditable: true,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Tipo',
            key: 'ItemType',
            sortable: true
          },
          {
            label: 'ID de modelo',
            key: 'ModelId',
            sortable: true
          },
          {
            label: 'Descripción del Artículo',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Número de modelo',
            key: 'ModelNumber',
            sortable: true
          },
          {
            label: 'Cantidad',
            key: 'Quantity',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Genérico',
            key: 'IsGeneric',
            type: Boolean,
            isEditable: true,
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default templateHolder

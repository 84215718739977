export default {
  state: () => ({
    serviceScheduleSearchParam: {
      isOpen: null,
      isScheduled: null,
      onDateFrom: null,
      onDateTo: null,
      assignedToId: null,
      itemNumber: '',
      model: '',
      workOrderNumber: '',
      page: 1,
      pageSize: 10,
      orderColumn: 'ItemNumber'
    }
  }),
  mutations: {
    setServiceScheduleSearchParam (state, value) {
      state.serviceScheduleSearchParam = value
    }
  },
  getters: {
    serviceScheduleSearchParam (state) {
      return state.serviceScheduleSearchParam
    }
  }
}

import { TXT_MAX_LENGTH, TXT_MIN_LENGTH, NOTES_MAX_LENGTH } from '../../helpers/const.js'

const validations = {
  required: '{attribute} es obligatorio',
  minLength: `{attribute} debe tener al menos ${TXT_MIN_LENGTH} caracteres`,
  maxLength: `{attribute} debe tener como máximo ${TXT_MAX_LENGTH} caracteres`,
  maxLengthCount: '{attribute} Máximo {max} caracteres ({overMax} de más)',
  notesMaxLength: `{attribute} debe tener como máximo ${NOTES_MAX_LENGTH} caracteres`,
  pwdMinLength: '{attribute} debe tener al menos {min} caracteres',
  sameAsPassword: 'Las contraseñas deben coincidir',
  numeric: '{attribute} debe ser un número',
  phone: '{attribute} no es válido',
  email: '{attribute} no es válido',
  url: '{attribute} no es válido',
  fax: '{attribute} no es válido',
  zip: '{attribute} no es válido',
  isDuplicate: '{attribute} ya se ha utilizado',
  selectCategory: 'Seleccione una categoría',
  timeValid: '{attribute} no es válido',
  selectType: 'Seleccione un tipo',
  numberValid: 'El número no es válido. elige otro',
  isValidUrl: 'La URL no es válido',
  endDate: '{attribute} no es válido',
  startDate: '{attribute} no es válido',
  isValidEmail: '{attribute} no es válido',
  differentValues: '{attribute} debe ser diferente del valor existente',
  noDuplicateRoleNames: 'Ese nombre de rol ya existe',
  inactiveDate: '{attribute} no es válido',
  activeWorkflows: '{attribute} es necesario para flujos de trabajo activos.'
}

export default validations

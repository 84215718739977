const searchReturns = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'ItemId',
        type: 'check'
      },
      {
        label: 'Category',
        key: 'Category',
        type: String,
        sortable: true
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'Barcode',
        key: 'Barcode',
        sortable: true
      },
      {
        label: 'Serial Number',
        key: 'SerialNumber',
        sortable: true
      },
      {
        label: 'Responsible Party',
        key: 'ResponsibleParty',
        sortable: true
      },
      {
        label: 'Owner',
        key: 'Owner',
        sortable: true
      },
      {
        label: 'On Site',
        key: 'Quantity',
        type: Number,
        sortable: true
      },
      {
        label: 'Available To Return',
        key: 'AvailableQuantity',
        type: Number,
        sortable: true
      },
      {
        label: 'Pending Return',
        key: 'Returning',
        type: Number,
        sortable: true
      },
      {
        label: 'To Return',
        key: 'QuantityReturned',
        type: Number,
        isEditable: true,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'ItemId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'Category',
            type: String,
            sortable: true
          },
          {
            label: 'Item',
            key: 'ItemNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            type: String,
            sortable: true
          },
          {
            label: 'Barcode',
            key: 'Barcode',
            sortable: true
          },
          {
            label: 'Serial Number',
            key: 'SerialNumber',
            sortable: true
          },
          {
            label: 'Responsible Party',
            key: 'ResponsibleParty',
            sortable: true
          },
          {
            label: 'Owner',
            key: 'Owner',
            sortable: true
          },
          {
            label: 'On Site',
            key: 'Quantity',
            type: Number,
            sortable: true
          },
          {
            label: 'Available To Return',
            key: 'AvailableQuantity',
            type: Number,
            sortable: true
          },
          {
            label: 'Pending Return',
            key: 'Returning',
            type: Number,
            sortable: true
          },
          {
            label: 'To Return',
            key: 'QuantityReturned',
            type: Number,
            isEditable: true,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default searchReturns

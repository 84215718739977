const customFields = {
  tableHeader: {
    desktop: [
      {
        label: 'check',
        key: 'CustomFieldDefinitionId',
        isModify: true
      },
      {
        label: 'Related Object',
        key: 'Table',
        isModify: true
      },
      {
        label: 'Record Type',
        key: 'Table',
        sortable: true,
        type: String
      },
      {
        label: 'Label',
        key: 'Label',
        sortable: true,
        type: String
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true,
        type: String,
        isModify: false
      },
      {
        label: 'Type',
        key: 'DataType',
        sortable: true,
        type: String
      },
      {
        label: 'Created By',
        key: 'CreatedBy',
        sortable: true,
        type: String,
        isModify: false
      },
      {
        label: 'Created On',
        key: 'CreatedOn',
        sortable: true,
        type: Date,
        isModify: false
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'CustomFieldDefinitionId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Related Object',
            key: 'Table',
            isModify: true
          },
          {
            label: 'Record Type',
            key: 'Table',
            type: String,
            sortable: true
          },
          {
            label: 'Label',
            key: 'Label',
            type: String,
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            type: String,
            sortable: true,
            isModify: false
          },
          {
            label: 'Type',
            key: 'DataType',
            type: String,
            sortable: true
          },
          {
            label: 'Created By',
            key: 'CreatedBy',
            type: String,
            sortable: true,
            isModify: false
          },
          {
            label: 'Created On',
            key: 'CreatedOn',
            type: Date,
            sortable: true,
            isModify: false
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default customFields

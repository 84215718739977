const numberBlocks = {
  tableHeader: {
    desktop: [
      {
        label: 'Number Type',
        key: 'description',
        sortable: true
      },
      {
        label: 'Block Size',
        key: 'range',
        sortable: true
      },
      {
        label: 'Current Number',
        key: 'number',
        sortable: true
      },
      {
        label: '',
        key: ''
      }
    ],
    mobile: [
      {
        label: 'check',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Number Type',
            key: 'description',
            sortable: true
          },
          {
            label: 'Block Size',
            key: 'range',
            sortable: true
          },
          {
            label: 'Current Number',
            key: 'number',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: ''
      }
    ]
  }
}

export default numberBlocks

export default {
  state: () => ({
    folders: null
  }),
  mutations: {
    addFolders (state, data) {
      state.folders = [].concat(data)
    }
  },
  getters: {
    folderList: state => state.folders
  }
}

const fuelTransactionTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Número de tarjeta'
      },
      {
        label: 'Transacción creada'
      },
      {
        label: 'Número de artículo'
      },
      {
        label: 'Producto'
      },
      {
        label: 'Cantidad'
      },
      {
        label: 'Unidad de medida'
      },
      {
        label: 'Costo total de combustible'
      },
      {
        label: 'Ciudad'
      },
      {
        label: 'Estado'
      },
      {
        label: 'Nombre de la conductora'
      },
      {
        label: 'Segundo nombre del conductor'
      },
      {
        label: 'Estado'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Número de tarjeta'
          },
          {
            label: 'Transacción creada'
          },
          {
            label: 'Número de artículo'
          },
          {
            label: 'Producto'
          },
          {
            label: 'Cantidad'
          },
          {
            label: 'Unidad de medida'
          },
          {
            label: 'Costo total de combustible'
          },
          {
            label: 'Ciudades'
          },
          {
            label: 'Estado'
          },
          {
            label: 'Nombre de la conductora'
          },
          {
            label: 'Segundo nombre del conductor'
          },
          {
            label: 'Estado'
          }
        ]
      }
    ]
  }
}
export default fuelTransactionTable

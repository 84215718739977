const costCenters = {
  tableHeader: {
    desktop: [
      {
        label: 'check',
        key: 'costCenterId',
        isModify: true
      },
      {
        label: 'No.',
        key: 'costCenterNumber',
        sortable: true,
        type: String
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true,
        type: String
      },
      {
        label: 'Code Group',
        key: 'codeGroup',
        sortable: true,
        type: String,
        isModify: false
      },
      {
        label: 'Inactive',
        key: 'inactive',
        sortable: true,
        type: Number,
        isModify: false
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'costCenterId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.',
            key: 'costCenterNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            type: String,
            sortable: true
          },
          {
            label: 'Code Group',
            key: 'codeGroup',
            type: String,
            sortable: true,
            isModify: false
          },
          {
            label: 'Inactive',
            key: 'inactive',
            type: Number,
            sortable: true,
            isModify: false
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  },
  modifyVisibilityTableHeader: {
    desktop: [
      {
        label: '',
        key: 'costCenterId',
        type: 'check'
      },
      {
        label: 'No.',
        key: 'costCenterNumber',
        sortable: true,
        type: String,
        showColFilter: false
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true,
        type: String,
        showColFilter: false
      }
    ],
    mobile: [
      {
        label: '',
        key: 'costCenterId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.',
            type: String,
            key: 'costCenterNumber',
            sortable: true,
            showColFilter: false
          },
          {
            label: 'Description',
            type: String,
            key: 'description',
            sortable: true,
            showColFilter: false
          }
        ]
      }
    ]
  }
}

export default costCenters

<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{title}}
        </h5>
        <IntegrationLink
        :twId="selectedItem.masterCategoryId"
        />
        <button class="close" type="button" @click="onClose">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="categoryData" ref="categoryData">
          <div
            v-if="selectedAction === 'delete'"
            class="alert alert-danger"
            role="alert">
            {{$t('message.warning.delete')}}
          </div>
          <input type="hidden" name="Type" v-model="selectedItem.Type">
          <!-- <input type="hidden" name="Id" v-model="selectedItem.Id"> -->
          <div class="form-group tw-select-field row">
            <div class="col-lg-3">
              <label for="number">{{$t('admin.categories.editDlg.labels.0')}}</label>
            </div>
            <div class="col-lg-9">
              <form-group
                :validator="$v.number"
                :attribute="$t('admin.categories.editDlg.labels.0')"
              >
                <input
                  v-if="numberType === 0"
                  class="form-control"
                  type="text"
                  name="Number"
                  :disabled="selectedAction === 'delete'"
                  :class="{ error: $v.number.$error }"
                  @input="$v.number.$touch()"
                  v-model="number">
                <template v-else>
                  <NumberChooser
                    v-if="selectedAction !== ''"
                    style="height:33px"
                    :numberType="numberType"
                    :number="number"
                    :isError="$v.number.$error"
                    :isDisabled="selectedAction === 'delete'"
                    @input="$v.number.$touch()"
                    @onNumberChanged="number = $event"
                    @onIsValidChanged="isNumberValid = $event; $v.number.$touch()"
                    @saveUsedNumber="saveCategoryNumber = $event;" />
                </template>
              </form-group>
            </div>
          </div>
          <div class="form-group tw-select-field row">
            <div class="col-lg-3">
              <label for="Description">{{$t('admin.categories.editDlg.labels.2')}}</label>
            </div>
            <div class="col-lg-9">
              <form-group
                :validator="$v.description"
                :attribute="$t('admin.categories.editDlg.labels.2')"
              >
                <input
                  type="text"
                  class="form-control"
                  v-model="description"
                  name="Description"
                  :maxlength="50"
                  :disabled="selectedAction === 'delete'"
                  :class="{ error: $v.description.$error }"
                  @input="$v.description.$touch()">
              </form-group>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          class="btn close-btn"
          type="button"
          @click="onCancelClick()"
          :disabled="isSubmitting"
        >
          {{$t('buttons.cancel')}}
        </button>
        <template v-if="selectedAction === 'delete'">
          <tw-button
            :key="selectedAction"
            :permissions="['CategoriesDelete']"
            :buttonText="$t('buttons.delete')"
          >
            <template v-if="isSubmitting">
              <button
                type="button"
                class="btn" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{$t('buttons.delete')}}...
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                @click="submit"
                class="btn">
                {{$t('buttons.delete')}}
              </button>
            </template>
          </tw-button>
        </template>
        <template v-else>
          <tw-button
            :key="selectedAction"
            :permissions="['CategoriesEdit']"
            :buttonText="$t('buttons.save.default')"
          >
            <template v-if="isSubmitting">
              <button
                type="button"
                class="btn" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{$t('buttons.save.default')}}...
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                @click="submit"
                class="btn"
                :disabled="!hasChanges">
                {{$t('buttons.save.default')}}
              </button>
            </template>
          </tw-button>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { errorHandler, successHandler } from '../helpers/utils'
import { uuid } from 'vue-uuid'
import NumberChooser from '../components//shared/NumberChooser.vue'
import TwButton from '../components/shared/TWButton.vue'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { TXT_MIN_LENGTH, TXT_MAX_LENGTH } from '../helpers/const.js'
import IntegrationLink from './integration/IntegrationLink.vue'

export default {
  name: 'CategoryForm',
  components: {
    NumberChooser,
    TwButton,
    IntegrationLink
  },
  props: {
    selectedItem: {
      type: Object,
      required: false,
      default: null
    },
    selectedAction: {
      type: String,
      required: false,
      default: ''
    },
    category: {
      type: String,
      required: true
    },
    numberType: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isSubmitting: false,
      description: '',
      number: '',
      saveCategoryNumber: null,
      isNumberValid: false
    }
  },
  validations: {
    description: {
      required,
      minLength: minLength(TXT_MIN_LENGTH),
      maxLength: maxLength(TXT_MAX_LENGTH)
    },
    number: {
      numberValid () {
        return this.isNumberValid
      }
    }
  },
  created () {
    this.refresh()
  },
  methods: {
    refresh () {
      this.description = this.selectedItem.description
      this.number = this.selectedItem.categoryNumber
    },
    async submit () {
      if (this.$v.$invalid) {
        this.$v.description.$touch()
        this.$v.number.$touch()
        return
      }
      this.isSubmitting = true
      const headerConfig = {
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }
      const newID = uuid.v4()
      const params = {
        dto: JSON.stringify({
          Id: this.selectedAction == 'edit' ? this.selectedItem.masterCategoryId : newID,
          OrgId: localStorage.getItem('OrgId'),
          Number: this.number,
          Type: this.selectedItem.type,
          Description: this.description,
          CreatedById: this.selectedAction == 'edit' ? this.selectedItem.createdById : localStorage.getItem('UserId'),
          CreatedBy: this.selectedAction == 'edit' ? this.selectedItem.createdBy : this.$store.getters.fullName,
          CreatedOn: this.selectedAction == 'edit' ? this.selectedItem.createdOn : new Date(),
          ModifiedBy: this.$store.getters.fullName,
          ModifiedOn: new Date(),
          ModifiedById: localStorage.getItem('UserId'),
          Inactive: false,
          Deleted: false
        })
      }
      const paramsInsert = {
        stockpointId: `"${this.globalStockpointId}"`,
        orgId: `"${localStorage.getItem('OrgId')}"`,
        listItemId: `"${newID}"`
      }
      const paramsDelete = JSON.stringify({
        orgId: '\'' + localStorage.getItem('OrgId') + '\'',
        deletedById: '\'' + localStorage.getItem('UserId') + '\'',
        deletedRecordId: '\'' + this.selectedItem.masterCategoryId + '\'',
        deletedRecordNumber: '\'' + this.number + '\'',
        description: '\'' + this.description + '\'',
        recordType: '16',
        markDeleted: 'true'
      })
      if (this.selectedAction === 'delete') {
        try {
          await axios.post('/api/core/RecycleBinService/Recycle', paramsDelete, headerConfig)
          this.isSubmitting = false
          successHandler(this.$toast, this.$t('message.success.delete'))
          this.$emit('saveSuccess')
        } catch (error) {
          this.isSubmitting = false
          if (error.response) {
            errorHandler(this.$toast, error.response.statusText, error)
          }
          this.$emit('saveFailure')
        }
      } else if (this.selectedAction === 'edit') {
        try {
          await axios.post('/api/core/CategoriesService/SaveCategory', params, headerConfig)
          if (this.saveCategoryNumber) {
            await this.saveCategoryNumber()
          }
          this.isSubmitting = false
          successHandler(this.$toast, this.$t('message.success.edit'))
          this.$emit('saveSuccess')
        } catch (error) {
          this.isSubmitting = false
          if (error.response) {
            errorHandler(this.$toast, error.response.statusText, error)
          }
          this.$emit('saveFailure')
        }
      } else {
        try {
          await axios.post('/api/core/CategoriesService/SaveCategory', params, headerConfig)
          await axios.post('/api/core/ListVisibilityService/insertListVis', paramsInsert)
          if (this.saveCategoryNumber) {
            await this.saveCategoryNumber()
          }
          this.isSubmitting = false
          successHandler(this.$toast, this.$t('message.success.create'))
          this.$emit('saveSuccess', newID)
        } catch (error) {
          this.isSubmitting = false
          if (error.response) {
            errorHandler(this.$toast, error.response.statusText, error)
          }
          this.$emit('saveFailure')
        }
      }
    },
    onCancelClick () {
      this.refresh()
      this.hideModal()
      this.$emit('cancel')
    },
    onClose () {
      if (this.hasChanges && !this.$v.$invalid) {
        this.$emit('openConfirmSaveClose')
      } else {
        this.hideModal()
      }
    },
    hideModal () {
      this.$emit('hideModal')
    }
  },
  computed: {
    title () {
      if (this.selectedAction === 'delete') {
        return this.$t('admin.categories.editDlg.title.2') + this.category
      } else if (this.selectedAction === 'edit') {
        return this.$t('admin.categories.editDlg.title.1') + this.category
      } else {
        return this.$t('admin.categories.editDlg.title.0') + this.category
      }
    },
    hasChanges () {
      if (this.selectedAction == 'create') return true
      if (this.number !== this.selectedItem.categoryNumber ||
        this.description !== this.selectedItem.description) {
        return true
      }
      return false
    },
    globalStockpointId () {
      return this.$store.getters.getStockpointId
    }
  },
  watch: {
    selectedItem () {
      this.refresh()
    }
  }
}
</script>
<style scoped>
.tw-select-field input {
  height: 33px!important;
}
.tw-select-field textarea {
  padding-top: 10px;
  height: 50px;
}
</style>

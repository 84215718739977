const workflowBuilder = {
  createWorkflow: {
    title: '',
    workflowMapTitle: 'Mapa de flujo de trabajo',
    workflowMapDescription: 'Descripción del mapa de flujo de trabajo',
    workflowLegend: {
      labels: {
        0: 'Desencadenar',
        1: 'tarea',
        2: 'Último tarea',
        3: 'Aprobación'
      },
      description: {
        0: 'El desencadenante es el evento en ToolWatch que inicia el flujo de trabajo.',
        1: 'El paso es una acción capturada como una tarea a completar.',
        2: 'El último paso cerrará el flujo de trabajo cuando se complete',
        3: 'La aprobación es un paso para revisar y aprobar/rechazar antes del siguiente paso'
      }
    },
    labels: {
      0: 'Nombre del flujo de trabajo',
      1: 'Pasos del flujo de trabajo',
      2: 'Descripción',
      3: 'Duración del flujo de trabajo',
      4: 'Desencadenar',
      5: 'Soltera',
      6: 'Serie',
      7: 'Paralela',
      8: '¿Establecer como activa?',
      9: 'Org Rama de árbol'
    },
    messages: {
      saved: 'flujo de trabajo guardado exitosamente',
      deleted: 'flujo de trabajo eliminado exitosamente',
      stepDeleted: 'Pasos del flujo de trabajo eliminados correctamente',
      canDelete: 'Eliminar flujo de trabajo',
      deleteStep: 'Eliminar tarea de flujo de trabajo',
      willDelete: 'Realmente desea eliminar este flujo de trabajo?',
      stepError: 'Actualiza todas las tareas antes de guardar',
      deleteWordkflow: '¿Estás segura de que quieres eliminar?'
    },
    actionHeader: {
      0: {
        title: 'Agregar tarea',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Agregar aprobación',
        icon: 'fas fa-plus'
      },
      2: {
        title: 'Editar',
        icon: ''
      },
      3: {
        title: 'Borrar',
        icon: ''
      }
    }
  },
  step: {
    modalHeader: {
      title: 'crear tarea',
      titleEstTime: 'Seleccione el tiempo estimado',
      subject: 'Sujeto',
      category: 'Categoría de tarea',
      toolWatchLocation: 'Ubicación del sistema',
      otherLocation: 'Otra localización',
      assignedTo: 'Asignado a',
      estTime: 'Est. Tiempo',
      priority: 'Prioridad',
      status: 'Estado',
      notes: 'Notas',
      editStepTitle: 'Editar tarea',
      locationValidationMsg: 'Se requiere la ubicación de ToolWatch',
      otherLocationValidationMsg: 'Se requiere otra ubicación',
      estTimeDateValidationMsg: 'Est. Tiempo necesario para el paso recurrente',
      approvalModaltitle: 'Crear aprobación',
      editapprovalModaltitle: 'Editar aprobación',
      approver: 'Aprobadora'
    },
    buttons: {
      recurrence: 'Reaparición',
      attach: 'Archivos adjuntos',
      addAndNext: 'Agregar y Siguiente',
      add: 'Agregar',
      cancel: 'Cancelar',
      save: 'Ahorrar',
      saveStepComplete: 'Ahorrar, Paso Completo',
      BtnSelectTime: 'Seleccionar'
    },
    messages: {
      saved: 'Paso creado exitosamente.',
      savedApproval: 'Paso de aprobación creado exitosamente.',
      editApproval: 'Aprobación editada correctamente.'
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'StepId',
          type: 'check'
        },
        {
          label: 'Sujeto',
          key: 'Subject',
          type: String
        },
        {
          label: 'Tipo',
          key: 'Type',
          type: String
        },
        {
          label: 'Asignado a',
          key: 'AssignedTo',
          type: String
        }
      ],
      mobile: [
        {
          label: '',
          key: 'StepId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Sujeto',
              key: 'Subject',
              type: String
            },
            {
              label: 'Tipo',
              key: 'Type',
              type: String
            },
            {
              label: 'Asignado a',
              key: 'AssignedTo',
              type: String
            }
          ]
        }
      ]
    }
  },
  tableHeader: {
    desktop: [
      {
        label: 'Activa',
        key: 'active',
        sortable: true
      },
      {
        label: 'Organización Rama de árbol',
        key: 'OrgTreeBranch',
        sortable: true
      },
      {
        label: 'Nombre ',
        key: 'WorkflowName',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Desencadenar',
        key: 'TriggerDescription',
        sortable: true
      },
      {
        label: 'Creado por',
        key: 'CreatedBy',
        sortable: true
      },
      {
        label: 'Creado en',
        key: 'CreatedOn',
        sortable: true
      },
      {
        label: 'Cuenta de pasos',
        key: 'StepCount',
        sortable: true
      },
      {
        label: 'Duración del flujo de trabajo',
        key: 'WorkflowDuration',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Nombre del flujo de trabajo',
        key: 'name',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Activa',
            key: 'active',
            sortable: true
          },
          {
            label: 'Organización Rama de árbol',
            key: 'OrgTreeBranch',
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true
          },
          {
            label: 'Desencadenar',
            key: 'trigger',
            sortable: true
          },
          {
            label: 'Creado por',
            key: 'createdBy',
            sortable: true
          },
          {
            label: 'Creado en',
            key: 'createdOn',
            sortable: true
          },
          {
            label: 'Cuenta de pasos',
            key: 'stepCount',
            sortable: true
          },
          {
            label: 'Duración del flujo de trabajo',
            key: 'workflowDuration',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default workflowBuilder

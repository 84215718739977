<template>
  <div class="error-wrapper">
    <LocaleSwitcher />
    <div class="error-page">
      <p class="code">404</p>
      <p class="title">{{$t('procore.404.title')}}</p>
      <p class="description">{{$t('procore.404.description')}}
        <br>
        <span v-html="$t('procore.404.back')"></span>
      </p>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from '../../components/shared/LocaleSwitcher.vue'

export default {
  name: 'PageNotFound',
  components: {
    LocaleSwitcher
  }
}
</script>

<style>
:root {
  --secondary-color: #D09433;
}
.error-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error-page .code {
  font-size: 150px;
  font-weight: bold;
}
.error-page .title {
  font-size: 40px;
  font-weight: 600;
}
.error-page .description {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.error-page .description a {
  color: var(--secondary-color);
}
.error-page .description a:hover {
  text-decoration: underline;
}

@media (max-width: 500px) {
  .error-page .code {
    font-size: 100px;
  }
  .error-page .title {
    font-size: 30px;
  }
  .error-page .description {
    font-size: 16px;
  }
}
</style>

<template>
  <modal-dialog
    ref="listSearchBrowser"
    v-if="initialSearchProvider && initialSearchProvider.listTypeId"
    :onClose="close"
    :isOverlapping="isOverlapping"
    class="tw-shipping-modal"
    :title="$t('listSearchBrowser.typeDescriptions.' + initialSearchProvider.listTypeId)"
    :tabIndex="false"
    @modalId="onModalId">
    <template v-slot:body>
      <div
        v-if="isEntityList && !requisitionBrowserOption"
        ref="entityPanel"
        class="form-group col-lg-12 btn-group btn-group-toggle row"
        role="group">
        <label
          class="btn btn-label"
          :class="(entityTypeSelected === 'A')?'btn-active':'close-btn'"
          v-if="showEmployeeOptions">
          <input type="radio" id="A" value="A" v-model="entityTypeSelected" autocomplete="off">
          {{$t('listSearchBrowser.labels.all')}}
        </label>
        <label
          class="btn btn-label"
          :class="(entityTypeSelected === 'E')?'btn-active':'close-btn'"
          v-if="showEmployeeOptions">
          <input type="radio" id="E" value="E" v-model="entityTypeSelected" autocomplete="off">
          {{$t('listSearchBrowser.labels.employees')}}
        </label>
        <label
          class="btn btn-label"
          :class="(entityTypeSelected === 'L')?'btn-active':'close-btn'">
          <input type="radio" id="L" value="L" v-model="entityTypeSelected" autocomplete="off">
          {{$t('listSearchBrowser.labels.locations')}}
        </label>
        <label
          class="btn btn-label"
          :class="(entityTypeSelected === 'SL')?'btn-active':'close-btn'">
          <input type="radio" id="SL" value="SL" v-model="entityTypeSelected" autocomplete="off">
          {{$t('listSearchBrowser.labels.stockLocations')}}
        </label>
      </div>
       <div
        v-if="requisitionBrowserOption"
        ref="entityPanel"
        class="form-group col-lg-12 btn-group btn-group-toggle row"
        role="group">
        <label
          class="btn btn-label"
          :class="(entityTypeSelected === 'A')?'btn-active':'close-btn'"
          v-if="showEmployeeOptions">
          <input type="radio" id="A" value="A" v-model="entityTypeSelected" autocomplete="off">
          {{$t('listSearchBrowser.labels.all')}}
        </label>
        <label
          class="btn btn-label"
          :class="(entityTypeSelected === 'E')?'btn-active':'close-btn'"
          v-if="showEmployeeOptions">
          <input type="radio" id="E" value="E" v-model="entityTypeSelected" autocomplete="off">
          {{$t('listSearchBrowser.labels.employees')}}
        </label>
        <label
          class="btn btn-label"
          :class="(entityTypeSelected === 'NSL')?'btn-active':'close-btn'">
          <input type="radio" id="NSL" value="NSL" v-model="entityTypeSelected" autocomplete="off">
          {{$t('listSearchBrowser.labels.nonStockLocations')}}
        </label>
        <label
          class="btn btn-label"
          :class="(entityTypeSelected === 'SL')?'btn-active':'close-btn'">
          <input type="radio" id="SL" value="SL" v-model="entityTypeSelected" autocomplete="off">
          {{$t('listSearchBrowser.labels.stockLocations')}}
        </label>
      </div>
      <div
        v-else-if="isCostCodeList"
        ref="costCodePanel"
        class="form-group col-lg-12 btn-group btn-group-toggle row"
        role="group">
        <label
          class="btn btn-label"
          :class="(costCodeTypeSelected === 'S')?'btn-active':'close-btn'">
          <input type="radio" id="S" value="S" v-model="costCodeTypeSelected" autocomplete="off">
          {{$t('listSearchBrowser.labels.specific')}}
        </label>
        <label
          class="btn btn-label"
          :class="globalCostCodeOptionClass">
          <input type="radio" id="G" value="G" v-model="costCodeTypeSelected" :disabled="!allowGlobalCostCodes" autocomplete="off">
          {{$t('listSearchBrowser.labels.global')}}
        </label>
      </div>
      <div class="form-group">
        <input ref="itemInput"
          class="list-search-input form-control"
          :disabled="disabled"
          v-model="text"
          @keyup="onInputKeyUp"
          @input="onTextUpdate"
          type="text"
        />
      </div>
      <div class="form-group">
        <div
          class="item-list"
          @keyup="onKeyUp"
          @keydown="onKeyDown"
          ref="itemList"
          tabindex="0">
          <template v-if="isLoading">
            <div
              class="searchLoading"
            >
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-warning" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="items.length">
            <div
              v-for="(item, index) in items"
              :key="item.Value + index"
              class="item-choice"
              @click="highlightItem(index)"
              @dblclick="trySelect()"
              :class="{'item-highlight': index === highlightedItemIndex}"
            >
              {{item.Key}}
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        @click="onCancel"
        class="btn close-btn"
        type="button">
        {{$t('buttons.cancel')}}
      </button>
      <button
        :disabled="highlightedItemIndex < 0"
        @click="trySelect"
        class="btn"
        type="button">
        {{$t('buttons.ok')}}
      </button>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '../shared/ModalDialog.vue'
import { deepCopy } from '../../helpers/utils.js'
import { searchCostCodes, getSafetyInspectionUsers } from '../../helpers/listSearch.js'
import $ from 'jquery'

export default {
  name: 'ListSearchBrowser',
  components: {
    ModalDialog
  },
  props: {
    initialSearchProvider: {
      type: Object,
      required: true
    },
    searchInfo: {
      type: Object,
      required: true
    },
    initialSelection: {
      type: Object,
      default () {
        return {
          Key: '',
          Value: ''
        }
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    stockpointId: {
      type: String,
      required: true
    },
    costCenterId: {
      type: String,
      default: ''
    },
    isOverlapping: {
      type: Boolean
    },
    isItemPopup: {
      type: Boolean,
      default: false
    },
    requisitionBrowserOption: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentSearchProvider: null,
      text: '',
      entityTypeSelected: 'A',
      costCodeTypeSelected: 'S',
      allowGlobalCostCodes: false,
      items: [],
      timeout: null,
      isLoading: false,
      highlightedItemIndex: -1,
      isInitialized: false,
      costCodes: [],
      selection: null
    }
  },
  async mounted () {
    await this.initialize()
  },
  methods: {
    open () {
      this.$refs.listSearchBrowser.open()
    },
    onModalId (id) {
      const modal = $('#' + id)
      modal.on('shown.bs.modal', () => {
        $('#' + id + ' .modal-content').css({ position: 'fixed', left: '50%', top: '48%', transform: 'translate(-50%, -45%)' })
        $('#' + id).css({ 'overflow-y': 'hidden' })
        $('#' + id + ' .item-list').css({ height: '200px' })
      })
    },
    close () {
      this.$emit('onClose')
      return true
    },
    trySelect () {
      if (this.highlightedItemIndex < 0) {
        return
      }
      this.selectHighlightedItem()
      this.$refs.listSearchBrowser.close()
    },
    onCancel () {
      this.$refs.listSearchBrowser.close()
    },
    async initialize () {
      if (this.initialSelection === null) {
        this.selection = {
          Key: '',
          Value: ''
        }
      } else {
        this.selection = {
          Key: this.initialSelection.Key,
          Value: this.initialSelection.Value
        }
      }
      this.currentSearchProvider = deepCopy(this.initialSearchProvider)
      if (this.isEntityList) {
        this.setEntityType()
      } else {
        this.text = this.selection.Key
      }
      await this.getItems()
      this.isInitialized = true
    },
    setEntityType () {
      if (this.showEmployeeOptions &&
        !this.searchInfo.IsEmployeeSearch &&
        !this.searchInfo.IsLocationSearch) {
        this.entityTypeSelected = 'A'
      } else if (this.showEmployeeOptions &&
        this.searchInfo.IsEmployeeSearch) {
        this.entityTypeSelected = 'E'
      } else {
        this.entityTypeSelected = 'L'
      }
    },
    async onTextUpdate () {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      const self = this
      this.timeout = setTimeout(async () => {
        self.getItems()
      }, 500)
    },
    async getItems () {
      if (!this.isInitialized) {
        this.text = ''
      }
      this.isLoading = true
      if (this.isCostCodeList) {
        this.costCodes = await this.loadCostCodes()
        this.items = this.filterCostCodes()
      } else if (this.currentSearchProvider.listTypeId == 58) {
        if (!this.isInitialized) {
          const users = await getSafetyInspectionUsers()
          this.items = users.map((user) => ({
            Value: user?.UserId + '',
            Key: user?.LastName + ', ' + user?.FirstName
          }))
        }
      } else {
        this.items = await this.$store.dispatch('loadDataByListType', {
          query: this.text,
          listTypeId: this.currentSearchProvider.listTypeId,
          stockpointId: this.stockpointId
        })
      }
      this.items = this.items.filter(item => item?.Key.toLowerCase().includes(this.text.toLowerCase()))
      this.isLoading = false
    },
    async loadCostCodes () {
      const { costCenter, costCodes } = await searchCostCodes(this.text, this.stockpointId, this.costCenterId)
      let mappedCodes = []
      let costCodeslinkedWithCostCenterHavingCodeGroup = []
      if (costCenter !== null) {
        this.allowGlobalCostCodes = costCenter.AllowSelectGlobalCostCodes
        if (costCenter.CodeGroup != null) {
          const costCodeslinkedWithCenterID = costCodes.filter(costCode => costCode.CostCenterId === costCenter.CostCenterId || costCode.CodeGroup === costCenter.CodeGroup)
          if (costCodeslinkedWithCenterID.length > 0 && costCenter.AllowSelectGlobalCostCodes) {
            costCodeslinkedWithCostCenterHavingCodeGroup = costCodeslinkedWithCenterID.filter(costCode => costCode.CodeGroup === costCenter.CodeGroup)
            if (costCodeslinkedWithCostCenterHavingCodeGroup.length > 0) {
              mappedCodes = costCodeslinkedWithCenterID.map(costCode => ({
                Key: costCode.Description,
                Value: costCode.CostCodeId,
                IsGlobal: costCode.CostCenterId === null || costCode.CostCenterId === undefined,
                costCenterId: costCode.CostCenterId
              }))
            }
          }
        }
      } else {
        this.allowGlobalCostCodes = true
      }
      if (costCodeslinkedWithCostCenterHavingCodeGroup.length <= 0) {
        mappedCodes = costCodes.map(costCode => ({
          Key: costCode.Description,
          Value: costCode.CostCodeId,
          IsGlobal: costCode.CostCenterId === null || costCode.CostCenterId === undefined,
          costCenterId: costCode.CostCenterId
        }))
      }
      return mappedCodes
    },
    filterCostCodes () {
      if (!this.allowGlobalCostCodes) {
        this.costCodeTypeSelected = 'S'
      } else if (!this.isInitialized && this.shouldUseGlobalCostCodeType) {
        this.costCodeTypeSelected = 'G'
      }

      if (this.costCodeTypeSelected === 'G') {
        return this.costCodes.filter(costCode => costCode.IsGlobal === true)
      } else {
        return this.costCodes.filter(costCode => costCode.IsGlobal === false)
      }
    },
    highlightItem (index) {
      this.highlightedItemIndex = index
    },
    selectItem (item) {
      this.$emit('onItemSelected', item)
    },
    selectHighlightedItem () {
      const kvp = this.items[this.highlightedItemIndex]
      this.selectItem(kvp)
    },
    scrollToIndex () {
      const targetElement =
        this.$refs.itemList?.children[this.highlightedItemIndex] || null

      if (targetElement) {
        const bounds = this.getViewBounds()
        const { top, bottom, height } = targetElement.getBoundingClientRect()

        if (top < bounds.top) {
          return (this.$refs.itemList.scrollTop = targetElement.offsetTop)
        } else if (bottom > bounds.bottom) {
          return (this.$refs.itemList.scrollTop =
            targetElement.offsetTop - (bounds.height - height))
        }
      }
    },
    getViewBounds () {
      if (this.$refs.itemList) {
        return this.$refs.itemList.getBoundingClientRect()
      } else {
        return { height: 0, top: 0, bottom: 0 }
      }
    },
    highlightPrevious () {
      let newIndex = this.highlightedItemIndex - 1
      if (newIndex < 0) {
        newIndex = this.items.length - 1
      }
      this.highlightedItemIndex = newIndex
    },
    highlightNext () {
      let newIndex = this.highlightedItemIndex + 1
      if (newIndex > this.items.length - 1) {
        newIndex = 0
      }
      this.highlightedItemIndex = newIndex
    },
    onKeyUp (e) {
      const code = e.code

      if (code === 'Enter') {
        this.trySelect()
        return
      }

      if (code === 'ArrowUp') {
        this.highlightPrevious()
        return
      }

      if (code === 'ArrowDown') {
        this.highlightNext()
      }
    },
    onInputKeyUp (e) {
      const code = e.code

      if (code === 'Enter') {
        this.trySelect()
      }
    },
    onKeyDown (e) {
      const code = e.code

      if (code === 'ArrowUp' || code === 'ArrowDown') {
        e.preventDefault()
      }
    }
  },
  computed: {
    isEntityList () {
      const entityTypeIds = [9, 10, 18, 38]
      return entityTypeIds.includes(this.initialSearchProvider.listTypeId)
    },
    showEmployeeOptions () {
      const employeeTypeIds = [10, 18]
      return employeeTypeIds.includes(this.initialSearchProvider.listTypeId)
    },
    isTransferrable () {
      const transferrableTypeIds = [18, 37, 38]
      return transferrableTypeIds.includes(this.initialSearchProvider.listTypeId)
    },
    isCostCodeList () {
      const costCodeTypeIds = [4, 36]
      return costCodeTypeIds.includes(this.initialSearchProvider.listTypeId)
    },
    globalCostCodeOptionClass () {
      if (this.costCodeTypeSelected === 'G') {
        return 'btn-active'
      } else if (this.allowGlobalCostCodes) {
        return 'close-btn'
      } else {
        return 'close-btn btn-disabled'
      }
    },
    hasOnlyGlobalCodes () {
      return this.costCodes.filter(x => x.IsGlobal === false).length === 0
    },
    shouldUseGlobalCostCodeType () {
      const currentCostCode = this.costCodes.find(x => this.selection.Value !== '' && x.Value === this.selection.Value)
      return (this.costCodeTypeSelected === 'S' && this.hasOnlyGlobalCodes) || (currentCostCode && currentCostCode.IsGlobal)
    },
    globalStockpointId () {
      return this.$store.getters.getStockpointId
    }
  },
  watch: {
    async entityTypeSelected (newVal) {
      const currentListTypeId = this.currentSearchProvider.listTypeId

      if (newVal === 'E') {
        const typeId = this.isTransferrable ? 37 : 7
        this.currentSearchProvider.listTypeId = typeId
      } else if (newVal === 'L') {
        const typeId = this.isTransferrable ? 38 : 9
        this.currentSearchProvider.listTypeId = typeId
      } else if (newVal === 'SL') {
        const typeId = this.isTransferrable ? 39 : 11
        this.currentSearchProvider.listTypeId = typeId
      } else if (newVal === 'NSL') {
        this.currentSearchProvider.listTypeId = 61
      } else {
        this.currentSearchProvider = deepCopy(this.initialSearchProvider)
      }

      if (this.currentSearchProvider.listTypeId !== currentListTypeId) {
        await this.getItems()
      }
    },
    async costCodeTypeSelected () {
      if (this.isInitialized) {
        this.text = ''
      }
      this.items = this.filterCostCodes()
    },
    highlightedItemIndex () {
      this.scrollToIndex()
    },
    items (newItems) {
      if (newItems && newItems.length > 0) {
        this.highlightedItemIndex = 0
      } else {
        this.highlightedItemIndex = -1
      }
    },
    isInitialized (newVal) {
      if (newVal === true) {
        if (this.selection.Value != '') {
          this.$nextTick(() => {
            for (let i = 0; i < this.items.length; i++) {
              const item = this.items[i]
              if (item.Value === this.selection.Value) {
                this.highlightedItemIndex = i
                break
              }
            }
          })
        }
      }
    }
  }
}
</script>
<style scoped>
  /* This exists globally, but only under certain contexts,
  so make sure it's available here */
  :root {
    --primary-color: #d29735;
  }
  .close-btn {
    background: transparent!important;
    color: #495760!important;
    border: 1px solid #DBDBDA!important;
  }
  .btn-label {
    width:fit-content!important;
    padding-right:0px;
  }
  .btn-active {
    background: var(--primary-color);
    color: white!important;
    font-weight: bold;
  }
  .btn-active:hover {
    color: white!important;
  }
  .btn-disabled {
    opacity: .5;
    background: grey!important;
  }
  .list-search-input {
    width:100%!important;
    border: 1px solid #E1E1E1;
    font-size: 12px;
    color: #231F20;
    padding: 0 15px 0 10px;
  }
  .item-list {
    height:350px;
    overflow: auto;
    border: 1px solid #E1E1E1;
    position: relative;
    outline: none;
    width:100%;
  }
  .item-highlight {
    background: var(--primary-color)!important;
    color: #ffffff!important;
  }
  .item-choice {
    display: flex;
    width: 100%;
    white-space: pre-wrap;
    height:20px;
    padding-left:5px;
    cursor: pointer;
    overflow: auto;
  }
  .btn {
    margin: 0 10px 12px!important;
  }
  .btn.close-btn {
    margin: 0 10px 12px 0!important;
  }

  @media (max-width: 500px) {
    .btn {
      margin: 0 auto 12px!important;
    }
    .btn.close-btn {
      margin: 0 auto 12px!important;
    }
  }
</style>

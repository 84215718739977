const scanItems = {
  tableHeader: {
    desktop: [
      {
        label: 'Num',
        key: 'itemNumber'
      },
      {
        label: 'Description',
        key: 'description'
      },
      {
        label: 'Qty',
        key: 'quantity'
      },
      {
        label: 'RetiredAt',
        key: 'lostAtId',
        isEditable: true,
        type: 'Guid',
        listType: 11
      },
      {
        label: 'Reinstate To',
        key: 'assignedToId',
        isEditable: true,
        type: 'Guid',
        listType: 11
      },
      {
        label: 'Status',
        key: 'statusCodeNumber'
      },
      {
        label: 'Personal Issue',
        key: 'personalIssue',
        type: Boolean
      },
      {
        label: 'Type',
        key: 'itemType'
      },
      {
        label: 'Notes',
        key: 'notes'
      }
    ],
    mobile: [
      {
        label: 'Num',
        key: 'itemNumber'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Description',
            key: 'description'
          },
          {
            label: 'Qty',
            key: 'quantity'
          },
          {
            label: 'RetiredAt',
            key: 'lostAtId',
            isEditable: true,
            type: 'Guid',
            listType: 11
          },
          {
            label: 'Reinstate To',
            key: 'assignedToId',
            isEditable: true,
            type: 'Guid',
            listType: 11
          },
          {
            label: 'Status',
            key: 'statusCodeNumber'
          },
          {
            label: 'Personal Issue',
            key: 'personalIssue',
            type: Boolean
          },
          {
            label: 'Type',
            key: 'itemType'
          },
          {
            label: 'Notes',
            key: 'notes'
          }
        ]
      }
    ]
  }
}

export default scanItems

const costCenters = {
  tableHeader: {
    desktop: [
      {
        label: 'Control'
      },
      {
        label: 'No.'
      },
      {
        label: 'Descripción'
      },
      {
        label: 'Grupo de códigos'
      },
      {
        label: 'Inactivo'
      },
      {
        label: ''
      }
    ],
    mobile: [
      {
        label: 'control'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.'
          },
          {
            label: 'Descripción'
          },
          {
            label: 'Grupo de códigos'
          },
          {
            label: 'Inactivo'
          }
        ]
      },
      {
        label: ''
      }
    ]
  },
  modifyVisibilityTableHeader: {
    desktop: [
      {
        label: '',
        key: 'costCenterId',
        type: 'check'
      },
      {
        label: 'No.',
        key: 'costCenterNumber',
        sortable: true,
        type: String,
        showColFilter: false
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true,
        type: String,
        showColFilter: false
      }
    ],
    mobile: [
      {
        label: '',
        key: 'costCenterId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.',
            type: String,
            key: 'costCenterNumber',
            sortable: true,
            showColFilter: false
          },
          {
            label: 'Descripción',
            type: String,
            key: 'description',
            sortable: true,
            showColFilter: false
          }
        ]
      }
    ]
  }
}

export default costCenters

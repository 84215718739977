const recycleBin = {
  title: 'Recycle Bin',
  searchHeader: 'Filter Results',
  startDate: 'Show items recycled between',
  endDate: 'and',
  recordType: 'Record Type',
  recordTypes: {
    0: '',
    1: 'Assets',
    2: 'Categories',
    3: 'Certifications',
    4: 'Classes',
    5: 'Contractors',
    6: 'Cost Centers',
    7: 'Cost Codes',
    8: 'Count Tickets',
    9: 'Custom Dashboards',
    10: 'Custom Reports',
    11: 'Customers',
    12: 'Departments',
    13: 'Employee Types',
    14: 'Employees',
    15: 'Fuel Cards',
    16: 'Kits',
    17: 'Locations',
    18: 'Manufacturers',
    19: 'Masters',
    20: 'PurchaseOrder',
    21: 'Rate Sheet Lines',
    22: 'Rate Sheets',
    23: 'Service Classes',
    24: 'Service Requests',
    25: 'Shipping Methods',
    26: 'Status Codes',
    27: 'Transfer',
    28: 'Units of Measure',
    29: 'Vendors',
    30: 'Work Orders'
  },
  actionHeader: {
    0: {
      title: 'View Item',
      icon: 'fas fa-edit'
    },
    1: {
      title: 'Reinstate',
      icon: ''
    }
  },
  detail: {
    title: 'Recycle Detail',
    labels: {
      0: 'Number',
      1: 'Type',
      2: 'Stock Location',
      3: 'Created On',
      4: 'Created By',
      5: 'Deleted On',
      6: 'Deleted By'
    }
  },
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'Deleted',
        key: 'DeletedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Type',
        key: 'RecordType',
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'Record Number',
        key: 'DeletedRecordNumber',
        type: String,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'Deleted',
        key: 'DeletedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Type',
            key: 'RecordType',
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            type: String,
            sortable: true
          }
        ]
      },
      {
        label: 'Record Number',
        key: 'DeletedRecordNumber',
        type: String,
        sortable: true
      }
    ],
    dropdownActions: [
      {
        title: 'Reinstate',
        actionName: 'reinstate',
        permissions: []
      }
    ]
  }
}

export default recycleBin

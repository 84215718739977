const contractors = {
  tableHeader: {
    desktop: [
      {
        label: 'check',
        key: 'contractorId',
        isModify: true
      },
      {
        label: 'No.',
        key: 'contractorNumber',
        sortable: true,
        isModify: false
      },
      {
        label: 'Name',
        key: 'name',
        sortable: true
      },
      {
        label: 'City',
        key: 'city',
        sortable: true,
        isModify: false
      },
      {
        label: 'State',
        key: 'state',
        sortable: true,
        isModify: false
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'contractorId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.',
            key: 'contractorNumber',
            sortable: true,
            isModify: false
          },
          {
            label: 'Name',
            key: 'name',
            sortable: true
          },
          {
            label: 'City',
            key: 'city',
            sortable: true,
            isModify: false
          },
          {
            label: 'State',
            key: 'state',
            sortable: true,
            isModify: false
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default contractors

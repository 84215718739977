const costCodes = {
  tableHeader: {
    desktop: [
      {
        label: 'check',
        key: 'costCodeId',
        isModify: true
      },
      {
        label: 'No.',
        key: 'costCodeNumber',
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true
      },
      {
        label: 'Cost Center',
        key: 'costCenter',
        sortable: true,
        isModify: false
      },
      {
        label: 'Code Group',
        key: 'codeGroup',
        sortable: true,
        isModify: false
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'costCodeId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.',
            key: 'costCodeNumber',
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'Cost Center',
            key: 'costCenter',
            sortable: true,
            isModify: false
          },
          {
            label: 'Code Group',
            key: 'codeGroup',
            sortable: true,
            isModify: false
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default costCodes

const appLists = {
  0: {
    Key: 'Lugar de trabajo',
    Value: 'jsp',
    Title: 'Lugar de trabajo'
  },
  1: {
    Key: 'Operaciones de almacén/patio',
    Value: 'wh',
    Title: 'Almacén/Patio'
  },
  2: {
    Key: 'Area Logistica',
    Value: 'bo',
    Title: 'Area Logistica'
  }
}

export default appLists

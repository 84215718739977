const bleTag = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: '',
        isModify: false
      },
      {
        label: 'check',
        key: 'UnmappedBleTagId',
        isModify: true
      },
      {
        label: 'Tag Mac Address',
        key: 'BleTagMacAddress',
        sortable: true
      },
      {
        label: 'Brand',
        key: 'Manufacturer',
        sortable: true
      },
      {
        label: 'Last Seen',
        type: Date,
        key: 'LastScan',
        sortable: true
      },
      {
        label: 'Gateway Mac Address',
        key: 'BleGatewayMacAddress',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'UnmappedBleTagId'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Tag Mac Address',
            key: 'BleTagMacAddress',
            sortable: true
          },
          {
            label: 'Brand',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Last Seen',
            type: Date,
            key: 'LastSeen',
            sortable: true
          },
          {
            label: 'Gateway Mac Address',
            key: 'BleGatewayMacAddress',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default bleTag

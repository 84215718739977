const categories = {
  ERP: 'ERP / Sistemas contables',
  CONSTRUCTION: 'Gestión de la construcción',
  MANUFACTURERS: 'Fabricantes',
  FLEET: 'Flota',
  BLE: 'Inalámbrico',
  IDENTITY: 'Identidad'
}

const integration = {
  title: 'Catálogo de integración',
  categories: categories,
  integrations: [
    {
      name: 'Vista',
      desc: 'Vista desc',
      category: categories.ERP,
      logo: 'vista.jpg',
      id: 'A3EE0C5B-AA25-4763-AE27-50737EE51CEC'
    },
    {
      name: 'Spectrum',
      desc: 'Spectrum desc',
      category: categories.ERP,
      logo: 'spectrum.png',
      id: 'BFBE365A-7846-4E29-8AAB-CB5D2D853585'
    },
    {
      name: 'Sage 300 CRE',
      desc: 'Sage 300 CRE desc',
      category: categories.ERP,
      logo: 'sage-300.png',
      id: 'DBB358BC-0CB8-403E-8555-9BB605083A09'
    },
    {
      name: 'Sage 100 Contractor',
      desc: 'Sage 100 Contractor desc',
      category: categories.ERP,
      logo: 'sage-100.jpg',
      id: '074ADCA3-AE25-46D4-9763-471CBC0A9507'
    },
    {
      name: 'eCMS',
      desc: 'eCMS desc',
      category: categories.ERP,
      logo: 'ecms.png',
      id: 'AB0930F3-C729-4D63-BE56-2AA651B9198B'
    },
    {
      name: 'Intacct',
      desc: 'Intacct desc',
      category: categories.ERP,
      logo: 'intacct.jpg',
      id: 'EAC589CB-93AF-4898-8A7D-D42605489835'
    },
    {
      name: 'CMiC',
      desc: 'CMiC desc',
      category: categories.ERP,
      logo: 'cmic.png',
      id: '394A47F0-640D-40A6-BA2A-7A4FDDE0BDE9'
    },
    {
      name: 'Procore',
      desc: 'Procore desc',
      category: categories.CONSTRUCTION,
      logo: 'procore.jpg',
      id: '250C2CA2-5626-4E55-A8E6-2CC3E6BC744B'
    },
    {
      name: 'DEWALT (Product Catalog)',
      desc: 'DEWALT (Product Catalog)desc',
      category: categories.MANUFACTURERS,
      logo: 'dewalt.png',
      id: ''
    },
    {
      name: 'GPS Insight',
      desc: 'GPS Insight',
      category: categories.FLEET,
      logo: 'gps-insight.jpg',
      id: 'C9734889-EE72-4CFA-B9AE-40866C4829DE'
    },
    {
      name: 'DEWALT (Site Manager)',
      desc: 'DEWALT (Site Manager) des',
      category: categories.BLE,
      logo: 'dewalt-site-manager.jpg',
      id: '783569A7-41FA-484E-85E7-A3CAD6C9B604'
    },
    {
      name: 'Active AD',
      desc: 'Active AD desc',
      category: categories.IDENTITY,
      logo: 'active-directory.png',
      id: ''
    },
    {
      name: 'OneLogin',
      desc: 'OneLogin desc',
      category: categories.IDENTITY,
      logo: 'one-login.png',
      id: ''
    },
    {
      name: 'Auth0',
      desc: 'Auth0 desc',
      category: categories.IDENTITY,
      logo: 'auth0.png',
      id: ''
    },
    {
      name: 'SAML 2.0',
      desc: 'SAML 2.0desc',
      category: categories.IDENTITY,
      logo: 'saml2.png',
      id: ''
    }
  ]
}

export default integration

const expandedToolItems = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: 'Creado por',
        key: 'createdBy',
        sortable: true,
        type: String
      },
      {
        label: 'Creado en',
        key: 'createdOn',
        sortable: true,
        type: Date
      },
      {
        label: 'Categoría',
        key: 'category',
        sortable: true,
        type: String
      },
      {
        label: 'Número',
        key: 'itemNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Código de barras',
        key: 'barcode',
        sortable: true,
        type: String
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true,
        isMaterial: true,
        type: String,
        sorted: true,
        asc: true
      },
      {
        label: 'Tipo',
        key: 'itemType',
        sortable: true,
        type: String
      },
      {
        label: 'Cant.',
        key: 'quantity',
        sortable: true,
        isMaterial: true,
        type: Number
      },
      {
        label: 'Asignado',
        key: 'pickedQuantity',
        sortable: true,
        isMaterial: true,
        type: Number,
        flag: 'isShowPickedItemState'
      },
      {
        label: 'Disponible',
        key: 'available',
        sortable: true,
        isMaterial: true,
        type: Number,
        flag: 'isShowPickedItemState'
      },
      {
        label: 'Asignación',
        key: 'assignedTo',
        sortable: true,
        type: String
      },
      {
        label: 'Estado',
        key: 'itemStatus',
        sortable: true,
        type: String
      },
      {
        label: 'Tran. Fecha',
        key: 'lastTransferDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Tran. No.',
        key: 'lastTransferNumber',
        sortable: true
      },
      {
        label: 'Periodo',
        key: 'ageDays',
        sortable: true,
        type: 'age'
      },
      {
        label: 'Personal responsable',
        key: 'responsibleParty',
        sortable: 'true'
      },
      {
        label: 'Propietario',
        key: 'owner',
        sortable: true,
        type: String
      },
      {
        label: 'Compartemiento',
        key: 'binId',
        sortable: true
      },
      {
        label: 'Clase de artículo',
        key: 'className',
        sortable: true,
        type: String
      },
      {
        label: 'Entregado por',
        key: 'lastTransferDeliveredBy',
        sortable: true
      },
      {
        label: 'Departamento',
        key: 'department',
        sortable: true,
        type: String
      },
      {
        label: 'Equipo',
        key: 'kitNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Último contado',
        key: 'lastCountDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Último escaneado',
        key: 'lastScannedOn',
        sortable: true,
        type: Date
      },
      {
        label: 'Última área de escaneo BLE',
        key: 'lastScanArea',
        sortable: true
      },
      {
        label: 'Última ubicación de escaneo BLE',
        key: 'lastScanLocation',
        type: 'LastScanLocation',
        sortable: true
      },
      {
        label: 'Posición GPS',
        key: 'gpsPosition',
        sortable: true
      },
      {
        label: 'Gerente',
        key: 'manager',
        sortable: true,
        type: String
      },
      {
        label: 'Fabricante',
        key: 'manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Metro',
        key: 'meterLevel',
        sortable: true,
        type: Number
      },
      {
        label: 'año modelo',
        key: 'modelYear',
        sortable: true,
        type: Number
      },
      {
        label: 'Modelo',
        key: 'model',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'UP',
        key: 'personalIssue',
        sortable: true,
        type: Boolean
      },
      {
        label: 'Costo de remplazo',
        key: 'replacementCost',
        sortable: true,
        type: Number
      },
      {
        label: 'Retornado',
        key: 'returnDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Número de serie',
        key: 'serialNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Supervisor',
        key: 'supervisor',
        sortable: true,
        type: String
      },
      {
        label: 'Uom',
        key: 'uom',
        sortable: true
      },
      {
        label: 'Kit',
        key: 'InKitType',
        type: 'InKit'
      },
      {
        label: 'Wireless',
        key: 'hasBleTag',
        type: 'BleTag'
      },
      {
        label: 'Necesita verificar',
        key: 'needsVerificationScore',
        sortable: true
      },
      {
        label: 'Notas',
        key: 'notes',
        sortable: true,
        type: String
      },
      {
        label: 'Vin',
        key: 'vin',
        sortable: true,
        type: String
      },
      {
        label: 'Número de placa',
        key: 'licensePlateNumber',
        sortable: true,
        type: String
      },
      {
        label: 'Estado de la placa',
        key: 'licensePlateState',
        sortable: true,
        type: String
      },
      {
        label: 'Costo de la última compra',
        key: 'lastPurchaseCost',
        sortable: true,
        type: Number
      }
    ],
    mobile: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: '',
        key: 'InKitType',
        type: 'InKit'
      },
      {
        label: 'Info',
        data: [
          {
            label: '',
            key: 'hasBleTag',
            type: 'BleTag'
          },
          {
            label: 'Categoría',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Número',
            key: 'itemNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Código de barras',
            key: 'barcode',
            sortable: true,
            type: String
          },
          {
            label: 'Número de serie',
            key: 'serialNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Equipo',
            key: 'kitNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Tipo',
            key: 'itemType',
            sortable: true,
            type: String
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Fabricante',
            key: 'manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Modelo',
            key: 'model',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Cant.',
            key: 'quantity',
            sortable: true,
            isMaterial: true,
            type: Number
          },
          {
            label: 'Asignado',
            key: 'pickedQuantity',
            sortable: true,
            isMaterial: true,
            type: Number,
            flag: 'isShowPickedItemState'
          },
          {
            label: 'Disponible',
            key: 'available',
            sortable: true,
            isMaterial: true,
            type: Number,
            flag: 'isShowPickedItemState'
          },
          {
            label: 'Uom',
            key: 'uom',
            sortable: true
          },
          {
            label: 'Estado',
            key: 'itemStatus',
            sortable: true,
            type: String
          },
          {
            label: 'UP',
            key: 'personalIssue',
            sortable: true,
            type: Boolean
          },
          {
            label: 'Asignación',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'Compartemiento',
            key: 'binId',
            sortable: true
          },
          {
            label: 'Personal responsable',
            key: 'responsibleParty',
            sortable: 'true'
          },
          {
            label: 'Periodo',
            key: 'ageDays',
            sortable: true,
            type: 'age'
          },
          {
            label: 'Último escaneado',
            key: 'lastScannedOn',
            sortable: true,
            type: Date
          },
          {
            label: 'Última ubicación de escaneo BLE',
            key: 'lastScanLocation',
            type: 'LastScanLocation',
            sortable: true
          },
          {
            label: 'Última área de escaneo BLE',
            key: 'lastScanArea',
            sortable: true
          },
          {
            label: 'Posición GPS',
            key: 'gpsPosition',
            sortable: true
          },
          {
            label: 'Último contado',
            key: 'lastCountDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Tran. No.',
            key: 'lastTransferNumber',
            sortable: true
          },
          {
            label: 'Tran. Fecha',
            key: 'lastTransferDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Regresando',
            key: 'returnDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Propietario',
            key: 'owner',
            sortable: true,
            type: String
          },
          {
            label: 'Gerente',
            key: 'manager',
            sortable: true,
            type: String
          },
          {
            label: 'Departamento',
            key: 'department',
            sortable: true,
            type: String
          },
          {
            label: 'Supervisor',
            key: 'supervisor',
            sortable: true,
            type: String
          },
          {
            label: 'Entregado por',
            key: 'lastTransferDeliveredBy',
            sortable: true
          },
          {
            label: 'Clase de artículo',
            key: 'className',
            sortable: true,
            type: String
          },
          {
            label: 'Necesita verificar',
            key: 'needsVerificationScore',
            sortable: true
          },
          {
            label: 'Notas',
            key: 'notes',
            sortable: true,
            type: String
          },
          {
            label: 'Costo de la última compra',
            key: 'lastPurchaseCost',
            sortable: true,
            type: Number
          }
        ]
      }
    ]
  }
}

export default expandedToolItems

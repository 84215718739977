const companyCalendar = {
  modal: {
    title: 'Set working hours',
    appointmentTitle: 'Set appointment'
  },
  labels: {
    0: 'Is working day',
    1: 'Start',
    2: 'End',
    3: 'Notes',
    4: 'Default Work Schedule',
    5: 'Non-Working Day',
    6: 'Manage Project Defaults...',
    7: 'Working Hour Defaults',
    8: 'Day',
    9: 'Week',
    10: 'Month',
    11: 'Is holiday',
    12: 'Modified Working Hours',
    13: 'Non-Working Days',
    14: 'to',
    15: 'Today',
    16: 'Is note',
    17: 'Is all day appointment'
  },
  warningDlg: {
    title: 'Warning',
    message: 'EndDateTime must be greater than or equal to the StartDateTime.'
  }
}

export default companyCalendar

const safetyPlan = {
  title: 'Planes de seguridad del sitio',
  addSiteSafetyPlan: 'Agregar plan de seguridad del sitio',
  viewSiteSafetyPlanDocument: 'Ver el documento del plan de seguridad del sitio',
  dateValidationMessage: 'La fecha de finalización debe ser mayor que la fecha de inicio.',
  frequencyDaysValidationMessage: 'Por favor seleccione al menos una casilla de verificación para Día(s)',
  frequencyMonthsValidationMessage: 'Por favor seleccione al menos una casilla de verificación para Mes(s)',
  deleteConfirmation: '¿Está seguro de que desea eliminar el Plan de seguridad del sitio?',
  header: {
    title: 'Crear un plan de seguridad del sitio',
    editTitle: 'Editar plan de seguridad del sitio',
    labels: {
      0: 'Clonar plan existente',
      1: 'Nombre del plan',
      2: 'identificación del plan',
      3: 'Descripción',
      4: 'Proyecto No.'
    },
    buttons: {
      attachBtnText: 'Adjuntar archivos'
    }
  },
  tabs: {
    0: 'Información general',
    1: 'Requerimientos de seguridad',
    2: 'Riesgos y EPP',
    3: 'Certificaciones',
    4: 'Partes interesadas y contratistas',
    5: 'Información de registro'
  },
  dropdownActions: [
    {
      title: 'Editar',
      actionName: 'editSafetyPlan',
      permissions: []
    },
    {
      title: 'Borrar',
      actionName: 'confirmDeleteSafetyPlan',
      permissions: []
    }
  ],
  safetyRequirementspanels: {
    inspectionRequirements: 'Requisitos de inspección',
    trainingRequirements: 'Requisitos de capacitación',
    incidentRequirements: 'Requisitos del incidente',
    observationRequirements: 'Requisitos de observación',
    jsaRequirements: 'Requisitos JSA',
    safetyScanRequirements: 'Requisitos de exploración de seguridad',
    formsRequirements: 'Requisitos de formularios'
  },
  StakeholdersContractors: {
    stakeholders: 'Partes Interesadas',
    contractors: 'Contratistas'
  },
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'SafetyPlanHeaderId',
        type: 'check'
      },
      {
        label: 'Identificación del plan',
        key: 'PlanId',
        sortable: true
      },
      {
        label: 'Nombre del plan',
        key: 'PlanName',
        sortable: true
      },
      {
        label: 'Ubicación de trabajo asignada',
        key: 'AssignedJobLocation',
        sortable: true
      },
      {
        label: 'Fecha de inicio',
        key: 'ProjectStartDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Fecha final',
        key: 'ProjectEndDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Estado',
        key: 'Status',
        sortable: true
      },
      {
        label: 'Estado',
        key: 'State',
        sortable: true
      },
      {
        label: 'Proyecto No.',
        key: 'ProjectNumber',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'SafetyPlanHeaderId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Identificación del plan',
            key: 'PlanId',
            sortable: true
          },
          {
            label: 'Nombre del plan',
            key: 'PlanName',
            sortable: true
          },
          {
            label: 'Ubicación de trabajo asignada',
            key: 'AssignedJobLocation',
            sortable: true
          },
          {
            label: 'Fecha de inicio',
            key: 'ProjectStartDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Fecha final',
            key: 'ProjectEndDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Estado',
            key: 'Status',
            sortable: true
          },
          {
            label: 'Estado',
            key: 'State',
            sortable: true
          },
          {
            label: 'Proyecto No.',
            key: 'ProjectNumber',
            sortable: true
          }
        ]
      }
    ]
  },
  addSafetyPlanInspection: 'Añadir Inspección',
  inspectionRequirements: {
    searchPlaceHolder: 'Buscar'
  },
  trainingRequirements: {
    buttons: {
      addTraining: 'Agregar entrenamiento'
    },
    dropdownActions: [
      {
        title: 'Editar',
        actionName: 'editTraining',
        permissions: []
      },
      {
        title: 'Borrar',
        actionName: 'confirmDeleteTraining',
        permissions: []
      },
      {
        title: 'Clon',
        actionName: 'cloneTraining',
        permissions: []
      }
    ],
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'SafetyPlanTrainingId',
          type: 'check'
        },
        {
          label: 'Nombre',
          key: 'TrainingName',
          sortable: true
        },
        {
          label: 'Frecuencia',
          key: 'FrequencyName',
          sortable: true
        },
        {
          label: 'Fecha de inicio',
          key: 'StartDate',
          sortable: true,
          type: Date
        },
        {
          label: 'Fecha final',
          key: 'EndDate',
          sortable: true,
          type: Date
        },
        {
          label: 'Empleados asignados',
          key: 'UserAssignedNames',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'SafetyPlanTrainingId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Nombre',
              key: 'TrainingName',
              sortable: true
            },
            {
              label: 'Frecuencia',
              key: 'FrequencyName',
              sortable: true
            },
            {
              label: 'Fecha de inicio',
              key: 'StartDate',
              sortable: true,
              type: Date
            },
            {
              label: 'Fecha final',
              key: 'EndDate',
              sortable: true,
              type: Date
            },
            {
              label: 'Empleados asignados',
              key: 'UserAssignedNames',
              sortable: true
            }
          ]
        }
      ]
    },
    toolboxTalkExamCourse: {
      paragraph1: 'Los exámenes de revisión de capacitación se generan por correo electrónico o mensaje de texto y se entregan a cada uno de los usuarios seleccionados que hayan ingresado un correo electrónico y/o teléfonos celulares válidos. Al completar esta página, se crearán mensajes de examen con un enlace a la página del examen de Toolbox Talk y se enviarán o programarán su envío a cada usuario. El método de entrega está determinado por la configuración de los usuarios de la capacitación (es decir, correo electrónico o teléfono celular).',
      paragraph2: 'Los mensajes de texto solo se enviarán entre las 6 a. m. y las 10 p. m., hora central. Para verificar el estado del examen de revisión, simplemente edite este evento de capacitación para ver la lista de usuarios que aparecen en la sección Destinatarios del examen a continuación. Cada listado contendrá el nombre de los usuarios de capacitación junto con el enlace de su examen, la cantidad de intentos y la puntuación.'
    },
    trainingDetail: {
      title: 'Detalle de entrenamiento',
      SourceRequired: 'Se requiere fuente',
      CategoryRequired: 'la categoria es requerida',
      ToolboxTalkRequired: 'Se requiere charla sobre herramientas',
      ConfirmationRequired: 'Se requiere confirmación de entrenamiento',
      TrainersAssignedRequired: 'Se requieren entrenadores asignados',
      labels: {
        0: 'Nombre de entrenamiento',
        1: 'Tipo de curso',
        2: 'Frecuencia',
        3: 'Día(s)',
        4: 'Mes(s)',
        5: 'Fecha de inicio y finalización',
        6: 'Empleados asignados',
        7: 'Instrucciones para la presentación de informes',
        8: 'Seleccionar todo',
        9: 'Desmarcar todo',
        11: 'Fuente',
        12: 'Categoría',
        13: 'Charla sobre la caja de herramientas',
        14: 'Confirmación de entrenamiento',
        15: 'Entrenadores asignados'
      }
    },
    deleteConfirmation: '¿Está seguro de que desea eliminar los requisitos de capacitación?\nLos eventos de capacitación que ya hayan ocurrido no se eliminarán',
    TrainingConfirmationParagraph1: `Las confirmaciones de capacitación se generan por correo electrónico o mensaje de texto.
    y entregado a cada uno de los usuarios seleccionados que tengan ingresado un correo electrónico y/o celular válido.
    Al completar esta página, se crearán mensajes con un enlace a la página de confirmación y
    enviado o programado para ser enviado a cada usuario.`,
    TrainingConfirmationParagraph2: `El método de entrega lo determinan los usuarios de la capacitación.
    configuración (es decir, correo electrónico o teléfono móvil). Los mensajes de texto solo se enviarán entre las 6 a. m.
    y las 22:00 horas hora central. Para comprobar el estado de la confirmación simplemente edite esta Formación
    Evento para ver la lista de usuarios que aparecen en la sección Destinatarios a continuación. Cada listado contendrá
    el nombre de los usuarios de la capacitación junto con el enlace del examen, el número de intentos y la puntuación.`
  },
  stakeholders: {
    title: 'Detalle de las partes interesadas',
    buttons: {
      addStakeholder: 'Agregar parte interesada'
    },
    dropdownActions: [
      {
        title: 'Editar',
        actionName: 'editStakeHolder',
        permissions: []
      },
      {
        title: 'Borrar',
        actionName: 'confirmDeleteStakeHolder',
        permissions: []
      }
    ],
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'safetyplanHeaderId',
          type: 'check'
        },
        {
          label: 'Nombre',
          key: 'Name',
          sortable: true
        },
        {
          label: 'Cargo/ Responsabilidades',
          key: 'TaskResponsibility',
          sortable: true
        },
        {
          label: 'Correo electrónico',
          key: 'Email',
          sortable: true
        },
        {
          label: 'Teléfono',
          key: 'Phone',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'safetyplanHeaderId',
          type: 'check'
        },
        {
          label: 'Información',
          data: [
            {
              label: 'Nombre',
              key: 'Name',
              sortable: true
            },
            {
              label: 'Cargo/ Responsabilidades',
              key: 'TaskResponsibility',
              sortable: true
            },
            {
              label: 'Correo electrónico',
              key: 'Email',
              sortable: true
            },
            {
              label: 'Teléfono',
              key: 'Phone',
              sortable: true
            }
          ]
        }
      ]
    },
    labels: {
      0: 'Interesado',
      1: 'Cargo/ Responsabilidades',
      2: 'Correo electrónico',
      3: 'Teléfono'
    },
    deleteConfirmation: '¿Estás seguro de que quieres eliminar el Stakeholder?'
  },
  jsaRequirements: {
    buttons: {
      addJsa: 'Agregar JSA'
    },
    dropdownActions: [
      {
        title: 'Editar',
        actionName: 'editSafetyPlanJsa',
        permissions: []
      },
      {
        title: 'Borrar',
        actionName: 'confirmDeleteSafetyPlanJsa',
        permissions: []
      },
      {
        title: 'Clon',
        actionName: 'cloneSafetyPlanJsa',
        permissions: []
      }
    ],
    JSADetail: {
      title: 'Detalle JSA',
      labels: {
        0: 'Nombre JSA',
        1: 'Plantillas bancarias JSA',
        5: 'Fecha de inicio y finalización',
        6: 'Usuarios asignados',
        7: 'Instrucciones para informar'
      },
      dateValidationMessage: 'La Fecha de finalización debe ser mayor que la Fecha de inicio.',
      deleteConfirmation: '¿Está seguro de que desea eliminar los requisitos de JSA?'
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'SafetyPlanJSAId',
          type: 'check'
        },
        {
          label: 'Nombre',
          key: 'JsaName',
          sortable: true
        },
        {
          label: 'Plantilla del banco JSA',
          key: 'BanKTemplateIdDesc',
          sortable: true
        },
        {
          label: 'Frecuencia',
          key: 'PatternType',
          sortable: true
        },
        {
          label: 'Fecha de inicio',
          key: 'StartDate',
          sortable: true,
          type: Date
        },
        {
          label: 'Fecha final',
          key: 'EndDate',
          sortable: true,
          type: Date
        },
        {
          label: 'Asignado por el usuario',
          key: 'UserAssigned',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'SafetyPlanJSAId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Nombre',
              key: 'JsaName',
              sortable: true
            },
            {
              label: 'Plantilla del banco JSA',
              key: 'BanKTemplateIdDesc',
              sortable: true
            },
            {
              label: 'Frecuencia',
              key: 'PatternType',
              sortable: true
            },
            {
              label: 'Fecha de inicio',
              key: 'StartDate',
              sortable: true,
              type: Date
            },
            {
              label: 'Fecha final',
              key: 'EndDate',
              sortable: true,
              type: Date
            },
            {
              label: 'Asignado por el usuario',
              key: 'UserAssigned',
              sortable: true
            }
          ]
        }
      ]
    }
  },
  hazardsPPE: {
    buttons: {
      addHazard: 'Agregar peligro'
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'HazardId',
          type: 'check'
        },
        {
          label: 'No.',
          key: 'HazardNumber',
          sortable: true
        },
        {
          label: 'Peligro',
          key: 'Hazard',
          sortable: true
        },
        {
          label: 'Descripción',
          key: 'HazardDescription',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'HazardId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'No.',
              key: 'HazardNumber',
              sortable: true
            },
            {
              label: 'Peligro',
              key: 'Hazard',
              sortable: true
            },
            {
              label: 'Descripción',
              key: 'HazardDescription',
              sortable: true
            }
          ]
        }
      ]
    },
    hazardDetail: {
      title: 'Crear peligro',
      labels: {
        0: 'Peligro No.',
        1: 'Peligro',
        2: 'Descripción',
        3: 'es genérico'
      }
    },
    hazardsPPEPanels: {
      hazardsOnSite: 'Peligros en el sitio',
      ppeRequirements: 'Requisitos de EPI'
    }
  },
  safetyPlanObservation: {
    labels: {
      0: 'Hacer cumplir los requisitos de observación',
      1: 'Instrucciones para informar',
      2: 'Frecuencia de envío'
    },
    message: {
      0: 'Seleccione al menos una casilla de verificación para Día(s) en Requisitos de observación.',
      1: 'Seleccione al menos una casilla de verificación para Mes(es) en Requisitos de observación.',
      3: 'La Fecha de finalización debe ser mayor que la Fecha de inicio según los Requisitos de observación.'
    }
  },
  safetyIncident: {
    labels: {
      0: 'Hacer cumplir los requisitos de incidentes',
      1: 'Instrucciones para informar',
      2: 'Servicios médicos y administración de primeros auxilios'
    }
  },
  ppeRequirements: {
    buttons: {
      addPPE: 'Añadir PPE'
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'SafetyplanPPEId',
          type: 'check'
        },
        {
          label: 'No.',
          key: 'PPENumber',
          sortable: true
        },
        {
          label: 'Equipo de protección personal',
          key: 'PPE',
          sortable: true
        },
        {
          label: 'Descripción',
          key: 'PPEDescription',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'SafetyplanPPEId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'No.',
              key: 'PPENumber',
              sortable: true
            },
            {
              label: 'Equipo de protección personal',
              key: 'PPE',
              sortable: true
            },
            {
              label: 'Descripción',
              key: 'PPEDescription',
              sortable: true
            }
          ]
        }
      ]
    },
    ppeDetail: {
      title: 'Crear equipo de protección personal',
      labels: {
        0: 'PPE No.',
        1: 'PPE',
        2: 'Descripción'
      }
    }
  },
  recordInfo: {
    title: 'Información de registro',
    labels: {
      0: 'Creada',
      1: 'Por',
      2: 'Última modificación'
    }
  },
  certifications: {
    buttons: {
      addCertification: 'Agregar certificación',
      clone: 'Clon'
    },
    dropdownActions: [
      {
        title: 'Editar',
        actionName: 'onEdit',
        permissions: []
      },
      {
        title: 'Borrar',
        actionName: 'onDelete',
        permissions: []
      },
      {
        title: 'Clon',
        actionName: 'onClone',
        permissions: []
      }
    ],
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'CertificationId',
          type: 'check'
        },
        {
          label: 'Código',
          key: 'CertificationNumber',
          sortable: true,
          width: '120px !important'
        },
        {
          label: 'Descripción',
          key: 'Description',
          sortable: true
        },
        {
          label: 'Notas',
          key: 'Notes',
          sortable: true
        },
        {
          label: 'Renovación',
          key: 'RenewValue',
          sortable: true,
          width: '100px !important'
        },
        {
          label: '',
          key: '',
          isModify: false,
          width: '120px !important'
        }
      ],
      mobile: [
        {
          label: '',
          key: 'CertificationId',
          type: 'check'
        },
        {
          label: 'Info',
          width: '50% !important',
          data: [
            {
              label: 'Código',
              key: 'CertificationNumber',
              sortable: true
            },
            {
              label: 'Descripción',
              key: 'Description',
              sortable: true
            },
            {
              label: 'Notas',
              key: 'Notes',
              sortable: true
            },
            {
              label: 'Renovación',
              key: 'RenewValue',
              sortable: true
            }
          ]
        },
        {
          label: '',
          key: '',
          isModify: false
        }
      ]
    }
  }
}
export default safetyPlan

const workflowApprovals = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'WorkflowApprovalTaskId',
        type: 'check'
      },
      {
        label: 'Número de flujo de trabajo',
        key: 'WorkflowNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Desencadenar',
        key: 'TriggerName',
        type: String,
        sortable: true
      },
      {
        label: 'Desencadenado por',
        key: 'TriggeredBy',
        type: String,
        sortable: true
      },
      {
        label: 'Fecha de activación',
        key: 'TriggeredOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Estado',
        key: 'ApprovalStatusType',
        type: Number,
        sortable: true
      },
      {
        label: 'Disposición',
        key: 'Disposition',
        type: String,
        sortable: true
      },
      {
        label: 'Fecha de decisión',
        key: 'DecisionDate',
        type: Date,
        sortable: true
      },
      {
        label: 'Aprobador asignado',
        key: 'AssignedApprover',
        type: String,
        sortable: true
      },
      {
        label: 'Aprobado por',
        key: 'ApprovalApprovedBy',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'WorkflowApprovalTaskId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Número de flujo de trabajo',
            key: 'WorkflowNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Desencadenar',
            key: 'TriggerName',
            type: String,
            sortable: true
          },
          {
            label: 'Desencadenado por',
            key: 'TriggeredBy',
            type: String,
            sortable: true
          },
          {
            label: 'Fecha de activación',
            key: 'TriggeredOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Estado',
            key: 'ApprovalStatusType',
            sortable: true
          },
          {
            label: 'Disposición',
            key: 'Disposition',
            type: String,
            sortable: true
          },
          {
            label: 'Fecha de decisión',
            key: 'DecisionDate',
            type: Date,
            sortable: true
          },
          {
            label: 'Aprobador asignado',
            key: 'AssignedApprover',
            type: String,
            sortable: true
          },
          {
            label: 'Aprobado por',
            key: 'ApprovalApprovedBy',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default workflowApprovals

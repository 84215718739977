const twGridColumnsDisplay = {
  columnsSetting: {
    dropDownList: {
      option1: 'Select Columns to Display',
      option2: 'Reset Column Widths',
      option3: 'Reset to Default'
    },
    panelLabel: {
      modalTitle: 'Select Columns to Display',
      l_label: 'Available Columns',
      r_label: 'Visible Columns'
    }
  },
  buttons: {
    change: 'Save Changes',
    btncancel: 'Cancel'
  },
  switch: {
    text: 'Column Text Wrapping',
    on: 'On',
    off: 'Off'
  }
}
export default twGridColumnsDisplay

const expandedToolItems = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: 'Created By',
        key: 'createdBy',
        sortable: true,
        type: String
      },
      {
        label: 'Created On',
        key: 'createdOn',
        sortable: true,
        type: Date
      },
      {
        label: 'Category',
        key: 'category',
        sortable: true,
        type: String,
        dafeultDisplay: true
      },
      {
        label: 'Number',
        key: 'itemNumber',
        sortable: true,
        type: Number,
        dafeultDisplay: true
      },
      {
        label: 'Bar Code',
        key: 'barcode',
        sortable: true,
        type: String,
        dafeultDisplay: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true,
        isMaterial: true,
        type: String,
        sorted: true,
        asc: true,
        dafeultDisplay: true
      },
      {
        label: 'Type',
        key: 'itemType',
        sortable: true,
        type: String,
        dafeultDisplay: true
      },
      {
        label: 'Qty',
        key: 'quantity',
        sortable: true,
        isMaterial: true,
        type: Number,
        dafeultDisplay: true
      },
      {
        label: 'Allocated',
        key: 'pickedQuantity',
        sortable: true,
        isMaterial: true,
        type: Number,
        flag: 'isShowPickedItemState'
      },
      {
        label: 'Available',
        key: 'available',
        sortable: true,
        isMaterial: true,
        type: Number,
        flag: 'isShowPickedItemState'
      },
      {
        label: 'Assignment',
        key: 'assignedTo',
        sortable: true,
        type: String,
        dafeultDisplay: true
      },
      {
        label: 'Status',
        key: 'itemStatus',
        sortable: true,
        type: String,
        dafeultDisplay: true
      },
      {
        label: 'Tran. Date',
        key: 'lastTransferDate',
        sortable: true,
        type: Date,
        dafeultDisplay: true
      },
      {
        label: 'Tran. No.',
        key: 'lastTransferNumber',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Age',
        key: 'ageDays',
        type: 'age',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Responsible Party',
        key: 'responsibleParty',
        sortable: 'true',
        dafeultDisplay: true
      },
      {
        label: 'Owner',
        key: 'owner',
        sortable: true,
        type: String,
        dafeultDisplay: true
      },
      {
        label: 'Bin',
        key: 'binId',
        sortable: true
      },
      {
        label: 'Item Class',
        key: 'className',
        sortable: true,
        type: String
      },
      {
        label: 'Delivered By',
        key: 'lastTransferDeliveredBy',
        sortable: true
      },
      {
        label: 'Department',
        key: 'department',
        sortable: true,
        type: String
      },
      {
        label: 'Kit No.',
        key: 'kitNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Last Counted',
        key: 'lastCountDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Last Scanned',
        key: 'lastScannedOn',
        sortable: true,
        type: Date
      },
      {
        label: 'Last Wireless Scan Area',
        key: 'lastScanArea',
        sortable: true
      },
      {
        label: 'Last Wireless Scan Location',
        key: 'lastScanLocation',
        type: 'LastScanLocation',
        sortable: true
      },
      {
        label: 'GPS Position',
        key: 'gpsPosition',
        sortable: true
      },
      {
        label: 'Manager',
        key: 'manager',
        sortable: true,
        type: String
      },
      {
        label: 'Manufacturer',
        key: 'manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Meter',
        key: 'meterLevel',
        sortable: true,
        type: Number
      },
      {
        label: 'Model Year',
        key: 'modelYear',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Model',
        key: 'model',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'PI',
        key: 'personalIssue',
        sortable: true,
        type: Boolean
      },
      {
        label: 'Replacement Cost',
        key: 'replacementCost',
        sortable: true,
        type: Number
      },
      {
        label: 'Return Date',
        key: 'returnDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Serial No.',
        key: 'serialNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Supervisor',
        key: 'supervisor',
        sortable: true,
        type: String
      },
      {
        label: 'UofM',
        key: 'uom',
        sortable: true
      },
      {
        label: 'Kit',
        key: 'InKitType',
        type: 'InKit'
      },
      {
        label: 'Wireless',
        key: 'hasBleTag',
        type: 'BleTag'
      },
      {
        label: 'Needs Verify',
        key: 'needsVerificationScore',
        sortable: true
      },
      {
        label: 'Notes',
        key: 'notes',
        sortable: true,
        type: String
      },
      {
        label: 'Vin',
        key: 'vin',
        sortable: true,
        type: String
      },
      {
        label: 'License Plate Number',
        key: 'licensePlateNumber',
        sortable: true,
        type: String
      },
      {
        label: 'License Plate State',
        key: 'licensePlateState',
        sortable: true,
        type: String
      },
      {
        label: 'Last Purchase Cost',
        key: 'lastPurchaseCost',
        sortable: true,
        type: Number
      }
    ],
    mobile: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: '',
        key: 'InKitType',
        type: 'InKit'
      },
      {
        label: 'Info',
        data: [
          {
            label: '',
            key: 'hasBleTag',
            type: 'BleTag'
          },
          {
            label: 'Category',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Number',
            key: 'itemNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Bar Code',
            key: 'barcode',
            sortable: true,
            type: String
          },
          {
            label: 'Serial Number',
            key: 'serialNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Kit',
            key: 'kitNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Type',
            key: 'itemType',
            sortable: true,
            type: String
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Manufacturer',
            key: 'manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Model',
            key: 'model',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Qty',
            key: 'quantity',
            sortable: true,
            isMaterial: true,
            type: Number
          },
          {
            label: 'Allocated',
            key: 'pickedQuantity',
            sortable: true,
            isMaterial: true,
            type: Number,
            flag: 'isShowPickedItemState'
          },
          {
            label: 'Available',
            key: 'available',
            sortable: true,
            isMaterial: true,
            type: Number,
            flag: 'isShowPickedItemState'
          },
          {
            label: 'Uom',
            key: 'uom',
            sortable: true
          },
          {
            label: 'Status',
            key: 'itemStatus',
            sortable: true,
            type: String
          },
          {
            label: 'PI',
            key: 'personalIssue',
            sortable: true,
            type: Boolean
          },
          {
            label: 'Assignment',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'Bin',
            key: 'binId',
            sortable: true
          },
          {
            label: 'Responsible Party',
            key: 'responsibleParty',
            sortable: 'true'
          },
          {
            label: 'Age',
            key: 'ageDays',
            sortable: true,
            type: 'age',
            sorted: true,
            asc: true
          },
          {
            label: 'Last Scanned',
            key: 'lastScannedOn',
            sortable: true,
            type: Date
          },
          {
            label: 'Last Wireless Scan Location',
            key: 'lastScanLocation',
            type: 'LastScanLocation',
            sortable: true
          },
          {
            label: 'Last Wireless Scan Area',
            key: 'lastScanArea',
            sortable: true
          },
          {
            label: 'GPS Position',
            key: 'gpsPosition',
            sortable: true
          },
          {
            label: 'Last Counted',
            key: 'lastCountDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Tran. No.',
            key: 'lastTransferNumber',
            sortable: true
          },
          {
            label: 'Tran. Date',
            key: 'lastTransferDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Returning',
            key: 'returnDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Owner',
            key: 'owner',
            sortable: true,
            type: String
          },
          {
            label: 'Manager',
            key: 'manager',
            sortable: true,
            type: String
          },
          {
            label: 'Department',
            key: 'department',
            sortable: true,
            type: String
          },
          {
            label: 'Supervisor',
            key: 'supervisor',
            sortable: true,
            type: String
          },
          {
            label: 'Delivered By',
            key: 'lastTransferDeliveredBy',
            sortable: true
          },
          {
            label: 'Item Class',
            key: 'className',
            sortable: true,
            type: String
          },
          {
            label: 'Needs Verify',
            key: 'needsVerificationScore',
            sortable: true
          },
          {
            label: 'Notes',
            key: 'notes',
            sortable: true,
            type: String
          },
          {
            label: 'Last Purchase Cost',
            key: 'lastPurchaseCost',
            sortable: true,
            type: Number
          }
        ]
      }
    ]
  }
}

export default expandedToolItems

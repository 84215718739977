const pickLists = {
  tableHeader: {
    desktop: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Solicitado',
        key: 'RequiredQty',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Elejido',
        key: 'PickQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'Fabricante',
        key: 'Manuf',
        type: String,
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'Model',
        type: String,
        sortable: true
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Genérico',
        key: 'IsGeneric',
        isEditable: true,
        type: Boolean,
        sortable: true
      },
      {
        label: 'Elejido desde',
        key: 'PickFromId',
        type: 'Guid',
        listType: 10,
        sortable: true
      },
      {
        label: 'Compartimiento',
        key: 'Bin'
      },
      {
        label: 'Código de costo',
        key: 'CostCode'
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Solicitado',
            key: 'RequiredQty',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Elejido',
            key: 'PickQty',
            type: Number,
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'Description',
            type: String,
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'Manuf',
            type: String,
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'Model',
            type: String,
            sortable: true
          },
          {
            label: 'Genérico',
            key: 'IsGeneric',
            isEditable: true,
            type: Boolean,
            sortable: true
          },
          {
            label: 'Elejido desde',
            key: 'PickFromId',
            type: 'Guid',
            listType: 10,
            sortable: true
          },
          {
            label: 'Compartimiento',
            key: 'Bin'
          },
          {
            label: 'Código de costo',
            key: 'CostCode'
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  },
  tableHeaderWithFlag: {
    desktop: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Solicitado',
        key: 'RequiredQty',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Elejido',
        key: 'PickQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'Fabricante',
        key: 'Manuf',
        type: String,
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'Model',
        type: String,
        sortable: true
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Genérico',
        key: 'IsGeneric',
        isEditable: true,
        type: Boolean,
        sortable: true
      },
      {
        label: 'Elejido desde',
        key: 'PickFromId',
        type: 'Guid',
        listType: 10,
        sortable: true
      },
      {
        label: 'Compartimiento',
        key: 'Bin'
      },
      {
        label: 'Código de costo',
        key: 'CostCode'
      },
      {
        label: 'Modelo cantidad',
        key: 'SumOfModelQty',
        sortable: true
      },
      {
        label: 'Modelo Cantidad disponible',
        key: 'SumOfModelAvailableQty',
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Solicitado',
            key: 'RequiredQty',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Elejido',
            key: 'PickQty',
            type: Number,
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'Description',
            type: String,
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'Manuf',
            type: String,
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'Model',
            type: String,
            sortable: true
          },
          {
            label: 'Genérico',
            key: 'IsGeneric',
            isEditable: true,
            type: Boolean,
            sortable: true
          },
          {
            label: 'Elejido desde',
            key: 'PickFromId',
            type: 'Guid',
            listType: 10,
            sortable: true
          },
          {
            label: 'Compartimiento',
            key: 'Bin'
          },
          {
            label: 'Código de costo',
            key: 'CostCode'
          },
          {
            label: 'Modelo cantidad',
            key: 'SumOfModelQty',
            sortable: true
          },
          {
            label: 'Modelo Cantidad disponible',
            key: 'SumOfModelAvailableQty',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default pickLists

const workRequiredTools = {
  tableHeader: {
    desktop: [
      {
        label: 'Used',
        type: Boolean,
        key: 'isUsed',
        id: 'workOrderRequiredPartId',
        isEditable: true
      },
      {
        label: 'Est. Qty',
        key: 'estimatedQty',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Act. Qty',
        key: 'actualQty',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'No.',
        key: 'masterNumber',
        sortable: true
      },
      {
        label: 'Description',
        key: 'masterDescription',
        sortable: true
      },
      {
        label: 'Model',
        key: 'model',
        sortable: true
      },
      {
        label: 'Cost Center',
        key: 'costCenter',
        sortable: true
      },
      {
        label: 'Cost Code',
        key: 'costCode',
        sortable: true
      },
      {
        label: 'Service Class',
        key: 'serviceClass',
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Used',
            type: Boolean,
            key: 'isUsed',
            id: 'workOrderRequiredPartId',
            isEditable: true
          },
          {
            label: 'Est. Qty',
            key: 'estimatedQty',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Act. Qty',
            key: 'actualQty',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'No.',
            key: 'masterNumber',
            sortable: true
          },
          {
            label: 'Description',
            key: 'masterDescription',
            sortable: true
          },
          {
            label: 'Model',
            key: 'model',
            sortable: true
          },
          {
            label: 'Cost Center',
            key: 'costCenter',
            sortable: true
          },
          {
            label: 'Cost Code',
            key: 'costCode',
            sortable: true
          },
          {
            label: 'Service Class',
            key: 'serviceClass',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default workRequiredTools

const toolCategoryItems = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: '',
        key: 'itemType',
        type: 'InKit'
      },
      {
        label: 'Category',
        key: 'category',
        sortable: true,
        type: String
      },
      {
        label: 'Number',
        key: 'itemNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Bar Code',
        key: 'barcode',
        sortable: true,
        type: String
      },
      {
        label: 'Serial Number',
        key: 'serialNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Kit',
        key: 'kitNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Type',
        key: 'itemType',
        sortable: true,
        type: String
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Manufacturer',
        key: 'manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Meter',
        key: 'meterLevel',
        sortable: true,
        type: Number
      },
      {
        label: 'Model',
        key: 'model',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Model Year',
        key: 'modelYear',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Qty',
        key: 'quantity',
        sortable: true,
        isMaterial: true,
        type: Number
      },
      {
        label: 'Allocated',
        key: 'pickedQuantity',
        sortable: true,
        isMaterial: true,
        type: Number,
        flag: 'isShowPickedItemState'
      },
      {
        label: 'Available',
        key: 'available',
        sortable: true,
        isMaterial: true,
        type: Number,
        flag: 'isShowPickedItemState'
      },
      {
        label: 'Status',
        key: 'itemStatus',
        sortable: true,
        type: String
      },
      {
        label: 'PI',
        key: 'personalIssue',
        sortable: true,
        type: Boolean
      },
      {
        label: 'Assignment',
        key: 'assignedTo',
        sortable: true,
        type: String
      },
      {
        label: 'Age',
        key: 'ageDays',
        sortable: true,
        type: 'age',
        sorted: true,
        asc: true
      },
      {
        label: 'Owner',
        key: 'owner',
        sortable: true,
        type: String
      },
      {
        label: 'Manager',
        key: 'manager',
        sortable: true,
        type: String
      },
      {
        label: 'Department',
        key: 'department',
        sortable: true,
        type: String
      },
      {
        label: 'Supervisor',
        key: 'supervisor',
        sortable: true,
        type: String
      },
      {
        label: 'Item Class',
        key: 'className',
        sortable: true,
        type: String
      },
      {
        label: 'Notes',
        key: 'notes',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: '',
        key: 'itemType',
        type: 'InKit'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Number',
            key: 'itemNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Bar Code',
            key: 'barcode',
            sortable: true,
            type: String
          },
          {
            label: 'Serial Number',
            key: 'serialNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Kit',
            key: 'kitNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'T',
            key: 'itemType',
            sortable: true,
            type: String
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Manufacturer',
            key: 'manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Model',
            key: 'model',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Qty',
            key: 'quantity',
            sortable: true,
            isMaterial: true,
            type: Number
          },
          {
            label: 'Allocated',
            key: 'pickedQuantity',
            sortable: true,
            isMaterial: true,
            type: Number,
            flag: 'isShowPickedItemState'
          },
          {
            label: 'Available',
            key: 'available',
            sortable: true,
            isMaterial: true,
            type: Number,
            flag: 'isShowPickedItemState'
          },
          {
            label: 'Status',
            key: 'itemStatus',
            sortable: true,
            type: String
          },
          {
            label: 'PI',
            key: 'personalIssue',
            sortable: true,
            type: Boolean
          },
          {
            label: 'Assignment',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'Age',
            key: 'ageDays',
            sortable: true,
            type: 'age',
            sorted: true,
            asc: true
          },
          {
            label: 'Owner',
            key: 'owner',
            sortable: true,
            type: String
          },
          {
            label: 'Manager',
            key: 'manager',
            sortable: true,
            type: String
          },
          {
            label: 'Department',
            key: 'department',
            sortable: true,
            type: String
          },
          {
            label: 'Supervisor',
            key: 'supervisor',
            sortable: true,
            type: String
          },
          {
            label: 'Item Class',
            key: 'className',
            sortable: true,
            type: String
          },
          {
            label: 'Notes',
            key: 'notes',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  }
}

export default toolCategoryItems

const employeeCert = {
  tableHeader: {
    desktop: [
      {
        label: 'Id',
        key: 'CertificationId',
        type: 'check'
      },
      {
        label: 'Cert#',
        key: 'CertificationNumber',
        isEditable: true,
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Certified On',
        type: Date,
        key: 'CertifiedOn',
        isEditable: true,
        sortable: true
      },
      {
        label: 'Expired On',
        type: Date,
        key: 'ExpiresOn',
        isEditable: true,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'CertificationId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Cert#',
            key: 'CertificationNumber',
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Certified On',
            key: 'CertifiedOn',
            sortable: true
          },
          {
            label: 'Expired On',
            key: 'ExpiresOn',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default employeeCert

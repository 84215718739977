import axios from 'axios'
import { errorHandler, deepCopy, getFormatedDateString, getFormattedTimeString, setPickHeader, uniq } from '../helpers/utils'

export default {
  state: () => ({
    picks: [],
    pickTicketHeader: {},
    pickTicketNumber: null,
    pickTicketSearchParam: {
      stateId: 1,
      pickTicketStatus: null,
      pickForId: null,
      assignedToId: null,
      dateCreatedStart: `${getFormatedDateString(new Date(), 0)}T00:00:00`,
      dateCreatedEnd: null,
      numberFrom: null,
      numberTo: null,
      pickFromId: null,
      requestedById: null
    },
    pickStatuses: []
  }),
  mutations: {
    addPick (state, item) {
      const array = [...state.picks, ...item]
      state.picks = uniq(array, x => x.LineId)
    },
    resetPick (state) {
      state.picks = []
    },
    setPickTicketHeader (state, header) {
      state.pickTicketHeader = { ...header }
    },
    resetPickHeader (state) {
      state.pickTicketHeader = {}
    },
    removePickTicketItem (state, id) {
      state.picks = deepCopy(state.picks.filter(ele => ele.LineId != id))
    },
    setPickTicketSearchParam (state, value) {
      state.pickTicketSearchParam = value
    },
    setPickStatuses (state, value) {
      state.pickStatuses = value
    }
  },
  actions: {
    async getPickHeader (context) {
      const paramsNextNumber = JSON.stringify({
        numberType: JSON.stringify(19), // Pick tickets
        requestedNumberSource: JSON.stringify(1) // System
      })
      try {
        const { data } = await axios.post('/api/core/NextNumberService/GetNextNumber', paramsNextNumber)
        context.commit('setPickTicketHeader', {
          ...setPickHeader(data.Body.Number)
        })
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    async statusToDraft (context, pickTicketId) {
      const params = {
        orgId: `"${localStorage.getItem('OrgId')}"`,
        pickTicketId: `"${pickTicketId}"`
      }
      try {
        await axios.post('/api/core/PickTicketsService/ChangePickTicketStatusToDraftFromReadyToPick', params)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    }
  },
  getters: {
    picks (state) {
      return state.picks
    },
    pickTicketHeader (state) {
      return state.pickTicketHeader
    },
    getPickTicketSearchParam (state) {
      return state.pickTicketSearchParam
    },
    pickStatuses (state) {
      return state.pickStatuses
    }
  }
}

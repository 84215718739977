const costCodes = {
  tableHeader: {
    desktop: [
      {
        label: 'control'
      },
      {
        label: 'No.'
      },
      {
        label: 'Descripción'
      },
      {
        label: 'Centro de costos'
      },
      {
        label: 'Grupo de códigos'
      },
      {
        label: ''
      }
    ],
    mobile: [
      {
        label: 'control'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.'
          },
          {
            label: 'Descripción'
          },
          {
            label: 'Centro de costos'
          },
          {
            label: 'Grupo de códigos'
          }
        ]
      },
      {
        label: ''
      }
    ]
  }
}

export default costCodes

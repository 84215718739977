const invoiceBrowserTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Number',
        key: 'InvoiceNumber',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Date',
        key: 'InvoiceDate',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Due',
        key: 'PaymentDue',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Charge Type',
        key: 'RateSheetEntryType',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Type',
        key: 'PostingType',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Include Loss Charges',
        key: 'IsRetiredIncluded',
        type: Boolean,
        sortable: 'true'
      },
      {
        label: 'Billing Period Start',
        key: 'BillingPeriodStart',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Billing Period End',
        key: 'BillingPeriodEnd',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Terms',
        key: 'Terms',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Sales Tax',
        key: 'SalesTax',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Cost Center',
        key: 'CostCenter',
        type: String,
        sortable: 'true'
      },
      {
        label: 'CreatedBy',
        key: 'CreatedBy',
        type: String,
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: 'Number',
        key: 'InvoiceNumber',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Date',
            key: 'InvoiceDate',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Due',
            key: 'PaymentDue',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Charge Type',
            key: 'RateSheetEntryType',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Type',
            key: 'PostingType',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Include Loss Charges',
            key: 'IsRetiredIncluded',
            type: Boolean,
            sortable: 'true'
          },
          {
            label: 'Billing Period Start',
            key: 'BillingPeriodStart',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Billing Period End',
            key: 'BillingPeriodEnd',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Terms',
            key: 'Terms',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Sales Tax',
            key: 'SalesTax',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Cost Center',
            key: 'CostCenter',
            type: String,
            sortable: 'true'
          },
          {
            label: 'CreatedBy',
            key: 'CreatedBy',
            type: String,
            sortable: 'true'
          }
        ]
      }
    ]
  }
}

export default invoiceBrowserTable

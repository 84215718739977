const safetyPlanScanDetail = {
  title: 'Detalle de inspección de activos',
  deleteConfirmation: '¿Está seguro de que desea eliminar los requisitos de escaneo?',
  btnAddSafetyScan: 'Agregar inspección',
  labels: {
    1: 'Descripción de la inspección',
    2: 'Tipo de activo',
    3: 'Lista de verificación',
    4: 'Usuarios asignados',
    5: 'Instrucciones para la presentación de informes'
  },
  safetyScantableHeader: {
    desktop: [
      {
        label: '',
        key: 'SafetyplanScanId',
        type: 'check'
      },
      {
        label: 'Descripción',
        key: 'InspectionDescription',
        sortable: true
      },
      {
        label: 'Tipo de activo',
        key: 'SafetyScanAssetTypeName',
        sortable: true
      },
      {
        label: 'Lista de Verificación',
        key: 'ChecklistName',
        sortable: true
      },
      {
        label: 'Frecuencia',
        key: 'PatternFrequency',
        sortable: true
      },
      {
        label: 'Fecha de inicio',
        key: 'StartDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Fecha final',
        key: 'EndDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Usuarios asignados',
        key: 'UserAssignedName',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'SafetyplanSafetyScanId',
        type: 'check'
      },
      {
        label: 'Description',
        key: 'InspectionDescription',
        sortable: true
      },
      {
        label: 'Información',
        data: [
          {
            label: 'Tipo de activo',
            key: 'SafetyScanAssetTypeName',
            sortable: true
          },
          {
            label: 'Lista de Verificación',
            key: 'ChecklistName',
            sortable: true
          },
          {
            label: 'Frecuencia',
            key: 'PatternFrequency',
            sortable: true
          },
          {
            label: 'Fecha de inicio',
            key: 'StartDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Fecha final',
            key: 'EndDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Usuarios asignados',
            key: 'ScanUserCount',
            sortable: true
          }
        ]
      }
    ]
  },
  dropdownActions: [
    {
      title: 'Editar',
      actionName: 'onEdit',
      permissions: []
    },
    {
      title: 'Borrar',
      actionName: 'onDelete',
      permissions: []
    },
    {
      title: 'Clon',
      actionName: 'onClone',
      permissions: []
    }
  ]
}
export default safetyPlanScanDetail

import { deepCopy, getFormatedDateString, uniq } from '../helpers/utils'

export default {
  state: () => ({
    fuelCardsItemDetails: {},
    fuelCardsPopupDetails: {}
  }),
  mutations: {
    addItem (state, item) {
      state.fuelCardsItemDetails = item
    },
    addFuelCardPopupDetail (state, item) {
      state.fuelCardsPopupDetails = item
    },
    resetFuelCardItem (state) {
      state.fuelCardsItemDetails = {}
      state.fuelCardsPopupDetails = {}
    }
  },
  getters: {
    fuelCardItemDetails (state) {
      return state.fuelCardsItemDetails
    },
    fuelCardPopupItemDetails (state) {
      return state.fuelCardsPopupDetails
    }
  }
}

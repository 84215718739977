const serviceClasses = {
  tableHeader: {
    desktop: [
      {
        label: 'control'
      },
      {
        label: 'No.'
      },
      {
        label: 'Nombre'
      },
      {
        label: ''
      }
    ],
    mobile: [
      {
        label: 'control'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.'
          },
          {
            label: 'Nombre'
          }
        ]
      },
      {
        label: ''
      }
    ]
  }
}

export default serviceClasses

const listVisibility = {
  title: 'Visibilidad de la lista',
  stockLocationsLabel: 'Ubicación de las existencias para darles visibilidad',
  listsLabel: 'Selección de la lista',
  allStockLocations: '... Establecer la visibilidad global (todas las ubicaciones del stock)',
  allLists: '... Establecer todas las listas de forma global',
  setAllVisible: 'Hacer visibles todas las listas en los lugares seleccionados',
  cancelChangesBtn: 'Cancelar',
  getListItemsTitle: 'Obtención de elementos de la lista y visibilidad',
  getCountsTitle: 'Conteo de elementos de la lista para actualizar elementos a actualizar',
  confirmChangesTitle: 'Cambios en la visibilidad de la lista',
  confirmChangesMsgPrefix: 'Los cambios actualizarán la visibilidad de ',
  confirmChangesMsgSuffix: ' elementos de la lista. ¿Estás seguro de que quieres guardar?',
  confirmCancelTitle: 'Cancelar cambios',
  confirmCancelMessage: 'Los cambios realizados no se guardarán. ¿Estás seguro de que quieres cancelar?',
  setVisibilityTitle: 'Actualización de la visibilidad de la lista',

  categoriesAll: 'Categorías (Todas)',
  categoriesMaterials: 'Categorías (Material)',
  categoriesTemplates: 'Categorías (Plantilla)',
  categoriesTools: 'Categorías (Herramienta)',
  certifications: 'Certificaciones',
  classes: 'Clases',
  contractors: 'Contratistas',
  customers: 'Clientes',
  departments: 'Departamentos',
  employeesAll: 'Empleados (Todas)',
  employeesActive: 'Empleados (Activo)',
  employeesInactive: 'Empleados (Inactivo)',
  employeeTypes: 'Tipos de empleados',
  locationsAll: 'Ubicaciones (Todas)',
  locationsActive: 'Ubicaciones (Activo)',
  locationsInactive: 'Ubicaciones (Inactivo)',
  manufacturers: 'Fabricantes',
  shippingMethods: 'Métodos de envío',
  statusCodes: 'Códigos de estado',
  uom: 'Unidades de medida',
  vendors: 'Vendedores',
  costCentersAll: 'Centros de costos (Todas)',
  costCentersActive: 'Centros de costos (Activo)',
  costCentersInactive: 'Centros de costos (Inactivo)',
  costCodesAll: 'Códigos de costos (Todas)',
  costCodesActive: 'Códigos de costos (Activo)',
  costCodesInactive: 'Códigos de costos (Inactivo)',
  serviceClasses: 'Clase de servicios'
}

export default listVisibility

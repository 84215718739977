const toolLists = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'assetHash',
        type: 'check'
      },
      {
        label: 'Bulk Distribution',
        key: 'bulkDistribution',
        sortable: true,
        isQuantity: true
      },
      {
        label: 'Qty',
        key: 'quantity',
        sortable: true,
        isQuantity: true
      },
      {
        label: 'Item ID',
        key: 'number',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Barcode',
        key: 'barcode',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Serial Number',
        key: 'serial',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Status',
        key: 'status',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Assigned To',
        key: 'assignedTo',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Owner',
        key: 'owner',
        sortable: true
      },
      {
        label: 'Manager',
        key: 'manager',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'assetHash',
        type: 'check'
      },
      {
        label: 'Bulk Distribution',
        key: 'bulkDistribution',
        sortable: true,
        isQuantity: true
      },
      {
        label: 'Item ID',
        key: 'number',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Qty',
            key: 'quantity',
            sortable: true,
            isQuantity: true
          },
          {
            label: 'Barcode',
            key: 'barcode',
            sortable: true,
            isQuantity: false
          },
          {
            label: 'Serial Number',
            key: 'serial',
            sortable: true,
            isQuantity: false
          },
          {
            label: 'Status',
            key: 'status',
            sortable: true,
            isQuantity: false
          },
          {
            label: 'Assigned To',
            key: 'assignedTo',
            sortable: true,
            isQuantity: false
          },
          {
            label: 'Owner',
            key: 'owner',
            sortable: true,
            isQuantity: false
          },
          {
            label: 'Manager',
            key: 'manager',
            sortable: true,
            isQuantity: false
          }
        ]
      }
    ]
  }
}

export default toolLists

const departments = {
  tableHeader: {
    desktop: [
      {
        label: 'check',
        key: 'departmentId',
        isModify: true
      },
      {
        label: 'No.',
        key: 'departmentNumber',
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true
      },
      {
        label: 'Phone',
        key: 'phone',
        sortable: true,
        isModify: false
      },
      {
        label: 'Fax',
        key: 'fax',
        sortable: true,
        isModify: false
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'departmentId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.',
            key: 'departmentNumber',
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'Phone',
            key: 'phone',
            sortable: true,
            isModify: false
          },
          {
            label: 'Fax',
            key: 'fax',
            sortable: true,
            isModify: false
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default departments

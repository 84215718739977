const templates = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'index',
        sortable: true
      },
      {
        label: 'Nombre',
        key: 'Description',
        sortable: true
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'control',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: '',
            key: 'index',
            sortable: true
          },
          {
            label: 'Nombre',
            key: 'Description',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default templates

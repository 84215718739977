const retireTools = {
  title: 'Herramientas Retiradas',
  warningMsg: [
    'Esta a punto de retirar las Herramientas. Cuando una herramienta es retirada, es removida del servicio y puesta en el listado de herramientas retiradas. Si desea, la herramiena puede ser reintegrada y ser puesta en servicio.',
    'La herramienta no aparecera mas en el buscador de herramienta o en reportes de servicios. El centro de costo y el código de costo tienen como valor predeterminado la última transferencia para las herramientas de cantidad. Revise y actualice para obtener datos precisos.',
    'Si la herramienta es encontrada en una transferencia o scaneada, la herramienta puede que sea reintegrada a servicio normal'
  ],
  labels: {
    0: 'Fecha de retiro:',
    1: 'Retirado en:',
    2: 'Personal responsable:',
    3: '*Razon:',
    4: 'Esta a punto de retirar 1 herramienta(s). El centro de costo y el código de costo tienen como valor predeterminado la última transferencia para las herramientas de cantidad. Revise y actualice para obtener datos precisos. Desea Continuar?',
    5: 'Retiro completado. 1 de 1 Herramientas retiradas.',
    6: 'Estado'
  },
  tableHeader: {
    desktop: [
      {
        label: 'Número',
        key: 'itemNumber',
        sortable: true
      },
      {
        label: 'Tipo',
        key: 'itemType',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true
      },
      {
        label: 'Cant.',
        key: 'quantity',
        type: Number,
        sortable: true,
        isEditable: true
      },
      {
        label: 'Retirado',
        key: 'assignedToId',
        type: 'Guid',
        listTypeId: 10,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Personal responsable',
        key: 'responsiblePartyId',
        type: 'Guid',
        listTypeId: 7,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Centro de costos',
        key: 'costCenterId',
        type: 'Guid',
        listTypeId: 3,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Código de costo',
        key: 'costCodeId',
        type: 'Guid',
        listTypeId: 4,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Razón',
        key: 'reason',
        type: 'Guid',
        listTypeId: 40,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Estado',
        key: 'itemStatusId',
        type: 'Guid',
        listTypeId: 15,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Notas',
        key: 'notes',
        isEditable: true,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Estado',
        key: 'Status',
        type: String
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Número',
            key: 'itemNumber',
            sortable: true
          },
          {
            label: 'Tipo',
            key: 'itemType',
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true
          },
          {
            label: 'Cant.',
            key: 'quantity',
            type: Number,
            sortable: true,
            isEditable: true
          },
          {
            label: 'Retirado',
            key: 'assignedToId',
            type: 'Guid',
            listTypeId: 10,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Personal responsable',
            key: 'responsiblePartyId',
            type: 'Guid',
            listTypeId: 7,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Centro de costos',
            key: 'costCenterId',
            type: 'Guid',
            listTypeId: 3,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Código de costo',
            key: 'costCodeId',
            type: 'Guid',
            listTypeId: 4,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Razón',
            key: 'reason',
            type: 'Guid',
            listTypeId: 40,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Estado',
            key: 'itemStatusId',
            type: 'Guid',
            listTypeId: 15,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Notas',
            key: 'notes',
            isEditable: true,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default retireTools

const companyCalendar = {
  modal: {
    title: 'Establecer horas de trabajo'
  },
  labels: {
    0: 'Es día laboral',
    1: 'Inicio',
    2: 'Fin',
    3: 'Notas',
    4: 'Horario de trabajo predeterminado',
    5: 'No es día laboral',
    6: 'Manejar proyectos predeterminados...',
    7: 'Horas de trabajos predeterminadas',
    8: 'Día',
    9: 'Semana',
    10: 'Mes',
    11: 'Es dia festivo',
    12: 'Horas de trabajo modificadas',
    13: 'Días no laborales',
    14: 'a',
    15: 'Hoy'
  }
}

export default companyCalendar

const routes = [
  {
    name: 'Dashboards',
    route_name: 'Dashboard',
    icon: 'fa-light fa-chart-line-up',
    path: '',
    matchPath: ['/', '/tickets/dashboard', '/purchasing/dashboard', '/service/dashboard', '/reports/dashboards'],
    exact: true,
    completed: true,
    released: true,
    wareHouseUser: true,
    backOfficeUser: true,
    jobSiteUser: true
  },
  {
    name: 'Inventory',
    icon: 'fa-light fa-screwdriver-wrench',
    matchPath: [],
    completed: true,
    released: true,
    jobSiteUser: true,
    backOfficeUser: true,
    children: [
      {
        name: 'Catalog',
        matchPath: ['/catalog/'],
        exact: true,
        guard: ['ToolModelView', 'MaterialModelView'],
        entitlement: 'T_And_E',
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true,
        path: 'catalog'
      },
      {
        name: 'Item Browser',
        matchPath: ['/items/', '/items?type'],
        guard: ['ToolDetailView', 'MaterialDetailView'],
        entitlement: 'T_And_E',
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true,
        path: 'items'
      },
      {
        name: 'Low Level Browser',
        matchPath: ['low-level'],
        guard: ['LowLevelBrowserView'],
        entitlement: 'M_And_C',
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true,
        path: 'low-level'
      },
      {
        name: 'Job Stock',
        matchPath: ['job-stock'],
        guard: ['JobStockView'],
        entitlement: 'ToolsJobStock',
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true,
        path: 'job-stock'
      },
      {
        name: 'Manage Retired Tools',
        matchPath: ['retired-tools'],
        guard: ['ManageRetiredToolsView'],
        entitlement: 'T_And_E',
        completed: true,
        released: true,
        jobSiteUser: false,
        wareHouseUser: true,
        backOfficeUser: false,
        path: 'retired-tools'
      }
    ]
  },
  {
    name: 'Inventory Movement',
    icon: 'fa-light fa-arrow-right-arrow-left',
    matchPath: [],
    completed: true,
    released: false,
    wareHouseUser: true,
    children: [
      {
        name: 'Transfer Tickets',
        path: '/tickets/transfers',
        guard: ['TransfersView'],
        matchPath: ['/tickets/transfers'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Pick Tickets',
        path: '/tickets/picks',
        matchPath: ['/tickets/picks'],
        guard: ['PickTicketView'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Requisition Browser',
        path: 'requisition-browser',
        matchPath: ['requisition-browser'],
        guard: ['RequisitionBrowserView'],
        entitlement: 'ToolsRequisitionBrowser',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Count Tickets',
        path: '/tickets/count-inventory',
        guard: ['CountInventoryView'],
        matchPath: ['/tickets/count-inventory'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Templates',
        path: '/tickets/templates',
        matchPath: ['/tickets/templates'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Requisitions Home',
        path: '/requisitions',
        exact: true,
        matchPath: ['/requisitions/'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        jobSiteUser: true
      },
      {
        name: 'Request History',
        params: 'items',
        path: '/requisitions/requests/items',
        matchPath: ['requisitions/requests'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        jobSiteUser: true
      },
      {
        name: 'Returns Home',
        path: '/returns',
        exact: true,
        matchPath: ['/returns/'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        coming: false,
        jobSiteUser: true
      },
      {
        name: 'Returns History',
        path: '/returns/history',
        matchPath: ['returns/history'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        coming: false,
        jobSiteUser: true
      }
    ]
  },
  {
    name: 'Service and Calibration',
    icon: 'fa-light fa-list-check',
    matchPath: [],
    completed: true,
    released: false,
    wareHouseUser: true,
    children: [
      {
        name: 'Service Requests',
        path: '/service/requests',
        matchPath: ['/service/requests', '/service/request'],
        guard: ['ServiceBrowserView'],
        entitlement: 'S_And_C',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Work Orders',
        path: '/service/work-orders',
        matchPath: ['/service/work-orders'],
        guard: ['ServiceBrowserView'],
        entitlement: 'S_And_C',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Browse Service Schedule',
        path: '/service/schedule',
        matchPath: ['/service/schedule', '/service/service-schedule'],
        guard: ['ServiceScheduleView'],
        entitlement: 'S_And_C',
        completed: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Equipment Management',
    id: 'EquipmentManagement',
    icon: 'equipment-management.png',
    customIcon: true,
    matchPath: [],
    completed: true,
    released: false,
    wareHouseUser: true,
    children: [
      {
        name: 'Equipment Browser',
        path: '/equipments',
        matchPath: ['/equipments', '/equipments?type'],
        guard: [],
        entitlement: '',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Scheduling',
        matchPath: [],
        completed: true,
        released: true,
        children: [
          {
            name: 'Scheduling Request',
            route_name: 'Equipment Scheduling Request',
            path: '/equipment/scheduling/schedulingrequest',
            matchPath: ['equipment/scheduling/schedulingrequest'],
            guard: ['SchedulingRequestView'],
            entitlement: 'Scheduling',
            completed: true,
            released: true
          },
          {
            name: 'Scheduling Browser',
            route_name: 'Equipment Scheduling Browser',
            path: '/equipment/scheduling/schedulingbrowser',
            matchPath: ['equipment/scheduling/schedulingbrowser'],
            guard: ['SchedulingBrowserView'],
            entitlement: 'Scheduling',
            completed: true,
            released: true
          },
          {
            name: 'Scheduling Calendar',
            route_name: 'Equipment Scheduling Calendar',
            path: '/equipment/scheduling/schedulingcalendar',
            matchPath: ['equipment/scheduling/schedulingcalendar'],
            guard: ['SchedulingCalendarView'],
            entitlement: 'Scheduling',
            completed: true,
            released: true
          }
        ]
      },
      {
        name: 'Equipment Usage Log',
        route_name: 'EquipmentUsageLog',
        path: '/equipment/usagelog',
        matchPath: ['/equipment/usagelog'],
        guard: [],
        entitlement: '',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Equipment Service Request',
        path: '/equipment/service-requests',
        matchPath: ['/equipment/service-requests'],
        guard: [],
        entitlement: '',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Equipment Work Order Browser',
        path: '/equipment/work-orders',
        matchPath: ['/equipment/work-orders'],
        guard: [],
        entitlement: '',
        completed: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Purchasing',
    icon: 'fa-light fa-cart-shopping',
    matchPath: [],
    completed: true,
    released: false,
    wareHouseUser: true,
    children: [
      {
        name: 'Purchase Orders',
        route_name: 'PurchaseOrders',
        path: '/purchasing/purchase-orders',
        matchPath: ['purchasing/purchase-orders'],
        guard: ['PurchaseOrdersView'],
        entitlement: 'P_And_R',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Receiving Tickets',
        path: '/purchasing/receiving',
        matchPath: ['purchasing/receiving'],
        guard: ['ReceivingTicketsView'],
        entitlement: 'P_And_R',
        completed: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Job Cost and Billing',
    icon: 'fa-light fa-calculator',
    matchPath: [],
    completed: true,
    released: true,
    jobSiteUser: true,
    backOfficeUser: true,
    children: [
      {
        name: 'Billing Browser',
        path: '/billing',
        guard: ['BillingBrowserView'],
        entitlement: 'JC_And_B',
        exact: true,
        matchPath: ['/billing/'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Invoice Browser',
        path: '/billing/invoice-browser',
        entitlement: 'JC_And_B',
        matchPath: ['invoice-browser'],
        guard: ['BillingBrowserView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Fuel Transaction Browser',
        path: '/fleet/fuel-transaction-browser',
        entitlement: 'JC_And_B',
        matchPath: ['fuel-transaction-browser'],
        guard: ['FuelCardsView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Rate Sheets',
        path: '/billing/ratesheets',
        guard: ['RateSheetsView'],
        entitlement: 'JC_And_B',
        matchPath: ['rateSheets'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Company Calendar',
        path: '/administration/company-calendar',
        guard: ['CompanyCalendarView'],
        matchPath: ['company-calendar'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Equipment Usage Log',
        path: '/billing/equipment',
        guard: ['HourlyUsageLogView'],
        entitlement: 'JC_And_B',
        matchPath: ['/billing/equipment'],
        completed: true,
        released: true,
        backOfficeUser: true,
        jobSiteUser: true
      },
      {
        name: 'Export Templates',
        path: '/billing/export-templates',
        guard: ['ExportJobCost&BillingExecute'],
        entitlement: 'JC_And_B',
        matchPath: ['export-templates'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Export Data',
        guard: ['ExportJobCost&BillingExecute'],
        entitlement: 'JC_And_B',
        path: '/billing/export-data',
        matchPath: ['export-data'],
        completed: true,
        released: true,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Scheduling',
    icon: 'fal fa-calendar-alt',
    matchPath: ['schedulingPlatformNav'],
    guard: ['SchedulingView'],
    entitlement: 'Scheduling',
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    children: [
      {
        name: 'Scheduling Home',
        path: '/reservations',
        exact: true,
        matchPath: ['/reservations/'],
        entitlement: 'Reservations',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      },
      {
        name: 'Scheduling History',
        params: 'items',
        path: '/reservations/history/items',
        matchPath: ['/reservations/history/items'],
        entitlement: 'Reservations',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      },
      {
        name: 'Scheduling Browser',
        path: '/scheduling/scheduling-browser',
        matchPath: ['/scheduling/scheduling-browser'],
        guard: ['SchedulingView'],
        entitlement: 'Scheduling',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true
      },
      {
        name: 'Scheduling Request',
        path: '/scheduling/scheduling-request',
        matchPath: ['/scheduling/scheduling-request'],
        guard: ['SchedulingView'],
        entitlement: 'Scheduling',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true
      },
      {
        name: 'Scheduling Calendar',
        path: '/scheduling/scheduling-calendar',
        matchPath: ['/scheduling/scheduling-calendar'],
        guard: ['SchedulingView'],
        entitlement: 'Scheduling',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Task Management',
    icon: 'fal fa-list-alt',
    matchPath: ['workmanagement'],
    completed: true,
    released: true,
    jobSiteUser: true,
    backOfficeUser: true,
    wareHouseUser: true,
    children: [
      {
        name: 'Task Browser',
        path: '/workflow/Tasks',
        matchPath: ['workflow/task-browser'],
        guard: ['TasksView'],
        entitlement: 'ToolsTasks',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      },
      {
        name: 'Workflow Browser',
        path: '/workflow/workflow-browser',
        matchPath: ['workflow/workflow-browser'],
        guard: ['WorkflowsView'],
        entitlement: 'ToolsWorkflows',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Active Workflows',
        path: '/workflow/active-workflow',
        matchPath: ['workflow/active-workflow'],
        guard: ['WorkflowsView'],
        entitlement: 'ToolsWorkflows',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      },
      {
        name: 'Approvals',
        path: '/workflow/workflow-approvals',
        matchPath: ['workflow/workflow-approvals'],
        guard: ['AllApprovalsView'],
        entitlement: 'ToolsApprovals',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Reports and Graphs',
    icon: 'fa-light fa-file-chart-pie',
    matchPath: [],
    completed: true,
    released: true,
    wareHouseUser: true,
    backOfficeUser: true,
    children: [
      {
        name: 'Reports',
        route_name: 'Canned Reports',
        path: '/reports',
        exact: true,
        matchPath: ['/reports/'],
        entitlement: 'Reporting',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Custom Reports',
        path: '/reports/custom',
        matchPath: ['reports/custom'],
        guard: ['CustomReportsView'],
        entitlement: 'CustomReports',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Scheduled Reports',
        path: '/reports/scheduled',
        matchPath: ['reports/scheduled'],
        guard: ['ScheduledReportsView'],
        entitlement: 'CustomReports',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Manage Dashboards',
        path: '/reports/dashboards',
        matchPath: ['/reports/dashboards'],
        entitlement: 'CustomDashboards',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Insights',
        path: '/insights',
        matchPath: ['insights'],
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Tracking',
    icon: 'fa-light fa-map-location-dot',
    matchPath: [],
    completed: true,
    released: true,
    wareHouseUser: true,
    backOfficeUser: true,
    jobSiteUser: true,
    children: [
      {
        name: 'Map',
        path: 'location-map',
        matchPath: ['location-map'],
        guard: ['LocationMapView'],
        entitlement: 'ToolsLocationsMap',
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Wireless Gateways',
        path: '/administration/ble-gateways',
        matchPath: ['administration/ble-gateways'],
        guard: ['WirelessGatewaysView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Wireless Tags',
        path: '/administration/ble-tags',
        matchPath: ['administration/ble-tags'],
        guard: ['WirelessTagsView'],
        completed: true,
        released: true,
        backOfficeUser: true
      }
      // {
      //   name: 'Telemetry',
      //   path: '#',
      //   matchPath: ['/future'],
      //   completed: true,
      //   released: true,
      //   backOfficeUser: true
      // }
    ]
  },
  {
    name: 'Administration',
    icon: 'fa-light fa-user-gear',
    matchPath: [],
    completed: true,
    released: true,
    backOfficeUser: true,
    children: [
      {
        name: 'Company Settings',
        path: '/administration/company-settings',
        guard: ['CompanyDefaultsView'],
        matchPath: ['administration/company-settings'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Configure User Roles',
        path: '/administration/configure-user-roles',
        matchPath: ['administration/configure-user-roles'],
        guard: ['RolesView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Document Central',
        path: '/administration/document-central',
        matchPath: ['administration/document-central'],
        guard: ['DocumentCentralView'],
        entitlement: 'ToolsDocumentCentral',
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Integration',
        matchPath: [],
        completed: true,
        released: true,
        backOfficeUser: true,
        children: [
          {
            name: 'Catalog',
            route_name: 'Integration Catalog',
            path: '/administration/integration/catalog',
            matchPath: ['administration/integration/catalog'],
            entitlement: 'Integration',
            completed: true,
            released: true
          },
          {
            name: 'Settings',
            route_name: 'Integration Settings',
            path: '/administration/integration/settings',
            matchPath: ['administration/integration/settings'],
            entitlement: 'Integration',
            completed: true,
            released: true
          }
        ]
      },
      {
        name: 'Data Loader',
        path: '/administration/data-loader',
        guard: ['DataLoaderView'],
        entitlement: 'DataLoader',
        matchPath: ['administration/data-loader'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Custom Fields',
        path: '/administration/custom-fields',
        guard: ['CustomFieldsView'],
        entitlement: 'CustomFields',
        matchPath: ['administration/custom-fields'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Company Lists',
        matchPath: [],
        completed: true,
        released: true,
        backOfficeUser: true,
        children: [
          {
            name: 'Certifications',
            guard: ['CertificationsView'],
            path: '/administration/lists/certifications',
            matchPath: ['certifications'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Contractors',
            guard: ['ContractorsView'],
            path: '/administration/lists/contractors',
            matchPath: ['administration/lists/contractors'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Customers',
            guard: ['CustomersView'],
            path: '/administration/lists/customers',
            matchPath: ['administration/lists/customers'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Departments',
            guard: ['DepartmentsView'],
            path: '/administration/lists/departments',
            matchPath: ['administration/lists/departments'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Descriptions',
            guard: ['DescriptionsView'],
            path: '/administration/lists/descriptions',
            matchPath: ['administration/lists/descriptions'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Employees',
            guard: ['EmployeesView'],
            path: '/administration/lists/employees',
            matchPath: ['administration/lists/employees'],
            completed: true,
            released: true
          },
          {
            name: 'Employee Types',
            guard: ['EmployeeTypesView'],
            path: '/administration/lists/employee-types',
            matchPath: ['administration/lists/employee-types'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Locations',
            guard: ['LocationsView'],
            path: '/administration/lists/locations',
            matchPath: ['administration/lists/locations'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Manufacturers',
            guard: ['ManufacturersView'],
            path: '/administration/lists/manufacturers',
            matchPath: ['administration/lists/manufacturers'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Tasks',
            matchPath: [],
            completed: true,
            released: true,
            backOfficeUser: true,
            children: [
              {
                name: 'Task Categories',
                guard: ['TasksView'],
                path: '/administration/lists/tasks-categories',
                matchPath: ['administration/lists/tasks-categories'],
                entitlement: 'ToolsTasks',
                completed: true,
                released: true
              },
              {
                name: 'Task Priorities',
                guard: ['TasksView'],
                path: '/administration/lists/tasks-priorities',
                matchPath: ['administration/lists/tasks-priorities'],
                entitlement: 'ToolsTasks',
                completed: true,
                released: true
              },
              {
                name: 'Task Statuses',
                guard: ['TasksView'],
                path: '/administration/lists/tasks-statuses',
                matchPath: ['administration/lists/tasks-statuses'],
                entitlement: 'ToolsTasks',
                completed: true,
                released: true
              }
            ]
          }
        ]
      },
      {
        name: 'Settings',
        matchPath: [],
        completed: true,
        released: true,
        backOfficeUser: true,
        children: [
          {
            name: 'Tools & Materials',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Categories',
                guard: ['CategoriesView'],
                path: '/administration/lists/categories',
                matchPath: ['administration/lists/categories'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Classes',
                guard: ['ClassesView'],
                path: '/administration/lists/classes',
                matchPath: ['administration/lists/classes'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Shipping Methods',
                guard: ['ShippingMethodsView'],
                path: '/administration/lists/shipping-methods',
                matchPath: ['administration/lists/shipping-methods'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Status Codes',
                guard: ['StatusCodesView'],
                path: '/administration/lists/status-codes',
                matchPath: ['administration/lists/status-codes'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Units of Measure',
                guard: ['UnitsofMeasureView'],
                path: '/administration/lists/units-of-measure',
                matchPath: ['administration/lists/units-of-measure'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              }
            ]
          },
          {
            name: 'Purchasing & Receiving',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Vendors',
                guard: ['VendorsView'],
                path: '/administration/lists/vendors',
                matchPath: ['administration/lists/vendors'],
                entitlement: 'P_And_R',
                completed: true,
                released: true
              }
            ]
          },
          {
            name: 'Job Cost & Billing',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Cost Centers',
                guard: ['CostCentersView'],
                path: '/administration/lists/cost-centers',
                matchPath: ['administration/lists/cost-centers'],
                entitlement: 'JC_And_B',
                completed: true,
                released: true
              },
              {
                name: 'Cost Codes',
                guard: ['CostCodesView'],
                path: '/administration/lists/cost-codes',
                matchPath: ['administration/lists/cost-codes'],
                entitlement: 'JC_And_B',
                completed: true,
                released: true
              }
            ]
          },
          {
            name: 'Service',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Service Classes',
                path: '/administration/lists/service-classes',
                matchPath: ['administration/lists/service-classes'],
                entitlement: 'S_And_C',
                completed: true,
                released: true
              }
            ]
          },
          {
            name: 'List Utilities',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Data Merge Utility',
                guard: ['DataMergeUtilityExecute'],
                path: '/administration/lists/data-merge-utility',
                matchPath: ['administration/lists/data-merge-utility'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              }
            ]
          },
          {
            name: 'Equipment',
            matchPath: [],
            completed: true,
            released: true,
            doNotCollapse: true,
            children: [
              {
                name: 'Fuel Cards',
                guard: ['FuelCardsView'],
                path: '/administration/lists/fuel-cards',
                matchPath: ['administration/lists/Fuel-cards'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Fuel Employees',
                guard: ['FuelCardsView'],
                path: '/administration/lists/fuel-employees',
                matchPath: ['administration/lists/fuel-employees'],
                entitlement: 'T_And_E',
                completed: true,
                released: true
              },
              {
                name: 'Import Meter',
                path: '/billing/import-meter',
                matchPath: ['import-meter'],
                completed: true,
                released: true,
                backOfficeUser: true
              }
            ]
          }
        ]
      },
      {
        name: 'Numbering',
        path: '/administration/numbering',
        guard: ['NumberBlocksView'],
        matchPath: ['administration/numbering'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'List Visibility',
        guard: ['ListVisibilityView'],
        path: '/administration/list-visibility',
        matchPath: ['administration/list-visibility'],
        completed: true,
        released: false,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Recycle Bin',
    icon: 'fa-light fa-recycle',
    path: 'recycle-bin',
    matchPath: ['recycle-bin'],
    guard: ['RecyclingBinView'],
    completed: true,
    released: true,
    jobSiteUser: false,
    wareHouseUser: true,
    backOfficeUser: true
  },
  {
    name: 'What’s New',
    icon: 'fa-light fa-sparkles',
    path: 'whatsnew',
    matchPath: ['whatsnew'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  },
  {
    name: 'Feedback',
    icon: 'chatbox-elipsis',
    matchPath: ['feedback'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  },
  {
    name: 'Documentation',
    icon: 'fa-light fa-file-circle-question',
    matchPath: ['Documentation'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  },
  {
    name: 'Get Support',
    icon: 'fa-light fa-circle-question',
    matchPath: ['getSupport'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  }
]

export default routes

const catalogProducts = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'MasterId',
        type: 'check'
      },
      {
        label: 'Category',
        key: 'Category',
        sortable: false
      },
      {
        label: 'T',
        key: 'ItemType',
        sortable: false
      },
      {
        label: 'Model',
        key: 'Model',
        sortable: false
      },
      {
        label: 'Manufacturer',
        key: 'Manufacturer',
        sortable: false
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: false
      },
      {
        label: 'Tracking',
        key: 'BrowserViewCount',
        sortable: false
      }
    ],
    mobile: [
      {
        label: '',
        key: 'check',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'Category',
            sortable: false
          },
          {
            label: 'T',
            key: 'ItemType',
            sortable: false
          },
          {
            label: 'Model',
            key: 'Model',
            sortable: false
          },
          {
            label: 'Manufacturer',
            key: 'Manufacturer',
            sortable: false
          },
          {
            label: 'Description',
            key: 'Description',
            sortable: false
          },
          {
            label: 'Tracking',
            key: 'BrowserViewCount',
            sortable: false
          }
        ]
      }
    ]
  }
}

export default catalogProducts

const PickedItemsList = {
  tableHeaderQuantityTool: {
    desktop: [
      {
        label: 'Número',
        key: 'PickTicketNumber',
        type: Number,
        sortable: true
      },
      {
        label: 'Creado',
        key: 'DatePicked',
        sortable: true
      },
      {
        label: 'Estado',
        key: 'State',
        sortable: true
      },
      {
        label: 'Escogido',
        key: 'Quantity',
        type: String,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Número',
            key: 'PickTicketNumber',
            type: Number,
            sortable: true
          },
          {
            label: 'Creado',
            key: 'DatePicked',
            sortable: true
          },
          {
            label: 'Estado',
            key: 'State',
            sortable: true
          },
          {
            label: 'Escogido',
            key: 'Quantity',
            type: String,
            sortable: true
          }
        ]
      }
    ]
  },
  tableHeaderUniqueTool: {
    desktop: [
      {
        label: 'Número',
        key: 'PickTicketNumber',
        type: Number,
        sortable: true
      },
      {
        label: 'Creado',
        key: 'DatePicked',
        sortable: true
      },
      {
        label: 'Estado',
        key: 'State',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Número',
            key: 'PickTicketNumber',
            type: Number,
            sortable: true
          },
          {
            label: 'Creado',
            key: 'DatePicked',
            sortable: true
          },
          {
            label: 'Estado',
            key: 'State',
            sortable: true
          }
        ]
      }
    ]
  },
  pickedItemStatsQuantityMsg: 'Esta selección tomará el inventario disponible para este artículo en negativos. ¿Está seguro de que desea continuar?',
  pickedItemStatsUniqueyMsg: 'El artículo que está seleccionando ya ha sido seleccionado en otro ticket de selección. ¿Quiere reasignar el artículo a este ticket de selección?',
  pickedItemStateTitle: 'Warning',
  pickedItemsTableHeader: 'Elija boleto(s) con artículo:',
  pickedItemStatsWarningMsg: 'Esto eliminará el artículo del ticket de selección anterior.',
  itemStatusQuantityMsg: 'La cantidad que está seleccionando hará que su inventario se convierta en negativo. ¿Está seguro de que desea continuar?'
}
export default PickedItemsList

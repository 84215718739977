import axios from 'axios'
export default {
  state: () => ({
    postRequests: []
  }),
  mutations: {
    clearExpiredRequests (state) {
      state.postRequests = state.postRequests.filter(x => x.expiration > new Date().getTime())
    },
    addRequest (state, request) {
      state.postRequests.push(request)
    }
  },
  actions: {
    getCachedPostRequest (context, request) {
      // If caller didn't provide an expiration,
      // set one using the default 10s time to live
      if (request.expiration === undefined) {
        request.expiration = new Date().getTime() + 10000
      }

      // If headerConfig wasn't passed in, set it as an empty object so we can
      // always pass it to the axios call
      if (request.headerConfig === undefined) {
        request.headerConfig = {}
      }

      // Clear any expired entries so we don't keep a giant list of stale requests
      context.commit('clearExpiredRequests')

      // If the request already exists, pass the underlying promise to the caller
      // so it can be awaited
      const result = context.getters.postRequests
        .find(x => x.url === request.url && x.paramsAsString === request.paramsAsString)
      if (result !== undefined) {
        return result.promise
      }

      // Create the promise and add the whole request to the store before returning
      // the promise to the initial caller
      const promise = axios.post(request.url, request.paramsAsString, request.headerConfig)
      request.promise = promise
      context.commit('addRequest', request)
      return promise
    }
  },
  getters: {
    postRequests: state => {
      return state.postRequests
    }
  }
}

const workRequiredTools = {
  tableHeader: {
    desktop: [
      {
        label: 'No. de modelo',
        key: 'masterNumber',
        sortable: true
      },
      {
        label: 'Cant.',
        key: 'requiredQty',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true
      },
      {
        label: 'Fabricante',
        key: 'manufacturer',
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'model',
        sortable: true
      },
      {
        label: 'Genérico',
        key: 'isGeneric',
        id: 'workOrderRequiredToolId',
        type: Boolean,
        isEditable: true,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'No. de modelo',
            key: 'masterNumber',
            sortable: true
          },
          {
            label: 'Cant.',
            key: 'requiredQty',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'manufacturer',
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'model',
            sortable: true
          },
          {
            label: 'Genérico',
            key: 'isGeneric',
            id: 'workOrderRequiredToolId',
            type: Boolean,
            isEditable: true,
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default workRequiredTools

const billing = {
  browser: {
    title: 'Billing Browser',
    costSelection: 'Cost Center Selection',
    filter: {
      0: 'All',
      1: 'Job Cost',
      2: 'Billing',
      3: 'Tools',
      4: 'Materials'
    },
    actionHeader: {
      0: {
        title: 'Calculate',
        icon: 'fas fa-calculator'
      },
      1: {
        title: 'Post',
        icon: 'fas fa-user-edit'
      },
      2: {
        title: 'Add Misc. Charge/Credit',
        icon: 'fas fa-plus'
      },
      3: {
        title: 'Delete Misc. Charge/Credit',
        icon: 'fas fa-trash'
      },
      4: {
        title: 'Refresh',
        icon: 'fas fa-sync-alt'
      }
    },
    lineAction: {
      0: {
        title: 'View Detail'
      },
      1: {
        title: 'Place Hold'
      },
      2: {
        title: 'Remove Hold'
      },
      3: {
        title: 'Create Related Charge/Credit'
      },
      4: {
        title: 'Edit Cost Code'
      }
    },
    entryType: {
      title: 'Display entries for',
      labels: {
        0: 'Tools & Equipment',
        1: 'Materials & Consumables'
      }
    },
    calculation: {
      title: 'Cost Center Calculation',
      dialog: {
        message: 'You have selected a cut-off date that is in the future. Are you sure you want to continue?'
      },
      labels: {
        0: 'Select cut-off date and time',
        1: 'Only cost centers with ratesheets assigned can be calculated.'
      }
    },
    post: {
      title: 'Posting',
      labels: {
        0: 'Select Cost Centers to post',
        1: 'Ready to Post'
      }
    },
    buttons: {
      0: 'View Detail',
      1: 'Hold',
      2: 'Remove Hold'
    },
    postAmount: {
      0: 'Billing Amount to post',
      1: 'Job Cost Amount to post'
    },
    miscCharge: {
      title: 'Misc. Charge/Credit',
      costCodeOptions: {
        0: 'Cost Center Specific',
        1: 'Global'
      },
      dialogLabels: {
        0: 'GL Charge Account',
        1: 'GL Credit Account',
        2: 'Scan or enter item number',
        3: 'Enter',
        4: 'Item Number',
        5: 'Description',
        6: 'Charge',
        7: 'Credit',
        8: 'Total',
        9: 'Misc. Charge/Credit Date',
        10: 'Billing',
        11: 'Job Costing',
        12: 'Select a Category',
        13: 'Select Item',
        14: 'Unknown Item',
        15: 'This Charge / Credit relates to transaction:',
        16: 'Item',
        17: 'Select Model',
        18: 'Apply To Max Charge'
      }
    },
    editCostCode: {
      title: 'Edit Cost Code',
      label: {
        0: 'Update transfer line on Transfer',
        1: 'Item'
      }
    },
    transactionDetail: {
      title: 'Transaction Detail',
      tags: {
        0: 'Cost Center Information',
        1: 'Item Information',
        2: 'Charges',
        3: 'Transaction Information',
        4: 'Effective Usage & Rate Information'
      },
      labels: {
        0: 'Cost Center',
        1: 'Cost Code',
        2: 'Rate Sheet Name',
        3: 'Rate Sheet Type',
        4: 'Rate Sheet ID',
        5: 'Calculated On',
        6: 'Cut Off Date',
        7: 'End Date',
        8: 'Item type',
        9: 'Category',
        10: 'Item No.',
        11: 'Description',
        12: 'Item Calculation Method',
        13: 'Charge Type',
        14: 'Qty.',
        15: 'Amount Each',
        16: 'Extended Total',
        17: 'Type',
        18: 'Start Date',
        19: 'ID',
        20: 'From',
        21: 'To',
        22: 'Effective Duration',
        23: 'Months',
        24: 'Weeks',
        25: 'Days',
        26: 'Hours',
        27: 'Potential Hours',
        28: 'Chargeable Hours',
        29: 'Item Rate Level',
        30: 'Item Effective Rate / Hr.'
      }
    }
  },
  ratesheets: {
    title: 'Rate Sheet Browser',
    filter: {
      0: 'Tools & Equipment Rates',
      1: 'Materials & Consumables Rates'
    },
    actionHeader: {
      0: {
        title: 'View Rate Sheet',
        icon: 'fas fa-user-edit'
      },
      1: {
        title: 'Add Tools & Equipment Rate Sheet',
        icon: 'fas fa-plus'
      },
      2: {
        title: 'Add Materials & Consumables Rate Sheet',
        icon: 'fas fa-plus'
      },
      3: {
        title: 'Copy Rate Sheet',
        icon: 'fas fa-copy'
      },
      4: {
        title: 'Delete',
        icon: 'fas fa-trash'
      }
    }
  },
  rateSheetView: {
    title: 'Rate Sheet',
    actionHeader: {
      0: {
        title: 'Save',
        tooltip: 'Save',
        icon: 'far fa-save',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      1: {
        title: 'Save And Close',
        tooltip: 'Save And Close',
        icon: 'far fa-save',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      2: {
        title: 'Add Related Charge',
        tooltip: 'Add Related Charge',
        icon: 'fas fa-plus',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      3: {
        title: 'Delete Related Charge',
        tooltip: 'Delete Related Charge',
        icon: 'fas fa-trash',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      4: {
        title: 'Export Data',
        tooltip: 'Export Data in CSV Format',
        icon: 'fas fa-file-export',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      5: {
        title: 'Publish to Excel',
        tooltip: 'Publish to MS Excel for Editing',
        icon: 'far fa-file-excel',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      6: {
        title: 'Import from Excel',
        tooltip: 'Import MS Excel Workbook',
        icon: 'fas fa-file-import',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      }
    },
    sheetHeader: {
      0: 'Rate Sheet Number',
      1: 'Description',
      2: 'Set Rate Sheet Rules',
      3: 'Base Rate Recalculation Method'
    },
    baseRateRecalculationMethods: {
      0: 'Percent Based',
      1: 'Rate/Charge Based'
    },
    importSheet: {
      title: 'Import Rate Sheet from Excel',
      message: {
        0: 'The requested import file cannot be loaded because the rate sheet ID \n from the file does not match the ID of the current rate sheet.',
        1: 'Failed to load or Invalid Excel.',
        2: 'Import rate sheet from Excel complete.'
      }
    },
    exportSheet: {
      title: 'Rate Sheet Export',
      message: 'Choose which rate sheets to export',
      labels: {
        0: 'Both',
        1: 'Job Cost',
        2: 'Billing'
      }
    },
    sheetTable: {
      message: 'Hourly Usage cannot be used on a Quantity Tracked Asset'
    },
    sheetRuleDlg: {
      title: 'Rate Sheet Rules',
      labels: {
        0: 'Establish the hours in each period for this rate sheet.',
        1: 'Establish the Migration points for this rate sheet.',
        2: 'Hours in a Day',
        3: 'Hours in a Week',
        4: 'Hours in a Month',
        5: 'Migrate to Daily Rate at',
        6: 'Migrate to Weekly Rate at',
        7: 'Migrate to Monthly Rate at'
      }
    }
  },
  exportTemplates: {
    title: 'Export Template Lists',
    placeholder: 'Search Templates',
    labels: {
      0: 'All',
      1: 'Transaction Summary',
      2: 'Transaction Detail',
      3: 'Name',
      4: 'Record Delimiter',
      5: 'Record Format',
      6: 'Available Fields',
      7: 'First Line of Export (Charge)',
      8: 'Second Line of Export (Credit)',
      9: 'Export Layout',
      10: 'Add to Export',
      11: 'Add to First Line',
      12: 'Add to Second Line',
      13: 'Edit Export Field Values',
      14: 'Fields Values Setup'
    },
    recordDelimiter: [
      {
        value: 1,
        description: 'Tab'
      },
      {
        value: 2,
        description: 'Comma'
      },
      {
        value: 3,
        description: 'Dash'
      },
      {
        value: 4,
        description: 'Plus'
      }
    ],
    recordFormat: [
      {
        value: 1,
        description: 'Job Cost (single line)'
      },
      {
        value: 2,
        description: 'Accounting (double line)'
      }
    ],
    availableFieldsHeader: [
      'Field Name', 'Type', 'Len', 'Notes'
    ],
    fieldValueTabs: [
      'Record Defaults',
      'Assignment Tools',
      'Calendar Tools',
      'Hourly Tools',
      'Consumables',
      'Service'
    ],
    fieldValueTabLabels: {
      0: 'Record Header',
      1: 'Reference 1',
      2: 'Reference 2',
      3: 'End of Record Marker',
      4: 'Cost Type',
      5: 'Transaction Type',
      6: 'Export Description'
    },
    validation: {
      templateNameRequired: 'Template Name Required',
      delimiterRequired: 'Delimiter Type Required'
    }
  },
  equipmentUsageLog: {
    title: 'Equipment Usage Log',
    addItemBrowserTitle: 'Tool Browser',
    actionHeader: {
      0: {
        title: 'Save',
        tooltip: 'Save',
        icon: 'fas fa-save'
      },
      1: {
        title: 'Add Item',
        tooltip: 'Add Item',
        icon: 'fas fa-plus'
      }
    },
    scanLabel: 'Enter item number',
    dialogLabels: {
      0: 'Choose Yes to delete the row or No to exit.',
      1: 'Delete Rows'
    },
    warningMsg: 'Required Fields are missing'
  },
  exportData: {
    title: 'Data Export',
    labels: {
      0: 'Export Source',
      1: 'Posted Transactions',
      2: 'Unposted Transactions',
      3: 'Job Cost Data',
      4: 'Billing Data',
      5: 'Job Cost and Billing Data',
      6: 'Data Format',
      7: 'Summary Templates',
      8: 'Detail Templates',
      9: 'Export Templates',
      10: 'Include Column Headers',
      11: 'Date Parameters',
      12: 'Posting Date Range'
    },
    warnLabels: {
      0: 'Please check at least 1 item from the cost centers.',
      1: 'Export template can not be empty. Please choose a template.'
    }
  },
  InvoiceBrowser: {
    title: 'Invoice Browser',
    actionHeader: {
      0: {
        title: 'Generate Invoices',
        tooltip: 'Generate Invoices',
        icon: 'fas fa-usd-circle'
      },
      1: {
        title: 'Print Invoices',
        tooltip: 'Print Invoices',
        icon: 'fas fa-print'
      },
      2: {
        title: 'Print Invoices with Rates',
        tooltip: 'Print Invoices with Rates',
        icon: 'fas fa-print'
      }
    },
    printTableHeader: [
      {
        label: 'Qty',
        key: 'Qty'
      },
      {
        label: 'Description',
        key: 'Description'
      },
      {
        label: 'Transaction Type',
        key: 'TransactionType'
      },
      {
        label: 'Charge Type',
        key: 'ChargeType'
      },
      {
        label: 'Hours',
        key: 'Hours'
      },
      {
        label: 'Effective Rate/Hr',
        key: 'EffectiveRate'
      },
      {
        label: 'Unit Price',
        key: 'UnitPrice'
      },
      {
        label: 'Total',
        key: 'Total'
      }
    ],
    labels: {
      0: 'Daily Rate',
      1: 'Weekly Rate',
      2: 'Monthly Rate',
      3: 'Invoice Number',
      4: 'Invoice Date',
      5: 'Billing Period',
      6: 'Payment Due',
      7: 'Terms',
      8: 'Cost Center',
      9: 'Center Contact',
      10: 'Contact Phone',
      11: 'Serial Number',
      12: 'Assignment',
      13: 'Start Date',
      14: 'End Date',
      15: 'Rental Charge',
      16: 'Sales Tax {salesTax}%',
      17: 'Net Due Upon Receipt',
      18: 'Please Remit To:',
      19: 'Special Instructions:',
      20: 'Invoice No.',
      21: 'Cost Centers',
      22: 'Invoice Dates From',
      23: 'Browse Invoices'
    },
    warningDialog: {
      title: 'Print Invoice with Rates',
      content: {
        0: '{cntWithNoRates} of your selected invoices do not have rate data. Do you want to continue?',
        1: 'Only invoices generated after Oct 1, 2020 will include rates for calculations and postings.'
      }
    },
    noInvoicesSelected: 'No invoices selected'
  },
  InvoiceBrowserGenerate: {
    title: 'Generate Invoices',
    labels: {
      0: 'Date Range',
      1: 'to',
      2: 'Posted',
      3: 'Unposted',
      4: 'Include charges for retired tools and loss charges',
      5: 'Charge Type',
      6: 'Job Cost',
      7: 'Billing',
      8: 'Cost Centers',
      9: 'Invoice Date',
      10: 'Payment Due',
      11: 'Remittance:'
    },
    warningDialog: {
      title: 'Warning',
      content: {
        0: 'Cost Center selection cannot be empty.',
        1: 'Payment Due Date cannot be empty.'
      }
    }
  },
  importMeter: {
    title: 'Import Meter'
  },
  importFuelEmployees: {
    title: 'Import Fuel Employees'
  },
  Map: {
    link: 'Map'
  }
}

export default billing

const calcMethods = [
  {
    Key: 'Asignacion',
    Value: 1
  },
  {
    Key: 'Calendario (Dias)',
    Value: 4
  },
  {
    Key: 'Calendario (Horas)',
    Value: 3
  },
  {
    Key: 'Calendario (Minutos)',
    Value: 0
  },
  {
    Key: 'Horas de Uso',
    Value: 2
  }
]

export default calcMethods

const costLists = {
  tableHeader: {
    desktop: [
      {
        label: 'Ubicación de abastecimiento'
      },
      {
        label: 'Cantidad total comprada'
      },
      {
        label: 'Costo de compra promedio'
      },
      {
        label: 'Costo de la última compra'
      },
      {
        label: 'Vendedor preferido'
      }
    ],
    mobile: [
      {
        label: 'Ubicación de abastecimiento'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Cantidad total comprada'
          },
          {
            label: 'Costo de compra promedio'
          },
          {
            label: 'Costo de la última compra'
          },
          {
            label: 'Vendedor preferido'
          }
        ]
      }
    ]
  }
}

export default costLists

import CONST from '../../helpers/const'

const frequency = {
  labels: {
    1: 'Frecuencia',
    2: 'Día(s)',
    3: 'Mes(es)',
    4: 'Seleccionar todo',
    5: 'Anular la selección de todo',
    6: 'Fecha de inicio y finalización'
  },
  validations: {
    startDate: 'Fecha de inicio',
    endDate: 'Fecha final'
  },
  daysOfWeek: [
    {
      name: 'Sol',
      value: CONST.SUNDAY
    },
    {
      name: 'Mon',
      value: CONST.MONDAY
    },
    {
      name: 'Martes',
      value: CONST.TUESDAY
    },
    {
      name: 'Miércoles',
      value: CONST.WEDNESDAY
    },
    {
      name: 'Jue',
      value: CONST.THURSDAY
    },
    {
      name: 'Vie',
      value: CONST.FRIDAY
    },
    {
      name: 'Sábado',
      value: CONST.SATURDAY
    }
  ],
  monthOfYear: [
    {
      name: 'Ene',
      value: CONST.JANUARY
    },
    {
      name: 'Feb',
      value: CONST.FEBRUARY
    },
    {
      name: 'Estropear',
      value: CONST.MARCH
    },
    {
      name: 'Apr',
      value: CONST.APRIL
    },
    {
      name: 'Mayo',
      value: CONST.MAY
    },
    {
      name: 'Jun',
      value: CONST.JUNE
    },
    {
      name: 'Jul',
      value: CONST.JULY
    },
    {
      name: 'Ago',
      value: CONST.AUGUST
    },
    {
      name: 'Sep',
      value: CONST.SEPTEMBER
    },
    {
      name: 'Oct',
      value: CONST.OCTOBER
    },
    {
      name: 'Nov',
      value: CONST.NOVEMBER
    },
    {
      name: 'Dic',
      value: CONST.DECEMBER
    }
  ],
  frequencyTypes: [
    {
      FrequencyName: 'N/A',
      FrequencyId: 0
    },
    {
      FrequencyName: 'Daily',
      FrequencyId: 1
    },
    {
      FrequencyName: 'Weekly',
      FrequencyId: 2
    },
    {
      FrequencyName: 'Monthly',
      FrequencyId: 3
    },
    {
      FrequencyName: 'Yearly',
      FrequencyId: 4
    }
  ]
}

export default frequency

const help = {
  onlineDoc: {
    mainLink: 'https://secure.toolwatch.com/',
    title: 'Online Documentation',
    contactSupport: 'Contact Support',
    userGuide: 'User Guides',
    guidesLink: [
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Set-up User Guide.pdf',
        name: 'ToolWatch Set-up'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Essentials User Guide.pdf',
        name: 'ToolWatch Essentials User Guide'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Service and Calibration User Guide.pdf',
        name: 'ToolWatch Service & Calibration User Guide'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Purchasing and Receiving User Guide.pdf',
        name: 'ToolWatch Purchasing & Receiving User Guide'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Job Cost and Billing User Guide.pdf',
        name: 'ToolWatch Job Cost & Billing User Guide'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Enterprise Resources User Guide.pdf',
        name: 'ToolWatch Enterprise Resources User Guide'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Mobile User Guide.pdf',
        name: 'ToolWatch Mobile User Guide'
      },
      {
        link: 'https://secure.toolwatch.com/media/support/ToolWatch Cloud User Guide.pdf',
        name: 'ToolWatch Cloud User Guide'
      }
    ],
    contactForm: {
      name: 'Contact name*',
      company: 'Company*',
      email: 'Email*',
      phoneNumber: 'Phone number',
      subject: 'Subject',
      issue: {
        title: 'Which best describes the nature of your issue?',
        lists: [
          {
            key: 'Question',
            value: 'S4 - Trivial'
          },
          {
            key: 'Minor bug or performance issue, causing minor business impact',
            value: 'S2 - Major'
          },
          {
            key: 'Major bug or performance issue, causing significant business impact',
            value: 'S1 - Urgent'
          },
          {
            key: 'Critical bug or performance issue causing downtime',
            value: 'S0 - Business-Stopping'
          }
        ]
      },
      description: 'Description',
      hint: 'Fields marked with an asterisk (*) are required.',
      submit: 'Submit'
    }
  }
}

export default help

const equivalentsList = {
  tableHeader: {
    desktop: [
      {
        label: 'UPC',
        key: 'Upc',
        editable: true,
        isText: true
      },
      {
        label: 'Manufacturer',
        key: 'Manufacturer',
        editable: true,
        isListSearchComboBox: true
      },
      {
        label: 'Product/Catalog No.',
        key: 'Model',
        editable: true,
        isText: true
      },
      {
        label: 'Description',
        key: 'Description',
        editable: true,
        isText: true
      },
      {
        label: '',
        key: 'RecordId',
        isRemove: true
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'UPC',
            key: 'Upc',
            editable: true,
            isText: true
          },
          {
            label: 'Manufacturer',
            key: 'Manufacturer',
            editable: true,
            isListSearchComboBox: true
          },
          {
            label: 'Product/Catalog No.',
            key: 'Model',
            editable: true,
            isText: true
          },
          {
            label: 'Description',
            key: 'Description',
            editable: true,
            isText: true
          }
        ]
      },
      {
        label: '',
        key: 'RecordId',
        isRemove: true
      }
    ]
  }
}

export default equivalentsList

const workflow = {
  workflowBuilder: {
    title: 'Workflow Browser',
    tabs: {
      0: {
        title: 'New Workflow'
      },
      1: {
        title: 'Edit Workflow'
      },
      2: {
        title: 'Copy Workflow'
      },
      3: {
        title: 'Delete Workflow'
      },
      4: {
        title: 'Print',
        icon: 'fas fa-print'
      },
      5: {
        title: 'Export',
        icon: 'fas fa-file-export'
      }
    },
    messages: {
      workflowStarted: 'Workflow(s) started successfully for trigger - ',
      workflowCheckErrorMsg: 'No workflow for Unique tool creation trigger. Please create atleast one',
      toolTypeItemCheckErrorMsg: 'No Unique Tool type record exist to start Unique tool creation trigger type Workflow'
    }
  },
  workflowBrowser: {
    title: 'Active Workflows',
    tabs: {
      0: {
        title: 'View'
      },
      1: {
        title: 'Edit',
        key: 'Edit Task'
      },
      2: {
        title: 'Print',
        icon: 'fas fa-print'
      },
      3: {
        title: 'Export',
        icon: 'fas fa-file-export'
      }
    },
    labels: {
      open: 'Open',
      closed: 'Closed',
      all: 'All'
    }
  },
  workflowApprovals: {
    labels: {
      open: 'Open',
      closed: 'Closed',
      all: 'All',
      switcherOff: 'My Approvals',
      switcherOn: 'All Users Approvals',
      placeholder: 'What are you looking for?',
      approvalRejectDialogTitle: 'Approve/Reject?',
      approve: 'Approve',
      reject: 'Reject',
      disposition: 'Disposition',
      dispositionErrorMsg: 'Please enter disposition'
    },
    browserLineActions: [
      {
        title: 'Approve',
        actionName: 'onApprove',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Reject',
        actionName: 'onReject',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  }
}

export default workflow

const classes = {
  tableHeader: {
    desktop: [
      {
        label: 'Control'
      },
      {
        label: 'Numero'
      },
      {
        label: 'Nombre'
      },
      {
        label: ''
      }
    ],
    mobile: [
      {
        label: 'control'
      },
      {
        label: 'Información',
        data: [
          {
            label: 'Numero'
          },
          {
            label: 'Nombre'
          }
        ]
      },
      {
        label: ''
      }
    ]
  }
}

export default classes

const folderReports = {
  tableHeader: {
    desktop: [
      {
        label: 'Folder Name',
        key: 'folderName',
        sortable: true
      },
      {
        label: 'Created By',
        key: 'createdBy',
        sortable: true
      },
      {
        label: 'Created On',
        key: 'formattedCreatedOn',
        sortable: true
      },
      {
        label: 'Modified By',
        key: 'modifiedBy',
        sortable: true
      },
      {
        label: 'Modified On',
        key: 'formattedModifiedOn',
        sortable: true
      },
      {
        label: '',
        key: '',
        sortable: false
      }
    ],
    mobile: [
      {
        label: 'Folder Name',
        key: 'folderName',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Created By',
            key: 'createdBy',
            sortable: true
          },
          {
            label: 'Created On',
            key: 'formattedCreatedOn',
            sortable: true
          },
          {
            label: 'Modified By',
            key: 'modifiedBy',
            sortable: true
          },
          {
            label: 'Modified On',
            key: 'formattedModifiedOn',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        sortable: false
      }
    ]
  }
}

export default folderReports

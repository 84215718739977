const taskBrowser = {
  task: {
    title: 'Task Browser',
    taskBrowserSelection: 'Task Assignment',
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'TaskId',
          type: 'check'
        },
        {
          label: 'Categoría de tarea',
          key: 'CategoryName',
          type: String,
          sortable: true
        },
        {
          label: 'Estado',
          key: 'State',
          type: String,
          sortable: true
        },
        {
          label: 'Sujeto',
          key: 'Subject',
          type: String,
          sortable: true
        },
        {
          label: 'Asignado a',
          key: 'AssignedTo',
          type: String,
          sortable: true
        },
        {
          label: 'Debido por',
          key: 'DueBy',
          type: Date,
          sortable: true
        },
        {
          label: 'fuente',
          key: 'Source',
          type: String,
          sortable: true
        },
        {
          label: 'Prioridad',
          key: 'PrioritiesName',
          type: String,
          sortable: true
        },
        {
          label: 'Estado',
          key: 'StatusName',
          type: String,
          sortable: true
        },
        {
          label: 'Asignado por',
          key: 'CreatedBy',
          type: String,
          sortable: true
        },
        {
          label: 'Completado en',
          key: 'CompletedOn',
          type: Date,
          sortable: true
        },
        {
          label: 'Reaparición',
          key: 'Recurrence',
          type: String,
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'TaskId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Asignado a',
              key: 'AssignedTo',
              type: String,
              sortable: true
            },
            {
              label: 'Debido por',
              key: 'DueBy',
              type: Date,
              sortable: true
            },
            {
              label: 'fuente',
              key: 'Source',
              type: String,
              sortable: true
            },
            {
              label: 'Prioridad',
              key: 'PrioritiesName',
              type: String,
              sortable: true
            },
            {
              label: 'Estado',
              key: 'StatusName',
              type: String,
              sortable: true
            },
            {
              label: 'Asignado por',
              key: 'CreatedBy',
              type: String,
              sortable: true
            },
            {
              label: 'Completado en',
              key: 'CompletedOn',
              type: Date,
              sortable: true
            },
            {
              label: 'Reaparición',
              key: 'Recurrence',
              type: String,
              sortable: true
            },
            {
              label: 'Estado',
              key: 'State',
              type: String,
              sortable: true
            }
          ]
        }
      ]
    },
    filter: {
      0: 'All'
    },
    actionHeader: {
      0: {
        title: 'Agregar tarea',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Editar tarea',
        key: 'Editar tarea',
        icon: 'fas fa-edit'
      },
      4: {
        title: 'Impresión',
        icon: 'fas fa-print'
      },
      5: {
        title: 'Exportar',
        icon: 'fas fa-file-export'
      }
    },
    modalHeader: {
      title: 'Crear tarea',
      subject: 'Tema',
      category: 'Categoría del Trabajo',
      toolWatchLocation: 'Ubicación del sistema',
      otherLocation: 'Otra Ubicacion',
      assignedTo: 'Asignado a',
      dueBy: 'Debido por',
      priority: 'Prioridad',
      status: 'Estado',
      notes: 'notas',
      editTaskTitle: 'Editar tarea',
      locationValidationMsg: 'Se requiere la ubicación de ToolWatch',
      otherLocationValidationMsg: 'Se requiere otra ubicación',
      dueByDateValidationMsg: 'La fecha de vencimiento es obligatoria para las tareas recurrentes'
    },
    buttons: {
      switcherOff: 'Mis tareas',
      switcherOn: 'Todas las Tasks',
      recurrence: 'Reaparición',
      attach: 'Archivos adjuntos',
      addAndNext: 'Agregar y Siguiente',
      add: 'Agregar',
      cancel: 'Cancelar',
      save: 'Ahorrar',
      saveTaskComplete: 'Ahorrar, Tarea completa'
    },
    export: {
      noItemsSelected: 'No hay artículos seleccionados'
    },
    messages: {
      saved: 'Trabajo creada con éxito.',
      updated: 'Trabajo guardada exitosamente.'
    },
    recurrenceValue: {
      yes: 'Yes',
      no: 'No'
    },
    labels: {
      open: 'Abierto',
      closed: 'Cerrado',
      all: 'Todo'
    }
  }
}

export default taskBrowser

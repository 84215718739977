<template>
  <div class="error-wrapper">
    <LocaleSwitcher />
    <div class="error-page">
      <p class="title">{{$t('procore.title')}}</p>
      <div class="description" v-if="showCompanyNotInProcore">
        <span v-html="$t('procore.description.0', { 0: companyId })"></span>
      </div>
      <div class="description" v-if="showCompanyNotIntegrated">
        <span v-html="$t('procore.description.1', { 0: companyId })"></span>
      </div>
      <div class="description" v-if="showCompanySyncNotEnabled">
        <span v-html="$t('procore.description.2', { 0: companyName, 1: companyId })"></span>
      </div>
      <div class="description" v-if="showCompanyNotActive">
        <span v-html="$t('procore.description.3', { 0: companyName, 1: companyId })"></span>
      </div>
      <div class="description" v-if="showProjectSyncNotEnabled">
        <span v-html="$t('procore.description.4', { 0: companyName, 1: companyId })"></span>
      </div>
      <div class="description" v-if="showProjectNotSynced">
        <span v-html="$t('procore.description.5', { 0: companyName, 1: companyId, 2: projectId })"></span>
      </div>
      <div class="description" v-if="showProjectNotActive">
        <span v-html="$t('procore.description.6', { 0: companyName, 1: companyId, 2: projectName, 3: projectId })"></span>
      </div>
      <div class="description" v-if="showProjectNotInProcore">
        <span v-html="$t('procore.description.7', { 0: companyName, 1: companyId, 2: projectName, 3: projectId })"></span>
      </div>
      <div class="description">
        <a :href="'/requisitions'">{{$t('procore.description.8')}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from '../components/shared/LocaleSwitcher.vue'

export default {
  name: 'ProcoreWarning',
  components: {
    LocaleSwitcher
  },
  computed: {
    settings () {
      return this.$store.getters.settings
    },
    companyExists () {
      return this.settings.CompanyName != null
    },
    showCompanyNotIntegrated () {
      return this.companyExists && !this.settings.IsIntegrated
    },
    showCompanySyncNotEnabled () {
      return this.companyExists && !this.settings.IsCompanySyncEnabled
    },
    showCompanyNotActive () {
      return this.companyExists && !this.settings.IsCompanyActive
    },
    showCompanyNotInProcore () {
      return !this.companyExists
    },
    companyId () {
      return this.$store.getters.companyId
    },
    companyName () {
      return this.settings.CompanyName
    },
    projectExists () {
      return this.companyExists && this.settings.ProjectName != null
    },
    showProjectSyncNotEnabled () {
      return this.companyExists && !this.settings.IsProjectSyncEnabled
    },
    showProjectNotSynced () {
      return this.projectExists && !this.settings.IsProjectSynced
    },
    showProjectNotActive () {
      return this.projectExists && !this.settings.IsProjectActive
    },
    showProjectNotInProcore () {
      return this.companyExists && !this.projectExists
    },
    projectId () {
      return this.$store.getters.projectId
    },
    projectName () {
      return this.settings.ProjectName
    }
  }
}
</script>

const invoiceBrowserTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Número',
        key: 'InvoiceNumber',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Fecha',
        key: 'InvoiceDate',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Vencimiento',
        key: 'PaymentDue',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Tipo de cargo’',
        key: 'RateSheetEntryType',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Tipo',
        key: 'PostingType',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Incluir cargos por pérdidas',
        key: 'IsRetiredIncluded',
        type: Boolean,
        sortable: 'true'
      },
      {
        label: 'Inicio de periodo de facturación',
        key: 'BillingPeriodStart',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Fin del periodo de facturación',
        key: 'BillingPeriodEnd',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Condiciones',
        key: 'Terms',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Impuesto de venta’',
        key: 'SalesTax',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Centro de costos',
        key: 'CostCenter',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Creado por',
        key: 'CreatedBy',
        type: String,
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: 'Número',
        key: 'InvoiceNumber',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Fecha',
            key: 'InvoiceDate',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Vencimiento',
            key: 'PaymentDue',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Tipo de cargo’',
            key: 'RateSheetEntryType',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Tipo',
            key: 'PostingType',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Incluir cargos por pérdidas',
            key: 'IsRetiredIncluded',
            type: Boolean,
            sortable: 'true'
          },
          {
            label: 'Inicio de periodo de facturación',
            key: 'BillingPeriodStart',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Fin del periodo de facturación',
            key: 'BillingPeriodEnd',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Condiciones',
            key: 'Terms',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Impuesto de venta’',
            key: 'SalesTax',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Centro de costos',
            key: 'CostCenter',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Creado por',
            key: 'CreatedBy',
            type: String,
            sortable: 'true'
          }
        ]
      }
    ]
  }
}

export default invoiceBrowserTable

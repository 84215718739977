const scheduledReports = {
  tableHeader: {
    desktop: [
      {
        label: 'Recipient',
        key: 'recipient',
        sortable: true
      },
      {
        label: 'Report',
        key: 'name',
        sortable: true
      },
      {
        label: 'Schedule',
        key: 'schedule',
        sortable: true
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Recipient',
            key: 'recipient',
            sortable: true
          },
          {
            label: 'Report',
            key: 'name',
            sortable: true
          },
          {
            label: 'Schedule',
            key: 'schedule',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default scheduledReports

import axios from 'axios'
import { uuid } from 'vue-uuid'
import { errorHandler, getDateAndTimeString } from '../helpers/utils'

export default {
  state: () => ({
    descriptions: []
  }),
  mutations: {
    setDescriptions (state, items) {
      state.descriptions = items
    }
  },
  actions: {
    async getDescriptions (context, toast) {
      const params = {
        orgId: JSON.stringify(localStorage.getItem('OrgId')),
        desc: JSON.stringify(null),
        excludeDeleted: JSON.stringify(true),
        ids: JSON.stringify(null)
      }
      try {
        context.commit('setLoading', true)
        const { data } = await axios.post('/api/core/DescriptionsService/Get', params)
        const descriptions = data.Body
          .sort((a, b) => a.Description.localeCompare(b.Description)).map(item => ({
            Type: item.Type.trim(),
            Description: item.Description,
            Id: item.Id
          }))
        context.commit('setDescriptions', descriptions)
      } catch (error) {
        if (error.response) {
          errorHandler(toast, error.response.statusText)
        }
      } finally {
        context.commit('setLoading', false)
      }
    },
    async saveNewDescription (context, {
      id,
      description,
      type
    }, toast) {
      const params = {
        description: JSON.stringify({
          Id: id ?? uuid.v4(),
          OrgId: localStorage.getItem('OrgId'),
          Description: description,
          Type: type,
          CreatedById: localStorage.getItem('UserId'),
          CreatedOn: getDateAndTimeString(),
          ModifiedById: localStorage.getItem('UserId'),
          ModifiedOn: getDateAndTimeString(),
          Deleted: false,
          Inactive: false
        })
      }
      try {
        context.commit('setLoading', true)
        await axios.post('/api/core/DescriptionsService/Save', params)
      } catch (error) {
        if (error.response) {
          errorHandler(toast, error.response.statusText)
        }
      } finally {
        if (context.getters.descriptions.length) {
          await context.dispatch('getDescriptions', toast)
        }
        context.commit('setLoading', false)
      }
    }
  },
  getters: {
    descriptions (state) {
      return state.descriptions
    }
  }
}

import { uuid } from 'vue-uuid'
import axios from 'axios'
import { sortingFunc } from '../helpers/utils.js'
import store from '../store'

export const listSearchOptions = {
  blank: {
    Key: '\xa0',
    Value: uuid.v4()
  },
  new: {
    Key: ' <New...> ',
    Value: uuid.v4()
  },
  browse: {
    Key: ' <Browse...> ',
    Value: uuid.v4()
  },
  all: {
    Key: ' --All--',
    Value: '00000000-0000-0000-0000-000000000000'
  },
  jobStockNewOption: {
    Key: '',
    Value: '00000000-0000-0000-0000-000000000000'
  }
}

export async function searchCostCodes (query, stockpointId, costCenterId) {
  let costCenter = null
  if (costCenterId !== null && costCenterId !== undefined && costCenterId !== '') {
    costCenter = await getCostCenter(costCenterId)
  }

  let costCodes = []
  if (costCenter === null) {
    // if the store already contains the cost code, it avoids to call the API again to get the data
    if (!store.getters.getShipData(4)?.data) {
      costCodes = await getCostCodes(stockpointId)
    } else {
      costCodes = store.getters.getShipData(4).data
    }
  } else {
    costCodes = await getCostCodes(stockpointId, costCenterId)
  }
  const lowerCaseQuery = query.toLowerCase()
  const filteredCostCodes = costCodes.filter(x => x.Description.toLowerCase().includes(lowerCaseQuery) || x.CostCodeNumber.includes(lowerCaseQuery))
  sortingFunc(filteredCostCodes, {
    key: 'Description',
    type: String,
    asc: false
  })
  return {
    costCenter: costCenter,
    costCodes: filteredCostCodes
  }
}

async function getCostCodes (stockpointId, costCenterId = null) {
  const headerConfig = {
    headers: {
      'Content-type': 'application/json; charset=utf-8'
    }
  }
  const params = {
    stockpointId: '\'' + stockpointId + '\''
  }

  if (costCenterId !== null) {
    params.costCenterId = '\'' + costCenterId + '\''
  }

  const stringifiedParams = JSON.stringify(params)
  try {
    const { data } = await axios.post('/api/core/CostCodesService/GetByCostCenterIdAndStockpointId', stringifiedParams, headerConfig)
    return data.Body
  } catch (error) {
    return []
  }
}

async function getCostCenter (costCenterId) {
  const headerConfig = {
    headers: {
      'Content-type': 'application/json; charset=utf-8'
    }
  }
  const params = JSON.stringify({
    costCenterId: '\'' + costCenterId + '\''
  })
  try {
    const { data } = await axios.post('/api/core/CostCentersService/Get', params, headerConfig)
    return data.Body
  } catch (error) {
    return null
  }
}

export async function searchById (id, listTypeId) {
  const headerConfig = {
    headers: {
      'Content-type': 'application/json; charset=utf-8'
    }
  }
  const params = JSON.stringify({
    id: '\'' + id + '\'',
    type: '\'' + listTypeId + '\''
  })
  try {
    const request = {
      url: '/api/core/ListsService/GetKvpById',
      paramsAsString: params,
      headerConfig: headerConfig
    }
    const { data } = await store.dispatch('getCachedPostRequest', request)
    return data.Body
  } catch (error) {
    return []
  }
}

export async function searchCostCodesById (id, stockpointId, costCenterId = null) {
  let costCenter = null
  if (costCenterId !== null && costCenterId !== undefined && costCenterId !== '') {
    costCenter = await getCostCenter(costCenterId)
  }

  let costCodes = []
  if (costCenter === null) {
    // if the store already contains the cost code, it avoids to call the API again to get the data
    if (!store.getters.getShipData(4)?.data) {
      costCodes = await getCostCodes(stockpointId)
    } else {
      costCodes = store.getters.getShipData(4).data
    }
  } else {
    costCodes = await getCostCodes(stockpointId, costCenterId)
  }
  return {
    costCenter: costCenter,
    costCode: costCodes.find(x => x.CostCodeId === id)
  }
}

export async function getSafetyInspectionUsers () {
  try {
    const { data } = await axios.post(
      '/api/core/SafetyPlanService/GetInspectionUsers', {})
    return data.Body
  } catch (error) {
    return null
  }
}

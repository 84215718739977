const pickedItems = {
  tableHeader: {
    desktop: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Picked',
        key: 'PickedQty',
        isEditable: true,
        type: Number,
        sortable: true
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Item Description',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'Manufacturer',
        key: 'Manuf',
        type: String,
        sortable: true
      },
      {
        label: 'Model',
        key: 'Model',
        type: String,
        sortable: true
      },
      {
        label: 'Picked By',
        key: 'PickedById',
        type: 'Guid',
        listType: 10,
        sortable: true
      },
      {
        label: 'Transferred',
        key: 'TransferNumber',
        type: String,
        sortable: true
      },
      {
        label: 'From Cost Code',
        key: 'FromCostCode',
        type: String,
        sortable: true
      },
      {
        label: 'To Cost Code',
        key: 'ToCostCode',
        type: String,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Picked',
            key: 'PickedQty',
            isEditable: true,
            type: Number,
            sortable: true
          },
          {
            label: 'Item',
            key: 'ItemNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Item Description',
            key: 'Description',
            type: String,
            sortable: true
          },
          {
            label: 'Manufacturer',
            key: 'Manuf',
            type: String,
            sortable: true
          },
          {
            label: 'Model',
            key: 'Model',
            type: String,
            sortable: true
          },
          {
            label: 'Picked By',
            key: 'PickedById',
            type: 'Guid',
            listType: 10,
            sortable: true
          },
          {
            label: 'Transferred',
            key: 'TransferNumber',
            type: String,
            sortable: true
          },
          {
            label: 'From Cost Code',
            key: 'FromCostCode',
            type: String,
            sortable: true
          },
          {
            label: 'To Cost Code',
            key: 'ToCostCode',
            type: String,
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  },
  tableHeaderWithFlag: {
    desktop: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Picked',
        key: 'PickedQty',
        isEditable: true,
        type: Number,
        sortable: true
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Item Description',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'Manufacturer',
        key: 'Manuf',
        type: String,
        sortable: true
      },
      {
        label: 'Model',
        key: 'Model',
        type: String,
        sortable: true
      },
      {
        label: 'Picked By',
        key: 'PickedById',
        type: 'Guid',
        listType: 10,
        sortable: true
      },
      {
        label: 'Transferred',
        key: 'TransferNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Pick From',
        key: 'PickedFrom',
        type: String,
        sortable: true
      },
      {
        label: 'Status',
        key: 'ItemStatus',
        type: String,
        sortable: true
      },
      {
        label: 'From Cost Code',
        key: 'FromCostCode',
        type: String,
        sortable: true
      },
      {
        label: 'To Cost Code',
        key: 'ToCostCode',
        type: String,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Picked',
            key: 'PickedQty',
            isEditable: true,
            type: Number,
            sortable: true
          },
          {
            label: 'Item',
            key: 'ItemNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Item Description',
            key: 'Description',
            type: String,
            sortable: true
          },
          {
            label: 'Manufacturer',
            key: 'Manuf',
            type: String,
            sortable: true
          },
          {
            label: 'Model',
            key: 'Model',
            type: String,
            sortable: true
          },
          {
            label: 'Picked By',
            key: 'PickedById',
            type: 'Guid',
            listType: 10,
            sortable: true
          },
          {
            label: 'Transferred',
            key: 'TransferNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Pick From',
            key: 'PickedFrom',
            type: String,
            sortable: true
          },
          {
            label: 'Status',
            key: 'ItemStatus',
            type: String,
            sortable: true
          },
          {
            label: 'From Cost Code',
            key: 'FromCostCode',
            type: String,
            sortable: true
          },
          {
            label: 'To Cost Code',
            key: 'ToCostCode',
            type: String,
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default pickedItems

const rateSheets = {
  tableHeader: {
    desktop: [
      {
        label: 'Tipo',
        key: 'type',
        sortable: 'true'
      },
      {
        label: 'ID de hoja de tarifas',
        key: 'rateSheetNumber',
        sortable: 'true'
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: 'true'
      },
      {
        label: 'Creado por',
        key: 'createdBy',
        sortable: 'true'
      },
      {
        label: 'Creado en',
        key: 'createdOn',
        sortable: 'true'
      },
      {
        label: 'Modificado por',
        key: 'modifiedBy',
        sortable: 'true'
      },
      {
        label: 'Modificado en',
        key: 'modifiedOn',
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: 'Tipo',
        key: 'type',
        sortable: true
      },
      {
        label: 'ID de hoja de tarifa',
        key: 'rateSheetId',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Description',
            key: 'description',
            sortable: 'true'
          },
          {
            label: 'Creado Por',
            key: 'createdBy',
            sortable: 'true'
          },
          {
            label: 'Creado en',
            key: 'createdOn',
            sortable: 'true'
          },
          {
            label: 'Modificado Por',
            key: 'modifiedBy',
            sortable: 'true'
          },
          {
            label: 'Modificado En',
            key: 'modifiedOn',
            sortable: 'true'
          }
        ]
      }
    ]
  }
}

export default rateSheets

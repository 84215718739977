import axios from 'axios'

export async function getCurrentMessages (isAcknowledgedOnly) {
  const params = JSON.stringify({
    isAcknowledgedOnly: isAcknowledgedOnly
  })
  try {
    const { data } = await axios.post('/api/core/MessageCenterService/GetCurrentMessages', params)
    const messageCentarData = data.Body
    return messageCentarData
  } catch (error) {
    if (error.response) {
      return []
    }
  }
}

export async function makeAllRead (recipientId) {
  const params = JSON.stringify({
    recipientId: JSON.stringify(recipientId)
  })
  try {
    await axios.post('/api/core/MessageCenterService/UpdateIsAcknowledgedMarkAllRead', params)
    return 'success'
  } catch (error) {
    if (error.response) {
      return ''
    }
  }
}

export async function singleMessageRead (messageId, recipientId) {
  const params = JSON.stringify({
    OrgId: '\'' + localStorage.getItem('OrgId') + '\'',
    RecipientId: JSON.stringify(recipientId),
    MessageId: JSON.stringify(messageId)
  })
  await axios.post('/api/core/MessageCenterService/UpdateIsAcknowledgedMessageCenterMessage', params)
}

export async function getUnreadMessageCount (isAcknowledgedOnly) {
  const params = JSON.stringify({
    isAcknowledgedOnly: isAcknowledgedOnly
  })
  try {
    const { data } = await axios.post('/api/core/MessageCenterService/GetUnreadMessageCount', params)
    const count = parseInt(data.Body)
    return count
  } catch (error) {
    if (error.response) {
      return 0
    }
  }
}

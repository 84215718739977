export default {
  state: () => ({
    isShowOnlyTracked: true,
    catalogResults: null
  }),
  mutations: {
    setIsShowOnlyTracked (state, flag) {
      state.isShowOnlyTracked = flag
    },
    setCatalogResults (state, data) {
      state.catalogResults = data
    }
  },
  getters: {
    isShowOnlyTracked (state) {
      return state.isShowOnlyTracked
    },
    catalogSearchType (state) {
      return state.isShowOnlyTracked ? 1 : 2
    },
    catalogResults (state) {
      return state.catalogResults
    }
  }
}

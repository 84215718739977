const metric = {
  tableHeader: {
    desktop: [
      {
        label: 'Indicador'
      },
      {
        label: 'Período'
      },
      {
        label: 'Actual'
      },
      {
        label: 'Anterior'
      },
      {
        label: 'Cambio'
      }
    ],
    mobile: ['Indicador', 'Información']
  }
}

export default metric

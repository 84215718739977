const reservations = {
  homeTitle: 'Planificación',
  searchPlaceholder: 'Qué te gustaría programar?',
  noResultsMessage: 'No hay resultados que coincidan con la descripción de su búsqueda.',
  inStock: '{count} en existencia',
  available: '{count} disponible',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha final',
  reservationNumber: 'Reserva No.',
  schedulingNumber: 'Calendario No.',
  late: '(TARDE)',
  upcoming: {
    title: 'Presentado',
    noMsg: 'Sin Presentado planificación',
    quantity: 'Cantidad',
    items: 'Elementos'
  },
  delivered: {
    title: 'En Proceso',
    noMsg: 'No hay planificación En Proceso'
  },
  reReserve: {
    title: 'completado',
    noMsg: 'Sin planificación completadas'
  },
  noMsg: 'No hay resultados que coincidan con la descripción de su búsqueda.',
  stock: {
    inStock: '{count} en existencia',
    outofStock: 'Agotado'
  },
  productInfo: {
    title: 'Información del Producto',
    labels: {
      0: 'Fabricante',
      1: 'Modelo',
      2: 'Catalogar #',
      3: 'UPC/ASIN',
      4: 'Año',
      5: 'Id del Modelo'
    }
  },
  productTracking: {
    title: 'Información de seguimiento',
    labels: {
      0: 'Categoría',
      1: 'Descripción genérica',
      2: 'Tipo de artículo',
      3: 'Método de seguimiento',
      4: 'Clase predeterminada',
      5: 'Certificación',
      6: 'Se requiere medidor'
    }
  },
  cart: {
    title: 'Enviar Planificación',
    desc: 'Artículos en tu calendario',
    noMsg: 'Sin calendario'
  },
  checkout: {
    subtitle: 'Revisa tu calendario'
  },
  history: {
    title: 'Historial de planificación',
    placeholder: 'Buscar todas las planificación',
    labels: {
      0: 'Por artículo',
      1: 'Por planificación',
      2: 'Calendario de nuevo'
    },
    filter: {
      status: {
        0: 'Todas',
        1: 'Abierto',
        2: 'Cerrado',
        3: 'Borrador',
        4: 'Enviado',
        5: 'Terminado'
      },
      time: {
        0: 'Últimos 30 días',
        1: 'Últimos 3 meses'
      }
    },
    itemDetail: {
      status: 'Estado',
      quantity: 'Cant',
      startDate: 'Fecha de inicio',
      document: 'Documento',
      source: 'Fuente',
      endDate: 'Fecha final'
    }
  },
  cartSummaryTitle: 'Resumen de Planificación'
}

export default reservations

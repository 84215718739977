const workRequiredTools = {
  tableHeader: {
    desktop: [
      {
        label: 'Model No.',
        key: 'masterNumber',
        sortable: true
      },
      {
        label: 'Qty',
        key: 'requiredQty',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true
      },
      {
        label: 'Manufacturer',
        key: 'manufacturer',
        sortable: true
      },
      {
        label: 'Model',
        key: 'model',
        sortable: true
      },
      {
        label: 'Generic',
        key: 'isGeneric',
        id: 'workOrderRequiredToolId',
        type: Boolean,
        isEditable: true,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Model No.',
            key: 'masterNumber',
            sortable: true
          },
          {
            label: 'Qty',
            key: 'requiredQty',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'Manufacturer',
            key: 'manufacturer',
            sortable: true
          },
          {
            label: 'Model',
            key: 'model',
            sortable: true
          },
          {
            label: 'Generic',
            key: 'isGeneric',
            id: 'workOrderRequiredToolId',
            type: Boolean,
            isEditable: true,
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default workRequiredTools

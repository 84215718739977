const templateHolder = {
  tableHeader: {
    desktop: [
      {
        label: 'Type',
        key: 'ItemType', // from Model
        sortable: true
      },
      {
        label: 'Model ID',
        key: 'ModelId', // from Model
        sortable: true
      },
      {
        label: 'Item Description',
        key: 'Description', // from Model
        sortable: true
      },
      {
        label: 'Manufacturer',
        key: 'Manufacturer', // from Model
        sortable: true
      },
      {
        label: 'Model Number',
        key: 'ModelNumber', // from Model
        sortable: true
      },
      {
        label: 'Quantity',
        key: 'Quantity',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Generic',
        key: 'IsGeneric',
        type: Boolean,
        isEditable: true,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Type',
            key: 'ItemType',
            sortable: true
          },
          {
            label: 'Model ID',
            key: 'ModelId',
            sortable: true
          },
          {
            label: 'Item Description',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Manufacturer',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Model Number',
            key: 'ModelNumber',
            sortable: true
          },
          {
            label: 'Quantity',
            key: 'Quantity',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Generic',
            key: 'IsGeneric',
            type: Boolean,
            isEditable: true,
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default templateHolder

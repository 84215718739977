<template>
    <input type="date"
      class="form-control"
      :disabled="disabled"
      v-model="selectedDate"
      @change="dateValueOnChange">
</template>

<script>
import { generateUniqueId, getFormatedDateString, formatDate } from '../../helpers/utils.js'
import $ from 'jquery'

export default {
  name: 'DatePicker',
  props: {
    date: {
      type: String,
      default: new Date()
    },
    format: {
      type: String,
      default: 'mm/dd/yyyy'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      elemId: 'date_' + generateUniqueId(),
      selectedDate: null
    }
  },
  mounted: function () {
    var self = this
    if (self._props.date !== null) {
      let formattedSelectedDate = self._props.date
      if (formattedSelectedDate.includes('/')) {
        formattedSelectedDate = formatDate(self._props.date)
      }
      this.selectedDate = formattedSelectedDate
    }
    $('#' + this.elemId)
      .on('changeDate', function (e) {
        self.updateDate(e.format())
      })
  },
  methods: {
    dateValueOnChange: function (e) {
      this.updateDate(e.currentTarget.value.trim())
    },
    updateDate: function (dateVal) {
      if (dateVal !== null) {
        let formattedSelectedDate = dateVal
        if (formattedSelectedDate.includes('/')) {
          formattedSelectedDate = formatDate(formattedSelectedDate)
        }
        this.selectedDate = formattedSelectedDate
        this.$emit('onDateChanged', dateVal)
      }
    }
  },
  watch: {
    date: function (newValue) {
      this.updateDate(newValue)
    }
  }
}
</script>

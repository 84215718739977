const workOrderItem = {
  tableHeader: {
    desktop: [
      {
        label: 'Descripción',
        key: 'description'
      },
      {
        label: 'Fabricante',
        key: 'manufacturer'
      },
      {
        label: 'Modelo',
        key: 'model'
      },
      {
        label: 'Año',
        key: 'year'
      }
    ],
    mobile: [
      {
        label: 'Descripción',
        key: 'description'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Fabricante',
            key: 'manufacturer'
          },
          {
            label: 'Modelo',
            key: 'model'
          },
          {
            label: 'Año',
            key: 'year'
          }
        ]
      }
    ]
  }
}

export default workOrderItem

const locations = {
  tableHeader: {
    desktop: [
      {
        label: 'Control'
      },
      {
        label: 'Ubicación No'
      },
      {
        label: 'Descripción'
      },
      {
        label: 'Ciudad'
      },
      {
        label: 'Estado'
      },
      {
        label: 'Ubicación de almacen'
      },
      {
        label: 'Ser dado de baja'
      },
      {
        label: '',
        key: ''
      }
    ],
    mobile: [
      {
        label: 'conteol'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Ubicación No'
          },
          {
            label: 'Descripción'
          },
          {
            label: 'Ciudad'
          },
          {
            label: 'Estado'
          },
          {
            label: 'Ubicación de almacen'
          },
          {
            label: 'Ser dado de baja'
          }
        ]
      },
      {
        label: '',
        key: ''
      }
    ]
  },
  locationDescription: {
    title: {
      0: 'Ubicación de existencias',
      1: 'Ubicación sin stock',
      2: 'Artículo rastreado por GPS'
    }
  }
}

export default locations

<template>
  <div
    :id="dialogId"
    class="modal fade"
    role="dialog"
    :tabindex="tabIndex"
    data-backdrop="static"
  >
    <div
      role="document"
      class="modal-dialog"
      :class="dialogCenter ? 'modal-dialog-centered' : ''"
      v-bind:style="{ maxWidth: maxWidth }">
      <div class="modal-content" style="height: auto">
        <div class="modal-header">
          <slot name="header">
            <span class="modal-title font-weight-bold text-center" style="font-size: 20px">
              {{title}}
            </span>
            <button
              v-if="isClose"
              type="button"
              class="close"
              @click="close">
              &times;
            </button>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              ref="confirmed"
              class="btn"
              :disabled="disabledOk"
              v-if="!disabledOk"
              @click="okay">
              {{okBtnText}}
              <span
                v-if="isProcessing"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true">
              </span>
            </button>
            <button
              class="btn close-btn"
              type="button"
              @click="cancel">
              {{ cancelBtnText }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { generateUniqueId } from '../../helpers/utils.js'
import $ from 'jquery'

export default {
  name: 'ModalDialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    onClose: {
      type: Function,
      default: null
    },
    onOkay: {
      type: Function,
      default: null
    },
    onCancel: {
      type: Function,
      default: null
    },
    disabledOk: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: null
    },
    isProcessing: {
      type: Boolean,
      required: false,
      default: false
    },
    isClose: {
      type: Boolean,
      required: false,
      default: true
    },
    okBtnText: {
      type: String,
      required: false,
      default: function () {
        return this.$t('buttons.ok')
      }
    },
    cancelBtnText: {
      type: String,
      default: function () {
        return this.$t('buttons.cancel')
      }
    },
    dialogCenter: {
      type: Boolean,
      required: false,
      default: true
    },
    isOverlapping: {
      type: Boolean,
      required: false,
      default: false
    },
    tabIndex: {
      type: [Number, Boolean],
      required: false,
      default: -1
    }
  },
  data () {
    return {
      dialogId: 'dlgModal' + generateUniqueId()
    }
  },
  mounted () {
    // this.$refs.confirmed.focus()
  },
  methods: {
    open (title) {
      if (title) {
        this.title = title
      }
      $('#' + this.dialogId).modal('show')
      this.$emit('modalId', this.dialogId)
    },
    close () {
      this.hide(this.onClose)
    },
    okay () {
      this.hide(this.onOkay)
    },
    cancel () {
      this.hide(this.onCancel)
    },
    async hide (extFunc) {
      if (!extFunc || await extFunc()) {
        $('#' + this.dialogId).modal('hide')
        if (!this.isOverlapping) {
          if ($('body').hasClass('modal-open')) {
            setTimeout(() => {
              $('body').removeClass('modal-open')
            }, 500)
          }
        } else {
          if ($('body').hasClass('modal-open')) {
            setTimeout(() => {
              $('body').addClass('modal-open')
            }, 500)
          }
        }
        this.$emit('resetMaxWidth', null)
      }
    }
  }
}
</script>

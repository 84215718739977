const customReports = {
  tableHeader: {
    desktop: [
      {
        label: 'Nombre del reporte'
      },
      {
        label: 'Descripción'
      },
      {
        label: 'Carpeta'
      },
      {
        label: 'Privado'
      },
      {
        label: 'Creado por'
      },
      {
        label: 'Creado el'
      }
    ],
    mobile: [
      {
        label: 'Nombre del reporte'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Descripción'
          },
          {
            label: 'Carpeta'
          },
          {
            label: 'Privado'
          },
          {
            label: 'Creado por'
          },
          {
            label: 'Creado el'
          }
        ]
      }
    ]
  }
}

export default customReports

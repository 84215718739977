<template>
  <div v-if="showLink">
    <i class="fas fa-link tw-link-icon" @click="onClick"></i>
    <ConfirmDialog
      ref="confirmDlg"
      class="tw-shipping-modal"
      :isOverlapping="true"
      :title="'Integration Linked'"
      :message="this.message"
      :showCancel="false"
      :okBtnText="$t('buttons.ok')"
    />
  </div>
</template>

<script>
import ConfirmDialog from '../../components/shared/ConfirmDialog.vue'
import axios from 'axios'

export default {
  name: 'IntegrationLink',
  components: {
    ConfirmDialog
  },
  data () {
    return {
      showLink: false,
      externalId: '',
      type: '',
      message: ''
    }
  },
  props: ['twId'],
  methods: {
    onClick () {
      this.$refs.confirmDlg.open()
    },
    async getIntegrationInfo () {
      const headerConfig = {
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }
      const params = JSON.stringify({
        twId: '\'' + this.twId + '\''
      })
      const { data } = await axios.post('/api/core/ExternalIdsService/GetExternalIdMappingsByTWId', params, headerConfig)
      if (!data.Body.length) {
        return
      }
      this.showLink = true
      data.Body.forEach(x => {
        this.message += '<div>Integration Type: ' + x.Type + ';    External ID: ' + x.ExternalId + '</div>'
      })
    }
  },
  created () {
    if (this.twId) {
      this.getIntegrationInfo()
    }
  }
}
</script>

<style>
:root {
  --primary-color: #d29735;
}
.tw-link-icon {
    margin:5px;
    padding:5px;
}
.tw-link-icon:hover {
    color: var(--primary-color);
}
</style>

const bleTag = {
  tableHeader: {
    desktop: [
      {
        label: 'control',
        key: 'UnmappedBleTagId',
        isModify: true
      },
      {
        label: 'Etiquetar la dirección Mac',
        key: 'BleTagMacAddress',
        sortable: true
      },
      {
        label: 'Marca',
        key: 'Manufacturer',
        sortable: true
      },
      {
        label: 'Visto Ultima vez',
        type: Date,
        key: 'LastScan',
        sortable: true
      },
      {
        label: 'Puerto de Dirección Mac',
        key: 'BleGatewayMacAddress',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'control',
        key: 'TagId'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Etiqueta la dirección Mac',
            key: 'MacAddress',
            sortable: true
          },
          {
            label: 'Marca',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Visto Ultima vez',
            type: Date,
            key: 'LastSeen',
            sortable: true
          },
          {
            label: 'Puerto de Dirección Mac',
            key: 'Gateway Mac Address',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default bleTag

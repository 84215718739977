<template>
  <div class="middle-box text-center loginscreen animated fadeInDown">
    <div>
      <div>
        <h1 class="logo-name">{{brandName}}</h1>
      </div>
      <h3>{{$t(brandTitle)}}</h3>
      <div class="form-div">
        <div v-if="errorMessage.length > 0" class="error-message">
          <p style='color: red;'>{{errorMessage}}</p>
          <button type="button" class="btn close-btn block full-width m-b" name="logout" @click="logout">
            {{ $t("requisition.editModal.logout") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clearLocalStorage, getPlatformLoginUrl } from '../helpers/utils.js'
import { generateCodeVerifier, generateChallenge, storeVerifier, getVerifier } from '../helpers/authentication'
import axios from 'axios'
export default {
  name: 'PlatformLogin',
  data () {
    return {
      errorMessage: ''
    }
  },
  created () {
    if (process.env.VUE_APP_BRAND === 'TOOLOPS') {
      document.documentElement.style.setProperty('--primary-color', '#00DC00')
      document.documentElement.style.setProperty('--secondary-color', '#00EB00')
    } else {
      document.documentElement.style.setProperty('--primary-color', '#4c84fc')
      document.documentElement.style.setProperty('--secondary-color', '#4c84fc')
    }
  },
  mounted: async function () {
    const storeLoginMethod = this.$route.query.storeLoginMethod
    const isPlatformLogin = !storeLoginMethod || storeLoginMethod === 'true'
    localStorage.setItem('isPlatformLogin', isPlatformLogin)
    delete this.$route.query.storeLoginMethod
    await this.tryLogin()
  },
  methods: {
    async tryLogin () {
      const errorMessage = this.$route.query.error_message
      delete this.$route.query.error_message
      if (errorMessage) {
        this.errorMessage = errorMessage
        return
      }

      const completeLogin = this.$route.query.complete_login
      delete this.$route.query.complete_login
      if (completeLogin) {
        await this.completeLogin()
        return
      }

      const authCode = this.$route.query.auth_code
      delete this.$route.query.auth_code
      if (authCode) {
        await this.loginByAuthCode(authCode)
        return
      }

      const redirectUrl = this.processRedirectUrl()
      var codeVerifier = await generateCodeVerifier()
      storeVerifier(codeVerifier)
      var codeChallenge = await generateChallenge(codeVerifier)
      window.location.href = `${this.platformUrl}/authorize?callback_uri=${this.callbackUri}/platform-login&redirect_uri=${redirectUrl}&client_type=web&response_type=code&code_challenge=${codeChallenge}&brand=${this.brand}`
    },
    async logout () {
      window.location.href = `${this.platformUrl}/logout?callback_uri=${window.location.origin}&redirect_uri=platform-login`
    },
    async loginByAuthCode (authCode) {
      const params = new URLSearchParams()
      params.append('authCode', authCode)
      params.append('codeVerifier', getVerifier())

      const headerConfig = {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
      }

      const { data } = await axios.post('/api/loginByAuthCode', params, headerConfig)
      if (!data.Token) {
        this.errorMessage = data.Message ? data.Message : data.TokenMessage
        return
      }
      await this.completeLogin()
    },
    async completeLogin () {
      // Clear local storage here.
      // It'll be hydrated during authentication check
      clearLocalStorage()
      this.$store.commit('setFLogin', true)
      // to get the redirect urls
      delete this.$route.query.isLegacyLogin
      const redirectUrl = this.processRedirectUrl()
      await this.$router.push(redirectUrl)
    },
    processRedirectUrl () {
      let redirectUrl = this.$route.query.redirect_url
      delete this.$route.query.redirect_url
      if (redirectUrl) {
        const query = Object.entries(this.$route.query)
        query.forEach(x => {
          redirectUrl += `&${x[0]}=${x[1]}`
        })
      } else {
        redirectUrl = '/'
      }
      return redirectUrl
    }
  },
  computed: {
    brandTitle () {
      if (this.brand === 'TOOLOPS') {
        return 'authorization.titleTooLops'
      } else {
        return 'authorization.titleAlign'
      }
    },
    brandName () {
      if (this.brand === 'TOOLOPS') {
        return 'TO'
      } else {
        return 'ALIGNOPS'
      }
    },
    platformUrl () {
      return getPlatformLoginUrl()
    },
    callbackUri () {
      if (window.location.origin === 'https://cloud.toolwatch.com' && !this.$route.query.isLegacyLogin) {
        return 'https://cloud.alignops.com'
      } else if (window.location.origin === 'https://cloud.aligntech.co' && !this.$route.query.isLegacyLogin) {
        return 'https://cloud.alignops.com'
      }

      return window.location.origin
    },
    brand () {
      return process.env.VUE_APP_BRAND
    }
  },
  watch: {
    async $route (to) {
      // Underlying sessions are no longer valid,
      // so clear them by logging out of platform
      if (to && to.query.redirect_url && to.query.redirect_url.includes('platform-login')) {
        await this.logout()
      }
    }
  }
}
</script>
<style scoped>
  .btn.close-btn {
    background: transparent;
    color: #495760;
    border: 2px solid #DBDBDA;
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .form-group.checkbox-form .checkbox-holder:before {
    top: -4px;
  }
</style>

const scheduledReports = {
  tableHeader: {
    desktop: [
      {
        label: 'Destinatario'
      },
      {
        label: 'Informe'
      },
      {
        label: 'Programar'
      },
      {
        label: 'Acción'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Destinatario'
          },
          {
            label: 'Informe'
          },
          {
            label: 'Programar'
          }
        ]
      },
      {
        label: 'Acción'
      }
    ]
  }
}

export default scheduledReports

const procore = {
  title: 'Advertencia de Procore',
  description: {
    0: 'La empresa <b> ({0}) </b> no existe en Procore',
    1: 'La empresa <b> ({0}) </b> no está integrada con ToolWatch',
    2: 'La empresa <b> {0} ({1}) </b> está integrada con ToolWatch, pero la sincronización no está habilitada.',
    3: 'La empresa <b> {0} ({1}) </b> está integrada con ToolWatch, pero la empresa Procore no está activa',
    4: 'La empresa <b> {0} ({1}) </b> está integrada con ToolWatch, pero la sincronización de proyectos no está habilitada.',
    5: 'La empresa <b> {0} ({1}) </b> está integrada con ToolWatch, pero el proyecto <b> ({2}) </b> no está sincronizado',
    6: 'La empresa <b> {0} ({1}) </b> está integrada con ToolWatch, pero el proyecto <b> {2} ({3}) </b> no está activo',
    7: 'La empresa <b> {0} ({1}) </b> está integrada con ToolWatch, pero el proyecto <b> {2} ({3}) </b> no existe en Procore',
    8: 'Continuar'
  },
  404: {
    title: 'Página no encontrada',
    description: 'La página que estás buscando no existe',
    back: 'Ir a la <a href="/"> Pagina de inicio'
  }
}

export default procore

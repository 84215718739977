const jobStocks = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'masterId',
        type: 'check'
      },
      {
        label: 'Categoría',
        key: 'category',
        type: String,
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'model',
        type: String,
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'description',
        type: String,
        sortable: true
      },
      {
        label: 'Cantidad',
        key: 'inStock',
        type: Number,
        sortable: true
      },
      {
        label: 'En cantidad de solicitud',
        key: 'requisitionQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Cantidad baja',
        key: 'lowLevel',
        type: Number,
        sortable: true
      },
      {
        label: 'Cantidad objetivo',
        key: 'targetLevel',
        type: Number,
        sortable: true
      },
      {
        label: 'Cantidad alta',
        key: 'highLevel',
        type: Number,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'masterId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            key: 'category',
            type: String,
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'model',
            type: String,
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'description',
            type: String,
            sortable: true
          },
          {
            label: 'Cantidad',
            key: 'inStock',
            type: Number,
            sortable: true
          },
          {
            label: 'En cantidad de solicitud',
            key: 'requisitionQty',
            type: Number,
            sortable: true
          },
          {
            label: 'Cantidad baja',
            key: 'lowLevel',
            type: Number,
            sortable: true
          },
          {
            label: 'Cantidad objetivo',
            key: 'targetLevel',
            type: Number,
            sortable: true
          },
          {
            label: 'Cantidad alta',
            key: 'highLevel',
            type: Number,
            sortable: true
          }
        ]
      }
    ]
  },
  tableHeaderWithPurchaseQty: {
    desktop: [
      {
        label: '',
        key: 'masterId',
        type: 'check'
      },
      {
        label: 'Categoría',
        key: 'category',
        type: String,
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'model',
        type: String,
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'description',
        type: String,
        sortable: true
      },
      {
        label: 'Cantidad',
        key: 'inStock',
        type: Number,
        sortable: true
      },
      {
        label: 'En cantidad de solicitud',
        key: 'requisitionQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Cantidad de compra',
        key: 'requisitionPurchaseOrderQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Cantidad baja',
        key: 'lowLevel',
        type: Number,
        sortable: true
      },
      {
        label: 'Cantidad objetivo',
        key: 'targetLevel',
        type: Number,
        sortable: true
      },
      {
        label: 'Cantidad alta',
        key: 'highLevel',
        type: Number,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'masterId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            key: 'category',
            type: String,
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'model',
            type: String,
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'description',
            type: String,
            sortable: true
          },
          {
            label: 'Cantidad',
            key: 'inStock',
            type: Number,
            sortable: true
          },
          {
            label: 'En cantidad de solicitud',
            key: 'requisitionQty',
            type: Number,
            sortable: true
          },
          {
            label: 'Cantidad de compra',
            key: 'requisitionPurchaseOrderQty',
            type: Number,
            sortable: true
          },
          {
            label: 'Cantidad baja',
            key: 'lowLevel',
            type: Number,
            sortable: true
          },
          {
            label: 'Cantidad objetivo',
            key: 'targetLevel',
            type: Number,
            sortable: true
          },
          {
            label: 'Cantidad alta',
            key: 'highLevel',
            type: Number,
            sortable: true
          }
        ]
      }
    ]
  },
  lineActions: [
    {
      title: 'Ver Detallel',
      actionName: 'viewJobStock',
      permissions: ['JobStockView']
    },
    {
      title: 'Agregar a la solicitud',
      actionName: 'addToReq',
      permissions: ['JobStockEdit', 'RequisitionsandReturnsEdit']
    },
    {
      title: 'Agregar modelo',
      actionName: 'addModel',
      permissions: ['JobStockEdit']
    },
    {
      title: 'Eliminar modelo',
      actionName: 'delete',
      permissions: ['JobStockDelete']
    }
  ],
  buttons: {
    exportbtn: 'Exportar',
    levelMangerbtn: 'Gerente de nivel de trabajo',
    savebtn: 'Ahorrar',
    backbtn: 'Atrás',
    cancelbtn: 'Cancelar',
    yesBtn: 'Sí',
    noBtn: 'No'
  },
  jobStockModalTitlesAndMsg: {
    requisitionModalTitles: 'Carro de requisición',
    successToastMsg: 'Entregar en la ubicación modificada.',
    requisitionModalMsg: 'Ya hay artículos en el carrito de Solicitudes. ¿Quieres añadir estos modelos al carrito en curso? "No" borrará el carrito en progreso e iniciará un carrito nuevo.'
  },
  jobLevelManager: {
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'masterId',
          type: 'check'
        },
        {
          label: 'Categoría',
          key: 'category',
          type: String,
          sortable: true
        },
        {
          label: 'Modelo',
          key: 'model',
          type: String,
          sortable: true
        },
        {
          label: 'Descripción',
          key: 'description',
          type: String,
          sortable: true
        },
        {
          label: 'Cantidad baja',
          key: 'lowLevel',
          type: Number,
          sortable: true,
          editable: true
        },
        {
          label: 'Cantidad objetivo',
          key: 'targetLevel',
          type: Number,
          sortable: true,
          editable: true
        },
        {
          label: 'Cantidad alta',
          key: 'highLevel',
          type: Number,
          sortable: true,
          editable: true
        }
      ],
      mobile: [
        {
          label: 'check',
          key: 'masterId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Categoría',
              key: 'category',
              type: String,
              sortable: true
            },
            {
              label: 'Modelo',
              key: 'model',
              type: String,
              sortable: true
            },
            {
              label: 'Descripción',
              key: 'description',
              type: String,
              sortable: true
            },
            {
              label: 'Cantidad baja',
              key: 'lowLevel',
              type: Number,
              sortable: true,
              editable: true
            },
            {
              label: 'Cantidad objetivo',
              key: 'targetLevel',
              type: Number,
              sortable: true,
              editable: true
            },
            {
              label: 'Cantidad alta',
              key: 'highLevel',
              type: Number,
              sortable: true,
              editable: true
            }
          ]
        }
      ]
    },
    lineActions: [
      {
        title: 'Agregar modelo',
        actionName: 'addJobManagerModel',
        permissions: ['JobStockEdit']
      },
      {
        title: 'Eliminar modelo',
        actionName: 'deleteJobManagerModel',
        permissions: ['JobStockDelete']
      }
    ]
  },
  validation: {
    lowLevel: 'El mínimo debe ser más bajo que el objetivo y el alto',
    targetLevel: 'El objetivo debe ser más alto que el mínimo y más bajo que el máximo',
    highLevel: 'El máximo debe ser más alto que el mínimo y el objetivo',
    errorMsg: 'Por favor, corrija la cantidad en los campos resaltados'
  },
  locationPlaceholder: 'Seleccione una ubicación'
}
export default jobStocks

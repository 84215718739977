const serviceClasses = {
  tableHeader: {
    desktop: [
      {
        label: 'check',
        key: 'serviceClassId',
        isModify: true
      },
      {
        label: 'No.',
        key: 'serviceClassNumber',
        sortable: true
      },
      {
        label: 'Name',
        key: 'description',
        sortable: true
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'serviceClassId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.',
            key: 'serviceClassNumber',
            sortable: true
          },
          {
            label: 'Name',
            key: 'description',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default serviceClasses

import { deepCopy, uniq } from '../helpers/utils'

export default {
  state: () => ({
    templatesData: [],
    templateHeader: {},
    categories: [],
    templateTypes: [],
    templateCategories: []
  }),
  mutations: {
    addTemplateData (state, item) {
      const array = [...state.templatesData, ...item]
      state.templatesData = uniq(array, x => x.MasterId)
    },
    resetTemplateData (state) {
      state.templatesData = []
    },
    setTemplateHeader (state, header) {
      state.templateHeader = { ...header }
    },
    removeTemplateData (state, id) {
      state.templatesData = deepCopy(state.templatesData.filter(data => data.Id != id))
    },
    setCategories (state, categories) {
      state.categories = categories
    },
    setTemplateTypes (state, templateTypes) {
      state.templateTypes = templateTypes
    },
    setTemplateCategories (state, templateCategories) {
      state.templateCategories = templateCategories
    }
  },
  getters: {
    templateData (state) {
      return state.templatesData
    },
    templateHeader (state) {
      return state.templateHeader
    },
    categories (state) {
      return state.categories
    },
    templateTypes (state) {
      return state.templateTypes
    },
    templateCategories (state) {
      return state.templateCategories
    }
  }
}

const workOrderTasks = {
  tableHeader: {
    desktop: [
      {
        label: 'Done',
        key: 'isDone',
        type: Boolean,
        id: 'sequenceNumber',
        isEditable: false,
        sortable: true
      },
      {
        label: 'Task',
        key: 'sequenceNumber',
        type: Number,
        sortable: true
      },
      {
        label: 'Type',
        key: 'taskType',
        type: String,
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        type: String,
        sortable: true
      },
      {
        label: 'Est. Hrs',
        key: 'estimatedHours',
        type: Number,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Done',
            key: 'isDone',
            type: Boolean,
            id: 'sequenceNumber',
            isEditable: false
          },
          {
            label: 'Task',
            key: 'sequenceNumber',
            type: Number
          },
          {
            label: 'Type',
            key: 'taskType',
            type: String
          },
          {
            label: 'Description',
            key: 'description',
            type: String
          },
          {
            label: 'Est. Hrs',
            key: 'estimatedHours',
            type: Number
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default workOrderTasks

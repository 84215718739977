<template>
<div>
  <span v-if='address'>{{address}}<br></span>
  <span v-if='address2'>{{address2}}<br></span>
  <span v-if='hasCityStateZip'>{{formattedCity}}{{formattedState}}{{zip}}<br></span>
  <span v-if='country'>{{country}}<br></span>
  <span v-if='phone'>{{phone}}</span>
</div>
</template>

<script>
export default {
  name: 'ReqAddress',
  props: {
    address: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null,
      default: function () {
        return ''
      }
    },
    address2: {
      required: false,
      validator: prop => typeof prop === 'string' || prop === null,
      default: function () {
        return ''
      }
    },
    city: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null,
      default: function () {
        return ''
      }
    },
    state: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null,
      default: function () {
        return ''
      }
    },
    zip: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null,
      default: function () {
        return ''
      }
    },
    country: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null,
      default: function () {
        return ''
      }
    },
    phone: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null,
      default: function () {
        return ''
      }
    }
  },
  computed: {
    hasCityStateZip () {
      return this.city ||
       this.state ||
       this.zip
    },
    formattedCity () {
      if (!this.city) {
        return ''
      }

      if (this.state) {
        return this.city + ', '
      }

      return this.city
    },
    formattedState () {
      if (!this.state) {
        return ''
      }

      if (this.zip) {
        return this.state + ' '
      }

      return this.state
    }
  }
}
</script>

<template>
  <div :id="'dlgConfirm' + elemId" class="modal fade confirm no-scroll" role="dialog"
    data-backdrop="static" tabindex="-1">
    <div
      class="modal-dialog"
      :class="dialogCenter ? 'modal-dialog-centered' : ''"
      v-bind:style="{ maxWidth: maxWidth }"
    >
      <div class="modal-content" style="height: auto">
        <div class="modal-header">
          <span class="modal-title font-weight-bold text-center">
            {{title}}
          </span>
        </div>
        <div class="modal-body d-flex align-items-center">
          <slot name="body">
            <span
              v-if="title == $t('message.pickTicket.delete.altTitle')"
            >
              <i
                style="font-size:25px; color: red;"
                class="fas fa-exclamation-circle mr-2"></i>
            </span>
            <span v-html="message" class="white-space-pre-wrap"></span>
          </slot>
        </div>
        <div class="modal-footer">
          <button v-if='showCancel' class="btn close-btn"
            type="button"
            v-on:click="onCancel">
            {{cancelBtnText}}
          </button>
          <tw-button
            :permissions="permissionsDelete"
            :buttonText="$t('buttons.ok')"
          >
          <button class="btn"
            type="button" style="color: white"
            v-on:click="onOk">
            {{okBtnText}}
          </button></tw-button>
          <button
            v-if="showNo"
            class="btn"
            type="button" style="color: white"
            v-on:click="onNo">
            {{noBtnText}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { generateUniqueId } from '../../helpers/utils.js'
import $ from 'jquery'
import TwButton from '../shared/TWButton.vue'
export default {
  name: 'ConfirmDialog',
  components: {
    TwButton
  },
  props: {
    title: {
      type: String
    },
    message: {
      type: String
    },
    okBtnText: {
      type: String,
      default: function () {
        return this.$t('buttons.ok')
      }
    },
    cancelBtnText: {
      type: String,
      default: function () {
        return this.$t('buttons.cancel')
      }
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showNo: {
      type: Boolean,
      required: false,
      default: false
    },
    noBtnText: {
      type: String,
      required: false,
      default: function () {
        return this.$t('buttons.no')
      }
    },
    maxWidth: {
      type: String,
      default: null
    },
    isOverlapping: {
      type: Boolean,
      required: false,
      default: false
    },
    dialogCenter: {
      type: Boolean,
      required: false,
      default: true
    },
    permissionsDelete: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      elemId: generateUniqueId()
    }
  },
  methods: {
    open () {
      $('#dlgConfirm' + this.elemId).modal('show')
    },
    onOk () {
      this.$emit('confirmResult', true)
      this.close()
    },
    onNo () {
      this.$emit('confirmResultNo')
      this.close()
    },
    onCancel () {
      this.$emit('confirmResult', false)
      this.close()
    },
    close () {
      $('#dlgConfirm' + this.elemId).modal('hide')
      if (!this.isOverlapping) {
        if ($('body').hasClass('modal-open')) {
          setTimeout(() => {
            $('body').removeClass('modal-open')
          }, 500)
        }
      } else {
        if ($('body').hasClass('modal-open')) {
          setTimeout(() => {
            $('body').addClass('modal-open')
          }, 500)
        }
      }
    }
  }
}
</script>
<style>
:root {
  --secondary-color: #D09433;
}
.modal.confirm .modal-footer .btn {
  color: white;
  background: var(--secondary-color);
}
.modal.confirm .modal-footer .btn.close-btn {
  background: transparent;
  border: 1px solid #DBDBDA;
  color: #495760;
}
.body-message {
  white-space: pre-line;
}
.white-space-pre-wrap {
  white-space: pre-wrap;
}
</style>

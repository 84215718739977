const scanItems = {
  tableHeader: {
    desktop: [
      {
        label: 'Retirado',
        key: 'lostFoundId',
        type: 'retired',
        sortable: 'true'
      },
      {
        label: 'Artículo',
        key: 'itemNumber',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Categoría',
        key: 'category',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Descripción',
        key: 'description',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Fabricante',
        key: 'manufacturer',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Modelo',
        key: 'model',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Asignado a',
        key: 'assignedTo',
        type: String,
        sortable: 'true'
      },
      {
        label: 'En un equipo',
        key: 'isInKit',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Cantidad',
        key: 'quantity',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Estado',
        key: 'itemStatus',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Medidor',
        key: 'meterLevel',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Uso personal',
        key: 'personalIssue',
        type: Boolean,
        sortable: 'true'
      },
      {
        label: 'Dueño',
        key: 'owner',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Tipo',
        key: 'itemType',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Código de barras',
        key: 'barcode',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'N º de Modelo.',
        key: 'masterNumber',
        type: Number,
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: 'Retirado',
        key: 'lostFoundId',
        type: 'retired',
        sortable: 'true'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Artículo',
            key: 'itemNumber',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Categoría',
            key: 'category',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Descripción',
            key: 'description',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Fabricante',
            key: 'manufacturer',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Modelo',
            key: 'model',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Asignado a',
            key: 'assignedTo',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Cantidad',
            key: 'quantity',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Estado',
            key: 'statusCodeNumber',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Medidor',
            key: 'meterLevel',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Uso personal',
            key: 'personalIssue',
            type: Boolean,
            sortable: 'true'
          },
          {
            label: 'Dueño',
            key: 'owner',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Tipo',
            key: 'itemType',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Código de barras',
            key: 'barcode',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'N º de Modelo.',
            key: 'masterNumber',
            type: Number,
            sortable: 'true'
          }
        ]
      }
    ]
  }
}

export default scanItems

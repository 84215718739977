export default {
  state: () => ({
    taskItems: []
  }),
  mutations: {
    addtaskItems (state, allItems) {
      state.taskItems = [...allItems]
    }
  },
  getters: {
    selectTaskItems (state) {
      return state.taskItems
    }
  }
}

const locationMap = {
  title: 'Mapa de ubicaciones',
  placeHolder: 'Descripción de la ubicación de búsqueda',
  locationType: {
    title: {
      0: 'Tipo de ubicacion'
    },
    labels: {
      0: 'Toda',
      1: 'Existencias',
      2: 'Agotada'
    }
  },
  locationPanel: {
    title: {
      0: 'Detalles de ubicación'
    }
  },
  inventorySummary: {
    title: {
      0: 'Resumen de inventario'
    }
  },
  filter: {
    labels: {
      0: 'Ubicaciones',
      1: 'Elementos rastreados por GPS',
      2: 'Ubicaciones de stock',
      3: 'Ubicaciones sin stock',
      4: 'Todas las categorías'
    }
  }
}
export default locationMap

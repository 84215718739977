const templatePickItems = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'Id',
        type: 'check'
      },
      {
        label: 'Type',
        key: 'TemplateType',
        sortable: true
      },
      {
        label: 'Category',
        key: 'Category',
        sortable: true
      },
      {
        label: 'ID',
        key: 'Number',
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Type',
        key: 'TemplateType',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'Category',
            sortable: true
          },
          {
            label: 'ID',
            key: 'Number',
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default templatePickItems

const calcStatus = {
  tableHeader: {
    desktop: [
      {
        label: 'Esta Calculado',
        key: 'calculating',
        type: Boolean,
        sortable: true,
        width: '6.8%'
      },
      {
        label: 'Esta Bloqueado',
        key: 'locking',
        type: Boolean,
        sortable: true,
        width: '7%'
      },
      {
        label: 'Número',
        key: 'costCenterNumber',
        type: String,
        sortable: true,
        width: '8.5%'
      },
      {
        label: 'Centro de costos',
        key: 'costCenter',
        type: String,
        sortable: true,
        width: '10.5%'
      },
      {
        label: 'Bloqueado por',
        key: 'lockedBy',
        type: String,
        sortable: true,
        width: '10%'
      },
      {
        label: 'Bloqueado en',
        key: 'lockedOn',
        type: Date,
        sortable: true,
        width: '10%'
      },
      {
        label: 'Fecha de corte',
        key: 'cutOffDate',
        type: Date,
        sortable: true,
        width: '10.5%'
      },
      {
        label: 'Fecha de cálculo',
        key: 'createdOn',
        type: Date,
        sortable: true,
        width: '10%'
      },
      {
        label: 'Estado (Herramientas)',
        key: 'details',
        id: 'ratesheetTools',
        type: String,
        sortable: true,
        width: '18%'
      },
      {
        label: 'Estado (Materiales)',
        type: String,
        key: 'materialDetails',
        id: 'ratesheetMaterials',
        sortable: true,
        width: '18%'
      }
    ],
    mobile: [
      {
        label: 'Esta Calculado',
        key: 'calculating',
        type: Boolean,
        sortable: true
      },
      {
        label: 'Esta Bloqueado',
        key: 'locking',
        type: Boolean,
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Número',
            key: 'costCenterNumber',
            type: Number,
            sortable: true
          },
          {
            label: 'Centro de costos',
            key: 'costCenter',
            type: String,
            sortable: true
          },
          {
            label: 'Bloqueado por',
            key: 'lockedBy',
            type: String,
            sortable: true
          },
          {
            label: 'Bloqueado en',
            key: 'lockedOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Fecha de corte',
            key: 'cutOffDate',
            type: Date,
            sortable: true
          },
          {
            label: 'Fecha de cálculo',
            key: 'createdOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Estado (Herramientas)',
            key: 'ratesheetTools',
            type: String,
            sortable: true
          },
          {
            label: 'Estado (Materiales)',
            key: 'ratesheetMaterials',
            type: String,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default calcStatus

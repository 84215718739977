const scheduling = {
  cart: {
    title: 'Scheduling Cart',
    desc: 'Items in your scheduling',
    noMsg: 'No Cart',
    dateRange: 'Scheduling Date Range',
    startDate: 'Start Date',
    endDate: 'End Date',
    datesRequired: 'Please select valid Start Date and End Date',
    costCenter: 'Cost Center',
    actionHeader: {
      0: {
        title: 'Collaborate',
        icon: 'fa-regular fa-comment-dots',
        key: 'collaborate',
        isShow: true
      }
    }
  },
  checkout: {
    title: 'Checkout',
    desc: 'Review your checkout',
    dateMessage: 'Return Date should be greater than or equal to Date Needed',
    labels: {
      0: 'Submit to',
      1: 'Location',
      2: 'Date Needed',
      3: 'Comments',
      4: 'Review Items',
      5: 'Return To',
      6: 'Pick Up',
      7: 'Return Date'
    }
  },
  calendar: {
    title: 'Scheduling Calendar',
    itemFound: 'No items| 1 results | {count} results',
    placeholder: {
      0: 'Enter a Model, Description, or Manufacturer',
      1: 'No Models available'
    }
  },
  calendarViewTableHeader: {
    desktop: [
      {
        label: 'Category',
        key: 'Category',
        sortable: true,
        type: String
      },
      {
        label: 'Model',
        key: 'Model',
        sortable: true,
        type: String
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true,
        type: String
      },
      {
        label: 'Manufacturer',
        key: 'Manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Available Qty',
        key: 'UndecidedQty',
        sortable: true,
        type: String
      },
      {
        label: 'Start Date',
        key: 'StartDate',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'End Date',
        key: 'EndDate',
        type: Date,
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: ''
      },
      {
        data: [
          {
            label: 'Category',
            key: 'Category',
            sortable: true,
            type: String
          },
          {
            label: 'Model',
            key: 'Model',
            sortable: true,
            type: String
          },
          {
            label: 'Description',
            key: 'Description',
            sortable: true,
            type: String
          },
          {
            label: 'Manufacturer',
            key: 'Manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Available Qty',
            key: 'UndecidedQty',
            sortable: true,
            type: String
          },
          {
            label: 'Start Date',
            key: 'StartDate',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'End Date',
            key: 'EndDate',
            type: Date,
            sortable: 'true'
          }
        ]
      }
    ]
  },
  formLabels: {
    labels: {
      0: 'Model',
      1: 'Go To Date',
      2: 'Available Quantity'
    }
  }
}
export default scheduling

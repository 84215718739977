const fuelTransactionBrowser = {
  browser: {
    title: 'Navegador de transacciones de combustible',
    costSelection: 'Selección de asignación de tarjetas',
    labels: {
      0: 'estado de la tarjeta de combustible',
      1: 'Fecha de Transacción',
      2: 'De:',
      3: 'A'
    },

    filter: {
      0: 'Todos',
      1: 'activo',
      2: 'Inactivo'
    },
    actionHeader: {
      0: {
        title: 'Ver detalles',
        icon: 'fas fa-edit'
      },
      1: {
        title: 'Transacciones de importación',
        icon: 'fas fa-file-export'
      }
    },
    lineAction: {
      0: {
        title: 'Ver Detalle'
      },
      1: {
        title: 'Colocar en espera'
      },
      2: {
        title: 'Remover Espera'
      },
      3: {
        title: 'Crear Cargos/Creditos Relacionados'
      },
      4: {
        title: 'Editar Codigo de Costos'
      }
    },
    entryType: {
      title: 'Mostrar Entradas Para',
      labels: {
        0: 'Herramientas & Equipos',
        1: 'Materiales & Consumibles'
      }
    },
    calculation: {
      title: 'Calculo de Centro de Costos',
      dialog: {
        message: 'Usted ha seleccionado una fecha de corte en el futuro, esta seguro que desea continuar?'
      },
      labels: {
        0: 'Seleccione la fecha y hora de corte',
        1: 'Solo los centro de costos con tarifarios assignados pueden ser calculados.'
      }
    },
    post: {
      title: 'Publicar',
      labels: {
        0: 'Seleccionar Centro de Costos A Publicar',
        1: 'Listo para Publicar'
      }
    },
    buttons: {
      0: 'Ver Detalle',
      1: 'Espera',
      2: 'Remover Espera'
    },
    TransactionsAmount: {
      0: 'Total de transacciones para las Fechas Seleccionadas'
    },
    miscCharge: {
      title: 'Misc. Cargos/Creditos',
      costCodeOptions: {
        0: 'Centro de Costos Especificos',
        1: 'Global'
      },
      dialogLabels: {
        0: 'GL Cuenta de Cargos ',
        1: 'GL Cuenta de Creditos',
        2: 'Scanne o ingrese numero de articulo',
        3: 'Ingresar',
        4: 'Numero de articulo',
        5: 'Descripcion',
        6: 'Cargos',
        7: 'Credito',
        8: 'Total',
        9: 'Misc. Fecha de Cargos/Creditos',
        10: 'Facturacion',
        11: 'Costos',
        12: 'Seleccionar una Categoria',
        13: 'Selecciona articulo',
        14: 'Articulo Desconocido',
        15: 'Este Cargo/Credito se relaciona a transaccion:',
        16: 'Articulo',
        17: 'Seleccionar Modelo',
        18: 'Aplicar Cargos Maximos'
      }
    },
    editCostCode: {
      title: 'Editar Codigo de Costos',
      label: {
        0: 'Actualizar linea de Transferencia en la Transferencia',
        1: 'Articulo'
      }
    },
    transactionDetail: {
      title: 'Detalle de la Trasnsaccion',
      tags: {
        0: 'Informacion de Centro de Costos',
        1: 'Informacion del Articulo',
        2: 'Cargos',
        3: 'Informacion de la Transaccion',
        4: 'Informacion de Uso y Tarifa Efectiva'
      },
      labels: {
        0: 'Centro de Costos',
        1: 'Codigo de Costos',
        2: 'Nombre de Hoja de Tarifas',
        3: 'Tipo de Hojas de Tarifas',
        4: 'ID de Hojas de Tarifas',
        5: 'Calculado en',
        6: 'Fecha de Corte',
        7: 'Fecha Final',
        8: 'Tipo de Articulo',
        9: 'Categoria',
        10: 'No. de Articulo',
        11: 'Descripcion',
        12: 'Metodo de Calculo del Articulo',
        13: 'Tipo de Cargo',
        14: 'Cant.',
        15: 'Cada Unidad',
        16: 'Total Extendido',
        17: 'Tipo',
        18: 'Fecha de Inicio',
        19: 'ID',
        20: 'Desde',
        21: 'A',
        22: 'Duracion Efectiva',
        23: 'Meses',
        24: 'Semanas',
        25: 'Dias',
        26: 'Horas',
        27: 'Horas Potenciales',
        28: 'Horas Cobrables',
        29: 'Nivel Tarifa del Articulo',
        30: 'Tarifa Efectiva del Articulo / Hr.'
      }
    }
  },
  ratesheets: {
    title: 'Navegador de Hojas de Tarifas',
    filter: {
      0: 'Tarifas de Herramientas & Equipos',
      1: 'Tarifas de Materiales & Consumibles'
    },
    actionHeader: {
      0: {
        title: 'Ver Hoja de Tarifa',
        icon: 'fas fa-user-edit'
      },
      1: {
        title: 'Agregar Hoja de Tarifas Herramients & Equipos',
        icon: 'fas fa-plus'
      },
      2: {
        title: 'Agregar Hoja de Tarifa de Materiales y Consumibles',
        icon: 'fas fa-plus'
      },
      3: {
        title: 'Copiar Hoja de Tarifa',
        icon: 'fas fa-copy'
      },
      4: {
        title: 'Borrar',
        icon: 'fas fa-trash'
      }
    }
  },
  rateSheetView: {
    title: 'Hoja de Tarifa',
    actionHeader: {
      0: {
        title: 'Salvar',
        tooltip: 'Save',
        icon: 'far fa-save',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      1: {
        title: 'Salvar y Cerrar',
        tooltip: 'Save And Close',
        icon: 'far fa-save',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      2: {
        title: 'Agregar Cargo Relacionado',
        tooltip: 'Add Related Charge',
        icon: 'fas fa-plus',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      3: {
        title: 'Borrar Cargo Relacionado',
        tooltip: 'Delete Related Charge',
        icon: 'fas fa-trash',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      4: {
        title: 'Exportar Datos',
        tooltip: 'Export Data in CSV Format',
        icon: 'fas fa-file-export',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      5: {
        title: 'Publicar a Excel',
        tooltip: 'Publish to MS Excel for Editing',
        icon: 'far fa-file-excel',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      },
      6: {
        title: 'Importar desde Excel',
        tooltip: 'Import MS Excel Workbook',
        icon: 'fas fa-file-import',
        permissions: ['RateSheetsEdit'],
        entitlements: ['JC_And_B']
      }
    },
    sheetHeader: {
      0: 'Numero de Hoja de Tarifa',
      1: 'Descripcion',
      2: 'Determinar Normas de Hoja de Tarifa'
    },
    importSheet: {
      title: 'Importar Hoja de Tarifa desde Excel',
      message: {
        0: 'La solicitud de importar el archivo no puede ser cargado por que el ID de la Hoja de Tarifa \n del archivo no es igual al ID de la Hoja de tarifa actual.',
        1: 'Fallo al cargar o Excel Invalido.',
        2: 'Importe Hoja de Tarifa desde Excel Completado.'
      }
    },
    exportSheet: {
      title: 'Exportar Hoja de Tarifa',
      message: 'Selecciona que Hoja de Tarifa a Exportar',
      labels: {
        0: 'Ambas',
        1: 'Costos',
        2: 'Facturacion'
      }
    },
    sheetTable: {
      message: 'Uso por hora no puede ser usado en activos Rastreados por Cantidad'
    },
    sheetRuleDlg: {
      title: 'Normas de Hojas de Tarifas',
      labels: {
        0: 'Establecer las horas de cada periodo para esta Hoja de Tarifa.',
        1: 'Establecer los puntos de migracion para esta Hoja de Tarifa.',
        2: 'Horas en el dia',
        3: 'Horas en la Semanas',
        4: 'Horas en el Mes',
        5: 'Migracion de la Tasa Diaria',
        6: 'Migracion de la Tasa Semanal',
        7: 'Migracion de la Tasa Mensual'
      }
    }
  },
  exportTemplates: {
    title: 'Exportar Listado de Plantilla',
    placeholder: 'Busqueda de Plantilla',
    labels: {
      0: 'Todas',
      1: 'Resumen de Transaccion',
      2: 'Detalle de Transaccio',
      3: 'Nombre',
      4: 'Registro Delimitado',
      5: 'Formato del Registro',
      6: 'Campos Dispponibles',
      7: 'Primera Linea A Exportar (Cargo)',
      8: 'Segunda Linea A Exportar (Credito)',
      9: 'Diseño de Exportacion',
      10: 'Agregar a Exportacion',
      11: 'Agregar a Primera Linea',
      12: 'Agreagar a Segunda Linea',
      13: 'Editar Valores de Campos a Exportar',
      14: 'Configuracion de Valores de Campos'
    },
    recordDelimiter: [
      {
        value: 1,
        description: 'Pestaña'
      },
      {
        value: 2,
        description: 'Comma'
      },
      {
        value: 3,
        description: 'Guion'
      },
      {
        value: 4,
        description: 'Mas'
      }
    ],
    recordFormat: [
      {
        value: 1,
        description: 'Costos (sola linea)'
      },
      {
        value: 2,
        description: 'Contabilidad (doble Linea)'
      }
    ],
    availableFieldsHeader: [
      'Nombre de Campo', 'Tip', 'Len', 'Notas'
    ],
    fieldValueTabs: [
      'Registros Predeterminados',
      'Herramientas Assignadas',
      'Calendario de Herramientas',
      'Herramientas de Horario',
      'Consumibles',
      'Servicios'
    ],
    fieldValueTabLabels: {
      0: 'Encabezado de Registros',
      1: 'Referencia 1',
      2: 'Referencia 2',
      3: 'Fin del Registro Marcado',
      4: 'Tipo de Costo',
      5: 'Tipo Transaccion',
      6: 'Descripcion de Exportacion'
    },
    validation: {
      templateNameRequired: 'Template Name Required',
      delimiterRequired: 'Delimiter Type Required'
    }
  },
  equipmentUsageLog: {
    title: 'Registro de uso del equipo',
    addItemBrowserTitle: 'Tool Browser',
    actionHeader: {
      0: {
        title: 'Salvar',
        tooltip: 'Save',
        icon: 'fas fa-save'
      },
      1: {
        title: 'Agregar Articulo',
        tooltip: 'Add Item',
        icon: 'fas fa-plus'
      }
    },
    scanLabel: 'Ingresar Numero de Articulo',
    dialogLabels: {
      0: 'Seleccionar Si para borrar la linea o No para salir.',
      1: 'Borrar Linea'
    },
    warningMsg: 'Campos Requerido no estan completos'
  },
  exportData: {
    title: 'Data Export',
    labels: {
      0: 'Exportar Origen',
      1: 'Transacciones Publicadas',
      2: 'Transacciones No-Publicadas',
      3: 'Datos de Costos',
      4: 'Datos de Facturacion',
      5: 'Datos de Costos y Facturacion',
      6: 'Formato de Datos',
      7: 'Resumen de Plantilla',
      8: 'Detalle de Plantilla',
      9: 'Exportar Plantilla',
      10: 'Incluir los topes de las columnas',
      11: 'Parametros de las Fechas',
      12: 'Rango de Fechas a Publicar'
    },
    warnLabels: {
      0: 'Por Favor revisar al menos 1 articulo del centro de costos.',
      1: 'Plantilla a exportar no puede estar vacia. Por Favor Seleccionar una Plantilla.'
    }
  },
  InvoiceBrowser: {
    title: 'Navegador de Facturas',
    actionHeader: {
      0: {
        title: 'Generar Facturas',
        tooltip: 'Generate Invoices',
        icon: 'fas fa-usd-circle'
      },
      1: {
        title: 'Imprimir Facturas',
        tooltip: 'Print Invoices',
        icon: 'fas fa-print'
      },
      2: {
        title: 'Imprimir Facturas con Tarifas',
        tooltip: 'Print Invoices with Rates',
        icon: 'fas fa-print'
      }
    },
    printTableHeader: [
      {
        label: 'Cant.',
        key: 'Qty'
      },
      {
        label: 'Descripcion',
        key: 'Description'
      },
      {
        label: 'Tipo de transaccion',
        key: 'TransactionType'
      },
      {
        label: 'Tipo de Cargo',
        key: 'ChargeType'
      },
      {
        label: 'Horas',
        key: 'Hours'
      },
      {
        label: 'Tarifa Efectiva/Hr',
        key: 'EffectiveRate'
      },
      {
        label: 'Precio por Unidad',
        key: 'UnitPrice'
      },
      {
        label: 'Total',
        key: 'Total'
      }
    ],
    labels: {
      0: 'Tarifa Diaria',
      1: 'Tarifa Semanal',
      2: 'Tarifa Semanal',
      3: 'Numero de Factura',
      4: 'Fecha de Factura',
      5: 'Periodo de Facturacion',
      6: 'Pago Pendiente',
      7: 'Terminos',
      8: 'Centro de Costos',
      9: 'Centro de Contactos',
      10: 'Telefono del Contacto',
      11: 'Numero de Searie',
      12: 'Assignacion',
      13: 'Fecha de inicio',
      14: 'Fecha Final',
      15: 'Cargo de Renta',
      16: 'Impuestos de Venta {salesTax}%',
      17: 'Neto debido al recibo',
      18: 'Por Favor Envie a:',
      19: 'Instrucciones Especiales:',
      20: 'Factura No.',
      21: 'Centro de Costos',
      22: 'Fecha de Factura',
      23: 'Buscar Facturas'
    },
    warningDialog: {
      title: 'Imprimir Facturas con Tarifas',
      content: {
        0: '{cntWithNoRates} de las Facturas Seleccionadas no tienen dotos de las tarifas. Desea Continuar?',
        1: 'Solo las facturas generadas despues de Oct 1, 2020 incluiran tarifas para claculoas a ser publicados.'
      }
    },
    noInvoicesSelected: 'No invoices selected'
  },
  InvoiceBrowserGenerate: {
    title: 'Generar Facturas',
    labels: {
      0: 'Rango de Fecha',
      1: 'A',
      2: 'Publicado',
      3: 'No-Publicado',
      4: 'Incluir Cargos de Herramientas retiradas y cargos de perdidas',
      5: 'Tipo de Cargo',
      6: 'Costos',
      7: 'Facturacion',
      8: 'Centro de Costos',
      9: 'Fecha de Factura',
      10: 'Pago Pendiente',
      11: 'Remesas:'
    },
    warningDialog: {
      title: 'Advertencia',
      content: {
        0: 'Centro de Costos seleccionado no pueden estar en blanco.',
        1: 'Fecha de Pagos Pendiente no pueden estar en blanco.'
      }
    }
  },
  importMeter: {
    title: 'Import Meter'
  },
  Map: {
    link: 'Map'
  }
}
export default fuelTransactionBrowser

const reservationsByReservation = {
  tableHeader: {
    desktop: [
      {
        label: 'Status',
        key: 'Status',
        sortable: 'true'
      },
      {
        label: 'Submit Date',
        key: 'CreatedOn',
        sortable: 'true'
      },
      {
        label: 'Start Date',
        key: 'StartDate',
        sortable: 'true'
      },
      {
        label: 'End Date',
        key: 'EndDate',
        sortable: 'true'
      },
      {
        label: 'Request',
        key: 'Number',
        sortable: 'true'
      },
      {
        label: 'By',
        key: 'RequestedByName',
        sortable: 'true'
      },
      {
        label: 'For',
        key: 'DeliverToEntityName',
        sortable: 'true'
      },
      {
        label: 'Cost Center',
        key: 'CostCenterName',
        sortable: 'true'
      },
      {
        label: 'Cost Code',
        key: 'CostCodeName',
        sortable: 'true'
      },
      {
        label: 'Items',
        key: 'QtyRequested',
        sortable: 'true'
      }
    ],
    mobile: ['Status', 'Info']
  }
}

export default reservationsByReservation

const meter = {
  tableHeader: {
    desktop: [
      {
        label: 'Número de artículo',
        key: 'ItemNumber',
        sortable: true,
        isEditable: true
      },
      {
        label: 'Modelo',
        key: 'Model',
        sortable: true,
        isEditable: true
      },
      {
        label: 'Descripción',
        key: 'Description',
        sortable: true,
        isEditable: true
      },
      {
        label: 'Medidor Actual',
        key: 'CurrentMeterLevel',
        sortable: true,
        isEditable: true
      },
      {
        label: 'Nuevo medidor',
        key: 'MeterLevel',
        sortable: true,
        isEditable: true
      },
      {
        label: 'Diferencia',
        key: 'MeterLevelDelta',
        sortable: true,
        isEditable: true
      },
      {
        label: 'Fecha',
        key: 'CreatedOn',
        type: Date,
        sortable: true,
        isEditable: true
      },
      {
        label: 'Estado',
        key: 'ImportStatus',
        sortable: true,
        isEditable: false
      }
    ],
    mobile: [
      {
        label: 'Número de artículo',
        key: 'ItemNumber'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Modelo',
            key: 'Model',
            sortable: true,
            isEditable: true
          },
          {
            label: 'Descripción',
            key: 'Description',
            sortable: true,
            isEditable: true
          },
          {
            label: 'Medidor Actual',
            key: 'CurrentMeterLevel',
            sortable: true,
            isEditable: true
          },
          {
            label: 'Nuevo medidor',
            key: 'MeterLevel',
            sortable: true,
            isEditable: true
          },
          {
            label: 'Diferencia',
            key: 'MeterLevelDelta',
            sortable: true,
            isEditable: true
          },
          {
            label: 'Fecha',
            key: 'CreatedOn',
            type: Date,
            sortable: true,
            isEditable: true
          },
          {
            label: 'Estado',
            key: 'ImportStatus',
            sortable: true,
            isEditable: false
          }
        ]
      }
    ]
  }
}

export default meter

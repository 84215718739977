const buttons = {
  cancel: 'Cancel',
  save: {
    change: 'Save Changes',
    setting: 'Save Settings',
    default: 'Save',
    taskComplete: 'Save. Task Complete'
  },
  cross: 'cancel',
  add: 'Add',
  change: 'Change',
  remove: 'Remove',
  create: 'Create New',
  close: 'Close',
  saveClose: 'Save and Close',
  export: 'Export',
  done: 'Done',
  edit: 'Edit',
  view: 'View',
  viewScheduleReason: 'Schedule Reason',
  viewTicket: 'View Ticket',
  yes: 'Yes',
  no: 'No',
  ok: 'OK',
  delete: 'Delete',
  deleteSelected: 'Delete Selected',
  logout: 'Log out',
  addNext: 'Add and Next',
  addItem: 'Add Item',
  addLine: 'Add Line',
  addModel: 'Add Models',
  addStock: 'Add Stock',
  stopStock: 'Stop Stocking',
  startStock: 'Start Stocking',
  stocking: 'Stocking',
  unstocking: 'Unstocking',
  addtoInventory: 'Add Another to your inventory?',
  startTracking: 'Start Tracking',
  back: {
    results: 'Back to Results',
    cart: 'Back to Cart',
    default: 'Back',
    reservation: 'Back to Scheduling'
  },
  stockPosition: 'Stock at {company}',
  itemText: 'No Item associated with this product | Item associated with this product | Items associated with this product',
  attachFiles: 'Attach files',
  more: 'More',
  viewRequest: 'VIEW THIS REQUEST',
  viewReservation: 'VIEW THIS RESERVATION',
  viewReport: 'View Report',
  details: 'Details',
  requestAgain: 'Request Again',
  reserveAgain: 'Schedule Again',
  addtoCart: 'Add to Cart',
  addtoReservationCart: 'Add to Scheduling',
  addtoTicket: 'Add to Ticket',
  createReport: 'Create Report',
  createFolder: 'Create Folder',
  exportReport: 'Export Report',
  createTools: 'Create Tools',
  removeItem: 'Remove Item',
  imagenotAvailable: 'Image Not Available',
  noMonthlyRateSpecified: 'No Monthly rate specified',
  noRateSpecified: 'No rate specified',
  perMonth: ' per month',
  each: ' ea.',
  perAssignment: ' per assignment',
  checkout: 'Checkout',
  requestSummary: 'Request Summary',
  totalItems: 'Total Items',
  submit: {
    request: 'Submit Request',
    ticket: 'Submit Ticket',
    cancel: 'Cancel Ticket',
    reservation: 'Submit Scheduling'
  },
  uploadLogo: 'Upload Logo',
  attachment: {
    0: 'Add',
    1: 'View',
    2: 'Replace',
    3: 'Rename',
    4: 'Delete',
    5: 'Save As',
    6: 'Add Attachment',
    7: 'Replace Attachment',
    8: 'Import from',
    9: 'Rename Attachment'
  },
  notification: {
    0: 'Notifications',
    1: 'Only show unread',
    2: 'Mark all as read'
  },
  share: 'Share Folder',
  merge: 'Merge',
  refresh: 'Refresh',
  calculate: 'Calculate',
  post: 'Post',
  logo: 'ToolWatch',
  warning: 'Warning',
  deactivateBtn: 'Deactivate User Account',
  manageUserAcnt: 'Manage User Account',
  createUserAcnt: 'Create User Account',
  modifyVis: 'Modify Visibility',
  processTransfer: 'Process Transfer',
  backToTransfer: 'Back to Transfer',
  backToPick: 'Back to Pick Ticket',
  backToKitRecord: 'Back to Kit Detail',
  set: 'Set',
  submitBtn: 'Submit',
  generate: 'Generate',
  summary: 'Summary',
  download: 'Download',
  browse: 'Browse',
  import: 'Import',
  search: 'Search',
  clear: 'Clear',
  count: 'Count',
  error: 'Error',
  note: 'Note',
  viewProjectCalendar: 'View Project Calendar...',
  twButtonTooltip: 'You are not authorized to {buttonText}. This action requires the role permission {permission}',
  permissionTooltip: 'You are not authorized to {title}. This action requires {requirements}',
  panelNotification: 'You are not authorized to {section}. This action requires',
  rolePermission: 'the role permission {permission}',
  roleEntitlement: 'the entitlement {entitlement}',
  addSource: 'Add Source',
  includeKitContents: 'Explode Kits',
  expandedView: 'Expanded View',
  restore: 'Restore',
  perform: 'Perform',
  addTask: 'Add Task',
  addTool: 'Add Tool',
  addPart: 'Add Part',
  skipUpdate: 'Skip Update',
  addContract: 'Add Contract',
  editContract: 'Edit Contract',
  addEventFromModal: 'Add Event From Model',
  confirmSave: 'Confirm Save',
  confirmDeletion: 'Confirm Deletion',
  transferKit: 'Transfer Kit',
  reinstateTools: 'Reinstate Tools',
  options: 'Options',
  stock: 'Stock',
  manageAttachments: 'Manage Attachments',
  removeRecurrence: 'Remove Recurrence',
  forgotPassword: 'Forgot Password?',
  continue: 'Continue?',
  gpsLocation: 'GPS Location',
  refreshMap: 'Refresh',
  downloadTemplate: 'Download Import Template',
  upload: 'Upload',
  move: 'Move',
  settings: 'Settings',
  reassign: 'Reassign',
  AddSteps: 'Add Step',
  pasteText: 'Ctrl+V to Paste Image',
  HelpandSupport: 'Help & Support',
  giveUsCall: 'Give us a call',
  giveUsCallTxt: 'We would love to answer any questions you may have. Give us a call at (800) 580-3114',
  chat: 'Chat',
  chatText: 'Connect with one of our amazing online customer service representives.',
  documentation: 'Documentation',
  documentationText: 'Search our extensive library of online resources and support materials.',
  supportTicket: 'Support Ticket',
  supportTicketText: 'Fill  out a quick online form and we will get back to you as soon as possible.'
}

export default buttons

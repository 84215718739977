import store from '../store/index'
export const useLocalUserStorage = () => {
  const userName = store.getters.userName
  if (!userName || userName.length === 0) {
    throw new Error('Username is required for localStorage composable.')
  }

  function saveData (key: string, value: any) {
    // local storage only allows strings
    localStorage.setItem(userName + '::' + key, JSON.stringify(value))
  }

  function getData (key: string) {
    const stringified = localStorage.getItem(userName + '::' + key)

    if (stringified) {
      return JSON.parse(stringified)
    }

    return null
  }

  function removeData (key: string) {
    localStorage.removeItem(userName + '::' + key)
  }

  // return the result out of the composable so callers can access it
  return {
    saveData,
    getData,
    removeData
  }
}

const dataLoader = {
  tableHeader: {
    desktop: [
      {
        label: 'Created',
        key: 'RunDate',
        type: Date,
        sortable: true
      },
      {
        label: 'Action',
        key: 'RunType',
        sortable: true,
        type: String
      },
      {
        label: 'Object',
        key: 'ObjectType',
        sortable: true,
        type: String
      },
      {
        label: 'Processed',
        key: 'OriginalRowsCount',
        sortable: true,
        type: Number
      },
      {
        label: 'Succeeded',
        key: 'SuccessRowsCount',
        sortable: true,
        type: Number
      },
      {
        label: 'Errors',
        key: 'ErrorRowsCount',
        sortable: true,
        type: Number
      },
      {
        label: 'Original File',
        key: 'OriginalFilePath',
        sortable: true,
        type: String
      },
      {
        label: 'Success File',
        key: 'SuccessFilePath',
        sortable: true,
        type: String
      },
      {
        label: 'Error File',
        key: 'ErrorFilePath',
        sortable: true,
        type: String
      },
      {
        label: 'Result Message',
        key: 'ResultMessage',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: 'check',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Created',
            key: 'RunDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Action',
            key: 'RunType',
            sortable: true,
            type: String
          },
          {
            label: 'Object',
            key: 'ObjectType',
            sortable: true,
            type: String
          },
          {
            label: 'Processed',
            key: 'OriginalRowsCount',
            sortable: true,
            type: Number
          },
          {
            label: 'Succeeded',
            key: 'SuccessRowsCount',
            sortable: true,
            type: Number
          },
          {
            label: 'Errors',
            key: 'ErrorRowsCount',
            sortable: true,
            type: Number
          },
          {
            label: 'Result Message',
            key: 'ResultMessage',
            sortable: true,
            type: String
          }
        ]
      },
      {
        label: 'Download',
        isModify: false,
        data: [
          {
            label: 'Original File',
            key: 'OriginalFilePath',
            sortable: true,
            type: String
          },
          {
            label: 'Success File',
            key: 'SuccessFilePath',
            sortable: true,
            type: String
          },
          {
            label: 'Error File',
            key: 'ErrorFilePath',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  }
}

export default dataLoader

const purchasing = {
  purchaseOrders: {
    title: 'Purchase Order Browser',
    actionHeader: {
      0: {
        title: 'Create Purchase Order',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Receive Purchase Order',
        icon: 'fas fa-shopping-basket'
      },
      2: {
        title: 'Print',
        icon: 'fas fa-print'
      }
    },
    switcher: {
      switcherText: 'Purchase Order / Item View'
    },
    purchaseOrdersActions: [
      {
        title: 'View',
        actionName: 'onView',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Delete ',
        actionName: 'onDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    itemViewBrowserLineActions: [
      {
        title: 'View',
        actionName: 'onView',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Delete',
        actionName: 'onItemDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Export',
        actionName: 'onExport',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    labels: {
      browsePO: 'Browse Purchase Orders',
      poNumber: 'PO Number',
      orderDate: 'Order Date',
      to: 'to',
      vendor: 'Vendor',
      shipTo: 'Ship To',
      status: 'Status',
      open: 'Open',
      closed: 'Closed',
      all: 'All',
      moreSearchOption: 'More search options...',
      fewerSearchOption: 'Fewer search options...',
      chargeTo: 'Charge To',
      requisitionNo: 'Requisition No',
      documentNo: 'Document No',
      expectedDate: 'Expected Date',
      unableToReceive: 'Unable to Receive',
      waitingReceipts: 'This PO is not yet waiting for receipts',
      confirmReceive: 'This PO is ready to be received. Would you like to receive against this PO? Yes or No',
      unableToDeleteItem: 'Unable to Delete',
      warningDeleteItemLess: 'Some selected items cannot be deleted as it would result in one or more POs having zero items. Please change your selection to delete',
      warningDeleteItem: 'Items can only be deleted from draft PO(s). Please change your selection'
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'OrderId',
          type: 'check'
        },
        {
          label: 'Order Date',
          key: 'OrderDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Po No.',
          key: 'OrderNumber',
          sortable: 'true'
        },
        {
          label: 'Vendor',
          key: 'VendorName',
          sortable: 'true'
        },
        {
          label: 'Charge To',
          key: 'CostCenterDescription',
          sortable: 'true'
        },
        {
          label: 'Ship To',
          key: 'ShipToEntityDescription',
          sortable: 'true'
        },
        {
          label: 'Status',
          key: 'Status',
          sortable: 'true'
        },
        {
          label: 'State',
          key: 'State',
          sortable: 'true'
        },
        {
          label: 'Expected',
          key: 'ExpectedDeliveryDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Requisition',
          key: 'Requisition',
          sortable: 'true'
        },
        {
          label: 'Document',
          key: 'DocumentNumber',
          sortable: 'true'
        },
        {
          label: ' ',
          key: 'action',
          isEditable: true
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Order Date',
              key: 'OrderDate',
              sortable: 'true'
            },
            {
              label: 'Po No.',
              key: 'OrderNumber',
              sortable: 'true'
            },
            {
              label: 'Vendor',
              key: 'VendorName',
              sortable: 'true'
            },
            {
              label: 'Charge To',
              key: 'CostCenterDescription',
              sortable: 'true'
            },
            {
              label: 'Ship To',
              key: 'ShipToEntityDescription',
              sortable: 'true'
            },
            {
              label: 'Status',
              key: 'Status',
              sortable: 'true'
            },
            {
              label: 'State',
              key: 'State',
              sortable: 'true'
            },
            {
              label: 'Expected',
              key: 'ExpectedDeliveryDate',
              sortable: 'true'
            },
            {
              label: 'Requisition',
              key: 'Requisition',
              sortable: 'true'
            },
            {
              label: 'Document',
              key: 'DocumentNumber',
              sortable: 'true'
            }
          ]
        },
        {
          label: ' ',
          key: 'action',
          isEditable: true
        }
      ]
    },
    tableHeaderFlagDisabled: {
      desktop: [
        {
          label: 'Order Date',
          key: 'OrderDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Po No.',
          key: 'OrderNumber',
          sortable: 'true'
        },
        {
          label: 'Vendor',
          key: 'VendorName',
          sortable: 'true'
        },
        {
          label: 'Charge To',
          key: 'CostCenterDescription',
          sortable: 'true'
        },
        {
          label: 'Ship To',
          key: 'ShipToEntityDescription',
          sortable: 'true'
        },
        {
          label: 'Status',
          key: 'Status',
          sortable: 'true'
        },
        {
          label: 'State',
          key: 'State',
          sortable: 'true'
        },
        {
          label: 'Expected',
          key: 'ExpectedDeliveryDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Requisition',
          key: 'Requisition',
          sortable: 'true'
        },
        {
          label: 'Document',
          key: 'DocumentNumber',
          sortable: 'true'
        },
        {
          label: ' ',
          key: 'action',
          isEditable: true
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Order Date',
              key: 'OrderDate',
              sortable: 'true'
            },
            {
              label: 'Po No.',
              key: 'OrderNumber',
              sortable: 'true'
            },
            {
              label: 'Vendor',
              key: 'VendorName',
              sortable: 'true'
            },
            {
              label: 'Charge To',
              key: 'CostCenterDescription',
              sortable: 'true'
            },
            {
              label: 'Ship To',
              key: 'ShipToEntityDescription',
              sortable: 'true'
            },
            {
              label: 'Status',
              key: 'Status',
              sortable: 'true'
            },
            {
              label: 'State',
              key: 'State',
              sortable: 'true'
            },
            {
              label: 'Expected',
              key: 'ExpectedDeliveryDate',
              sortable: 'true'
            },
            {
              label: 'Requisition',
              key: 'Requisition',
              sortable: 'true'
            },
            {
              label: 'Document',
              key: 'DocumentNumber',
              sortable: 'true'
            }
          ]
        },
        {
          label: ' ',
          key: 'action',
          isEditable: true
        }
      ]
    },
    itemViewTableHeader: {
      desktop: [
        {
          label: '',
          key: 'LineId',
          type: 'check'
        },
        {
          label: 'PO Number',
          key: 'PONumber',
          sortable: 'true'
        },
        {
          label: 'Model',
          key: 'Model',
          sortable: 'true'
        },
        {
          label: 'Description',
          key: 'Description',
          sortable: 'true'
        },
        {
          label: 'Category',
          key: 'Category',
          sortable: 'true'
        },
        {
          label: 'Manufacturer',
          key: 'Manufacturer',
          sortable: 'true'
        },
        {
          label: 'Qty',
          key: 'Qty',
          sortable: 'true'
        },
        {
          label: 'Cost',
          key: 'Cost',
          sortable: 'true'
        },
        {
          label: 'Ext. Cost',
          key: 'ExtCost',
          sortable: 'true'
        },
        {
          label: 'Vendor',
          key: 'Vendor',
          sortable: 'true'
        },
        {
          label: 'Status',
          key: 'Status',
          sortable: 'true'
        },
        {
          label: 'Order Date',
          key: 'OrderDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Expected Date',
          key: 'ExpectedDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Ship To',
          key: 'ShipTo',
          sortable: 'true'
        },
        {
          label: 'Charge To',
          key: 'ChargeTo',
          sortable: 'true'
        },
        {
          label: 'Document Number',
          key: 'DocumentNumber',
          sortable: 'true'
        },
        {
          label: 'Requisition Number',
          key: 'RequisitionNumber',
          sortable: 'true'
        },
        {
          label: ' ',
          key: 'actionItemView',
          isEditable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'LineId',
          type: 'check'
        },
        {
          data: [
            {
              label: 'PO Number',
              key: 'PONumber',
              sortable: 'true'
            },
            {
              label: 'Model',
              key: 'Model',
              sortable: 'true'
            },
            {
              label: 'Description',
              key: 'Description',
              sortable: 'true'
            },
            {
              label: 'Category',
              key: 'Category',
              sortable: 'true'
            },
            {
              label: 'Manufacturer',
              key: 'Manufacturer',
              sortable: 'true'
            },
            {
              label: 'Qty',
              key: 'Qty',
              sortable: 'true'
            },
            {
              label: 'Cost',
              key: 'Cost',
              sortable: 'true'
            },
            {
              label: 'Ext. Cost',
              key: 'ExtCost',
              sortable: 'true'
            },
            {
              label: 'Vendor',
              key: 'Vendor',
              sortable: 'true'
            },
            {
              label: 'Status',
              key: 'Status',
              sortable: 'true'
            },
            {
              label: 'Order Date',
              key: 'OrderDate',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Expected Date',
              key: 'ExpectedDate',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Ship To',
              key: 'ShipTo',
              sortable: 'true'
            },
            {
              label: 'Charge To',
              key: 'ChargeTo',
              sortable: 'true'
            },
            {
              label: 'Document Number',
              key: 'DocumentNumber',
              sortable: 'true'
            },
            {
              label: 'Requisition Number',
              key: 'RequisitionNumber',
              sortable: 'true'
            }
          ]
        },
        {
          label: ' ',
          key: 'actionItemView',
          isEditable: true
        }
      ]
    }
  },
  PODetail: {
    title: 'PO Detail',
    POStatus: {
      0: 'Open',
      1: 'Closed',
      2: 'Draft',
      3: 'Waiting for Receipts',
      4: 'Done Receiving',
      5: 'Complete',
      6: 'Incomplete',
      7: 'Cancelled'
    },
    actionHeader: {
      save: {
        title: 'Save',
        icon: 'fas fa-save'
      },
      print: {
        title: 'Print',
        icon: 'fas fa-print'
      },
      addTemplate: {
        title: 'Add Template',
        icon: 'fas fa-plus'
      },
      addToolRecord: {
        title: 'Add Tool Record',
        icon: 'fas fa-plus'
      },
      addMaterialRecord: {
        title: 'Add Material Record',
        icon: 'fas fa-plus'
      },
      attachments: {
        title: 'Attachments',
        icon: 'fas fa-paperclip'
      },
      addItem: {
        title: 'Add Item',
        icon: 'fas fa-plus'
      },
      deleteItem: {
        title: 'Delete Item',
        icon: 'fas fa-x'
      },
      clearPODraft: {
        title: 'Clear Draft',
        icon: 'fa fa-trash'
      }
    },
    actionHeaderCreate: {
      save: {
        title: 'Save',
        icon: 'fas fa-save'
      },
      addTemplate: {
        title: 'Add Template',
        icon: 'fas fa-plus'
      },
      addToolRecord: {
        title: 'Add Tool Record',
        icon: 'fas fa-plus'
      },
      addMaterialRecord: {
        title: 'Add Material Record',
        icon: 'fas fa-plus'
      },
      attachments: {
        title: 'Attachments',
        icon: 'fas fa-paperclip'
      },
      addItem: {
        title: 'Add Item',
        icon: 'fas fa-plus'
      },
      deleteItem: {
        title: 'Delete Item',
        icon: 'fas fa-x'
      },
      clearPODraft: {
        title: 'Clear Draft',
        icon: 'fa fa-trash'
      }
    },
    labels: {
      po: 'Purchase Order',
      poSummary: 'PO Detail Summary',
      vendor: 'Vendor',
      shipTo: 'Ship To',
      chargeToNotes: 'Charge To Notes',
      costCenter: 'Cost Center',
      costCode: 'Cost Code',
      orderedBy: 'Ordered By',
      requisition: 'Requisition',
      shipVia: 'Ship Via',
      orderDate: 'Order Date',
      expectedDate: 'Expected Date',
      documentNo: 'Document No',
      items: 'Items',
      subTotal: 'Sub Total',
      shipping: 'Shipping',
      tax: 'Tax',
      total: 'Total',
      comments: 'Comments',
      recordInformation: 'Record Information',
      createdOn: 'Created On',
      createdBy: 'Created By',
      modifiedOn: 'Modified On',
      modifiedBy: 'Modified By',
      status: 'Status',
      lastReceivedOn: 'Last Received On',
      lastReceivedBy: 'Last Received By',
      onlyItemForVendor: 'Only items for this vendor',
      allItems: 'All items',
      poTicket: 'Purchase Order Ticket',
      itemsInPOTicket: 'Items in the Purchase Order Ticket',
      itemDetail: 'Item Detail',
      itemNo: 'Item No.',
      itemDesc: 'Item Description',
      sku: 'SKU',
      qty: 'Qty',
      cost: 'Cost',
      unitMeasure: 'Unit of Measure',
      chargeTo: 'Charge To'
    },
    dialogLabels: {
      unableSave: 'Unable to save...',
      noVendor: 'Please select one Vendor before saving. If no Vendor(s) exist, please create them.',
      noShip: 'Please select one Ship To before saving. If no Ship To(s) exists, please create them.',
      noItem: 'You must have at least one item in purchase before saving.',
      changeStatus: 'Change status...',
      isReadyForPurchase: 'Is this order ready for purchase?',
      changeCostsTitle: 'ToolWatch',
      changeCostsMsg: 'Would you like to update the item costs for {description}?',
      mustNumberAllRecords: 'You must number all records.',
      deleteLineTitle: 'Delete',
      deleteLineItem: 'Are you sure you want to delete this item?',
      undeleteLineTitle: 'Please select an item to delete.',
      successDelete: 'Deleted successfully.'
    },
    tabs: [
      'PO Items',
      'Receipts',
      'Record Info'
    ],
    poItemsTabTableHeader: {
      desktop: [
        {
          label: 'Model ID',
          key: 'ItemNumber',
          sortable: 'true'
        },
        {
          label: 'T',
          key: 'ItemType',
          sortable: 'true'
        },
        {
          label: 'Manufacturer',
          key: 'Manufacturer',
          sortable: 'true'
        },
        {
          label: 'Model',
          key: 'Model',
          sortable: 'true'
        },
        {
          label: 'Item Description',
          key: 'Description',
          sortable: 'true'
        },
        {
          label: 'SKU',
          key: 'Sku',
          sortable: 'true',
          editable: true
        },
        {
          label: 'Quantity',
          key: 'QuantityOrdered',
          sortable: 'true',
          editable: true
        },
        {
          label: 'Received',
          key: 'QuantityReceived',
          sortable: 'true'
        },
        {
          label: 'Cancelled',
          key: 'QuantityCancelled',
          sortable: 'true',
          editable: true
        },
        {
          label: 'Cost',
          key: 'Cost',
          sortable: 'true',
          editable: true
        },
        {
          label: 'UOM',
          key: 'UomDescription',
          sortable: 'true',
          editable: true
        },
        {
          label: 'Extended',
          key: 'ExtendedCosts',
          type: 'Currency',
          sortable: 'true'
        },
        {
          label: 'Closed',
          key: 'isClosed',
          type: Boolean,
          sortable: 'true'
        },
        {
          label: 'Cost Center',
          key: 'CostCenterId',
          sortable: 'true'
        },
        {
          label: 'Cost Code',
          key: 'CostCodeId',
          sortable: 'true'
        },
        {
          label: 'Bill',
          key: 'Bill',
          type: Boolean,
          sortable: 'true'
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Model ID',
              key: 'ItemNumber',
              sortable: 'true'
            },
            {
              label: 'T',
              key: 'ItemType',
              sortable: 'true'
            },
            {
              label: 'Manufacturer',
              key: 'Manufacturer',
              sortable: 'true'
            },
            {
              label: 'Model',
              key: 'Model',
              sortable: 'true'
            },
            {
              label: 'Item Description',
              key: 'Description',
              sortable: 'true'
            },
            {
              label: 'SKU',
              key: 'Sku',
              sortable: 'true'
            },
            {
              label: 'Quantity',
              key: 'QuantityOrdered',
              sortable: 'true',
              editable: true
            },
            {
              label: 'Received',
              key: 'QuantityReceived',
              sortable: 'true'
            },
            {
              label: 'Cancelled',
              key: 'QuantityCancelled',
              sortable: 'true',
              editable: true
            },
            {
              label: 'Cost',
              key: 'Cost',
              type: 'Currency',
              sortable: 'true'
            },
            {
              label: 'UOM',
              key: 'UomDescription',
              sortable: 'true'
            },
            {
              label: 'Extended',
              key: 'ExtendedCosts',
              type: 'Currency',
              sortable: 'true'
            },
            {
              label: 'Closed',
              key: 'isClosed',
              type: Boolean,
              sortable: 'true'
            },
            {
              label: 'Cost Center',
              key: 'CostCenterId',
              sortable: 'true'
            },
            {
              label: 'Cost Code',
              key: 'CostCodeId',
              sortable: 'true'
            },
            {
              label: 'Bill',
              key: 'Bill',
              type: Boolean,
              sortable: 'true'
            }
          ]
        },
        {
          label: '',
          key: 'poItemAction'
        }
      ]
    },
    receiptsTabTableHeader: {
      desktop: [
        {
          label: 'Model ID',
          key: 'ItemNumber',
          sortable: 'true'
        },
        {
          label: 'Item Description',
          key: 'Description',
          sortable: 'true'
        },
        {
          label: 'Received',
          key: 'QuantityReceived',
          sortable: 'true'
        },
        {
          label: 'Receiving Ticket',
          key: 'ReceiptNumber',
          sortable: 'true'
        },
        {
          label: 'Received On',
          key: 'ReceivedOn',
          sortable: 'true'
        },
        {
          label: 'Received At',
          key: 'ReceivedAtLocation',
          sortable: 'true'
        },
        {
          label: 'Received By',
          key: 'ReceivedByEntity',
          sortable: 'true'
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Model ID',
              key: 'ItemNumber',
              sortable: 'true'
            },
            {
              label: 'Item Description',
              key: 'Description',
              sortable: 'true'
            },
            {
              label: 'Received',
              key: 'QuantityReceived',
              sortable: 'true'
            },
            {
              label: 'Receiving Ticket',
              key: 'ReceiptNumber',
              sortable: 'true'
            },
            {
              label: 'Received On',
              key: 'ReceivedOn',
              sortable: 'true'
            },
            {
              label: 'Received At',
              key: 'ReceivedAtLocation',
              sortable: 'true'
            },
            {
              label: 'Received By',
              key: 'ReceivedByEntity',
              sortable: 'true'
            }
          ]
        }
      ]
    },
    poDetailAddTemplateTableHeader: {
      desktop: [
        {
          label: '',
          key: 'Id',
          type: 'check',
          headerCheckboxVisible: false
        },
        {
          label: 'Type',
          key: 'TemplateType',
          sortable: true,
          type: String
        },
        {
          label: 'Category',
          key: 'Category',
          sortable: true,
          type: String
        },
        {
          label: 'ID',
          key: 'Number',
          sortable: true.valueOf,
          type: String
        },
        {
          label: 'Description',
          key: 'Description',
          sortable: true,
          type: String
        }
      ],
      mobile: [
        {
          label: '',
          key: 'Id',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Type',
              key: 'TemplateType',
              sortable: true,
              type: String
            },
            {
              label: 'Category',
              key: 'Category',
              sortable: true,
              type: String
            },
            {
              label: 'ID',
              key: 'Number',
              sortable: true.valueOf,
              type: String
            },
            {
              label: 'Description',
              key: 'Description',
              sortable: true,
              type: String
            }
          ]
        }
      ]
    },
    poDetailAddToolRecordTableHeader: {
      desktop: [
        {
          label: '',
          key: 'Id',
          type: 'check',
          headerCheckboxVisible: false
        },
        {
          label: 'Category',
          key: 'Category',
          sortable: true,
          type: String
        },
        {
          label: 'Description',
          key: 'Description',
          sortable: true,
          type: String
        },
        {
          label: 'Manufacturer',
          key: 'Manufacturer',
          sortable: true,
          type: String
        },
        {
          label: 'Model',
          key: 'Model',
          sortable: true,
          type: String
        },
        {
          label: 'Model ID',
          key: 'Number',
          sortable: true,
          type: String
        }
      ],
      mobile: [
        {
          label: '',
          key: 'Id',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Category',
              key: 'Category',
              sortable: true,
              type: String
            },
            {
              label: 'Description',
              key: 'Description',
              sortable: true,
              type: String
            },
            {
              label: 'Manufacturer',
              key: 'Manufacturer',
              sortable: true,
              type: String
            },
            {
              label: 'Model',
              key: 'Model',
              sortable: true,
              type: String
            },
            {
              label: 'Model ID',
              key: 'Number',
              sortable: true,
              type: String
            }
          ]
        }
      ]
    }
  },
  receivingTicket: {
    title: 'Receiving Browser',
    labels: {
      0: 'Receiving Ticket',
      1: 'Received Date',
      2: 'to',
      3: 'Vendor',
      4: 'Received At',
      5: 'Fewer search options...',
      6: 'More search options...',
      7: 'Charge To',
      8: 'PO. Number',
      9: 'Requisition No.',
      10: 'Document No',
      11: 'Expected Date'
    },
    tableHeader: {
      desktop: [
        {
          label: 'Rec. No',
          key: 'ReceiptNumber',
          sortable: 'true'
        },
        {
          label: 'Received',
          key: 'ReceivedOn',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Received At',
          key: 'ReceivedAtDescription',
          sortable: 'true'
        },
        {
          label: 'Received By',
          key: 'ReceivedByDescription',
          sortable: 'true'
        },
        {
          label: 'Vendor',
          key: 'VendorName',
          sortable: 'true'
        },
        {
          label: 'State',
          key: 'State',
          sortable: 'true'
        },
        {
          label: 'PO No.',
          key: 'PONumber',
          sortable: 'true'
        },
        {
          label: 'Charge To',
          key: 'ReceivedAtDescription',
          sortable: 'true'
        },
        {
          label: 'Expected Date',
          key: 'ExpectedDeliveryDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Requisition',
          key: 'Requisition',
          sortable: 'true'
        },
        {
          label: 'Document',
          key: 'DocumentNumber',
          sortable: 'true'
        },
        {
          label: '',
          key: 'receivingTicketAction'
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Rec. No',
              key: 'ReceiptNumber',
              sortable: 'true'
            },
            {
              label: 'Received',
              key: 'ReceivedOn',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Received At',
              key: 'ReceivedAtDescription',
              sortable: 'true'
            },
            {
              label: 'Received By',
              key: 'ReceivedByDescription',
              sortable: 'true'
            },
            {
              label: 'Vendor',
              key: 'VendorName',
              sortable: 'true'
            },
            {
              label: 'State',
              key: 'State',
              sortable: 'true'
            },
            {
              label: 'PO No.',
              key: 'PONumber',
              sortable: 'true'
            },
            {
              label: 'Charge To',
              key: 'ReceivedAtDescription',
              sortable: 'true'
            },
            {
              label: 'Expected Date',
              key: 'ExpectedDeliveryDate',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Requisition',
              key: 'Requisition',
              sortable: 'true'
            },
            {
              label: 'Document',
              key: 'DocumentNumber',
              sortable: 'true'
            }
          ]
        },
        {
          label: '',
          key: 'receivingTicketAction'
        }
      ]
    }
  },
  receivingTicketDetail: {
    title: 'Receiving Ticket',
    actionHeader: {
      checkAll: {
        title: 'Check All',
        icon: 'fas fa-check-square'
      },
      unCheckAll: {
        title: 'Uncheck All',
        icon: 'fas fa-square'
      },
      print: {
        title: 'Print',
        icon: 'fas fa-print'
      },
      attachments: {
        title: 'Attachments',
        icon: 'fas fa-paperclip'
      }
    },
    labels: {
      poInfo: 'Purchase Order Information',
      poNo: 'PO No.',
      requisitionNo: 'Requisition No.',
      documentNo: 'Document No.',
      vendor: 'Vendor',
      receivingInfo: 'Receiving Information',
      receivedBy: 'Received By',
      receivedDate: 'Received Date',
      receivingDocument: 'Receiving Document',
      receivedAt: 'Received At',
      note: 'Note',
      signature: 'Signature',
      viewPO: 'View PO...',
      receivingTicketSummary: 'Receiving Ticket Summary',
      newToolWizard: 'New Tool Wizard...',
      newToolsPending: 'New Tools Pending',
      newToolWizardNote1: 'Please enter the tool numbers that you wish to assign to the new tools, so they can be added to your ToolWatch system. You can also enter the bar code and serial numbers at this time; however they are not required.',
      newToolWizardNote2: 'After clicking "Create Tools", the new tools will be added to your ToolWatch system database.',
      autoNumberTools: 'Auto Number Tools'
    },
    tableHeader: {
      desktop: [
        {
          label: 'Model ID',
          key: 'ItemNumber',
          sortable: 'true'
        },
        {
          label: 'T',
          key: 'ItemType',
          sortable: true
        },
        {
          label: 'Manufacturer',
          key: 'Manufacturer',
          sortable: 'true'
        },
        {
          label: 'Model',
          key: 'Model',
          sortable: 'true'
        },
        {
          label: 'Item Description',
          key: 'Description',
          sortable: 'true'
        },
        {
          label: 'SKU',
          key: 'SKU',
          sortable: 'true'
        },
        {
          label: 'Remaining',
          key: 'QuantityOrdered',
          sortable: 'true'
        },
        {
          label: '',
          key: 'received',
          isEditable: true,
          type: Boolean,
          sortable: 'true'
        },
        {
          label: 'Received',
          key: 'QuantityReceived',
          sortable: 'true',
          editable: true
        },
        {
          label: 'Actual Price',
          key: 'ActualPrice',
          sortable: 'true'
        },
        {
          label: 'UOM',
          key: 'UomDescription',
          sortable: 'true'
        },
        {
          label: 'Extended Cost',
          key: 'ExtendedCost',
          type: 'Currency',
          sortable: 'true'
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Model ID',
              key: 'ItemNumber',
              sortable: 'true'
            },
            {
              label: 'T',
              key: 'ItemType',
              sortable: true
            },
            {
              label: 'Manufacturer',
              key: 'Manufacturer',
              sortable: 'true'
            },
            {
              label: 'Model',
              key: 'Model',
              sortable: 'true'
            },
            {
              label: 'Item Description',
              key: 'Description',
              sortable: 'true'
            },
            {
              label: 'SKU',
              key: 'SKU',
              sortable: 'true'
            },
            {
              label: 'Remaining',
              key: 'QuantityOrdered',
              sortable: 'true'
            },
            {
              label: '',
              key: 'received',
              isEditable: true,
              type: Boolean,
              sortable: 'true'
            },
            {
              label: 'Received',
              key: 'QuantityReceived',
              sortable: 'true',
              editable: true
            },
            {
              label: 'Actual Price',
              key: 'ActualPrice',
              sortable: 'true'
            },
            {
              label: 'UOM',
              key: 'UomDescription',
              sortable: 'true'
            },
            {
              label: 'Extended Cost',
              key: 'ExtendedCost',
              type: 'Currency',
              sortable: 'true'
            }
          ]
        }
      ]
    },
    newToolWizardTableHeader: {
      desktop: [
        {
          label: 'Permanent Tool Number',
          key: 'toolWizardToolNumber'
        },
        {
          label: 'Bar Code',
          key: 'toolWizardBarCode'
        },
        {
          label: 'Serial Number',
          key: 'toolWizardSerialNumber'
        },
        {
          label: 'Item Description',
          key: 'Description'
        },
        {
          label: 'Manufacturer',
          key: 'Manufacturer'
        },
        {
          label: 'Model Number',
          key: 'Model'
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Permanent Tool Number',
              key: 'toolWizardToolNumber'
            },
            {
              label: 'Bar Code',
              key: 'toolWizardBarCode'
            },
            {
              label: 'Serial Number',
              key: 'toolWizardSerialNumber'
            },
            {
              label: 'Item Description',
              key: 'Description'
            },
            {
              label: 'Manufacturer',
              key: 'Manufacturer'
            },
            {
              label: 'Model Number',
              key: 'Model'
            }
          ]
        }
      ]
    }
  }
}

export default purchasing

const retiredTools = {
  title: 'Retired Tools',
  actionHeader: {
    0: {
      title: 'Reinstate Item',
      icon: 'fas fa-undo'
    },
    1: {
      title: 'View Detail',
      icon: 'fas fa-edit'
    },
    2: {
      title: 'View Transaction History',
      icon: 'fas fa-history'
    },
    3: {
      title: 'Change Retirement Date',
      icon: 'fas fa-calendar'
    },
    4: {
      title: 'Delete',
      icon: 'fas fa-trash'
    }
  },
  browseRetiredTools: {
    title: 'Browse Retired Tools',
    labels: {
      0: 'Item No.',
      1: 'Date Retired',
      2: 'to',
      3: 'Category',
      4: 'Supervisor',
      5: 'Retired At',
      6: 'Retired Only',
      7: 'All',
      8: 'Reason',
      9: 'Status'
    }
  },
  noSelectRetiredTools: 'You have not selected any retired tools.',
  tableHeader: {
    desktop: [
      {
        label: 'Category',
        key: 'Category',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Retired',
        type: Date,
        key: 'RetiredDate',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Type',
        key: 'ItemType',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Bar Code',
        key: 'BarCode',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Quantity',
        key: 'Quantity',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Serial Number',
        key: 'SerialNumber',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Reason',
        key: 'Reason',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Status',
        key: 'StatusCodeDesc',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Retired By',
        key: 'RetiredBy',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Retired At',
        key: 'AssignedTo',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Responsible Party',
        key: 'ResponsiblePartyDesc',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'PI',
        key: 'PersonalIssue',
        type: Boolean,
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Owner',
        key: 'Owner',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Manager',
        key: 'Manager',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Reinstated To',
        key: 'ReinstatedToDesc',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Reinstated By',
        key: 'ReinstatedByDesc',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Reinstated',
        type: Date,
        key: 'ReinstatedDate',
        sortable: true,
        dafeultDisplay: true
      },
      {
        label: 'Last Scanned',
        key: 'LastScannedOn',
        sortable: true,
        type: Date
      },
      {
        label: 'Last Wireless Scan Area',
        key: 'LastScanArea',
        sortable: true
      },
      {
        label: 'Last Wireless Scan Location',
        key: 'LastScanLocation',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Category',
        key: 'Category',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Retired',
            type: Date,
            key: 'RetiredDate',
            sortable: true
          },
          {
            label: 'Item',
            key: 'ItemNumber',
            sortable: true
          },
          {
            label: 'Type',
            key: 'ItemType',
            sortable: true
          },
          {
            label: 'Bar Code',
            key: 'BarCode',
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Quantity',
            key: 'Quantity',
            sortable: true
          },
          {
            label: 'Serial Number',
            key: 'SerialNumber',
            sortable: true
          },
          {
            label: 'Retired By',
            key: 'RetiredBy',
            sortable: true
          },
          {
            label: 'Retired At',
            key: 'AssignedTo',
            sortable: true
          },
          {
            label: 'Responsible Party',
            key: 'ResponsiblePartyDesc',
            sortable: true
          },
          {
            label: 'PI',
            key: 'PersonalIssue',
            type: Boolean,
            sortable: true
          },
          {
            label: 'Status',
            key: 'StatusCodeNumber',
            sortable: true
          },
          {
            label: 'Owner',
            key: 'Owner',
            sortable: true
          },
          {
            label: 'Manager',
            key: 'Manager',
            sortable: true
          },
          {
            label: 'Reinstated To',
            key: 'ReinstatedToDesc',
            sortable: true
          },
          {
            label: 'Reinstated By',
            key: 'ReinstatedByDesc',
            sortable: true
          },
          {
            label: 'Reinstated',
            type: Date,
            key: 'ReinstatedDate',
            sortable: true
          },
          {
            label: 'Last Scanned',
            key: 'LastScannedOn',
            sortable: true,
            type: Date
          },
          {
            label: 'Last Wireless Scan Area',
            key: 'LastScanArea',
            sortable: true
          },
          {
            label: 'Last Wireless Scan Location',
            key: 'LastScanLocation',
            type: 'LastScanLocation',
            sortable: true
          }
        ]
      }
    ]
  },
  reinstateHeader: {
    desktop: [
      {
        label: 'Num',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Qty',
        key: 'Quantity',
        sortable: true
      },
      {
        label: 'RetiredAt',
        key: 'AssignedTo',
        sortable: true
      },
      {
        label: 'Reinstate To',
        key: 'ReinstateToId',
        isEditable: true,
        type: 'Guid',
        listType: 11,
        sortable: true
      },
      {
        label: 'Status',
        isEditable: true,
        type: 'Guid',
        listType: 15,
        key: 'ItemStatusId',
        sortable: true
      },
      {
        label: 'Personal Issue',
        key: 'PersonalIssue',
        type: Boolean,
        sortable: true
      },
      {
        label: 'Type',
        key: 'ItemType',
        sortable: true
      },
      {
        label: 'Notes',
        key: 'Notes',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Num',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Description',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Qty',
            key: 'Quantity',
            sortable: true
          },
          {
            label: 'RetiredAt',
            key: 'AssignedTo',
            sortable: true
          },
          {
            label: 'Reinstate To',
            key: 'ReinstateToId',
            isEditable: true,
            type: 'Guid',
            listType: 11,
            sortable: true
          },
          {
            label: 'Status',
            isEditable: true,
            type: 'Guid',
            listType: 15,
            key: 'ItemStatusId',
            sortable: true
          },
          {
            label: 'Personal Issue',
            key: 'PersonalIssue',
            type: Boolean,
            sortable: true
          },
          {
            label: 'Type',
            key: 'ItemType',
            sortable: true
          },
          {
            label: 'Notes',
            key: 'Notes',
            sortable: true
          }
        ]
      }
    ]
  },
  delete: {
    title: 'Continue?',
    message: 'Deleting this tool will remove it from retired tools. You will not find it in your tool browser or the recycle bin. Are you sure you want to delete this tool?'
  },
  detail: {
    title: 'Retired Tool Detail',
    note: 'Note',
    toolInformation: {
      title: 'Tool Information',
      labels: {
        toolNumber: 'Tool Number',
        barCode: 'Bar Code',
        desc: 'Description',
        serialNum: 'Serial Number',
        qty: 'Qty.',
        department: 'Department',
        class: 'Class',
        status: 'Status',
        meter: 'Meter'
      }
    },
    originalPurchase: {
      title: 'Original Purchase',
      labels: {
        poNum: 'PO No.',
        cost: 'Cost',
        date: 'Date',
        warranty: 'Warranty'
      }
    },
    modalInformation: {
      title: 'Model Information',
      labels: {
        modelNum: 'Model Number',
        manufacturer: 'Manufacturer',
        model: 'Model',
        category: 'Category'
      }
    },
    retirementInformation: {
      title: 'Retirement Information',
      labels: {
        retiredBy: 'Retired By',
        dateRetired: 'Date Retired',
        time: 'Time',
        reason: 'Reason',
        totalValueRetired: 'Total Value Retired',
        retiredAt: 'Retired At',
        responsibleParty: 'Responsible Party'
      }
    },
    billingInformation: {
      title: 'Billing Information',
      labels: {
        costCenter: 'Cost Center',
        costCode: 'Cost Code',
        amount: 'Amount'
      }
    }
  },
  history: {
    title: 'Transaction History',
    tableHeader: {
      desktop: [
        {
          label: 'Tran. Date',
          key: 'TransactionDate',
          type: Date,
          sortable: true
        },
        {
          label: 'Tran. No.',
          key: 'TransferNumber',
          sortable: true
        },
        {
          label: 'Type',
          key: 'TransactionType',
          sortable: true
        },
        {
          label: 'Description',
          key: 'MasterDescription',
          sortable: true
        },
        {
          label: 'Qty',
          key: 'Quantity',
          sortable: true
        },
        {
          label: 'Assigned To',
          key: 'AssignedTo',
          sortable: true
        },
        {
          label: 'Assignment Date',
          key: 'AssignmentDate',
          type: Date,
          sortable: true
        },
        {
          label: 'Status',
          key: 'Status',
          sortable: true
        },
        {
          label: 'PI',
          type: Boolean,
          isEditable: false,
          key: 'PersonalIssue',
          sortable: true
        },
        {
          label: 'Owner',
          key: 'Owner',
          sortable: true
        },
        {
          label: 'Kit',
          key: 'KitNumber',
          sortable: true
        }
      ],
      mobile: [
        {
          label: 'Tran. Date',
          key: 'TransactionDate',
          type: Date,
          sortable: true
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Tran. No.',
              key: 'TransferNumber',
              sortable: true
            },
            {
              label: 'Type',
              key: 'TransactionType',
              sortable: true
            },
            {
              label: 'Description',
              key: 'MasterDescription',
              sortable: true
            },
            {
              label: 'Qty',
              key: 'Quantity',
              sortable: true
            },
            {
              label: 'Assigned To',
              key: 'AssignedTo',
              sortable: true
            },
            {
              label: 'Assignment Date',
              key: 'AssignmentDate',
              type: Date,
              sortable: true
            },
            {
              label: 'Status',
              key: 'Status',
              sortable: true
            },
            {
              label: 'PI',
              type: Boolean,
              isEditable: false,
              key: 'PersonalIssue',
              sortable: true
            },
            {
              label: 'Owner',
              key: 'Owner',
              sortable: true
            },
            {
              label: 'Kit',
              key: 'KitNumber',
              sortable: true
            }
          ]
        }
      ]
    }
  },
  changeRetirementDate: {
    title: 'Change Retirement Date',
    labels: {
      category: 'Category',
      desc: 'Description',
      itemNum: 'Item Number',
      retiredDate: 'Retirement Date'
    }
  }
}

export default retiredTools

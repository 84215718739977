const countTicketTabCountSummaryTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Category',
        key: 'Category',
        sortable: true
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'T',
        key: 'Type',
        sortable: true
      },
      {
        label: 'Item Description',
        key: 'ItemDescription',
        sortable: true
      },
      {
        label: 'Manuf',
        key: 'Manufacturer',
        sortable: true
      },
      {
        label: 'Model',
        key: 'Model',
        sortable: true
      },
      {
        label: 'Expected',
        key: 'InStock',
        sortable: true
      },
      {
        label: 'Total Count',
        key: 'Total',
        sortable: true
      },
      {
        label: '+/-',
        key: 'Variance',
        sortable: true
      },
      {
        label: 'Owner',
        key: 'Owner',
        sortable: true
      },
      {
        label: 'Kit',
        key: 'KitNumber',
        sortable: true
      },
      {
        label: 'Status',
        key: 'ItemStatus',
        sortable: true
      },
      {
        label: 'PI',
        nested: true,
        nestedKey: 'Key',
        key: 'PersonalIssue',
        sortable: true
      },
      {
        label: 'Bin No',
        key: 'Bin',
        sortable: true
      },
      {
        label: '',
        key: 'actionField',
        sortable: false
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'Category',
            sortable: true
          },
          {
            label: 'Item',
            key: 'ItemNumber',
            sortable: true
          },
          {
            label: 'T',
            key: 'Type',
            sortable: true
          },
          {
            label: 'Item Description',
            key: 'ItemDescription',
            sortable: true
          },
          {
            label: 'Manuf',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Model',
            key: 'Model',
            sortable: true
          },
          {
            label: 'Expected',
            key: 'InStock',
            sortable: true
          },
          {
            label: 'Total Count',
            key: 'Total',
            sortable: true
          },
          {
            label: '+/-',
            key: 'Variance',
            sortable: true
          },
          {
            label: 'Owner',
            key: 'Owner',
            sortable: true
          },
          {
            label: 'Kit',
            key: 'KitNumber',
            sortable: true
          },
          {
            label: 'Status',
            key: 'ItemStatus',
            sortable: true
          },
          {
            label: 'PI',
            nested: true,
            nestedKey: 'Key',
            key: 'PersonalIssue',
            sortable: true
          },
          {
            label: 'Bin No',
            key: 'Bin',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: 'actionField',
        sortable: false
      }
    ]
  }
}

export default countTicketTabCountSummaryTable

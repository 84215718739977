import { getFormatedDateString, sortingFunc } from '../helpers/utils'

export default {
  state: () => ({
    countTicketSearchParam: {
      locationId: null,
      ticketNumberLike: '',
      summaryLike: '',
      createdAfter: `${getFormatedDateString(new Date(), 0)}T00:00:00`,
      createdBefore: null,
      isOpen: null,
      ticketIds: null,
      orgId: localStorage.getItem('OrgId'),
      includeDeleted: false
    },
    countInventoryEditId: null,
    selectedCountTicketItem: null,
    ticket: {
      Summary: ''
    },
    summaryList: [],
    lineList: [],
    tempList: [],
    toCountList: []
  }),
  mutations: {
    setCountTicketsSearchParam (state, value) {
      state.countTicketSearchParam = value
    },
    setCountInventoryEditId (state, id) {
      state.countInventoryEditId = id
    },
    setSelectedCountTicketItem (state, item) {
      state.selectedCountTicketItem = item
      state.selectedCountTicketItem.Key.InventoryKeyId = null
    },
    setCountTicketHeader (state, ticketHeader) {
      state.ticket = ticketHeader
    },
    initCountTicketList (state) {
      state.summaryList = []
      state.lineList = []
      state.toCountList = []
    },
    initCountTicketTempList (state) {
      state.tempList = []
    },
    setCountTicketSummaryList (state, summaryList) {
      state.summaryList = summaryList
      state.lineList = []
      summaryList.forEach(summary => {
        summary.CountLines.forEach(countLine => {
          state.lineList.push(countLine)
        })
      })
    },
    pushCountTicketSummaryList (state, item) {
      state.summaryList.unshift(item)
    },
    setCountTicketTempList (state, items) {
      state.tempList = items
    },
    sliceCountTicketSummaryList (state, index) {
      state.summaryList.splice(index, 1)
    },
    sortSummaryList (state, sortData) {
      sortingFunc(state.summaryList, sortData)
    },
    setCountTicketLineList (state, lineList) {
      state.lineList = lineList
    },
    pushCountTicketLineList (state, item) {
      state.lineList.unshift(item)
    },
    sortLineList (state, sortData) {
      sortingFunc(state.lineList, sortData)
    },
    sliceCountTicketLineList (state, index) {
      state.lineList.splice(index, 1)
    },
    summaryListEmpty (state) {
      state.summaryList = []
      state.lineList = []
      state.toCountList = []
    },
    setToCountList (state, item) {
      state.toCountList = item
    }
  },
  getters: {
    getCountTicketSearchParam (state) {
      return state.countTicketSearchParam
    },
    getCountInventoryEditId (state) {
      return state.countInventoryEditId
    },
    getSelectedCountTicketItem (state) {
      return state.selectedCountTicketItem
    },
    getCountTicketHeader (state) {
      return state.ticket
    },
    getCountTicketSummaryList (state) {
      return state.summaryList
    },
    getCountTicketLineList (state) {
      return state.lineList
    },
    getCountTicketTempList (state) {
      return state.tempList
    },
    getToCountList (state) {
      return state.toCountList
    }
  }
}

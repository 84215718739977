const invoiceBrowserTable = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'Id',
        type: 'check'
      },
      {
        label: 'Created On',
        key: 'CreatedOn',
        type: Date,
        sortable: 'true',
        filtertype: 'DateTime'
      },
      {
        label: 'Ticket',
        key: 'Number',
        sortable: 'true'
      },
      {
        label: 'Status',
        key: 'Status',
        sortable: 'true'
      },
      {
        label: 'Count Location',
        key: 'Location',
        sortable: 'true'
      },
      {
        label: 'Summary',
        key: 'Summary',
        sortable: 'true'
      },
      {
        label: '',
        key: ''
      }
    ],
    mobile: [
      {
        label: '',
        key: 'Id',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Created On',
            key: 'CreatedOn',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Ticket',
            key: 'Number',
            sortable: 'true'
          },
          {
            label: 'Status',
            key: 'Status',
            sortable: 'true'
          },
          {
            label: 'Count Location',
            key: 'Location',
            sortable: 'true'
          },
          {
            label: 'Summary',
            key: 'Summary',
            sortable: 'true'
          }
        ]
      },
      {
        label: '',
        key: ''
      }
    ]
  }
}

export default invoiceBrowserTable

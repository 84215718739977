const serviceSchedule = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'OT# actual',
        key: 'LastWorkOrderNumber',
        sortable: true
      },
      {
        label: 'Creado en',
        key: 'LastWorkOrderCreatedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Proxima fecha',
        key: 'OnDate',
        type: Date,
        sortable: true
      },
      {
        label: 'Medidor siguiente',
        key: 'OnMeter',
        sortable: true
      },
      {
        label: 'Siguiente turno',
        key: 'OnTurn',
        sortable: true
      },
      {
        label: 'Prioridad',
        key: 'Priority',
        sortable: true
      },
      {
        label: 'Servicio solicitado',
        key: 'RequestedService',
        sortable: true
      },
      {
        label: 'Artículo No.',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Número de serie',
        key: 'SerialNumber',
        sortable: true
      },
      {
        label: 'Fabricante',
        key: 'Manufacturer',
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'Model',
        sortable: true
      },
      {
        label: 'Asignación actual',
        key: 'AssignedTo',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'OT# actual',
        key: 'LastWorkOrderNumber',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Creado en',
            key: 'LastWorkOrderCreatedOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Proxima fecha',
            key: 'OnDate',
            type: Date,
            sortable: true
          },
          {
            label: 'Medidor siguiente',
            key: 'OnMeter',
            sortable: true
          },
          {
            label: 'Siguiente turno',
            key: 'OnTurn',
            sortable: true
          },
          {
            label: 'Prioridad',
            key: 'Priority',
            sortable: true
          },
          {
            label: 'Servicio solicitado',
            key: 'RequestedService',
            sortable: true
          },
          {
            label: 'Artículo No.',
            key: 'ItemNumber',
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Número de serie',
            key: 'SerialNumber',
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'Model',
            sortable: true
          },
          {
            label: 'Asignación actual',
            key: 'AssignedTo',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default serviceSchedule

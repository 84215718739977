const dataLoader = {
  tableHeader: {
    desktop: [
      {
        label: 'Creado',
        key: 'RunDate',
        type: Date,
        sortable: true
      },
      {
        label: 'Acción',
        key: 'RunType',
        sortable: true,
        type: String
      },
      {
        label: 'Objeto',
        key: 'ObjectType',
        sortable: true,
        type: String
      },
      {
        label: 'Procesado',
        key: 'OriginalRowsCount',
        sortable: true,
        type: Number
      },
      {
        label: 'Exitoso',
        key: 'SuccessRowsCount',
        sortable: true,
        type: Number
      },
      {
        label: 'Errores',
        key: 'ErrorRowsCount',
        sortable: true,
        type: Number
      },
      {
        label: 'Archivo original',
        key: 'OriginalFilePath',
        sortable: true,
        type: String
      },
      {
        label: 'Archivo Exitoso',
        key: 'SuccessFilePath',
        sortable: true,
        type: String
      },
      {
        label: 'Archivo erroneo',
        key: 'ErrorFilePath',
        sortable: true,
        type: String
      },
      {
        label: 'Mensaje de resultado',
        key: 'ResultMessage',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: 'control',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Creado',
            key: 'RunDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Acción',
            key: 'RunType',
            sortable: true,
            type: String
          },
          {
            label: 'Objeto',
            key: 'ObjectType',
            sortable: true,
            type: String
          },
          {
            label: 'Procesado',
            key: 'OriginalRowsCount',
            sortable: true,
            type: Number
          },
          {
            label: 'Exitoso',
            key: 'SuccessRowsCount',
            sortable: true,
            type: Number
          },
          {
            label: 'Errores',
            key: 'ErrorRowsCount',
            sortable: true,
            type: Number
          },
          {
            label: 'Mensaje de resultado',
            key: 'ResultMessage',
            sortable: true,
            type: String
          }
        ]
      },
      {
        label: 'Descarga',
        isModify: false,
        data: [
          {
            label: 'Archivo original',
            key: 'OriginalFilePath',
            sortable: true,
            type: String
          },
          {
            label: 'Archivo Exitoso',
            key: 'SuccessFilePath',
            sortable: true,
            type: String
          },
          {
            label: 'Archivo erroneo',
            key: 'ErrorFilePath',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  }
}

export default dataLoader

const customReports = {
  tableHeader: {
    desktop: [
      {
        label: 'Report Name',
        key: 'name',
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true
      },
      {
        label: 'Folder',
        key: 'folder',
        sortable: true
      },
      {
        label: 'Private',
        key: 'isPrivate',
        sortable: true
      },
      {
        label: 'Created By',
        key: 'createdBy',
        sortable: true
      },
      {
        label: 'Created On',
        key: 'formattedCreatedOn',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Report Name',
        key: 'name',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'Folder',
            key: 'folder',
            sortable: true
          },
          {
            label: 'Private',
            key: 'isPrivate',
            sortable: true
          },
          {
            label: 'Created By',
            key: 'createdBy',
            sortable: true
          },
          {
            label: 'Created On',
            key: 'formattedCreatedOn',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default customReports

const costLists = {
  tableHeader: {
    desktop: [
      {
        label: 'Stock Location',
        key: 'Location',
        sortable: true
      },
      {
        label: 'Total Qty Purchased',
        key: 'TotalQtyPurchased',
        sortable: true
      },
      {
        label: 'Average Purchase Cost',
        key: 'AveragePurchaseCost',
        type: 'cost',
        sortable: true
      },
      {
        label: 'Last Purchase Cost',
        key: 'LastPurchaseCost',
        type: 'cost',
        sortable: true
      },
      {
        label: 'Preferred Vendor',
        key: 'PreferredVendorId',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Stock Location',
        key: 'Location',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Total Qty Purchased',
            key: 'TotalQtyPurchased',
            sortable: true
          },
          {
            label: 'Average Purchase Cost',
            key: 'AveragePurchaseCost',
            type: 'cost',
            sortable: true
          },
          {
            label: 'Last Purchase Cost',
            key: 'LastPurchaseCost',
            type: 'cost',
            sortable: true
          },
          {
            label: 'Preferred Vendor',
            key: 'PreferredVendorId',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default costLists

const workOrderTasks = {
  tableHeader: {
    desktop: [
      {
        label: 'Completado',
        key: 'isDone',
        type: Boolean,
        id: 'sequenceNumber',
        isEditable: false,
        sortable: true
      },
      {
        label: 'Trabajo',
        key: 'sequenceNumber',
        type: Number,
        sortable: true
      },
      {
        label: 'Tipo',
        key: 'taskType',
        type: String,
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'description',
        type: String,
        sortable: true
      },
      {
        label: 'Est. Horas',
        key: 'estimatedHours',
        type: Number,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Completado',
            key: 'isDone',
            type: Boolean,
            id: 'sequenceNumber',
            isEditable: false
          },
          {
            label: 'Trabajo',
            key: 'sequenceNumber',
            type: Number
          },
          {
            label: 'Tipo',
            key: 'taskType',
            type: String
          },
          {
            label: 'Descripción',
            key: 'description',
            type: String
          },
          {
            label: 'Est. Horas',
            key: 'estimatedHours',
            type: Number
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default workOrderTasks

import workOrderRows from './workOrderGridRows.vue'
export interface workOrderRowsProps {
    minHeight: string,
    subtractedHeight: string,
    isEdited: boolean,
    isPerformed: boolean,
    isMultiSelectable: boolean,
    isDisabled: boolean
}
export const createDefaultWorkOrderRowsProps = () : workOrderRowsProps => {
  return {
    minHeight: '300px',
    subtractedHeight: '310px',
    isEdited: true,
    isPerformed: false,
    isMultiSelectable: false,
    isDisabled: false
  }
}
export const useWorkOrderRowsComponentData = (props : workOrderRowsProps) => {
  return {
    component: workOrderRows,
    props: props
  }
}

import { getFormatedDateString } from '../helpers/utils'

export default {
  state: () => ({
    receivingTicketsSearchParam: {
      receivingTicketNum: null,
      beginReceivedDate: getFormatedDateString(new Date(), 0),
      endReceivedDate: null,
      lessThanEndReceivedDatePlusOneDay: true,
      vendorId: null,
      receivedAtLocationId: null,
      costCenterId: null,
      purchaseOrderNumber: null,
      requisitionNumber: null,
      documentNumber: null,
      beginExpectedDate: null,
      endExpectedDate: null,
      lessThanEndExpectedDatePlusOneDay: true
    }
  }),
  mutations: {
    setReceivingTicketsSearchParam (state, value) {
      state.receivingTicketsSearchParam = value
    },
    initReceivingTicketsSearchParam (state) {
      state.receivingTicketsSearchParam = {
        receivingTicketNum: null,
        beginReceivedDate: `${getFormatedDateString(new Date(), 0)}T00:00:00`,
        endReceivedDate: null,
        lessThanEndReceivedDatePlusOneDay: true,
        vendorId: null,
        receivedAtLocationId: null,
        costCenterId: null,
        purchaseOrderNumber: null,
        requisitionNumber: null,
        documentNumber: null,
        beginExpectedDate: null,
        endExpectedDate: null,
        lessThanEndExpectedDatePlusOneDay: true
      }
    }
  },
  actions: {
  },
  getters: {
    getReceivingTicketsSearchParam (state) {
      return state.receivingTicketsSearchParam
    }
  }
}

export default {
  state: () => ({
    eventRecurrence: {
      RecurrenceTypeId: 5,
      Meter: null,
      Turns: null,
      TargetMeter: null,
      OccurrenceDurationMinutes: null,
      OccurrenceStartTime: null,
      PatternDayOfMonth: null,
      PatternDaysOfWeek: null,
      PatternFrequency: null,
      PatternInterval: null,
      PatternMonthOfYear: null,
      PatternOccurrenceOfDayInMonth: null,
      PatternType: null,
      RangeEndDate: null,
      RangeLimit: null,
      RangeMaxOccurrences: null,
      RangeStartDate: null,
      UseAppointmentRecurrenceBytes: false
    },
    isRemovingRecurrence: false
  }),
  mutations: {
    setRecurrence (state, recurrence) {
      state.eventRecurrence = recurrence
    },
    resetRecurrence (state, isReset) {
      // isReset = true: reset. isReset = false: remove
      state.eventRecurrence = {
        RecurrenceId: isReset ? null : state.eventRecurrence.RecurrenceId,
        RecurrenceTypeId: 5,
        Meter: null,
        Turns: null,
        TargetMeter: null,
        OccurrenceDurationMinutes: null,
        OccurrenceStartTime: null,
        PatternDayOfMonth: null,
        PatternDaysOfWeek: null,
        PatternFrequency: null,
        PatternInterval: null,
        PatternMonthOfYear: null,
        PatternOccurrenceOfDayInMonth: null,
        PatternType: null,
        RangeEndDate: null,
        RangeLimit: null,
        RangeMaxOccurrences: null,
        RangeStartDate: null,
        UseAppointmentRecurrenceBytes: false
      }
      state.isRemovingRecurrence = false
    },
    setIsRemovingFlag (state, flag) {
      state.isRemovingRecurrence = flag
    }
  },
  getters: {
    getRecurrence (state) {
      return state.eventRecurrence
    },
    isRemovingRecurrence (state) {
      return state.isRemovingRecurrence
    }
  }
}

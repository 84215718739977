const toolPickItems = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'Id',
        type: 'check'
      },
      {
        label: 'Image',
        key: 'ImageUrl',
        type: 'Image'
      },
      {
        label: 'Category',
        key: 'Category',
        sortable: true,
        type: String
      },
      {
        label: 'T',
        key: 'ItemType',
        sortable: true,
        type: String
      },
      {
        label: 'Model ID',
        key: 'Number',
        sortable: true,
        type: String
      },
      {
        label: 'Manufacturer',
        key: 'Manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Model',
        key: 'Model',
        sortable: true,
        type: String
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: '',
        key: 'Id',
        type: 'check'
      },
      {
        label: 'Image',
        key: 'ImageUrl',
        type: 'Image'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'Category',
            sortable: true,
            type: String
          },
          {
            label: 'T',
            key: 'ItemType',
            sortable: true,
            type: String
          },
          {
            label: 'Model ID',
            key: 'Number',
            sortable: true,
            type: String
          },
          {
            label: 'Manufacturer',
            key: 'Manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Model',
            key: 'Model',
            sortable: true,
            type: String
          },
          {
            label: 'Description',
            key: 'Description',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  }
}

export default toolPickItems

const integrationSettings = {
  tableHeader: {
    desktop: [
      {
        label: 'Name',
        key: 'name',
        sortable: true
      },
      {
        label: 'Status',
        key: 'enabled',
        sortable: true
      },
      {
        label: 'Category',
        key: 'category',
        sortable: true
      },
      {
        label: 'Description',
        key: 'desc'
      }
    ],
    mobile: [
      {
        label: 'check',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Name',
            key: 'name'
          },
          {
            label: 'status',
            key: 'enabled',
            sortable: true
          },
          {
            label: 'Category',
            key: 'category',
            sortable: true
          },
          {
            label: 'Description',
            key: 'desc'
          }
        ]
      }
    ]
  }
}

export default integrationSettings

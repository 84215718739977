const billing = {
  browser: {
    title: 'Navegador de facturación',
    costSelection: 'Selección del centro de costo',
    filter: {
      0: 'Todos',
      1: 'Costo de trabajo',
      2: 'Facturación',
      3: 'Herramientas',
      4: 'Materiales'
    },
    actionHeader: {
      0: {
        title: 'Calcular',
        icon: 'fas fa-calculator'
      },
      1: {
        title: 'Publicar',
        icon: 'fas fa-user-edit'
      },
      2: {
        title: 'Agregar Misc. Cargo/Crédito',
        icon: 'fas fa-plus'
      },
      3: {
        title: 'Eliminar Misc. cargo/crédito',
        icon: 'fas fa-trash'
      },
      4: {
        title: 'Actualizar',
        icon: 'fas fa-sync-alt'
      }
    },
    lineAction: {
      0: {
        title: 'Ver Detalle'
      },
      1: {
        title: 'Retener'
      },
      2: {
        title: 'Remover retención'
      },
      3: {
        title: 'Crear cargo / crédito relacionado'
      },
      4: {
        title: 'Editar código de costo'
      }
    },
    entryType: {
      title: 'Mostrar entradas para',
      labels: {
        0: 'Herramientas y equipos',
        1: 'Materiales y consumibles'
      }
    },
    calculation: {
      title: 'Cálculo del centro de costo',
      dialog: {
        message: 'Ha seleccionado una fecha límite futura. Estás seguro de que quieres continuar?'
      },
      labels: {
        0: 'Seleccionar fecha y hora de corte',
        1: 'Solo se pueden calcular los centros de costos con hojas de tarifas asignadas.'
      }
    },
    post: {
      title: 'Publicar',
      labels: {
        0: 'Seleccione los centros de costos para publicar',
        1: 'Esta listo para publicar'
      }
    },
    buttons: {
      0: 'Ver Detalle',
      1: 'Retener',
      2: 'Remover retención'
    },
    postAmount: {
      0: 'Facturación a publicar',
      1: 'Costo de trabajo a publicar'
    },
    miscCharge: {
      title: 'Cargo/Crédito miscelaneos',
      costCodeOptions: {
        0: 'Centro de costo específico',
        1: 'Global'
      },
      dialogLabels: {
        0: 'Cuenta de cargo GL',
        1: 'Cuenta de crédito GL',
        2: 'Escanear o ingresar el número de artículo',
        3: 'Acceder',
        4: 'Número de artículo',
        5: 'Descripción',
        6: 'Cargo',
        7: 'Crédito',
        8: 'Total',
        9: 'Misc. Cargo/Crédito Fecha',
        10: 'Facturación',
        11: 'Costo del trabajo',
        12: 'Seleccione una categoría',
        13: 'Seleccione un artículo',
        14: 'Elemento desconocido',
        15: 'Este cargo / crédito esta relacionado con la transacción:',
        16: 'Artículo',
        17: 'Seleccionar modelo',
        18: 'Aplicar al cargo máximo'
      }
    },
    editCostCode: {
      title: 'Editar código de costo',
      label: {
        0: 'Actualizar la línea de transferencia en la transferencia',
        1: 'Artículo'
      }
    },
    transactionDetail: {
      title: 'Detalle de la transacción',
      tags: {
        0: 'Información del centro de costos',
        1: 'Información del artículo',
        2: 'Cargos',
        3: 'Información de la transacción',
        4: 'Uso efectivo y Información de tarifas'
      },
      labels: {
        0: 'Centro de costos',
        1: 'Código de costo',
        2: 'Nombre de la hoja de tarifas',
        3: 'Tipo de hoja de tarifas',
        4: 'ID de hoja de tarifas',
        5: 'Calculado en',
        6: 'Fecha de corte',
        7: 'Fecha final',
        8: 'Tipo de artículo',
        9: 'Categoría',
        10: 'Artículo No.',
        11: 'Descripción',
        12: 'Método de cálculo del artículo',
        13: 'Tipo de carga',
        14: 'Cant.',
        15: 'Cantidad cada uno',
        16: 'Total extendido',
        17: 'Tipo',
        18: 'Fecha de inicio',
        19: 'ID',
        20: 'De',
        21: 'A',
        22: 'Duración efectiva',
        23: 'Meses',
        24: 'Semanas',
        25: 'Dias',
        26: 'Horas',
        27: 'Horas potenciales',
        28: 'Horas imputables',
        29: 'Nivel de tarifa de artículo',
        30: 'Tasa efectiva / Hr. del articulo'
      }
    }
  },
  ratesheets: {
    title: 'Navegador de hojas de tarifas',
    filter: {
      0: 'Tarifas de materiales y consumibles',
      1: 'Tarifas de herramientas y equipos'
    },
    actionHeader: {
      0: {
        title: 'Ver hoja de tarifas',
        icon: 'fas fa-user-edit'
      },
      1: {
        title: 'Agregar hoja de tarifas de herramientas y equipos',
        icon: 'fas fa-plus'
      },
      2: {
        title: 'Agregar hoja de tarifas de materiales y consumibles',
        icon: 'fas fa-plus'
      },
      3: {
        title: 'Copiar Hoja de tarifas',
        icon: 'fas fa-copy'
      },
      4: {
        title: 'Borrar',
        icon: 'fas fa-trash'
      }
    }
  },
  rateSheetView: {
    title: 'Hoja de tarifas',
    addItemBrowserTitle: 'Herramienta Navegador',
    actionHeader: {
      0: {
        title: 'Guardar',
        tooltip: 'Guardar'
      },
      1: {
        title: 'Guardar y cerrar',
        tooltip: 'Guardar y cerrar'
      },
      2: {
        title: 'Agregar cargo relacionado',
        tooltip: 'Agregar cargo relacionado'
      },
      3: {
        title: 'Eliminar cargo relacionado',
        tooltip: 'Eliminar cargo relacionado'
      },
      4: {
        title: 'Exportar datos',
        tooltip: 'Exportar datos en formato CSV'
      },
      5: {
        title: 'Publicar en Excel',
        tooltip: 'Publicar en MS Excel para editar'
      },
      6: {
        title: 'Importar desde Excel',
        tooltip: 'Importar libro de trabajo de MS Excel'
      }
    },
    sheetHeader: {
      0: 'Número de hoja de tarifas',
      1: 'Descripción',
      2: 'Establecer reglas de hoja de tarifas',
      3: 'Método de recálculo de la tasa base'
    },
    baseRateRecalculationMethods: {
      0: 'Basado en porcentaje',
      1: 'Basado en tarifa/cargo'
    },
    importSheet: {
      title: 'Importar hoja de tarifas de Excel',
      message: {
        0: 'El archivo de importación solicitado no se puede cargar porque el ID \n de la hoja de tarifas del archivo no coincide con el ID de la hoja de tarifas actual.',
        1: 'Error al cargar o Excel no válido.',
        2: 'Importar hoja de tarifas de Excel completa.'
      }
    },
    exportSheet: {
      title: 'Exportación de hojas de tarifas',
      message: 'Elija qué hojas de tarifas exportar',
      labels: {
        0: 'Ambas',
        1: 'Costo de trabajo',
        2: 'Facturación'
      }
    },
    sheetTable: {
      message: 'El uso por hora no se puede utilizar en un activo de cantidad rastreada'
    },
    sheetRuleDlg: {
      title: 'Reglas de la hoja de tarifas',
      labels: {
        0: 'Establezca las horas en cada período para esta hoja de tarifas.',
        1: 'Establezca los puntos de migración para esta hoja de tarifas.',
        2: 'Horas en un día',
        3: 'Horas en una semana',
        4: 'Horas en un mes',
        5: 'Migrar a tarifa diaria a',
        6: 'Migrar a tarifa semanal a',
        7: 'Migrar a tarifa mensual a'
      }
    }
  },
  exportTemplates: {
    title: 'Exportar listas de plantillas',
    placeholder: 'Buscar plantillas',
    labels: {
      0: 'Todo',
      1: 'Resumen de Transacciones',
      2: 'Detalle de la transacción',
      3: 'Nombre',
      4: 'Delimitador de registro',
      5: 'Formato de registro',
      6: 'Campos disponibles',
      7: 'Primera línea de exportación (Cargar)',
      8: 'Segunda línea de exportación (Crédito)',
      9: 'Exportar Diseño',
      10: 'Agregar para exportar',
      11: 'Agregar a la primera línea',
      12: 'Agregar a la segunda línea',
      13: 'Editar valores de campo de exportación',
      14: 'Configurar valores de los campos'
    },
    recordDelimiter: [
      {
        value: 1,
        description: 'Espacio'
      },
      {
        value: 2,
        description: 'Coma'
      },
      {
        value: 3,
        description: 'Guión'
      },
      {
        value: 4,
        description: 'Más'
      }
    ],
    recordFormat: [
      {
        value: 1,
        description: 'Costo del trabajo (línea individual)'
      },
      {
        value: 2,
        description: 'Contabilidad (doble línea)'
      }
    ],
    availableFieldsHeader: [
      'Nombre del campo', 'Tipo', 'Long', 'Notas'
    ],
    fieldValueTabs: [
      'Registros predeterminados',
      'Herramientas de asignación',
      'Herramientas de calendario',
      'Herramientas de horario',
      'Consumibles',
      'Servicio'
    ],
    fieldValueTabLabels: {
      0: 'Encabezado de registro',
      1: 'Referencia 1',
      2: 'Referencia 2',
      3: 'Marcador de fin de registro',
      4: 'Tipo de costo',
      5: 'Tipo de transacción',
      6: 'Exportar descripción'
    },
    validation: {
      templateNameRequired: 'Nombre de la plantilla requerida',
      delimiterRequired: 'Tipo de delimitador obligatorio'
    }
  },
  equipmentUsageLog: {
    title: 'Registro de uso de equipos',
    addItemBrowserTitle: 'Navegador de herramientas',
    actionHeader: {
      0: {
        title: 'Guardar',
        tooltip: 'Guardar',
        icon: 'fas fa-save'
      },
      1: {
        title: 'Añadir artículo',
        tooltip: 'Añadir artículo',
        icon: 'fas fa-plus'
      }
    },
    scanLabel: 'ingresar el número de artículo',
    dialogLabels: {
      1: 'Elegir si para eliminar la fila o no para salir',
      0: 'Eliminar filas'
    },
    warningMsg: 'Faltan campos obligatorios'
  },
  exportData: {
    title: 'Exportacion de datos',
    labels: {
      0: 'Fuente de exportacion',
      1: 'Transacciones publicadas',
      2: 'Transacciones no publicadas',
      3: 'Datos de costos del trabajo',
      4: 'Datos de facturacion',
      5: 'Costo de trabajo y datos de facturacion',
      6: 'Formato de datos',
      7: 'Plantillas de resumen',
      8: 'Plantillas de detalles',
      9: 'Plantillas de exportacion',
      10: 'Incluir encabezados de columnas',
      11: 'Parametros de fecha',
      12: 'Rango de fechas de publicacion'
    }
  },
  InvoiceBrowser: {
    title: 'Navegador de facturas',
    actionHeader: {
      0: {
        title: 'Generar facturas',
        tooltip: 'Generar facturas',
        icon: 'fas fa-usd-circle'
      },
      1: {
        title: 'Imprimir facturas',
        tooltip: 'Imprimir facturas',
        icon: 'fas fa-print'
      },
      2: {
        title: 'Imprimir facturas con tarifas',
        tooltip: 'Imprimir facturas con tarifas',
        icon: 'fas fa-print'
      }
    },
    printTableHeader: [
      {
        label: 'Cant',
        key: 'Qty'
      },
      {
        label: 'Descripción',
        key: 'Description'
      },
      {
        label: 'tipo de transacción',
        key: 'TransactionType'
      },
      {
        label: 'Tipo de cargo',
        key: 'ChargeType'
      },
      {
        label: 'Horas',
        key: 'Hours'
      },
      {
        label: 'Tasa efectiva / hora',
        key: 'EffectiveRate'
      },
      {
        label: 'Precio unitario',
        key: 'UnitPrice'
      },
      {
        label: 'Total',
        key: 'Total'
      }
    ],
    labels: {
      0: 'Tarifa diaria',
      1: 'Tarifa Semanal',
      2: 'Tarifa mensual',
      3: 'Número de factura',
      4: 'Fecha de la factura',
      5: 'Período de facturación',
      6: 'Pago vencido',
      7: 'Condiciones',
      8: 'Centro de costos',
      9: 'Contacto central',
      10: 'Teléfono de contacto',
      11: 'Número de serie',
      12: 'Asignación',
      13: 'Fecha de inicio',
      14: 'Fecha de finalización',
      15: 'Cargo de alquiler',
      16: 'Impuesto sobre las ventas {salesTax}%',
      17: 'Neto adeudado al recibirlo',
      18: 'Por favor, envíar a:',
      19: 'Instrucciones especiales:',
      20: 'Factura no.',
      21: 'Centros de costo',
      22: 'Fechas de factura desde',
      23: 'Examinar facturas'
    },
    warningDialog: {
      title: 'Imprimir factura con tarifas',
      content: {
        0: '{cntWithNoRates} de sus facturas seleccionadas no tiene datos de tarifas. ¿Quieres continuar?',
        1: 'Solo las facturas generadas después del 1 de octubre de 2020 incluirán tarifas para cálculos y contabilizaciones.'
      }
    },
    noInvoicesSelected: 'No hay facturas seleccionados'
  },
  InvoiceBrowserGenerate: {
    title: 'Generar facturas',
    labels: {
      0: 'Rango de fechas',
      1: 'Para',
      2: 'publicado',
      3: 'no publicado',
      4: 'Incluir cargos por herramientas retiradas y cargos por pérdidas',
      5: 'Tipo de cargo',
      6: 'Costo del trabajo',
      7: 'Facturación',
      8: 'Centros de costo',
      9: 'Fecha de factura',
      10: 'Fecha de pago',
      11: 'Remesa:'
    },
    warningDialog: {
      title: 'Advertencia',
      content: {
        0: 'La selección del centro de costos no puede estar vacía.',
        1: 'La fecha de vencimiento del pago no puede estar vacía.'
      }
    }
  },
  importMeter: {
    title: 'Importar medidor'
  },
  importFuelEmployees: {
    title: 'Empleados de combustible de importación'
  },
  Map: {
    link: 'Mapa'
  }
}

export default billing

const certifications = {
  tableHeader: {
    desktop: [
      {
        label: 'control'
      },
      {
        label: 'Código'
      },
      {
        label: 'Descripción'
      },
      {
        label: 'Renovar'
      },
      {
        label: ''
      }
    ],
    mobile: [
      {
        label: 'control'
      },
      {
        label: 'Información',
        data: [
          {
            label: 'Código'
          },
          {
            label: 'Descripción'
          },
          {
            label: 'Renovar'
          }
        ]
      },
      {
        label: ''
      }
    ]
  }
}

export default certifications

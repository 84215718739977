<template>
<div>
  <nav
    v-if="isShowWorkForceNav"
    class="navbar-default navbar-static-side"
    role="navigation"
    v-bind:style="{display: hideNav}">
    <a class="navbar-minimalize minimalize-styl-2 btn btn-primary"><i class="fa fa-bars"></i> </a>
    <div class="sidebar-collapse">
      <ul class="nav metismenu" :class="jobSiteUser && 'job-site'" id="side-menu">
        <li class="nav-header" @click="clicked = false">
          <div class="dropdown profile-element">
            <div class="logo-holder" @click="removeSideBar">
              <router-link :to="$i18nRoute({ name: 'Dashboard' })">
                <img alt="image" :src="require(`../../assets/integration-logos/${brandLogo}`)" />
              </router-link>
            </div>
            <div v-if="platformNavigation">
              <div class="org-name">
                <p>
                  <span class="text-wrap" style="color: white;">
                    {{cart.StockPointName}}
                  </span>
                  <br>
                  <span class="text-wrap">
                    {{orgName}}
                  </span>
                </p>
              </div>
              <ReqOrgList
                @onOrgSelected="switchingApp"
              />
            </div>
            <div v-else>
              <div class="mt-3" v-if="jobSiteUser">
                <span class="portal-name ml-28">
                  {{$t('appLists.0.Title')}}
                </span>
              </div>
              <div class="mt-3" v-if="wareHouseUser">
                <span class="portal-name ml-28">
                  {{$t('appLists.1.Title')}}
                </span>
              </div>
              <div class="mt-3" v-if="backOfficeUser">
                <span class="portal-name ml-28">
                  {{$t('appLists.2.Title')}}
                </span>
              </div>
              <a @click="logout()" class="dropdown-toggle">
                <template>
                  <span
                    class="block m-t-xs font-bold profile-name">
                    {{fullName}}
                    <b class="caret"></b>
                    <div class="org-name">
                      <span class="text-wrap">
                        {{orgName}}
                      </span>
                    </div>
                  </span>
                  <span style="color: #B2B3B4; font-weight: bold; font-size: 14px;">{{cart.StockPointName}}</span>
                </template>
              </a>

              <div class="d-flex flex-column profile-element mt-4" v-if="jobSiteUser">
                <span class="profile-name block m-t-xs font-bold text-white" @click="logout()">
                  {{cart.ShipToName}}
                </span>
                <span class="portal-name mt-1" @click="logout()">
                  {{cart.CostCenterName}}
                </span>
                <span class="portal-name mt-1" @click="logout()">
                  {{cart.CostCodeName}}
                </span>
              </div>
            </div>

          </div>
          <div class="logo-element" @click="platformNavigation ? null : logout()">
            <img v-if="isBrandAlign" alt="image" :src="require(`../../assets/integration-logos/${alignTopLogo}`)"/>
            <a v-else class="dropdown-toggle">
              TW
            </a>
          </div>
        </li>
        <template v-for="(route, index1) in routes">
          <li
            :key="'route_' + index1"
            v-show="navGuard(route) && showNavLink(route)"
            :class="['main-route', highlightMainRoute(route), route.children ? 'group' : 'single']"
          >
            <router-link
              v-if="!route.children"
              :disabled="!isValidRoute(route, null)"
              :event="isValidRoute(route, null) ? 'click' : ''"
              :to="$i18nRoute({
                name: route.route_name ? route.route_name : route.name,
                params: { id: route.params }
            })" >
              <template v-if="!routerNoGuard(route, null) || !routerEntitlement(route, null)">
                <span class="d-flex">
                  <div class="ico-holder text-center">
                    <i :class="[route.icon]"></i>
                  </div>
                  <span class="nav-label">{{$t('routes')[index1].name}}</span>
                  <template v-if="!route.completed">
                    - {{$t('message.comingSoon')}}
                  </template>
                  <template v-else-if="!routerEntitlement(route, null)">
                    <i
                      class="material-icons pl-1"
                      style="cursor: default;"
                      v-tooltip:bottom="'Your contract is not entitled to this module'">
                      lock
                    </i>
                  </template>
                  <template v-else>
                    <i
                      class="material-icons pl-1"
                      style="cursor: default;"
                      v-tooltip:bottom="'Your account does not have permission'">
                      lock
                    </i>
                  </template>
                </span>
              </template>
              <template v-else>
                <div class="ico-holder text-center">
                  <i :class="[route.icon, highlightMainRouteIcon([route])]"></i>
                </div>
                <span class="nav-label">{{$t('routes')[index1].name}}</span>
              </template>
            </router-link>
            <a
              class="has-arrow"
              aria-expanded="true"
              v-else
            >
              <div class="ico-holder text-center">
                <i :class="[route.icon, highlightMainRouteIcon(route.children)]"></i>
              </div>
              <span class="nav-label">{{$t('routes')[index1].name}}</span><span class="fa arrow"></span>
            </a>
            <ul v-if="route.children"
              class="nav nav-second-level mm-collapse"
              aria-expanded="true"
              :class="getMatchPath(route) ? 'mm-show' : ''"
            >
              <li class="mini-nav" v-if="!route.children">
                <router-link :to="$i18nRoute({
                  name: route.route_name ? route.route_name : route.name,
                  params: { id: route.params }
                })">
                  <span>{{$t('routes')[index1].name}}</span>
                </router-link>
              </li>
              <template v-if="route.children">
                <template v-for="(childroute, index2) in route.children">
                  <li
                    :key="'nested-route_' + index2"
                    v-show="navGuard(childroute) && showNavLink(route, childroute)"
                    :class="['nested-route', highlightNestedRoute(childroute)]"
                    style="position: relative"
                  >
                    <template v-if="childroute.path">
                      <router-link
                        :disabled="!isValidRoute(route, childroute)"
                        :class="!isValidRoute(route, childroute) ? 'inactive' : ''"
                        :event="isValidRoute(route, childroute) ? 'click' : ''"
                        :to="$i18nRoute({
                          name: childroute.route_name ? childroute.route_name : childroute.name,
                          params: { id: childroute.params }
                        })">
                        <template v-if="!routerNoGuard(route, childroute) || !routerEntitlement(route, childroute)">
                          <span class="d-flex">
                            {{$t('routes')[index1].children[index2].name}}
                            <template v-if="route.released && !childroute.completed">
                              - {{$t('message.comingSoon')}}
                            </template>
                            <template v-else-if="!routerEntitlement(route, childroute)">
                              <i
                                class="material-icons pl-1"
                                style="cursor: default;"
                                v-tooltip:bottom="'Your contract is not entitled to this module'">
                                lock
                              </i>
                            </template>
                            <template v-else>
                              <i
                                class="material-icons pl-1"
                                style="cursor: default;"
                                v-tooltip:bottom="'Your account does not have permission'">
                                lock
                              </i>
                            </template>
                          </span>
                        </template>
                        <template v-else>
                          {{$t('routes')[index1].children[index2].name}}
                        </template>
                      </router-link>
                    </template>
                    <template v-else>
                      <a
                        class="has-arrow"
                        href="#"
                        aria-expanded="true"
                        v-if="!isMinibar"
                      >
                        <span class="nav-label">{{$t('routes')[index1].children[index2].name}}</span><span class="fa arrow"></span>
                      </a>
                      <a
                        href="#"
                        aria-expanded="true"
                        v-else
                      >
                        {{childroute.name}}
                      </a>
                      <ul
                        class="nav nav-third-level mm-collapse"
                        aria-expanded="true"
                        :class="getMatchPath(childroute) ? 'mm-show' : ''"
                      >
                        <template v-for="(nestedRoute, index3) in childroute.children">
                          <li
                            v-if="nestedRoute.path"
                            :key="'nested-route_' + index3"
                            v-show="showNavLink(route, nestedRoute)"
                            :class="[highlightNestedRoute(nestedRoute)]"
                          >
                            <router-link
                              :disabled="!isValidRoute(route, nestedRoute)"
                              :event="isValidRoute(route, nestedRoute) ? 'click' : ''"
                              :to="$i18nRoute({
                                name: nestedRoute.route_name ? nestedRoute.route_name : nestedRoute.name,
                                params: { id: nestedRoute.params }
                              })">
                              <template v-if="!routerEntitlement(route, nestedRoute)">
                                <span class="d-flex">
                                  {{$t('routes')[index1].children[index2].children[index3].name}}
                                  <i
                                    class="material-icons pl-1"
                                    style="cursor: default;"
                                    v-tooltip:bottom="'Your contract is not entitled to this module'">
                                    lock
                                  </i>
                                </span>
                              </template>
                              <template v-else-if="!routerNoGuard(route, nestedRoute)">
                                <span class="d-flex">
                                  {{$t('routes')[index1].children[index2].children[index3].name}}
                                  <i
                                    class="material-icons pl-1"
                                    style="cursor: default;"
                                    v-tooltip:bottom="'Your account does not have permission'">
                                    lock
                                  </i>
                                </span>
                              </template>
                              <template v-else>
                                {{$t('routes')[index1].children[index2].children[index3].name}}
                              </template>
                            </router-link>
                          </li>
                          <li :key="'nested-route_' + index3" v-else>
                            <p v-show="showNavLink(route, nestedRoute)" class="lists-container">{{nestedRoute.name}}</p>
                          </li>
                        </template>
                      </ul>
                    </template>
                  </li>
                </template>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </div>
  <div :class="brandFooter">{{$t('message.poweredToolWatch')}}</div>
  </nav>
   <nav
      class="navbar-default navbar-static-side"
      role="navigation"
      v-if="!isShowWorkForceNav"
      v-bind:style="{ display: hideNav }"
    >
      <a class="navbar-minimalize minimalize-styl-2 btn btn-primary"
        ><i class="fa fa-bars"></i>
      </a>
      <div  class="sidebar-collapse-wfm" style="height:49px;padding-bottom:0px;">
        <ul class="nav metismenu" id="side-menu">
        <li class="nav-header" @click="clicked = false" style="height:49px;">
        <div class="dropdown profile-element">
          <div class="logo-holder" @click="removeSideBar">
            <router-link
              :class="{ disabled: !isShowWorkForceNav }"
              :to="$i18nRoute({ name: 'Dashboard' })"
              @click.native="logoHandler"
            >
              <img
                alt="image"
                :src="require(`../../assets/integration-logos/${brandLogo}`)"
              />
            </router-link>
          </div>
        </div>
        <div class="logo-element">
          <img
            v-if="isBrandAlign"
            alt="image"
            :src="require(`../../assets/integration-logos/${alignTopLogo}`)"
          />
          <a v-else class="dropdown-toggle"> TW </a>
        </div>
        </li></ul>
      </div>
    </nav>
</div>
</template>
<script>
import ReqOrgList from '../requisitions/ReqOrgList.vue'
import $ from 'jquery'
import axios from 'axios'
import cookie from 'cookie'
import routes from '../../locales/en/routes.js'
import { setProcoreShipTo } from '../../helpers/procore.js'
import {
  isValidRoute,
  routerNoGuard,
  routerEntitlement,
  showNavLink,
  errorHandler,
  successHandler
} from '../../helpers/utils.js'
export default {
  name: 'SideBar',
  components: {
    ReqOrgList
  },
  data () {
    return {
      pathname: '',
      clicked: false,
      routes: [],
      timeHandler: null,
      isMinibar: false,
      isValidRoute: isValidRoute,
      routerNoGuard: routerNoGuard,
      routerEntitlement: routerEntitlement,
      showNavLink: showNavLink,
      isBrandAlign: false
    }
  },
  created () {
    window.addEventListener('resize', this.myEventHandler)
    this.pathname = this.$route.fullPath
    this.routes = routes
    var root = document.querySelector(':root')
    var rs = getComputedStyle(root)
    if (process.env.VUE_APP_BRAND === 'TOOLOPS') {
      document.documentElement.style.setProperty('--navbar-custom-icon-filter', 'invert(53%) sepia(58%) saturate(4401%) hue-rotate(88deg) brightness(107%) contrast(113%)')
      document.documentElement.style.setProperty('--logo-img-margin-logo-top', '-20px')
    } else {
      this.isBrandAlign = true
      document.documentElement.style.setProperty('--navbar-bg-color', '#20333c')
      document.documentElement.style.setProperty('--navbar-menu-bg-color', '#20333c')
      document.documentElement.style.setProperty('--topBar-active-bg-color', '#364750')
      document.documentElement.style.setProperty('--active-menu-text-color', '#ffffff')
      document.documentElement.style.setProperty('--nested-menus-bg-color', '#1a2a30') // Dark BG Shade
      document.documentElement.style.setProperty('--second-nested-menus-bg-color', '#121e21') // Dark BG Shade 2
      document.documentElement.style.setProperty('--mini-navbar-active-menu-bg-color', '#364750')
      document.documentElement.style.setProperty('--active-menu-bg-color', '#4c84fc')
      document.documentElement.style.setProperty('--menu-hover-bg-color', '#364750') // Dark BG Tint
      document.documentElement.style.setProperty('--nested-active-menus-bg-color', '#4c84fc') // primary color
      document.documentElement.style.setProperty('--mini-navbar-bg-color', '#20333c') // Dark BG
      document.documentElement.style.setProperty('--logo-header-margin', '0 0 15px -24px')
      document.documentElement.style.setProperty('--logo-img-margin-top', '-5px')
      document.documentElement.style.setProperty('--navbar-custom-icon-filter', 'brightness(0) invert(1)')
      document.documentElement.style.setProperty('--dropdown-icon-css', 'normal')
      document.documentElement.style.setProperty('--logo-img-margin-top', '12px')
      document.documentElement.style.setProperty('--logo-img-margin-left', '22px')
      document.documentElement.style.setProperty('--logo-img-margin-bootom', '10px')
      document.documentElement.style.setProperty('--logo-img-margin-logo-top', '-10px')
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.myEventHandler)
  },
  methods: {
    async switchingApp (location) {
      await this.savePinnedLocation(location)
      await this.saveShipTo(location)
      successHandler(this.$toast, this.$t('message.success.shipSaving'))
      await this.$router.go(this.$route.fullPath)
    },
    async saveShipTo (location) {
      try {
        this.cart.StockpointId = location.Id
        await this.$store.dispatch('saveCart', this.cart)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, this.$t('message.error.shipSaving'))
        }
      }
    },
    async savePinnedLocation (location) {
      try {
        var pinnedLocationInfo = {
          pinnedLocationId: "'" + location.Id + "'"
        }
        await axios.post(
          '/api/core/SessionsService/SetSessionPinnedLocationId',
          pinnedLocationInfo
        )
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, this.$t('message.error.shipSaving'))
        }
      }
    },
    myEventHandler (e) {
      $('body').removeClass('fixed-sidebar')
      if (e.target.innerWidth > 1352) {
        $('body').removeClass('mini-navbar')
        $('body').removeClass('body-small')
      } else if (e.target.innerWidth > 1024) {
        $('body').addClass('mini-navbar')
        $('body').removeClass('body-small')
      } else {
        $('body').addClass('body-small')
        $('body').removeClass('mini-navbar')
      }
    },
    async logout () {
      this.toggleModal()
    },
    getMatchPath (route) {
      return route.matchPath.reduce((res, path) => {
        return res || (route.exact ? this.pathname === path : this.pathname.includes(path))
      }, false)
    },
    isPathActive (route) {
      const pathName = this.pathname.split('/')[1]
      let matched = route.matchPath.includes(pathName)
      if (!matched && pathName !== '') {
        matched = this.pathname.startsWith('/' + route.matchPath[0])
      }
      if (!matched && pathName === '' && route.matchPath[0] === '/') {
        matched = true
      }
      return matched
    },
    highlightMainRouteIcon (routes) {
      const result = routes.some(route => {
        if (route.children) {
          return route.children.some(ch => {
            if (!ch.matchPath) return false
            return this.getMatchPath(ch)
          })
        }
        return this.getMatchPath(route)
      })
      return result ? 'active-url' : ''
    },
    highlightMainRoute (route) {
      const matched = this.getMatchPath(route)
      const routeClassName = !route.children ? 'mm-active-url' : 'mm-active'
      return matched ? routeClassName : ''
    },
    highlightNestedRoute (route) {
      return this.getMatchPath(route) ? 'active-url mm-active' : ''
    },
    toggleModal () {
      $('#ship-address').modal('show')
    },
    navGuard (route) {
      if (this.platformNavigation) return true
      return (route.jobSiteUser && this.jobSiteUser == route.jobSiteUser) ||
        (route.wareHouseUser && this.wareHouseUser == route.wareHouseUser) ||
        (route.backOfficeUser && this.backOfficeUser == route.backOfficeUser)
    },
    removeSideBar () {
      if (window.innerWidth <= 1024) {
        $('body').removeClass('fixed-sidebar')
      }
    },
    collapseMenu () {
      $('#page-wrapper').on('click', () => {
        this.removeSideBar()
      })
    },
    setMMPosition (element) {
      $.fn.isInViewport = function () {
        const elementTop = $(this).offset().top
        const elementBottom = elementTop + $(this).outerHeight()
        const viewportTop = $(window).scrollTop()
        const viewportBottom = viewportTop + $(window).height()
        return elementBottom < viewportBottom
      }
      if ($(element).hasClass('nav-second-level')) {
        const isInView = $(element).isInViewport()
        const css = { bottom: '' }
        var eTop = $(element).parent().offset().top
        var sTop = $(window).scrollTop()
        var top = eTop - sTop
        if (!isInView) {
          css.top = ''
          css.bottom = -44
        } else {
          css.top = top + 44
          css.bottom = ''
        }
        $(element).css(css)
      }
    },
    updateHoverEffects () {
      $('.mini-navbar .main-route').hover((e) => {
        this.setMMPosition($(e.currentTarget).find('ul:first'))
      })
      $('.mini-navbar .nested-route').click((e) => {
        var element = $(e.currentTarget)
        $(element).parent().parent().parent().children().find('.mm-active')
          .children('a').attr('aria-expanded', 'false')
        $(element).parent().parent().parent().children().removeClass('mm-active')
        $(element).parent().parent().parent().children().removeClass('mm-show')
        $(element).parent().parent('.main-route').addClass('mm-active')
        $(element).parent().addClass('mm-show')
        $(element).parent().parent().parent().children().find('.mm-active')
          .children('a').attr('aria-expanded', 'true')
      })
    }
  },
  mounted: function () {
    if (window.innerWidth <= 1352 && window.innerWidth > 1024) {
      $('body').removeClass('body-small')
      $('body').addClass('mini-navbar')
    } else if (window.innerWidth <= 1024) {
      $('body').addClass('body-small')
    }
    // Minimalize menu
    $('body').on('click', '.navbar-minimalize', (event) => {
      event.preventDefault()
      if (window.innerWidth > 1024) {
        $('body').toggleClass('mini-navbar')
      } else {
        $('body').toggleClass('fixed-sidebar')
        if (!this.isShowWorkForceNav && $('body').hasClass('fixed-sidebar')) {
          $('#top-bar .nav li:nth-child(3)').removeClass('mm-active-url')
          $('nav').addClass('wfm-nav-height')
        }
        if (!this.isShowWorkForceNav && !$('body').hasClass('fixed-sidebar')) {
          $('#top-bar .nav li:nth-child(3)').addClass('mm-active-url')
        }
        if (this.isShowWorkForceNav) {
          $('nav').removeClass('wfm-nav-height')
        }
      }
      if (process.env.VUE_APP_BRAND != 'TOOLOPS') {
        var els = document.getElementsByClassName('nav-second-level')
        if ($('body').hasClass('mini-navbar')) {
          els.forEach(element => {
            element.style.borderRadius = '10px'
          })
        } else {
          els.forEach(element => {
            element.style.borderRadius = ''
          })
        }
      }
      this.updateHoverEffects()
    })
    $('li.main-route a:not(.has-arrow)').on('click', (event) => {
      event.preventDefault()
      this.removeSideBar()
    })
    this.collapseMenu()
    this.updateHoverEffects()
    $('.mini-navbar .main-route a').click(function () {
      if ($(this).attr('href') !== undefined && !$(this).parent().hasClass('nested-route') && !$(this).hasClass('has-arrow')) {
        $('.mini-navbar .main-route.mm-active').removeClass('mm-active')
      }
    })

    $('.sidebar-collapse').scroll(function () {
      if ($('body').hasClass('mini-navbar')) {
        $('.mini-navbar .nav .nav-second-level').each(function () {
          var top = $(this).parent().offset().top - $(window).scrollTop() + 44
          $(this).css({ top: top, bottom: '' })
        })
      }
    })

    $('#side-menu').metisMenu()
    var brand = 'alignOps'
    if (process.env.VUE_APP_BRAND == 'TOOLOPS') {
      brand = 'sage'
    }
    $('#wrapper').attr('class', 'brand-' + brand)
  },
  updated: function () {
    if (window.innerWidth <= 1352 && window.innerWidth > 1024) {
      $('body').removeClass('body-small')
      $('body').addClass('mini-navbar')
    } else if (window.innerWidth <= 1024) {
      $('body').addClass('body-small')
    } else {
      $('body').removeClass('body-small')
    }
    this.collapseMenu()
  },
  watch: {
    $route (to) {
      this.clicked = true
      this.pathname = to.fullPath
    }
  },
  computed: {
    fullName () {
      return this.$store.getters.fullName
    },
    brandLogo () {
      return this.$store.getters.brandLogo
    },
    hideNav () {
      const cookies = cookie.parse(document.cookie || '')
      if (cookies['tw.cloud.hideNav'] === 'true') {
        return 'none'
      } else {
        return 'block'
      }
    },
    cart () {
      const cart = this.$store.getters.cart
      setProcoreShipTo(cart)
      return cart
    },
    jobSiteUser () {
      return this.$store.getters.jobSiteUser === 0
    },
    wareHouseUser () {
      return this.$store.getters.jobSiteUser === 1
    },
    backOfficeUser () {
      return this.$store.getters.jobSiteUser === 2
    },
    orgName () {
      return localStorage.getItem('OrgName') ?? ''
    },
    brandFooter () {
      if (process.env.VUE_APP_BRAND === 'TOOLOPS') {
        if (this.jobSiteUser) {
          return 'sidebar-footer-absolute'
        } else {
          return 'sidebar-footer-relative'
        }
      } else {
        return 'hide'
      }
    },
    platformNavigation () {
      return this.$store.getters.showPlatformNavigation
    },
    alignTopLogo () {
      return this.$store.getters.alignTopLogo
    },
    isShowWorkForceNav () {
      return this.$store.getters.isShowWorkForceNav
    }
  },
  directives: {
    tooltip: {
      inserted (el, binding) {
        $(el).tooltip({
          title: binding.value,
          placement: binding.arg,
          trigger: 'hover',
          delay: 200
        })
      }
    }
  }
}
</script>
<style scoped>
:root {
  --secondary-color: #D09433;
  --navbar-bg-color: #2c3238;
  --navbar-menu-bg-color: #4D565F;
  --topBar-active-bg-color: #2C3238;
  --active-menu-text-color: #D09433;
  --second-nested-menus-bg-color: #2C3238;
  --nested-menus-bg-color: #2C3238;
  --mini-navbar-active-menu-bg-color: #4D565F;
  --active-menu-bg-color: #4D565F;
}
.navbar-default a:not([href]):not([tabindex]) {
  color: white;
}
.navbar-default a:not([href]):not([tabindex]):hover {
  color: var(--active-menu-text-color);
}
.main-route a[disabled=disabled] {
  opacity: 0.5;
  /* pointer-events: none; */
}
.main-route a[disabled=disabled]:focus,
.main-route a[disabled=disabled]:hover,
.main-route .mm-active a[disabled=disabled] {
  color: white;
  cursor: inherit;
}
.nav.nav-second-level li a[disabled=disabled] {
  opacity: 0.5;
  /* pointer-events: none; */
}
.nav.nav-second-level li a[disabled=disabled]:focus,
.nav.nav-second-level li a[disabled=disabled]:hover,
.nav.nav-second-level li.mm-active a[disabled=disabled] {
  color: white;
  cursor: inherit;
}
.dropdown-toggle a {
  padding: 0!important;
}
.material-icons {
  font-size: 17px!important;
}
body.mini-navbar li.nested-route ul {
  min-width: 160px;
  max-height: 245px;
  overflow: auto;
  display: none !important;
  scrollbar-width: none;
}
body.mini-navbar li.nested-route ul::-webkit-scrollbar {
  width: 5px;
}
body.mini-navbar li.nested-route ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.8);
}
body.mini-navbar li.nested-route ul::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 2px;
}
body.mini-navbar li.nested-route ul.mm-show {
  display: block!important;
}
.nested-route.active-url a {
  color: var(--active-menu-text-color);
}
.nav.nav-second-level li li a {
  display: block;
  color: white;
}
.nav.nav-second-level li li.mm-active > a {
  color: var(--secondary-color);
}
.nav.nav-second-level li li a span {
  margin: 0;
}
.nav.nav.nav-second-level > li > a:not(.inactive):hover, .nav.nav.nav-second-level > li > a:not(.inactive):focus {
  color: var(--secondary-color);
}
ul.nav-third-level{
  background: var(--second-nested-menus-bg-color);
}
.navbar-static-side {
  position: fixed;
}
.navbar-minimalize {
  position: absolute;
  top: 7px;
  right: 17px;
  z-index: 2000;
}
.body-small .navbar-minimalize {
  position: fixed;
}
.mini-navbar .navbar-minimalize {
  right: -52px;
}
span.portal-name {
  color: #B2B3B4;
  font-size: 14px;
  font-weight: 600;
}
div.org-name {
  color: #B2B3B4;
  font-size: 14px;
  font-weight: 600;
}
.ml-28 {
  margin-left: 28px;
}
.dropdown .profile-element .profile-name:hover,
.dropdown .profile-element .portal-name:hover {
  cursor: pointer;
}
p.lists-container {
  padding-left: 40px;
  margin: 20px 0 10px;
  color: white;
  font-weight: bold;
}
li.lists-routes {
  position: relative;
  padding-left: 10px!important;
}
body.mini-navbar p.lists-container,
body.mini-navbar li.lists-routes {
  padding-left: 0!important;
  text-align: center;
}
ul > li.mini-nav {
  display: none;
}
.mini-navbar .metismenu > li > ul,
.mini-navbar .metismenu ul.nav-second-level {
  height: auto !important;
  max-height: 80vh !important;
  overflow-y: scroll;
  scrollbar-width: none;
}
.mini-navbar .metismenu ul.nav-second-level > li.mini-nav {
  display: block;
  text-align: center;
}
.mini-navbar .metismenu ul.nav-second-level > li.mini-nav a {
  color: white;
}
.mini-navbar .metismenu > li:hover > ul {
  opacity: 1
}
.mini-navbar .metismenu > li:hover > ul > li.nested-route:hover > ul {
  opacity: 1;
}
.body-small.fixed-sidebar nav {
  overflow: auto;
}
@media (max-width: 1024px) {
  .body-small .navbar-minimalize {
    top: 26px;
    left: 16px;
  }
  .body-small.fixed-sidebar .navbar-minimalize {
    position: absolute;
    top: 6px;
    right: 16px;
    left: auto;
  }
  .mini-navbar .navbar-minimalize {
    top: 0px;
    left: 80px;
  }
}
ul.mm-show {
  height: auto!important;
}
.sidebar-footer-absolute {
  color:#FFFFFF;
  font-weight:bold;
  padding: 10px 16px;
  position: absolute;
  bottom: 0;
}
.sidebar-footer-relative {
  color:#FFFFFF;
  font-weight:bold;
  padding: 10px 16px;
  position: relative;
  bottom: 0;
}
.hide {
  display: none;
}
.navbar-default .logo-holder img {
  width: var(--logo-img-width);
  margin-top: var(--logo-img-margin-top);
  margin-left: var(--logo-img-margin-left);
  margin-bottom: var(--logo-img-margin-bootom)
}
.mini-navbar ul.nav-third-level a:hover {
  color: var(--secondary-color);
}
.logo-element{
  max-height: 80px;
  margin-top: var(--logo-img-margin-logo-top);
}
a.disabled {
  pointer-events: none;
  cursor: default;
}
.wfm-nav-height {
  height: 49px!important;
}
.navbar-default .sidebar-collapse-wfm {
  height: 49px;
  overflow-x: visible;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: 5px;
  padding-bottom: 20px;
}
.navbar-default .sidebar-collapse-wfm::-webkit-scrollbar {
  width: 5px;
}
.navbar-default .sidebar-collapse-wfm::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.8);
}
.navbar-default .sidebar-collapse-wfm::-webkit-scrollbar-thumb {
  background-color: rgb(32, 51, 60);
  border-radius: 2px;
}
</style>

const platformRoles = {
  tableHeader: {
    desktop: [
      {
        label: 'Nombre del rol',
        key: 'Name',
        sortable: true
      },
      {
        label: 'Descripción del rol',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Usuarios asignados al rol',
        key: 'UserCount',
        sortable: true
      },
      {
        label: 'Permisos habilitados',
        key: 'PermissionCount',
        sortable: true
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: '',
        key: '',
        isModify: false
      },
      {
        label: 'Información',
        data: [
          {
            label: 'Nombre',
            key: 'Name',
            sortable: true
          }
        ]
      }
    ]
  },
  rolePermissionsTblHeader: {
    desktop: [
      {
        label: 'Permiso',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Acceso / Ver',
        key: 'CanView',
        permission: 'CanView',
        sortable: true
      },
      {
        label: 'Agregar / Editar',
        key: 'CanEdit',
        permission: 'CanEdit',
        sortable: true
      },
      {
        label: 'Borrar',
        key: 'CanDelete',
        permission: 'CanDelete',
        sortable: true
      },
      {
        label: 'Ejecutar',
        key: 'CanExecute',
        permission: 'CanExecute',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: '',
        isModify: false
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Permiso',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Ver',
            key: 'CanView',
            permission: 'CanView',
            sortable: true
          },
          {
            label: 'Agregar / Editar',
            key: 'CanEdit',
            permission: 'CanEdit',
            sortable: true
          },
          {
            label: 'Borrar',
            key: 'CanDelete',
            permission: 'CanDelete',
            sortable: true
          },
          {
            label: 'Ejecutar',
            key: 'CanExecute',
            permission: 'CanExecute',
            sortable: true
          }
        ]
      }
    ]
  },
  labels: {
    0: 'Rol',
    1: 'Nombre del rol:',
    2: 'Clonar rol existente:',
    3: '{userCount} usuarios asignados',
    4: '{enabledCount} de permisos {maxCount}',
    5: 'Descripcion del rol:'
  },
  cloneRoles: {
    select: 'Seleccione un rol desde el que empezar...',
    viewOnly: 'Ver sólo'
  }
}

export default platformRoles

const userRoles = {
  tableHeader: {
    desktop: [
      {
        label: 'Id',
        key: 'RoleId'
      },
      {
        label: 'Nombre',
        key: 'Name',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Id',
        key: 'RoleId'
      },
      {
        label: 'Nombre',
        data: [
          {
            label: 'Nombre',
            key: 'Name',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default userRoles

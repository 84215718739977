const pickedItems = {
  tableHeader: {
    desktop: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Elejido',
        key: 'PickedQty',
        isEditable: true,
        type: Number,
        sortable: true
      },
      {
        label: 'Artículo',
        key: 'ItemNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Descripción del Artículo',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'Fabricante',
        key: 'Manuf',
        type: String,
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'Model',
        type: String,
        sortable: true
      },
      {
        label: 'Elegido por',
        key: 'PickedById',
        type: 'Guid',
        listType: 10,
        sortable: true
      },
      {
        label: 'Transferido',
        key: 'TransferNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Desde código de costo',
        key: 'FromCostCode',
        type: String,
        sortable: true
      },
      {
        label: 'Al código de costo',
        key: 'ToCostCode',
        type: String,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Elegido',
            key: 'PickedQty',
            isEditable: true,
            type: Number,
            sortable: true
          },
          {
            label: 'Artículo',
            key: 'ItemNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Descripción del Artículo',
            key: 'Description',
            type: String,
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'Manuf',
            type: String,
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'Model',
            type: String,
            sortable: true
          },
          {
            label: 'Elegido por',
            key: 'PickedById',
            type: 'Guid',
            listType: 10,
            sortable: true
          },
          {
            label: 'Transferido',
            key: 'TransferNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Desde código de costo',
            key: 'FromCostCode',
            type: String,
            sortable: true
          },
          {
            label: 'Al código de costo',
            key: 'ToCostCode',
            type: String,
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  },
  tableHeaderWithFlag: {
    desktop: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Elejido',
        key: 'PickedQty',
        isEditable: true,
        type: Number,
        sortable: true
      },
      {
        label: 'Artículo',
        key: 'ItemNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Descripción del Artículo',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'Fabricante',
        key: 'Manuf',
        type: String,
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'Model',
        type: String,
        sortable: true
      },
      {
        label: 'Elegido por',
        key: 'PickedById',
        type: 'Guid',
        listType: 10,
        sortable: true
      },
      {
        label: 'Transferido',
        key: 'TransferNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Escoge de',
        key: 'PickedFrom',
        type: String,
        sortable: true
      },
      {
        label: 'Estado',
        key: 'ItemStatus',
        type: String,
        sortable: true
      },
      {
        label: 'De Costo Codeus',
        key: 'FromCostCode',
        type: String,
        sortable: true
      },
      {
        label: 'Al código de costo',
        key: 'ToCostCode',
        type: String,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Elegido',
            key: 'PickedQty',
            isEditable: true,
            type: Number,
            sortable: true
          },
          {
            label: 'Artículo',
            key: 'ItemNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Descripción del Artículo',
            key: 'Description',
            type: String,
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'Manuf',
            type: String,
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'Model',
            type: String,
            sortable: true
          },
          {
            label: 'Elegido por',
            key: 'PickedById',
            type: 'Guid',
            listType: 10,
            sortable: true
          },
          {
            label: 'Transferido',
            key: 'TransferNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Escoge de',
            key: 'PickedFrom',
            type: String,
            sortable: true
          },
          {
            label: 'Estado',
            key: 'ItemStatus',
            type: String,
            sortable: true
          },
          {
            label: 'De Costo Codeus',
            key: 'FromCostCode',
            type: String,
            sortable: true
          },
          {
            label: 'Al código de costo',
            key: 'ToCostCode',
            type: String,
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default pickedItems

const safetyPlanContractorDetail = {
  title: 'Detalle del contratista',
  deleteConfirmation: '¿Está seguro de que desea eliminar el(los) Contratista(s)?',
  btnAddContractor: 'Agregar contratista',
  btnAddAdditionalContact: 'Agregar contacto adicional',
  btnCancel: 'Cancelar',
  btnSave: 'Ahorrar',
  errorMessage: 'Ya se ha utilizado contacto adicional.',
  labels: {
    1: 'Contacto',
    2: 'Tareas / Responsabilidades',
    3: 'Contacto Principal',
    4: 'Agregar. Contacto',
    5: 'Correo Electrónico',
    6: 'Teléfono'
  },
  contractortableHeader: {
    desktop: [
      {
        label: '',
        key: 'SafetyplanContractorId',
        type: 'check'
      },
      {
        label: 'Nombre',
        key: 'ContractorName',
        sortable: true
      },
      {
        label: 'Tareas / Responsabilidades',
        key: 'TaskResponsibility',
        sortable: true
      },
      {
        label: 'Contacto Principal',
        key: 'MaincontactEntityName',
        sortable: true
      },
      {
        label: 'Agregar. Contacto',
        key: 'AdditionalcontactEntityCount',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'SafetyplanContractorId',
        type: 'check'
      },
      {
        label: 'Información',
        data: [
          {
            label: 'Nombre',
            key: 'ContractorName',
            sortable: true
          },
          {
            label: 'Tareas / Responsabilidades',
            key: 'TaskResponsibility',
            sortable: true
          },
          {
            label: 'Contacto Principal',
            key: 'MaincontactEntityName',
            sortable: true
          },
          {
            label: 'Agregar. Contacto',
            key: 'AdditionalcontactEntityCount',
            sortable: true
          }
        ]
      }
    ]
  },
  dropdownActions: [
    {
      title: 'Editar',
      actionName: 'editContractors',
      permissions: []
    },
    {
      title: 'Borrar',
      actionName: 'confirmDeltetContractors',
      permissions: []
    }
  ]
}
export default safetyPlanContractorDetail

const stockList = {
  tableHeader: {
    desktop: [
      {
        label: 'Ubicación de Almacen',
        key: 'location',
        sortable: true
      },
      {
        label: 'En abastecimiento',
        key: 'inStock',
        sortable: true
      },
      {
        label: 'Bajo',
        key: 'lowLevel',
        sortable: true,
        editable: true
      },
      {
        label: 'Objetivo',
        key: 'targetLevel',
        sortable: true,
        editable: true
      },
      {
        label: 'Alto',
        key: 'highLevel',
        sortable: true,
        editable: true
      },
      {
        label: 'Compartimiento',
        key: 'bin',
        sortable: true,
        editable: true,
        isText: true
      }
    ],
    mobile: [
      {
        label: 'Ubicación de Almacen',
        key: 'location',
        sortable: true
      },
      {
        label: 'Información',
        data: [
          {
            label: 'En abastecimiento',
            key: 'inStock',
            sortable: true
          },
          {
            label: 'Bajo',
            key: 'lowLevel',
            sortable: true,
            editable: true
          },
          {
            label: 'Objetivo',
            key: 'targetLevel',
            sortable: true,
            editable: true
          },
          {
            label: 'Alto',
            key: 'highLevel',
            sortable: true,
            editable: true
          },
          {
            label: 'Compartimiento',
            key: 'bin',
            sortable: true,
            editable: true,
            isText: true
          }
        ]
      }
    ]
  }
}

export default stockList

const transfer = {
  tableHeader: {
    desktop: [
      {
        label: ''
      },
      {
        label: ''
      },
      {
        label: 'Equipo'
      },
      {
        label: 'Artículo'
      },
      {
        label: 'Cant.'
      },
      {
        label: 'Descripción'
      },
      {
        label: 'Número De Serie'
      },
      {
        label: 'Desde'
      },
      {
        label: 'Centro de Costo Desde'
      },
      {
        label: 'Estado'
      },
      {
        label: 'Medidor'
      },
      {
        label: 'UP'
      },
      {
        label: 'Dueño'
      },
      {
        label: 'Centro de costo a'
      },
      {
        label: 'Código de costo'
      }
    ],
    mobile: [
      {
        label: ''
      },
      {
        label: ''
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Equipo'
          },
          {
            label: 'Artículo'
          },
          {
            label: 'Cant.'
          },
          {
            label: 'Descripción'
          },
          {
            label: 'Número De Serie'
          },
          {
            label: 'Desde'
          },
          {
            label: 'Centro de Costo Desde'
          },
          {
            label: 'Estado'
          },
          {
            label: 'Metro'
          },
          {
            label: 'UP'
          },
          {
            label: 'Dueño'
          },
          {
            label: 'Centro de costo a'
          },
          {
            label: 'Código de costo'
          }
        ]
      }
    ]
  },
  lineActions: [
    {
      title: 'Eliminar línea'
    }
  ]
}

export default transfer

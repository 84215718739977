const customFields = {
  tableHeader: {
    desktop: [
      {
        label: 'control',
        key: 'CustomFieldDefinitionId',
        isModify: true
      },
      {
        label: 'Objeto relacionado',
        key: 'Table',
        isModify: true
      },
      {
        label: 'Clase de Registro',
        key: 'Table',
        sortable: true,
        type: String
      },
      {
        label: 'Etiqueta',
        key: 'Label',
        sortable: true,
        type: String
      },
      {
        label: 'Descripción',
        key: 'Description',
        sortable: true,
        type: String,
        isModify: false
      },
      {
        label: 'Tipo',
        key: 'DataType',
        sortable: true,
        type: String
      },
      {
        label: 'Creado por',
        key: 'CreatedBy',
        sortable: true,
        type: String,
        isModify: false
      },
      {
        label: 'Creado en',
        key: 'CreatedOn',
        sortable: true,
        type: Date,
        isModify: false
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'control',
        key: 'CustomFieldDefinitionId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Objeto relacionado',
            key: 'Table',
            isModify: true
          },
          {
            label: 'Clase de Registro',
            key: 'Table',
            type: String,
            sortable: true
          },
          {
            label: 'Etiqueta',
            key: 'Label',
            type: String,
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'Description',
            type: String,
            sortable: true,
            isModify: false
          },
          {
            label: 'Tipo',
            key: 'DataType',
            type: String,
            sortable: true
          },
          {
            label: 'Creado por',
            key: 'CreatedBy',
            type: String,
            sortable: String,
            isModify: false
          },
          {
            label: 'Creado en',
            key: 'CreatedOn',
            type: Date,
            sortable: Date,
            isModify: false
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default customFields


import { ref, onMounted, onUnmounted } from '@vue/composition-api'

// query defaults to matching screens that are 1023px or narrower
export const useMediaQueryMatch = (query = '(max-width: 1023px)') => {
  const mediaQueryMatch = ref<MediaQueryList | null>(null)
  const mediaQuery = ref(query)
  const isMatch = ref(false)

  onMounted(() => {
    // create a mediaQueryList based on the provided query
    mediaQueryMatch.value = window.matchMedia(mediaQuery.value)

    if (mediaQueryMatch.value) {
      // set the initial value
      isMatch.value = mediaQueryMatch.value.matches

      // add an event listener for when the match result changes
      // between true and false
      if (mediaQueryMatch.value.addEventListener) {
        mediaQueryMatch.value.addEventListener('change', handleChange)
        return
      }

      // Old versions of Safari (pre v14) can't use Add/RemoveEventListener
      // This is the old way
      mediaQueryMatch.value.addListener(handleChange)
    }
  })

  onUnmounted(() => {
    // remove the event listener if the mediaQueryList exists
    if (mediaQueryMatch.value) {
      if (mediaQueryMatch.value.removeEventListener) {
        mediaQueryMatch.value.removeEventListener('change', handleChange)
        return
      }

      // Old versions of Safari (pre v14) can't use Add/RemoveEventListener
      // This is the old way
      mediaQueryMatch.value.removeListener(handleChange)
    }
  })

  // set the media query match result when match result changes
  function handleChange (e: MediaQueryListEvent) : void {
    isMatch.value = e.matches
  }

  // return the result out of the composable so callers can access it
  return {
    isMatch
  }
}

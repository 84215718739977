const reservations = {
  homeTitle: 'Scheduling',
  searchPlaceholder: 'What would you like to schedule?',
  noResultsMessage: 'There are no results that match your search description.',
  inStock: '{count} In Stock',
  available: '{count} Available',
  startDate: 'Start date',
  endDate: 'End date',
  reservationNumber: 'Reservation No.',
  schedulingNumber: 'Schedule No.',
  late: '(LATE)',
  upcoming: {
    title: 'Submitted',
    noMsg: 'No Submitted Scheduling',
    quantity: 'Quantity',
    items: 'Items'
  },
  delivered: {
    title: 'In Process',
    noMsg: 'No In Process Scheduling'
  },
  reReserve: {
    title: 'Completed',
    noMsg: 'No Completed Scheduling'
  },
  noMsg: 'There are no results that match your search description.',
  stock: {
    inStock: '{count} in stock',
    outofStock: 'Out of stock'
  },
  productInfo: {
    title: 'Product Information',
    labels: {
      0: 'Manufacturer',
      1: 'Model',
      2: 'Catalog #',
      3: 'UPC/ASIN',
      4: 'Year',
      5: 'Model Id'
    }
  },
  productTracking: {
    title: 'Tracking Information',
    labels: {
      0: 'Category',
      1: 'Generic Description',
      2: 'Item Type',
      3: 'Tracking Method',
      4: 'Default Class',
      5: 'Certification',
      6: 'Meter Required'
    }
  },
  cart: {
    title: 'Submit Scheduling',
    desc: 'Items in your scheduling',
    noMsg: 'No Scheduling'
  },
  checkout: {
    subtitle: 'Review your scheduling'
  },
  history: {
    title: 'Scheduling History',
    placeholder: 'Search all scheduling',
    labels: {
      0: 'By Item',
      1: 'By Scheduling',
      2: 'Schedule Again'
    },
    filter: {
      status: {
        0: 'All',
        1: 'Open',
        2: 'Closed',
        3: 'Draft',
        4: 'Submitted',
        5: 'Completed'
      },
      time: {
        0: 'Last 30 days',
        1: 'Past 3 months'
      }
    },
    itemDetail: {
      status: 'Status',
      quantity: 'Qty',
      startDate: 'Start Date',
      document: 'Document',
      source: 'Source',
      endDate: 'End Date'
    }
  },
  cartSummaryTitle: 'Scheduling Summary'
}

export default reservations

const workflowBuilder = {
  createWorkflow: {
    title: '',
    workflowMapTitle: 'Workflow Map',
    workflowMapDescription: 'Workflow Map Description',
    workflowLegend: {
      labels: {
        0: 'Trigger',
        1: 'Task',
        2: 'Last Task',
        3: 'Approval'
      },
      description: {
        0: 'Trigger is the event in ToolWatch that starts the workflow',
        1: 'Step is an action captured as a task to be completed',
        2: 'Last step will close the workflow when completed',
        3: 'Approval is a step to review and approve/reject before the next step'
      }
    },
    labels: {
      0: 'Workflow Name',
      1: 'Workflow Steps',
      2: 'Description',
      3: 'Workflow Duration',
      4: 'Trigger',
      5: 'Single',
      6: 'Series',
      7: 'Parallel',
      8: 'Set as Active?',
      9: 'Org Tree Branch'
    },
    messages: {
      saved: 'Workflow Saved Successfully',
      deleted: 'Workflow Deleted Successfully',
      stepDeleted: 'Workflow Steps Deleted Successfully',
      canDelete: 'Delete Workflow',
      deleteStep: 'Delete Workflow Task',
      willDelete: 'Do you really want to delete this Workflow?',
      stepError: 'Update all tasks before save',
      deleteWordkflow: 'Are you sure you want to delete?'
    },
    actionHeader: {
      0: {
        title: 'Add Task',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Add Approval',
        icon: 'fas fa-plus'
      },
      2: {
        title: 'Edit',
        icon: ''
      },
      3: {
        title: 'Delete',
        icon: ''
      }
    }
  },
  step: {
    modalHeader: {
      title: 'Create Task',
      titleEstTime: 'Select Estimated Time',
      subject: 'Subject',
      category: 'Task Category',
      toolWatchLocation: 'System Location',
      otherLocation: 'Other Location',
      assignedTo: 'Assigned To',
      estTime: 'Est. Time',
      priority: 'Priority',
      status: 'Status',
      notes: 'Notes',
      editStepTitle: 'Edit Task',
      locationValidationMsg: 'ToolWatch Location is required',
      otherLocationValidationMsg: 'Other Location is required',
      estTimeDateValidationMsg: 'Est. Time is required for Recurring Step',
      approvalModaltitle: 'Create Approval',
      editapprovalModaltitle: 'Edit Approval',
      approver: 'Approver'
    },
    buttons: {
      recurrence: 'Recurrence',
      attach: 'Attachments',
      addAndNext: 'Add and Next',
      add: 'Add',
      cancel: 'Cancel',
      save: 'Save',
      saveStepComplete: 'Save, Step Complete',
      BtnSelectTime: 'Select'
    },
    messages: {
      saved: 'Step created successfully.',
      savedApproval: 'Approval step created successfully.',
      editApproval: 'Approval edited successfully.'
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'StepId',
          type: 'check'
        },
        {
          label: 'Subject',
          key: 'Subject',
          type: String
        },
        {
          label: 'Type',
          key: 'Type',
          type: String
        },
        {
          label: 'Assigned To',
          key: 'AssignedTo',
          type: String
        }
      ],
      mobile: [
        {
          label: '',
          key: 'StepId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Subject',
              key: 'Subject',
              type: String
            },
            {
              label: 'Type',
              key: 'Type',
              type: String
            },
            {
              label: 'Assigned To',
              key: 'AssignedTo',
              type: String
            }
          ]
        }
      ]
    }
  },
  tableHeader: {
    desktop: [
      {
        label: 'Active',
        key: 'active',
        sortable: true
      },
      {
        label: 'Org Tree Branch',
        key: 'OrgTreeBranch',
        sortable: true
      },
      {
        label: 'Workflow Name',
        key: 'WorkflowName',
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Trigger',
        key: 'TriggerDescription',
        sortable: true
      },
      {
        label: 'Created By',
        key: 'CreatedBy',
        sortable: true
      },
      {
        label: 'Created On',
        key: 'CreatedOn',
        sortable: true
      },
      {
        label: 'Step Count',
        key: 'StepCount',
        sortable: true
      },
      {
        label: 'Workflow Duration',
        key: 'WorkflowDuration',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Workflow Name',
        key: 'WorkflowName',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Active',
            key: 'active',
            sortable: true
          },
          {
            label: 'Org Tree Branch',
            key: 'OrgTreeBranch',
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Trigger',
            key: 'TriggerDescription',
            sortable: true
          },
          {
            label: 'Created By',
            key: 'CreatedBy',
            sortable: true
          },
          {
            label: 'Created On',
            key: 'CreatedOn',
            sortable: true
          },
          {
            label: 'Step Count',
            key: 'StepCount',
            sortable: true
          },
          {
            label: 'Workflow Duration',
            key: 'WorkflowDuration',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default workflowBuilder

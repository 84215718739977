import { deepCopy, getFormatedDateString, uniq } from '../helpers/utils'

export default {
  state: () => ({
    cloneInspectionsData: {},
    cloneFormsData: {},
    cloneJsasData: {},
    cloneTrainingsData: {},
    cloneScansData: {},
    safetyAttachmentsData: {},
    safetyCertificationsData: {},
    safetyPPEsData: {},
    safetyHazardsData: {},
    safetyStakeholdersData: {},
    safetyContractorsData: {},
    safetyRecordInfoData: {}
  }),
  mutations: {
    addRecordInfo (state, item) {
      state.safetyRecordInfoData = item
    },
    addSafetyContractors (state, item) {
      state.safetyContractorsData = item
    },
    addSafetyStakeholders (state, item) {
      state.safetyStakeholdersData = item
    },
    addSafetyHazards (state, item) {
      state.safetyHazardsData = item
    },
    addSafetyPPEs (state, item) {
      state.safetyPPEsData = item
    },
    addSafetyCertifications (state, item) {
      state.safetyCertificationsData = item
    },
    addSafetyAttachments (state, item) {
      state.safetyAttachmentsData = item
    },
    addCloneInspections (state, item) {
      state.cloneInspectionsData = item
    },
    addCloneForms (state, item) {
      state.cloneFormsData = item
    },
    addCloneJsas (state, item) {
      state.cloneJsasData = item
    },
    addCloneTrainings (state, item) {
      state.cloneTrainingsData = item
    },
    addCloneScans (state, item) {
      state.cloneScansData = item
    },
    clearCloneData (state) {
      state.cloneInspectionsData = {}
      state.cloneFormsData = {}
      state.cloneJsasData = {}
      state.cloneTrainingsData = {}
      state.cloneScansData = {}
      state.safetyAttachmentsData = {}
      state.safetyCertificationsData = {}
      state.safetyPPEsData = {}
      state.safetyHazardsData = {}
      state.safetyStakeholdersData = {}
      state.safetyContractorsData = {}
      state.safetyRecordInfoData = {}
    }
  },
  getters: {
    SafetyAttachmentsData (state) {
      return state.safetyAttachmentsData
    },
    SafetyCertificationsData (state) {
      return state.safetyCertificationsData
    },
    SafetyPPEsData (state) {
      return state.safetyPPEsData
    },
    SafetyHazardsData (state) {
      return state.safetyHazardsData
    },
    SafetyStakeholdersData (state) {
      return state.safetyStakeholdersData
    },
    SafetyContractorsData (state) {
      return state.safetyContractorsData
    },
    SafetyRecordInfoData (state) {
      return state.safetyRecordInfoData
    },
    cloneInspectionsData (state) {
      return state.cloneInspectionsData
    },
    cloneFormsData (state) {
      return state.cloneFormsData
    },
    cloneJsasData (state) {
      return state.cloneJsasData
    },
    cloneTrainingsData (state) {
      return state.cloneTrainingsData
    },
    cloneScansData (state) {
      return state.cloneScansData
    }
  }
}

const rateSheetView = {
  tableHeader: {
    desktop: [
      {
        label: 'Categoría',
        key: 'Category',
        type: String,
        isEditable: false,
        isTool: false,
        sortable: true
      },
      {
        label: 'Descripción del Artículo',
        key: 'Description',
        type: String,
        isEditable: true,
        isTool: false,
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'Model',
        type: String,
        isEditable: false,
        sortable: true
      },
      {
        label: 'ID de modelo',
        key: 'MasterNumber',
        type: Number,
        isEditable: false,
        sortable: true
      },
      {
        label: 'No cargar',
        key: 'DoNotBill',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [0, 1, 2, 3, 4, -1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Valor base',
        key: 'BaseCost',
        type: Number,
        isEditable: true,
        isTool: false,
        sortable: true
      },
      {
        label: 'Método de cálculo',
        key: 'BillingCalculationMethodsId',
        type: Number,
        isEditable: true,
        isTool: false,
        sortable: true,
        filterOptions: 'calcMethods',
        filterBy: 'Value'
      },
      {
        label: 'Tasa de uso por hora%',
        key: 'HourlyRatePercent',
        baseKey: 'HourlyRate',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tasa de uso por hora',
        key: 'HourlyRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tasa efectiva diaria por hora%',
        key: 'DailyRatePercent',
        baseKey: 'DailyRate',
        perType: 0,
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tasa efectiva diaria por hora',
        key: 'DailyRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tarifa diaria',
        key: 'DailyTotal',
        baseKey: 'DailyRate',
        perType: 0,
        type: 'perHour',
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: '% De tarifa semanal efectiva por hora',
        key: 'WeeklyRatePercent',
        baseKey: 'WeeklyRate',
        type: 'percent',
        perType: 1,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tasa efectiva semanal por hora',
        key: 'WeeklyRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tarifa semanal',
        key: 'WeeklyTotal',
        baseKey: 'WeeklyRate',
        type: 'perHour',
        perType: 1,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tasa mensual efectiva por hora%',
        key: 'MonthlyRatePercent',
        baseKey: 'MonthlyRate',
        type: 'percent',
        perType: 2,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tarifa efectiva mensual por hora',
        key: 'MonthlyRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tasa mensual',
        key: 'MonthlyTotal',
        baseKey: 'MonthlyRate',
        type: 'perHour',
        perType: 2,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Usar tasa de inactividad',
        key: 'IdleRateEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [2],
        isTool: true,
        sortable: true
      },
      {
        label: '% De tasa de inactividad',
        key: 'IdleRatePercent',
        baseKey: 'IdleRate',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tasa de inactividad por hora',
        key: 'IdleRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Utilice Maint. Tarifa de cargo por artículo',
        key: 'MaintenanceRateEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Utilice Maint. Tasa de cargo por trabajo',
        key: 'MaintenanceRateEnabledAtJob',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tasa Maint. al% de Ascenso',
        key: 'MaintenanceRatePromoteAtPercent',
        baseKey: 'MaintenanceRatePromoteAt',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Tarifa de Maint. de Ascenso a $',
        key: 'MaintenanceRatePromoteAt',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Maint. Velocidad %',
        key: 'MaintenanceRatePercent',
        baseKey: 'MaintintenanceRate',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Maint. Tarifa por hora',
        key: 'MaintintenanceRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Carga inicial %',
        key: 'InitialChargePercent',
        baseKey: 'InitialCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Cargo inicial',
        key: 'InitialCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: '% De crédito de devolución',
        key: 'ReturnCreditPercent',
        baseKey: 'ReturnCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Crédito de devolución',
        key: 'ReturnCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Utilice Min. Cargo',
        key: 'MinChargeEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Min. Cargo %',
        key: 'MinimumChargePercent',
        baseKey: 'MinCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Min. Cargo',
        key: 'MinCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Usar cargo máxima del artículo',
        key: 'MaxChargeEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Usar cargo de trabajo máximo',
        key: 'MaxChargeEnabledAtJob',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Usar cargo máxima por período',
        key: 'MaxPeriodEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: '% De cargo máxima',
        key: 'MaxChargePercent',
        baseKey: 'MaxCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Cargo máxima',
        key: 'MaxCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Usar cargo por pérdida',
        key: 'LossChargeEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Cargo por pérdida%',
        key: 'LossChargePercent',
        baseKey: 'LossCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Cargo por pérdida',
        key: 'LossCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Cargar pérdida neta',
        key: 'LossChargeDeductFromTotal',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Código de costo',
        key: 'CostCodeId',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: false,
        sortable: true
      },
      {
        label: 'Cuenta GL Charge',
        key: 'ChargeAccountNo',
        type: 'text',
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: false,
        sortable: true
      },
      {
        label: 'Cuenta GL Credit',
        key: 'CreditAccountNo',
        type: 'text',
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: false,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            key: 'Category',
            type: String,
            isEditable: false,
            isTool: false,
            sortable: true
          },
          {
            label: 'Descripción del Artículo',
            key: 'Description',
            type: String,
            isEditable: true,
            isTool: false,
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'Model',
            type: String,
            isEditable: false,
            sortable: true
          },
          {
            label: 'ID de modelo',
            key: 'MasterNumber',
            type: Number,
            isEditable: false,
            sortable: true
          },
          {
            label: 'No cargar',
            key: 'DoNotBill',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [0, 1, 2, 3, 4, -1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Valor base',
            key: 'BaseCost',
            type: Number,
            isEditable: true,
            isTool: false,
            sortable: true
          },
          {
            label: 'Método de cálculo',
            key: 'BillingCalculationMethodsId',
            type: Number,
            isEditable: true,
            isTool: false,
            sortable: true
          },
          {
            label: 'Tasa de uso por hora%',
            key: 'HourlyRatePercent',
            baseKey: 'HourlyRate',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Tasa de uso por hora',
            key: 'HourlyRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Tasa efectiva diaria por hora%',
            key: 'DailyRatePercent',
            baseKey: 'DailyRate',
            perType: 0,
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Tasa efectiva diaria por hora',
            key: 'DailyRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Tarifa diaria',
            key: 'DailyTotal',
            baseKey: 'DailyRate',
            perType: 0,
            type: 'perHour',
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: '% De tarifa semanal efectiva por hora',
            key: 'WeeklyRatePercent',
            baseKey: 'WeeklyRate',
            type: 'percent',
            perType: 1,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Tasa efectiva semanal por hora',
            key: 'WeeklyRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Tarifa semanal',
            key: 'WeeklyTotal',
            baseKey: 'WeeklyRate',
            type: 'perHour',
            perType: 1,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Tasa mensual efectiva por hora%',
            key: 'MonthlyRatePercent',
            baseKey: 'MonthlyRate',
            type: 'percent',
            perType: 2,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Tarifa efectiva mensual por hora',
            key: 'MonthlyRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Tasa mensual',
            key: 'MonthlyTotal',
            baseKey: 'MonthlyRate',
            type: 'perHour',
            perType: 2,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Usar tasa de inactividad',
            key: 'IdleRateEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [2],
            isTool: true,
            sortable: true
          },
          {
            label: '% De tasa de inactividad',
            key: 'IdleRatePercent',
            baseKey: 'IdleRate',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Tasa de inactividad por hora',
            key: 'IdleRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Utilice Maint. Tarifa de cargo por artículo',
            key: 'MaintenanceRateEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Utilice Maint. Tasa de cargo por trabajo',
            key: 'MaintenanceRateEnabledAtJob',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Asciende a Maint. Tasa al%',
            key: 'MaintenanceRatePromoteAtPercent',
            baseKey: 'MaintenanceRatePromoteAt',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Asciende a Maint. Tarifa a $',
            key: 'MaintenanceRatePromoteAt',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Maint. Velocidad %',
            key: 'MaintenanceRatePercent',
            baseKey: 'MaintintenanceRate',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Maint. Tarifa por hora',
            key: 'MaintintenanceRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Carga inicial %',
            key: 'InitialChargePercent',
            baseKey: 'InitialCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Carga inicial',
            key: 'InitialCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: '% De crédito de devolución',
            key: 'ReturnCreditPercent',
            baseKey: 'ReturnCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Crédito de devolución',
            key: 'ReturnCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Utilice Min. Cargo',
            key: 'MinChargeEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Min. Cargo %',
            key: 'MinimumChargePercent',
            baseKey: 'MinCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Min. Cargo',
            key: 'MinCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Usar carga máxima del artículo',
            key: 'MaxChargeEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Usar cargo de trabajo máximo',
            key: 'MaxChargeEnabledAtJob',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Usar cargo máxima por período',
            key: 'MaxPeriodEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: '% De cargo máxima',
            key: 'MaxChargePercent',
            baseKey: 'MaxCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Cargo máxima',
            key: 'MaxCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Usar cargo por pérdida',
            key: 'LossChargeEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Cargo por pérdida%',
            key: 'LossChargePercent',
            baseKey: 'LossCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Cargo por pérdida',
            key: 'LossCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Cargar pérdida neta',
            key: 'LossChargeDeductFromTotal',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Código de costo',
            key: 'CostCodeId',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: false,
            sortable: true
          },
          {
            label: 'Cuenta GL Charge',
            key: 'ChargeAccountNo',
            type: 'text',
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: false,
            sortable: true
          },
          {
            label: 'Cuenta GL Credit',
            key: 'CreditAccountNo',
            type: 'text',
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: false,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default rateSheetView

const fuelTransactionImportTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Card Number',
        key: 'CardNumber',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Item Number',
        key: 'ItemNumber',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Transaction Created',
        key: 'TransactionCreated',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Purchase Transaction Id',
        key: 'PurchaseTransactionId',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Product',
        key: 'Product',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Qty',
        key: 'Qty',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Cost Each',
        key: 'CostEach',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Total Fuel Cost',
        key: 'TotalFuelCost',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Address',
        key: 'Address',
        sortable: true,
        isEditable: false
      },
      {
        label: 'City',
        key: 'City',
        sortable: true,
        isEditable: false
      },
      {
        label: 'State',
        key: 'State',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Zip',
        key: 'Zip',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Driver Last Name',
        key: 'DriverLastName',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Driver First Name',
        key: 'DriverFirstName',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Employee Number',
        key: 'EmployeeNumber',
        sortable: true,
        isEditable: false
      },
      {
        label: '',
        key: 'ImportStatus',
        sortable: false,
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'Employee Number',
        key: 'EmployeeNumber'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Card Number',
            key: 'CardNumber',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Item Number',
            key: 'ItemNumber',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Transaction Created',
            key: 'TransactionCreated',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Purchase Transaction Id',
            key: 'PurchaseTransactionId',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Product',
            key: 'Product',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Qty',
            key: 'Qty',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Cost Each',
            key: 'CostEach',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Total Fuel Cost',
            key: 'TotalFuelCost',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Address',
            key: 'Address',
            sortable: true,
            isEditable: false
          },
          {
            label: 'City',
            key: 'City',
            sortable: true,
            isEditable: false
          },
          {
            label: 'State',
            key: 'State',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Zip',
            key: 'Zip',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Driver Last Name',
            key: 'DriverLastName',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Driver First Name',
            key: 'DriverFirstName',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Employee Number',
            key: 'EmployeeNumber',
            sortable: true,
            isEditable: false
          },
          {
            label: '',
            key: 'ImportStatus',
            sortable: false,
            isModify: false
          }
        ]
      }
    ]
  }
}
export default fuelTransactionImportTable

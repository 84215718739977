const documentCentral = {
  title: 'Central de documentos',
  actionHeader: {
    0: {
      title: 'Subir archivo',
      icon: 'fas fa-upload'
    },
    1: {
      title: 'Agregar carpeta',
      icon: 'fas fa-plus'
    },
    2: {
      title: 'Papelera de reciclaje',
      icon: 'fas fa-recycle'
    }
  },
  selectFolderForm: {
    title: 'Seleccione Nueva ubicación',
    currentLocation: 'Ubicación actual:',
    message: {
      0: '¿Mover',
      1: 'a `{location}`?'
    },
    not_allowed: '¡No se puede mover la selección a `{location}`!'
  },
  placeholder: {
    0: 'Qué estás buscando?'
  },
  uploadDocumentsForm: {
    title: 'Subir documento(s) nuevo(s)',
    labels: {
      0: 'Seleccionar archivo(s) para cargar',
      1: 'Subir desde',
      2: 'Ubicación'
    }
  },
  addFolderForm: {
    title: 'Crear nueva carpeta',
    labels: {
      0: 'Nombre de carpeta',
      1: 'Descripción',
      2: 'Ubicación'
    },
    duplicateFolderErrorMessage: 'Ya existe una carpeta con el mismo nombre ({folderName}) en esta ubicación.'
  },
  verifyDeleteForm: {
    nothing: 'Nada seleccionado',
    proceed: '¿Está seguro de que desea continuar?',
    documents: {
      title: 'Eliminar documento(s)',
      plural: 'Está a punto de eliminar documentos {count}-',
      singular: 'Está a punto de eliminar este documento-',
      restore: 'Todos los documentos eliminados residirán en la Papelera de reciclaje. Se pueden restaurar desde allí si es necesario.'
    },
    folders: {
      title: 'Eliminar carpeta(s)',
      plural: 'Está a punto de eliminar {count} carpetas-',
      singular: 'Está a punto de eliminar esta carpeta-'
    },
    documentsAndFolders: {
      title: 'Eliminar documento(s) y carpeta(s)',
      plural: '\n' +
        'Está a punto de eliminar {documentCount} documentos y {folderCount} carpetas-'
    }
  },
  folderDetailsForm: {
    title: 'Detalles de la carpeta',
    details: 'Detalles',
    revisions: 'Revisiones',
    labels: {
      0: 'Nombre',
      1: 'Descripción',
      2: 'Ubicación'
    }
  },
  documentDetailsForm: {
    title: 'Detalles del documento',
    details: 'Detalles',
    revisions: 'Revisiones',
    labels: {
      0: 'Nombre',
      1: 'Descripción',
      2: 'ID',
      3: 'Subido',
      4: 'Subido por',
      5: 'Ubicación'
    }
  },
  revisionColumns: {
    0: 'Revisión',
    1: 'Modificado por',
    2: 'Modificado el'
  },
  dropdownActions: [
    {
      title: 'Detalles',
      actionName: 'editItem',
      permissions: []
    },
    {
      title: 'Descargar',
      actionName: 'downloadItem',
      permissions: []
    },
    {
      title: 'Mover',
      actionName: 'moveItem',
      permissions: []
    },
    {
      title: 'Borrar',
      actionName: 'deleteItem',
      permissions: []
    }
  ],
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'ItemId',
        type: 'check'
      },
      {
        label: 'Título',
        key: 'Name',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Tipo',
        key: 'MimeType',
        sortable: true
      },
      {
        label: 'Subido',
        key: 'CreatedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Subido por',
        key: 'AuthorName',
        sortable: true
      },
      {
        label: 'I.D.',
        key: 'ItemId',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'ItemId',
        type: 'check'
      },
      {
        label: 'Título',
        key: 'Name',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Tipo',
        key: 'MimeType',
        sortable: true
      },
      {
        label: 'Subido',
        key: 'CreatedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Subido por',
        key: 'AuthorName',
        sortable: true
      },
      {
        label: 'I.D.',
        key: 'ItemId',
        sortable: true
      }
    ]
  },
  recycleBin: {
    title: 'Papelera de reciclaje',
    searchLabel: {
      0: 'Mostrar elementos reciclados entre',
      1: 'y'
    },
    actionHeader: {
      0: {
        title: 'Repositorio central de documentos',
        icon: 'fas fa-folder'
      }
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'RecordId',
          type: 'check'
        },
        {
          label: 'Título',
          key: 'Name',
          sortable: true
        },
        {
          label: 'Tipo',
          key: 'MimeType',
          sortable: true
        },
        {
          label: 'Eliminada',
          key: 'RecycledOn',
          type: Date,
          sortable: true
        },
        {
          label: 'I.D.',
          key: 'RecordId',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'RecordId',
          type: 'check'
        },
        {
          label: 'Título',
          key: 'Name',
          sortable: true
        },
        {
          label: 'Tipo',
          key: 'MimeType',
          sortable: true
        },
        {
          label: 'Eliminada',
          key: 'RecycledOn',
          type: Date,
          sortable: true
        },
        {
          label: 'I.D.',
          key: 'RecordId',
          sortable: true
        }
      ]
    },
    dropdownActions: [
      {
        title: 'Ver ítem',
        actionName: 'viewItem',
        permissions: []
      },
      {
        title: 'Reintegrar',
        actionName: 'reinstate',
        permissions: []
      }
    ],
    detailsModal: {
      title: 'Detalles del artículo de reciclaje',
      labels: {
        0: 'Nombre',
        1: 'Reciclado en',
        2: 'Reciclado por',
        3: 'Reciclado de carpeta',
        4: 'Tipo',
        5: 'ID'
      }
    }
  }

}

export default documentCentral

const scheduling = {
  cart: {
    title: 'Carro de programación',
    desc: 'Artículos en su programación',
    noMsg: 'Sin carrito',
    dateRange: 'Rango de fechas de programación',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha final',
    datesRequired: 'Seleccione una fecha de inicio y una fecha de finalización válidas',
    costCenter: 'Centro de costos',
    actionHeader: {
      0: {
        title: 'Colaborar',
        icon: 'fa-regular fa-comment-dots',
        key: 'collaborate',
        isShow: true
      }
    }
  },
  checkout: {
    title: 'Verificar',
    desc: 'Revisa tu pago',
    dateMessage: 'La fecha de regreso debe ser mayor o igual a la fecha necesaria',
    labels: {
      0: 'Envia a',
      1: 'Ubicación',
      2: 'Fecha necesaria',
      3: 'Comentarios',
      4: 'Revisar artículos',
      5: 'Volver a',
      6: 'Levantar',
      7: 'Fecha de regreso'
    },
    calendar: {
      title: 'Scheduling Calendar',
      itemFound: 'No items| 1 results | {count} results',
      placeholder: {
        0: 'Ingrese un modelo, descripción o fabricante',
        1: 'No hay modelos disponibles'
      }
    },
    calendarViewTableHeader: {
      desktop: [
        {
          label: 'Categoría',
          key: 'Category',
          sortable: true,
          type: String
        },
        {
          label: 'Modelo',
          key: 'Model',
          sortable: true,
          type: String
        },
        {
          label: 'Descripción',
          key: 'Description',
          sortable: true,
          type: String
        },
        {
          label: 'Fabricante',
          key: 'Manufacturer',
          sortable: true,
          type: String
        },
        {
          label: 'Disponible Qty',
          key: 'UndecidedQty',
          sortable: true,
          type: String
        },
        {
          label: 'Fecha de inicio',
          key: 'StartDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Fecha final',
          key: 'EndDate',
          type: Date,
          sortable: 'true'
        }
      ],
      mobile: [
        {
          label: ''
        },
        {
          data: [
            {
              label: 'Categoría',
              key: 'Category',
              sortable: true,
              type: String
            },
            {
              label: 'Modelo',
              key: 'Model',
              sortable: true,
              type: String
            },
            {
              label: 'Descripción',
              key: 'Description',
              sortable: true,
              type: String
            },
            {
              label: 'Fabricante',
              key: 'Manufacturer',
              sortable: true,
              type: String
            },
            {
              label: 'Disponible Qty',
              key: 'UndecidedQty',
              sortable: true,
              type: String
            },
            {
              label: 'Fecha de inicio',
              key: 'StartDate',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Fecha final',
              key: 'EndDate',
              type: Date,
              sortable: 'true'
            }
          ]
        }
      ]
    },
    formLabels: {
      labels: {
        0: 'Modelo',
        1: 'Ir a la cita',
        2: 'Cantidad disponible'
      }
    }
  }
}
export default scheduling

const scanItems = {
  tableHeader: {
    desktop: [
      {
        label: 'Num',
        key: 'itemNumber'
      },
      {
        label: 'Descripción',
        key: 'description'
      },
      {
        label: 'Cant.',
        key: 'quantity'
      },
      {
        label: 'Retirado en',
        key: 'lostAtId',
        isEditable: true,
        type: 'Guid',
        listType: 11
      },
      {
        label: 'Restablecer a',
        key: 'assignedToId',
        isEditable: true,
        type: 'Guid',
        listType: 11
      },
      {
        label: 'Estado',
        key: 'statusCodeNumber'
      },
      {
        label: 'Asunto personal',
        key: 'personalIssue',
        type: Boolean
      },
      {
        label: 'Tipo',
        key: 'itemType'
      },
      {
        label: 'Notas',
        key: 'notes'
      }
    ],
    mobile: [
      {
        label: 'Num',
        key: 'itemNumber'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Descripción',
            key: 'description'
          },
          {
            label: 'Qty',
            key: 'quantity'
          },
          {
            label: 'Retirado en',
            key: 'lostAtId',
            isEditable: true,
            type: 'Guid',
            listType: 11
          },
          {
            label: 'Restablecer a',
            key: 'assignedToId',
            isEditable: true,
            type: 'Guid',
            listType: 11
          },
          {
            label: 'Estado',
            key: 'statusCodeNumber'
          },
          {
            label: 'Asunto personal',
            key: 'personalIssue',
            type: Boolean
          },
          {
            label: 'tipo',
            key: 'itemType'
          },
          {
            label: 'Notas',
            key: 'notes'
          }
        ]
      }
    ]
  }
}

export default scanItems

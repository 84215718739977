const workOrder = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'workOrderId',
        type: 'check'
      },
      {
        label: 'No.',
        key: 'number',
        type: Number,
        sortable: true
      },
      {
        label: 'Status',
        key: 'status',
        type: String,
        sortable: true
      },
      {
        label: 'Created',
        key: 'createdOn',
        type: Date,
        sortable: true
      },
      {
        label: 'State',
        key: 'state',
        type: String,
        sortable: true
      },
      {
        label: 'Service Request Note',
        key: 'serviceRequestNote',
        type: String,
        sortable: true
      },
      {
        label: 'Urgency',
        key: 'priorityLevel',
        type: String,
        sortable: true
      },
      {
        label: 'Item No.',
        key: 'itemNumber',
        type: Number,
        sortable: true
      },
      {
        label: 'Bar Code',
        key: 'barCode',
        type: Number,
        sortable: true
      },
      {
        label: 'Manufacturer',
        key: 'manufacturer',
        type: String,
        sortable: true
      },
      {
        label: 'Model',
        key: 'model',
        type: String,
        sortable: true
      },
      {
        label: 'Item Description',
        key: 'itemDescription',
        type: String,
        sortable: true
      },
      {
        label: 'Requested Service',
        key: 'serviceRequestDescription',
        type: String,
        sortable: true
      },
      {
        label: 'Due',
        key: 'scheduledDate',
        type: Date,
        sortable: true
      },
      {
        label: 'Total Charges',
        key: 'Charges',
        type: Number,
        sortable: true
      },
      {
        label: 'Current Assignment',
        key: 'currentAssignment',
        type: String,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'workOrderId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.',
            key: 'number',
            type: Number,
            sortable: true
          },
          {
            label: 'Status',
            key: 'status',
            type: String,
            sortable: true
          },
          {
            label: 'Created',
            key: 'createdOn',
            type: Date,
            sortable: true
          },
          {
            label: 'State',
            key: 'state',
            type: String,
            sortable: true
          },
          {
            label: 'Service Request Note',
            key: 'serviceRequestNote',
            type: String,
            sortable: true
          },
          {
            label: 'Urgency',
            key: 'priorityLevel',
            type: String,
            sortable: true
          },
          {
            label: 'Item No.',
            key: 'itemNumber',
            type: Number,
            sortable: true
          },
          {
            label: 'Bar Code',
            key: 'barCode',
            type: Number,
            sortable: true
          },
          {
            label: 'Manufacturer',
            key: 'manufacturer',
            type: String,
            sortable: true
          },
          {
            label: 'Model',
            key: 'model',
            type: String,
            sortable: true
          },
          {
            label: 'Item Description',
            key: 'itemDescription',
            type: String,
            sortable: true
          },
          {
            label: 'Requested Service',
            key: 'serviceRequestDescription',
            type: String,
            sortable: true
          },
          {
            label: 'Due',
            key: 'scheduledDate',
            type: Date,
            sortable: true
          },
          {
            label: 'Total Charges',
            key: 'Charges',
            type: Number,
            sortable: true
          },
          {
            label: 'Current Assignment',
            key: 'currentAssignment',
            type: String,
            sortable: true
          }
        ]
      }
    ]
  },
  lineActions: [
    {
      title: 'View',
      actionName: 'viewWorkOrder',
      permissions: ['ServiceTicketView'],
      isMultiple: false
    },
    {
      title: 'Schedule Reason',
      actionName: 'scheduleReason',
      isMultiple: false
    },
    {
      title: 'Delete',
      actionName: 'deleteWorkOrder',
      permissions: ['ServiceTicketDelete'],
      isMultiple: false
    }
  ]
}

export default workOrder

import axios from 'axios'
import { setStockpointId, setJobId, deepCopy } from '@/helpers/utils.js'
import { setProcoreShipTo } from '@/helpers/procore.js'
import { uuid } from 'vue-uuid'

export default {
  state: () => ({
    cart: {
      Items: []
    },
    isCartLoading: false
  }),
  mutations: {
    storeCart (state, cart) {
      state.cart = cart
    },
    addItem (state, item) {
      const id = item.ModelId !== undefined ? item.ModelId : item.Id
      const $cart = deepCopy(state.cart)
      const index = $cart.Items.findIndex(ele => ele.ModelId === id)
      if (index === -1) {
        $cart.Items.push(item)
      } else {
        $cart.Items[index].Quantity = +$cart.Items[index].Quantity + +item.Quantity
      }
      state.cart = deepCopy($cart)
    },
    removeItem (state, item) {
      state.cart.Items = state.cart.Items.filter(i => i.ModelId !== item.ModelId)
    },
    updateItem (state, item) {
      const foundIndex = state.cart.Items.findIndex(i => i.ModelId == item.ModelId)
      state.cart.Items[foundIndex] = item
    },
    setCartLoading (state, flag) {
      state.isCartLoading = flag
    }
  },
  actions: {
    addItemToCart (context, item) {
      context.commit('addItem', item)
    },
    removeItemFromCart (context, item) {
      context.commit('removeItem', item)
    },
    async loadCart (context, isApiLoadRequired) {
      const headerConfig = {
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }
      const data = { Body: {} }
      data.Body = context.getters.cart
      if (isApiLoadRequired || data.Body.OriginationType === undefined) {
        const params = JSON.stringify({})
        await axios.post('/api/core/RequisitionCartService/GetCart', params, headerConfig)
          .then((cartdata) => {
            data.Body = cartdata.data.Body
            data.Body.OriginationId = data.Body.OriginationId ?? uuid.v4()
            data.Body.OriginationType = data.Body.OriginationType ?? 'Requisition'
            context.commit('storeCart', data.Body)
          })
      }
      setProcoreShipTo(data.Body)
      setJobId(data.Body)
      if (data.Body.DefaultCostCodeId !== undefined && data.Body.DefaultCostCodeId !== null) {
        localStorage.setItem('DefaultCostCodeId', data.Body.DefaultCostCodeId)
      }
      context.commit('setCartLoading', false)
      let locations = context.getters.getLocations
      if (!locations.length) {
        await context.dispatch('loadStockLocations')
        locations = context.getters.getLocations
      }
      const cartLocation = locations.find(x => x.Id === data.Body.StockpointId)
      if (cartLocation === undefined) {
        context.commit('setAssignedStockpointChanged', true)
      }
    },
    async saveCart (context, cart) {
      const params = JSON.stringify({
        cart: JSON.stringify(cart)
      })
      await axios.post('/api/core/RequisitionCartService/saveCart', params)
      await context.dispatch('loadCart', true)
    },
    updateItemInCart (context, item) {
      context.commit('updateItem', item)
    },
    checkoutWithCart (context, cart) {
      axios.post('/api/cloud/requisitions/', cart)
        .then(() => {
          context.dispatch('loadCart', true)
          context.commit('setLoading', false)
        })
    },
    setCart (context, cart) {
      context.commit('storeCart', cart)
    }
  },
  getters: {
    cartItems (state) {
      return state.cartItems
    },
    cart (state) {
      return state.cart
    },
    isCartLoading (state) {
      return state.isCartLoading
    }
  }
}

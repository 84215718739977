<template>
<div v-if="isNotificationIconVisible">
  <div
    v-click-outside="closeNotificationModal"
    v-bind:class = "(showNotificationModal)?'notification-dropdown   show':'notification-dropdown dropdown'"
    >
    <div class="bellIconRow">
      <div class="button bell-icon">
        <a @click="toggleNotificationModal">
          <i class="far fa-bell" style="font-size: 18px; color: white;"></i>
          <span class="button__badge" v-if="unreadCount>0">{{ unreadCount }}</span>
        </a>
      </div>
      <div
        v-bind:class = "(showNotificationModal)?'dropdown-menu show':'dropdown-menu'">
        <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 0px 5px">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <span class="notifications-title">{{$t('buttons.notification.0')}}</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 p-0 display-flex">
              <span class="notifications-bold-text">{{$t('buttons.notification.1')}}</span>
              <span class="notifications-switch">
                <ToggleSwitch
                  :isActive="true"
                  :activeColor="activeColor"
                  @onActiveChanged="onSwitchChanged">
                </ToggleSwitch>
              </span>
            </div>
          </div>
          <div v-for="(messageCenter, idx) in messageCenterData" :key="idx"
            class="row notifications-content" >
            <div class="col-lg-6 col-md-6 col-sm-12 notification-date" v-if="messageCenter.showDate">
              <span class="notifications-bold-text"> {{messageCenter.createdOn}}</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mark-all-as-read" v-if="idx==0">
              <span class="notifications-bold-text display-right markAllRead"
              @click="markAllRead">{{$t('buttons.notification.2')}}</span>
            </div>
            <div class="row notifications-content content-border" >
              <div v-html="messageCenter.Message.split('\n')[0]"></div>
              <div class="row notifications-box">
                <div class="col-lg-11 col-md-11 col-sm-11 notifications-text">
                  <div @click="singleMessageRead(messageCenter.MessageId, messageCenter.RecipientId)" v-html="getAnchorLink(messageCenter)"></div>
                  <div class="notification-message"
                    v-html="messageCenter.Message.split('\n')[2]">
                  </div>
                </div>
                <div v-if="!messageCenter.IsAcknowledged"
                  class="col-lg-1 col-md-1 col-sm-1 fa-circle-div">
                  <i class="fa-solid fa-circle"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { Vue } from 'vue-property-decorator'
import ToggleSwitch from '../components/shared/ToggleSwitch'
import { getCurrentMessages, makeAllRead, singleMessageRead, getUnreadMessageCount } from './Notifications.js'
import { getFormattedDateByLocale } from '../helpers/utils.js'

Vue.directive('click-outside', {
  bind () {
    this.event = event => this.vm.$emit(this.expression, event)
    this.el.addEventListener('click', this.stopProp)
    document.body.addEventListener('click', this.event)
  },
  unbind () {
    this.el.removeEventListener('click', this.stopProp)
    document.body.removeEventListener('click', this.event)
  },
  stopProp (event) { event.stopPropagation() }
})
export default {
  name: 'Notifications',
  components: {
    ToggleSwitch
  },
  data () {
    return {
      showNotificationModal: false,
      onlyShowUnread: true,
      messageCenterInitialData: [],
      messageCenterData: [],
      unreadCount: 0,
      totalCount: 0,
      displayedDate: '',
      unreadMessagePoller: null,
      isActive: false
    }
  },
  async created () {
    await this.getCurrentMessages()
    document.addEventListener('click', this.setIsActive)
    document.addEventListener('keydown', this.setIsActive)
    await this.resetUnreadMessagePoller()
  },
  beforeDestroy () {
    if (unreadMessagePoller != null) clearInterval(unreadMessagePoller)
    document.removeEventListener('click', this.setIsActive)
    document.removeEventListener('keydown', this.setIsActive)
  },
  methods: {
    async resetUnreadMessagePoller () {
      if (this.unreadMessagePoller !== null) {
        clearInterval(this.unreadMessagePoller)
        this.unreadMessagePoller = null
      }
      await this.getUnreadMessageCount()
      this.unreadMessagePoller = setInterval(async () => {
        if (this.isActive) {
          await this.getUnreadMessageCount()
          this.isActive = false
        }
      }, 30000)
    },
    async getUnreadMessageCount () {
      const unreadMessageCount = await getUnreadMessageCount(false)
      this.unreadCount = unreadMessageCount
      if (this.totalCount === 0) {
        this.totalCount = this.unreadCount
      }
    },
    async getCurrentMessages () {
      this.displayedDate = ''
      this.messageCenterInitialData = await getCurrentMessages(false)
      if (this.messageCenterInitialData) {
        this.totalCount = this.messageCenterInitialData.length
        if (this.onlyShowUnread) {
          this.messageCenterData = this.messageCenterInitialData.filter(item => !item.IsAcknowledged)
        } else {
          this.messageCenterData = this.messageCenterInitialData
        }
        this.messageCenterData.forEach(item => {
          const date = this.getFormattedDate(item.createdOn)
          if (date == this.displayedDate) {
            item.showDate = false
            item.createdOn = date
          } else {
            item.showDate = true
            this.displayedDate = date
            if (this.currentDate == date) {
              item.createdOn = 'TODAY'
            } else {
              item.createdOn = date
            }
          }
        })
      }
    },
    async markAllRead () {
      const result = await makeAllRead(this.messageCenterInitialData[0].RecipientId)
      if (result === 'success') {
        this.unreadCount = 0
        this.messageCenterData.forEach(item => {
          if (!item.IsAcknowledged) {
            item.IsAcknowledged = true
          }
        })
        if (this.onlyShowUnread) {
          this.messageCenterData = this.messageCenterData.filter(item => !item.IsAcknowledged)
        }
      }
    },
    singleMessageRead (messageId, recipientId) {
      singleMessageRead(messageId, recipientId)
    },
    toggleNotificationModal: function (event) {
      if (this.totalCount > 0) {
        this.showNotificationModal = !this.showNotificationModal
        if (this.showNotificationModal) {
          this.getCurrentMessages()
        }
      }
    },
    closeNotificationModal: function (event) {
      if (this.showNotificationModal) {
        this.showNotificationModal = false
      }
    },
    onSwitchChanged: function (isActive) {
      this.onlyShowUnread = isActive
      this.getCurrentMessages()
    },
    getFormattedDate (date) {
      return getFormattedDateByLocale(new Date(date))
    },
    setIsActive () {
      this.isActive = true
    },
    getAnchorLink (message) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(message.Message, 'text/html')
      const anchor = doc.querySelector('a')
      if (anchor) {
        anchor.setAttribute('target', '_blank')
        anchor.setAttribute('href', anchor.getAttribute('href') || 'link')
      }
      return anchor ? anchor.outerHTML : ''
    }
  },
  computed: {
    activeColor () {
      return this.$store.getters.secondaryColor
    },
    isNotificationIconVisible () {
      return this.$store.getters.isShowNotification
    },
    currentDate () {
      return this.getFormattedDate(new Date())
    }
  }
}
</script>

<style>
  div.notification-dropdown {
    margin-top: 5px;
    position: absolute;
  }
  .notification-dropdown div.dropdown-menu {
    top: 25px!important;
    left: -330px!important;
    min-width: 380px!important;
    max-width: 400px!important;
    padding: 5px!important;
    border: none !important;
    max-height: 500px;
    overflow-x: hidden;
  }
  .notification-dropdown .dropdown-menu a span {
    color: #647178!important;
    font-weight: 700;
  }
  .notification-dropdown .dropdown-menu img {
    width: 22px;
  }
  .notification-dropdown div.notifications-content {
    padding: 0px 0px 5px 5px;
    margin: 0px 0px;
    width:100%;
  }
  .notification-dropdown div.notifications-box {
    padding: 0px;
    margin-left: -2px;
    width: 100%;
  }
  .notification-dropdown .content-border {
    border: 2px solid rgba(0,0,0,.15);
    border-radius: 2px;
  }
  .notification-dropdown div.notifications-text {
    border: 1px solid rgba(0,0,0,.15);
    padding: 3px 2px;
    border-radius: 2px;
    width:100%;
  }
  .notification-dropdown div.notifications-text a,
  .notification-dropdown div.notifications-text a:hover{
    font-weight: 900;
    color: #007bff;
  }
  .notification-dropdown span.notifications-title {
    line-height: 50px;
    font-weight: 900;
    font-size: 24px;
  }
  .notification-dropdown .p-0 {
    padding: 0px !important;
  }
  .notification-dropdown .fa-circle-div{
    font-size: 20px;
    padding-left: 0px !important;
  }
  .notification-dropdown .display-flex {
    display : flex;
    align-items : center;
  }
  .notification-dropdown .notifications-bold-text {
    font-size: 14px;
    font-weight: 600;
    color: #647178;
    padding-right: 0px;
  }
  .notification-dropdown .display-right {
    float: right;
  }
  .notification-dropdown .notifications-switch {
    font-size: 14px;
  }
  .notification-dropdown .fa-circle {
    color: var(--secondary-color);
    padding: 10px 5px;
  }
  .notification-dropdown .bellIconRow .button {
    color: var(--slds-g-color-neutral-base-50, var(--lwc-colorTextIconDefault,rgb(116, 116, 116))) !important;
    display: inline-block;
    position: relative;
    padding: 8px 15px;
    font-size: 15px !important;
  }
  .notification-dropdown .button__badge {
    position: absolute;
    top: 0px;
    right: 7px;
    padding: 0px 6px;
    border-radius: 50%;
    background-color: red;
    color: white !important;
    font-size: 11px !important;
  }
  .notification-dropdown .bellIconRow
  {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 15px;
  }
  .notification-dropdown .notification-message {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .notification-dropdown .notification-date {
    padding-left: 0px !important;
  }
  .notification-dropdown .mark-all-as-read {
    padding-right: 0px !important;
  }
  .markAllRead:hover {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  .bell-icon {
    left: -60px;
  }
</style>

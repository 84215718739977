export default {
  state: () => ({
    retiredToolsSearchParam: {
      categoryId: null,
      itemNumberOrBarCode: '',
      beginDate: null,
      endDate: null,
      includeReinstatement: true,
      supervisorId: null,
      assignToId: null
    }
  }),
  mutations: {
    setRetiredToolsSearchParam (state, value) {
      state.retiredToolsSearchParam = value
    }
  },
  getters: {
    getRetiredToolsSearchParam (state) {
      return state.retiredToolsSearchParam
    }
  }
}

const transfer = {
  tableHeader: {
    desktop: [
      {
        label: ''
      },
      {
        label: ''
      },
      {
        label: 'Equipo'
      },
      {
        label: 'Artículo'
      },
      {
        label: 'Todos'
      },
      {
        label: 'Fuera'
      },
      {
        label: 'Dentro'
      },
      {
        label: 'Descripción'
      },
      {
        label: 'Desde'
      },
      {
        label: 'Formulario de centro de costos'
      },
      {
        label: 'Estado'
      },
      {
        label: 'Medidor'
      },
      {
        label: 'UP'
      },
      {
        label: 'Propietario'
      },
      {
        label: 'Centro de costo a'
      },
      {
        label: 'Código de costo'
      }
    ],
    mobile: [
      {
        label: ''
      },
      {
        label: 'Info',
        data: [
          {
            label: ''
          },
          {
            label: 'Equipo'
          },
          {
            label: 'Artículo'
          },
          {
            label: 'Todos'
          },
          {
            label: 'Fuera'
          },
          {
            label: 'Dentro'
          },
          {
            label: 'Descripción'
          },
          {
            label: 'Desde'
          },
          {
            label: 'Formulario de centro de costos'
          },
          {
            label: 'Estado'
          },
          {
            label: 'Medidor'
          },
          {
            label: 'UP'
          },
          {
            label: 'Propietario'
          },
          {
            label: 'Centro de costo a'
          },
          {
            label: 'Código de costo'
          }
        ]
      }
    ]
  },
  readOnlyTableHeader: {
    desktop: [
      {
        label: ''
      },
      {
        label: ''
      },
      {
        label: 'Equipo'
      },
      {
        label: 'Artículo'
      },
      {
        label: 'Todos'
      },
      {
        label: 'Fuera'
      },
      {
        label: 'Dentro'
      },
      {
        label: 'Descripción'
      },
      {
        label: 'Desde'
      },
      {
        label: 'Formulario de centro de costos'
      },
      {
        label: 'Estado'
      },
      {
        label: 'Medidor'
      },
      {
        label: 'UP'
      },
      {
        label: 'Propietario'
      },
      {
        label: 'Centro de costo a'
      },
      {
        label: 'Código de costo'
      }
    ],
    mobile: [
      {
        label: ''
      },
      {
        label: 'Info',
        data: [
          {
            label: ''
          },
          {
            label: 'Equipo'
          },
          {
            label: 'Artículo'
          },
          {
            label: 'Todos'
          },
          {
            label: 'Fuera'
          },
          {
            label: 'Dentro'
          },
          {
            label: 'Descripción'
          },
          {
            label: 'Desde'
          },
          {
            label: 'Formulario de centro de costos'
          },
          {
            label: 'Estado'
          },
          {
            label: 'Medidor'
          },
          {
            label: 'UP'
          },
          {
            label: 'Propietario'
          },
          {
            label: 'Centro de costo a'
          },
          {
            label: 'Código de costo'
          }
        ]
      }
    ]
  }
}

export default transfer

<template>
  <router-view v-if="isProcessing"></router-view>
</template>

<script>
import axios from 'axios'
import { errorHandler } from '../../helpers/utils'
export default {
  name: 'PurchaseRouterView',
  data () {
    return {
      isProcessing: false
    }
  },
  async created () {
    try {
      this.isProcessing = false
      this.$store.commit('setLoading', true)
      if (!this.$route.path.includes('dashboard')) {
        const reqArray = []
        if (!this.poStatusList || this.poStatusList.length === 0) {
          reqArray.push(this.getPurchaseStatus())
        }
        if (!this.templateTypes || this.templateTypes.length === 0) {
          reqArray.push(this.getTemplateTypes())
        }
        if (!this.serviceDescriptions.length) {
          this.$store.dispatch('getDescriptions', this.$toast)
        }
        if (!this.categories.length) {
          reqArray.push(this.getCatetories())
        }
        await Promise.all(reqArray)
      }
    } catch (error) {
      errorHandler(this.$toast, error.response.statusText, error)
    } finally {
      this.$store.commit('setLoading', false)
      this.isProcessing = true
    }
  },
  methods: {
    async getPurchaseStatus () {
      try {
        const { data } = await axios.post('/api/core/PurchasingService/GetStatuses', {})
        if (data && data.Body && data.Body.length > 0) {
          this.$store.commit('setPOStatusList', data.Body)
        }
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    async getTemplateTypes () {
      const params = {
        orgId: `"${localStorage.getItem('OrgId')}"`
      }
      try {
        const { data } = await axios.post('/api/core/TemplateService/GetTypes', params)
        this.$store.commit('setTemplateTypes', data.Body)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    async getCatetories () {
      const params = {
        orgId: `"${localStorage.getItem('OrgId')}"`,
        ids: JSON.stringify([]),
        number: '',
        description: 'null',
        includeDeleted: 'false'
      }
      try {
        const { data } = await axios.post('/api/core/CategoriesService/GetCategories', params)
        this.$store.commit('setCategories', data.Body)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    }
  },
  computed: {
    poStatusList () {
      return this.$store.getters.poStatusList
    },
    templateTypes () {
      return this.$store.getters.templateTypes
    },
    serviceDescriptions () {
      return this.$store.getters.descriptions
    },
    categories () {
      return this.$store.getters.categories
    }
  }
}
</script>

<style>

</style>

const fuelCards = {
  title: 'Fuel Cards',
  noMsg: 'No Fuel Cards Data',
  cardStatus: 'Status',
  deleteWarningMsg: 'Unassign card before deleting',
  deleteConfirmMsg: 'Are you sure you want to delete the Fuel Card?',
  confirmTitle: 'Delete Fuel Card',
  actionHeader: {
    0: {
      title: 'Import Fuel Cards',
      icon: 'fas fa-fas fa-file-export'
    }
  },
  fuelCardsFilter: {
    0: 'All',
    1: 'Active',
    2: 'Inactive'
  },
  fuelCardsFormModal: {
    editDlg: {
      title: {
        0: 'Create Fuel Cards',
        1: 'Edit Fuel Cards',
        2: 'Delete Fuel Cards'
      },
      labels: {
        0: 'Card Number',
        1: 'Card Number 2',
        2: 'Vehicle/Asset #',
        3: 'VIN',
        4: 'Employee',
        5: 'Item'
      }
    },
    taggedForm: {
      0: 'Record Information',
      1: 'Created',
      2: 'By',
      3: 'Modified'
    },
    errorMessage: 'Fuel card already exist with same number, use a different card number'
  },
  itemDetail: {
    subtitle: 'Fuel Cards Add Items',
    addItem: {
      0: {
        title: 'Add Item & Back',
        icon: 'fas fa-undo'
      },
      1: {
        title: 'Cancel & Back',
        icon: 'fas fa-arrow-left'
      }
    },
    errorMessage: 'Please select an item.'
  },
  ExpandedTableHeader: {
    desktop: [
      {
        label: '',
        key: 'itemType',
        type: 'InKit'
      },
      {
        label: '',
        key: 'hasBleTag',
        type: 'BleTag'
      },
      {
        label: 'Category',
        key: 'category',
        sortable: true,
        type: String
      },
      {
        label: 'Number',
        key: 'itemNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Bar Code',
        key: 'barcode',
        sortable: true,
        type: String
      },
      {
        label: 'Serial Number',
        key: 'serialNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Kit',
        key: 'kitNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'T',
        key: 'itemType',
        sortable: true,
        type: String
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Manufacturer',
        key: 'manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Model',
        key: 'model',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Qty',
        key: 'quantity',
        sortable: true,
        isMaterial: true,
        type: Number
      },
      {
        label: 'Uom',
        key: 'uom',
        sortable: true
      },
      {
        label: 'Status',
        key: 'itemStatus',
        sortable: true,
        type: String
      },
      {
        label: 'PI',
        key: 'personalIssue',
        sortable: true,
        type: Boolean
      },
      {
        label: 'Assignment',
        key: 'assignedTo',
        sortable: true,
        type: String
      },
      {
        label: 'Bin',
        key: 'binId',
        sortable: true
      },
      {
        label: 'Responsible Party',
        key: 'responsibleParty',
        sortable: 'true'
      },
      {
        label: 'Age',
        key: 'ageDays',
        sortable: true,
        type: 'age',
        sorted: true,
        asc: true
      },
      {
        label: 'Last Scanned',
        key: 'lastScannedOn',
        sortable: true,
        type: Date
      },
      {
        label: 'Last Wireless Scan Location',
        key: 'lastScanLocation',
        type: 'LastScanLocation',
        sortable: true
      },
      {
        label: 'Last Wireless Scan Area',
        key: 'lastScanArea',
        sortable: true
      },
      {
        label: 'Last Counted',
        key: 'lastCountDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Tran. No.',
        key: 'lastTransferNumber',
        sortable: true
      },
      {
        label: 'Tran. Date',
        key: 'lastTransferDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Returning',
        key: 'returnDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Owner',
        key: 'owner',
        sortable: true,
        type: String
      },
      {
        label: 'Manager',
        key: 'manager',
        sortable: true,
        type: String
      },
      {
        label: 'Department',
        key: 'department',
        sortable: true,
        type: String
      },
      {
        label: 'Supervisor',
        key: 'supervisor',
        sortable: true,
        type: String
      },
      {
        label: 'Delivered By',
        key: 'lastTransferDeliveredBy',
        sortable: true
      },
      {
        label: 'Item Class',
        key: 'className',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: '',
        key: 'itemType',
        type: 'InKit'
      },
      {
        label: 'Info',
        data: [
          {
            label: '',
            key: 'hasBleTag',
            type: 'BleTag'
          },
          {
            label: 'Category',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Number',
            key: 'itemNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Bar Code',
            key: 'barcode',
            sortable: true,
            type: String
          },
          {
            label: 'Serial Number',
            key: 'serialNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Kit',
            key: 'kitNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'T',
            key: 'itemType',
            sortable: true,
            type: String
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Manufacturer',
            key: 'manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Model',
            key: 'model',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Qty',
            key: 'quantity',
            sortable: true,
            isMaterial: true,
            type: Number
          },
          {
            label: 'Uom',
            key: 'uom',
            sortable: true
          },
          {
            label: 'Status',
            key: 'itemStatus',
            sortable: true,
            type: String
          },
          {
            label: 'PI',
            key: 'personalIssue',
            sortable: true,
            type: Boolean
          },
          {
            label: 'Assignment',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'Bin',
            key: 'binId',
            sortable: true
          },
          {
            label: 'Responsible Party',
            key: 'responsibleParty',
            sortable: 'true'
          },
          {
            label: 'Age',
            key: 'ageDays',
            sortable: true,
            type: 'age',
            sorted: true,
            asc: true
          },
          {
            label: 'Last Scanned',
            key: 'lastScannedOn',
            sortable: true,
            type: Date
          },
          {
            label: 'Last Wireless Scan Location',
            key: 'lastScanLocation',
            type: 'LastScanLocation',
            sortable: true
          },
          {
            label: 'Last Wireless Scan Area',
            key: 'lastScanArea',
            sortable: true
          },
          {
            label: 'Last Counted',
            key: 'lastCountDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Tran. No.',
            key: 'lastTransferNumber',
            sortable: true
          },
          {
            label: 'Tran. Date',
            key: 'lastTransferDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Returning',
            key: 'returnDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Owner',
            key: 'owner',
            sortable: true,
            type: String
          },
          {
            label: 'Manager',
            key: 'manager',
            sortable: true,
            type: String
          },
          {
            label: 'Department',
            key: 'department',
            sortable: true,
            type: String
          },
          {
            label: 'Supervisor',
            key: 'supervisor',
            sortable: true,
            type: String
          },
          {
            label: 'Delivered By',
            key: 'lastTransferDeliveredBy',
            sortable: true
          },
          {
            label: 'Item Class',
            key: 'className',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  },
  ToolCategoryTableHeader: {
    desktop: [
      {
        label: '',
        key: 'itemType',
        type: 'InKit'
      },
      {
        label: 'Category',
        key: 'category',
        sortable: true,
        type: String
      },
      {
        label: 'Number',
        key: 'itemNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Bar Code',
        key: 'barcode',
        sortable: true,
        type: String
      },
      {
        label: 'Serial Number',
        key: 'serialNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Kit',
        key: 'kitNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'T',
        key: 'itemType',
        sortable: true,
        type: String
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Manufacturer',
        key: 'manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Model',
        key: 'model',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Qty',
        key: 'quantity',
        sortable: true,
        isMaterial: true,
        type: Number
      },
      {
        label: 'Status',
        key: 'itemStatus',
        sortable: true,
        type: String
      },
      {
        label: 'PI',
        key: 'personalIssue',
        sortable: true,
        type: Boolean
      },
      {
        label: 'Assignment',
        key: 'assignedTo',
        sortable: true,
        type: String
      },
      {
        label: 'Age',
        key: 'ageDays',
        sortable: true,
        type: 'age',
        sorted: true,
        asc: true
      },
      {
        label: 'Owner',
        key: 'owner',
        sortable: true,
        type: String
      },
      {
        label: 'Manager',
        key: 'manager',
        sortable: true,
        type: String
      },
      {
        label: 'Department',
        key: 'department',
        sortable: true,
        type: String
      },
      {
        label: 'Supervisor',
        key: 'supervisor',
        sortable: true,
        type: String
      },
      {
        label: 'Item Class',
        key: 'className',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: '',
        key: 'itemType',
        type: 'InKit'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Number',
            key: 'itemNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Bar Code',
            key: 'barcode',
            sortable: true,
            type: String
          },
          {
            label: 'Serial Number',
            key: 'serialNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Kit',
            key: 'kitNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'T',
            key: 'itemType',
            sortable: true,
            type: String
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Manufacturer',
            key: 'manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Model',
            key: 'model',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Qty',
            key: 'quantity',
            sortable: true,
            isMaterial: true,
            type: Number
          },
          {
            label: 'Status',
            key: 'itemStatus',
            sortable: true,
            type: String
          },
          {
            label: 'PI',
            key: 'personalIssue',
            sortable: true,
            type: Boolean
          },
          {
            label: 'Assignment',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'Age',
            key: 'ageDays',
            sortable: true,
            type: 'age',
            sorted: true,
            asc: true
          },
          {
            label: 'Owner',
            key: 'owner',
            sortable: true,
            type: String
          },
          {
            label: 'Manager',
            key: 'manager',
            sortable: true,
            type: String
          },
          {
            label: 'Department',
            key: 'department',
            sortable: true,
            type: String
          },
          {
            label: 'Supervisor',
            key: 'supervisor',
            sortable: true,
            type: String
          },
          {
            label: 'Item Class',
            key: 'className',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  },
  materialCategoryTableHeader: {
    desktop: [
      {
        label: 'Category',
        key: 'category',
        sortable: true,
        type: String
      },
      {
        label: 'Number',
        key: 'itemNumber',
        sortable: true,
        type: String
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true,
        type: String
      },
      {
        label: 'Model',
        key: 'model',
        sortable: true,
        type: String
      },
      {
        label: 'Qty',
        key: 'quantity',
        sortable: true,
        type: Number
      },
      {
        label: 'Uom',
        key: 'uom',
        sortable: true,
        type: String
      },
      {
        label: 'Stock Location',
        key: 'assignedTo',
        sortable: true,
        type: String
      },
      {
        label: 'Bin',
        key: 'binId',
        sortable: true,
        type: String
      },
      {
        label: 'Bar Code',
        key: 'barcode',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Number',
            key: 'itemNumber',
            sortable: true,
            type: String
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true,
            type: String
          },
          {
            label: 'Model',
            key: 'model',
            sortable: true,
            type: String
          },
          {
            label: 'Qty',
            key: 'quantity',
            sortable: true,
            type: Number
          },
          {
            label: 'Uom',
            key: 'uom',
            sortable: true,
            type: String
          },
          {
            label: 'Stock Location',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'Bin',
            key: 'binId',
            sortable: true,
            type: String
          },
          {
            label: 'Bar Code',
            key: 'barcode',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  },
  fuelCardListHeader: {
    desktop: [
      {
        label: 'Card No',
        key: 'cardNumber',
        sortable: true
      },
      {
        label: 'Assignment',
        key: 'assignment',
        sortable: true
      },
      {
        label: 'Status',
        key: 'status',
        sortable: true
      },
      {
        label: 'Created On',
        key: 'formattedCreatedOn',
        sortable: true
      },
      {
        label: '',
        key: 'action',
        isEditable: true
      }
    ],
    mobile: [
      {
        label: 'Card No',
        key: 'cardNumber',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Assignment',
            key: 'Assignment',
            sortable: true
          },
          {
            label: 'Status',
            key: 'status',
            sortable: true
          },
          {
            label: 'Created On',
            key: 'formattedCreatedOn',
            sortable: true
          }
        ]
      }
    ]
  },
  importFuelCard: {
    label: 'Select CSV File To Import and Validate.',
    title: 'Import Fuel Cards',
    downloadLabel: 'Download Import Template',
    importSuccess: 'Fuel Card import was successful'
  },
  fuelCardImportHeader: {
    desktop: [
      {
        label: 'Card Number',
        key: 'CardNumber',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Card Number2',
        key: 'CardNumber2',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Item Number',
        key: 'ItemNumber',
        sortable: true,
        isEditable: false
      },
      {
        label: 'VIN',
        key: 'VIN',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Status',
        key: 'Status',
        sortable: true,
        isEditable: false
      },
      {
        label: '',
        key: 'ImportStatus',
        sortable: false,
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'Card Number',
        key: 'CardNumber'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Card Number',
            key: 'CardNumber',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Card Number2',
            key: 'CardNumber2',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Item Number',
            key: 'ItemNumber',
            sortable: true,
            isEditable: false
          },
          {
            label: 'VIN',
            key: 'VIN',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Status',
            key: 'Status',
            sortable: true,
            isEditable: false
          },
          {
            label: '',
            key: 'ImportStatus',
            sortable: false,
            isModify: false
          }
        ]
      }
    ]
  },
  fuelTransactions: {
    panels: {
      0: 'Fuel Transactions'
    }
  },
  fuelTransactionsTabheader: {
    desktop: [
      {
        label: 'Transaction Date',
        key: 'transactionDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Driver',
        key: 'driverName',
        sortable: true
      },
      {
        label: 'Fuel Card #',
        key: 'cardNumber',
        sortable: true
      },
      {
        label: 'Site Name',
        key: 'department',
        sortable: true
      },
      {
        label: 'Site Address',
        key: 'address',
        sortable: true
      },
      {
        label: 'Site City',
        key: 'city',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Transaction Date',
        key: 'transactionDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Driver',
        key: 'driverName',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Fuel Card',
            key: 'cardNumber',
            sortable: true
          },
          {
            label: 'Department',
            key: 'department',
            sortable: true
          },
          {
            label: 'Site Address',
            key: 'Address',
            sortable: true
          },
          {
            label: 'Site City',
            key: 'city',
            sortable: true
          }]
      }
    ]
  }
}
export default fuelCards

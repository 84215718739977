const lowLevelBrowser = {
  title: 'Low Level Browser',
  actionHeader: {
    0: {
      title: 'Recalculate',
      icon: 'fas fa-sync-alt'
    },
    1: {
      title: 'Export Data',
      icon: 'fas fa-file-export'
    },
    2: {
      title: 'Add to Purchase Order',
      icon: 'fas fa-plus'
    }
  },
  tableHeader: {
    desktop: [
      {
        label: 'Category',
        key: 'masterCategory',
        sortable: true
      },
      {
        label: 'Description',
        key: 'masterDescription',
        sortable: true
      },
      {
        label: 'Model',
        key: 'model',
        sortable: true
      },
      {
        label: 'Current Lvl',
        key: 'stockLevel',
        sortable: true
      },
      {
        label: 'On Order',
        key: 'qtyOnOrder',
        sortable: true
      },
      {
        label: 'Low Lvl',
        key: 'lowLevel',
        sortable: true
      },
      {
        label: 'Target Lvl',
        key: 'targetLevel',
        sortable: true
      },
      {
        label: 'High Lvl',
        key: 'highLevel',
        sortable: true
      },
      {
        label: 'Vendor',
        key: 'preferredVendor',
        sortable: true
      },
      {
        label: '',
        key: 'lowLevelAction'
      }
    ],
    mobile: [
      {
        label: 'Category',
        key: 'masterCategory'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Description',
            key: 'masterDescription',
            sortable: true
          },
          {
            label: 'Model',
            key: 'model',
            sortable: true
          },
          {
            label: 'Current Lvl',
            key: 'stockLevel',
            sortable: true
          },
          {
            label: 'On Order',
            key: 'qtyOnOrder',
            sortable: true
          },
          {
            label: 'Low Lvl',
            key: 'lowLevel',
            sortable: true
          },
          {
            label: 'Target Lvl',
            key: 'targetLevel',
            sortable: true
          },
          {
            label: 'High Lvl',
            key: 'highLevel',
            sortable: true
          },
          {
            label: 'Vendor',
            key: 'preferredVendor',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: 'lowLevelAction'
      }
    ]
  }
}

export default lowLevelBrowser

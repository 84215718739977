const safetyPlanGeneralInfo = {
  labels: {
    0: 'Ubicación de trabajo asignada',
    1: 'Dirección del proyecto',
    2: 'Ciudad',
    3: 'País',
    4: 'Estado',
    5: 'Código Postal',
    6: 'Tamaño máximo de la tripulación en el proyecto',
    7: 'Alcance del trabajo',
    8: 'Sin duración del proyecto',
    9: 'Duración del proyecto',
    10: 'Estado',
    11: 'Inactiva',
    12: 'Fecha inactiva',
    13: 'Razón',
    14: 'Recordatorio de desmantelamiento'
  },
  validations: {
    startDate: 'Fecha de inicio',
    endDate: 'Fecha final',
    inactiveDate: 'Inactive Date'
  }
}
export default safetyPlanGeneralInfo

<template>
  <svg class="loading-spinner">
    <circle
      :cx="circlePositions[index] && circlePositions[index].x"
      :cy="circlePositions[index] && circlePositions[index].y"
      :r="item.radius"
      :fill="item.color"
      v-for="(item, index) in circles"
      :key="index"/>
  </svg>
</template>

<script>
const CENTER_X = 50
const CENTER_Y = 50
// const RADIUS = 20

function positionOnCircle (radius, angle, center) {
  return {
    x: center.x + (radius * Math.cos(toRadians(angle))),
    y: center.y + (radius * Math.sin(toRadians(angle)))
  }
}

function toRadians (angle) {
  return angle * Math.PI / 180
}

function calculatePositions (component) {
  const angleIncrement = 360 / component.circles.length
  const positions = {}
  component.circles.forEach((circle, index) => {
    positions[index] = positionOnCircle(
      this.RADIUS,
      angleIncrement * index,
      { x: CENTER_X, y: CENTER_Y }
    )
  })
  return positions
}

export default {
  data () {
    return {
      circles: [
        { color: 'rgba(207, 147, 51, 1)', radius: 0 },
        { color: 'rgba(207, 147, 51, 0.9)', radius: 0 },
        { color: 'rgba(207, 147, 51, 0.8)', radius: 0 },
        { color: 'rgba(207, 147, 51, 0.7)', radius: 0 },
        { color: 'rgba(207, 147, 51, 0.6)', radius: 0 },
        { color: 'rgba(207, 147, 51, 0.5)', radius: 0 },
        { color: 'rgba(207, 147, 51, 0.4)', radius: 0 },
        { color: 'rgba(207, 147, 51, 0.3)', radius: 0 },
        { color: 'rgba(207, 147, 51, 0.2)', radius: 0 }
      ],
      counter: 0,
      interval: null,
      RADIUS: 20,
      circleSize: 8
    }
  },
  props: {
    radius: {
      type: Number,
      required: false,
      default: 20
    },
    size: {
      type: Number,
      required: false,
      default: 8
    }
  },
  computed: {
    circlePositions: calculatePositions
  },
  created () {
    this.circleSize = this.size
    this.interval = setInterval(() => {
      this.counter++
      this.circles = this.circles.map((item, index) => ({
        ...item,
        radius: (this.counter + index) % this.circleSize
      }))
    }, 70)
    this.RADIUS = this.radius
    this.circles[0].color = `rgba(${this.$store.getters.primaryColorRGB}, 1)`
    this.circles[1].color = `rgba(${this.$store.getters.primaryColorRGB}, 0.9)`
    this.circles[2].color = `rgba(${this.$store.getters.primaryColorRGB}, 0.8)`
    this.circles[3].color = `rgba(${this.$store.getters.primaryColorRGB}, 0.7)`
    this.circles[4].color = `rgba(${this.$store.getters.primaryColorRGB}, 0.6)`
    this.circles[5].color = `rgba(${this.$store.getters.primaryColorRGB}, 0.5)`
    this.circles[6].color = `rgba(${this.$store.getters.primaryColorRGB}, 0.4)`
    this.circles[7].color = `rgba(${this.$store.getters.primaryColorRGB}, 0.3)`
    this.circles[8].color = `rgba(${this.$store.getters.primaryColorRGB}, 0.2)`
  },
  destroyed () {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
  .loading-spinner {
    width: 100px;
    height: 100px;
  }
</style>

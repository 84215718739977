const collaborate = {
  title: 'Collaborate',
  actionName: {
    0: {
      name: 'Edit'
    },
    1: {
      name: 'Delete'
    },
    3: {
      name: 'Save'
    },
    4: {
      name: 'Cancel'
    },
    btnCollaboration: 'Post Collaboration',
    placeHolder: 'Type your collaboration here...',
    toolTip: 'Pick Ticket must be saved first to Collaborate',
    deleteConfirm: 'Are you sure you want to delete this comment?',
    deleteTitle: 'Delete Comment',
    deleteBtnText: 'Delete',
    cancelBtnText: 'Cancel'
  },
  editToolTipInfo: 'Edited by "{modifyBy}" {modifiedOn}'
}
export default collaborate

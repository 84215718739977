const buttons = {
  cancel: 'Cancelar',
  save: {
    change: 'Guardar cambios',
    setting: 'Guardar ajustes',
    default: 'Guardar',
    taskComplete: 'Guardar. Trabajo completada'
  },
  cross: 'cancelar',
  add: 'Agregar',
  change: 'Cambiar',
  remove: 'Remover',
  create: 'Crear nuevo',
  close: 'Cerrar',
  saveClose: 'Guardar y cerrar',
  export: 'Exportar',
  done: 'Aceptar or (Elegir)',
  edit: 'Editar',
  view: 'Ver',
  viewScheduleReason: 'Motivo del Horario',
  viewTicket: 'Ver Etiqueta',
  yes: 'Sí',
  no: 'No',
  ok: 'OK',
  delete: 'Eliminar',
  deleteSelected: 'Eliminar seleccionado',
  logout: 'Cerrar sesión',
  addNext: 'Agregar y siguiente',
  addItem: 'Añadir artículo',
  addLine: 'Añadir línea',
  addModel: 'Agregar modelos',
  addStock: 'Almacenar',
  stopStock: 'Deja de almacenar',
  startStock: 'Empezar a almacenar',
  stocking: 'Almacenando',
  unstocking: 'Desalmacenar',
  addtoInventory: '¿Agregar otro a su inventario?',
  startTracking: 'Empezar a almacenar',
  back: {
    results: 'Volver a Resultados',
    cart: 'Volver al carrito',
    default: 'Volver',
    reservation: 'Volver a Calendario'
  },
  stockPosition: 'Almacenar en {compañia}',
  itemText: 'Ningún artículo asociado con este producto | Elemento asociado a este producto | Elementos asociados con este producto',
  attachFiles: 'Adjuntar archivos',
  more: 'Más',
  viewRequest: 'Ver solicitud',
  viewReservation: 'Ver esta reservacion',
  viewReport: 'Ver los reportes',
  details: 'Detalles',
  requestAgain: 'Solicitar de nuevo',
  reserveAgain: 'Reservar de nuevo',
  addtoCart: 'Agreagar al carrito',
  addtoReservationCart: 'Agregar al Calendario',
  addtoTicket: 'Agregar al ticket',
  createReport: 'Crear reporte',
  createFolder: 'Crear carpeta',
  exportReport: 'Reporte de exportacion',
  createTools: 'Crear herramientas',
  removeItem: 'Remover el artículo',
  imagenotAvailable: 'Imagen no disponible',
  noMonthlyRateSpecified: 'Sin tarifa mensual especificada',
  noRateSpecified: 'Sin tarifa especificada',
  perMonth: ' por mes',
  each: ' cu.',
  perAssignment: ' por asignación',
  checkout: 'Verificar',
  requestSummary: 'Resumen de solicitud',
  totalItems: 'Articulos totales',
  submit: {
    request: 'Enviar solicitud',
    ticket: 'Enviar ticket',
    cancel: 'Cancelar ticket',
    reservation: 'Someter Calendario'
  },
  uploadLogo: 'Subir logotipo',
  attachment: {
    0: 'Agregar',
    1: 'Ver',
    2: 'Reemplazar',
    3: 'Renombrar',
    4: 'Eliminar',
    5: 'Guardar como',
    6: 'Agregar archivo adjunto',
    7: 'Reemplazar archivo adjunto',
    8: 'Importar desde',
    9: 'Cambiar el nombre del archivo adjunto'
  },
  notification: {
    0: 'Notificaciones',
    1: 'Mostrar solo no leídos',
    2: 'Marcar todo como leído'
  },
  share: 'Compartir carpeta',
  merge: 'Unir',
  refresh: 'Actualizar',
  calculate: 'Calcular',
  post: 'Publica ',
  logo: 'Toolwatch',
  warning: 'Advertencia',
  deactivateBtn: 'Desactivar cuenta de usuario',
  manageUserAcnt: 'Administrar cuenta de usuario',
  createUserAcnt: 'Crear cuenta de usuario',
  modifyVis: 'Modificar la visibilidad',
  processTransfer: 'Procesar Trasnferencia',
  backToTransfer: 'Volver a Transferencia',
  backToPick: 'Regresar a Solicitud',
  backToKitRecord: 'Volver al detalle del equipo',
  set: 'colocar',
  submitBtn: 'Enviar',
  generate: 'Generar',
  summary: 'Resumen',
  download: 'Descargar',
  browse: 'Buscar',
  import: 'Importar',
  search: 'Buscar',
  clear: 'Eliminar',
  count: 'Contar',
  error: 'Error',
  note: 'Nota',
  viewProjectCalendar: 'Ver calendario del proyecto...',
  twButtonTooltip: 'No está autorizado a {buttonText}. Esta acción requiere el permiso de función {permission}',
  permissionTooltip: 'No está autorizado a {title}. Esta acción requiere {requirements}',
  panelNotification: 'No está autorizado a {section}. Esta acción requiere',
  rolePermission: 'el permiso de rol {permission}',
  roleEntitlement: 'el derecho {entitlement}',
  includeKitContents: 'Explotar kits',
  expandedView: 'Vista ampliada',
  restore: 'Restaurar',
  addSource: 'Agregar fuente',
  perform: 'Realizar',
  addTask: 'Agregar Trabajo',
  addTool: 'Agregar herramienta',
  addPart: 'Agregar parte',
  skipUpdate: 'Omitir actualización',
  addContract: 'Agregar contrato',
  editContract: 'Editar contrato',
  addEventFromModal: 'Agregar evento del modelo',
  confirmSave: 'Confirmar Guardar',
  confirmDeletion: 'Confirmar Borrar',
  transferKit: 'Transferencia de Equipo',
  reinstateTools: 'Reintegrar herramientas',
  options: 'Opciones',
  stock: 'Existencias',
  manageAttachments: 'Administrar archivos adjuntos',
  removeRecurrence: 'Eliminar recurrencia',
  forgotPassword: '¿Olvidaste tu contraseña?',
  continue: '¿Continuar?',
  gpsLocation: 'Ubicación GPS',
  refreshMap: 'Actualizar',
  downloadTemplate: 'Descargar plantilla de importación',
  upload: 'Subir',
  move: 'Mover',
  settings: 'Ajustes',
  reassign: 'Reasignar',
  pasteText: 'Ctrl+V para pegar imagen',
  HelpandSupport: 'Servicio de asistencia',
  giveUsCall: 'Llamanos',
  giveUsCallTxt: 'Nos encantaría responder cualquier pregunta que puedas tener. Llámanos al (800) 580-3114',
  chat: 'Charlar',
  chatText: 'Conéctese con uno de nuestros increíbles representantes de servicio al cliente en línea.',
  documentation: 'Documentación',
  documentationText: 'Busque en nuestra extensa biblioteca de recursos en línea y materiales de apoyo.',
  supportTicket: 'Boleto de soporte',
  supportTicketText: 'Complete un formulario rápido en línea y nos comunicaremos con usted lo antes posible.'
}

export default buttons

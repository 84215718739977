const employees = {
  tableHeader: {
    desktop: [
      {
        label: 'check',
        key: 'twEntityId',
        isModify: true
      },
      {
        label: 'IsUser',
        key: 'username',
        sortable: true,
        isModify: false
      },
      {
        label: 'No.',
        key: 'entityNumber',
        sortable: true
      },
      {
        label: 'Last Name',
        key: 'lastName',
        sortable: true,
        isModify: false
      },
      {
        label: 'First Name',
        key: 'firstName',
        sortable: true,
        isModify: false
      },
      {
        label: 'Status',
        key: 'inactive',
        sortable: true,
        isModify: false
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true,
        isModify: true
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'twEntityId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No.',
            key: 'entityNumber',
            sortable: true
          },
          {
            label: 'Last Name',
            key: 'lastName',
            sortable: true,
            isModify: false
          },
          {
            label: 'First Name',
            key: 'firstName',
            sortable: true,
            isModify: false
          },
          {
            label: 'Status',
            key: 'inactive',
            sortable: true,
            isModify: false
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true,
            isModify: true
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default employees

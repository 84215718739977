const equipmentUsageLog = {
  tableHeader: {
    desktop: [
      {
        label: 'No.',
        key: 'number',
        isEditable: true,
        type: Number,
        sortable: true
      },
      {
        label: 'Item No.',
        key: 'itemNumber',
        isEditable: false,
        type: Number,
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        isEditable: false,
        type: String,
        sortable: true
      },
      {
        label: 'Model',
        key: 'model',
        isEditable: false,
        type: String,
        sortable: true
      },
      {
        label: 'Manufacturer',
        key: 'manufacturer',
        isEditable: false,
        type: String,
        sortable: true
      },
      {
        label: '*Start Date',
        key: 'startDate',
        isEditable: true,
        type: Date,
        sortable: true
      },
      {
        label: '*End Date',
        key: 'endDate',
        isEditable: true,
        type: Date,
        sortable: true
      },
      {
        label: '*Cost Center',
        key: 'costCenter',
        isEditable: true,
        sortable: false
      },
      {
        label: 'Cost Code',
        key: 'costCode',
        isEditable: true,
        sortable: false
      },
      {
        label: '*Hours Used',
        key: 'hoursUsed',
        isEditable: true,
        type: Number,
        sortable: true
      },
      {
        label: 'Operator',
        key: 'operator',
        isEditable: true,
        type: Date,
        sortable: false
      },
      {
        label: 'Location',
        key: 'location',
        sortable: false
      },
      {
        label: '',
        key: '',
        isEditable: true,
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'No.',
            key: 'number',
            isEditable: true,
            type: Number,
            sortable: true
          },
          {
            label: 'Item No.',
            key: 'itemNumber',
            isEditable: false,
            type: Number,
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            isEditable: false,
            type: String,
            sortable: true
          },
          {
            label: 'Model',
            key: 'model',
            isEditable: false,
            type: String,
            sortable: true
          },
          {
            label: 'Manufacturer',
            key: 'manufacturer',
            isEditable: false,
            type: String,
            sortable: true
          },
          {
            label: '*Start Date',
            key: 'startDate',
            isEditable: true,
            type: Date,
            sortable: true
          },
          {
            label: '*End Date',
            key: 'endDate',
            isEditable: true,
            type: Date,
            sortable: true
          },
          {
            label: '*Cost Center',
            key: 'costCenter',
            isEditable: true,
            sortable: false
          },
          {
            label: 'Cost Code',
            key: 'costCode',
            isEditable: true,
            sortable: false
          },
          {
            label: '*Hours Used',
            key: 'hoursUsed',
            isEditable: true,
            type: Number,
            sortable: true
          },
          {
            label: 'Operator',
            key: 'operator',
            isEditable: true,
            type: Date,
            sortable: false
          },
          {
            label: 'Location',
            key: 'location',
            sortable: false
          }
        ]
      },
      {
        label: '',
        key: '',
        isEditable: true,
        isModify: false
      }
    ]
  }
}

export default equipmentUsageLog

const meter = {
  tableHeader: {
    desktop: [
      {
        label: 'Item Number',
        key: 'ItemNumber',
        sortable: true,
        isEditable: true
      },
      {
        label: 'Model',
        key: 'Model',
        sortable: true,
        isEditable: true
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true,
        isEditable: true
      },
      {
        label: 'Current Meter',
        key: 'CurrentMeterLevel',
        sortable: true,
        isEditable: true
      },
      {
        label: 'New Meter',
        key: 'MeterLevel',
        sortable: true,
        isEditable: true
      },
      {
        label: 'Difference',
        key: 'MeterLevelDelta',
        sortable: true,
        isEditable: true
      },
      {
        label: 'Date',
        key: 'CreatedOn',
        type: Date,
        sortable: true,
        isEditable: true
      },
      {
        label: 'Status',
        key: 'ImportStatus',
        sortable: true,
        isEditable: false
      }
    ],
    mobile: [
      {
        label: 'Item Number',
        key: 'ItemNumber'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Model',
            key: 'Model',
            sortable: true,
            isEditable: true
          },
          {
            label: 'Description',
            key: 'Description',
            sortable: true,
            isEditable: true
          },
          {
            label: 'Current Meter',
            key: 'CurrentMeterLevel',
            sortable: true,
            isEditable: true
          },
          {
            label: 'New Meter',
            key: 'MeterLevel',
            sortable: true,
            isEditable: true
          },
          {
            label: 'Difference',
            key: 'MeterLevelDelta',
            sortable: true,
            isEditable: true
          },
          {
            label: 'Date',
            key: 'CreatedOn',
            type: Date,
            sortable: true,
            isEditable: true
          },
          {
            label: 'Status',
            key: 'ImportStatus',
            sortable: true,
            isEditable: false
          }
        ]
      }
    ]
  }
}

export default meter

const routes = [
  {
    name: 'Tablero del Sistema',
    route_name: 'Dashboard',
    icon: 'fal fa-chart-line',
    path: '',
    matchPath: ['/'],
    exact: true,
    completed: true,
    released: true,
    wareHouseUser: true,
    backOfficeUser: true,
    jobSiteUser: true
  },
  {
    name: 'Trabajos',
    icon: 'fal fa-list-alt',
    matchPath: ['tasks'],
    guard: ['TasksView'],
    entitlement: 'ToolsTasks',
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true,
    path: 'tasks'
  },
  {
    name: 'Catalogo',
    icon: 'fal fa-tools',
    matchPath: ['/catalog/'],
    exact: true,
    guard: ['ToolModelView', 'MaterialModelView'],
    entitlement: 'T_And_E',
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true,
    path: 'catalog'
  },
  {
    name: 'Buscador de Artículos',
    icon: 'fal fa-wrench',
    matchPath: ['/items/', '/items?type'],
    guard: ['ToolDetailView', 'MaterialDetailView'],
    entitlement: 'T_And_E',
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true,
    path: 'items'
  },
  {
    name: 'Área de bajo nivel',
    icon: 'fal fa-arrow-down-wide-short',
    matchPath: ['low-level'],
    guard: ['LowLevelBrowserView'],
    entitlement: 'M_And_C',
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true,
    path: 'low-level'
  },
  {
    name: 'Administración de tareas',
    icon: 'fal fa-list-alt',
    matchPath: ['workmanagement'],
    completed: true,
    released: true,
    jobSiteUser: true,
    backOfficeUser: true,
    wareHouseUser: true,
    children: [
      {
        name: 'Tarea Navegador',
        path: '/workflow/Tasks',
        matchPath: ['task-browser'],
        entitlement: 'ToolsTasks',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      },
      {
        name: 'Navegador de flujo de trabajo',
        path: '/workflow/workflow-browser',
        matchPath: ['workflow-browser'],
        guard: ['WorkflowsView'],
        entitlement: 'ToolsWorkflows',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      },
      {
        name: 'Flujos de trabajo activos',
        path: '/workflow/active-workflow',
        matchPath: ['active-workflow'],
        guard: ['WorkflowsView'],
        entitlement: 'ToolsWorkflows',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Aprobaciones',
        path: '/workflow/workflow-approvals',
        matchPath: ['workflow-approvals'],
        guard: ['AllApprovalsView'],
        entitlement: 'ToolsApprovals',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Manejo de Herramientas Retiradas',
    icon: 'fal fa-trash-undo',
    matchPath: ['retired-tools'],
    guard: ['ManageRetiredToolsView'],
    entitlement: 'T_And_E',
    completed: true,
    released: true,
    jobSiteUser: false,
    wareHouseUser: true,
    backOfficeUser: false,
    path: 'retired-tools'
  },
  {
    name: 'Costos y Facturación',
    icon: 'fal fa-calculator',
    matchPath: ['billing'],
    completed: true,
    released: true,
    jobSiteUser: true,
    backOfficeUser: true,
    children: [
      {
        name: 'Navegador de Importaciones',
        path: '/billing',
        guard: ['BillingBrowserView'],
        entitlement: 'JC_And_B',
        exact: true,
        matchPath: ['/billing/'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Navegador de Facturas',
        path: '/billing/invoice-browser',
        entitlement: 'JC_And_B',
        matchPath: ['invoice-browser'],
        guard: ['BillingBrowserView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Navegador de Transaccion de Combustible',
        path: '/fleet/fuel-transaction-browser',
        entitlement: 'JC_And_B',
        matchPath: ['fuel-transaction-browser'],
        guard: ['FuelCardsView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Hoja de Tarifas',
        path: '/billing/ratesheets',
        guard: ['RateSheetsView'],
        entitlement: 'JC_And_B',
        matchPath: ['rateSheets'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Consumo de Equipos',
        path: '/billing/equipment',
        guard: ['HourlyUsageLogView'],
        entitlement: 'JC_And_B',
        matchPath: ['equipment'],
        completed: true,
        released: true,
        backOfficeUser: true,
        jobSiteUser: true
      },
      {
        name: 'Exportar Plantillas',
        path: '/billing/export-templates',
        guard: ['ExportJobCost&BillingExecute'],
        entitlement: 'JC_And_B',
        matchPath: ['export-templates'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Exportar Datos',
        guard: ['ExportJobCost&BillingExecute'],
        entitlement: 'JC_And_B',
        path: '/billing/export-data',
        matchPath: ['export-data'],
        completed: true,
        released: true,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Solicitudes y Devoluciones',
    icon: 'fal fa-right-left',
    matchPath: ['requisitions', 'returns'],
    completed: true,
    released: true,
    jobSiteUser: true,
    children: [
      {
        name: 'Area de Solicitudes',
        path: '/requisitions',
        exact: true,
        matchPath: ['/requisitions/'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        jobSiteUser: true
      },
      {
        name: 'Historial de Solicitudes',
        params: 'items',
        path: '/requisitions/requests/items',
        matchPath: ['requisitions/requests'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        jobSiteUser: true
      },
      {
        name: 'Area de Devoluciones',
        path: '/returns',
        exact: true,
        matchPath: ['/returns/'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        coming: false,
        jobSiteUser: true
      },
      {
        name: 'Historial de Devoluciones',
        path: '/returns/history',
        matchPath: ['returns/history'],
        guard: ['RequisitionsandReturnsView'],
        entitlement: 'Requisitions',
        completed: true,
        coming: false,
        jobSiteUser: true
      }
    ]
  },
  {
    name: 'Programaciones',
    icon: 'fal fa-calendar-alt',
    matchPath: ['reservations'],
    completed: true,
    released: true,
    jobSiteUser: true,
    backOfficeUser: true,
    wareHouseUser: true,
    children: [
      {
        name: 'Area de Programaciones',
        path: '/reservations',
        exact: true,
        matchPath: ['/reservations/'],
        entitlement: 'Reservations',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      },
      {
        name: 'Historial de Programaciones',
        params: 'items',
        path: '/reservations/history/items',
        matchPath: ['reservations'],
        entitlement: 'Reservations',
        completed: true,
        jobSiteUser: true,
        backOfficeUser: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'programación - Nueva!',
    icon: 'fal fa-calendar-alt',
    matchPath: ['scheduling'],
    guard: ['SchedulingView'],
    entitlement: 'Scheduling',
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    children: [
      {
        name: 'Scheduling Browser',
        path: '/scheduling/scheduling-browser',
        matchPath: ['/scheduling/scheduling-browser'],
        guard: ['SchedulingBrowserView'],
        entitlement: 'Scheduling',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true
      },
      {
        name: 'Scheduling Request',
        path: '/scheduling/scheduling-request',
        matchPath: ['/scheduling/scheduling-request'],
        guard: ['SchedulingRequestView'],
        entitlement: 'Scheduling',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true
      },
      {
        name: 'Scheduling Calendar',
        path: '/scheduling/scheduling-calendar',
        matchPath: ['/scheduling/scheduling-calendar'],
        guard: ['SchedulingCalendarView'],
        entitlement: 'Scheduling',
        completed: true,
        jobSiteUser: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Servicios & Calibracion',
    icon: 'fal fa-list-check',
    matchPath: ['service'],
    completed: true,
    released: false,
    wareHouseUser: true,
    children: [
      {
        name: 'Tablero',
        route_name: 'Service Dashboard',
        path: '/service/dashboard',
        exact: true,
        matchPath: ['/service/dashboard'],
        entitlement: 'S_And_C',
        completed: false,
        wareHouseUser: true
      },
      {
        name: 'Solicitud de Servicios',
        path: '/service/requests',
        matchPath: ['/service/requests', '/service/request'],
        guard: ['ServiceBrowserView'],
        entitlement: 'S_And_C',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Orden de Trabajos',
        path: '/service/work-orders',
        matchPath: ['/service/work-orders'],
        guard: ['ServiceBrowserView'],
        entitlement: 'S_And_C',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Navegador de Programacion de Servicios',
        path: '/service/schedule',
        matchPath: ['/service/schedule', '/service/service-schedule'],
        guard: ['ServiceScheduleView'],
        entitlement: 'S_And_C',
        completed: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Solicitudes & Transferencias',
    icon: 'fal fa-clipboard-list-check',
    matchPath: ['tickets'],
    completed: true,
    released: false,
    wareHouseUser: true,
    children: [
      {
        name: 'Tablero',
        route_name: 'Assignments Dashboard',
        path: '/tickets/dashboard',
        matchPath: ['/tickets/dashboard'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Boleta de Transferencias',
        path: '/tickets/transfers',
        guard: ['TransfersView'],
        matchPath: ['/tickets/transfers'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Boletos de Solicitudes',
        path: '/tickets/picks',
        matchPath: ['/tickets/picks'],
        guard: ['PickTicketView'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Navegador de solicitudes',
        path: 'requisition-browser',
        matchPath: ['requisition-browser'],
        guard: ['RequisitionBrowserView'],
        entitlement: 'P_And_R',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Inventario de Conteo',
        path: '/tickets/count-inventory',
        guard: ['CountInventoryView'],
        matchPath: ['/tickets/count-inventory'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Plantillas',
        path: '/tickets/templates',
        matchPath: ['/tickets/templates'],
        entitlement: 'PT_And_T',
        completed: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Compras',
    icon: 'fal fa-shopping-cart',
    matchPath: ['purchasing'],
    completed: true,
    released: false,
    wareHouseUser: true,
    children: [
      {
        name: 'Tablero',
        route_name: 'Purchasing Dashboard',
        path: '/purchasing/dashboard',
        exact: true,
        matchPath: ['/purchasing/dashboard'],
        entitlement: 'P_And_R',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Ordenes de Compra',
        route_name: 'PurchaseOrders',
        path: '/purchasing/purchase-orders',
        exact: true,
        matchPath: ['/purchasing/purchase-orders'],
        guard: ['PurchaseOrdersView'],
        entitlement: 'P_And_R',
        completed: true,
        wareHouseUser: true
      },
      {
        name: 'Boletos de Recepcion',
        path: '/purchasing/receiving',
        matchPath: ['/purchasing/receiving'],
        guard: ['ReceivingTicketsView'],
        entitlement: 'P_And_R',
        completed: true,
        wareHouseUser: true
      }
    ]
  },
  {
    name: 'Mapa - NUEVO!',
    icon: 'fal fa-map',
    path: 'location-map',
    matchPath: ['location-map'],
    guard: ['LocationMapView'],
    entitlement: 'ToolsLocationsMap',
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  },
  {
    name: 'Reportes & Graficos',
    icon: 'fal fa-file-chart-pie',
    matchPath: ['reports'],
    completed: true,
    released: true,
    wareHouseUser: true,
    backOfficeUser: true,
    children: [
      {
        name: 'Reportes',
        route_name: 'Canned Reports',
        path: '/reports',
        exact: true,
        matchPath: ['/reports/'],
        entitlement: 'Reporting',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Reportes Personalizados',
        path: '/reports/custom',
        matchPath: ['reports/custom'],
        guard: ['CustomReportsView'],
        entitlement: 'CustomReports',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Reportes Pogramados',
        path: '/reports/scheduled',
        matchPath: ['reports/scheduled'],
        guard: ['ScheduledReportsView'],
        entitlement: 'CustomReports',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Tableros Personalizados',
        path: '/reports/dashboards',
        matchPath: ['/reports/dashboards'],
        entitlement: 'CustomDashboards',
        completed: true,
        released: true,
        wareHouseUser: true,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Administracio',
    icon: 'fal fa-users-cog',
    matchPath: ['administration'],
    completed: true,
    released: true,
    backOfficeUser: true,
    children: [
      {
        name: 'BLE Sensores de Puerto',
        path: '/administration/ble-gateways',
        matchPath: ['administration/ble-gateways'],
        guard: ['WirelessGatewaysView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'BLE Etiquetas',
        path: '/administration/ble-tags',
        matchPath: ['administration/ble-tags'],
        guard: ['WirelessTagsView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Programacion de la Compañia',
        path: '/administration/company-settings',
        guard: ['CompanyDefaultsView'],
        matchPath: ['administration/company-settings'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Configuracion de roles',
        path: '/administration/configure-user-roles',
        matchPath: ['administration/configure-user-roles'],
        guard: ['RolesView'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Planes de seguridad del sitio',
        path: '/administration/safety-plan',
        matchPath: ['administration/safety-plan'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Cargador de Data',
        path: '/administration/document-central',
        matchPath: ['administration/document-central'],
        guard: ['DocumentCentralView'],
        entitlement: 'ToolsDocumentCentral',
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Data Loader',
        path: '/administration/data-loader',
        guard: ['DataLoaderView'],
        entitlement: 'DataLoader',
        matchPath: ['administration/data-loader'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Campos Personalizados',
        path: '/administration/custom-fields',
        guard: ['CustomFieldsView'],
        entitlement: 'CustomFields',
        matchPath: ['administration/custom-fields'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Integracion',
        matchPath: ['administration/integration'],
        completed: true,
        released: true,
        backOfficeUser: true,
        children: [
          {
            name: 'Catalogo',
            route_name: 'Integration Catalog',
            path: '/administration/integration/catalog',
            matchPath: ['administration/integration/catalog'],
            entitlement: 'Integration',
            completed: true,
            released: true
          },
          {
            name: 'Programacion',
            route_name: 'Integration Settings',
            path: '/administration/integration/settings',
            matchPath: ['administration/integration/settings'],
            entitlement: 'Integration',
            completed: true,
            released: true
          }
        ]
      },
      {
        name: 'Calendario de la Compañia',
        path: '/administration/company-calendar',
        guard: ['CompanyCalendarView'],
        matchPath: ['administration/company-calendar'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'importar Medidor',
        path: '/billing/import-meter',
        matchPath: ['import-meter'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Numerador',
        path: '/administration/numbering',
        guard: ['NumberBlocksView'],
        matchPath: ['administration/numbering'],
        completed: true,
        released: true,
        backOfficeUser: true
      },
      {
        name: 'Visibilidad de Listas',
        guard: ['ListVisibilityView'],
        path: '/administration/list-visibility',
        matchPath: ['administration/list-visibility'],
        completed: true,
        released: false,
        backOfficeUser: true
      },
      {
        name: 'Listas',
        matchPath: ['administration/lists'],
        completed: true,
        released: true,
        backOfficeUser: true,
        children: [
          {
            name: 'Herramientas & Materiales'
          },
          {
            name: 'Categorías',
            guard: ['CategoriesView'],
            path: '/administration/lists/categories',
            matchPath: ['administration/lists/categories'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Certificaciones',
            guard: ['CertificationsView'],
            path: '/administration/lists/certifications',
            matchPath: ['administration/lists/certifications'],
            completed: true,
            released: true
          },
          {
            name: 'Clases',
            guard: ['ClassesView'],
            path: '/administration/lists/classes',
            matchPath: ['administration/lists/classes'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Contratistas',
            guard: ['ContractorsView'],
            path: '/administration/lists/contractors',
            matchPath: ['administration/lists/contractors'],
            completed: true,
            released: true
          },
          {
            name: 'Clientes',
            guard: ['CustomersView'],
            path: '/administration/lists/customers',
            matchPath: ['administration/lists/customers'],
            completed: true,
            released: true
          },
          {
            name: 'Departamentos',
            guard: ['DepartmentsView'],
            path: '/administration/lists/departments',
            matchPath: ['administration/lists/departments'],
            completed: true,
            released: true
          },
          {
            name: 'Descripciones',
            guard: ['DescriptionsView'],
            path: '/administration/lists/descriptions',
            matchPath: ['administration/lists/descriptions'],
            completed: true,
            released: true
          },
          {
            name: 'Empleados',
            guard: ['EmployeesView'],
            path: '/administration/lists/employees',
            matchPath: ['administration/lists/employees'],
            completed: true,
            released: true
          },
          {
            name: 'Tipos de Empleados',
            guard: ['EmployeeTypesView'],
            path: '/administration/lists/employee-types',
            matchPath: ['administration/lists/employee-types'],
            completed: true,
            released: true
          },
          {
            name: 'Ubicaciónes',
            guard: ['LocationsView'],
            path: '/administration/lists/locations',
            matchPath: ['administration/lists/locations'],
            completed: true,
            released: true
          },
          {
            name: 'Fabricantes',
            guard: ['ManufacturersView'],
            path: '/administration/lists/manufacturers',
            matchPath: ['administration/lists/manufacturers'],
            completed: true,
            released: true
          },
          {
            name: 'Metodos de Envio',
            guard: ['ShippingMethodsView'],
            path: '/administration/lists/shipping-methods',
            matchPath: ['administration/lists/shipping-methods'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Codigo de Estados',
            guard: ['StatusCodesView'],
            path: '/administration/lists/status-codes',
            matchPath: ['administration/lists/status-codes'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Unidades de Medidas',
            guard: ['UnitsofMeasureView'],
            path: '/administration/lists/units-of-measure',
            matchPath: ['administration/lists/units-of-measure'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Combustible'
          },
          {
            name: 'Tarjeta de Combustible',
            guard: ['FuelCardsView'],
            path: '/administration/lists/fuel-cards',
            matchPath: ['administration/lists/fuel-cards'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Empleado de Combustible',
            guard: ['FuelCardsView'],
            path: '/administration/lists/fuel-employees',
            matchPath: ['administration/lists/fuel-employees'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Trabajos'
          },
          {
            name: 'Categorías de Tarea',
            guard: ['TasksView'],
            path: '/administration/lists/tasks-categories',
            matchPath: ['administration/lists/tasks-categories'],
            entitlement: 'ToolsTasks',
            completed: true,
            released: true
          },
          {
            name: 'Prioridades de Tarea',
            guard: ['TasksView'],
            path: '/administration/lists/tasks-priorities',
            matchPath: ['administration/lists/tasks-priorities'],
            entitlement: 'ToolsTasks',
            completed: true,
            released: true
          },
          {
            name: 'Estados de Tarea',
            guard: ['TasksView'],
            path: '/administration/lists/tasks-statuses',
            matchPath: ['administration/lists/tasks-statuses'],
            entitlement: 'ToolsTasks',
            completed: true,
            released: true
          },
          {
            name: 'Compra & Recepcion'
          },
          {
            name: 'Vendedores',
            guard: ['VendorsView'],
            path: '/administration/lists/vendors',
            matchPath: ['administration/lists/vendors'],
            entitlement: 'P_And_R',
            completed: true,
            released: true
          },
          {
            name: 'Costo & Facturación'
          },
          {
            name: 'Centro de Costo',
            guard: ['CostCentersView'],
            path: '/administration/lists/cost-centers',
            matchPath: ['administration/lists/cost-centers'],
            entitlement: 'JC_And_B',
            completed: true,
            released: true
          },
          {
            name: 'Codigo de Costo',
            guard: ['CostCodesView'],
            path: '/administration/lists/cost-codes',
            matchPath: ['administration/lists/cost-codes'],
            entitlement: 'JC_And_B',
            completed: true,
            released: true
          },
          {
            name: 'Servicios'
          },
          {
            name: 'Clase de Servicios',
            path: '/administration/lists/service-classes',
            matchPath: ['administration/lists/service-classes'],
            entitlement: 'S_And_C',
            completed: true,
            released: true
          },
          {
            name: 'Lista de Utilidades'
          },
          {
            name: 'Utilidad para migrar Datos',
            guard: ['DataMergeUtilityExecute'],
            path: '/administration/lists/data-merge-utility',
            matchPath: ['administration/lists/data-merge-utility'],
            entitlement: 'T_And_E',
            completed: true,
            released: true
          }
        ]
      }
    ]
  },
  {
    name: 'Papelera de Reciclaje',
    icon: 'fal fa-recycle',
    path: 'recycle-bin',
    matchPath: ['recycle-bin'],
    guard: ['RecyclingBinView'],
    completed: true,
    released: true,
    jobSiteUser: false,
    wareHouseUser: true,
    backOfficeUser: true
  },
  {
    name: 'Insights',
    icon: 'fal fa-lightbulb',
    matchPath: ['Insights'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  },
  {
    name: 'EHS',
    icon: 'fa-light fa-square-plus',
    matchPath: ['safety'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true,
    children: [
      {
        name: 'Administrador',
        path: '/safety/admin',
        matchPath: ['safety/admin'],
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Inspeccion',
        path: '/safety/inspections',
        matchPath: ['safety/inspections'],
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'JSA',
        path: '/safety/jsa',
        matchPath: ['safety/jsa'],
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Observaciones',
        path: '/safety/observations',
        matchPath: ['safety/observations'],
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Incidentes',
        path: '/safety/incidents',
        matchPath: ['safety/incidents'],
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Scaneos',
        path: '/safety/scan',
        matchPath: ['safety/scan'],
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      },
      {
        name: 'Formas',
        path: '/safety/forms',
        matchPath: ['safety/forms'],
        completed: true,
        released: true,
        jobSiteUser: true,
        wareHouseUser: true,
        backOfficeUser: true
      }
    ]
  },
  {
    name: 'Ayuda',
    icon: 'fal fa-question-circle',
    matchPath: ['help'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  }
]

export default routes

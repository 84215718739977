import axios from 'axios'
import { errorHandler } from '../helpers/utils.js'

const getDefaultState = () => {
  return {
    ReturnTicketId: null,
    Items: []
  }
}

export default {
  state: () => ({
    ticket: {
      ReturnTicketId: null,
      Items: []
    },
    isTicketLoading: false,
    searchObj: {
      ItemNumber: '',
      SerialNumber: '',
      Barcode: ''
    }
  }),
  mutations: {
    storeTicket (state, ticket) {
      state.ticket = ticket
    },
    storeTicketId (state, ticketId) {
      state.ticket.ReturnTicketId = ticketId
    },
    setTicketLoading (state, flag) {
      state.isTicketLoading = flag
    },
    setSearchParams (state, obj) {
      state.searchObj = obj
    },
    resetSearchParams (state) {
      state.searchObj.ItemNumber = ''
      state.searchObj.SerialNumber = ''
      state.searchObj.Barcode = ''
    }
  },
  actions: {
    async createReturn (context, params) {
      const { data } = await axios.post('/api/core/ReturnsService/CreateReturn', params)
      context.commit('storeTicketId', data.Body)
      await context.dispatch('setTicket', context.getters.ticket)
    },
    async addItemToTicket (context, params) {
      await axios.post('/api/core/ReturnsService/AddItemsToReturn', params)
      await context.dispatch('setTicket', context.getters.ticket)
    },
    async removeItemsFromTicket (context, ItemIds) {
      const params = {
        returnTicketId: `"${context.getters.ticket.ReturnTicketId}"`,
        itemIds: JSON.stringify(ItemIds)
      }
      try {
        await axios.post('/api/core/ReturnsService/RemoveItemsFromReturn', params)
        await context.dispatch('setTicket', context.getters.ticket)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    async checkoutWithTicket (context, ticket) {
      const { data } = await axios.post('/api/core/ReturnsService/SubmitReturn', ticket)
      if (data.Success) {
        // successfully submitted the ticket, reset ticket state.
        await context.dispatch('clearTicket')
      }
    },
    async setTicket (context, ticket) {
      try {
        const params = {
          returnTicketId: `"${ticket.ReturnTicketId}"`
        }
        const { data } = await axios.post('/api/core/ReturnsService/GetReturn', params)
        context.commit('storeTicket', data.Body)
      } catch (error) {
        console.log(error.response)
      }
    },
    async clearTicket (context) {
      context.commit('storeTicket', getDefaultState())
    }
  },
  getters: {
    ticketItems (state) {
      return state.ticket.Items
    },
    ticket (state) {
      return state.ticket
    },
    isTicketLoading (state) {
      return state.isTicketLoading
    },
    getSearchOptions (state) {
      return state.searchObj
    }
  }
}

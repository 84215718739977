export default {
  state: () => ({
    showNavigation: 'tw',
    /**
     * @typedef {'expanded' | 'hidden' | 'collapsed'}
     */
    navigationBarStatusWFM: 'expanded',
    lastTWRoute: null,
    lastEHSRoute: null,
    lastWFMRoute: null
  }),
  mutations: {
    setShowNavigation (state, value) {
      state.showNavigation = value
    },
    setNavigationBarStatusWFM (state, value) {
      state.navigationBarStatusWFM = value
    },
    setLastTWRoute (state, value) {
      state.lastTWRoute = value
    },
    setLastEHSRoute (state, value) {
      state.lastEHSRoute = value
    },
    setLastWFMRoute (state, value) {
      state.lastWFMRoute = value
    }
  },
  getters: {
    isShowNavigation (state) {
      return state.showNavigation
    },
    navigationBarStatusWFM (state) {
      return state.navigationBarStatusWFM
    },
    lastTWRoute (state) {
      return state.lastTWRoute
    },
    lastEHSRoute (state) {
      return state.lastEHSRoute
    },
    lastWFMRoute (state) {
      return state.lastWFMRoute
    }
  }
}

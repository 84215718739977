import { deepCopy, getFormatedDateString, uniq, uniqIfNotNull } from '../helpers/utils'

export default {
  state: () => ({
    workOrderSearchParam: {
      stateId: 1,
      workOrderStatus: null,
      assignedToId: null,
      dateCreatedStart: `${getFormatedDateString(new Date(), 0)}T00:00:00`,
      dateCreatedEnd: null,
      requestDesc: null,
      includeCharges: true,
      chargesColumnName: 'Charges',
      includeDeleted: false
    },
    workOrderView: {},
    workOrdersTasks: [],
    workOrdersTasksPerformed: [],
    requiredTools: [],
    requiredParts: [],
    requiredPartsUsed: [],
    workOrderTransfers: [],
    workOrderCharges: [],
    hasBeenChangeForRequiredTools: false,
    hasBeenChangeForParts: false,
    hasBeenChangeForAssignments: false,
    hasBeenChangeForCharges: false,
    hasBeenChangeForTasks: false
  }),
  mutations: {
    setWorkOrderSearchParam (state, value) {
      state.workOrderSearchParam = value
    },
    // work order tasks
    addWorkOrdersTasks (state, item) {
      state.workOrdersTasks = [...state.workOrdersTasks, ...item]
    },
    updateWorkOrderTasks (state) {
      state.workOrdersTasks = [...state.workOrdersTasks]
    },
    resetWorkOrderTasks (state) {
      state.workOrdersTasks = []
    },
    removeWorkOrdersTasks (state, id) {
      state.workOrdersTasks = deepCopy(state.workOrdersTasks.filter(ele => ele.sequenceNumber !== id))
    },

    // work order tasks performed
    addWorkOrdersTasksPerformed (state, item) {
      state.workOrdersTasksPerformed = [...state.workOrdersTasksPerformed, ...item]
    },
    resetWorkOrdersTasksPerformed (state) {
      state.workOrdersTasksPerformed = []
    },
    // required tools
    addRequiredTools (state, item) {
      const array = [...state.requiredTools, ...item]
      state.requiredTools = uniq(array, x => x.workOrderRequiredToolId)
    },
    resetRequiredTools (state) {
      state.requiredTools = []
    },
    removeRequiredTools (state, id) {
      state.requiredTools = deepCopy(state.requiredTools.filter(ele => ele.workOrderRequiredToolId !== id))
    },
    // required parts
    addRequiredParts (state, item) {
      const array = [...state.requiredParts, ...item]
      state.requiredParts = uniq(array, x => x.workOrderRequiredPartId)
    },
    resetRequiredParts (state) {
      state.requiredParts = []
    },
    removeRequiredParts (state, id) {
      state.requiredParts = deepCopy(state.requiredParts.filter(ele => ele.workOrderRequiredPartId !== id))
    },
    // required parts used
    addRequiredPartsUsed (state, item) {
      const array = [...state.requiredPartsUsed, ...item]
      state.requiredPartsUsed = uniq(array, x => x.workOrderRequiredPartUsedId)
    },
    resetRequiredPartsUsed (state) {
      state.requiredPartsUsed = []
    },
    removeRequiredPartsUsed (state, id) {
      state.requiredPartsUsed = deepCopy(state.requiredPartsUsed.filter(ele => ele.workOrderRequiredPartUsedId !== id))
    },
    // work order transfers
    addWorkOrderTransfers (state, item) {
      state.workOrderTransfers = [...state.workOrderTransfers, ...item]
    },
    resetWorkOrderTransfers (state) {
      state.workOrderTransfers = []
    },
    // work order charges
    addWorkOrderCharges (state, item) {
      const array = [...state.workOrderCharges, ...item]
      state.workOrderCharges = uniqIfNotNull(array, x => x.workOrderRequiredPartId)
    },
    resetWorkOrderCharges (state) {
      state.workOrderCharges = []
    },
    removeWorkOrderCharges (state, id) {
      state.workOrderCharges = deepCopy(state.workOrderCharges.filter(ele => ele.workOrderChargeId !== id))
    },
    removeWorkOrderChargeByPartId (state, id) {
      state.workOrderCharges = deepCopy(state.workOrderCharges.filter(ele => ele.workOrderRequiredPartId !== id))
    },
    setHasBeenChangeForRequiredTools (state, value) {
      state.hasBeenChangeForRequiredTools = value
    },
    setHasBeenChangeForParts (state, value) {
      state.hasBeenChangeForParts = value
    },
    setHasBeenChangeForAssignment (state, value) {
      state.hasBeenChangeForAssignments = value
    },
    setHasBeenChangeForCharges (state, value) {
      state.hasBeenChangeForCharges = value
    },
    setHasBeenChangeForTasks (state, value) {
      state.hasBeenChangeForTasks = value
    },

    // work order view
    setWorkOrderView (state, value) {
      state.workOrderView = { ...state.workOrderView, ...value }
    },
    resetWorkOrderView (state) {
      state.workOrderView = {}
    }
  },
  getters: {
    getWorkOrderSearchParam (state) {
      return state.workOrderSearchParam
    },
    workOrdersTasks (state) {
      return state.workOrdersTasks
    },
    workOrdersTasksPerformed (state) {
      return state.workOrdersTasksPerformed
    },
    requiredTools (state) {
      return state.requiredTools
    },
    requiredParts (state) {
      return state.requiredParts
    },
    requiredPartsUsed (state) {
      return state.requiredPartsUsed
    },
    workOrderCharges (state) {
      return state.workOrderCharges
    },
    workOrderTransfers (state) {
      return state.workOrderTransfers
    },
    workOrderView (state) {
      return state.workOrderView
    },
    workOrderId (state) {
      return state.workOrderView.workOrderId
    },
    hasBeenChangeForRequiredTools (state) {
      return state.hasBeenChangeForRequiredTools
    },
    hasBeenChangeForParts (state) {
      return state.hasBeenChangeForParts
    },
    hasBeenChangeForAssignments (state) {
      return state.hasBeenChangeForAssignments
    },
    hasBeenChangeForCharges (state) {
      return state.hasBeenChangeForCharges
    },
    hasBeenChangeForTasks (state) {
      return state.hasBeenChangeForTasks
    }
  }
}

export default {
  state: () => ({
    isGrid: true
  }),
  mutations: {
    setGrid (state, flag) {
      state.isGrid = flag
    }
  },
  actions: {

  },
  getters: {
    isGrid (state) {
      return state.isGrid
    }
  }
}

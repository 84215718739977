export default {
  state: () => ({
    reports: null,
    folders: null,
    possibleRecipients: null,
    possibleRoles: null
  }),
  mutations: {
    addReports (state, data) {
      state.reports = [].concat(data)
    },
    addFolders (state, data) {
      state.folders = [].concat(data)
    },
    addPossibleRecipients (state, data) {
      state.possibleRecipients = [].concat(data)
    },
    addPossibleRoles (state, data) {
      state.possibleRoles = [].concat(data)
    }
  },
  getters: {
    reports: state => state.reports,
    folders: state => state.folders,
    possibleRecipients: state => state.possibleRecipients,
    possibleRoles: state => state.possibleRoles
  }
}

const lowLevelBrowser = {
  title: 'Navegador de bajo nivel',
  actionHeader: {
    0: {
      title: 'Recalcular',
      icon: 'fas fa-sync-alt'
    },
    1: {
      title: 'Exportar datos',
      icon: 'fas fa-file-export'
    },
    2: {
      title: 'Agregar a la orden de compra',
      icon: 'fas fa-plus'
    }
  },
  tableHeader: {
    desktop: [
      {
        label: 'Categoría',
        key: 'masterCategory',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'masterDescription',
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'model',
        sortable: true
      },
      {
        label: 'Nivel actual',
        key: 'stockLevel',
        sortable: true
      },
      {
        label: 'En orden',
        key: 'qtyOnOrder',
        sortable: true
      },
      {
        label: 'Nivel bajo',
        key: 'lowLevel',
        sortable: true
      },
      {
        label: 'Nivel objetivo',
        key: 'targetLevel',
        sortable: true
      },
      {
        label: 'Alto nivel',
        key: 'highLevel',
        sortable: true
      },
      {
        label: 'Vendedor',
        key: 'preferredVendor',
        sortable: true
      },
      {
        label: '',
        key: 'lowLevelAction'
      }
    ],
    mobile: [
      {
        label: 'Categoría',
        key: 'masterCategory'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Descripción',
            key: 'masterDescription',
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'model',
            sortable: true
          },
          {
            label: 'Nivel actual',
            key: 'stockLevel',
            sortable: true
          },
          {
            label: 'En orden',
            key: 'qtyOnOrder',
            sortable: true
          },
          {
            label: 'Nivel bajo',
            key: 'lowLevel',
            sortable: true
          },
          {
            label: 'Nivel objetivo',
            key: 'targetLevel',
            sortable: true
          },
          {
            label: 'Alto nivel',
            key: 'highLevel',
            sortable: true
          },
          {
            label: 'Vendedor',
            key: 'preferredVendor',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: 'lowLevelAction'
      }
    ]
  }
}

export default lowLevelBrowser

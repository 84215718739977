const InvoiceBrowserGenerate = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'Selected',
        type: Boolean,
        sortable: false
      },
      {
        label: 'Número',
        key: 'CostCenterNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'CostCenterDescription',
        type: String,
        sortable: true
      },
      {
        label: 'Cliente',
        key: 'CustomerName',
        type: String,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'Selected',
        type: Boolean,
        sortable: false
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Número',
            key: 'CostCenterNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'CostCenterDescription',
            type: String,
            sortable: true
          },
          {
            label: 'Cliente',
            key: 'CustomerName',
            type: String,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default InvoiceBrowserGenerate

<template>
  <router-view v-if="isProcessing"></router-view>
</template>

<script>
import axios from 'axios'
import { errorHandler } from '../../helpers/utils'

export default {
  name: 'TicketRouterView',
  data () {
    return {
      isProcessing: false
    }
  },
  async created () {
    this.isProcessing = false
    this.$store.commit('setLoading', true)
    if (!this.$route.path.includes('dashboard')) {
      const reqArray = []
      if (!this.entityData) {
        reqArray.push(this.$store.dispatch('loadDataByListType', {
          query: '',
          listTypeId: 10,
          stockpointId: this.globalStockpointId
        }))
      }
      if (!this.manufData) {
        reqArray.push(this.$store.dispatch('loadDataByListType', {
          query: '',
          listTypeId: 14,
          stockpointId: this.globalStockpointId
        }))
      }
      if (!this.employeeData) {
        reqArray.push(this.$store.dispatch('loadDataByListType', {
          query: '',
          listTypeId: 7,
          stockpointId: this.globalStockpointId
        }))
      }
      if (!this.statusData) {
        reqArray.push(this.$store.dispatch('loadDataByListType', {
          query: '',
          listTypeId: 15,
          stockpointId: this.globalStockpointId
        }))
      }
      if (!this.categories.length) {
        reqArray.push(this.getCatetories())
      }
      if (!this.templateTypes.length) {
        reqArray.push(this.getTemplateTypes())
      }
      if (!this.serviceDescriptions.length) {
        this.$store.dispatch('getDescriptions')
      }
      if (!this.templateCategories.length) {
        reqArray.push(this.getTemplateCategories())
      }
      if (!this.pickStatuses.length) {
        reqArray.push(this.getPickStatuses())
      }
      await Promise.all(reqArray)
    }
    this.$store.commit('setLoading', false)
    this.isProcessing = true
  },
  methods: {
    async getCatetories () {
      const params = {
        orgId: `"${localStorage.getItem('OrgId')}"`,
        ids: JSON.stringify([]),
        number: '',
        description: 'null',
        includeDeleted: 'false'
      }
      try {
        const { data } = await axios.post('/api/core/CategoriesService/GetCategories', params)
        this.$store.commit('setCategories', data.Body)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    async getTemplateTypes () {
      const params = {
        orgId: `"${localStorage.getItem('OrgId')}"`
      }
      try {
        const { data } = await axios.post('/api/core/TemplateService/GetTypes', params)
        // this.templateTypes = data.Body
        this.$store.commit('setTemplateTypes', data.Body)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    async getTemplateCategories () {
      const selectedType = 'A'
      const params = {
        orgId: `"${localStorage.getItem('OrgId')}"`,
        type: '26',
        listVisStockpointId: `"${this.globalStockpointId}"`,
        sortBy: '\'description\'',
        criteria: `" MasterCategories.type = '${selectedType}' "`,
        inactive: 'false',
        deleted: 'false'
      }
      try {
        const { data } = await axios.post('/api/core/ListsService/GetListItems', params)
        this.$store.commit('setTemplateCategories', data.Body)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    async getPickStatuses () {
      try {
        const { data } = await axios.post('/api/core/PickTicketsService/GetStatuses', {})
        const pickStatuses = data.Body
        pickStatuses.unshift({
          StatusId: null,
          Name: '\xa0',
          ParentId: null
        })
        this.$store.commit('setPickStatuses', pickStatuses)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    }
  },
  computed: {
    globalStockpointId () {
      return this.$store.getters.getStockpointId
    },
    statusData () {
      return this.$store.getters.getShipData(15)
    },
    employeeData () {
      return this.$store.getters.getShipData(7)
    },
    entityData () {
      return this.$store.getters.getShipData(10)
    },
    manufData () {
      return this.$store.getters.getShipData(14)
    },
    isPick () {
      return this.$route.path.includes('pick')
    },
    categories () {
      return this.$store.getters.categories
    },
    templateTypes () {
      return this.$store.getters.templateTypes
    },
    serviceDescriptions () {
      return this.$store.getters.descriptions
    },
    templateCategories () {
      return this.$store.getters.templateCategories
    },
    pickStatuses () {
      return this.$store.getters.pickStatuses
    }
  }
}
</script>

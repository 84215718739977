const userRoles = {
  tableHeader: {
    desktop: [
      {
        label: 'Name',
        key: 'Name',
        sortable: true
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: '',
        key: '',
        isModify: false
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Name',
            key: 'Name',
            sortable: true
          }
        ]
      }
    ]
  },
  rolePermissionsTblHeader: {
    desktop: [
      {
        label: 'Permission',
        key: 'Description',
        sortable: true
      },
      {
        label: 'View',
        key: 'View',
        permission: 'CanView',
        sortable: true
      },
      {
        label: 'Add',
        key: 'Add',
        permission: 'CanAdd',
        sortable: true
      },
      {
        label: 'Edit',
        key: 'Edit',
        permission: 'CanEdit',
        sortable: true
      },
      {
        label: 'Delete',
        key: 'Delete',
        permission: 'CanDelete',
        sortable: true
      },
      {
        label: 'Execute',
        key: 'Execute',
        permission: 'CanExecute',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: '',
        isModify: false
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Permission',
            key: 'Description',
            sortable: true
          },
          {
            label: 'View',
            key: 'View',
            permission: 'CanView',
            sortable: true
          },
          {
            label: 'Add',
            key: 'Add',
            permission: 'CanAdd',
            sortable: true
          },
          {
            label: 'Edit',
            key: 'Edit',
            permission: 'CanEdit',
            sortable: true
          },
          {
            label: 'Delete',
            key: 'Delete',
            permission: 'CanDelete',
            sortable: true
          },
          {
            label: 'Execute',
            key: 'Execute',
            permission: 'CanExecute',
            sortable: true
          }
        ]
      }
    ]
  },
  usersAssignedToRolePage: {
    title: 'Users Assigned to Role',
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'TWEntityId',
          type: 'check'
        },
        {
          label: 'No.',
          key: 'EntityNumber',
          sortable: true
        },
        {
          label: 'First Name',
          key: 'FirstName',
          sortable: true
        },
        {
          label: 'Last Name',
          key: 'LastName',
          sortable: true
        },
        {
          label: 'Type',
          key: 'Type',
          sortable: true
        },
        {
          label: 'Additional Roles',
          key: 'AdditionalRolesCount',
          type: Number,
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'TWEntityId',
          type: 'check'
        },
        {
          label: 'No.',
          key: 'EntityNumber',
          sortable: true
        },
        {
          label: 'First Name',
          key: 'FirstName',
          sortable: true
        },
        {
          label: 'Last Name',
          key: 'LastName',
          sortable: true
        },
        {
          label: 'Type',
          key: 'Type',
          sortable: true
        },
        {
          label: 'AdditionalRoles',
          key: 'AdditionalRolesCount',
          type: Number,
          sortable: true
        }
      ]
    },
    roleNameLabel: 'Role Name: ',
    usersAssignedToRoleCountLabel: 'Users Assigned to Role: ',
    addUserToRoleButton: 'Add User(s) to Role',
    backToRoles: 'Back to Roles',
    dropdownActions: [
      {
        title: 'Reassign',
        actionName: 'reassignItem',
        permissions: []
      },
      {
        title: 'Remove',
        actionName: 'removeItem',
        permissions: []
      }
    ],
    removalOfUsersModal: {
      title: 'Remove User(s)',
      confirmMessage: 'Are you sure you want to remove the following user(s)' +
        ' from the {roleName} role?',
      messageBody: 'The following users will be converted back to employees -' +
        ' <br> <i>taking away their access to the ToolWatch product</i>'
    },
    reassignUsersModal: {
      title: 'Reassign User(s)',
      currentRoleLabel: 'Current Role Assigned: ',
      newRoleLabel: 'New Role to Assign user(s) ',
      tableHeader: {
        desktop: [
          {
            label: '',
            key: 'RoleName',
            type: 'check'
          },
          {
            label: 'Role Name',
            key: 'RoleName',
            sortable: true
          },
          {
            label: 'Role Description ',
            key: 'RoleDescription',
            sortable: true
          }
        ],
        mobile: [
          {

            label: '',
            key: 'RoleName',
            type: 'check'
          },
          {
            label: 'Role Name',
            key: 'RoleName',
            sortable: true
          },
          {
            label: 'Role Description ',
            key: 'RoleDescription',
            sortable: true
          }
        ]
      }
    },
    addUsersToRoleModal: {
      title: 'Add User(s) to Role',
      roleNameLabel: 'Role Name: ',
      usersAssignedToRoleCountLabel: 'Users Assigned to Role: ',
      dropdownActions: [
        {
          title: 'Edit',
          actionName: 'editItem',
          permissions: []
        }
      ],
      tableHeader: {
        desktop: [
          {
            label: 'IsTwUser',
            key: 'Username',
            type: 'user'
          },
          {
            label: '',
            key: 'Id',
            type: 'check'
          },
          {
            label: 'No.',
            key: 'Number',
            sortable: true
          },
          {
            label: 'First Name',
            key: 'FirstName',
            sortable: true
          },
          {
            label: 'Last Name',
            key: 'LastName',
            sortable: true
          },
          {
            label: 'Title',
            key: 'Title',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'IsTwUser',
            key: 'username',
            type: 'icon'
          },
          {
            label: '',
            key: 'description',
            type: 'check'
          },
          {
            label: 'No.',
            key: 'EntityNumber',
            sortable: true
          },
          {
            label: 'First Name',
            key: 'FirstName',
            sortable: true
          },
          {
            label: 'Last Name',
            key: 'LastName',
            sortable: true
          },
          {
            label: 'Title',
            key: 'Title',
            sortable: true
          }
        ]
      }
    }
  }
}

export default userRoles

const inventoryAdjustmentsTable = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: '',
        type: 'warning'
      },
      {
        label: '',
        key: 'check',
        type: 'check'
      },
      {
        label: 'Categoría',
        key: 'Category',
        sortable: true,
        type: String
      },
      {
        label: 'Artículo',
        key: 'ItemNumber',
        sortable: true,
        type: String
      },
      {
        label: 'Tipo',
        key: 'Type',
        sortable: true,
        type: String
      },
      {
        label: 'Descripción del Artículo',
        key: 'ItemDescription',
        sortable: true,
        type: String
      },
      {
        label: 'Fabricante',
        key: 'Manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Modelo',
        key: 'Model',
        sortable: true,
        type: String
      },
      {
        label: 'Anticipado',
        key: 'InStock',
        sortable: true,
        type: Number
      },
      {
        label: 'Conteo',
        key: 'Total',
        sortable: true,
        type: Number
      },
      {
        label: '+/-',
        key: 'Variance',
        sortable: true,
        type: Number
      },
      {
        label: 'Contado en',
        key: 'Location',
        sortable: true,
        type: String
      },
      {
        label: 'Asignación Actual',
        key: 'Assignment',
        sortable: true,
        type: String
      },
      {
        label: 'Etiqueta',
        key: 'TicketNumber',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: '',
        key: '',
        type: 'warning'
      },
      {
        label: 'Info',
        data: [
          {
            label: '',
            key: 'check',
            type: 'check'
          },
          {
            label: 'Categoría',
            key: 'Category',
            sortable: true,
            type: String
          },
          {
            label: 'Artículo',
            key: 'ItemNumber',
            sortable: true,
            type: String
          },
          {
            label: 'Tipo',
            key: 'Type',
            sortable: true,
            type: String
          },
          {
            label: 'Descripción del Artículo',
            key: 'ItemDescription',
            sortable: true,
            type: String
          },
          {
            label: 'Fabricante',
            key: 'Manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Modelo',
            key: 'Model',
            sortable: true,
            type: String
          },
          {
            label: 'Anticipado',
            key: 'InStock',
            sortable: true,
            type: Number
          },
          {
            label: 'Conteo',
            key: 'Total',
            sortable: true,
            type: Number
          },
          {
            label: '+/-',
            key: 'Variance',
            sortable: true,
            type: Number
          },
          {
            label: 'Contado en',
            key: 'Location',
            sortable: true,
            type: String
          },
          {
            label: 'Asignación Actual',
            key: 'Assignment',
            sortable: true,
            type: String
          },
          {
            label: 'Etiqueta',
            key: 'TicketNumber',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  }
}

export default inventoryAdjustmentsTable

const workAssignment = {
  tableHeader: {
    desktop: [
      {
        label: 'Asignación de',
        type: 'Guid',
        key: 'twEntityIdFrom',
        listType: 10,
        sortable: true
      },
      {
        label: 'Asignación a',
        key: 'twEntityIdTo',
        type: 'Guid',
        listType: 10,
        sortable: true
      },
      {
        label: 'Por',
        key: 'assignedBy',
        sortable: true
      },
      {
        label: 'Transferir',
        key: 'transferNumber',
        sortable: true
      },
      {
        label: 'Fecha',
        key: 'assignmentDate',
        type: Date,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Asignación de',
            type: 'Guid',
            key: 'twEntityIdFrom',
            listType: 10,
            sortable: true
          },
          {
            label: 'Asignación a',
            key: 'twEntityIdTo',
            type: 'Guid',
            listType: 10,
            sortable: true
          },
          {
            label: 'Por',
            key: 'assignedBy',
            sortable: true
          },
          {
            label: 'Transferir',
            key: 'transferNumber',
            sortable: true
          },
          {
            label: 'Fecha',
            key: 'assignmentDate',
            type: Date,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default workAssignment

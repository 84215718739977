const appLists = {
  0: {
    Key: 'Job Site',
    Value: 'jsp',
    Title: 'Job Site'
  },
  1: {
    Key: 'Warehouse/Yard Operations',
    Value: 'wh',
    Title: 'Warehouse/Yard'
  },
  2: {
    Key: 'Back Office',
    Value: 'bo',
    Title: 'Back Office'
  }
}

export default appLists

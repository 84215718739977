import store from './store'
import Vue from 'vue'
import vueDebounce from 'vue-debounce'
import router from './router'
import App from './App.vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import VueToast from 'vue-toast-notification'
import VueClosable from 'vue-closable'
import Vuelidate from 'vuelidate'
import vuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor'
import { i18n } from './i18n'
import { Trans } from './plugins/Translation'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueCookies from 'vue-cookies'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueGtag from 'vue-gtag'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-datepicker'
import 'metismenu'
import 'vue-toast-notification/dist/theme-sugar.css'
import './assets/css/toolwatch.css'
import './assets/css/plugins/footable/footable.core.css'
import './assets/css/plugins/touchspin/jquery.bootstrap-touchspin.min.css'
import './assets/css/plugins/inspinia-animate.css'
import './assets/fontawesome-pro-6.4.0/css/all.css'
import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import axios from 'axios'
import cookie from 'cookie'
import vuetify from './plugins/vuetify'
import Vuetify from 'vuetify'
import VueCompositionAPI from '@vue/composition-api'

import './registerComponentHooks'

import { datadogRum } from '@datadog/browser-rum'

Vue.use(VueCompositionAPI)
Vue.use(VueCookies)
Vue.use(VueToast)
Vue.use(VueClosable)
Vue.use(Vuetify, {
  theme: {
    primary: '#0031FF',
    secondary: '#424242'
  }
})
Vue.use(vueDebounce, {
  listenTo: ['input', 'keyup']
})
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD4KVHJtKG8DMz_uU-Eufy5krfQJmSUPMY',
    libraries: 'drawing' // necessary for places input
  }
})
Vue.use(VueGtag, {
  config: { id: 'G-MKVHNJ4CZV' },
  enabled: false
}, router)
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(vuelidateErrorExtractor, {
  i18n: 'validations',
  template: templates.singleErrorExtractor.foundation6
})

const options = {
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    './assets/css/toolwatch.css'
  ]
}

Vue.use(VueHtmlToPaper, options)
Vue.component('FormWrapper', templates.FormWrapper)
Vue.component('FormErrors', templates.multiErrorExtractor.foundation6)

const cookies = cookie.parse(document.cookie || '')
axios.defaults.headers.common['X-TW-CSRF'] = cookies['tw.cloud.csrf']
if (process.env.VUE_APP_SENTRY_ENABLED == 'true') {
  console.log('Sentry.io tracking enabled')
  Sentry.init({
    Vue,
    dsn: 'https://8ae5c7a592b848f5898aead485bc4fc3@o537411.ingest.sentry.io/5655569',
    release: process.env.VUE_APP_COMMIT_HASH,
    integrations: [new Integrations.BrowserTracing()],

    // Passing in logErrors is optional and is false if it is not provided. If you
    // set it to true, Sentry will call Vue's original logError function as well.
    logErrors: true,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    ignoreErrors: [
      // Avoiding redundant navigation is built into Vue Router and expected behavior.
      // This section prevents sending the error to Sentry, but will allow it to show in the console
      // in the rare cases where a developer might have forgotten to update to a new route when pushing
      'NavigationDuplicated',
      // Per https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded and a comment
      // from the specification author, this is benign and can be ignored.
      'ResizeObserver loop limit exceeded',
      // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
      'ResizeObserver loop completed with undelivered notifications.'
    ]
  })
}

if (process.env.VUE_APP_DATADOG_RUM_ENABLED == 'true') {
  datadogRum.init({
    applicationId: 'd4f84063-89cb-47e1-8299-d3d620d58faa',
    clientToken: 'puba142c1effaaaa9e08b4604c201913d58',
    site: 'datadoghq.com',
    service: 'cloud',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
  datadogRum.startSessionReplayRecording()
}

export const bus = new Vue()

new Vue({
  render: h => h(App),
  store,
  router,
  vuetify,
  i18n
}).$mount('#app')

const certifications = {
  tableHeader: {
    desktop: [
      {
        label: 'check',
        key: 'certificationId',
        isModify: true
      },
      {
        label: 'Code',
        key: 'certificationNumber',
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true
      },
      {
        label: 'Renew',
        key: 'renewUnitOfTime',
        sortable: true,
        isModify: false
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'certificationId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Code',
            key: 'certificationNumber',
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'Renew',
            key: 'renewUnitOfTime',
            sortable: true,
            isModify: false
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default certifications

import { getFormatedDateString } from '../helpers/utils'

export default {
  state: () => ({
    serviceRequestsSearchParam: {
      orgId: localStorage.getItem('OrgId'),
      statusId: null,
      stateId: 1,
      requestedById: null,
      dateCreatedStart: `${getFormatedDateString(new Date(), 0)}T00:00:00`,
      dateCreatedEnd: null,
      serviceRequested: '',
      isStandalone: true
    }
  }),
  mutations: {
    setServiceRequestsSearchParam (state, value) {
      state.serviceRequestsSearchParam = value
    }
  },
  getters: {
    serviceRequestsSearchParam (state) {
      return state.serviceRequestsSearchParam
    }
  }
}

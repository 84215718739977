const numberHistory = {
  tableHeader: {
    desktop: [
      {
        label: 'Number',
        key: 'number',
        sortable: false
      },
      {
        label: 'Type',
        key: 'type',
        sortable: false
      },
      {
        label: 'Source',
        key: 'source',
        sortable: false
      },
      {
        label: 'User',
        key: 'user',
        sortable: false
      },
      {
        label: 'Given On',
        key: 'createdOn',
        sortable: false
      },
      {
        label: 'Saved On',
        key: 'savedOn',
        sortable: false
      }
    ],
    mobile: [
      {
        label: 'Number',
        key: '',
        sortable: false
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Type',
            key: 'type',
            sortable: false
          },
          {
            label: 'Source',
            key: 'source',
            sortable: false
          },
          {
            label: 'User',
            key: 'user',
            sortable: false
          },
          {
            label: 'Given On',
            key: 'createdOn',
            sortable: false
          },
          {
            label: 'Saved On',
            key: 'savedOn',
            sortable: false
          }
        ]
      }
    ]
  }
}

export default numberHistory

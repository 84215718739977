const message = {
  success: {
    setting: {
      imageUpload: 'Se ha subido su nuevo logo.',
      settingSaved: 'Configuaraciones guardados'
    },
    catalog: {
      imageUpload: 'Se ha subido la imagen del modelo.'
    },
    shipSaving: 'Se ha guardado la ubicación del stock.',
    cart: {
      add: 'Artículo agregado al carrito.',
      remove: 'Artículo eliminado del carrito',
      update: 'Artículo actualizado en el carrito'
    },
    ticket: {
      add: 'Artículo agregado al billete.',
      remove: 'Artículo eliminado del billete.',
      update: 'Artículo actualizado en el billete.'
    },
    blesensors: {
      save: 'Puerta de enlace actualizada con éxito'
    },
    checkout: 'Compra exitosa',
    save: 'Los cambios se guardaron exitosamente',
    edit: 'Editado con éxito',
    delete: 'Eliminado con éxito',
    create: 'Creado con éxito',
    copy: 'Copiado con éxito',
    add: 'Añadido exitosamente',
    merge: 'Se migrado con éxito',
    remove: 'Removido con exito',
    process: 'Procesado exitosamente',
    adjustmentsPost: 'Los ajustes se han publicado exitosamente!',
    restore: 'Restaurado con éxito',
    defaultSave: 'Guardado con éxito',
    reinstate: 'Reinstalado exitosamente',
    attachmentReplacement: 'Adjunto reemplazado con éxito.',
    attachmentAdded: 'Adjunto agregado con éxito.',
    download: '{filename} descargado correctamente',
    move: 'Movido correctamente',
    workorderRowsLimit: 'Ha alcanzado un límite máximo de {formattedCount} elementos. Por favor refine sus criterios de búsqueda para ver más artículos.'
  },
  error: {
    setting: {
      imageUpload: 'Error al cargar el logotipo.  reintentar.',
      settingSaved: 'No se pudo guardar la configuración. Inténtalo de nuevo.',
      invalidFormat: 'Formato de archivo invalido',
      imageSize: 'El tamaño del archivo no debe ser superior a 50kb.'
    },
    shipSaving: 'La ubicación del stock falló. Inténtalo de nuevo.',
    cart: {
      add: 'No se pudo agregar el elemento. reintentar.',
      remove: 'No se pudo remover el elemento. reintentar.',
      update: 'Error al actualizar el elemento. reintentar.'
    },
    blesensors: {
      save: 'Error al guardar el sensor del puerto BLE. Inténtalo de nuevo',
      maxEditRows: 'Sólo se puede editar una puerta a la vez',
      macAddressRequired: 'Se requiere la dirección MAC'
    },
    customReport: {
      delete: 'El informe personalizado no se puede eliminar. Es una dependencia para otro recurso.'
    },
    catalog: {
      imageUpload: 'Error al cargar la imagen del modelo. Vuelva a intentarlo.',
      corruptFileMessage: 'El archivo está dañado e ilegible.'
    },
    save: 'No se pudieron guardar los cambios',
    copy: 'Error al copiar',
    remove: 'Error al eliminar',
    labelDuplication: 'nombre de etiqueta duplicado',
    emailDuplication: '*Este formato de correo electrónico no es válido o ya se utiliza.',
    validate: {
      passwordLength: 'La contraseña debe tener al menos 8 caracteres',
      confirmPassword: '¡Las contraseñas deben coincidir!'
    },
    sameAssignRequiredTitle: 'Se requiere la misma asignación',
    sameAssignRequiredMsg: 'Todos los artículos en un boleto de recuento deben asignarse a la misma ubicación o persona.',
    roolLocationNotDeleteMsg: 'No puede eliminar la ubicación del almacen principal',
    itemsAssignedToLocationMsg: 'Esta ubicación no se puede eliminar, hay {0} elementos asignados. Por favor actualice los elementos asignados o retire.',
    ownerAssignedToLocationMsg: 'Esta ubicación no se puede eliminar, hay {0} elementos que pertenecen a esta ubicación. Actualice el propietario de los artículos.',
    activeItemAssignedToLocationMsg: 'Esta ubicación no se puede desactivar ni eliminar porque hay {0} elementos asignados.',
    activeItemAssignedToLocationSecondMsg: 'Actualice la asignación de los artículos a otra ubicación.',
    activeOwnerAssignedToLocationMsg: 'Esta ubicación no puede desactivarse ni eliminarse porque hay elementos que pertenecen a esta ubicación.',
    activeOwnerAssignedToLocationSecondMsg: 'Actualice la propiedad de los elementos a otra ubicación.',
    activeOwnerAndItemAssignedToLocationMsg: 'Esta ubicación no se puede desactivar ni eliminar porque hay {0} elementos asignados y {1} elementos que pertenecen a esta ubicación.',
    activeOwnerAndItemAssignedToLocationSecondMsg: 'Actualice la asignación y/o propiedad de los artículos a otra ubicación.',
    activeItemAssignedToEmployeeMsg: 'Esta Empleados no se puede desactivar, hay {0} elemento(s) asignados.',
    alreadyOpenCountTicketMsg: 'Un ticket anterior aún está abierto. Guarde o cierre el ticket de conteo existente.',
    alreadyOpenCountTicketTitle: 'Boleto de conteo ya abierto',
    activeEmployeeAssignedToLocationMsg: 'Esta ubicación no se puede desactivar ni eliminar porque hay {0} empleados asignados a la ubicación que deben eliminarse o desactivarse primero.',
    duplicateUsageOfVin: 'El VIN que ingresó ya está asignado al Número de artículo: {0} y no puede duplicarse.'
  },
  warning: {
    delete: '¿Está seguro de que desea eliminar el siguiente elemento?',
    deleteTool: '¿Estás seguro de que quieres eliminar esta herramienta?',
    confirm: '¿Desea guardar los cambios en este registro?',
    confirmDelete: '¿Estás seguro?',
    scanItemWarning: 'Debes seleccionar una línea o cancelar.',
    selectToProcess: 'Debes seleccionar al menos una línea para procesar.',
    confirmProcess: '¿Está seguro de que le gustaría procesar los recuentos seleccionados?',
    deletePOItem: '¿Estás seguro de que deseas eliminar el seleccionado PO(s)',
    deleteItem: '¿Está seguro de que desea eliminar los elementos seleccionados?',
    workOrderNotServicePlan: 'Esta orden de trabajo no se creó como parte de un plan de servicio.',
    editOwner: 'No se puede cambiar el propietario de las herramientas de cantidad. Si se seleccionan varias filas, \nelimine las herramientas de cantidad de su selección.',
    processDraft: 'No se puede crear una nueva transferencia, ya existe un borrador de transferencia. Procesar o cancelar la transferencia existente antes de registrar el ajuste de inventario.',
    mismatchedCountAssigments: 'No se pueden publicar ajustes para elementos no únicos que no se cuentan en su ubicación de asignación',
    autoAssigntitle: 'Continuar?',
    autoAssignwarningMsg: 'Todos los activos rastreados de forma inalámbrica dentro del alcance de la puerta de enlace se reasignarán a la nueva ubicación. ¿Desea continuar?',
    workflowInProgressMsg: 'Los flujos de trabajo que tienen trabajo en progreso no se pueden editar ni eliminar. Complete todas las tareas asociadas y vuelva a intentarlo.',
    workflowInProgressTitle: 'Flujo de trabajo en progreso',
    workflowTemplateTitle: 'Plantilla de flujo de trabajo',
    workflowTemplateMsg: 'La plantilla de flujo de trabajo no se puede eliminar.',
    deleteReqBrowser: '¿Está seguro de que desea eliminar la solicitud de compra seleccionada?',
    deleteReqBrowserItem: '¿Estás segura de que quieres eliminar?',
    ReqBrowserNotDeleteWarning: 'No puede eliminar solicitudes que hayan estado en curso o completadas.',
    deleteCertificate: 'Este certificado está asociado al/los plan(es) de seguridad, ¿está seguro de que desea eliminarlo?'
  },
  confirm: {
    wrongAssignTitle: 'Asignación incorrecta',
    wrongAssignMsg: 'Este artículo no está asignado a la ubicación que se está contando.\n ¿Deseas agregarlo de todos modos??'
  },
  noData: 'Sin datos',
  systemDashboardNoDataSelect: 'Seleccionar',
  systemDashboardToLoadData: ' para cargar información',
  noContent: 'Sin contenido',
  noItems: 'No hay artículos',
  noResults: 'No hay resultados',
  noResultSearchSuggestion: 'Busque o seleccione una categoría para mostrar los resultados.',
  sortBy: 'Ordenar por',
  cannotRefresh: 'Espere 1 minuto antes de actualizar el tablero',
  results: 'Sin resultados | 1 resultado | {count} resultados',
  itemSelection: 'Sin artículo seleccionado | 1 artículo seleccionado | {count} elementos seleccionados',
  intervention: {
    0: 'para',
    1: 'en categoria',
    2: 'para la marca',
    3: 'en lugar de'
  },
  attachmentMsg: 'No hay ningún archivo adjunto para esta herramienta',
  searchTools: 'Herramientas de búsqueda',
  comingSoon: 'Próximamente',
  mergeHint: 'Seleccione el modelo que se combinará:',
  billing: {
    0: 'Misc. Los cargos / créditos no se pueden poner en espera.',
    1: 'Elija Misc. Cargo o artículo de crédito!',
    2: 'Solo se pueden poner en espera las herramientas únicas'
  },
  deleteTransfer: 'Quieres seguir eliminando esta transferencia?',
  deleteWorkOrder: '¿Está seguro de que desea eliminar la orden de trabajo seleccionada?',
  importMeter: {
    label: 'Seleccione el archivo CSV para importar y validar',
    warning: 'La fila de encabezado requiere las columnas ItemNumber, ImportValue y Date',
    importSuccess: 'La importación del medidor se realizó correctamente'
  },
  importFuelEmployees: {
    label: 'Seleccionar archivo CSV para alimentar a las empleadas y validar',
    warning: 'La fila de encabezado requiere las columnas ItemNumber, ImportValue y Date',
    importSuccess: 'La importación de la transacción de combustible fue exitosa'
  },
  chooseTemplateType: 'Elija el tipo de plantilla que desea crear',
  pickTicket: {
    delete: {
      title: 'Confirmar eliminación',
      text: '¿Estás segura de que quieres eliminar esta línea?',
      altTitle: 'Error',
      altText: 'Solo puede eliminar estas líneas mientras un boleto está en borrador',
      altText_1: 'Solo puede eliminar estas líneas mientras hay un ticket en curso'
    },
    view: {
      title: 'Seleccione una línea de \'Artículos seleccionados\'',
      text: 'Haga clic en la pestaña \'Elementos seleccionados\', luego seleccione una línea para mostrar'
    },
    warning: {
      0: 'Primero debe seleccionar una línea para cumplir.',
      1: 'Las plantillas solo se pueden aplicar mientras se redacta el ticket',
      2: 'Solo puede agregar líneas mientras se redacta el boleto',
      3: 'Primero debe seleccionar un valor "Elegir de" de arriba',
      4: 'El artículo duplicado {itemid} ya fue seleccionado',
      5: 'No se puede ajustar el stock para el producto. Agregue el artículo antes de ajustar el stock',
      6: 'El artículo ya existe en el ticket de recogida. Ajuste la cantidad del artículo existente en el ticket de recogida.'
    }
  },
  retired: 'RETIRADO',
  numberDuplication: 'El número {number} ya está en uso para:',
  saveChanges: '¿Quieres guardar tus cambios?',
  serviceEventDeletion: '¿Está seguro? Esto no se puede deshacer.',
  attachment: {
    title: 'Eliminar archivo adjunto',
    text: '¿Está seguro de que desea eliminar este archivo adjunto?'
  },
  addToTransfer: '{number} elementos agregados a la transferencia actual: haga clic para ver',
  addToTransferDuplicateItemMessage: 'Advertencia de duplicado: {number} elementos son duplicados y ya se agregaron a la transferencia actual. Haga clic para ver',
  addToTransferMessage: '1 artículo agregado a la transferencia actual',
  addToTransferDuplicateMessage: 'Advertencia de duplicado: {número} elementos están en la transferencia actual',
  addToolToTransferMessage: '{number} elementos agregados a la transferencia actual',
  addToTransferDuplicateItem: 'Advertencia de duplicado: este artículo está en la transferencia actual',
  noMoreData: 'No mas datos',
  noResult: 'No hay resultados',
  loading: 'Cargando',
  poweredToolWatch: 'Generado por ToolWatch',
  itemCountedOnCountTicket: 'Artículos contados en boleto(s) de conteo',
  transferConfirm: 'Algunos elementos se asignan a una ubicación diferente. Quieres añadirlos a una transferencia?',
  exportTemplateDeletion: 'Estás segura de que quieres eliminar la plantilla?',
  restrictRetireQuantitytools: 'No se pueden retirar artículos de cantidad, eliminar el estado de retiro y realizar ajustes de inventario',
  jobstock: {
    title: 'Eliminar modelo del stock de trabajos',
    deletemessage: 'Esto eliminará el modelo del seguimiento del inventario de trabajos y se perderán las cantidades baja, objetivo y alta. ¿Está seguro de que desea eliminar el modelo de la tabla?'
  }
}
export default message

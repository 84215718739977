import axios from 'axios'
import store from '../store/index'

export function setProcoreShipTo (cart) {
  if (store.getters.settings != null && store.getters.settings.IsProjectSynced) {
    if (cart != null && (cart.Items == null || cart.Items.length == 0)) {
      cart.ShipToEntityId = store.getters.settings.LocationId
      cart.ShipToName = store.getters.settings.LocationDescription
      cart.CostCenterId = store.getters.settings.CostCenterId
      cart.CostCenterName = store.getters.settings.CostCenterDescription
      cart.CostCodeId = store.getters.settings.CostCodeId
      cart.CostCodeName = store.getters.settings.CostCodeDescription
    }
  }
}

export function setProcoreIntegration (urlString) {
  const url = new URL(urlString)
  const companyId = url.searchParams.get('procoreCompanyId')
  const projectId = url.searchParams.get('procoreProjectId')

  if (companyId && projectId) {
    store.commit('setCompanyId', companyId)
    store.commit('setProjectId', projectId)
    // store.dispatch('setProcoreCompanyProject', { companyId: companyId, projectId: projectId })
  }
}

export async function checkProcoreIntegration () {
  if (store.getters.isProcoreIntegrationVerified) {
    return true
  }

  const companyId = store.getters.companyId
  const projectId = store.getters.projectId
  let result = true

  // do not check integration
  const disableCheck = true
  if (disableCheck == false && (companyId > 0 && projectId > 0)) {
    const params = new URLSearchParams()
    params.append('procoreCompanyId', companyId)
    params.append('procoreProjectId', projectId)

    const headerConfig = {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded'
      }
    }
    const { data, status } = await axios.post('/api/cloud/procore/checkIntegration', params, headerConfig)
    if (status == 200) {
      store.commit('setSettings', data)

      // check if need to display warning
      if (!store.getters.settings.IsProjectSynced) {
        result = false
      }
    }
  }
  store.commit('setVerified', true)
  return result
}

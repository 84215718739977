const dataMergers = {
  tableHeader: {
    desktop: [
      {
        label: 'No',
        key: 'listType'
      },
      {
        label: 'Nombre',
        key: 'name',
        sortable: true
      },
      {
        label: '',
        key: ''
      }
    ],
    mobile: [
      {
        label: 'control',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'No',
            key: 'listType'
          },
          {
            label: 'Nombre',
            key: 'name',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: ''
      }
    ]
  }
}

export default dataMergers

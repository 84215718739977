const calcMethods = [
  {
    Key: 'Assignment',
    Value: 1
  },
  {
    Key: 'Calendar (Days)',
    Value: 4
  },
  {
    Key: 'Calendar (Hours)',
    Value: 3
  },
  {
    Key: 'Calendar (Minutes)',
    Value: 0
  },
  {
    Key: 'Hourly Usage',
    Value: 2
  }
]

export default calcMethods

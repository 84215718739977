const users = {
  title: 'Usuarios',
  linkedAccounts: {
    title: 'Migrar Cuentas',
    adminUserLabel: 'Administrar',
    inspectionsUserLabel: 'Inspecciones',
    jsaUserLabel: 'JSA',
    obsUserLabel: 'Observaciones',
    incidentUserLabel: 'Incidentes',
    scanUserLabel: 'Scaneos de Seguridad',
    formsUserLabel: 'Formularios de Seguridad',
    trainingUserLabel: 'Entrenamiento',
    linkButton: 'Salvar',
    relinkButton: 'Reenlazar',
    cancelButton: 'Cancelar',
    unlinkDialogTitle: 'Enlazar Usurios Existentes',
    unlinkDialogMessage: 'Esta seguro que desea desenlazar este usuario? Este usuarion generado automaticamente va ha ser eliminado de EHS.',
    createUsersButton: 'Crear Usuarios para todas las  Aplicaciones',
    usersNotCreatedTitle: 'Usuarios no Creado',
    usersNotCreatedMessage: 'Org debe ser rastreada a una cueta EHS, y el usuario EHS users debe ser rastreado a un usuario TW.',
    noLinkedAccountTitle: 'No hay cuenta enlazada',
    noLinkedAccountMessage: 'Debe tener una cuenta EHS enlazada a su Org para modificar cuentas de usuarios EHS.'
  },
  ehsAccess: {
    ehsLicense: 'Licencia de usuario EHS',
    observationLicense: 'Licencia de Observaciones',
    adminLicense: 'Licencia Administrativa',
    fullLicense: 'Licencia Full',
    title: 'Acceso de usuario EHS'
  }
}

const usersTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Nombre'
      },
      {
        label: 'Apellido'
      },
      {
        label: 'Cuentas Enlazadas'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Nombre'
          },
          {
            label: 'Apellido'
          },
          {
            label: 'Cuentas Enlazadas'
          }
        ]
      }
    ]
  }
}

export { users, usersTable }

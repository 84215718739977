<template>
  <router-view v-if="isProcessing"></router-view>
</template>

<script>
import { errorHandler } from '../../helpers/utils'
export default {
  name: 'EquipmentManagementRouterView',
  data () {
    return {
      isProcessing: false
    }
  },
  async created () {
    try {
      this.isProcessing = false
      if (!this.serviceDescriptions.length) {
        await this.$store.dispatch('getDescriptions', this.$toast)
      }
    } catch (error) {
      if (error.response) {
        errorHandler(this.$toast, error.response.statusText, error)
      }
    } finally {
      this.isProcessing = true
    }
  },
  computed: {
    serviceDescriptions () {
      return this.$store.getters.descriptions
    }
  }
}
</script>

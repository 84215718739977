const fuelEmployeesTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Número de empleado'
      },
      {
        label: 'Apellido del conductor'
      },
      {
        label: 'Nombre del conductor'
      },
      {
        label: 'Segundo nombre del conductor'
      }
    ],
    mobile: [
      {
        label: 'Número de artículo'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Número de empleado'
          },
          {
            label: 'Apellido del conductor'
          },
          {
            label: 'Nombre del conductor'
          },
          {
            label: 'Segundo nombre del conductor'
          }
        ]
      }
    ]
  }
}
export default fuelEmployeesTable

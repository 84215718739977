<template>
  <Fragment>
    <div :class="loading ? 'loading' : 'noLoading'">
      <LoadingBar />
    </div>
    <div :style="cssVars" id="wrapper">
      <TopBar />
      <req-edit-ship-to />
      <SideBar v-if="!isInIframe && !platformNavigation" />
      <PlatformNavigationSideBar v-if="!isInIframe && platformNavigation" />
      <router-view></router-view>
    </div>
    <Intercom />
  </Fragment>
</template>

<script>
import cookie from 'cookie'
import SideBar from '../shared/SideBar'
import PlatformNavigationSideBar from '../shared/PlatformNavigationSideBar'
import TopBar from '../shared/TopBar'
import LoadingBar from '../shared/LoadingBar'
import { Fragment } from 'vue-fragment'
import ReqEditShipTo from '../requisitions/ReqEditShipTo.vue'
import Intercom from '../../help/Intercom.vue'

export default {
  name: 'App',
  components: {
    TopBar,
    SideBar,
    PlatformNavigationSideBar,
    Fragment,
    LoadingBar,
    ReqEditShipTo,
    Intercom
  },
  computed: {
    loading () {
      return this.$store.getters.loading || this.$store.getters.loadingCount > 0
    },
    isInIframe () {
      try {
        return window.self !== window.top
      } catch {
        return true
      }
    },
    cssVars () {
      return {
        '--full-sidebar-padding-left': this.isInIframe ? '16px' : '280px',
        '--mini-sidebar-padding-left': this.isInIframe ? '16px' : '80px'
      }
    },
    platformNavigation () {
      return this.$store.getters.showPlatformNavigation
    }
  },
  mounted: function () {
    const cookies = cookie.parse(document.cookie || '')
    if (process.env.VUE_APP_GTAGS_ENABLED === 'true' && this.$store.getters.isLoggedIn) {
      this.$gtag.query('set', 'user_properties', {
        OrgID: this.$store.getters.orgSettings.OrgId,
        IsTraining: this.$store.getters.orgSettings.IsTraining,
        IsCustomer: this.$store.getters.orgSettings.IsCustomer,
        CustomerName: this.$store.getters.orgSettings.CustomerName,
        IsSandboxUser: this.$store.getters.isSandboxUser,
        IsImpersonation: this.$store.getters.isSuperUser,
        Cluster: cookies['tw.cloud.cluster']
      })
      this.$gtag.query('set', { user_id: this.$store.getters.userName })
      this.$gtag.optIn()
    } else {
      this.$gtag.optOut()
    }
  }
}
</script>

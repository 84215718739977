const billing = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'IsHold',
        key: 'Hold',
        type: Boolean,
        sortable: 'true'
      },
      {
        label: 'Cost Center',
        key: 'CostCenter',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Cost Code',
        key: 'CostCode',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Transaction Start Date',
        key: 'StartTime',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Transaction End Date',
        key: 'EndTime',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Item Category',
        key: 'Category',
        type: String,
        sortable: 'true'
      },
      {
        label: 'T',
        key: 'ItemType',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Item No.',
        key: 'ItemNumber',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Item Model',
        key: 'Model',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Item Description',
        key: 'Description',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Charge Type',
        key: 'BillingEntryType',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Calculation Method',
        key: 'BillingCalcMethod',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Amount Each',
        key: 'ChargeEach',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Qty',
        key: 'Quantity',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Extended Total',
        key: 'ExtendedTotal',
        type: Number,
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'IsHold',
        key: 'Hold',
        type: Boolean,
        sortable: 'true'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Cost Center',
            key: 'CostCenter',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Cost Code',
            key: 'CostCode',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Transaction Start Date',
            key: 'StartTime',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Transaction End Date',
            key: 'EndTime',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Item Category',
            key: 'Category',
            type: String,
            sortable: 'true'
          },
          {
            label: 'T',
            key: 'ItemType',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Item No.',
            key: 'ItemNumber',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Item Model',
            key: 'Model',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Item Description',
            key: 'Description',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Charge Type',
            key: 'BillingEntryType',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Calculation Method',
            key: 'BillingCalcMethod',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Amount Each',
            key: 'ChargeEach',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Qty',
            key: 'Quantity',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Extended Total',
            key: 'ExtendedTotal',
            type: Number,
            sortable: 'true'
          }
        ]
      }
    ]
  }
}

export default billing

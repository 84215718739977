const reservationsByReservation = {
  tableHeader: {
    desktop: [
      {
        label: 'Estado'
      },
      {
        label: 'Fecha de envío'
      },
      {
        label: 'Fecha de inicio'
      },
      {
        label: 'Fecha final'
      },
      {
        label: 'Solicitud'
      },
      {
        label: 'Por'
      },
      {
        label: 'Para'
      },
      {
        label: 'Centro de costos'
      },
      {
        label: 'Código de costo'
      },
      {
        label: 'Artículos'
      }
    ],
    mobile: ['Estado', 'Info']
  }
}

export default reservationsByReservation

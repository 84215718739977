const workflowBrowser = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'CurrentTaskId',
        type: 'check'
      },
      {
        label: 'Workflow Number',
        key: 'WorkflowNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Workflow Name',
        key: 'WorkflowName',
        type: String,
        sortable: true
      },
      {
        label: 'Current Step Name',
        key: 'CurrentStepSubject',
        type: String,
        sortable: true
      },
      {
        label: 'Current Step Number',
        key: 'CurrentStepNumber',
        type: Number,
        sortable: true
      },
      {
        label: 'Total Steps',
        key: 'TotalSteps',
        type: Number,
        sortable: true
      },
      {
        label: 'Assigned To',
        key: 'CurrentOwner',
        type: String,
        sortable: true
      },
      {
        label: 'Due Date',
        key: 'DueDate',
        sortable: true
      },
      {
        label: 'Status',
        key: 'Status',
        type: String,
        sortable: true
      },
      {
        label: 'Triggered (Date/Time)',
        key: 'CreatedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Closed (Date/Time)',
        key: 'CompletedOn',
        type: Date,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'CurrentTaskId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Workflow Number',
            key: 'WorkflowNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Workflow Name',
            key: 'WorkflowName',
            type: String,
            sortable: true
          },
          {
            label: 'Current Step Name',
            key: 'CurrentStepSubject',
            type: String,
            sortable: true
          },
          {
            label: 'Current Step Number',
            key: 'CurrentStepNumber',
            type: Number,
            sortable: true
          },
          {
            label: 'Total Steps',
            key: 'TotalSteps',
            type: Number,
            sortable: true
          },
          {
            label: 'Assigned To',
            key: 'CurrentOwner',
            type: String,
            sortable: true
          },
          {
            label: 'Due Date',
            key: 'DueDate',
            type: Date,
            sortable: true
          },
          {
            label: 'Status',
            key: 'IsCompleted',
            type: String,
            sortable: true
          },
          {
            label: 'Triggered (Date/Time)',
            key: 'CreatedOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Closed (Date/Time)',
            key: 'CompletedOn',
            type: Date,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default workflowBrowser

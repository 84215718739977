const kits = {
  title: 'Detalle del Equipo',
  actionHeaders: {
    0: {
      title: 'Eliminar línea',
      icon: 'fas fa-trash'
    },
    1: {
      title: 'Agregar registro de herramienta',
      icon: 'fas fa-plus'
    },
    2: {
      title: 'Agregar registro de material',
      icon: 'fas fa-plus'
    },
    3: {
      title: 'Imprimir',
      icon: 'fas fa-print'
    }
  },
  labels: {
    kitNum: 'Equipo No.',
    assignedTo: 'Asignado a',
    description: 'Descripción',
    category: 'Categoría',
    barCode: 'código de barras',
    note: 'Nota',
    detailSummary: 'Resumen de detalles del kit',
    kitSaveError: 'Error al guardar el kit',
    kitSaveErrorMsg: 'Se debe agregar al menos un artículo antes de poder guardar el kit.'
  },
  kitItemTbl: {
    tableHeader: {
      desktop: [
        {
          label: 'T',
          key: 'itemType',
          sortable: true
        },
        {
          label: 'Categoría',
          key: 'category',
          sortable: true
        },
        {
          label: 'Número de artículo',
          key: 'itemNumber',
          sortable: true
        },
        {
          label: 'Descripción',
          key: 'description',
          sortable: true
        },
        {
          label: 'Cantidad',
          key: 'quantity',
          type: Number,
          isEditable: true,
          sortable: true
        },
        {
          label: 'Modelo',
          key: 'model',
          sortable: true
        },
        {
          label: 'Fabricante',
          key: 'manufacturer',
          sortable: true
        },
        {
          label: 'Número de serie',
          key: 'serialNumber',
          sortable: true
        },
        {
          label: 'Estado',
          key: 'itemStatus',
          sortable: true
        },
        {
          label: 'Asignado a',
          key: 'assignedTo',
          sortable: true
        },
        {
          label: 'UP',
          key: 'personalIssue',
          type: Boolean,
          isEditable: false,
          sortable: true
        },
        {
          label: 'Dueño',
          key: 'owner',
          sortable: true
        },
        {
          label: '',
          type: 'Action'
        }
      ],
      mobile: [
        {
          label: 'T',
          key: 'itemType',
          sortable: true
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Categoría',
              key: 'category',
              sortable: true
            },
            {
              label: 'Número de artículo',
              key: 'itemNumber',
              sortable: true
            },
            {
              label: 'Descripción',
              key: 'description',
              sortable: true
            },
            {
              label: 'Cantidad',
              key: 'quantity',
              type: Number,
              isEditable: true,
              sortable: true
            },
            {
              label: 'Modelo',
              key: 'model',
              sortable: true
            },
            {
              label: 'Fabricante',
              key: 'manufacturer',
              sortable: true
            },
            {
              label: 'Número de serie',
              key: 'serialNumber',
              sortable: true
            },
            {
              label: 'Estado',
              key: 'itemStatus',
              sortable: true
            },
            {
              label: 'Asignado a',
              key: 'assignedTo',
              sortable: true
            },
            {
              label: 'UP',
              key: 'personalIssue',
              type: Boolean,
              isEditable: false,
              sortable: true
            },
            {
              label: 'Dueño',
              key: 'owner',
              sortable: true
            }
          ]
        },
        {
          label: '',
          type: 'Action'
        }
      ]
    }
  },
  print: {
    noItems: 'No hay artículos'
  }
}

export default kits

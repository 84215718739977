const routes = [
  {
    name: 'System Dashboard',
    route_name: 'Dashboard',
    icon: 'fal fa-chart-line',
    path: '',
    matchPath: ['/'],
    exact: true,
    completed: true,
    released: true,
    wareHouseUser: true,
    backOfficeUser: true,
    jobSiteUser: true
  },
  {
    name: 'Pick Ticket & Transfer Dashboard',
    route_name: 'Assignments Dashboard',
    path: '/tickets/dashboard',
    matchPath: ['/tickets/dashboard'],
    exact: true,
    entitlement: 'PT_And_T',
    completed: true,
    wareHouseUser: true
  },
  {
    name: 'Purchasing Dashboard',
    route_name: 'Purchasing Dashboard',
    path: '/purchasing/dashboard',
    exact: true,
    matchPath: ['/purchasing/dashboard'],
    entitlement: 'P_And_R',
    completed: true,
    wareHouseUser: true
  },
  {
    name: 'Service & Calibration Dashboard',
    route_name: 'Service Dashboard',
    path: '/service/dashboard',
    exact: true,
    matchPath: ['/service/dashboard'],
    entitlement: 'S_And_C',
    completed: true,
    wareHouseUser: true
  },
  {
    name: 'Manage Dashboards',
    path: '/reports/dashboards',
    matchPath: ['/reports/dashboards'],
    entitlement: 'CustomDashboards',
    completed: true,
    released: true,
    wareHouseUser: true,
    backOfficeUser: true
  }
]

export default routes

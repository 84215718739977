const workAssignment = {
  tableHeader: {
    desktop: [
      {
        label: 'Assignment From',
        type: 'Guid',
        key: 'twEntityIdFrom',
        listType: 10,
        sortable: true
      },
      {
        label: 'Assignment To',
        key: 'twEntityIdTo',
        type: 'Guid',
        listType: 10,
        sortable: true
      },
      {
        label: 'By',
        key: 'assignedBy',
        sortable: true
      },
      {
        label: 'Transfer',
        key: 'transferNumber',
        sortable: true
      },
      {
        label: 'Date',
        key: 'assignmentDate',
        type: Date,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Assignment From',
            type: 'Guid',
            key: 'twEntityIdFrom',
            listType: 10,
            sortable: true
          },
          {
            label: 'Assignment To',
            key: 'twEntityIdTo',
            type: 'Guid',
            listType: 10,
            sortable: true
          },
          {
            label: 'By',
            key: 'assignedBy',
            sortable: true
          },
          {
            label: 'Transfer',
            key: 'transferNumber',
            sortable: true
          },
          {
            label: 'Date',
            key: 'assignmentDate',
            type: Date,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default workAssignment

export default {
  state: () => {
    const curDate = new Date()
    curDate.setMonth(curDate.getMonth() - 1)
    const yyyy = curDate.getFullYear()
    const mm = curDate.getMonth() + 1
    const dd = curDate.getDate()
    return ({
      equipmentUsageLog: [],
      isBackFromAddItem: false,
      invoiceSearchParam: {
        InvoiceNumberFrom: null,
        InvoiceNumberTo: null,
        InvoiceDateFrom: yyyy + '-' + String(mm).padStart(2, '0') + '-' + String(dd).padStart(2, '0') + 'T00:00:00',
        InvoiceDateTo: null,
        CostCenterId: null,
        CostCodeId: null,
        OrgId: localStorage.getItem('OrgId'),
        InvoiceNumbers: null,
        RateSheetEntryTypeId: null,
        IsPosted: null
      }
    })
  },
  mutations: {
    addEquipmentUsageLog (state, item) {
      state.equipmentUsageLog = [...item, ...state.equipmentUsageLog]
      state.isBackFromAddItem = true
    },
    setEquipmentUsageLog (state, item) {
      state.equipmentUsageLog = item
    },
    deleteEquipmentUsageLogItem (state, index) {
      state.equipmentUsageLog.splice(index, 1)
    },
    setIsBackFromAddItem (state, value) {
      state.isBackFromAddItem = value
    },
    setInvoiceSearchParam (state, value) {
      state.invoiceSearchParam = value
    }
  },
  getters: {
    getEquipmentUsageLog (state) {
      return state.equipmentUsageLog
    },
    getIsBackFromAddItem (state) {
      return state.isBackFromAddItem
    },
    getInvoiceSearchParam (state) {
      return state.invoiceSearchParam
    }
  }
}

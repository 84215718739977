const adjustmentDetailTable = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: '',
        type: ''
      },
      {
        label: 'Artículo',
        key: 'ItemNumber',
        sortable: true,
        type: String
      },
      {
        label: 'Descripción del Artículo',
        key: 'ItemDescription',
        sortable: true,
        type: String
      },
      {
        label: 'Fabricante',
        key: 'Manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Modelo',
        key: 'Model',
        sortable: true,
        type: String
      },
      {
        label: 'Contenedor',
        key: 'Bin',
        sortable: true,
        type: Number
      },
      {
        label: 'Etiqueta',
        key: 'TicketNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Contado por',
        key: 'CountedBy',
        sortable: true,
        type: String
      },
      {
        label: 'Ubicación de Conteo',
        key: 'Location',
        sortable: true,
        type: String
      },
      {
        label: 'Asignado A',
        key: 'Assignment',
        sortable: true,
        type: String
      },
      {
        label: 'Conteo',
        key: 'Count',
        sortable: true,
        type: String
      },
      {
        label: 'Nota',
        key: 'Note',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: '',
        key: '',
        type: ''
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Artículo',
            key: 'ItemNumber',
            sortable: true,
            type: String
          },
          {
            label: 'Descripción del Artículo',
            key: 'ItemDescription',
            sortable: true,
            type: String
          },
          {
            label: 'Fabricante',
            key: 'Manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Modelo',
            key: 'Model',
            sortable: true,
            type: String
          },
          {
            label: 'Numero de Contenedor',
            key: 'Bin',
            sortable: true,
            type: Number
          },
          {
            label: 'Etiqueta',
            key: 'TicketNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Contado por',
            key: 'CountedBy',
            sortable: true,
            type: String
          },
          {
            label: 'Ubicación de Conteo',
            key: 'Location',
            sortable: true,
            type: String
          },
          {
            label: 'Asignación Actual',
            key: 'Assignment',
            sortable: true,
            type: String
          },
          {
            label: 'Conteo',
            key: 'Count',
            sortable: true,
            type: String
          },
          {
            label: 'Nota',
            key: 'Note',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  }
}

export default adjustmentDetailTable

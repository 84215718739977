<template>
  <router-view v-if="isProcessing"></router-view>
</template>

<script>
import { errorHandler } from '../../helpers/utils'
export default {
  name: 'KitRouterView',
  data () {
    return {
      isProcessing: false
    }
  },
  async created () {
    const reqArray = []
    try {
      this.isProcessing = false
      this.$store.commit('setLoading', true)
      if (!this.serviceDescriptions.length) {
        this.$store.dispatch('getDescriptions', this.$toast)
      }
      if (!this.toolCategories) {
        reqArray.push(this.getToolCategories())
      }
      await Promise.all(reqArray)
    } catch (error) {
      errorHandler(this.$toast, error.response.statusText, error)
    } finally {
      this.isProcessing = true
    }
  },
  methods: {
    getToolCategories () {
      return this.$store.dispatch('loadDataByListType', {
        query: '',
        listTypeId: 12,
        stockpointId: this.globalStockpointId
      })
    }
  },
  computed: {
    globalStockpointId () {
      return this.$store.getters.getStockpointId
    },
    serviceDescriptions () {
      return this.$store.getters.descriptions
    },
    toolCategories () {
      return this.$store.getters.getShipData(12)
    }
  }
}
</script>

const workflowBrowser = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'CurrentTaskId',
        type: 'check'
      },
      {
        label: 'Número de flujo de trabajo',
        key: 'WorkflowNumber',
        sortable: true
      },
      {
        label: 'Nombre del flujo de trabajo',
        key: 'WorkflowName',
        sortable: true
      },
      {
        label: 'Nombre del paso actual',
        key: 'CurrentStepSubject',
        sortable: true
      },
      {
        label: 'Número de paso actual',
        key: 'CurrentStepNumber',
        sortable: true
      },
      {
        label: 'Pasos totales',
        key: 'TotalSteps',
        sortable: true
      },
      {
        label: 'Asignado a',
        key: 'CurrentOwner',
        sortable: true
      },
      {
        label: 'Fecha de vencimiento',
        key: 'DueDate',
        type: Date,
        sortable: true
      },
      {
        label: 'Estado',
        key: 'Status',
        sortable: true
      },
      {
        label: 'Activado (Fecha/Hora)',
        key: 'CreatedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Cerrado (Fecha/Hora)',
        key: 'CompletedOn',
        type: Date,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'CurrentTaskId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Número de flujo de trabajo',
            key: 'WorkflowNumber',
            sortable: true
          },
          {
            label: 'Nombre del flujo de trabajo',
            key: 'WorkflowName',
            sortable: true
          },
          {
            label: 'Nombre del paso actual',
            key: 'CurrentStepSubject',
            sortable: true
          },
          {
            label: 'Número de paso actual',
            key: 'CurrentStepNumber',
            sortable: true
          },
          {
            label: 'Pasos totales',
            key: 'TotalSteps',
            sortable: true
          },
          {
            label: 'Asignado a',
            key: 'CurrentOwner',
            sortable: true
          },
          {
            label: 'Fecha de vencimiento',
            key: 'DueDate',
            type: Date,
            sortable: true
          },
          {
            label: 'Estado',
            key: 'IsCompleted',
            sortable: true
          },
          {
            label: 'Activado (Fecha/Hora)',
            key: 'CreatedOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Cerrado (Fecha/Hora)',
            key: 'CompletedOn',
            type: Date,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default workflowBrowser

import CONST from '../../helpers/const'

const frequency = {
  labels: {
    1: 'Frequency',
    2: 'Day(s)',
    3: 'Month(s)',
    4: 'Select All',
    5: 'Unselect All',
    6: 'Start & End Date'
  },
  validations: {
    startDate: 'Start Date',
    endDate: 'End Date'
  },
  daysOfWeek: [
    {
      name: 'Sun',
      value: CONST.SUNDAY
    },
    {
      name: 'Mon',
      value: CONST.MONDAY
    },
    {
      name: 'Tue',
      value: CONST.TUESDAY
    },
    {
      name: 'Wed',
      value: CONST.WEDNESDAY
    },
    {
      name: 'Thu',
      value: CONST.THURSDAY
    },
    {
      name: 'Fri',
      value: CONST.FRIDAY
    },
    {
      name: 'Sat',
      value: CONST.SATURDAY
    }
  ],
  monthOfYear: [
    {
      name: 'Jan',
      value: CONST.JANUARY
    },
    {
      name: 'Feb',
      value: CONST.FEBRUARY
    },
    {
      name: 'Mar',
      value: CONST.MARCH
    },
    {
      name: 'Apr',
      value: CONST.APRIL
    },
    {
      name: 'May',
      value: CONST.MAY
    },
    {
      name: 'Jun',
      value: CONST.JUNE
    },
    {
      name: 'Jul',
      value: CONST.JULY
    },
    {
      name: 'Aug',
      value: CONST.AUGUST
    },
    {
      name: 'Sep',
      value: CONST.SEPTEMBER
    },
    {
      name: 'Oct',
      value: CONST.OCTOBER
    },
    {
      name: 'Nov',
      value: CONST.NOVEMBER
    },
    {
      name: 'Dec',
      value: CONST.DECEMBER
    }
  ],
  frequencyTypes: [
    {
      FrequencyName: 'N/A',
      FrequencyId: 0
    },
    {
      FrequencyName: 'Daily',
      FrequencyId: 1
    },
    {
      FrequencyName: 'Weekly',
      FrequencyId: 2
    },
    {
      FrequencyName: 'Monthly',
      FrequencyId: 3
    },
    {
      FrequencyName: 'Yearly',
      FrequencyId: 4
    }
  ]
}

export default frequency

const procore = {
  title: 'Procore Warning',
  description: {
    0: 'Company <b>({0})</b> does not exist in Procore',
    1: 'Company <b>({0})</b> is not integrated with ToolWatch',
    2: 'Company <b>{0} ({1})</b> is integrated with ToolWatch, but Synchronization is not enabled',
    3: 'Company <b>{0} ({1})</b> is integrated with ToolWatch, but Procore Company is not Active',
    4: 'Company <b>{0} ({1})</b> is integrated with ToolWatch, but Project Synchronization is not enabled',
    5: 'Company <b>{0} ({1})</b> is integrated with ToolWatch, but Project <b>({2})</b> is not synchronized',
    6: 'Company <b>{0} ({1})</b> is integrated with ToolWatch, but Project <b>{2} ({3})</b> is not Active',
    7: 'Company <b>{0} ({1})</b> is integrated with ToolWatch, but Project <b>{2} ({3})</b> does not exist in Procore',
    8: 'Continue'
  },
  404: {
    title: 'Page Not Found',
    description: 'The page you are looking for doesn\'t exist.',
    back: 'Go to <a href="/"> Home Page'
  }
}

export default procore

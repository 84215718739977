const retHistory = {
  tableHeader: {
    desktop: [
      {
        label: 'Status',
        key: 'Status',
        sortable: 'true',
        type: String
      },
      {
        label: 'Date',
        key: 'CreatedOn',
        sortable: 'true',
        type: Date,
        filtertype: 'DateLocalString'
      },
      {
        label: 'Ticket',
        key: 'ReturnNumber',
        sortable: 'true',
        type: Number
      },
      {
        label: 'Project',
        key: 'Location',
        sortable: 'true',
        type: String
      },
      {
        label: 'Items',
        key: 'LineCount',
        sortable: false
      }
    ],
    mobile: [
      {
        label: 'Status',
        key: 'Status',
        sortable: 'true',
        type: String
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Date',
            key: 'CreatedOn',
            sortable: 'true',
            type: Date
          },
          {
            label: 'Ticket',
            key: 'ReturnNumber',
            sortable: 'true',
            type: Number
          },
          {
            label: 'Project',
            key: 'Location',
            sortable: 'true',
            type: String
          },
          {
            label: 'Items',
            key: 'LineCount',
            sortable: false
          }
        ]
      }
    ]
  }
}

export default retHistory

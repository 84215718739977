const rateSheets = {
  tableHeader: {
    desktop: [
      {
        label: 'Type',
        key: 'type',
        sortable: 'true'
      },
      {
        label: 'Rate Sheet ID',
        key: 'rateSheetNumber',
        sortable: 'true'
      },
      {
        label: 'Description',
        key: 'description',
        sortable: 'true'
      },
      {
        label: 'Created By',
        key: 'createdBy',
        sortable: 'true'
      },
      {
        label: 'Created On',
        key: 'createdOn',
        type: Date,
        sortable: 'true'
      },
      {
        label: 'Modified By',
        key: 'modifiedBy',
        sortable: 'true'
      },
      {
        label: 'Modified On',
        type: Date,
        key: 'modifiedOn',
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: 'Type',
        key: 'type',
        sortable: true
      },
      {
        label: 'Rate Sheet ID',
        key: 'rateSheetNumber',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Description',
            key: 'description',
            sortable: 'true'
          },
          {
            label: 'Created By',
            key: 'createdBy',
            sortable: 'true'
          },
          {
            label: 'Created On',
            key: 'createdOn',
            type: Date,
            sortable: 'true'
          },
          {
            label: 'Modified By',
            key: 'modifiedBy',
            sortable: 'true'
          },
          {
            label: 'Modified On',
            key: 'modifiedOn',
            type: Date,
            sortable: 'true'
          }
        ]
      }
    ]
  }
}

export default rateSheets

import { deepCopy, getFormatedDateString, uniq } from '../helpers/utils'

export default {
  state: () => ({
    createStepItemDetails: {},
    stepList: [],
    workflowList: [],
    attachmentIdList: []
  }),
  mutations: {
    addStep (state, stepItems) {
      const items = [...state.stepList, ...stepItems]
      state.stepList = items
    },
    updateStep (state, stepItems) {
      const itemIndex = state.stepList.findIndex(o => o.StepId === stepItems[0].StepId)
      const data = state.stepList
      data[itemIndex] = stepItems[0]
      state.stepList = data
    },
    resetStepList (state) {
      state.stepList = []
    },
    addAttachment (state, attachmentId) {
      const attachment = [...state.attachmentIdList, ...attachmentId]
      state.attachmentIdList = attachment
    },
    removeAttachment (state, attachmentId) {
      const index = state.attachmentIdList.indexOf(attachmentId)
      if (index >= 0) {
        state.attachmentIdList.splice(index, 1)
      }
    },
    resetAttachmentList (state) {
      state.attachmentIdList = []
    },
    addWorkflow (state, workflowItem) {
      const items = workflowItem
      state.workflowList = items
    }
  },
  getters: {
    getStepList (state) {
      return state.stepList
    },
    getWorkflowList (state) {
      return state.workflowList
    },
    getAttachmentList (state) {
      return state.attachmentIdList
    }
  }
}

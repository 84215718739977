const integrationSettings = {
  tableHeader: {
    desktop: [
      {
        label: 'Nombre',
        key: 'name',
        sortable: true
      },
      {
        label: 'Estado',
        key: 'enabled',
        sortable: true
      },
      {
        label: 'Categoría',
        key: 'category',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'desc'
      }
    ],
    mobile: [
      {
        label: 'control',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Nombre',
            key: 'name'
          },
          {
            label: 'Estado',
            key: 'enabled',
            sortable: true
          },
          {
            label: 'Categoría',
            key: 'category',
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'desc'
          }
        ]
      }
    ]
  }
}

export default integrationSettings

const fuelEmployees = {
  tableHeader: {
    desktop: [
      {
        label: 'Employee',
        key: 'description',
        sortable: true,
        isModify: false
      },
      {
        label: 'Employee Number',
        key: 'number',
        sortable: true,
        isModify: false
      },
      {
        label: 'Status',
        key: 'status',
        sortable: true,
        isModify: false
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'twEntityId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Employee',
            key: 'description',
            sortable: true,
            isModify: false
          },
          {
            label: 'Employee Number',
            key: 'number',
            sortable: true,
            isModify: false
          },
          {
            label: 'Status',
            key: 'status',
            sortable: true,
            isModify: false
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default fuelEmployees

const descriptions = {
  confirmDelete: 'Are you sure you want to delete the following descriptions?',
  tableHeader: {
    desktop: [
      {
        label: 'Description',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Type',
        key: 'TypeName',
        sortable: true
      },
      {
        label: '',
        key: ''
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Description',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Type',
            key: 'TypeName',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: ''
      }
    ]
  }
}

export default descriptions

<template>
  <div class="dropdown" :style="cssVars">
    <a data-toggle="dropdown" class="dropdown-toggle" href="#">
      <span class="block m-t-xs font-bold profile-name">
        <img :src="imageUrl"/>
        {{ $t('menu.' + $i18n.locale) }} <b class="caret"></b>
      </span>
    </a>
    <ul class="dropdown-menu m-t-xs">
      <li v-for="locale in supportedLocales" :key="locale">
        <a class="dropdown-item" @click="switchLocale(locale)">
          <img :src="setImageURL(locale)"/>
          {{ $t('menu.' + locale) }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { Trans } from '@/plugins/Translation'

export default {
  name: 'LocaleSwitcher',
  props: {
    cssPosition: {
      type: String,
      default: function () {
        return 'absolute'
      }
    }
  },
  data () {
    return {
      imageUrl: ''
    }
  },
  created () {
    this.imageUrl = this.setImageURL(this.$i18n.locale)
  },
  computed: {
    supportedLocales () {
      return Trans.supportedLocales
    },
    cssVars () {
      return {
        '--position': this.cssPosition
      }
    }
  },
  methods: {
    switchLocale (locale) {
      this.imageUrl = this.setImageURL(locale)
      localStorage.setItem('Selectedlanguage', locale)
      if (this.$i18n.locale !== locale) {
        return Trans.changeLocale(locale)
      }
    },
    setImageURL (locale) {
      switch (locale) {
        case 'en':
          return 'https://img.icons8.com/color/48/000000/usa-circular.png'
        case 'es':
          return 'https://img.icons8.com/color/48/000000/spain2-circular.png'
        default:
          break
      }
    }
  }
}
</script>
<style scoped>
  div.dropdown {
    position: var(--position);
    right: 15px;
    top: 10px;
    z-index: 10001;
  }
  ul.dropdown-menu {
    top: 25px!important;
    left: -53px!important;
    min-width: 140px!important;
    padding: 5px 0!important;
  }
  .dropdown a span {
    color: #647178!important;
    font-weight: 700;
  }
  .dropdown img {
    width: 22px;
  }

</style>

const fuelEmployeesTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Employee',
        key: 'FullName',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Employee Number',
        key: 'EmployeeNumber',
        sortable: true,
        isEditable: false
      },
      {
        label: 'Status',
        key: 'Status',
        sortable: true,
        isEditable: false
      },
      {
        label: '',
        key: 'ImportStatus',
        sortable: false,
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'Employee Number',
        key: 'EmployeeNumber'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Employee',
            key: 'FullName',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Employee Number',
            key: 'EmployeeNumber',
            sortable: true,
            isEditable: false
          },
          {
            label: 'Status',
            key: 'Status',
            sortable: true,
            isEditable: false
          },
          {
            label: '',
            key: 'ImportStatus',
            sortable: false,
            isModify: false
          }
        ]
      }
    ]
  }
}

export default fuelEmployeesTable


import { getCurrentInstance } from '@vue/composition-api'

export const useTWGridBase = () => {
  const { emit } = getCurrentInstance() as any

  function onSelectItem (val, key) {
    emit('onSelectItem', { val, key })
  }

  function onSelectAll () {
    emit('onSelectItem')
  }

  function onSelectAllWithKey (key) {
    emit('onSelectAllWithKey', { key })
  }

  return { onSelectItem, onSelectAll, onSelectAllWithKey }
}

export const useTWGridRowsBase = () => {
  const { emit } = getCurrentInstance() as any

  function onViewClick (row) {
    emit('onViewClick', row)
    document.onselectstart = () => { return true }
  }

  function onEditClick (row) {
    emit('onEditClick', row)
  }

  function onDeleteClick (row) {
    emit('onDeleteClick', row)
  }

  function onViewScheduleReasonClick (row) {
    emit('onScheduleClick', row)
  }

  function onSelectedRowChange (row, header) {
    emit('onSelectedRowChange', { row, header })
  }

  function onDownloadClick (path) {
    emit('onDownloadClick', path)
  }

  // return the result out of the composable so callers can access it
  return {
    onViewClick,
    onEditClick,
    onDeleteClick,
    onViewScheduleReasonClick,
    onSelectedRowChange,
    onDownloadClick
  }
}

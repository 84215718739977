<template>
  <div class="px-4 pt-1" style="position: relative">
    <input type="hidden" :id="formId" :name="formId" v-model="value" />
    <div>
      <div v-if="isEditable" class="form-group row mb-0 pb-2">
        <div
          v-if="!isDisabled && suggestedPrompt"
          class="row"
          style="width: 100%"
        >
          <div class="col offset-md-3">
            <div class="form-check ml-3">
              <div class="form-group checkbox-form">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="useSuggestedCheckbox"
                  v-model="useSuggested"
                  v-on:click="useSuggestedClick"
                />
                <label class="checkbox-holder" for="useSuggestedCheckbox">
                </label>
                <label class="form-check-label mb-1" for="useSuggestedCheckbox">
                  {{ suggestedPrompt }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <label
          class="col-md-3 col-form-label font-weight-bold align-middle pr-0"
        >
          {{ displayLabel }}
          <span
            v-if="isRequired && !value && !isLoading"
            class="text-danger float-right"
          >
            *
          </span>
        </label>
        <!-- <div v-if="isLoading" class="col-md-9 d-flex justify-content-center">
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->
        <div class="col-md-9 align-middle">
          <div v-if="selectOptions">
            <div v-if="selectOptions.length === 2 && isComboSelector">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  :disabled="lockedValue || isLoading"
                  :name="formId + 'Combo'"
                  :id="'inlineRadio' + selectOptions[0]"
                  :value="selectOptions[0]"
                  v-on:click="selectionChange(selectOptions[0])"
                  v-model="value"
                />
                <label
                  class="form-check-label"
                  :for="'inlineRadio' + selectOptions[0]"
                  >{{ selectOptions[0] }}</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  :disabled="lockedValue || isLoading"
                  :name="formId + 'Combo'"
                  :id="'inlineRadio' + selectOptions[1]"
                  :value="selectOptions[1]"
                  v-on:click="selectionChange(selectOptions[1])"
                  v-model="value"
                />
                <label
                  class="form-check-label"
                  :for="'inlineRadio' + selectOptions[1]"
                  >{{ selectOptions[1] }}</label
                >
              </div>
            </div>
            <div v-else-if="selectOptions.length === 3 && isComboSelector">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  :disabled="lockedValue || isLoading"
                  :name="formId + 'Combo'"
                  :id="'inlineRadio' + selectOptions[0]"
                  :value="selectOptions[0]"
                  v-on:click="selectionChange(selectOptions[0])"
                  v-model="value"
                />
                <label
                  class="form-check-label"
                  :for="'inlineRadio' + selectOptions[0]"
                  >{{ selectOptions[0] }}</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  :disabled="lockedValue || isLoading"
                  :name="formId + 'Combo'"
                  :id="'inlineRadio' + selectOptions[1]"
                  :value="selectOptions[1]"
                  v-on:click="selectionChange(selectOptions[1])"
                  v-model="value"
                />
                <label
                  class="form-check-label"
                  :for="'inlineRadio' + selectOptions[1]"
                  >{{ selectOptions[1] }}</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  :disabled="lockedValue || isLoading"
                  :name="formId + 'Combo'"
                  :id="'inlineRadio' + selectOptions[2]"
                  :value="selectOptions[2]"
                  v-on:click="selectionChange(selectOptions[2])"
                  v-model="value"
                />
                <label
                  class="form-check-label"
                  :for="'inlineRadio' + selectOptions[2]"
                  >{{ selectOptions[2] }}</label
                >
              </div>
            </div>
            <div v-else>
               <select v-if="formId === 'category' || formId === 'manufacturer'"  class="form-control" v-model="value" @change="selectionChange($event.target.value)">
                <option
                  v-for="(selectOption, index) in selectOptions"
                  :key="index"
                  :value="selectOption.key"
               >
                {{ selectOption.value }}
               </option>
              </select>
              <select v-else  class="form-control" v-model="value">
                <option
                  v-for="(selectOption, index) in selectOptions"
                  :key="index"
                  :value="selectOption.key"
               >
                {{ selectOption.value }}
               </option>
              </select>
            </div>
            <!-- <v-select
              v-else
              class="form-control"
              v-model="value"
              :options="selectOptions"
              :clearable="false"
            >
            </v-select> -->
          </div>
          <div v-else>
            <input
              v-if="isNumericInput"
              type="number"
              min="1"
              class="form-control"
              v-model="value"
              :disabled="isDisabled || isLoading"
              :required="isRequired"
            />
            <input
              v-else-if="isCurrencyInput"
              id="CatalogItemPurchaseCost"
              type="text"
              data-type="currency"
              class="form-control"
              v-model="value"
              @change="formatCost"
              :disabled="isDisabled || isLoading"
              :required="isRequired"
            />
            <input
              v-else
              type="text"
              class="form-control"
              v-model="value"
              :disabled="isDisabled || isLoading"
              :maxlength="maxlength"
              :required="isRequired"
              @input="textInputChange"
              name="newItemModel"
            />
          </div>
        </div>
      </div>
      <div v-else class="form-group row mb-0 pb-2">
        <label class="col-md-3 col-form-label font-weight-bold">{{
          displayLabel
        }}</label>
        <div class="col-md-7">
          <label class="col-form-label" :key="value">{{ value }}</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CatalogFormInputLine',
  props: {
    displayLabel: {
      type: String,
      required: true
    },
    initialValue: {
      type: [Number, String],
      required: false
    },
    formId: {
      type: String,
      required: true
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    selectOptions: {
      type: Array,
      required: false,
      default: null
    },
    maxlength: {
      type: Number,
      required: false,
      default: 50
    },
    isNumericInput: {
      type: Boolean,
      required: false,
      default: false
    },
    isCurrencyInput: {
      type: Boolean,
      required: false,
      default: false
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isComboSelector: {
      type: Boolean,
      required: false,
      default: false
    },
    suggestedValue: {
      type: String,
      required: false,
      default: ''
    },
    suggestedPrompt: {
      type: String,
      required: false,
      default: ''
    },
    categoryId: {
      type: String,
      required: false,
      default: ''
    },
    manufacturerId: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      isEditing: false,
      value: '',
      valueBeforeEditing: null,
      lockedValue: null,
      useSuggested: false,
      formatter: new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: 'USD'
      })
    }
  },
  created () {
    this.value = this.initialValue
    if (this.formId === 'category' && this.initialValue === undefined) {
      this.value = this.categoryId
    } else if (this.formId === 'manufacturer' && this.initialValue === undefined) {
      this.value = this.manufacturerId
    }
  },
  methods: {
    update (newValue) {
      this.value = newValue
    },
    selectionChange (selection) {
      this.$emit('selectionChange', selection)
    },
    lockToValue (value) {
      this.lockedValue = value
      this.value = value
    },
    useSuggestedClick () {
      if (!this.useSuggested) {
        this.value = this.suggestedValue
        this.useSuggested = true
      } else {
        this.value = ''
        this.useSuggested = false
      }
    },
    textInputChange () {
      if (this.value !== this.suggestedValue) {
        this.useSuggested = false
      } else {
        this.useSuggested = true
      }
    },
    formatCost () {
      let cost = this.value
      if (typeof this.value === 'string') {
        cost = Number(this.value.replace(/[^0-9.]+/g, ''))
      }
      this.value = this.formatter.format(cost)
    }
  }
}
</script>

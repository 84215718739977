const customDashboards = {
  tableHeader: {
    desktop: [
      {
        label: 'Dashboard Name',
        key: 'name',
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true
      },
      {
        label: 'Folder',
        key: 'folder',
        sortable: true
      },
      {
        label: 'Private',
        key: 'isPrivate',
        sortable: true
      },
      {
        label: 'Created By',
        key: 'createdBy',
        sortable: true
      },
      {
        label: 'Created On',
        key: 'formattedCreatedOn',
        sortable: true
      },
      {
        label: '',
        key: 'action'
      }
    ],
    mobile: [
      {
        label: 'Dashboard Name',
        key: 'name',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'Folder',
            key: 'folder',
            sortable: true
          },
          {
            label: 'Private',
            key: 'isPrivate',
            sortable: true
          },
          {
            label: 'Created By',
            key: 'createdBy',
            sortable: true
          },
          {
            label: 'Created On',
            key: 'formattedCreatedOn',
            sortable: true
          }
        ]
      }
    ]
  },
  defaultDashboard: [
    {
      id: '931d8c15-518e-440c-b25b-f6f6d61135e1',
      name: 'System Dashboard (DEFAULT)',
      dashboardType: 'System Dashboard (DEFAULT)'
    },
    {
      id: 'fb47a7d5-190f-4ea0-8925-d12bb4590c21',
      name: 'Service & Calibration (DEFAULT)',
      dashboardType: 'Service & Calibration (DEFAULT)'
    },
    {
      id: '86ac0211-91a7-4d20-92d2-8919f74cf036',
      name: 'Pick Tickets & Transfers (DEFAULT)',
      dashboardType: 'Pick Tickets & Transfers (DEFAULT)'
    },
    {
      id: 'aa18e8b4-6c58-446d-b385-25c6304918a4',
      name: 'Purchasing (DEFAULT)',
      dashboardType: 'Purchasing (DEFAULT)'
    }
  ],
  deleteWarningMsg: 'Are you sure you want to delete the selected?',
  customDashboardAssignMsg: 'Dashboard is assigned and cannot be deleted.'
}

export default customDashboards

const scanItems = {
  tableHeader: {
    desktop: [
      {
        label: 'Retired',
        key: 'lostFoundId',
        type: 'retired',
        sortable: 'true'
      },
      {
        label: 'Item',
        key: 'itemNumber',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Category',
        key: 'category',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Description',
        key: 'description',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Manufacturer',
        key: 'manufacturer',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Model',
        key: 'model',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Assigned To',
        key: 'assignedTo',
        type: String,
        sortable: 'true'
      },
      {
        label: 'In a Kit',
        key: 'isInKit',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Quantity',
        key: 'quantity',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Status',
        key: 'itemStatus',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Meter',
        key: 'meterLevel',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Personal Issue',
        key: 'personalIssue',
        type: Boolean,
        sortable: 'true'
      },
      {
        label: 'Owner',
        key: 'owner',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Type',
        key: 'itemType',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Bar Code',
        key: 'barcode',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Model No.',
        key: 'masterNumber',
        type: Number,
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: 'Retired',
        key: 'lostFoundId',
        type: 'retired',
        sortable: 'true'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Item',
            key: 'itemNumber',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Category',
            key: 'category',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Description',
            key: 'description',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Manufacturer',
            key: 'manufacturer',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Model',
            key: 'model',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Assigned To',
            key: 'assignedTo',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Quantity',
            key: 'quantity',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Status',
            key: 'statusCodeNumber',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Meter',
            key: 'meterLevel',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Personal Issue',
            key: 'personalIssue',
            type: Boolean,
            sortable: 'true'
          },
          {
            label: 'Owner',
            key: 'owner',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Type',
            key: 'itemType',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Bar Code',
            key: 'barcode',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Model No.',
            key: 'masterNumber',
            type: Number,
            sortable: 'true'
          }
        ]
      }
    ]
  }
}

export default scanItems

const scanModelTableHeader = {
  tableHeader: {
    desktop: [
      {
        label: 'Image',
        key: 'ImageUrl',
        type: 'image'
      },
      {
        label: 'Category',
        key: 'Category',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Type',
        key: 'ItemType',
        type: String,
        sortable: 'true'
      },
      {
        label: 'ID',
        key: 'Number',
        type: Number,
        sortable: 'true'
      },
      {
        label: 'Manufacturer',
        key: 'Manufacturer',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Model Number',
        key: 'Model',
        type: String,
        sortable: 'true'
      },
      {
        label: 'Description',
        key: 'Description',
        type: String,
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: 'Image',
        key: 'ImageUrl',
        type: 'image'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'Category',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Type',
            key: 'ItemType',
            type: String,
            sortable: 'true'
          },
          {
            label: 'ID',
            key: 'Number',
            type: Number,
            sortable: 'true'
          },
          {
            label: 'Manufacturer',
            key: 'Manufacturer',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Model Number',
            key: 'Model',
            type: String,
            sortable: 'true'
          },
          {
            label: 'Description',
            key: 'Description',
            type: String,
            sortable: 'true'
          }
        ]
      }
    ]
  }
}

export default scanModelTableHeader

const numberHistory = {
  tableHeader: {
    desktop: [
      {
        label: 'Número'
      },
      {
        label: 'Tipo'
      },
      {
        label: 'Origen'
      },
      {
        label: 'Usuario'
      },
      {
        label: 'Provisto el'
      },
      {
        label: 'Guardado el'
      }
    ],
    mobile: [
      {
        label: 'Número'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Tipo'
          },
          {
            label: 'Origen'
          },
          {
            label: 'Usuario'
          },
          {
            label: 'Provisto el'
          },
          {
            label: 'Guardado el'
          }
        ]
      }
    ]
  }
}

export default numberHistory

const InvoiceBrowserGenerate = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'CostCenterId',
        type: 'check'
      },
      {
        label: 'Number',
        key: 'CostCenterNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Description',
        key: 'CostCenterDescription',
        type: String,
        sortable: true
      },
      {
        label: 'Customer',
        key: 'CustomerName',
        type: String,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'CostCenterId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Number',
            key: 'CostCenterNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Description',
            key: 'CostCenterDescription',
            type: String,
            sortable: true
          },
          {
            label: 'Customer',
            key: 'CustomerName',
            type: String,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default InvoiceBrowserGenerate

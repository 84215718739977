const serviceSchedule = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'Current WO#',
        key: 'LastWorkOrderNumber',
        sortable: true
      },
      {
        label: 'Created On',
        key: 'LastWorkOrderCreatedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Next Date',
        key: 'OnDate',
        type: Date,
        sortable: true
      },
      {
        label: 'Next Meter',
        key: 'OnMeter',
        sortable: true
      },
      {
        label: 'Next Turn',
        key: 'OnTurn',
        sortable: true
      },
      {
        label: 'Priority',
        key: 'Priority',
        sortable: true
      },
      {
        label: 'Requested Service',
        key: 'RequestedService',
        sortable: true
      },
      {
        label: 'Item No.',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Serial Number',
        key: 'SerialNumber',
        sortable: true
      },
      {
        label: 'Manufacturer',
        key: 'Manufacturer',
        sortable: true
      },
      {
        label: 'Model',
        key: 'Model',
        sortable: true
      },
      {
        label: 'Current Assignment',
        key: 'AssignedTo',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'Current WO#',
        key: 'LastWorkOrderNumber',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Created On',
            key: 'LastWorkOrderCreatedOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Next Date',
            key: 'OnDate',
            type: Date,
            sortable: true
          },
          {
            label: 'Next Meter',
            key: 'OnMeter',
            sortable: true
          },
          {
            label: 'Next Turn',
            key: 'OnTurn',
            sortable: true
          },
          {
            label: 'Priority',
            key: 'Priority',
            sortable: true
          },
          {
            label: 'Requested Service',
            key: 'RequestedService',
            sortable: true
          },
          {
            label: 'Item No.',
            key: 'ItemNumber',
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            sortable: true
          },
          {
            label: 'Serial Number',
            key: 'SerialNumber',
            sortable: true
          },
          {
            label: 'Manufacturer',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Model',
            key: 'Model',
            sortable: true
          },
          {
            label: 'Current Assignment',
            key: 'AssignedTo',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default serviceSchedule

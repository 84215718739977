const safetyPlanInspectionDetail = {
  title: 'Inspection Detail',
  deleteConfirmation: 'Are you sure you want to delete the Inspection Requirement?',
  labels: {
    0: 'Inspection Name',
    1: 'Inspection Checklist',
    6: 'Users Assigned',
    7: 'Reporting Instructions',
    10: 'Inspection Company'
  },
  inspectiontableHeader: {
    desktop: [
      {
        label: '',
        key: 'SafetyplanInspectionId',
        type: 'check'
      },
      {
        label: 'Name',
        key: 'InspectionName',
        sortable: true
      },
      {
        label: 'Checklist',
        key: 'ChecklistName',
        sortable: true
      },
      {
        label: 'Frequency',
        key: 'PatternFrequency',
        sortable: true
      },
      {
        label: 'Start Date',
        key: 'StartDate',
        sortable: true,
        type: Date
      },
      {
        label: 'End Date',
        key: 'EndDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Users Assigned',
        key: 'UserAssignedName',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'SafetyplanInspectionId',
        type: 'check'
      },
      {
        label: 'Name',
        key: 'InspectionName',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Checklist',
            key: 'ChecklistName',
            sortable: true
          },
          {
            label: 'Frequency',
            key: 'PatternFrequency',
            sortable: true
          },
          {
            label: 'Start Date',
            key: 'StartDate',
            sortable: true,
            type: Date
          },
          {
            label: 'End Date',
            key: 'EndDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Users Assigned',
            key: 'UserAssignedName',
            sortable: true
          }
        ]
      }
    ]
  },
  dropdownActions: [
    {
      title: 'Edit',
      actionName: 'onEdit',
      permissions: []
    },
    {
      title: 'Delete',
      actionName: 'onDelete',
      permissions: []
    },
    {
      title: 'Clone',
      actionName: 'onClone',
      permissions: []
    }
  ]
}

export default safetyPlanInspectionDetail

const dashboard = {
  title: {
    system: 'System Dashboard',
    tools: 'Tools Dashboard',
    materials: 'Materials Dashboard',
    requisition: 'Requisitions Dashboard',
    service: 'Service and Calibration Dashboard',
    pickTickets: 'Pick Tickets & Transfers Dashboard',
    purchasing: 'Purchasing Dashboard',
    jobSite: 'Job Site Dashboard'
  },
  panelLabel: {
    favorite: {
      title: 'Favorites',
      modalTitle: 'Favorites Manager',
      l_label: 'Available',
      r_label: 'Selected'
    },
    message: 'Message Center',
    kpi: 'Key Performance Indicators',
    system: {
      0: '% of Total Tool Value Out',
      1: '% of Pick Ticket Lines Filled',
      2: 'Value of Tools Lost'
    },
    jobSiteSystemDashboard: {
      0: '% of Total Tool Value Out',
      1: 'Tools & Equipment by Category - Top 10',
      2: 'Tools & Equipment By Assignment - Top 10'
    },
    requisitions: {
      0: '% of Pick Ticket Lines Filled',
      1: '% of Pick Ticket Lines Filled',
      2: 'Value of Tools Lost'
    },
    service: {
      0: 'Avg. Age of Overdue WOs',
      1: 'Avg. Hours to Complete Service Request by Month',
      2: 'Overdue Workorders by Category'
    },
    pickTickets: {
      0: 'Pick Ticket Status',
      1: 'Number of Transfers by Assignee (Top 10)',
      2: 'Number of Transfers by Category (Top 10)'
    },
    purchasing: {
      0: 'Avg. Age of Overdue POs',
      1: 'Total Value of POs by Vendor',
      2: 'Unique POs by Vendor'
    },
    tools: {
      0: 'Tool Loss % over 90 Days',
      1: 'Tools and Equipment by Category - Top 10',
      2: 'Tools and Equipment by Assignment - Top 10'
    },
    materials: {
      0: '% of Total Material Value Out',
      1: 'Materials In Stock By Assignment - Average Cost',
      2: 'Materials In Stock By Category - Average Cost'
    }
  },
  messageSummary: {
    workItemName: 'DashboardWI',
    DecomissionLocations: {
      text: 'There are locations to be decommissioned'
    },
    itemsPastReturnDate: {
      text: 'There are tools out past their return date',
      methodName: 'ShowToolBrowser_ToolsPastReturnDate'
    },
    lowLevel: {
      text: 'There are assets with low levels',
      methodName: 'ShowLowLevelBrowser'
    },
    openWorkOrders: {
      text: 'There are tools needing to be serviced',
      methodName: 'ShowWorkOrderBrowser_OpenWorkOrders'
    }
  }
}

export default dashboard

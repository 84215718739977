import axios from 'axios'
import { errorHandler, sortingFunc } from '../helpers/utils.js'

export default {
  state: () => ({
    shipData: [],
    locations: [],
    orgLocations: [],
    isShipLoading: false,
    costCenter: null,
    stockpointId: null,
    jobId: null
  }),
  mutations: {
    addShipData (state, { data, typeId }) {
      const obj = {}
      obj.typeId = typeId
      obj.data = data
      const existingIndex = state.shipData.findIndex((element) => element.typeId == typeId)

      if (existingIndex !== -1) {
        state.shipData[existingIndex] = obj
      } else {
        state.shipData.push(obj)
      }
      state.shipData = [...state.shipData]
    },
    setShipLoading (state, flag) {
      state.isShipLoading = flag
    },
    setCostCenter (state, Id) {
      state.costCenter = Id
    },
    setLocations (state, data) {
      state.locations = data
    },
    setOrgLocations (state, data) {
      state.orgLocations = data
    },
    setStockpointId (state, data) {
      state.stockpointId = data
    },
    setJobId (state, data) {
      state.jobId = data
    }
  },
  actions: {
    async loadDataByListType (context, { query, listTypeId, stockpointId }) {
      const inputSource = 2 // User
      const headerConfig = {
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }
      const params = JSON.stringify({
        query: '\'' + query + '\'',
        type: '\'' + listTypeId + '\'',
        stockpointId: '\'' + stockpointId + '\'',
        inputSource: '\'' + inputSource + '\''
      })
      try {
        const { data: { Body: lists } } = await axios.post('/api/core/ListsService/GetKVPList', params, headerConfig)
        if (query == '') {
          context.commit('addShipData', { data: lists, typeId: listTypeId })
        }
        return lists
      } catch (error) {
        return []
      }
    },
    async loadCostCenterByStockPoint (context, stockPointId) {
      const params = {
        orgId: `"${localStorage.getItem('OrgId')}"`,
        entityId: `"${stockPointId}"`
      }
      try {
        const { data } = await axios.post('/api/core/EntitiesService/GetEntityDto', params)
        return data.Body.CostCenterId
      } catch (error) {
        return null
      }
    },
    async GetEntityData (context, stockPointId) {
      const params = {
        orgId: `"${localStorage.getItem('OrgId')}"`,
        entityId: `"${stockPointId}"`
      }
      try {
        const { data } = await axios.post('/api/core/EntitiesService/GetEntityDto', params)
        return data.Body
      } catch (error) {
        return null
      }
    },
    // force rebuild
    async loadStockLocations (context) {
      const params = JSON.stringify({
        includeInactive: 'false'
      })
      try {
        const { data } = await axios.post('/api/core/EntitiesService/GetOrgEntityTreeDtos', params)
        const locations = data.Body
        sortingFunc(locations, {
          key: 'Description',
          type: String,
          asc: false
        })
        context.commit('setLocations', locations)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    async loadOrgLocations (context) {
      const params = JSON.stringify({
        includeInactive: 'false'
      })
      try {
        const { data } = await axios.post('/api/core/EntitiesService/GetOrgEntityTreeDtos', params)
        const locations = data.Body
        sortingFunc(locations, {
          key: 'Description',
          type: String,
          asc: false
        })
        context.commit('setOrgLocations', locations)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    async loadStockPoint (context, { listTypeId, stockpointId }) {
      const params = {
        orgId: `"${localStorage.getItem('OrgId')}"`,
        id: `"${stockpointId}"`,
        type: `${listTypeId}`
      }
      try {
        const { data } = await axios.post('/api/core/ListsService/GetKvpById', params)
        return data.Body.Value
      } catch (error) {
        return null
      }
    }
  },
  getters: {
    getShipData: (state) => (id) => {
      if (!state.shipData.length) return null
      return state.shipData.filter(data => data.typeId === id)[0]
    },
    isShipLoading (state) {
      return state.isShipLoading
    },
    getCostCenter: (state) => {
      return state.costCenter
    },
    getLocations (state) {
      return state.locations
    },
    getOrgLocations (state) {
      return state.orgLocations
    },
    getStockpointId (state) {
      return state.stockpointId
    },
    getJobId (state) {
      return state.jobId
    }
  }
}

const countTicketTabCountSummaryTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Categoría',
        key: 'Category',
        sortable: true
      },
      {
        label: 'Artículo',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'T',
        key: 'ItemType',
        sortable: true
      },
      {
        label: 'Descripción del Artículo',
        key: 'ItemDescription',
        sortable: true
      },
      {
        label: 'Fabricante',
        key: 'Manufacturer',
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'Model',
        sortable: true
      },
      {
        label: 'Conteo',
        key: 'Count',
        sortable: true
      },
      {
        label: 'Compartimiento',
        key: 'Bin',
        sortable: true
      },
      {
        label: 'Asignación',
        key: 'Assignment',
        sortable: true
      },
      {
        label: 'Nota',
        key: 'Note',
        sortable: true
      },
      {
        label: 'Contado',
        key: 'CountedOn',
        type: Date,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Contado por',
        key: 'CountedBy',
        sortable: true
      },
      {
        label: 'Propietario',
        key: 'Owner',
        sortable: true
      },
      {
        label: 'Equipo No.',
        key: 'KitNumber',
        sortable: true
      },
      {
        label: 'Estado',
        key: 'Status',
        sortable: true
      },
      {
        label: 'UP',
        nested: true,
        nestedKey: 'Key',
        key: 'PersonalIssue',
        sortable: true
      },
      {
        label: '',
        key: 'countedItemActionField',
        sortable: false
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            key: 'Category',
            sortable: true
          },
          {
            label: 'Artículo',
            key: 'ItemNumber',
            sortable: true
          },
          {
            label: 'T',
            key: 'ItemType',
            sortable: true
          },
          {
            label: 'Descripción del Artículo',
            key: 'ItemDescription',
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'Model',
            sortable: true
          },
          {
            label: 'Contar',
            key: 'Count',
            sortable: true
          },
          {
            label: 'Compartimiento',
            key: 'Bin',
            sortable: true
          },
          {
            label: 'Asignación',
            key: 'Assignment',
            sortable: true
          },
          {
            label: 'Nota',
            key: 'Note',
            sortable: true
          },
          {
            label: 'Contado',
            key: 'CountedOn',
            type: Date,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Contado por',
            key: 'CountedBy',
            sortable: true
          },
          {
            label: 'Propietario',
            key: 'Owner',
            sortable: true
          },
          {
            label: 'Equipo No.',
            key: 'KitNumber',
            sortable: true
          },
          {
            label: 'Estado',
            key: 'Status',
            sortable: true
          },
          {
            label: 'UP',
            nested: true,
            nestedKey: 'Key',
            key: 'PersonalIssue',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: 'countedItemActionField',
        sortable: false
      }
    ]
  }
}

export default countTicketTabCountSummaryTable

const reqbyReq = {
  tableHeader: {
    desktop: [
      {
        label: 'Estado',
        key: 'Status',
        sortable: 'true'
      },
      {
        label: 'Fecha',
        key: 'CreatedOn',
        sortable: 'true'
      },
      {
        label: 'Pedido',
        key: 'Number',
        sortable: 'true'
      },
      {
        label: 'Por',
        key: 'RequestedByName',
        sortable: 'true'
      },
      {
        label: 'Para',
        key: 'DeliverToEntityName',
        sortable: 'true'
      },
      {
        label: 'Centro de costos',
        key: 'CostCenterName',
        sortable: 'true'
      },
      {
        label: 'Código de costo',
        key: 'CostCodeName',
        sortable: 'true'
      },
      {
        label: 'Artículos',
        key: 'QtyRequested',
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: 'Estado',
        key: 'Status'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Fecha',
            key: 'CreatedOn',
            sortable: 'true'
          },
          {
            label: 'Pedido',
            key: 'Number',
            sortable: 'true'
          },
          {
            label: 'Por',
            key: 'RequestedByName',
            sortable: 'true'
          },
          {
            label: 'Para',
            key: 'DeliverToEntityName',
            sortable: 'true'
          },
          {
            label: 'Centro de costos',
            key: 'CostCenterName',
            sortable: 'true'
          },
          {
            label: 'Código de costo',
            key: 'CostCodeName',
            sortable: 'true'
          },
          {
            label: 'Artículos',
            key: 'QtyRequested',
            sortable: 'true'
          }
        ]
      }
    ]
  },
  tableHeaderWithFlag: {
    desktop: [
      {
        label: 'Colaborar',
        key: 'CollaborateCount',
        sortable: 'true',
        flag: 'showCollaborateExpansion'
      },
      {
        label: 'Estado',
        key: 'Status',
        sortable: 'true'
      },
      {
        label: 'Fecha',
        key: 'CreatedOn',
        sortable: 'true'
      },
      {
        label: 'Pedido',
        key: 'Number',
        sortable: 'true'
      },
      {
        label: 'Por',
        key: 'RequestedByName',
        sortable: 'true'
      },
      {
        label: 'Para',
        key: 'DeliverToEntityName',
        sortable: 'true'
      },
      {
        label: 'Centro de costos',
        key: 'CostCenterName',
        sortable: 'true'
      },
      {
        label: 'Código de costo',
        key: 'CostCodeName',
        sortable: 'true'
      },
      {
        label: 'Artículos',
        key: 'QtyRequested',
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: 'Colaborar',
        key: 'CollaborateCount',
        sortable: 'true',
        flag: 'showCollaborateExpansion'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Estado',
            key: 'Status',
            sortable: 'true'
          },
          {
            label: 'Fecha',
            key: 'CreatedOn',
            sortable: 'true'
          },
          {
            label: 'Pedido',
            key: 'Number',
            sortable: 'true'
          },
          {
            label: 'Por',
            key: 'RequestedByName',
            sortable: 'true'
          },
          {
            label: 'Para',
            key: 'DeliverToEntityName',
            sortable: 'true'
          },
          {
            label: 'Centro de costos',
            key: 'CostCenterName',
            sortable: 'true'
          },
          {
            label: 'Código de costo',
            key: 'CostCodeName',
            sortable: 'true'
          },
          {
            label: 'Artículos',
            key: 'QtyRequested',
            sortable: 'true'
          }
        ]
      }
    ]
  }
}

export default reqbyReq

const searchReturns = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'ItemId'
      },
      {
        label: 'Categoría'
      },
      {
        label: 'Artículo'
      },
      {
        label: 'Descripción'
      },
      {
        label: 'Código de barras'
      },
      {
        label: 'Número de serie'
      },
      {
        label: 'Personal responsable'
      },
      {
        label: 'Dueño'
      },
      {
        label: 'en-ubicacion'
      },
      {
        label: 'Disponible para devolver'
      },
      {
        label: 'Pendiente de devolución'
      },
      {
        label: 'Regresar'
      }
    ],
    mobile: [
      {
        label: '',
        key: ''
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría'
          },
          {
            label: 'Artículo'
          },
          {
            label: 'Descripción'
          },
          {
            label: 'Código de barras'
          },
          {
            label: 'Número de serie'
          },
          {
            label: 'Personal responsable'
          },
          {
            label: 'Dueño'
          },
          {
            label: 'en-ubicacion'
          },
          {
            label: 'Disponible para devolver'
          },
          {
            label: 'Pendiente de devolución'
          },
          {
            label: 'Regresar'
          }
        ]
      }
    ]
  }
}

export default searchReturns

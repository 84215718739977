export default {
  state: () => ({
    searchCriteria: {
      q: undefined,
      assetKind: 'A'
    },
    searchCategory: '',
    searchManufacturer: '',
    searchCategories: [],
    searchManufacturerList: [],
    searching: false,
    searchTemplateList: [],
    searchTemplates: null
  }),
  mutations: {
    setSearchCriteria (state, results) {
      state.searchCriteria = results
    },
    setSearchText (state, text) {
      if (state.searchCriteria) {
        state.searchCriteria.q = text
      }
    },
    setSearchCategoryList (state, category) {
      state.searchCategories = [...new Set(category)]
    },
    setSearchCategory (state, category) {
      state.searchCategory = category
    },
    setSearchManufacturer (state, manufacturer) {
      state.searchManufacturer = manufacturer
    },
    setSearchManufacturerList (state, manufacturer) {
      state.searchManufacturerList = [...new Set(manufacturer)]
    },
    setAssetKind (state, assetKind) {
      if (state.searchCriteria) {
        state.searchCriteria.assetKind = assetKind
      }
    },
    setTemplateList (state, Template) {
      state.searchTemplateList = [...new Set(Template)]
    },
    setTemplate (state, Template) {
      state.searchTemplates = Template
    }
  },
  getters: {
    searchText: state => {
      return state.searchCriteria.q || ''
    },
    assetKind: state => {
      return state.searchCriteria.assetKind || 'A'
    },
    searchCategory: state => {
      return state.searchCategory
    },
    searchCategoryList: state => {
      return state.searchCategories
    },
    searchManufacturer: state => {
      return state.searchManufacturer
    },
    searchManufacturerList: state => {
      return state.searchManufacturerList
    },
    searchTemplate: state => {
      return state.searchTemplates
    },
    searchTemplateList: state => {
      return state.searchTemplateList
    }
  }
}

const routes = {
  Dashboards: [
    {
      name: 'Inspeccion',
      route_name: 'Inspection Dashboard',
      path: '/ehs/dashboards',
      matchPath: ['ehs/dashboards'],
      completed: true,
      released: true
    },
    {
      name: 'Entranmiento',
      route_name: 'Training Dashboard',
      path: '/ehs/dashboards/training-dashboard',
      matchPath: ['ehs/dashboards/training-dashboard'],
      completed: true,
      released: true
    },
    {
      name: 'Incidentes',
      route_name: 'Incidents Dashboard',
      path: '/ehs/dashboards/incidents-dashboard',
      matchPath: ['ehs/dashboards/incidents-dashboard'],
      completed: true,
      released: true
    },
    {
      name: 'Observaciones',
      route_name: 'Observations Dashboard',
      path: '/ehs/dashboards/observations',
      matchPath: ['ehs/dashboards/observations'],
      completed: true,
      released: true
    },
    {
      name: 'JSA',
      route_name: 'JSA Dashboard',
      path: '/ehs/dashboards/jsa',
      matchPath: ['ehs/dashboards/jsa'],
      completed: true,
      released: true
    },
    {
      name: 'Escaneo de Seguridad',
      route_name: 'Safety Scan Dashboard',
      path: '/ehs/dashboards/safety-scan-dashboard',
      matchPath: ['ehs/dashboards/safety-scan-dashboard'],
      completed: true,
      released: true
    },
    {
      name: 'Formas',
      route_name: 'Forms Dashboard',
      path: '/ehs/dashboards/forms-dashboard',
      matchPath: ['ehs/dashboards/forms-dashboard'],
      completed: true,
      released: true
    }
  ],
  PerformanceReports: [
    {
      name: 'Inspeccion',
      route_name: 'Inspection Performance Reports',
      path: '/ehs/reports-graphs/performance-reports/inspection-performance-reports',
      matchPath: ['ehs/reports-graphs/performance-reports/inspection-performance-reports'],
      completed: true,
      released: true
    },
    {
      name: 'Entranmiento',
      route_name: 'Training Performance Reports',
      path: '/ehs/reports-graphs/performance-reports/training-performance-reports',
      matchPath: ['ehs/reports-graphs/performance-reports/training-performance-reports'],
      completed: true,
      released: true
    },
    {
      name: 'Incidentes',
      route_name: 'Incidents Performance Reports',
      path: '/ehs/reports-graphs/performance-reports/incidents-performance-reports',
      matchPath: ['ehs/reports-graphs/performance-reports/incidents-performance-reports'],
      completed: true,
      released: true
    },
    {
      name: 'Observaciones',
      route_name: 'Observations Performance Reports',
      path: '/ehs/reports-graphs/performance-reports/observations-performance-reports',
      matchPath: ['ehs/reports-graphs/performance-reports/observations-performance-reports'],
      completed: true,
      released: true
    },
    {
      name: 'JSA',
      route_name: 'JSA Performance Reports',
      path: '/ehs/reports-graphs/performance-reports/jsa-performance-reports',
      matchPath: ['ehs/reports-graphs/performance-reports/jsa-performance-reports'],
      // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
      completed: true,
      released: true
    },
    {
      name: 'Escaneo de Seguridad',
      route_name: 'Safety Scan Performance Reports',
      path: '/ehs/reports-graphs/performance-reports/safety-scan-performance-reports',
      matchPath: ['ehs/reports-graphs/performance-reports/safety-scan-performance-reports'],
      // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
      completed: true,
      released: true
    },
    {
      name: 'Formas',
      route_name: 'Forms Performance Reports',
      path: '/ehs/reports-graphs/performance-reports/forms-performance-reports',
      matchPath: ['ehs/reports-graphs/performance-reports/forms-performance-reports'],
      // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
      completed: true,
      released: true
    }
  ],
  ScheduledReports: [
    {
      name: 'Inspeccion',
      route_name: 'Inspection Scheduled Reports',
      path: '/ehs/reports-graphs/scheduled-reports/inspection-scheduled-reports',
      matchPath: ['ehs/reports-graphs/scheduled-reports/inspection-scheduled-reports'],
      completed: true,
      released: true
    },
    {
      name: 'Entranmiento',
      route_name: 'Training Scheduled Reports',
      path: '/ehs/reports-graphs/scheduled-reports/training-scheduled-reports',
      matchPath: ['ehs/reports-graphs/scheduled-reports/training-scheduled-reports'],
      completed: true,
      released: true
    },
    {
      name: 'Incidentes',
      route_name: 'Incidents Scheduled Reports',
      path: '/ehs/reports-graphs/scheduled-reports/incidents-scheduled-reports',
      matchPath: ['ehs/reports-graphs/scheduled-reports/incidents-scheduled-reports'],
      completed: true,
      released: true
    },
    {
      name: 'Observaciones',
      route_name: 'Observations Scheduled Reports',
      path: '/ehs/reports-graphs/scheduled-reports/observations-scheduled-reports',
      matchPath: ['ehs/reports-graphs/scheduled-reports/observations-scheduled-reports'],
      completed: true,
      released: true
    },
    {
      name: 'JSA',
      route_name: 'JSA Scheduled Reports',
      path: '/ehs/reports-graphs/scheduled-reports/jsa-scheduled-reports',
      matchPath: ['ehs/reports-graphs/scheduled-reports/jsa-scheduled-reports'],
      // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
      completed: true,
      released: true
    },
    {
      name: 'Escaneo de Seguridad',
      route_name: 'Safety Scan Scheduled Reports',
      path: '/ehs/reports-graphs/scheduled-reports/safety-scan-scheduled-reports',
      matchPath: ['ehs/reports-graphs/scheduled-reports/safety-scan-scheduled-reports'],
      // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
      completed: true,
      released: true
    }
  ],
  LicenseMaintenance: [
    {
      name: 'Inspeccion',
      route_name: 'Inspection License Maintenance',
      path: '/ehs/ehs-settings/ehs-license-maintenance/inspection-license-maintenance',
      matchPath: ['ehs/ehs-settings/ehs-license-maintenance/inspection-license-maintenance'],
      completed: true,
      released: true
    },
    {
      name: 'Entranmiento',
      route_name: 'Training License Maintenance',
      path: '/ehs/ehs-settings/ehs-license-maintenance/training-license-maintenance',
      matchPath: ['ehs/ehs-settings/ehs-license-maintenance/training-license-maintenance'],
      completed: true,
      released: true
    },
    {
      name: 'Incidentes',
      route_name: 'Incident License Maintenance',
      path: '/ehs/ehs-settings/ehs-license-maintenance/incident-license-maintenance',
      matchPath: ['ehs/ehs-settings/ehs-license-maintenance/incident-license-maintenance'],
      completed: true,
      released: true
    },
    {
      name: 'Observaciones',
      route_name: 'Observation License Maintenance',
      path: '/ehs/ehs-settings/ehs-license-maintenance/observation-license-maintenance',
      matchPath: ['ehs/ehs-settings/ehs-license-maintenance/observation-license-maintenance'],
      completed: true,
      released: true
    },
    {
      name: 'JSA',
      route_name: 'JSA License Maintenance',
      path: '/ehs/ehs-settings/ehs-license-maintenance/jsa-license-maintenance',
      matchPath: ['ehs/ehs-settings/ehs-license-maintenance/jsa-license-maintenance'],
      // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
      completed: true,
      released: true
    },
    {
      name: 'Escaneo de Seguridad',
      route_name: 'Safety Scan License Maintenance',
      path: '/ehs/ehs-settings/ehs-license-maintenance/safety-scan-license-maintenance',
      matchPath: ['ehs/ehs-settings/ehs-license-maintenance/safety-scan-license-maintenance'],
      // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
      completed: true,
      released: true
    },
    {
      name: 'Formas',
      route_name: 'Forms License Maintenance',
      path: '/ehs/ehs-settings/ehs-license-maintenance/forms-license-maintenance',
      matchPath: ['ehs/ehs-settings/ehs-license-maintenance/forms-license-maintenance'],
      // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
      completed: true,
      released: true
    }
  ],
  LocationMaintenance: [
    {
      name: 'Observaciones',
      route_name: 'Observation Location Maintenance',
      path: '/ehs/ehs-settings/location-maintenance/observation-location-maintenance',
      matchPath: ['ehs/ehs-settings/location-maintenance/observation-location-maintenance'],
      completed: true,
      released: true
    },
    {
      name: 'Incidentes',
      route_name: 'Incidents Location Maintenance',
      path: '/ehs/ehs-settings/location-maintenance/incidents-location-maintenance',
      matchPath: ['ehs/ehs-settings/location-maintenance/incidents-location-maintenance'],
      completed: true,
      released: true
    },
    {
      name: 'Escaneo de Seguridad',
      route_name: 'Safety Scan Location Maintenance',
      path: '/ehs/ehs-settings/location-maintenance/safety-scan-location-maintenance',
      matchPath: ['ehs/ehs-settings/location-maintenance/safety-scan-location-maintenance'],
      // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
      completed: true,
      released: true
    },
    {
      name: 'Formas',
      route_name: 'Forms Location Maintenance',
      path: '/ehs/ehs-settings/location-maintenance/forms-location-maintenance',
      matchPath: ['ehs/ehs-settings/location-maintenance/forms-location-maintenance'],
      // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
      completed: true,
      released: true
    }
  ],
  ResponsiblePartyMaintenance: [
    {
      name: 'Inspeccion',
      route_name: 'Inspection RP Maintenance',
      path: '/ehs/ehs-settings/responsible-party-maintenance/inspection-pr-maintenance',
      matchPath: ['ehs/ehs-settings/responsible-party-maintenance/inspection-pr-maintenance'],
      completed: true,
      released: true
    },
    {
      name: 'Incidentes',
      route_name: 'Incidents RP Maintenance',
      path: '/ehs/ehs-settings/responsible-party-maintenance/incidents-pr-maintenance',
      matchPath: ['ehs/ehs-settings/responsible-party-maintenance/incidents-pr-maintenance'],
      completed: true,
      released: true
    },
    {
      name: 'Observaciones',
      route_name: 'Observation RP Maintenance',
      path: '/ehs/ehs-settings/responsible-party-maintenance/observation-pr-maintenance',
      matchPath: ['ehs/ehs-settings/responsible-party-maintenance/observation-pr-maintenance'],
      completed: true,
      released: true
    },
    {
      name: 'Escaneo de Seguridad',
      route_name: 'Safety Scan RP Maintenance',
      path: '/ehs/ehs-settings/responsible-party-maintenance/safety-scan-pr-maintenance',
      matchPath: ['ehs/ehs-settings/responsible-party-maintenance/safety-scan-pr-maintenance'],
      // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
      completed: true,
      released: true
    },
    {
      name: 'Formas',
      route_name: 'Forms RP Maintenance',
      path: '/ehs/ehs-settings/responsible-party-maintenance/forms-pr-maintenance',
      matchPath: ['ehs/ehs-settings/responsible-party-maintenance/forms-pr-maintenance'],
      // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
      completed: true,
      released: true
    }
  ],
  DataExportAPI: [
    {
      name: 'Inspeccion',
      route_name: 'Inspection Data Export / API',
      path: '/ehs/ehs-settings/data-export-api/inspection-data-export-api',
      matchPath: ['ehs/ehs-settings/data-export-api/inspection-data-export-api'],
      // entitlement: 'Entitlement_Safety_Tier_Integrations',
      completed: true,
      released: true
    },
    {
      name: 'Entranmiento',
      route_name: 'Training Data Export / API',
      path: '/ehs/ehs-settings/data-export-api/training-data-export-api',
      matchPath: ['ehs/ehs-settings/data-export-api/training-data-export-api'],
      // entitlement: 'Entitlement_Safety_Tier_Integrations',
      completed: true,
      released: true
    },
    {
      name: 'Incidentes',
      route_name: 'Incident Data Export / API',
      path: '/ehs/ehs-settings/data-export-api/incident-data-export-api',
      matchPath: ['ehs/ehs-settings/data-export-api/incident-data-export-api'],
      // entitlement: 'Entitlement_Safety_Tier_Integrations',
      completed: true,
      released: true
    },
    {
      name: 'Observaciones',
      route_name: 'Observations Data Export / API',
      path: '/ehs/ehs-settings/data-export-api/observations-data-export-api',
      matchPath: ['ehs/ehs-settings/data-export-api/observations-data-export-api'],
      // entitlement: 'Entitlement_Safety_Tier_Integrations',
      completed: true,
      released: true
    },
    {
      name: 'JSA',
      route_name: 'JSA Data Export / API',
      path: '/ehs/ehs-settings/data-export-api/jsa-data-export-api',
      matchPath: ['ehs/ehs-settings/data-export-api/jsa-data-export-api'],
      // entitlement: 'Entitlement_Safety_Tier_Integrations',
      completed: true,
      released: true
    },
    {
      name: 'Escaneo de Seguridad',
      route_name: 'Safety Scan Data Export / API',
      path: '/ehs/ehs-settings/data-export-api/safety-scan-data-export-api',
      matchPath: ['ehs/ehs-settings/data-export-api/safety-scan-data-export-api'],
      // entitlement: 'Entitlement_Safety_Tier_Integrations',
      completed: true,
      released: true
    },
    {
      name: 'Formas',
      route_name: 'Forms Data Export / API',
      path: '/ehs/ehs-settings/data-export-api/forms-data-export-api',
      matchPath: ['ehs/ehs-settings/data-export-api/forms-data-export-api'],
      // entitlement: 'Entitlement_Safety_Tier_Integrations',
      completed: true,
      released: true
    }
  ]
}

export default routes

const requisitionBrowser = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'RequisitionRequestHeaderId',
        type: 'check'
      },
      {
        label: 'Colaborar',
        type: Number,
        key: 'CollaborateCount',
        sortable: true,
        flag: 'showCollaborateExpansion'
      },
      {
        label: 'Solicitud No',
        type: Number,
        key: 'Number',
        sortable: true
      },
      {
        label: 'Pendiente',
        type: Date,
        key: 'RequestedDueDate',
        sortable: true,
        filtertype: 'DateTime'
      },
      {
        label: 'Solicitud Para',
        type: String,
        key: 'RequestedTo',
        sortable: true
      },
      {
        label: 'Solicitud De',
        key: 'RequestedFrom',
        sortable: true
      },
      {
        label: 'Solicitado por',
        key: 'RequestedBy',
        sortable: true
      },
      {
        label: 'Cantidad solicitada',
        key: 'RequestedQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Cantidad accionada',
        key: 'ActionedQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Cantidad indecisa',
        key: 'UndecidedQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Seleccionar cantidad de boletos',
        key: 'PickQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Cantidad de orden de compra',
        key: 'PurchaseQty',
        sortable: true
      },
      {
        label: 'Cantidad rechazada',
        key: 'RejectedQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Código de costo',
        key: 'CostCode',
        sortable: true
      },
      {
        label: 'Creada',
        type: Date,
        key: 'CreatedOn',
        sortable: true,
        filtertype: 'DateTime'
      },
      {
        label: 'Estado',
        type: String,
        key: 'StateType',
        sortable: true
      },
      {
        label: 'Estatus',
        type: String,
        key: 'StatusValue',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'RequisitionRequestHeaderId',
        type: 'check'
      },
      {
        label: 'Colaborar',
        type: Number,
        key: 'CollaborateCount',
        flag: 'showCollaborateExpansion'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Solicitud No',
            type: Number,
            key: 'Number',
            sortable: true
          },
          {
            label: 'Pendiente',
            type: Date,
            key: 'RequestedDueDate',
            sortable: true
          },
          {
            label: 'Solicitud Para',
            type: String,
            key: 'RequestedTo',
            sortable: true
          },
          {
            label: 'Solicitud de',
            key: 'RequestedFrom',
            sortable: true
          },
          {
            label: 'Solicitado por',
            key: 'RequestedBy',
            sortable: true
          },
          {
            label: 'Cantidad solicitada',
            key: 'RequestedQty',
            sortable: true
          },
          {
            label: 'Cantidad accionada',
            key: 'ActionedQty',
            sortable: true
          },
          {
            label: 'Cantidad indecisa',
            key: 'UndecidedQty',
            sortable: true
          },
          {
            label: 'Seleccionar cantidad de boletos',
            key: 'PickQty',
            sortable: true
          },
          {
            label: 'Cantidad de orden de compra',
            key: 'PurchaseQty',
            sortable: true
          },
          {
            label: 'Cantidad rechazada',
            key: 'RejectedQty',
            sortable: true
          },
          {
            label: 'Código de costo',
            key: 'CostCode',
            sortable: true
          },
          {
            label: 'Creada',
            type: Date,
            key: 'CreatedOn',
            sortable: true
          },
          {
            label: 'Estado',
            type: String,
            key: 'StateType',
            sortable: true
          },
          {
            label: 'Estatus',
            type: String,
            key: 'StatusValue',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default requisitionBrowser

const schedulingBrowser = {
  title: 'Scheduling Browser',
  tableHeader: {
    desktop: [
      {
        label: 'State',
        key: 'state',
        sortable: true
      },
      {
        label: 'Scheduling Request',
        key: 'schedulingRequest',
        sortable: true
      },
      {
        label: 'Submitted On',
        key: 'submittedOn',
        sortable: true,
        type: Date
      },
      {
        label: 'Start Date',
        key: 'startDate',
        sortable: true,
        type: Date
      },
      {
        label: 'End Date',
        key: 'endDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Category',
        key: 'category',
        sortable: true
      },
      {
        label: 'Model',
        key: 'model',
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true
      },
      {
        label: 'Manufacturer',
        key: 'manufacturer',
        sortable: true
      },
      {
        label: 'Requested Qty',
        key: 'quantity',
        sortable: true
      },
      {
        label: 'PickTicket Approved Qty',
        key: 'pickTicketApprovedQty',
        sortable: true
      },
      {
        label: 'PurchaseOrder Approved Qty',
        key: 'purchaseOrderApprovedQty',
        sortable: true
      },
      {
        label: 'No Action Approved Qty',
        key: 'noActionApprovedQty',
        sortable: true
      },
      {
        label: 'Rejected Qty',
        key: 'rejectedQty',
        sortable: true
      },
      {
        label: 'Undecided Qty',
        key: 'undecidedQty',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'State',
        key: 'state'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Scheduling Request',
            key: 'schedulingRequest',
            sortable: true
          },
          {
            label: 'Submitted On',
            key: 'submittedOn',
            sortable: true,
            type: Date
          },
          {
            label: 'Start Date',
            key: 'startDate',
            sortable: true,
            type: Date
          },
          {
            label: 'End Date',
            key: 'endDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Category',
            key: 'category',
            sortable: true
          },
          {
            label: 'Model',
            key: 'model',
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'Manufacturer',
            key: 'manufacturer',
            sortable: true
          },
          {
            label: 'Requested Qty',
            key: 'quantity',
            sortable: true
          },
          {
            label: 'PickTicket Approved Qty',
            key: 'pickTicketApprovedQty',
            sortable: true
          },
          {
            label: 'PurchaseOrder Approved Qty',
            key: 'purchaseOrderApprovedQty',
            sortable: true
          },
          {
            label: 'No Action Approved Qty',
            key: 'noActionApprovedQty',
            sortable: true
          },
          {
            label: 'Rejected Qty',
            key: 'rejectedQty',
            sortable: true
          },
          {
            label: 'Undecided Qty',
            key: 'undecidedQty',
            sortable: true
          }
        ]
      }
    ]
  },
  formLabels: {
    labels: {
      0: 'Schedule Request',
      1: 'Model',
      2: 'Go To Date'
    }
  },
  subActionHeader: {
    1: {
      title: 'Reject',
      key: 'Reject',
      isShow: true
    },
    2: {
      title: 'Approve',
      key: 'Approve',
      isShow: true
    },
    3: {
      title: 'Back',
      icon: 'fas fa-undo',
      isShow: true
    }
  },
  scheduleRequest: 'Scheduling Request',
  details: {
    labels: {
      0: 'Scheduling Number',
      1: 'Status',
      2: 'Submit To',
      3: 'Location',
      4: 'Date Needed',
      5: 'Return Date'
    }
  },
  approveRejectTableHeader: {
    desktop: [
      {
        label: '',
        key: 'scheduleLineId',
        type: 'check'
      },
      {
        label: 'Requested',
        key: 'undecidedQty',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        type: String,
        sortable: true
      },
      {
        label: 'Manufacturer',
        key: 'manufacturer',
        type: String,
        sortable: true
      },
      {
        label: 'Model',
        key: 'model',
        type: String,
        sortable: true
      },
      {
        label: 'Generic',
        key: 'IsGeneric',
        isEditable: true,
        type: Boolean,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'scheduleLineId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Requested',
            key: 'undecidedQty',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            type: String,
            sortable: true
          },
          {
            label: 'Manufacturer',
            key: 'manufacturer',
            type: String,
            sortable: true
          },
          {
            label: 'Model',
            key: 'model',
            type: String,
            sortable: true
          },
          {
            label: 'Generic',
            key: 'IsGeneric',
            isEditable: true,
            type: Boolean,
            sortable: true
          }
        ]
      }
    ]
  },
  lineActions: [
    {
      title: 'View Detail',
      actionName: 'viewDetail'
    }
  ],
  lineItemActions: [
    {
      title: 'Approve',
      actionName: 'approve'
    },
    {
      title: 'Reject',
      actionName: 'reject'
    }
  ],
  scheduleApprove: 'Scheduling Approve',
  schedulingApproveOptions: {
    0: 'Approve and Create Pick Ticket',
    1: 'Approve and Create Purchase Order',
    2: 'Approve with No Other Action'
  },
  rejectMessage: 'Are you sure you want to reject this request?',
  rejectTitle: 'Continue?',
  rejectSuccess: 'Selected scheduling items has been rejected successfully.',
  rejectError: 'Cannot reject more than the requested quantity.',
  approveSuccess: 'Selected scheduling items has been approved successfully.',
  actionMessage: 'Select item before performing Approve/Reject action.'
}

export default schedulingBrowser

<template>
  <div class="px-4 pt-3" style="position: relative;">
    <input type="hidden" v-bind:id="formId" v-bind:name="formId" v-model="value">
    <div>
      <div
        class="form-group row mb-0 pb-2">
        <label class="col-md-3 col-form-label font-weight-bold align-middle pr-0">
          {{displayLabel}}
          <span v-if="isRequired && !value && !isNumberValid && !isLoading" class="text-danger float-right">
              *
          </span>
        </label>
        <div v-if="!numberType" class="col-md-9 align-middle">
          <input class="form-control" v-model="value" v-bind:disabled="true">
        </div>
        <form-group v-else class="col-md-9 align-middle mb-0" :validator="$v.value">
          <NumberChooser
            style="height:33px"
            :numberType="numberType"
            :number="value"
            :isError="$v.value.$error"
            @onNumberChanged="onNumberChanged($event)"
            @onIsValidChanged="onIsValidChanged($event)"
            @getNewNumber="getNewNumber($event)"
            @saveUsedNumber="saveUsedNumber($event)"
            @isAllowBarcodeBlank="isAllowBarcodeBlank"/>
        </form-group>
      </div>
    </div>
  </div>
</template>
<script>
import NumberChooser from '../components/shared/NumberChooser.vue'

export default {
  name: 'NextNumberCatalogFormInputLine',
  components: {
    NumberChooser
  },
  props: {
    numberType: {
      type: Number, // TW.Lists.Contracts.NumberType
      default: null
    },
    displayLabel: {
      type: String,
      required: true
    },
    initialValue: {
      type: String,
      default: ''
    },
    formId: {
      type: String,
      required: true
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    maxlength: {
      type: Number,
      required: false,
      default: 50
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      value: '',
      isNumberValid: false
    }
  },
  validations: {
    value: {
      numberValid () {
        return this.isNumberValid
      }
    }
  },
  created () {
    this.value = this.initialValue
  },
  methods: {
    onNumberChanged (newValue) {
      this.value = newValue
      this.$emit('onNumberChanged', newValue)
    },
    onIsValidChanged (newValue) {
      this.isNumberValid = newValue
      this.$v.value.$touch()
      this.$emit('onIsValidChanged', newValue)
    },
    saveUsedNumber (method) {
      this.$emit('saveUsedNumber', method)
    },
    getNewNumber (method) {
      this.$emit('getNewNumber', method)
    },
    isAllowBarcodeBlank (val) {
      this.$emit('isAllowBarcodeBlank', val)
    }
  }
}
</script>

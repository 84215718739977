const adjustmentDetailTable = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: '',
        type: ''
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        sortable: true,
        type: String
      },
      {
        label: 'Item Description',
        key: 'ItemDescription',
        sortable: true,
        type: String
      },
      {
        label: 'Manuf.',
        key: 'Manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Model',
        key: 'Model',
        sortable: true,
        type: String
      },
      {
        label: 'Bin No.',
        key: 'Bin',
        sortable: true,
        type: Number
      },
      {
        label: 'Ticket',
        key: 'TicketNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Counted By',
        key: 'CountedBy',
        sortable: true,
        type: String
      },
      {
        label: 'Counted At',
        key: 'Location',
        sortable: true,
        type: String
      },
      {
        label: 'Current Assignment',
        key: 'Assignment',
        sortable: true,
        type: String
      },
      {
        label: 'Count',
        key: 'Count',
        sortable: true,
        type: String
      },
      {
        label: 'Note',
        key: 'Note',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: '',
        key: '',
        type: ''
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Item',
            key: 'ItemNumber',
            sortable: true,
            type: String
          },
          {
            label: 'Item Description',
            key: 'ItemDescription',
            sortable: true,
            type: String
          },
          {
            label: 'Manuf.',
            key: 'Manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Model',
            key: 'Model',
            sortable: true,
            type: String
          },
          {
            label: 'Bin No.',
            key: 'Bin',
            sortable: true,
            type: Number
          },
          {
            label: 'Ticket',
            key: 'TicketNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Counted By',
            key: 'CountedBy',
            sortable: true,
            type: String
          },
          {
            label: 'Counted At',
            key: 'Location',
            sortable: true,
            type: String
          },
          {
            label: 'Current Assignment',
            key: 'Assignment',
            sortable: true,
            type: String
          },
          {
            label: 'Count',
            key: 'Count',
            sortable: true,
            type: String
          },
          {
            label: 'Note',
            key: 'Note',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  }
}

export default adjustmentDetailTable

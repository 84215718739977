const documentCentral = {
  title: 'Document Central',
  actionHeader: {
    0: {
      title: 'Upload File',
      icon: 'fas fa-upload'
    },
    1: {
      title: 'Add Folder',
      icon: 'fas fa-plus'
    },
    2: {
      title: 'Recycle Bin',
      icon: 'fas fa-recycle'
    }
    // 3: {
    //   title: 'Go to Root Folder',
    //   icon: 'fas fa-angle-double-up'
    // }
  },
  selectFolderForm: {
    title: 'Select New Location',
    currentLocation: 'Current location:',
    message: {
      0: 'Move',
      1: 'to `{location}`?'
    },
    not_allowed: 'Unable to move selection to `{location}`!'
  },
  placeholder: {
    0: 'What are you looking for?'
  },
  uploadDocumentsForm: {
    title: 'Upload New Document(s)',
    labels: {
      0: 'Select file(s) to upload',
      1: 'Upload From',
      2: 'Location'
    }
  },
  addFolderForm: {
    title: 'Create New Folder',
    labels: {
      0: 'Folder Name',
      1: 'Description',
      2: 'Location'
    },
    duplicateFolderErrorMessage: 'A folder with the same name ({folderName}) already exists in this location.'
  },
  verifyDeleteForm: {
    nothing: 'Nothing selected',
    proceed: 'Are you sure you want to proceed?',
    documents: {
      title: 'Delete Document(s)',
      plural: 'You are about to delete {count} documents-',
      singular: 'You are about to delete this document-',
      restore: 'All deleted documents will reside in the Recycle Bin. They can be restored from there if needed.'
    },
    folders: {
      title: 'Delete Folder(s)',
      plural: 'You are about to delete {count} folders-',
      singular: 'You are about to delete this folder-'
    },
    documentsAndFolders: {
      title: 'Delete Document(s) and Folder(s)',
      plural: 'You are about to delete {documentCount} documents and {folderCount} folders-'
    }
  },
  folderDetailsForm: {
    title: 'Folder Details',
    details: 'Details',
    revisions: 'Revisions',
    labels: {
      0: 'Name',
      1: 'Description',
      2: 'Location'
    }
  },
  documentDetailsForm: {
    title: 'Document Details',
    details: 'Details',
    revisions: 'Revisions',
    labels: {
      0: 'Name',
      1: 'Description',
      2: 'ID',
      3: 'Uploaded',
      4: 'Uploaded By',
      5: 'Location'
    }
  },
  revisionColumns: {
    0: 'Revision',
    1: 'Modified By',
    2: 'Modified On'
  },
  dropdownActions: [
    {
      title: 'Details',
      actionName: 'editItem',
      permissions: []
    },
    {
      title: 'Download',
      actionName: 'downloadItem',
      permissions: []
    },
    {
      title: 'Move',
      actionName: 'moveItem',
      permissions: []
    },
    {
      title: 'Delete',
      actionName: 'deleteItem',
      permissions: []
    }
  ],
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'ItemId',
        type: 'check'
      },
      {
        label: 'Title',
        key: 'Name',
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Type',
        key: 'MimeType',
        sortable: true
      },
      {
        label: 'Uploaded',
        key: 'CreatedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Uploaded By',
        key: 'AuthorName',
        sortable: true
      },
      {
        label: 'I.D.',
        key: 'ItemId',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'ItemId',
        type: 'check'
      },
      {
        label: 'Title',
        key: 'Name',
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        sortable: true
      },
      {
        label: 'Type',
        key: 'MimeType',
        sortable: true
      },
      {
        label: 'Uploaded',
        key: 'CreatedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Uploaded By',
        key: 'AuthorName',
        sortable: true
      },
      {
        label: 'I.D.',
        key: 'ItemId',
        sortable: true
      }
    ]
  },
  recycleBin: {
    title: 'Recycle Bin',
    searchLabel: {
      0: 'Show items recycled between',
      1: 'and'
    },
    actionHeader: {
      0: {
        title: 'Document Central Repository',
        icon: 'fas fa-folder'
      }
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'RecordId',
          type: 'check'
        },
        {
          label: 'Title',
          key: 'Name',
          sortable: true
        },
        {
          label: 'Type',
          key: 'MimeType',
          sortable: true
        },
        {
          label: 'Deleted',
          key: 'RecycledOn',
          type: Date,
          sortable: true
        },
        {
          label: 'I.D.',
          key: 'RecordId',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'RecordId',
          type: 'check'
        },
        {
          label: 'Title',
          key: 'Name',
          sortable: true
        },
        {
          label: 'Type',
          key: 'MimeType',
          sortable: true
        },
        {
          label: 'Deleted',
          key: 'RecycledOn',
          type: Date,
          sortable: true
        },
        {
          label: 'I.D.',
          key: 'RecordId',
          sortable: true
        }
      ]
    },
    dropdownActions: [
      {
        title: 'ViewItem',
        actionName: 'viewItem',
        permissions: []
      },
      {
        title: 'Reinstate',
        actionName: 'reinstate',
        permissions: []
      }
    ],
    detailsModal: {
      title: 'Recycle Item Details',
      labels: {
        0: 'Name',
        1: 'Recycled On',
        2: 'Recycled By',
        3: 'Recycled From Folder',
        4: 'Type',
        5: 'ID'
      }
    }
  }
}

export default documentCentral

const workOrderCharges = {
  tableHeader: {
    desktop: [
      {
        label: 'Trabajo',
        type: Number,
        key: 'workOrderTaskNumber',
        sortable: true
      },
      {
        label: 'Tipo',
        key: 'chargeType',
        sortable: true
      },
      {
        label: 'Artículo No.',
        key: 'masterNumber',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true
      },
      {
        label: 'Cant.',
        key: 'qty',
        sortable: true
      },
      {
        label: 'Costo',
        key: 'cost',
        type: 'NumberWithTwoDigits',
        sortable: true
      },
      {
        label: 'Unidad',
        key: 'unit',
        sortable: true
      },
      {
        label: 'Partes',
        key: 'parts',
        type: 'NumberWithTwoDigits',
        sortable: true
      },
      {
        label: 'Labor',
        key: 'labor',
        type: 'NumberWithTwoDigits',
        sortable: true
      },
      {
        label: 'Otro',
        key: 'other',
        type: 'NumberWithTwoDigits',
        sortable: true
      },
      {
        label: 'Centro de costos',
        key: 'costCenterId',
        type: 'Guid',
        listType: 3,
        sortable: true
      },
      {
        label: 'Código de costo',
        key: 'costCodeId',
        type: 'Guid',
        listType: 4,
        sortable: true
      },
      {
        label: 'Clase de servicio',
        key: 'serviceClassId',
        type: 'Guid',
        listType: 33,
        sortable: true
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Trabajo',
            type: Number,
            key: 'workOrderTaskNumber',
            sortable: true
          },
          {
            label: 'Tipo',
            key: 'chargeType',
            sortable: true
          },
          {
            label: 'Artículo No.',
            key: 'masterNumber',
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true
          },
          {
            label: 'Cant.',
            key: 'qty',
            sortable: true
          },
          {
            label: 'Costo',
            key: 'cost',
            type: 'NumberWithTwoDigits',
            sortable: true
          },
          {
            label: 'Unidad',
            key: 'unit',
            sortable: true
          },
          {
            label: 'Partes',
            key: 'parts',
            type: 'NumberWithTwoDigits',
            sortable: true
          },
          {
            label: 'Labor',
            key: 'labor',
            type: 'NumberWithTwoDigits',
            sortable: true
          },
          {
            label: 'Otro',
            key: 'other',
            type: 'NumberWithTwoDigits',
            sortable: true
          },
          {
            label: 'Centro de costos',
            key: 'costCenterId',
            type: 'Guid',
            listType: 3,
            sortable: true
          },
          {
            label: 'Código de costo',
            key: 'costCodeId',
            type: 'Guid',
            listType: 4,
            sortable: true
          },
          {
            label: 'Clase de servicio',
            key: 'serviceClassId',
            type: 'Guid',
            listType: 33,
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default workOrderCharges

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"req-org-v-select",attrs:{"label":"Description","options":_vm.orgLists,"clearable":false,"placeholder":" "},on:{"input":function($event){_vm.$emit('onOrgSelected', $event), _vm.$emit('resetData', true)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var level = ref.level;
var Description = ref.Description;
return [_c('span',{class:{'font-weight-bolder': level === 1}},[(level === 2)?_c('i',{staticClass:"fa-solid fa-building"}):_vm._e(),_vm._v(_vm._s(Description))])]}},{key:"selected-option",fn:function(ref){
var level = ref.level;
var Description = ref.Description;
var DescriptionTruncated = ref.DescriptionTruncated;
return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: Description,
        placement: 'top',
        trigger: DescriptionTruncated ? 'hover' : 'manual'
      }),expression:"{\n        content: Description,\n        placement: 'top',\n        trigger: DescriptionTruncated ? 'hover' : 'manual'\n      }"}],class:{'font-weight-bolder': level === 1},style:(("width: " + _vm.dropdownWidth + "px; text-wrap: nowrap; overflow: hidden;"))},[(level === 2)?_c('i',{staticClass:"fa-solid fa-building",staticStyle:{"padding-right":"2px"}}):_vm._e(),_vm._v(" "+_vm._s(DescriptionTruncated ? DescriptionTruncated.trim() : Description.trim())+" ")])]}}]),model:{value:(_vm.selectedOrg),callback:function ($$v) {_vm.selectedOrg=$$v},expression:"selectedOrg"}})}
var staticRenderFns = []

export { render, staticRenderFns }
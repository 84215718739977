const transferTicket = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'TransferId',
        type: 'check'
      },
      {
        label: 'Colaborar',
        type: Number,
        key: 'CollaborateCount',
        sortable: true,
        flag: 'showCollaborateExpansion'
      },
      {
        label: 'Fecha de transferencia',
        type: Date,
        key: 'CreatedOn',
        sortable: true,
        sorted: true,
        asc: true
      },
      {
        label: 'Fecha de asignación',
        type: Date,
        key: 'AssignmentDate',
        sortable: true
      },
      {
        label: 'Estado',
        type: String,
        key: 'IsDraft',
        sortable: true
      },
      {
        label: 'Número',
        type: Number,
        key: 'TransferNumber',
        sortable: true
      },
      {
        label: 'Transferido desde',
        type: String,
        key: 'TwEntityFrom',
        sortable: true
      },
      {
        label: 'Transferida a',
        type: String,
        key: 'TwEntityTo',
        sortable: true
      },
      {
        label: 'Proveedor',
        type: String,
        key: 'OriginationType',
        sortable: true
      },
      {
        label: 'Transferido por',
        type: String,
        key: 'AssignedBy',
        sortable: true
      },
      {
        label: 'Entregado por',
        type: String,
        key: 'DeliveredBy',
        sortable: true
      },
      {
        label: 'Documento núm.',
        type: String,
        key: 'DocNumber',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'TransferId',
        type: 'check'
      },
      {
        label: 'Colaborar',
        type: Number,
        key: 'CollaborateCount',
        flag: 'showCollaborateExpansion'
      },
      {
        label: 'Número',
        type: Number,
        key: 'TransferNumber',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Fecha de transferencia',
            type: Date,
            key: 'CreatedOn',
            sortable: true
          },
          {
            label: 'Fecha de asignación',
            type: Date,
            key: 'AssignmentDate',
            sortable: true
          },
          {
            label: 'Transferido desde',
            type: String,
            key: 'TwEntityFrom',
            sortable: true
          },
          {
            label: 'Transferida a',
            type: String,
            key: 'TwEntityTo',
            sortable: true
          },
          {
            label: 'Proveedor',
            type: String,
            key: 'originationType',
            sortable: true
          },
          {
            label: 'Transferido por',
            type: String,
            key: 'AssignedBy',
            sortable: true
          },
          {
            label: 'Entregado por',
            type: String,
            key: 'DeliveredBy',
            sortable: true
          },
          {
            label: 'Documento núm.',
            type: String,
            key: 'DocNumber',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default transferTicket

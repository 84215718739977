const descriptions = {
  confirmDelete: 'Está seguro de que desea eliminar las siguientes descripciones?',
  tableHeader: {
    desktop: [
      {
        label: ''
      },
      {
        label: 'Descripción'
      },
      {
        label: 'Tipo'
      },
      {
        label: ''
      }
    ],
    mobile: [
      {
        label: ''
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Descripción'
          },
          {
            label: 'Tipo'
          }
        ]
      },
      {
        label: ''
      }
    ]
  }
}

export default descriptions

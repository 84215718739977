const requisition = {
  homeTitle: 'Solicitudes',
  history: 'Historial de solicitudes',
  greeting: 'Hola',
  placeholder: {
    0: 'Qué estás buscando?',
    1: 'Qué estas devolviendo?'
  },
  openCart: {
    title: 'Abierto',
    noMsg: 'No hay solicitudes abiertas',
    requestNo: 'Solicitud No.',
    quantity: 'Cantidad'
  },
  delivered: {
    title: 'Entregado',
    noMsg: 'No hay solicitudes entregadas'
  },
  reRequest: {
    title: 'Solicitar de nuevo',
    noMsg: 'Sin solicitudes'
  },
  noMsg: 'No hay resultados que coincidan con la descripción de su búsqueda.',
  stock: {
    inStock: '{count} en existencia',
    outofStock: 'Agotado'
  },
  productDetail: {
    title: 'Detalle del producto'
  },
  assignmentChanged: {
    title: 'Ubicación de stock asignada cambiada',
    message: 'Su ubicación de stock asignada ha cambiado.',
    confirmSettings: 'Por favor, confirme su configuración.',
    invalidJob: 'Su trabajo seleccionado anteriormente ya no es válido. Por favor elije otro.'
  },
  productInfo: {
    title: 'Información del Producto',
    labels: {
      0: 'Fabricante',
      1: 'Modelo',
      2: 'Catalogar #',
      3: 'UPC/ASIN',
      4: 'Año',
      5: 'Id del Modelo'
    }
  },
  productTracking: {
    title: 'Información de rastreo',
    labels: {
      0: 'Categoría',
      1: 'Descripción genérica',
      2: 'Tipo de artículo',
      3: 'Método de rastreo',
      4: 'Clase predeterminada',
      5: 'Certificación',
      6: 'Se requiere medidor'
    }
  },
  cart: {
    title: 'Carrito',
    desc: 'objetos en tu carrito de compras',
    noMsg: 'Sin carrito',
    actionHeader: {
      0: {
        title: 'Colaborar',
        icon: 'fa-regular fa-comment-dots',
        key: 'collaborate',
        isShow: true
      }
    },
    requiredRequestFrom: 'Se requiere solicitud de',
    requiredDeliverTo: 'Se requiere entregar a'
  },
  checkout: {
    title: 'Verificar',
    desc: 'Revise su carro de salidas',
    labels: {
      0: 'Envia a',
      1: 'Ubicación y centro de costos',
      2: 'Fecha requerida',
      3: 'Comentarios',
      4: 'Revisar artículos',
      5: 'Volver a',
      6: 'Recoger',
      7: 'Notas'
    },
    actionHeader: {
      0: {
        title: 'Colaborar',
        icon: 'fa-regular fa-comment-dots',
        key: 'collaborate',
        isShow: true
      }
    }
  },
  editModal: {
    title: 'Bienvenidos',
    logout: 'Cerrar sesión',
    for: 'Para',
    labels: {
      0: 'Portal',
      1: 'Formato de La Compania',
      2: 'Ubicaciones',
      3: 'Centro de costos',
      4: 'Código de costo',
      5: 'Direcciones',
      6: 'Entregar a',
      7: 'Solicitud de',
      8: 'Regresar de'
    }
  },
  reqHistory: {
    title: 'Solicitar historial',
    placeholder: 'Buscar todas las solicitudes',
    labels: {
      0: 'Por artículo',
      1: 'Por solicitud',
      2: 'Solicitar de nuevo'
    },
    filter: {
      status: {
        0: 'Todas',
        1: 'Abierto',
        2: 'Cerrado',
        3: 'Borrador',
        4: 'enviado',
        5: 'terminado'
      },
      time: {
        0: 'Últimos 30 días',
        1: 'Últimos 3 meses'
      }
    },
    itemDetail: {
      0: 'Estado',
      1: 'Cant.',
      2: 'Fecha',
      3: 'Documento',
      4: 'Fuente'
    }
  },
  confirmDlg: {
    title: 'Cambiar ubicación',
    message: 'Su carrito ya contiene ({count}) artículos. Debe eliminar sus elementos si desea cambiar su ubicación.'
  },
  return: {
    title: 'Devoluciones',
    location: 'Regresando de',
    labels: {
      0: 'Iniciar una devolución',
      1: 'Devoluciones en curso',
      2: 'Regresado recientemente'
    },
    searchPage: {
      header: 'Seleccionar artículos para devolver',
      switcherLabel: 'Ocultar artículos programados para devolución',
      itemSelected: 'Sin artículo seleccionado | 1 artículo seleccionado | {count} elementos seleccionados'
    },
    ticket: {
      title: 'Devolución de ticket',
      placeholder: 'Buscar este ticket',
      items: 'Sin objeto | 1 articulo | {count} elementos',
      actionLabels: {
        0: 'Acciones',
        1: 'Copiar producto',
        2: 'Fusionar productos',
        3: 'Quitar producto'
      },
      number: 'Devolución No.',
      noMsg: 'No hay ticket',
      onSite: '{count} on site',
      returning: '{count} al regresar'
    },
    summary: {
      title: 'Resumen de devoluciones'
    },
    history: {
      title: 'Historial de devoluciones',
      placeholder: 'Buscar todas las devoluciones',
      labels: {
        0: 'Estado',
        1: 'Cant.',
        2: 'Fecha',
        3: 'Documento',
        4: 'Por'
      }
    },
    confirmDlg: {
      title: 'Cambiar ticket de devulucion',
      message: 'Este Ticket de devolucion contiene ({count}) articulos para {location}. Usted no puede añadir articulos de otra ubicacion. <br> Usted puede completar en ticket actual, o cancelar e iniciar un nuevo ticket.</br>'
    },
    noItem: 'Sin {title} Articulos',
    deleteReturn: {
      title: 'Eliminar boleto de vuelta',
      message: '¿Está a punto de eliminar todos los elementos del boleto de regreso ({count})?',
      yes: 'Sí, eliminar boleto',
      no: 'No, cancela'
    }
  },
  reqBrowser: {
    title: 'Navegador de solicitudes',
    labels: {
      open: 'Abierta',
      closed: 'Cerrado',
      all: 'Todo'
    },
    browserLineActions: [
      {
        title: 'Vista',
        actionName: 'onView',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Borrar',
        actionName: 'onDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    browserLineActionsView: [
      {
        title: 'Vista',
        actionName: 'onView',
        requiresSelection: true,
        allowsMultiSelect: false
      }
    ],
    browserLineActionsDelete: [
      {
        title: 'Borrar',
        actionName: 'onDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  },
  reqBrowserView: {
    title: 'RequVista de solicitudisar',
    titlePurchaseOrder: 'Orden de compra',
    titlePickTicket: 'Recoger boleto',
    titleReject: 'Rechazar',
    titleUndoReject: 'Deshacer Rechazar',
    subActionHeader: {
      0: {
        title: 'Ahorrar',
        icon: 'fas fa-save',
        isShow: true
      },
      1: {
        title: 'Imprimir',
        key: 'Print',
        icon: 'fas fa-print',
        isShow: true
      },
      2: {
        title: 'Agregar plantilla',
        icon: 'fas fa-plus',
        isShow: true
      },
      3: {
        title: 'Agregar registro',
        icon: 'fas fa-plus',
        isShow: true
      },
      4: {
        title: 'Archivos adjuntos',
        icon: 'fas fa-paperclip',
        isShow: true
      },
      5: {
        title: 'Atrás',
        icon: 'fas fa-undo',
        isShow: true
      },
      6: {
        title: 'Colaborar',
        key: 'collaborate',
        icon: 'fa-regular fa-comment-dots',
        isShow: true,
        isDraft: true
      }
    },
    collaborationHeader: {
      5: {
        title: 'Atrás',
        icon: 'fas fa-undo',
        isShow: true
      },
      6: {
        title: 'Colaborar',
        key: 'collaborate',
        icon: 'fa-regular fa-comment-dots',
        isShow: true,
        isDraft: true
      }
    },
    cart: {
      desc: 'Artículos en el billete de transferencia',
      labels: {
        0: 'Número de solicitud.',
        1: 'Estado'
      }
    },
    noRequisitionSelected: {
      title: 'No se seleccionó ningún artículo de solicitud.'
    },
    ticketHolder: {
      labels: {
        0: 'De',
        1: 'A',
        2: 'Solicitud No.',
        3: 'Solicitado por',
        4: 'Fecha de vencimiento',
        5: 'Asignado a',
        6: 'Fecha de cierre'
      }
    },
    browserLineActions: [
      {
        title: 'Tomar acción',
        actionName: 'onAction',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Borrar',
        actionName: 'onDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Historia',
        actionName: 'onHistory',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    browserLineHistory: [
      {
        title: 'Historia',
        actionName: 'onHistory',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  },
  reqBrowserCreate: {
    title: 'Requisar',
    subtitle: 'Requisar',
    addBackLink: {
      0: {
        title: 'Volver a Solicitud',
        icon: 'fas fa-undo'
      }
    },
    requisitonRequestCart: {
      labels: {
        0: 'Solicitado de',
        1: 'Solicitada a',
        2: 'Volver a Solicitud'
      }
    },
    ticketItem: {
      title: 'Artículos en Solicitud',
      labels: {
        0: 'es genérico'
      }
    }
  }
}

export default requisition
